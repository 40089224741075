import { EventEmitter, Injectable, Output } from '@angular/core';
import { EnumerationService } from './enumeration-service';
import { MenuItemStatus } from '../modules/tpo/models/enums/menu-item-status.enum';
import { MenuItemStatusChangedEvent } from '../modules/tpo/models/menu-item-status-changed-event.model';
import { BorrowerRelationshipTitleType, ChannelEnum, DomesticRelationshipTypeEnum, Employment, EthnicityType, MilitaryBranch, MilitaryStatus, MortgageBorrower, NativeAmericanLandsType, PropertyTitleType, RealEstateOwned, RecordingJurisdictionType, ResidencyAddress, ResidencyBasis, ResidencyType } from '../models';
import { Constants } from './constants';
import { UrlaMortgage } from '../modules/urla/models/urla-mortgage.model';
import { MortgageCalculationService } from '../modules/urla/services/mortgage-calculation.service';
import { BorrowerType } from '../modules/leads/models/lead-credit.model';
import { LoanPurposeTypeEnum, MortgageAppliedForTypeEnum } from '../modules/app-details/components/title-history/models/title-order.model';
import { DateTime } from 'luxon';
import { getDaysForMonth } from 'src/utils';
import { allFieldStateNone, loadDeepHavenUrlaFieldsConFig, loadDefaultUrlaFieldsConFig, MortgageFieldRequiredState, MortgageModelFieldsConfig, updateUrlaFieldConfigBasedOnMortgage } from '../shared/services/mortgage-field-validation-utils';
import { AmortizationType } from '../modules/reports/mortgage-call/models/mcr-audit-data.model';

@Injectable({
  providedIn: 'root'
})
export class UrlaValidationService {

  @Output()
  menuItemStatusChanged: EventEmitter<MenuItemStatusChangedEvent> = new EventEmitter<MenuItemStatusChangedEvent>();

  private _titleOnlyEnumValue: string = "";
  private _nonTitledSpouseEnumValue: string = "";
  private _nonBorrowerHouseholdMemberEnumValue: string = "";
  private _loanPurposePurchaseEnumValue: string;
  private _propertyWillBePrimaryEnumValue: string;
  private _propertyWillBeSecondaryEnumValue: string;
  private _propertyWillBeInvestmentEnumValue: string;
  private _ethnicityTypeHispanicOrLatino: string;
  private _raceTypeAmericanIndianOrAlaskaNative: string;
  private _projectTypeCondominium: string;
  private _estateLeaseHoldEnumValue: string;
  private _formerEmployerEnumValue: string;
  private _currentEmployerEnumValue: string;
  private _amortizationTypeArmEnumValue: string;

  private _defaultUrlaFieldsConfig: MortgageModelFieldsConfig;
  private _fieldsConfigWhereNothingIsRequestedOrRequired: MortgageModelFieldsConfig;
  private _deepHavenUrlaFieldsConfig: MortgageModelFieldsConfig;

  constructor(private readonly _enumsService: EnumerationService,
    private readonly _calculationService: MortgageCalculationService) {

    this._titleOnlyEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.SigningRole.TitleOnly);
    this._nonTitledSpouseEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.SigningRole.NonTitledSpouse);
    this._nonBorrowerHouseholdMemberEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.SigningRole.NonBorrowerHouseholdMember);
    this._loanPurposePurchaseEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.LoanPurposeType.Purchase);
    this._propertyWillBePrimaryEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.PropertyWillBeType.PrimaryResidence);
    this._propertyWillBeSecondaryEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.PropertyWillBeType.SecondaryResidence);
    this._propertyWillBeInvestmentEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.PropertyWillBeType.Investment);
    this._ethnicityTypeHispanicOrLatino = this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.ethnicityTypeHispanicOrLatino);
    this._raceTypeAmericanIndianOrAlaskaNative = this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.raceTypeAmericanIndianOrAlaskaNative);
    this._projectTypeCondominium = this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.projectTypeCondominium);
    this._estateLeaseHoldEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.EstateType.Leasehold);
    this._formerEmployerEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.EmploymentType.FormerEmployer);
    this._currentEmployerEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.EmploymentType.CurrentEmployer);
    this._amortizationTypeArmEnumValue = this._enumsService.getEnumValue(Constants.enumerations.enumItemNames.isAmortizationTypeArm);

    this._defaultUrlaFieldsConfig = loadDefaultUrlaFieldsConFig();
    this._fieldsConfigWhereNothingIsRequestedOrRequired = allFieldStateNone();
    this._deepHavenUrlaFieldsConfig = loadDeepHavenUrlaFieldsConFig();
  }

  getUrlaFieldsConfigForMortgage = (mortgage: UrlaMortgage, isLinkedToLos: boolean, isTpo: Boolean): MortgageModelFieldsConfig => {
    let mortgageFieldsConfig: MortgageModelFieldsConfig;
    if (mortgage.companyId == 229 || mortgage.companyId == 276) {
      mortgageFieldsConfig = { ...this._deepHavenUrlaFieldsConfig };
    } else {
      mortgageFieldsConfig = { ...this._defaultUrlaFieldsConfig };
    }
    updateUrlaFieldConfigBasedOnMortgage(mortgageFieldsConfig, mortgage, isLinkedToLos, isTpo);
    return mortgageFieldsConfig;
  }

  getStatusForBorrowersInfo = (mortgageFieldsConfig: MortgageModelFieldsConfig, mortgage: UrlaMortgage, isTpo: boolean = false): MenuItemStatus => {
    let isCorrespondent = mortgage.channel === ChannelEnum.Correspondent;
    let state = MenuItemStatus.Success;

    if (mortgage.borrowers && mortgage.borrowers.length > 0) {
      mortgage.borrowers.forEach(borrower => {

        let borrowerState = this.getStatusForBorrowerInfo(mortgageFieldsConfig, borrower, isCorrespondent, isTpo)

        if (state == MenuItemStatus.Error) {
          return state;
        }
        else if (borrowerState == MenuItemStatus.Error || borrowerState == MenuItemStatus.Pending) {
          state = borrowerState;
        }
      });
    }

    return state;
  }

  getStatusForBorrowerInfo = (mortgageFieldsConfig: MortgageModelFieldsConfig, borrower: MortgageBorrower, isCorrespondent: boolean, isTpo: boolean = false): MenuItemStatus => {
    let state = MenuItemStatus.Success;

    let contactInfoState = this.getStatusForBorrowerContactInfo(mortgageFieldsConfig, borrower, isCorrespondent, isTpo);
    let addressInfoState = this.getStatusForBorrowerAddressInfo(mortgageFieldsConfig, borrower);
    let employmentState = this.getStatusForBorrowerEmploymentInfo(mortgageFieldsConfig, borrower);
    let titleState = this.getStatusForBorrowerTitleInfo(mortgageFieldsConfig, borrower);

    if (contactInfoState == MenuItemStatus.Error || addressInfoState == MenuItemStatus.Error ||
      employmentState == MenuItemStatus.Error || titleState == MenuItemStatus.Error) {
      state = MenuItemStatus.Error;
    }
    else if (contactInfoState == MenuItemStatus.Pending || addressInfoState == MenuItemStatus.Pending ||
      employmentState == MenuItemStatus.Pending || titleState == MenuItemStatus.Pending) {
      state = MenuItemStatus.Pending;
    }

    return state;
  }

  getStatusForBorrowerContactInfo = (mortgageFieldsConfig: MortgageModelFieldsConfig, borrower: MortgageBorrower, isCorrespondent: boolean, isTpo: boolean = false) => {
    let state = MenuItemStatus.Success;

    let isTitleOnlyOrNonTitledSpouse = (borrower.signingRole == this._titleOnlyEnumValue ||
      borrower.signingRole == this._nonTitledSpouseEnumValue || borrower.signingRole == this._nonBorrowerHouseholdMemberEnumValue);

    let unmarriedAddendum = borrower.domesticRelationshipType != null ||
      borrower.unmarriedRelationshipState != null;

    if (isTitleOnlyOrNonTitledSpouse) {
      if (mortgageFieldsConfig['mortgage.borrower.firstName'] && mortgageFieldsConfig['mortgage.borrower.firstName'].required == MortgageFieldRequiredState.Required) {
        mortgageFieldsConfig['mortgage.borrower.firstName'].required = MortgageFieldRequiredState.Requested;
      }
      if (mortgageFieldsConfig['mortgage.borrower.lastName'] && mortgageFieldsConfig['mortgage.borrower.lastName'].required == MortgageFieldRequiredState.Required) {
        mortgageFieldsConfig['mortgage.borrower.lastName'].required = MortgageFieldRequiredState.Requested;
      }
      if (mortgageFieldsConfig['mortgage.borrower.primaryEmail'] && mortgageFieldsConfig['mortgage.borrower.primaryEmail'].required == MortgageFieldRequiredState.Required) {
        mortgageFieldsConfig['mortgage.borrower.primaryEmail'].required = MortgageFieldRequiredState.Requested;
      }
      if (mortgageFieldsConfig['mortgage.borrower.socialSecNum'] && mortgageFieldsConfig['mortgage.borrower.socialSecNum'].required == MortgageFieldRequiredState.Required) {
        mortgageFieldsConfig['mortgage.borrower.socialSecNum'].required = MortgageFieldRequiredState.Requested;
      }
    }

    const areAnyOfBorrowerContactInfoFieldsInvalidDueToBeingRequired = this.checkIfAnyOfBorrowerContactInfoFieldsAreInvalid(borrower, mortgageFieldsConfig,
      isTitleOnlyOrNonTitledSpouse, isTpo, isCorrespondent, MortgageFieldRequiredState.Required);
    if (areAnyOfBorrowerContactInfoFieldsInvalidDueToBeingRequired) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (!isTitleOnlyOrNonTitledSpouse && borrower.aliases && borrower.aliases.length > 0) {
      borrower.aliases.forEach(alias => {
        if (
          mortgageFieldsConfig['mortgage.borrower.alias.aliasType']?.required == MortgageFieldRequiredState.Required && !(!!alias.aliasType) ||
          mortgageFieldsConfig['mortgage.borrower.alias.firstName']?.required == MortgageFieldRequiredState.Required && !(!!alias.firstName) ||
          mortgageFieldsConfig['mortgage.borrower.alias.middleName']?.required == MortgageFieldRequiredState.Required && !(!!alias.middleName) ||
          mortgageFieldsConfig['mortgage.borrower.alias.lastName']?.required == MortgageFieldRequiredState.Required && !(!!alias.lastName) ||
          mortgageFieldsConfig['mortgage.borrower.alias.nameSuffix']?.required == MortgageFieldRequiredState.Required && !(!!alias.nameSuffix)
        ) {
          state = MenuItemStatus.Error;
          return state;
        }
      });
    }

    const areAnyOfBorrowerContactInfoFieldsInvalidDueToBeingRequested = this.checkIfAnyOfBorrowerContactInfoFieldsAreInvalid(borrower, mortgageFieldsConfig,
      isTitleOnlyOrNonTitledSpouse, isTpo, isCorrespondent, MortgageFieldRequiredState.Requested);
    if (areAnyOfBorrowerContactInfoFieldsInvalidDueToBeingRequested) {
      state = MenuItemStatus.Pending;
    }
    if (!isTitleOnlyOrNonTitledSpouse && borrower.aliases && borrower.aliases.length > 0) {
      borrower.aliases.forEach(alias => {
        if ((state === MenuItemStatus.Error) &&
          mortgageFieldsConfig['mortgage.borrower.alias.aliasType']?.required == MortgageFieldRequiredState.Requested && !(!!alias.aliasType) ||
          mortgageFieldsConfig['mortgage.borrower.alias.firstName']?.required == MortgageFieldRequiredState.Requested && !(!!alias.firstName) ||
          mortgageFieldsConfig['mortgage.borrower.alias.middleName']?.required == MortgageFieldRequiredState.Requested && !(!!alias.middleName) ||
          mortgageFieldsConfig['mortgage.borrower.alias.lastName']?.required == MortgageFieldRequiredState.Requested && !(!!alias.lastName) ||
          mortgageFieldsConfig['mortgage.borrower.alias.nameSuffix']?.required == MortgageFieldRequiredState.Requested && !(!!alias.nameSuffix)
        ) {
          state = MenuItemStatus.Pending;
        }
      });
    }

    return state;
  }

  getStatusForBorrowerAddressInfo = (mortgageFieldsConfig: MortgageModelFieldsConfig, borrower: MortgageBorrower): MenuItemStatus => {
    let numberOfMonths = 0;
    let numberOfYears = 0;

    let state = MenuItemStatus.Success;

    const isTitleOnlyOrNonTitledSpouse = (borrower.signingRole == this._titleOnlyEnumValue || borrower.signingRole == this._nonTitledSpouseEnumValue
      || borrower.signingRole == this._nonBorrowerHouseholdMemberEnumValue);

    if (borrower.residencyAddresses && borrower.residencyAddresses.length > 0) {
      borrower.residencyAddresses.forEach(address => {
        numberOfMonths += Number(address.durationMonths) | 0;
        numberOfYears += Number(address.durationYears) || 0;

        let borrowerAddressFieldsAreInvalid = this.checkIfAnyOfBorrowerAddressFieldsAreInvalid(borrower, address, mortgageFieldsConfig, isTitleOnlyOrNonTitledSpouse,
          MortgageFieldRequiredState.Required);
        if (address.residencyType && borrowerAddressFieldsAreInvalid) {
          state = MenuItemStatus.Error;
        }
        if (state !== MenuItemStatus.Error && address.residencyType) {
          borrowerAddressFieldsAreInvalid = this.checkIfAnyOfBorrowerAddressFieldsAreInvalid(borrower, address, mortgageFieldsConfig, isTitleOnlyOrNonTitledSpouse,
            MortgageFieldRequiredState.Requested);
          if (borrowerAddressFieldsAreInvalid) {
            state = MenuItemStatus.Pending;
          }
        }
      });

      if ((numberOfYears * 12 + numberOfMonths < 24) && !isTitleOnlyOrNonTitledSpouse) {
        state = MenuItemStatus.Error;
        return state;
      }
    }

    return state;
  }

  getStatusForBorrowerEmploymentInfo = (mortgageFieldsConfig: MortgageModelFieldsConfig, borrower: MortgageBorrower): MenuItemStatus => {
    let totalDurationOfEmploymentInMonths: number = 0;
    let maxDate = { year: new Date().getFullYear() + 5, month: new Date().getMonth() + 1, day: new Date().getDate() }
    let state = MenuItemStatus.Success;

    const isCompanyDeepHaven = [229, 276].includes(borrower.companyId);

    const isTitleOnlyOrNonTitledSpouse = (borrower.signingRole == this._titleOnlyEnumValue || borrower.signingRole == this._nonTitledSpouseEnumValue
      || borrower.signingRole == this._nonBorrowerHouseholdMemberEnumValue);

    if (borrower.employments && borrower.employments.length > 0) {
      borrower.employments.forEach(employment => {
        totalDurationOfEmploymentInMonths += this.calculateEmploymentDurationInMonths(employment);

        if (!(employment.employer && employment.employer === "Retired")) {
          let borrowerEmploymentFieldsAreInvalid = this.checkIfAnyOfBorrowerEmploymentFieldsAreInvalid(employment, isTitleOnlyOrNonTitledSpouse, mortgageFieldsConfig,
            MortgageFieldRequiredState.Required);
          if (borrowerEmploymentFieldsAreInvalid) {
            state = MenuItemStatus.Error;
          }

          if (state !== MenuItemStatus.Error) {
            borrowerEmploymentFieldsAreInvalid = this.checkIfAnyOfBorrowerEmploymentFieldsAreInvalid(employment, isTitleOnlyOrNonTitledSpouse, mortgageFieldsConfig,
              MortgageFieldRequiredState.Requested);
            if (borrowerEmploymentFieldsAreInvalid) {
              state = MenuItemStatus.Pending;
            }
          }
        }
      });

      const enforceEmploymentHistory = !isTitleOnlyOrNonTitledSpouse && (!isCompanyDeepHaven 
        || (isCompanyDeepHaven && borrower.employments && borrower.employments.length > 0));

      if (enforceEmploymentHistory && totalDurationOfEmploymentInMonths < 24) {
        state = MenuItemStatus.Error;
        return state;
      }
    } else {
      if (!isTitleOnlyOrNonTitledSpouse && !isCompanyDeepHaven) {
        state = MenuItemStatus.Error;
        return state;
      }
    }

    return state;
  }

  getStatusForBorrowerTitleInfo = (mortgageFieldsConfig: MortgageModelFieldsConfig, borrower: MortgageBorrower) => {
    let state = MenuItemStatus.Success;
    if (
      mortgageFieldsConfig['mortgage.borrower.borrowerRelationshipTitleType']?.required == MortgageFieldRequiredState.Required && !(!!borrower.borrowerRelationshipTitleType) ||
      mortgageFieldsConfig['mortgage.borrower.borrowerRelationshipTitleTypeOtherDescription']?.required == MortgageFieldRequiredState.Required && borrower.borrowerRelationshipTitleType === BorrowerRelationshipTitleType.Other && !(!!borrower.borrowerRelationshipTitleTypeOtherDescription)
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (
      mortgageFieldsConfig['mortgage.borrower.borrowerRelationshipTitleType']?.required == MortgageFieldRequiredState.Requested && !(!!borrower.borrowerRelationshipTitleType) ||
      mortgageFieldsConfig['mortgage.borrower.borrowerRelationshipTitleTypeOtherDescription']?.required == MortgageFieldRequiredState.Requested && borrower.borrowerRelationshipTitleType === BorrowerRelationshipTitleType.Other && !(!!borrower.borrowerRelationshipTitleTypeOtherDescription)
    ) {
      state = MenuItemStatus.Pending;
    }
    return state;
  }

  private isFilteredAsset = (assetType, assetInsertionType) => {
    if (assetInsertionType == 1) return true;

    return assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Bond) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CheckingAccount) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.BridgeLoanNotDeposited) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.LifeInsurance) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CertificateOfDepositTimeDeposit) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.IndividualDevelopmentAccount) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.MoneyMarketFund) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.MutualFund) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.RetirementFund) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.SavingsAccount) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Stock) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.TrustAccount);
  }

  isFilteredLiability = (liabilityType: string) => {
    return liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.Alimony) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.ChildSupport) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.ChildCare) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.JobRelatedExpenses) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.SeparateMaintenanceExpense) &&
      liabilityType != this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.OtherExpense);
  }

  private isFilteredAssetForGift = (assetType) => {

    return assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.CashGift) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.GiftOfEquity) ||
      assetType == this._enumsService.getEnumValue(Constants.enumerationValueNames.AssetType.Grant);
  }

  getStatusForFinancialInfo = (mortgageFieldsConfig: MortgageModelFieldsConfig, mortgage: UrlaMortgage): MenuItemStatus => {
    let liabilityTypeCollections: string = this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.CollectionsJudgementsAndLiens);

    let state = MenuItemStatus.Success;
    if (mortgage.assets && mortgage.assets.length > 0) {
      let isAssetError = mortgage.assets.some(asset =>
        ((this.isFilteredAsset(asset.assetType, asset.assetInsertionType)) && (
          mortgageFieldsConfig['mortgage.asset.assetType']?.required == MortgageFieldRequiredState.Required && !(!!asset.assetType) ||
          mortgageFieldsConfig['mortgage.asset.depository']?.required == MortgageFieldRequiredState.Required && !(!!asset.depository) ||
          mortgageFieldsConfig['mortgage.asset.accountNumber']?.required == MortgageFieldRequiredState.Required && !(!!asset.accountNumber) ||
          mortgageFieldsConfig['mortgage.asset.cashMarketValue']?.required == MortgageFieldRequiredState.Required && (asset.cashMarketValue == null || asset.cashMarketValue == undefined))
        ) || ((!this.isFilteredAsset(asset.assetType, asset.assetInsertionType)) && (
          mortgageFieldsConfig['mortgage.asset.assetType']?.required == MortgageFieldRequiredState.Required && !(!!asset.assetType) ||
          mortgageFieldsConfig['mortgage.asset.cashMarketValue']?.required == MortgageFieldRequiredState.Required && (asset.cashMarketValue == null || asset.cashMarketValue == undefined))
        )
      );
      if (isAssetError) {
        state = MenuItemStatus.Error;
        return state;
      }
      let isAssetPending = mortgage.assets.some(asset =>
        ((this.isFilteredAsset(asset.assetType, asset.assetInsertionType)) && (
          !asset.owningBorrowerIds || asset.owningBorrowerIds.length === 0 ||
          mortgageFieldsConfig['mortgage.asset.assetType']?.required == MortgageFieldRequiredState.Requested && !(!!asset.assetType) ||
          mortgageFieldsConfig['mortgage.asset.depository']?.required == MortgageFieldRequiredState.Requested && !(!!asset.depository) ||
          mortgageFieldsConfig['mortgage.asset.accountNumber']?.required == MortgageFieldRequiredState.Requested && !(!!asset.accountNumber) ||
          mortgageFieldsConfig['mortgage.asset.cashMarketValue']?.required == MortgageFieldRequiredState.Requested && (asset.cashMarketValue == null || asset.cashMarketValue == undefined))
        ) ||
        ((!this.isFilteredAsset(asset.assetType, asset.assetInsertionType)) && (
          !asset.owningBorrowerIds || asset.owningBorrowerIds.length === 0 ||
          mortgageFieldsConfig['mortgage.asset.assetType']?.required == MortgageFieldRequiredState.Requested && !(!!asset.assetType) ||
          mortgageFieldsConfig['mortgage.asset.cashMarketValue']?.required == MortgageFieldRequiredState.Requested && (asset.cashMarketValue == null || asset.cashMarketValue == undefined))
        )
      );
      if (isAssetPending) {
        state = MenuItemStatus.Pending;
      }
    }

    if (mortgage.transactionDetail.purchaseCredits && mortgage.transactionDetail.purchaseCredits.length > 0) {
      let isCreditError = mortgage.transactionDetail.purchaseCredits.some(credit => (
        mortgageFieldsConfig['mortgage.asset.assetType']?.required == MortgageFieldRequiredState.Required && !(!!credit.purchaseCreditType) ||
        mortgageFieldsConfig['mortgage.asset.cashMarketValue']?.required == MortgageFieldRequiredState.Required && (credit.purchaseCreditAmount == null || credit.purchaseCreditAmount == undefined))
      );
      if (isCreditError) {
        state = MenuItemStatus.Error;
        return state;
      }
      let isCreditPending = mortgage.transactionDetail.purchaseCredits.some(credit => (
        mortgageFieldsConfig['mortgage.asset.assetType']?.required == MortgageFieldRequiredState.Requested && !(!!credit.purchaseCreditType) ||
        mortgageFieldsConfig['mortgage.asset.cashMarketValue']?.required == MortgageFieldRequiredState.Requested && (credit.purchaseCreditAmount == null || credit.purchaseCreditAmount == undefined))
      );
      if (isCreditPending) {
        state = MenuItemStatus.Pending;
      }
    }

    if (mortgage.liabilities && mortgage.liabilities.length > 0) {

      mortgage.liabilities.forEach(liability => {
        let liabilityState = this.getStatusForLiability(mortgageFieldsConfig, liability, liabilityTypeCollections, mortgage);
        if (state == MenuItemStatus.Error) {
          return;
        }
        else if (liabilityState == MenuItemStatus.Error || liabilityState == MenuItemStatus.Pending) {
          state = liabilityState
        }
      })
    }

    return state;

  }

  getStatusForLiability = (mortgageFieldsConfig: MortgageModelFieldsConfig, liability, liabilityTypeCollections, mortgage: UrlaMortgage) => {
    let state = MenuItemStatus.Success;

    let liabilityTypeHeloc: string = this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.HELOC);
    let liabilityTypeMortgage: string = this._enumsService.getEnumValue(Constants.enumerationValueNames.LiabilityType.MortgageLoan);

    if (!liability.isExcluded && (liability.typeOfLiability == liabilityTypeHeloc || liability.typeOfLiability == liabilityTypeMortgage)) {
      if (liability.liabilityId < 0) {
        const linkedReoToNewLiability = mortgage.realEstateOwned?.find(reo => reo.liabilities.find(l => l.liabilityId == liability.liabilityId));
        if (!linkedReoToNewLiability) {
          state = MenuItemStatus.Error;
          return state;
        }
      } else {
        if (!liability.reoId) {
          state = MenuItemStatus.Error;
          return state;
        }
      }
    }

    if (
      (mortgageFieldsConfig['mortgage.liability.typeOfLiability']?.required == MortgageFieldRequiredState.Required && !(!!liability.typeOfLiability)) ||
      (mortgageFieldsConfig['mortgage.liability.holderName']?.required == MortgageFieldRequiredState.Required && !(!!liability.holderName) && this.isFilteredLiability(liability.typeOfLiability)) ||
      (mortgageFieldsConfig['mortgage.liability.accountNumber']?.required == MortgageFieldRequiredState.Required && !(!!liability.accountNumber) && this.isFilteredLiability(liability.typeOfLiability)) ||
      (mortgageFieldsConfig['mortgage.liability.monthlyPayment']?.required == MortgageFieldRequiredState.Required && (liability.monthlyPayment == null || liability.monthlyPayment == undefined)
        && liability.unpaidBalance && liability.unpaidBalance > 0 && liability.typeOfLiability != liabilityTypeCollections && this.isFilteredLiability(liability.typeOfLiability))
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (
      (!liability.owningBorrowerIds) || (liability.owningBorrowerIds.length === 0) ||
      (mortgageFieldsConfig['mortgage.liability.typeOfLiability']?.required == MortgageFieldRequiredState.Requested && !(!!liability.typeOfLiability)) ||
      (mortgageFieldsConfig['mortgage.liability.holderName']?.required == MortgageFieldRequiredState.Requested && !(!!liability.holderName) && this.isFilteredLiability(liability.typeOfLiability)) ||
      (mortgageFieldsConfig['mortgage.liability.accountNumber']?.required == MortgageFieldRequiredState.Requested && !(!!liability.accountNumber) && this.isFilteredLiability(liability.typeOfLiability)) ||
      (mortgageFieldsConfig['mortgage.liability.monthlyPayment']?.required == MortgageFieldRequiredState.Requested && (liability.monthlyPayment == null || liability.monthlyPayment == undefined)
        && liability.unpaidBalance && liability.unpaidBalance > 0 && liability.typeOfLiability != liabilityTypeCollections && this.isFilteredLiability(liability.typeOfLiability))
    ) {
      state = MenuItemStatus.Pending;
    }
    return state;
  }

  private checkIfInvestmentOrTwoToFourUnitProperty = (reo: RealEstateOwned) => {
    if (['RetainForPrimaryOrSecondaryResidence', 'Retained', 'RentalProperty'].indexOf(reo.dispositionStatus) < 0) {
      return false;
    }
    return reo.typeOfProperty == "TwoToFourUnitProperty" || reo.intendedUsageType == "Investment";    // Rental income is never used for secondary residences
  }

  getStatusForReo = (mortgageFieldsConfig: MortgageModelFieldsConfig, mortgage: UrlaMortgage): MenuItemStatus => {
    let state = MenuItemStatus.Success;

    if (mortgage.realEstateOwned && mortgage.realEstateOwned.length > 0) {

      mortgage.realEstateOwned.forEach(reo => {
        if (
          mortgage.borrowers.find(borr => borr.residencyAddresses.find(a =>
            a.residencyType === ResidencyType.PresentAddress &&
            a.residencyBasis !== ResidencyBasis.Own &&
            reo.address1?.toLocaleLowerCase() === a.address?.address1?.toLocaleLowerCase() &&
            reo.city?.toLocaleLowerCase() === a.address?.city?.toLocaleLowerCase() &&
            reo.state?.toLocaleLowerCase() === a.address?.state?.toLocaleLowerCase() &&
            reo.zipCode?.toLocaleLowerCase() === a.address?.zipCode?.toLocaleLowerCase())) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.address1']?.required == MortgageFieldRequiredState.Required && !(!!reo.address1) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.address2']?.required == MortgageFieldRequiredState.Required && !(!!reo.address2) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.city']?.required == MortgageFieldRequiredState.Required && !(!!reo.city) ||
          (mortgageFieldsConfig['mortgage.realEstateOwned.state']?.required == MortgageFieldRequiredState.Required && !(!!reo.state) && reo.country == 'us') ||
          (mortgageFieldsConfig['mortgage.realEstateOwned.zipCode']?.required == MortgageFieldRequiredState.Required && !(!!reo.zipCode) && reo.country == 'us') ||
          mortgageFieldsConfig['mortgage.realEstateOwned.country']?.required == MortgageFieldRequiredState.Required && !(!!reo.country) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.borrowerPicker']?.required == MortgageFieldRequiredState.Required && (reo.owningBorrowerIds == null || reo.owningBorrowerIds == undefined || reo.owningBorrowerIds.length == 0) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.typeOfProperty']?.required == MortgageFieldRequiredState.Required && !(!!reo.typeOfProperty) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.currentUsageType']?.required == MortgageFieldRequiredState.Required && !(!!reo.currentUsageType) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.dispositionStatus']?.required == MortgageFieldRequiredState.Required && !(!!reo.dispositionStatus) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.intendedUsageType']?.required == MortgageFieldRequiredState.Required && !(!!reo.intendedUsageType) && reo.dispositionStatus != 'Sold' ||
          mortgageFieldsConfig['mortgage.realEstateOwned.isSubjectProperty']?.required == MortgageFieldRequiredState.Required && (reo.isSubjectProperty == null || reo.isSubjectProperty == undefined) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.assetAccountId']?.required == MortgageFieldRequiredState.Required && !(!!reo.assetAccountId) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.marketValue']?.required == MortgageFieldRequiredState.Required && (reo.marketValue == null || reo.marketValue == undefined) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.monthlyMiscExpenses']?.required == MortgageFieldRequiredState.Required && (reo.monthlyMiscExpenses == null || reo.monthlyMiscExpenses == undefined) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.percentOwned']?.required == MortgageFieldRequiredState.Required && (reo.percentOwned == null || reo.percentOwned == undefined || reo.percentOwned.toString() == '') && this.checkIfInvestmentOrTwoToFourUnitProperty(reo) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.vacancyFactor']?.required == MortgageFieldRequiredState.Required && (reo.vacancyFactor == null || reo.vacancyFactor == undefined || reo.vacancyFactor.toString() == '') && this.checkIfInvestmentOrTwoToFourUnitProperty(reo) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.grossRentalIncome']?.required == MortgageFieldRequiredState.Required && (reo.grossRentalIncome == null || reo.grossRentalIncome == undefined) && this.checkIfInvestmentOrTwoToFourUnitProperty(reo) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.netRentalIncome']?.required == MortgageFieldRequiredState.Required && (reo.netRentalIncome == null || reo.netRentalIncome == undefined) && this.checkIfInvestmentOrTwoToFourUnitProperty(reo)
        ) {
          state = MenuItemStatus.Error;
        }

        if (state != MenuItemStatus.Error && (
          mortgageFieldsConfig['mortgage.realEstateOwned.address1']?.required == MortgageFieldRequiredState.Requested && !(!!reo.address1) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.address2']?.required == MortgageFieldRequiredState.Requested && !(!!reo.address2) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.city']?.required == MortgageFieldRequiredState.Requested && !(!!reo.city) ||
          (mortgageFieldsConfig['mortgage.realEstateOwned.state']?.required == MortgageFieldRequiredState.Requested && !(!!reo.state) && reo.country == 'us') ||
          (mortgageFieldsConfig['mortgage.realEstateOwned.zipCode']?.required == MortgageFieldRequiredState.Requested && !(!!reo.zipCode) && reo.country == 'us') ||
          mortgageFieldsConfig['mortgage.realEstateOwned.country']?.required == MortgageFieldRequiredState.Requested && !(!!reo.country) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.borrowerPicker']?.required == MortgageFieldRequiredState.Requested && (reo.owningBorrowerIds == null || reo.owningBorrowerIds == undefined || reo.owningBorrowerIds.length == 0) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.typeOfProperty']?.required == MortgageFieldRequiredState.Requested && !(!!reo.typeOfProperty) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.currentUsageType']?.required == MortgageFieldRequiredState.Requested && !(!!reo.currentUsageType) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.dispositionStatus']?.required == MortgageFieldRequiredState.Requested && !(!!reo.dispositionStatus) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.intendedUsageType']?.required == MortgageFieldRequiredState.Requested && !(!!reo.intendedUsageType) && reo.dispositionStatus != 'Sold' ||
          mortgageFieldsConfig['mortgage.realEstateOwned.isSubjectProperty']?.required == MortgageFieldRequiredState.Requested && (reo.isSubjectProperty == null || reo.isSubjectProperty == undefined) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.assetAccountId']?.required == MortgageFieldRequiredState.Requested && !(!!reo.assetAccountId) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.marketValue']?.required == MortgageFieldRequiredState.Requested && (reo.marketValue == null || reo.marketValue == undefined) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.monthlyMiscExpenses']?.required == MortgageFieldRequiredState.Requested && (reo.monthlyMiscExpenses == null || reo.monthlyMiscExpenses == undefined) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.percentOwned']?.required == MortgageFieldRequiredState.Requested && (reo.percentOwned == null || reo.percentOwned == undefined || reo.percentOwned.toString() == '') && this.checkIfInvestmentOrTwoToFourUnitProperty(reo) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.vacancyFactor']?.required == MortgageFieldRequiredState.Requested && (reo.vacancyFactor == null || reo.vacancyFactor == undefined || reo.vacancyFactor.toString() == '') && this.checkIfInvestmentOrTwoToFourUnitProperty(reo) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.grossRentalIncome']?.required == MortgageFieldRequiredState.Requested && (reo.grossRentalIncome == null || reo.grossRentalIncome == undefined) && this.checkIfInvestmentOrTwoToFourUnitProperty(reo) ||
          mortgageFieldsConfig['mortgage.realEstateOwned.netRentalIncome']?.required == MortgageFieldRequiredState.Requested && (reo.netRentalIncome == null || reo.netRentalIncome == undefined) && this.checkIfInvestmentOrTwoToFourUnitProperty(reo)
        )) {
          state = MenuItemStatus.Pending;
        }
      })
    }

    return state;
  }

  private isSalesPriceExceedsAppraisedValue = (mortgage: UrlaMortgage) => {
    return (mortgage.mortgageTerm.mortgageAppliedFor === 'VA' || mortgage.mortgageTerm.mortgageAppliedFor === 'FHA') &&
      this._calculationService.isPurposeOfLoanPurchase(mortgage) && mortgage.transactionDetail.purchasePriceAmount && mortgage.mortgageTerm.appraisedValue &&
      mortgage.transactionDetail.purchasePriceAmount > mortgage.mortgageTerm.appraisedValue;
  }

  getStatusForLoanProperty = (mortgageFieldsConfig: MortgageModelFieldsConfig, mortgage: UrlaMortgage, isAppraisedValueHidden: boolean) => {
    const isReverseMortgage = mortgage.isReverseMortgage;

    let state = MenuItemStatus.Success;

    let loanPropertyInfoState = !isReverseMortgage ? this.getStatusForLoanPropertyInfo(mortgageFieldsConfig, mortgage, isAppraisedValueHidden) 
      : this.getStatusForReverseMortgagePropertyInfo(mortgageFieldsConfig, mortgage, isAppraisedValueHidden);
    let otherNewMortgageLoansState = this.getStatusForOtherNewMortgageLoans(mortgageFieldsConfig, mortgage);
    let rentalIncomeOnPurchasesState = this.getStatusForRentalIncomeOnPurchases(mortgageFieldsConfig, mortgage);
    let giftOrGrantsState = this.getStatusForGiftOrGrants(mortgageFieldsConfig, mortgage);

    if (loanPropertyInfoState == MenuItemStatus.Error || otherNewMortgageLoansState == MenuItemStatus.Error ||
      rentalIncomeOnPurchasesState == MenuItemStatus.Error || giftOrGrantsState == MenuItemStatus.Error) {
      state = MenuItemStatus.Error;
    }
    else if (loanPropertyInfoState == MenuItemStatus.Pending || otherNewMortgageLoansState == MenuItemStatus.Pending ||
      rentalIncomeOnPurchasesState == MenuItemStatus.Pending || giftOrGrantsState == MenuItemStatus.Pending) {
      state = MenuItemStatus.Pending;
    }

    return state;
  }

  getStatusForLoanPropertyInfo = (mortgageFieldsConfig: MortgageModelFieldsConfig, mortgage: UrlaMortgage, isAppraisedValueHidden: boolean) => {
    let state = MenuItemStatus.Success;

    if (
      (mortgageFieldsConfig['mortgage.mortgageTerm.amount']?.required == MortgageFieldRequiredState.Required && (mortgage.mortgageTerm.amount == null || mortgage.mortgageTerm.amount == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.purposeOfLoan']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.purposeOfLoan)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.refiPurpose']?.required == MortgageFieldRequiredState.Required && this._calculationService.isPurposeOfLoanRefinance(mortgage) && !(!!mortgage.subjectProperty.refiPurpose)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.refinancePrimaryPurpose']?.required == MortgageFieldRequiredState.Required && this._calculationService.isPurposeOfLoanRefinance(mortgage) && !(!!mortgage.subjectProperty.refinancePrimaryPurpose)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.propertyWillBe']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.propertyWillBe)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.address1']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.address1)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.address2']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.address2)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.city']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.city)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.state']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.state)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.zipCode']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.zipCode)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.county']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.county)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.noOfUnits']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.noOfUnits == null || mortgage.subjectProperty.noOfUnits == undefined || mortgage.subjectProperty.noOfUnits.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.constructionMethod']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.constructionMethod)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.constructionMethodOtherDescription']?.required == MortgageFieldRequiredState.Required && mortgage.subjectProperty.constructionMethod == 'Other' && !(!!mortgage.subjectProperty.constructionMethodOtherDescription)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.constructionStatus']?.required == MortgageFieldRequiredState.Required && this._calculationService.isPurposeOfLoanConstructionToPerm(mortgage) && !(!!mortgage.subjectProperty.constructionStatus)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.isFhaSecondaryResidence']?.required == MortgageFieldRequiredState.Required && this._calculationService.isFhaLoan(mortgage) && (mortgage.subjectProperty.isFhaSecondaryResidence == null || mortgage.subjectProperty.isFhaSecondaryResidence == undefined)) ||
      (mortgageFieldsConfig['mortgage.transactionDetail.purchasePriceAmount']?.required == MortgageFieldRequiredState.Required && this._calculationService.isPurposeOfLoanPurchase(mortgage) && (mortgage.transactionDetail.purchasePriceAmount == null || mortgage.transactionDetail.purchasePriceAmount == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.presentValue']?.required == MortgageFieldRequiredState.Required && !this._calculationService.isPurposeOfLoanPurchase(mortgage) && (mortgage.subjectProperty.presentValue == null || mortgage.subjectProperty.presentValue == undefined)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.appraisedValue']?.required == MortgageFieldRequiredState.Required && !isAppraisedValueHidden && (mortgage.mortgageTerm.appraisedValue == null || mortgage.mortgageTerm.appraisedValue == undefined)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.isMixedUseProperty == null || mortgage.subjectProperty.isMixedUseProperty == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.constructionLoanPurpose']?.required == MortgageFieldRequiredState.Required && ['ConstructionOnly', 'ConstructionToPermanent'].includes(mortgage.subjectProperty.purposeOfLoan) && !(!!mortgage.subjectProperty.constructionLoanPurpose)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType']?.required == MortgageFieldRequiredState.Required && ['ConstructionOnly', 'ConstructionToPermanent'].includes(mortgage.subjectProperty.purposeOfLoan) && !(!!mortgage.subjectProperty.constructionToPermanentClosingType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.originalCost']?.required == MortgageFieldRequiredState.Required && this._calculationService.isPurposeOfLoanRefinance(mortgage) && (mortgage.subjectProperty.originalCost == null || mortgage.subjectProperty.originalCost == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.originalCostYear']?.required == MortgageFieldRequiredState.Required && this._calculationService.isPurposeOfLoanRefinance(mortgage) && (mortgage.subjectProperty.originalCostYear == null || mortgage.subjectProperty.originalCostYear == undefined))
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (
      (mortgageFieldsConfig['mortgage.mortgageTerm.amount']?.required == MortgageFieldRequiredState.Requested && (mortgage.mortgageTerm.amount == null || mortgage.mortgageTerm.amount == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.purposeOfLoan']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.purposeOfLoan)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.refiPurpose']?.required == MortgageFieldRequiredState.Requested && this._calculationService.isPurposeOfLoanRefinance(mortgage) && !(!!mortgage.subjectProperty.refiPurpose)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.refinancePrimaryPurpose']?.required == MortgageFieldRequiredState.Requested && this._calculationService.isPurposeOfLoanRefinance(mortgage) && !(!!mortgage.subjectProperty.refinancePrimaryPurpose)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.propertyWillBe']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.propertyWillBe)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.address1']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.address1)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.address2']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.address2)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.city']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.city)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.state']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.state)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.zipCode']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.zipCode)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.county']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.county)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.noOfUnits']?.required == MortgageFieldRequiredState.Requested && (mortgage.subjectProperty.noOfUnits == null || mortgage.subjectProperty.noOfUnits == undefined || mortgage.subjectProperty.noOfUnits.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.constructionMethod']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.constructionMethod)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.constructionMethodOtherDescription']?.required == MortgageFieldRequiredState.Requested && mortgage.subjectProperty.constructionMethod == 'Other' && !(!!mortgage.subjectProperty.constructionMethodOtherDescription)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.constructionStatus']?.required == MortgageFieldRequiredState.Requested && this._calculationService.isPurposeOfLoanConstructionToPerm(mortgage) && !(!!mortgage.subjectProperty.constructionStatus)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.isFhaSecondaryResidence']?.required == MortgageFieldRequiredState.Requested && this._calculationService.isFhaLoan(mortgage) && (mortgage.subjectProperty.isFhaSecondaryResidence == null || mortgage.subjectProperty.isFhaSecondaryResidence == undefined)) ||
      (mortgageFieldsConfig['mortgage.transactionDetail.purchasePriceAmount']?.required == MortgageFieldRequiredState.Requested && this._calculationService.isPurposeOfLoanPurchase(mortgage) && (mortgage.transactionDetail.purchasePriceAmount == null || mortgage.transactionDetail.purchasePriceAmount == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.presentValue']?.required == MortgageFieldRequiredState.Requested && !this._calculationService.isPurposeOfLoanPurchase(mortgage) && (mortgage.subjectProperty.presentValue == null || mortgage.subjectProperty.presentValue == undefined)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.appraisedValue']?.required == MortgageFieldRequiredState.Requested && !isAppraisedValueHidden && (mortgage.mortgageTerm.appraisedValue == null || mortgage.mortgageTerm.appraisedValue == undefined)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty']?.required == MortgageFieldRequiredState.Requested && (mortgage.subjectProperty.isMixedUseProperty == null || mortgage.subjectProperty.isMixedUseProperty == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.constructionLoanPurpose']?.required == MortgageFieldRequiredState.Requested && ['ConstructionOnly', 'ConstructionToPermanent'].includes(mortgage.subjectProperty.purposeOfLoan) && !(!!mortgage.subjectProperty.constructionLoanPurpose)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType']?.required == MortgageFieldRequiredState.Requested && ['ConstructionOnly', 'ConstructionToPermanent'].includes(mortgage.subjectProperty.purposeOfLoan) && !(!!mortgage.subjectProperty.constructionToPermanentClosingType))||
      (mortgageFieldsConfig['mortgage.subjectProperty.originalCost']?.required == MortgageFieldRequiredState.Requested && this._calculationService.isPurposeOfLoanRefinance(mortgage) && (mortgage.subjectProperty.originalCost == null || mortgage.subjectProperty.originalCost == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.originalCostYear']?.required == MortgageFieldRequiredState.Requested && this._calculationService.isPurposeOfLoanRefinance(mortgage) && (mortgage.subjectProperty.originalCostYear == null || mortgage.subjectProperty.originalCostYear == undefined))
    ) {
      state = MenuItemStatus.Pending;
    }

    if (this.isSalesPriceExceedsAppraisedValue(mortgage)) {
      if (mortgage.borrowers && mortgage.borrowers.length > 0) {
        mortgage.borrowers.forEach(borrower => {
          if (
            mortgageFieldsConfig['mortgage.borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType']?.required == MortgageFieldRequiredState.Required && (borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType == null || borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType == undefined)
          ) {
            state = MenuItemStatus.Error;
            return state;
          }
          if (
            mortgageFieldsConfig['mortgage.borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType']?.required == MortgageFieldRequiredState.Requested && (borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType == null || borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType == undefined)
          ) {
            state = MenuItemStatus.Pending;
          }
        });
      }
    }

    return state;
  }

  getStatusForOtherNewMortgageLoans = (mortgageFieldsConfig: MortgageModelFieldsConfig, mortgage: UrlaMortgage): MenuItemStatus => {
    let state = MenuItemStatus.Success;

    if (mortgage.relatedMortgages && mortgage.relatedMortgages.length > 0) {
      mortgage.relatedMortgages.forEach(relatedMortgage => {
        if (
          (mortgageFieldsConfig['mortgage.relatedMortgage.lienType']?.required == MortgageFieldRequiredState.Required && !(!!relatedMortgage.lienType)) ||
          (mortgageFieldsConfig['mortgage.relatedMortgage.monthlyPayment']?.required == MortgageFieldRequiredState.Required && (relatedMortgage.monthlyPayment == null || relatedMortgage.monthlyPayment == undefined)) ||
          (mortgageFieldsConfig['mortgage.relatedMortgage.loanOrDrawAmount']?.required == MortgageFieldRequiredState.Required && (relatedMortgage.loanOrDrawAmount == null || relatedMortgage.loanOrDrawAmount == undefined)) ||
          (mortgageFieldsConfig['mortgage.relatedMortgage.creditLimit']?.required == MortgageFieldRequiredState.Required && (relatedMortgage.creditLimit == null || relatedMortgage.creditLimit == undefined)) ||
          (mortgageFieldsConfig['mortgage.relatedMortgage.isHeloc']?.required == MortgageFieldRequiredState.Required && (relatedMortgage.isHeloc == null || relatedMortgage.isHeloc == undefined))
        ) {
          state = MenuItemStatus.Error;
        }

        if ((state != MenuItemStatus.Error) && (
          mortgageFieldsConfig['mortgage.relatedMortgage.lienType']?.required == MortgageFieldRequiredState.Requested && !(!!relatedMortgage.lienType) ||
          (mortgageFieldsConfig['mortgage.relatedMortgage.monthlyPayment']?.required == MortgageFieldRequiredState.Requested && (relatedMortgage.monthlyPayment == null || relatedMortgage.monthlyPayment == undefined)) ||
          (mortgageFieldsConfig['mortgage.relatedMortgage.loanOrDrawAmount']?.required == MortgageFieldRequiredState.Requested && (relatedMortgage.loanOrDrawAmount == null || relatedMortgage.loanOrDrawAmount == undefined)) ||
          (mortgageFieldsConfig['mortgage.relatedMortgage.creditLimit']?.required == MortgageFieldRequiredState.Requested && (relatedMortgage.creditLimit == null || relatedMortgage.creditLimit == undefined)) ||
          (mortgageFieldsConfig['mortgage.relatedMortgage.isHeloc']?.required == MortgageFieldRequiredState.Requested && (relatedMortgage.isHeloc == null || relatedMortgage.isHeloc == undefined))
        )) {
          state = MenuItemStatus.Pending;
        }
      });
    }
    return state;
  }

  getStatusForRentalIncomeOnPurchases = (mortgageFieldsConfig: MortgageModelFieldsConfig, mortgage: UrlaMortgage) => {
    let state = MenuItemStatus.Success;

    let borrower;

    if (mortgage.subjectProperty &&
      (mortgage.subjectProperty.purposeOfLoan == this._loanPurposePurchaseEnumValue &&
        (mortgage.subjectProperty.propertyWillBe == this._propertyWillBeInvestmentEnumValue ||
          mortgage.subjectProperty.propertyWillBe == this._propertyWillBeSecondaryEnumValue ||
          (mortgage.subjectProperty.noOfUnits > 1 && mortgage.subjectProperty.propertyWillBe == this._propertyWillBePrimaryEnumValue)
        )
      )) {

      borrower = mortgage.borrowers.find(b => b.typeOfBorrower === BorrowerType.PrimaryBorrower);
      if (!borrower && mortgage.borrowers.length) {
        borrower = mortgage.borrowers[0];
      }

      if (
        (mortgageFieldsConfig['mortgage.borrower.hasOneYearLandlordExperience']?.required == MortgageFieldRequiredState.Required && (borrower.hasOneYearLandlordExperience == null || borrower.hasOneYearLandlordExperience == undefined)) ||
        (mortgageFieldsConfig['mortgage.subjectProperty.expectedGrossMonthlyRentalIncome']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.expectedGrossMonthlyRentalIncome == null || mortgage.subjectProperty.expectedGrossMonthlyRentalIncome == undefined)) ||
        (mortgageFieldsConfig['mortgage.subjectProperty.expectedNetMonthlyRentalIncome']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.expectedNetMonthlyRentalIncome == null || mortgage.subjectProperty.expectedNetMonthlyRentalIncome == undefined))
      ) {
        state = MenuItemStatus.Error;
        return state;
      }
      if (
        mortgageFieldsConfig['mortgage.borrower.hasOneYearLandlordExperience']?.required == MortgageFieldRequiredState.Requested && (borrower.hasOneYearLandlordExperience == null || borrower.hasOneYearLandlordExperience == undefined) ||
        (mortgageFieldsConfig['mortgage.subjectProperty.expectedGrossMonthlyRentalIncome']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.expectedGrossMonthlyRentalIncome == null || mortgage.subjectProperty.expectedGrossMonthlyRentalIncome == undefined)) ||
        (mortgageFieldsConfig['mortgage.subjectProperty.expectedNetMonthlyRentalIncome']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.expectedNetMonthlyRentalIncome == null || mortgage.subjectProperty.expectedNetMonthlyRentalIncome == undefined)) ||
        mortgage.subjectProperty.propertyWillBe == "Investment" && mortgage.subjectProperty.expectedNetMonthlyRentalIncome > 0 && (borrower.hasOneYearLandlordExperience == null || borrower.hasOneYearLandlordExperience == undefined)
      ) {
        state = MenuItemStatus.Pending;
      }
    }

    return state;
  }

  getStatusForGiftOrGrants = (mortgageFieldsConfig: MortgageModelFieldsConfig, mortgage: UrlaMortgage) => {
    let state = MenuItemStatus.Success;

    let assets = mortgage.assets.filter(a => this.isFilteredAssetForGift(a.assetType));

    if (assets.length > 0) {
      let isError = assets.some(asset =>
        (mortgageFieldsConfig['mortgage.asset.assetType']?.required == MortgageFieldRequiredState.Required && !(!!asset.assetType)) ||
        (mortgageFieldsConfig['mortgage.asset.giftOrGrantSource']?.required == MortgageFieldRequiredState.Required && !(!!asset.giftOrGrantSource)) ||
        (mortgageFieldsConfig['mortgage.asset.isDeposited']?.required == MortgageFieldRequiredState.Required && (asset.isDeposited == null || asset.isDeposited == undefined)) ||
        (mortgageFieldsConfig['mortgage.asset.cashMarketValue']?.required == MortgageFieldRequiredState.Required && (asset.cashMarketValue == null || asset.cashMarketValue == undefined))
      );
      if (isError) {
        state = MenuItemStatus.Error;
        return state;
      }

      let isPending = assets.some(asset =>
        !asset.owningBorrowerIds || asset.owningBorrowerIds.length === 0 ||
        mortgageFieldsConfig['mortgage.asset.assetType']?.required == MortgageFieldRequiredState.Requested && !(!!asset.assetType) ||
        (mortgageFieldsConfig['mortgage.asset.giftOrGrantSource']?.required == MortgageFieldRequiredState.Requested && !(!!asset.giftOrGrantSource)) ||
        (mortgageFieldsConfig['mortgage.asset.isDeposited']?.required == MortgageFieldRequiredState.Requested && (asset.isDeposited == null || asset.isDeposited == undefined)) ||
        (mortgageFieldsConfig['mortgage.asset.cashMarketValue']?.required == MortgageFieldRequiredState.Requested && (asset.cashMarketValue == null || asset.cashMarketValue == undefined))
      );
      if (isPending) {
        state = MenuItemStatus.Pending;
      }
    }


    return state;
  }

  getStatusForDeclarations = (mortgageFieldsConfig: MortgageModelFieldsConfig, mortgage: UrlaMortgage): MenuItemStatus => {
    const isReverseMortgage = mortgage.isReverseMortgage;

    let state = MenuItemStatus.Success;

    let isPurchase = this._calculationService.isPurposeOfLoanPurchase(mortgage);
    if (mortgage.borrowers && mortgage.borrowers.length > 0) {
      mortgage.borrowers.forEach(borrower => {
        let decState = !isReverseMortgage ? this.getStatusForBorrowerDeclarations(mortgageFieldsConfig, borrower, isPurchase)
          : this.getStatusForReverseMortgageBorrowerDeclarations(mortgageFieldsConfig, borrower);
        if (state == MenuItemStatus.Error) {
          return;
        }
        else if (decState == MenuItemStatus.Error || decState == MenuItemStatus.Pending) {
          state = decState;
          return;
        }
      });
    }

    return state;
  }

  getStatusForBorrowerDeclarations = (mortgageFieldsConfig: MortgageModelFieldsConfig, borrower: MortgageBorrower, isPurchase: boolean) => {
    const isTitleOnlyOrNonTitledSpouse = (borrower.signingRole == this._titleOnlyEnumValue || borrower.signingRole == this._nonTitledSpouseEnumValue
      || borrower.signingRole == this._nonBorrowerHouseholdMemberEnumValue);

    let state = MenuItemStatus.Success;
    if (
      mortgageFieldsConfig['mortgage.borrower.declarations.occupyProperty']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.occupyProperty == null || borrower.declarations.occupyProperty == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.havePropertiesOwnership']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.declarations.occupyProperty && (borrower.declarations.havePropertiesOwnership == null || borrower.declarations.havePropertiesOwnership == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.typeOfProperty']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.declarations.havePropertiesOwnership && borrower.declarations.occupyProperty && !(!!borrower.declarations.typeOfProperty) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.heldTitleHow']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.declarations.havePropertiesOwnership && borrower.declarations.occupyProperty && !(!!borrower.declarations.heldTitleHow) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.isFirstTimeHomeBuyer']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.declarations.occupyProperty && (borrower.declarations.isFirstTimeHomeBuyer == null || borrower.declarations.isFirstTimeHomeBuyer == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.haveRelationshipWithSeller']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && isPurchase && (borrower.declarations.haveRelationshipWithSeller == null || borrower.declarations.haveRelationshipWithSeller == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.borrowedDownPayment']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.borrowedDownPayment == null || borrower.declarations.borrowedDownPayment == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.undisclosedMoneyAmount']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.declarations.borrowedDownPayment && (borrower.declarations.undisclosedMoneyAmount == null || borrower.declarations.undisclosedMoneyAmount == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.applyingForOtherMortgage']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.applyingForOtherMortgage == null || borrower.declarations.applyingForOtherMortgage == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.applyingForNonMortgageCredit']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.applyingForNonMortgageCredit == null || borrower.declarations.applyingForNonMortgageCredit == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.propertyHasLienWithCleanEnergyProgram']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.propertyHasLienWithCleanEnergyProgram == null || borrower.declarations.propertyHasLienWithCleanEnergyProgram == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.coSignedLoan']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.coSignedLoan == null || borrower.declarations.coSignedLoan == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.haveJudgement']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.haveJudgement == null || borrower.declarations.haveJudgement == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.haveTaxDebt']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.haveTaxDebt == null || borrower.declarations.haveTaxDebt == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.haveLawsuit']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.haveLawsuit == null || borrower.declarations.haveLawsuit == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.haveConveyedTitleInLieuOfForeclosure']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.haveConveyedTitleInLieuOfForeclosure == null || borrower.declarations.haveConveyedTitleInLieuOfForeclosure == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.haveCompletedShortSale']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.haveCompletedShortSale == null || borrower.declarations.haveCompletedShortSale == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.beenForclosed']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.beenForclosed == null || borrower.declarations.beenForclosed == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.declaredBankruptcy']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.declaredBankruptcy == null || borrower.declarations.declaredBankruptcy == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.bankruptcyType']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.declarations.declaredBankruptcy && !(!!borrower.declarations.bankruptcyType)
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (borrower.declarations.explanations && borrower.declarations.explanations.length > 0) {
      let isError = borrower.declarations.explanations.some(explanation =>
      (mortgageFieldsConfig['mortgage.borrower.declarations.explanation.description']?.required == MortgageFieldRequiredState.Required
        && !!explanation.explanationType
        && !(!!explanation.description))
      );
      if (isError && !isTitleOnlyOrNonTitledSpouse) {
        state = MenuItemStatus.Error;
        return state;
      }
    }
    if (
      mortgageFieldsConfig['mortgage.borrower.declarations.occupyProperty']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.occupyProperty == null || borrower.declarations.occupyProperty == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.havePropertiesOwnership']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.declarations.occupyProperty && (borrower.declarations.havePropertiesOwnership == null || borrower.declarations.havePropertiesOwnership == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.typeOfProperty']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.declarations.havePropertiesOwnership && borrower.declarations.occupyProperty && !(!!borrower.declarations.typeOfProperty) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.heldTitleHow']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.declarations.havePropertiesOwnership && borrower.declarations.occupyProperty && !(!!borrower.declarations.heldTitleHow) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.isFirstTimeHomeBuyer']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.declarations.occupyProperty && (borrower.declarations.isFirstTimeHomeBuyer == null || borrower.declarations.isFirstTimeHomeBuyer == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.haveRelationshipWithSeller']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && isPurchase && (borrower.declarations.haveRelationshipWithSeller == null || borrower.declarations.haveRelationshipWithSeller == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.borrowedDownPayment']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.borrowedDownPayment == null || borrower.declarations.borrowedDownPayment == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.undisclosedMoneyAmount']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.undisclosedMoneyAmount == null || borrower.declarations.undisclosedMoneyAmount == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.applyingForOtherMortgage']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.applyingForOtherMortgage == null || borrower.declarations.applyingForOtherMortgage == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.applyingForNonMortgageCredit']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.applyingForNonMortgageCredit == null || borrower.declarations.applyingForNonMortgageCredit == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.propertyHasLienWithCleanEnergyProgram']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.propertyHasLienWithCleanEnergyProgram == null || borrower.declarations.propertyHasLienWithCleanEnergyProgram == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.coSignedLoan']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.coSignedLoan == null || borrower.declarations.coSignedLoan == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.haveJudgement']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.haveJudgement == null || borrower.declarations.haveJudgement == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.haveTaxDebt']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.haveTaxDebt == null || borrower.declarations.haveTaxDebt == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.haveLawsuit']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.haveLawsuit == null || borrower.declarations.haveLawsuit == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.haveConveyedTitleInLieuOfForeclosure']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.haveConveyedTitleInLieuOfForeclosure == null || borrower.declarations.haveConveyedTitleInLieuOfForeclosure == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.haveCompletedShortSale']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.haveCompletedShortSale == null || borrower.declarations.haveCompletedShortSale == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.beenForclosed']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.beenForclosed == null || borrower.declarations.beenForclosed == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.declaredBankruptcy']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.declaredBankruptcy == null || borrower.declarations.declaredBankruptcy == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.bankruptcyType']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.declarations.declaredBankruptcy && !(!!borrower.declarations.bankruptcyType)
    ) {
      state = MenuItemStatus.Pending;
    }
    if (borrower.declarations.explanations && borrower.declarations.explanations.length > 0) {
      let isPending = borrower.declarations.explanations.some(explanation =>
      (mortgageFieldsConfig['mortgage.borrower.declarations.explanation.description']?.required == MortgageFieldRequiredState.Requested
        && !!explanation.explanationType
        && !(!!explanation.description))
      );
      if (isPending && !isTitleOnlyOrNonTitledSpouse) {
        state = MenuItemStatus.Pending;
      }
    }

    return state;
  }

  getStatusForReverseMortgageBorrowerDeclarations = (mortgageFieldsConfig: MortgageModelFieldsConfig, borrower: MortgageBorrower) => {
    const isTitleOnlyOrNonTitledSpouse = (borrower.signingRole == this._titleOnlyEnumValue || borrower.signingRole == this._nonTitledSpouseEnumValue
      || borrower.signingRole == this._nonBorrowerHouseholdMemberEnumValue);

    let state = MenuItemStatus.Success;
    if (
      mortgageFieldsConfig['mortgage.borrower.declarations.hasUnresolvedBankruptcy']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.hasUnresolvedBankruptcy == null || borrower.declarations.hasUnresolvedBankruptcy == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.wasRequiredToBringMoneyToClosing']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.wasRequiredToBringMoneyToClosing == null || borrower.declarations.wasRequiredToBringMoneyToClosing == undefined)
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (borrower.reverseFinancialProductsToBePurchaseds && borrower.reverseFinancialProductsToBePurchaseds.length > 0) {
      let isError = borrower.reverseFinancialProductsToBePurchaseds.some(reverseFinancialProduct =>
      (mortgageFieldsConfig['mortgage.borrower.reverseFinancialProductsToBePurchaseds.productName']?.required == MortgageFieldRequiredState.Required && !(!!reverseFinancialProduct.productName) ||
        mortgageFieldsConfig['mortgage.borrower.reverseFinancialProductsToBePurchaseds.costOfPurchase']?.required == MortgageFieldRequiredState.Required && (reverseFinancialProduct.costOfPurchase == null || reverseFinancialProduct.costOfPurchase == undefined))
      );
      if (isError && !isTitleOnlyOrNonTitledSpouse) {
        state = MenuItemStatus.Error;
        return state;
      }
    }
    if (borrower.reverseExistingFHAInsuredLoans && borrower.reverseExistingFHAInsuredLoans.length > 0) {
      let isError = borrower.reverseExistingFHAInsuredLoans.some(reverseFHALoan =>
      (mortgageFieldsConfig['mortgage.borrower.reverseExistingFHAInsuredLoans.creditorName']?.required == MortgageFieldRequiredState.Required && !(!!reverseFHALoan.creditorName) ||
        mortgageFieldsConfig['mortgage.borrower.reverseExistingFHAInsuredLoans.reoId']?.required == MortgageFieldRequiredState.Required && (reverseFHALoan.reoId == null || reverseFHALoan.reoId == undefined) ||
        mortgageFieldsConfig['mortgage.borrower.reverseExistingFHAInsuredLoans.accountNumber']?.required == MortgageFieldRequiredState.Required && !(!!reverseFHALoan.accountNumber) ||
        mortgageFieldsConfig['mortgage.borrower.reverseExistingFHAInsuredLoans.mortgageAmount']?.required == MortgageFieldRequiredState.Required && (reverseFHALoan.mortgageAmount == null || reverseFHALoan.mortgageAmount == undefined) ||
        mortgageFieldsConfig['mortgage.borrower.reverseExistingFHAInsuredLoans.unpaidBalance']?.required == MortgageFieldRequiredState.Required && (reverseFHALoan.unpaidBalance == null || reverseFHALoan.unpaidBalance == undefined)
      )
      );
      if (isError && !isTitleOnlyOrNonTitledSpouse) {
        state = MenuItemStatus.Error;
        return state;
      }
    }

    if (
      mortgageFieldsConfig['mortgage.borrower.declarations.hasUnresolvedBankruptcy']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.hasUnresolvedBankruptcy == null || borrower.declarations.hasUnresolvedBankruptcy == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.declarations.wasRequiredToBringMoneyToClosing']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (borrower.declarations.wasRequiredToBringMoneyToClosing == null || borrower.declarations.wasRequiredToBringMoneyToClosing == undefined)
    ) {
      state = MenuItemStatus.Pending;
      return state;
    }
    if (borrower.reverseFinancialProductsToBePurchaseds && borrower.reverseFinancialProductsToBePurchaseds.length > 0) {
      let isError = borrower.reverseFinancialProductsToBePurchaseds.some(reverseFinancialProduct =>
      (mortgageFieldsConfig['mortgage.borrower.reverseFinancialProductsToBePurchaseds.productName']?.required == MortgageFieldRequiredState.Requested && !(!!reverseFinancialProduct.productName) ||
        mortgageFieldsConfig['mortgage.borrower.reverseFinancialProductsToBePurchaseds.costOfPurchase']?.required == MortgageFieldRequiredState.Requested && (reverseFinancialProduct.costOfPurchase == null || reverseFinancialProduct.costOfPurchase == undefined))
      );
      if (isError && !isTitleOnlyOrNonTitledSpouse) {
        state = MenuItemStatus.Pending;
        return state;
      }
    }
    if (borrower.reverseExistingFHAInsuredLoans && borrower.reverseExistingFHAInsuredLoans.length > 0) {
      let isError = borrower.reverseExistingFHAInsuredLoans.some(reverseFHALoan =>
      (mortgageFieldsConfig['mortgage.borrower.reverseExistingFHAInsuredLoans.creditorName']?.required == MortgageFieldRequiredState.Requested && !(!!reverseFHALoan.creditorName) ||
        mortgageFieldsConfig['mortgage.borrower.reverseExistingFHAInsuredLoans.reoId']?.required == MortgageFieldRequiredState.Requested && (reverseFHALoan.reoId == null || reverseFHALoan.reoId == undefined) ||
        mortgageFieldsConfig['mortgage.borrower.reverseExistingFHAInsuredLoans.accountNumber']?.required == MortgageFieldRequiredState.Requested && !(!!reverseFHALoan.accountNumber) ||
        mortgageFieldsConfig['mortgage.borrower.reverseExistingFHAInsuredLoans.mortgageAmount']?.required == MortgageFieldRequiredState.Requested && (reverseFHALoan.mortgageAmount == null || reverseFHALoan.mortgageAmount == undefined) ||
        mortgageFieldsConfig['mortgage.borrower.reverseExistingFHAInsuredLoans.unpaidBalance']?.required == MortgageFieldRequiredState.Requested && (reverseFHALoan.unpaidBalance == null || reverseFHALoan.unpaidBalance == undefined)
      )
      );
      if (isError && !isTitleOnlyOrNonTitledSpouse) {
        state = MenuItemStatus.Pending;
        return state;
      }
    }

    return state;
  }

  getStatusForReverseMortgagePropertyInfo = (mortgageFieldsConfig: MortgageModelFieldsConfig, mortgage: UrlaMortgage, isAppraisedValueHidden: boolean) => {
    let state = MenuItemStatus.Success;
    if (
      (mortgageFieldsConfig['mortgage.mortgageTerm.amount']?.required == MortgageFieldRequiredState.Required && (mortgage.mortgageTerm.amount == null || mortgage.mortgageTerm.amount == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.purposeOfLoan']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.purposeOfLoan)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.refiPurpose']?.required == MortgageFieldRequiredState.Required && this._calculationService.isPurposeOfLoanRefinance(mortgage) && !(!!mortgage.subjectProperty.refiPurpose)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.refinancePrimaryPurpose']?.required == MortgageFieldRequiredState.Required && this._calculationService.isPurposeOfLoanRefinance(mortgage) && !(!!mortgage.subjectProperty.refinancePrimaryPurpose)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.propertyWillBe']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.propertyWillBe)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.address1']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.address1)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.address2']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.address2)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.city']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.city)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.state']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.state)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.zipCode']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.zipCode)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.county']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.county)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.noOfUnits']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.noOfUnits == null || mortgage.subjectProperty.noOfUnits == undefined || mortgage.subjectProperty.noOfUnits.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.constructionMethod']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.constructionMethod)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.constructionMethodOtherDescription']?.required == MortgageFieldRequiredState.Required && mortgage.subjectProperty.constructionMethod == 'Other' && !(!!mortgage.subjectProperty.constructionMethodOtherDescription)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.constructionStatus']?.required == MortgageFieldRequiredState.Required && this._calculationService.isPurposeOfLoanConstructionToPerm(mortgage) && !(!!mortgage.subjectProperty.constructionStatus)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.isFhaSecondaryResidence']?.required == MortgageFieldRequiredState.Required && this._calculationService.isFhaLoan(mortgage) && (mortgage.subjectProperty.isFhaSecondaryResidence == null || mortgage.subjectProperty.isFhaSecondaryResidence == undefined)) ||
      (mortgageFieldsConfig['mortgage.transactionDetail.purchasePriceAmount']?.required == MortgageFieldRequiredState.Required && this._calculationService.isPurposeOfLoanPurchase(mortgage) && (mortgage.transactionDetail.purchasePriceAmount == null || mortgage.transactionDetail.purchasePriceAmount == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.presentValue']?.required == MortgageFieldRequiredState.Required && !this._calculationService.isPurposeOfLoanPurchase(mortgage) && (mortgage.subjectProperty.presentValue == null || mortgage.subjectProperty.presentValue == undefined)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.appraisedValue']?.required == MortgageFieldRequiredState.Required && !isAppraisedValueHidden && (mortgage.mortgageTerm.appraisedValue == null || mortgage.mortgageTerm.appraisedValue == undefined)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.isMixedUseProperty == null || mortgage.subjectProperty.isMixedUseProperty == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.constructionLoanPurpose']?.required == MortgageFieldRequiredState.Required && ['ConstructionOnly', 'ConstructionToPermanent'].includes(mortgage.subjectProperty.purposeOfLoan) && !(!!mortgage.subjectProperty.constructionLoanPurpose)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType']?.required == MortgageFieldRequiredState.Required && ['ConstructionOnly', 'ConstructionToPermanent'].includes(mortgage.subjectProperty.purposeOfLoan) && !(!!mortgage.subjectProperty.constructionToPermanentClosingType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.originalCost']?.required == MortgageFieldRequiredState.Required && this._calculationService.isPurposeOfLoanRefinance(mortgage) && (mortgage.subjectProperty.originalCost == null || mortgage.subjectProperty.originalCost == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.originalCostYear']?.required == MortgageFieldRequiredState.Required && this._calculationService.isPurposeOfLoanRefinance(mortgage) && (mortgage.subjectProperty.originalCostYear == null || mortgage.subjectProperty.originalCostYear == undefined)) || 
      (mortgageFieldsConfig['mortgage.subjectProperty.isPlannedUnitDevelopment']?.required == MortgageFieldRequiredState.Required && this._calculationService.isPurposeOfLoanRefinance(mortgage) && (mortgage.subjectProperty.isPlannedUnitDevelopment == null || mortgage.subjectProperty.isPlannedUnitDevelopment == undefined)) || 
      (mortgageFieldsConfig['mortgage.subjectProperty.isPropertyNotInAProject']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.isPropertyNotInAProject == null || mortgage.subjectProperty.isPropertyNotInAProject == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.projectType']?.required == MortgageFieldRequiredState.Required && mortgage.subjectProperty.isPropertyNotInAProject == false && !(!!mortgage.subjectProperty.projectType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.projectDesignType']?.required == MortgageFieldRequiredState.Required && mortgage.subjectProperty && (mortgage.subjectProperty.projectType == this._projectTypeCondominium) && !(!!mortgage.subjectProperty.projectDesignType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.projectName']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.projectType || mortgage.subjectProperty.isPlannedUnitDevelopment) && !(!!mortgage.subjectProperty.projectName)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.projectClassificationIdentifier']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.projectType) && !(!!mortgage.subjectProperty.projectClassificationIdentifier)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.condominiumProjectStatus']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.projectType) && !(!!mortgage.subjectProperty.condominiumProjectStatus)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.projectType) && !(!!mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.msaIdentifier']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.msaIdentifier)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.attachmentType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.attachmentType)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.remainingEconomicLife']?.required == MortgageFieldRequiredState.Required && (mortgage.reverseExtension.remainingEconomicLife == null || mortgage.reverseExtension.remainingEconomicLife == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.aboveGradeInSqtFeet']?.required == MortgageFieldRequiredState.Required && (mortgage.reverseExtension.aboveGradeInSqtFeet == null || mortgage.reverseExtension.aboveGradeInSqtFeet == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.hasWell']?.required == MortgageFieldRequiredState.Required && (mortgage.reverseExtension.hasWell == null || mortgage.reverseExtension.hasWell == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.hasSepticTank']?.required == MortgageFieldRequiredState.Required && (mortgage.reverseExtension.hasSepticTank == null || mortgage.reverseExtension.hasSepticTank == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.thereAreChildrenUnderSix']?.required == MortgageFieldRequiredState.Required && (mortgage.reverseExtension.thereAreChildrenUnderSix == null || mortgage.reverseExtension.thereAreChildrenUnderSix == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.isRuralProperty']?.required == MortgageFieldRequiredState.Required && (mortgage.reverseExtension.isRuralProperty == null || mortgage.reverseExtension.isRuralProperty == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.isInDecliningMarket']?.required == MortgageFieldRequiredState.Required && (mortgage.reverseExtension.isInDecliningMarket == null || mortgage.reverseExtension.isInDecliningMarket == undefined))
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (
      (mortgageFieldsConfig['mortgage.mortgageTerm.amount']?.required == MortgageFieldRequiredState.Requested && (mortgage.mortgageTerm.amount == null || mortgage.mortgageTerm.amount == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.purposeOfLoan']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.purposeOfLoan)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.refiPurpose']?.required == MortgageFieldRequiredState.Requested && this._calculationService.isPurposeOfLoanRefinance(mortgage) && !(!!mortgage.subjectProperty.refiPurpose)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.refinancePrimaryPurpose']?.required == MortgageFieldRequiredState.Requested && this._calculationService.isPurposeOfLoanRefinance(mortgage) && !(!!mortgage.subjectProperty.refinancePrimaryPurpose)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.propertyWillBe']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.propertyWillBe)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.address1']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.address1)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.address2']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.address2)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.city']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.city)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.state']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.state)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.zipCode']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.zipCode)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.county']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.county)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.noOfUnits']?.required == MortgageFieldRequiredState.Requested && (mortgage.subjectProperty.noOfUnits == null || mortgage.subjectProperty.noOfUnits == undefined || mortgage.subjectProperty.noOfUnits.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.constructionMethod']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.constructionMethod)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.constructionMethodOtherDescription']?.required == MortgageFieldRequiredState.Requested && mortgage.subjectProperty.constructionMethod == 'Other' && !(!!mortgage.subjectProperty.constructionMethodOtherDescription)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.constructionStatus']?.required == MortgageFieldRequiredState.Requested && this._calculationService.isPurposeOfLoanConstructionToPerm(mortgage) && !(!!mortgage.subjectProperty.constructionStatus)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.isFhaSecondaryResidence']?.required == MortgageFieldRequiredState.Requested && this._calculationService.isFhaLoan(mortgage) && (mortgage.subjectProperty.isFhaSecondaryResidence == null || mortgage.subjectProperty.isFhaSecondaryResidence == undefined)) ||
      (mortgageFieldsConfig['mortgage.transactionDetail.purchasePriceAmount']?.required == MortgageFieldRequiredState.Requested && this._calculationService.isPurposeOfLoanPurchase(mortgage) && (mortgage.transactionDetail.purchasePriceAmount == null || mortgage.transactionDetail.purchasePriceAmount == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.presentValue']?.required == MortgageFieldRequiredState.Requested && !this._calculationService.isPurposeOfLoanPurchase(mortgage) && (mortgage.subjectProperty.presentValue == null || mortgage.subjectProperty.presentValue == undefined)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.appraisedValue']?.required == MortgageFieldRequiredState.Requested && !isAppraisedValueHidden && (mortgage.mortgageTerm.appraisedValue == null || mortgage.mortgageTerm.appraisedValue == undefined)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.isMixedUseProperty']?.required == MortgageFieldRequiredState.Requested && (mortgage.subjectProperty.isMixedUseProperty == null || mortgage.subjectProperty.isMixedUseProperty == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.constructionLoanPurpose']?.required == MortgageFieldRequiredState.Requested && ['ConstructionOnly', 'ConstructionToPermanent'].includes(mortgage.subjectProperty.purposeOfLoan) && !(!!mortgage.subjectProperty.constructionLoanPurpose)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType']?.required == MortgageFieldRequiredState.Requested && ['ConstructionOnly', 'ConstructionToPermanent'].includes(mortgage.subjectProperty.purposeOfLoan) && !(!!mortgage.subjectProperty.constructionToPermanentClosingType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.originalCost']?.required == MortgageFieldRequiredState.Requested && this._calculationService.isPurposeOfLoanRefinance(mortgage) && (mortgage.subjectProperty.originalCost == null || mortgage.subjectProperty.originalCost == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.originalCostYear']?.required == MortgageFieldRequiredState.Requested && this._calculationService.isPurposeOfLoanRefinance(mortgage) && (mortgage.subjectProperty.originalCostYear == null || mortgage.subjectProperty.originalCostYear == undefined)) || 
      (mortgageFieldsConfig['mortgage.subjectProperty.isPlannedUnitDevelopment']?.required == MortgageFieldRequiredState.Requested && this._calculationService.isPurposeOfLoanRefinance(mortgage) && (mortgage.subjectProperty.isPlannedUnitDevelopment == null || mortgage.subjectProperty.isPlannedUnitDevelopment == undefined)) || 
      (mortgageFieldsConfig['mortgage.subjectProperty.isPropertyNotInAProject']?.required == MortgageFieldRequiredState.Requested && (mortgage.subjectProperty.isPropertyNotInAProject == null || mortgage.subjectProperty.isPropertyNotInAProject == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.projectType']?.required == MortgageFieldRequiredState.Requested && mortgage.subjectProperty.isPropertyNotInAProject == false && !(!!mortgage.subjectProperty.projectType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.projectDesignType']?.required == MortgageFieldRequiredState.Requested && mortgage.subjectProperty && (mortgage.subjectProperty.projectType == this._projectTypeCondominium) && !(!!mortgage.subjectProperty.projectDesignType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.projectName']?.required == MortgageFieldRequiredState.Requested && (mortgage.subjectProperty.projectType || mortgage.subjectProperty.isPlannedUnitDevelopment) && !(!!mortgage.subjectProperty.projectName)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.projectClassificationIdentifier']?.required == MortgageFieldRequiredState.Requested && (mortgage.subjectProperty.projectType) && !(!!mortgage.subjectProperty.projectClassificationIdentifier)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.condominiumProjectStatus']?.required == MortgageFieldRequiredState.Requested && (mortgage.subjectProperty.projectType) && !(!!mortgage.subjectProperty.condominiumProjectStatus)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier']?.required == MortgageFieldRequiredState.Requested && (mortgage.subjectProperty.projectType) && !(!!mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.msaIdentifier']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.msaIdentifier)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.attachmentType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.attachmentType)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.remainingEconomicLife']?.required == MortgageFieldRequiredState.Required && (mortgage.reverseExtension.remainingEconomicLife == null || mortgage.reverseExtension.remainingEconomicLife == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.aboveGradeInSqtFeet']?.required == MortgageFieldRequiredState.Required && (mortgage.reverseExtension.aboveGradeInSqtFeet == null || mortgage.reverseExtension.aboveGradeInSqtFeet == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.hasWell']?.required == MortgageFieldRequiredState.Required && (mortgage.reverseExtension.hasWell == null || mortgage.reverseExtension.hasWell == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.hasSepticTank']?.required == MortgageFieldRequiredState.Required && (mortgage.reverseExtension.hasSepticTank == null || mortgage.reverseExtension.hasSepticTank == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.thereAreChildrenUnderSix']?.required == MortgageFieldRequiredState.Required && (mortgage.reverseExtension.thereAreChildrenUnderSix == null || mortgage.reverseExtension.thereAreChildrenUnderSix == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.isRuralProperty']?.required == MortgageFieldRequiredState.Required && (mortgage.reverseExtension.isRuralProperty == null || mortgage.reverseExtension.isRuralProperty == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.isInDecliningMarket']?.required == MortgageFieldRequiredState.Required && (mortgage.reverseExtension.isInDecliningMarket == null || mortgage.reverseExtension.isInDecliningMarket == undefined))
    ) {
      state = MenuItemStatus.Pending;
      return state;
    }

    if (this.isSalesPriceExceedsAppraisedValue(mortgage)) {
      if (mortgage.borrowers && mortgage.borrowers.length > 0) {
        mortgage.borrowers.forEach(borrower => {
          if (
            mortgageFieldsConfig['mortgage.borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType']?.required == MortgageFieldRequiredState.Required && (borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType == null || borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType == undefined)
          ) {
            state = MenuItemStatus.Error;
            return state;
          }
          if (
            mortgageFieldsConfig['mortgage.borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType']?.required == MortgageFieldRequiredState.Requested && (borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType == null || borrower.fhavaBorrowerCertificationSalesPriceExceedsAppraisedValueType == undefined)
          ) {
            state = MenuItemStatus.Pending;
          }
        });
      }
    }

    return state;
  }

  getStatusForReverseMortgageLoanInfo = (mortgageFieldsConfig: MortgageModelFieldsConfig, mortgage: UrlaMortgage) => {
    let state = MenuItemStatus.Success;

    const isArm = mortgage.mortgageTerm.amortizationType === AmortizationType.ARM;
    const isFixed = mortgage.mortgageTerm.amortizationType === AmortizationType.FixedRate;
    if (
      (mortgageFieldsConfig['mortgage.mortgageTerm.amortizationType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.mortgageTerm.amortizationType)) || 
      (mortgageFieldsConfig['mortgage.reverseExtension.armInitialIndex']?.required == MortgageFieldRequiredState.Required && isArm && (mortgage.reverseExtension.armInitialIndex == null || mortgage.reverseExtension.armInitialIndex == undefined)) || 
      (mortgageFieldsConfig['mortgage.reverseExtension.armExpectedIndex']?.required == MortgageFieldRequiredState.Required && isArm && (mortgage.reverseExtension.armExpectedIndex == null || mortgage.reverseExtension.armExpectedIndex == undefined)) || 
      (mortgageFieldsConfig['mortgage.reverseExtension.armInitialMargin']?.required == MortgageFieldRequiredState.Required && isArm && (mortgage.reverseExtension.armInitialMargin == null || mortgage.reverseExtension.armInitialMargin == undefined)) || 
      (mortgageFieldsConfig['mortgage.reverseExtension.armExpectedMargin']?.required == MortgageFieldRequiredState.Required && isArm && (mortgage.reverseExtension.armExpectedMargin == null || mortgage.reverseExtension.armExpectedMargin == undefined)) || 
      (mortgageFieldsConfig['mortgage.reverseExtension.armInitialRate']?.required == MortgageFieldRequiredState.Required && isArm && (mortgage.reverseExtension.armInitialRate == null || mortgage.reverseExtension.armInitialRate == undefined)) || 
      (mortgageFieldsConfig['mortgage.reverseExtension.armExpectedRate']?.required == MortgageFieldRequiredState.Required && isArm && (mortgage.reverseExtension.armExpectedRate == null || mortgage.reverseExtension.armExpectedRate == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.fixedRate']?.required == MortgageFieldRequiredState.Required && isFixed && (mortgage.reverseExtension.fixedRate == null || mortgage.reverseExtension.fixedRate == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.maxClaimAmount']?.required == MortgageFieldRequiredState.Required && (mortgage.reverseExtension.maxClaimAmount == null || mortgage.reverseExtension.maxClaimAmount == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.principalLimit']?.required == MortgageFieldRequiredState.Required && (mortgage.reverseExtension.principalLimit == null || mortgage.reverseExtension.principalLimit == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.principalLimitFactor']?.required == MortgageFieldRequiredState.Required && (mortgage.reverseExtension.principalLimitFactor == null || mortgage.reverseExtension.principalLimitFactor == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.upb']?.required == MortgageFieldRequiredState.Required && (mortgage.reverseExtension.upb == null || mortgage.reverseExtension.upb == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.plu']?.required == MortgageFieldRequiredState.Required && (mortgage.reverseExtension.plu == null || mortgage.reverseExtension.plu == undefined))
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (
      (mortgageFieldsConfig['mortgage.mortgageTerm.amortizationType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.mortgageTerm.amortizationType)) || 
      (mortgageFieldsConfig['mortgage.reverseExtension.armInitialIndex']?.required == MortgageFieldRequiredState.Requested && isArm && (mortgage.reverseExtension.armInitialIndex == null || mortgage.reverseExtension.armInitialIndex == undefined)) || 
      (mortgageFieldsConfig['mortgage.reverseExtension.armExpectedIndex']?.required == MortgageFieldRequiredState.Requested && isArm && (mortgage.reverseExtension.armExpectedIndex == null || mortgage.reverseExtension.armExpectedIndex == undefined)) || 
      (mortgageFieldsConfig['mortgage.reverseExtension.armInitialMargin']?.required == MortgageFieldRequiredState.Requested && isArm && (mortgage.reverseExtension.armInitialMargin == null || mortgage.reverseExtension.armInitialMargin == undefined)) || 
      (mortgageFieldsConfig['mortgage.reverseExtension.armExpectedMargin']?.required == MortgageFieldRequiredState.Requested && isArm && (mortgage.reverseExtension.armExpectedMargin == null || mortgage.reverseExtension.armExpectedMargin == undefined)) || 
      (mortgageFieldsConfig['mortgage.reverseExtension.armInitialRate']?.required == MortgageFieldRequiredState.Requested && isArm && (mortgage.reverseExtension.armInitialRate == null || mortgage.reverseExtension.armInitialRate == undefined)) || 
      (mortgageFieldsConfig['mortgage.reverseExtension.armExpectedRate']?.required == MortgageFieldRequiredState.Requested && isArm && (mortgage.reverseExtension.armExpectedRate == null || mortgage.reverseExtension.armExpectedRate == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.fixedRate']?.required == MortgageFieldRequiredState.Requested && isFixed && (mortgage.reverseExtension.fixedRate == null || mortgage.reverseExtension.fixedRate == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.maxClaimAmount']?.required == MortgageFieldRequiredState.Requested && (mortgage.reverseExtension.maxClaimAmount == null || mortgage.reverseExtension.maxClaimAmount == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.principalLimit']?.required == MortgageFieldRequiredState.Requested && (mortgage.reverseExtension.principalLimit == null || mortgage.reverseExtension.principalLimit == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.principalLimitFactor']?.required == MortgageFieldRequiredState.Requested && (mortgage.reverseExtension.principalLimitFactor == null || mortgage.reverseExtension.principalLimitFactor == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.upb']?.required == MortgageFieldRequiredState.Requested && (mortgage.reverseExtension.upb == null || mortgage.reverseExtension.upb == undefined)) ||
      (mortgageFieldsConfig['mortgage.reverseExtension.plu']?.required == MortgageFieldRequiredState.Requested && (mortgage.reverseExtension.plu == null || mortgage.reverseExtension.plu == undefined))
    ) {
      state = MenuItemStatus.Pending;
    }

    return state;
  }

  getStatusForMilitaryService = (mortgageFieldsConfig: MortgageModelFieldsConfig, mortgage: UrlaMortgage): MenuItemStatus => {
    let isVaBorrower = mortgage.mortgageTerm.mortgageAppliedFor === MortgageAppliedForTypeEnum.VA;

    let state = MenuItemStatus.Success;

    if (mortgage.borrowers && mortgage.borrowers.length > 0) {
      mortgage.borrowers.forEach(borrower => {
        let militaryState = this.getStatusForBorrowerMilitaryService(mortgageFieldsConfig, borrower, isVaBorrower);
        if (state == MenuItemStatus.Error) {
          return;
        }
        else if (militaryState == MenuItemStatus.Error || militaryState == MenuItemStatus.Pending) {
          state = militaryState;
          return;
        }
      });
    }

    return state;
  }

  getStatusForBorrowerMilitaryService = (mortgageFieldsConfig: MortgageModelFieldsConfig, borrower: MortgageBorrower, isVaBorrower: boolean) => {

    const isTitleOnlyOrNonTitledSpouse = (borrower.signingRole == this._titleOnlyEnumValue || borrower.signingRole == this._nonTitledSpouseEnumValue
      || borrower.signingRole == this._nonBorrowerHouseholdMemberEnumValue);

    let state = MenuItemStatus.Success;

    if (
      mortgageFieldsConfig['mortgage.borrower.hasServedInMilitary']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (borrower.hasServedInMilitary == null || borrower.hasServedInMilitary == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.isFirstUseOfVaBenefit']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && (borrower.isFirstUseOfVaBenefit == null || borrower.isFirstUseOfVaBenefit == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.isVaFundingFeeExempt']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && (borrower.isVaFundingFeeExempt == null || borrower.isVaFundingFeeExempt == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.isSurvivingSpouseOfMilitary']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && (borrower.isSurvivingSpouseOfMilitary == null || borrower.isSurvivingSpouseOfMilitary == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.militaryStatus']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && !(!!borrower.militaryStatus) ||
      mortgageFieldsConfig['mortgage.borrower.militaryStatusOtherDescription']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && borrower.militaryStatus === MilitaryStatus.Other && !(!!borrower.militaryStatusOtherDescription) ||
      mortgageFieldsConfig['mortgage.borrower.militaryTourStartDate']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && borrower.isActiveDutyMilitary && !(!!borrower.militaryTourStartDate) ||
      (borrower.hasServedInMilitary && borrower.isActiveDutyMilitary && borrower.militaryTourStartDate && this.checkIsInvalidDate(borrower.militaryTourStartDate)) ||
      mortgageFieldsConfig['mortgage.borrower.militaryTourEndDate']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && borrower.isActiveDutyMilitary && !(!!borrower.militaryTourEndDate) ||
      (borrower.hasServedInMilitary && borrower.isActiveDutyMilitary && borrower.militaryTourEndDate && this.checkIsInvalidDate(borrower.militaryTourEndDate)) ||
      mortgageFieldsConfig['mortgage.borrower.branchOfService']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && !(!!borrower.branchOfService) ||
      mortgageFieldsConfig['mortgage.borrower.branchOfServiceOtherDescription']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && borrower.branchOfService === MilitaryBranch.Other && !(!!borrower.branchOfServiceOtherDescription) ||
      mortgageFieldsConfig['mortgage.borrower.militaryServiceNumber']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && borrower.branchOfService === MilitaryBranch.Other && !(!!borrower.militaryServiceNumber) ||
      mortgageFieldsConfig['mortgage.borrower.militaryServedAsName']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && borrower.branchOfService === MilitaryBranch.Other && !(!!borrower.militaryServedAsName) ||
      mortgageFieldsConfig['mortgage.borrower.caivrsIdentifier']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && isVaBorrower && !(!!borrower.caivrsIdentifier) ||
      mortgageFieldsConfig['mortgage.borrower.nearestLivingRelativeName']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && isVaBorrower && !(!!borrower.nearestLivingRelativeName) ||
      mortgageFieldsConfig['mortgage.borrower.isVAPastCreditRecordSatisfactory']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && isVaBorrower && (borrower.isVAPastCreditRecordSatisfactory == null || borrower.isVAPastCreditRecordSatisfactory == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.hasFiledVADisabilityClaim']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && isVaBorrower && (borrower.hasFiledVADisabilityClaim == null || borrower.hasFiledVADisabilityClaim == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.hasCurrentPreDischargeClaim']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && isVaBorrower && (borrower.hasCurrentPreDischargeClaim == null || borrower.hasCurrentPreDischargeClaim == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.hasVABenefitRelatedIndebtedness']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && isVaBorrower && (borrower.hasVABenefitRelatedIndebtedness == null || borrower.hasVABenefitRelatedIndebtedness == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.vaSocialSecurityTaxAmount']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && isVaBorrower && (borrower.vaSocialSecurityTaxAmount == null || borrower.vaSocialSecurityTaxAmount == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.vaFederalTaxAmount']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && isVaBorrower && (borrower.vaFederalTaxAmount == null || borrower.vaFederalTaxAmount == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.vaStateTaxAmount']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && isVaBorrower && (borrower.vaStateTaxAmount == null || borrower.vaStateTaxAmount == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.vaLocalTaxAmount']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && isVaBorrower && (borrower.vaLocalTaxAmount == null || borrower.vaLocalTaxAmount == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.vaBorrowerCertificationOccupancyType']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && isVaBorrower && !(!!borrower.vaBorrowerCertificationOccupancyType)
    ) {
      state = MenuItemStatus.Error;
      return state;
    }

    if (
      mortgageFieldsConfig['mortgage.borrower.hasServedInMilitary']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (borrower.hasServedInMilitary == null || borrower.hasServedInMilitary == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.isFirstUseOfVaBenefit']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && (borrower.isFirstUseOfVaBenefit == null || borrower.isFirstUseOfVaBenefit == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.isVaFundingFeeExempt']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && (borrower.isVaFundingFeeExempt == null || borrower.isVaFundingFeeExempt == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.isSurvivingSpouseOfMilitary']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && (borrower.isSurvivingSpouseOfMilitary == null || borrower.isSurvivingSpouseOfMilitary == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.militaryStatus']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && !(!!borrower.militaryStatus) ||
      mortgageFieldsConfig['mortgage.borrower.militaryStatusOtherDescription']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && borrower.militaryStatus === MilitaryStatus.Other && !(!!borrower.militaryStatusOtherDescription) ||
      mortgageFieldsConfig['mortgage.borrower.militaryTourStartDate']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && borrower.isActiveDutyMilitary && !(!!borrower.militaryTourStartDate) ||
      mortgageFieldsConfig['mortgage.borrower.militaryTourEndDate']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && borrower.isActiveDutyMilitary && !(!!borrower.militaryTourEndDate) ||
      mortgageFieldsConfig['mortgage.borrower.branchOfService']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && !(!!borrower.branchOfService) ||
      mortgageFieldsConfig['mortgage.borrower.branchOfServiceOtherDescription']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && borrower.branchOfService === MilitaryBranch.Other && !(!!borrower.branchOfServiceOtherDescription) ||
      mortgageFieldsConfig['mortgage.borrower.militaryServiceNumber']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && borrower.branchOfService === MilitaryBranch.Other && !(!!borrower.militaryServiceNumber) ||
      mortgageFieldsConfig['mortgage.borrower.militaryServedAsName']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && borrower.branchOfService === MilitaryBranch.Other && !(!!borrower.militaryServedAsName) ||
      mortgageFieldsConfig['mortgage.borrower.caivrsIdentifier']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && isVaBorrower && !(!!borrower.caivrsIdentifier) ||
      mortgageFieldsConfig['mortgage.borrower.nearestLivingRelativeName']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && isVaBorrower && !(!!borrower.nearestLivingRelativeName) ||
      mortgageFieldsConfig['mortgage.borrower.isVAPastCreditRecordSatisfactory']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && isVaBorrower && (borrower.isVAPastCreditRecordSatisfactory == null || borrower.isVAPastCreditRecordSatisfactory == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.hasFiledVADisabilityClaim']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && isVaBorrower && (borrower.hasFiledVADisabilityClaim == null || borrower.hasFiledVADisabilityClaim == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.hasCurrentPreDischargeClaim']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && isVaBorrower && (borrower.hasCurrentPreDischargeClaim == null || borrower.hasCurrentPreDischargeClaim == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.hasVABenefitRelatedIndebtedness']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && isVaBorrower && (borrower.hasVABenefitRelatedIndebtedness == null || borrower.hasVABenefitRelatedIndebtedness == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.vaSocialSecurityTaxAmount']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && isVaBorrower && (borrower.vaSocialSecurityTaxAmount == null || borrower.vaSocialSecurityTaxAmount == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.vaFederalTaxAmount']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && isVaBorrower && (borrower.vaFederalTaxAmount == null || borrower.vaFederalTaxAmount == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.vaStateTaxAmount']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && isVaBorrower && (borrower.vaStateTaxAmount == null || borrower.vaStateTaxAmount == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.vaLocalTaxAmount']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && isVaBorrower && (borrower.vaLocalTaxAmount == null || borrower.vaLocalTaxAmount == undefined) ||
      mortgageFieldsConfig['mortgage.borrower.vaBorrowerCertificationOccupancyType']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && borrower.hasServedInMilitary && isVaBorrower && !(!!borrower.vaBorrowerCertificationOccupancyType)
    ) {
      state = MenuItemStatus.Pending;
    }

    return state;
  }

  getStatusForDemographics = (mortgageFieldsConfig: MortgageModelFieldsConfig, mortgage: UrlaMortgage): MenuItemStatus => {
    let state = MenuItemStatus.Success;

    if (mortgage.borrowers && mortgage.borrowers.length > 0) {
      mortgage.borrowers.forEach(borrower => {
        let demoState = this.getStatusForBorrowerDemographics(mortgageFieldsConfig, borrower);
        if (state == MenuItemStatus.Error) {
          return;
        }
        else if (demoState == MenuItemStatus.Error || demoState == MenuItemStatus.Pending) {
          state = demoState;
          return;
        }
      });
    }

    return state;
  }

  getStatusForBorrowerDemographics = (mortgageFieldsConfig: MortgageModelFieldsConfig, borrower: MortgageBorrower) => {

    const isTitleOnlyOrNonTitledSpouse = (borrower.signingRole == this._titleOnlyEnumValue || borrower.signingRole == this._nonTitledSpouseEnumValue
      || borrower.signingRole == this._nonBorrowerHouseholdMemberEnumValue);

    let state = MenuItemStatus.Success;

    if (
      (mortgageFieldsConfig['mortgage.borrower.governmentMonitors.ethnicity']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && !(!!borrower.governmentMonitors.ethnicity)) ||
      (mortgageFieldsConfig['mortgage.borrower.governmentMonitors.ethnicityOrigin']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (!!borrower.governmentMonitors.ethnicity) &&
        borrower.governmentMonitors.ethnicity.includes(this._ethnicityTypeHispanicOrLatino) && !(!!borrower.governmentMonitors.ethnicityOrigin)) ||
      (mortgageFieldsConfig['mortgage.borrower.governmentMonitors.ethnicityOtherDescription']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (!!borrower.governmentMonitors.ethnicity) &&
        borrower.governmentMonitors.ethnicity.includes(EthnicityType.Other) && !(!!borrower.governmentMonitors.ethnicityOtherDescription)) ||
      (mortgageFieldsConfig['mortgage.borrower.governmentMonitors.sex']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && !(!!borrower.governmentMonitors.sex)) ||
      (mortgageFieldsConfig['mortgage.borrower.governmentMonitors.race']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && !(!!borrower.governmentMonitors.race)) ||
      (mortgageFieldsConfig['mortgage.borrower.governmentMonitors.nativeAmericanTribeName']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (!!borrower.governmentMonitors.race) &&
        borrower.governmentMonitors.race.includes(this._raceTypeAmericanIndianOrAlaskaNative) && !(!!borrower.governmentMonitors.nativeAmericanTribeName)) ||
      (mortgageFieldsConfig['mortgage.borrower.governmentMonitors.raceDesignation']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (!!borrower.governmentMonitors.race) && !(!!borrower.governmentMonitors.raceDesignation)) ||
      (mortgageFieldsConfig['mortgage.borrower.governmentMonitors.applicationTakenMethod']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && !(!!borrower.governmentMonitors.applicationTakenMethod)) ||
      (mortgageFieldsConfig['mortgage.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname == null || borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname == undefined)) ||
      (mortgageFieldsConfig['mortgage.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (borrower.governmentMonitors.isGenderBasedOnVisualOrSurname == null || borrower.governmentMonitors.isGenderBasedOnVisualOrSurname == undefined)) ||
      (mortgageFieldsConfig['mortgage.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && (borrower.governmentMonitors.isRaceBasedOnVisualOrSurname == null || borrower.governmentMonitors.isRaceBasedOnVisualOrSurname == undefined))
    ) {
      state = MenuItemStatus.Error;
      return state;
    }

    if (
      (mortgageFieldsConfig['mortgage.borrower.governmentMonitors.ethnicity']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && !(!!borrower.governmentMonitors.ethnicity)) ||
      (mortgageFieldsConfig['mortgage.borrower.governmentMonitors.ethnicityOrigin']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (!!borrower.governmentMonitors.ethnicity) &&
        borrower.governmentMonitors.ethnicity.includes(this._ethnicityTypeHispanicOrLatino) && !(!!borrower.governmentMonitors.ethnicityOrigin)) ||
      (mortgageFieldsConfig['mortgage.borrower.governmentMonitors.ethnicityOtherDescription']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (!!borrower.governmentMonitors.ethnicity) &&
        borrower.governmentMonitors.ethnicity.includes(EthnicityType.Other) && !(!!borrower.governmentMonitors.ethnicityOtherDescription)) ||
      (mortgageFieldsConfig['mortgage.borrower.governmentMonitors.sex']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && !(!!borrower.governmentMonitors.sex)) ||
      (mortgageFieldsConfig['mortgage.borrower.governmentMonitors.race']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && !(!!borrower.governmentMonitors.race)) ||
      (mortgageFieldsConfig['mortgage.borrower.governmentMonitors.nativeAmericanTribeName']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (!!borrower.governmentMonitors.race) &&
        borrower.governmentMonitors.race.includes(this._raceTypeAmericanIndianOrAlaskaNative) && !(!!borrower.governmentMonitors.nativeAmericanTribeName)) ||
      (mortgageFieldsConfig['mortgage.borrower.governmentMonitors.raceDesignation']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (!!borrower.governmentMonitors.race) && !(!!borrower.governmentMonitors.raceDesignation)) ||
      (mortgageFieldsConfig['mortgage.borrower.governmentMonitors.applicationTakenMethod']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && !(!!borrower.governmentMonitors.applicationTakenMethod)) ||
      (mortgageFieldsConfig['mortgage.borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname == null || borrower.governmentMonitors.isEthnicityBasedOnVisualOrSurname == undefined)) ||
      (mortgageFieldsConfig['mortgage.borrower.governmentMonitors.isGenderBasedOnVisualOrSurname']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (borrower.governmentMonitors.isGenderBasedOnVisualOrSurname == null || borrower.governmentMonitors.isGenderBasedOnVisualOrSurname == undefined)) ||
      (mortgageFieldsConfig['mortgage.borrower.governmentMonitors.isRaceBasedOnVisualOrSurname']?.required == MortgageFieldRequiredState.Requested && !isTitleOnlyOrNonTitledSpouse && (borrower.governmentMonitors.isRaceBasedOnVisualOrSurname == null || borrower.governmentMonitors.isRaceBasedOnVisualOrSurname == undefined))
    ) {
      state = MenuItemStatus.Pending;
    }

    return state;
  }

  private hasStateLicense(value: string) {
    return value !== 'tx';
  }

  getStatusForLoanOriginatorInfo = (mortgageFieldsConfig: MortgageModelFieldsConfig, mortgage: UrlaMortgage, isMersEnabled: boolean, isRequiredLoanAndCaseNumbers: boolean) => {
    let state = MenuItemStatus.Success;

    const areAnyOfLoanOfiginationFieldsInvalidForRequired = this.checkIfAnyOfLoanOriginationFieldsAreInvalid(mortgageFieldsConfig, mortgage, MortgageFieldRequiredState.Required, isRequiredLoanAndCaseNumbers);

    if (areAnyOfLoanOfiginationFieldsInvalidForRequired) {
      state = MenuItemStatus.Error;
      return state;
    }

    const areAnyOfLoanOfiginationFieldsInvalidForRequested = this.checkIfAnyOfLoanOriginationFieldsAreInvalid(mortgageFieldsConfig, mortgage, MortgageFieldRequiredState.Requested, isRequiredLoanAndCaseNumbers);
    if (areAnyOfLoanOfiginationFieldsInvalidForRequested) {
      state = MenuItemStatus.Pending;
    }
    return state;
  }

  getStatusForBorrowingEntityInfo = (mortgageFieldsConfig: MortgageModelFieldsConfig, mortgage: UrlaMortgage) => {
    let state = MenuItemStatus.Success;
    if (!mortgage.borrowers.some(x => x.mortgagePartyType == "Entity"))
      return state;

    if (
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityName'] == MortgageFieldRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityName)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityType'] == MortgageFieldRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityType)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityTaxIdentifier'] == MortgageFieldRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityTaxIdentifier)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.filingLocation'] == MortgageFieldRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.filingLocation)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.stateOfOrganization'] == MortgageFieldRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.stateOfOrganization)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.dateOfOrgFiling'] == MortgageFieldRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.dateOfOrgFiling)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.address1'] == MortgageFieldRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityAddress?.address1)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.address2'] == MortgageFieldRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityAddress?.address2)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.city'] == MortgageFieldRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityAddress?.city)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.state'] == MortgageFieldRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityAddress?.state)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.zipCode'] == MortgageFieldRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityAddress?.zipCode)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.address1'] == MortgageFieldRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityMailingAddress?.address1)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.address2'] == MortgageFieldRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityMailingAddress?.address2)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.city'] == MortgageFieldRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityMailingAddress?.city)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.state'] == MortgageFieldRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityMailingAddress?.state)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.zipCode'] == MortgageFieldRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.entityMailingAddress?.zipCode)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.signingPartyName'] == MortgageFieldRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.signingPartyName)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.signingPartyTitle'] == MortgageFieldRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.signingPartyTitle)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.member1.name'] == MortgageFieldRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.member1?.name)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.member1.ownershipPercent'] == MortgageFieldRequiredState.Required && !(!!mortgage.extension?.borrowingEntity?.member1?.ownershipPercent))
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityName'] == MortgageFieldRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityName)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityType'] == MortgageFieldRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityType)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityTaxIdentifier'] == MortgageFieldRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityTaxIdentifier)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.filingLocation'] == MortgageFieldRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.filingLocation)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.stateOfOrganization'] == MortgageFieldRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.stateOfOrganization)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.dateOfOrgFiling'] == MortgageFieldRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.dateOfOrgFiling)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.address1'] == MortgageFieldRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityAddress?.address1)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.address2'] == MortgageFieldRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityAddress?.address2)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.city'] == MortgageFieldRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityAddress?.city)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.state'] == MortgageFieldRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityAddress?.state)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityAddress.zipCode'] == MortgageFieldRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityAddress?.zipCode)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.address1'] == MortgageFieldRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityMailingAddress?.address1)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.address2'] == MortgageFieldRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityMailingAddress?.address2)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.city'] == MortgageFieldRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityMailingAddress?.city)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.state'] == MortgageFieldRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityMailingAddress?.state)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.entityMailingAddress.zipCode'] == MortgageFieldRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.entityMailingAddress?.zipCode)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.signingPartyName'] == MortgageFieldRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.signingPartyName)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.signingPartyTitle'] == MortgageFieldRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.signingPartyTitle)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.member1.name'] == MortgageFieldRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.member1?.name)) ||
      (mortgageFieldsConfig['mortgage.extension.borrowingEntity.member1.ownershipPercent'] == MortgageFieldRequiredState.Requested && !(!!mortgage.extension?.borrowingEntity?.member1?.ownershipPercent))
    ) {
      state = MenuItemStatus.Pending;
    }
    return state;
  }

  getStatusForPropertyLoanInfo = (mortgageFieldsConfig: MortgageModelFieldsConfig, mortgage: UrlaMortgage) => {
    let state = MenuItemStatus.Success;

    const constructionLikePurposes = [
      LoanPurposeTypeEnum.ConstructionOnly as string,
      LoanPurposeTypeEnum.ConstructionToPermanent as string,
    ];

    if (
      (mortgageFieldsConfig['mortgage.subjectProperty.refinanceProgram']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.refinanceProgram)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.attachmentType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.attachmentType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType']?.required == MortgageFieldRequiredState.Required && this._calculationService.isPurposeOfLoanConstructionToPerm(mortgage) && !(!!mortgage.subjectProperty.constructionToPermanentClosingType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.isPlannedUnitDevelopment']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.isPlannedUnitDevelopment == null || mortgage.subjectProperty.isPlannedUnitDevelopment == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.isPropertyNotInAProject']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.isPropertyNotInAProject == null || mortgage.subjectProperty.isPropertyNotInAProject == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.projectType']?.required == MortgageFieldRequiredState.Required && mortgage.subjectProperty.isPropertyNotInAProject == false && !(!!mortgage.subjectProperty.projectType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.projectDesignType']?.required == MortgageFieldRequiredState.Required && mortgage.subjectProperty && (mortgage.subjectProperty.projectType == this._projectTypeCondominium) && !(!!mortgage.subjectProperty.projectDesignType)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.mortgageTerm.loanUnderwritingSubmitterType)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterTypeOtherDescription']?.required == MortgageFieldRequiredState.Required && mortgage.mortgageTerm.loanUnderwritingSubmitterType == 'Other' && !(!!mortgage.mortgageTerm.loanUnderwritingSubmitterTypeOtherDescription)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.loanTermMaximumMonths']?.required == MortgageFieldRequiredState.Required && (mortgage.mortgageTerm.loanTermMaximumMonths == null || mortgage.mortgageTerm.loanTermMaximumMonths == undefined || mortgage.mortgageTerm.loanTermMaximumMonths.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.partialPaymentApplicationMethodType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.mortgageTerm.partialPaymentApplicationMethodType)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.paymentFrequency']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.mortgageTerm.paymentFrequency)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.lienPosition']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.mortgageTerm.lienPosition)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.lienPositionOtherDescription']?.required == MortgageFieldRequiredState.Required && mortgage.mortgageTerm.lienPosition == 'Other' && !(!!mortgage.mortgageTerm.lienPositionOtherDescription)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.borrowerRequestedInterestRate']?.required == MortgageFieldRequiredState.Required && (mortgage.mortgageTerm.borrowerRequestedInterestRate == null || mortgage.mortgageTerm.borrowerRequestedInterestRate == undefined || mortgage.mortgageTerm.borrowerRequestedInterestRate.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.borrowerRequestedLoanAmount']?.required == MortgageFieldRequiredState.Required && (mortgage.mortgageTerm.borrowerRequestedLoanAmount == null || mortgage.mortgageTerm.borrowerRequestedLoanAmount == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.livingAreaSquareFeet']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.livingAreaSquareFeet == null || mortgage.subjectProperty.livingAreaSquareFeet == undefined || mortgage.subjectProperty.livingAreaSquareFeet.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.condominiumOrPudDeclaration']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.condominiumOrPudDeclaration)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.currentOccupancyType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.currentOccupancyType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.currentOccupantName']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.currentOccupantName)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.currentOwnerType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.currentOwnerType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.msaIdentifier']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.msaIdentifier)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.currentUsageType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.currentUsageType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.currentUsageTypeOtherDescription']?.required == MortgageFieldRequiredState.Required && mortgage.subjectProperty.currentUsageType == 'Other' && !(!!mortgage.subjectProperty.currentUsageTypeOtherDescription)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.bathroomCount']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.bathroomCount == null || mortgage.subjectProperty.bathroomCount == undefined || mortgage.subjectProperty.bathroomCount.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.bedroomCount']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.bedroomCount == null || mortgage.subjectProperty.bedroomCount == undefined || mortgage.subjectProperty.bedroomCount.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.livableRoomCount']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.livableRoomCount == null || mortgage.subjectProperty.livableRoomCount == undefined || mortgage.subjectProperty.livableRoomCount.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.mortgageTerm.creditorServicingOfLoanStatementType)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementTypeOtherDescription']?.required == MortgageFieldRequiredState.Required && mortgage.mortgageTerm.creditorServicingOfLoanStatementType == 'Other' && !(!!mortgage.mortgageTerm.creditorServicingOfLoanStatementTypeOtherDescription)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.projectName']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.projectType || mortgage.subjectProperty.isPlannedUnitDevelopment) && !(!!mortgage.subjectProperty.projectName)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.projectClassificationIdentifier']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.projectType) && !(!!mortgage.subjectProperty.projectClassificationIdentifier)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.condominiumProjectStatus']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.projectType) && !(!!mortgage.subjectProperty.condominiumProjectStatus)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.projectType) && !(!!mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.improvementCost']?.required == MortgageFieldRequiredState.Required && constructionLikePurposes.includes(mortgage.subjectProperty.purposeOfLoan) && (mortgage.subjectProperty.improvementCost == null || mortgage.subjectProperty.improvementCost == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.lotAcquiredDate']?.required == MortgageFieldRequiredState.Required && constructionLikePurposes.includes(mortgage.subjectProperty.purposeOfLoan) && !(!!mortgage.subjectProperty.lotAcquiredDate)) ||
      (constructionLikePurposes.includes(mortgage.subjectProperty.purposeOfLoan) && mortgage.subjectProperty.lotAcquiredDate && this.checkIsInvalidDate(mortgage.subjectProperty.lotAcquiredDate)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.lotOriginalCost']?.required == MortgageFieldRequiredState.Required && constructionLikePurposes.includes(mortgage.subjectProperty.purposeOfLoan) && (mortgage.subjectProperty.lotOriginalCost == null || mortgage.subjectProperty.lotOriginalCost == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.lotAppraisedValue']?.required == MortgageFieldRequiredState.Required && constructionLikePurposes.includes(mortgage.subjectProperty.purposeOfLoan) && (mortgage.subjectProperty.lotAppraisedValue == null || mortgage.subjectProperty.lotAppraisedValue == undefined))
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (
      (mortgageFieldsConfig['mortgage.subjectProperty.refinanceProgram']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.refinanceProgram)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.attachmentType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.attachmentType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.constructionToPermanentClosingType']?.required == MortgageFieldRequiredState.Requested && this._calculationService.isPurposeOfLoanConstructionToPerm(mortgage) && !(!!mortgage.subjectProperty.constructionToPermanentClosingType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.isPlannedUnitDevelopment']?.required == MortgageFieldRequiredState.Requested && (mortgage.subjectProperty.isPlannedUnitDevelopment == null || mortgage.subjectProperty.isPlannedUnitDevelopment == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.isPropertyNotInAProject']?.required == MortgageFieldRequiredState.Requested && (mortgage.subjectProperty.isPropertyNotInAProject == null || mortgage.subjectProperty.isPropertyNotInAProject == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.projectType']?.required == MortgageFieldRequiredState.Requested && mortgage.subjectProperty.isPropertyNotInAProject == false && !(!!mortgage.subjectProperty.projectType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.projectDesignType']?.required == MortgageFieldRequiredState.Requested && mortgage.subjectProperty && (mortgage.subjectProperty.projectType == this._projectTypeCondominium) && !(!!mortgage.subjectProperty.projectDesignType)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.mortgageTerm.loanUnderwritingSubmitterType)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.loanUnderwritingSubmitterTypeOtherDescription']?.required == MortgageFieldRequiredState.Requested && mortgage.mortgageTerm.loanUnderwritingSubmitterType == 'Other' && !(!!mortgage.mortgageTerm.loanUnderwritingSubmitterTypeOtherDescription)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.loanTermMaximumMonths']?.required == MortgageFieldRequiredState.Requested && (mortgage.mortgageTerm.loanTermMaximumMonths == null || mortgage.mortgageTerm.loanTermMaximumMonths == undefined || mortgage.mortgageTerm.loanTermMaximumMonths.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.partialPaymentApplicationMethodType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.mortgageTerm.partialPaymentApplicationMethodType)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.paymentFrequency']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.mortgageTerm.paymentFrequency)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.lienPosition']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.mortgageTerm.lienPosition)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.lienPositionOtherDescription']?.required == MortgageFieldRequiredState.Requested && mortgage.mortgageTerm.lienPosition == 'Other' && !(!!mortgage.mortgageTerm.lienPositionOtherDescription)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.borrowerRequestedInterestRate']?.required == MortgageFieldRequiredState.Requested && (mortgage.mortgageTerm.borrowerRequestedInterestRate == null || mortgage.mortgageTerm.borrowerRequestedInterestRate == undefined || mortgage.mortgageTerm.borrowerRequestedInterestRate.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.borrowerRequestedLoanAmount']?.required == MortgageFieldRequiredState.Requested && (mortgage.mortgageTerm.borrowerRequestedLoanAmount == null || mortgage.mortgageTerm.borrowerRequestedLoanAmount == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.livingAreaSquareFeet']?.required == MortgageFieldRequiredState.Requested && (mortgage.subjectProperty.livingAreaSquareFeet == null || mortgage.subjectProperty.livingAreaSquareFeet == undefined || mortgage.subjectProperty.livingAreaSquareFeet.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.condominiumOrPudDeclaration']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.condominiumOrPudDeclaration)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.currentOccupancyType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.currentOccupancyType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.currentOccupantName']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.currentOccupantName)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.currentOwnerType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.currentOwnerType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.msaIdentifier']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.msaIdentifier)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.currentUsageType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.currentUsageType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.currentUsageTypeOtherDescription']?.required == MortgageFieldRequiredState.Requested && mortgage.subjectProperty.currentUsageType == 'Other' && !(!!mortgage.subjectProperty.currentUsageTypeOtherDescription)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.bathroomCount']?.required == MortgageFieldRequiredState.Requested && (mortgage.subjectProperty.bathroomCount == null || mortgage.subjectProperty.bathroomCount == undefined || mortgage.subjectProperty.bathroomCount.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.bedroomCount']?.required == MortgageFieldRequiredState.Requested && (mortgage.subjectProperty.bedroomCount == null || mortgage.subjectProperty.bedroomCount == undefined || mortgage.subjectProperty.bedroomCount.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.livableRoomCount']?.required == MortgageFieldRequiredState.Requested && (mortgage.subjectProperty.livableRoomCount == null || mortgage.subjectProperty.livableRoomCount == undefined || mortgage.subjectProperty.livableRoomCount.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.mortgageTerm.creditorServicingOfLoanStatementType)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.creditorServicingOfLoanStatementTypeOtherDescription']?.required == MortgageFieldRequiredState.Requested && mortgage.mortgageTerm.creditorServicingOfLoanStatementType == 'Other' && !(!!mortgage.mortgageTerm.creditorServicingOfLoanStatementTypeOtherDescription)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.projectName']?.required == MortgageFieldRequiredState.Requested && (mortgage.subjectProperty.projectType || mortgage.subjectProperty.isPlannedUnitDevelopment) && !(!!mortgage.subjectProperty.projectName)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.projectClassificationIdentifier']?.required == MortgageFieldRequiredState.Requested && (mortgage.subjectProperty.projectType) && !(!!mortgage.subjectProperty.projectClassificationIdentifier)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.condominiumProjectStatus']?.required == MortgageFieldRequiredState.Requested && (mortgage.subjectProperty.projectType) && !(!!mortgage.subjectProperty.condominiumProjectStatus)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier']?.required == MortgageFieldRequiredState.Requested && (mortgage.subjectProperty.projectType) && !(!!mortgage.subjectProperty.fnmCondominiumProjectManagerProjectIdentifier)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.improvementCost']?.required == MortgageFieldRequiredState.Requested && constructionLikePurposes.includes(mortgage.subjectProperty.purposeOfLoan) && (mortgage.subjectProperty.improvementCost == null || mortgage.subjectProperty.improvementCost == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.lotAcquiredDate']?.required == MortgageFieldRequiredState.Requested && constructionLikePurposes.includes(mortgage.subjectProperty.purposeOfLoan) && !(!!mortgage.subjectProperty.lotAcquiredDate)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.lotOriginalCost']?.required == MortgageFieldRequiredState.Requested && constructionLikePurposes.includes(mortgage.subjectProperty.purposeOfLoan) && (mortgage.subjectProperty.lotOriginalCost == null || mortgage.subjectProperty.lotOriginalCost == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.lotAppraisedValue']?.required == MortgageFieldRequiredState.Requested && constructionLikePurposes.includes(mortgage.subjectProperty.purposeOfLoan) && (mortgage.subjectProperty.lotAppraisedValue == null || mortgage.subjectProperty.lotAppraisedValue == undefined))
    ) {
      state = MenuItemStatus.Pending;
    }
    return state;
  }

  getStatusForTitleInfo = (mortgageFieldsConfig: MortgageModelFieldsConfig, mortgage: UrlaMortgage) => {
    let state = MenuItemStatus.Success;

    if (
      (mortgageFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeld']?.required == MortgageFieldRequiredState.Required && this._calculationService.isPurposeOfLoanRefinance(mortgage) && !(!!mortgage.subjectProperty.mannerTitleCurrentlyHeld)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeldOtherDescription']?.required == MortgageFieldRequiredState.Required && mortgage.subjectProperty.mannerTitleCurrentlyHeld === PropertyTitleType.Other && !(!!mortgage.subjectProperty.mannerTitleCurrentlyHeldOtherDescription)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeld']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.mannerTitleWillBeHeld)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeldOtherDescription']?.required == MortgageFieldRequiredState.Required && mortgage.subjectProperty.mannerTitleWillBeHeld === PropertyTitleType.Other && !(!!mortgage.subjectProperty.mannerTitleWillBeHeldOtherDescription)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.estate']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.estate)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.leaseHoldExpirationDate']?.required == MortgageFieldRequiredState.Required && mortgage.subjectProperty?.estate === this._estateLeaseHoldEnumValue && !(!!mortgage.subjectProperty.leaseHoldExpirationDate)) ||
      (mortgage.subjectProperty?.estate === this._estateLeaseHoldEnumValue && mortgage.subjectProperty.leaseHoldExpirationDate && this.checkIsInvalidDate(mortgage.subjectProperty.leaseHoldExpirationDate)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.groundLeaseExpirationDate']?.required == MortgageFieldRequiredState.Required && mortgage.subjectProperty?.estate === this._estateLeaseHoldEnumValue && !(!!mortgage.subjectProperty.groundLeaseExpirationDate)) ||
      (mortgage.subjectProperty?.estate === this._estateLeaseHoldEnumValue && mortgage.subjectProperty.groundLeaseExpirationDate && this.checkIsInvalidDate(mortgage.subjectProperty.groundLeaseExpirationDate)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.nativeAmericanLandsType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsTypeOtherDescription']?.required == MortgageFieldRequiredState.Required && mortgage.subjectProperty.nativeAmericanLandsType === NativeAmericanLandsType.Other && !(!!mortgage.subjectProperty.nativeAmericanLandsTypeOtherDescription)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.titlePreliminaryReportDate']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.titlePreliminaryReportDate)) || (mortgage.subjectProperty.titlePreliminaryReportDate && this.checkIsInvalidDate(mortgage.subjectProperty.titlePreliminaryReportDate)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.titleCurrentlyHeldInNames']?.required == MortgageFieldRequiredState.Required && this._calculationService.isPurposeOfLoanRefinance(mortgage) && !(!!mortgage.subjectProperty.titleCurrentlyHeldInNames)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.titleReportItemsDescription']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.titleReportItemsDescription)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.titleReportRequiredEndorsementsDescription']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.titleReportRequiredEndorsementsDescription)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.trustClassification']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.trustClassification)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.isDeedRestricted']?.required == MortgageFieldRequiredState.Required && (mortgage.subjectProperty.isDeedRestricted == null || mortgage.subjectProperty.isDeedRestricted == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.deedRestrictionTermMonths']?.required == MortgageFieldRequiredState.Required && mortgage.subjectProperty.isDeedRestricted && (mortgage.subjectProperty.deedRestrictionTermMonths == null || mortgage.subjectProperty.deedRestrictionTermMonths == undefined || mortgage.subjectProperty.deedRestrictionTermMonths.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.recordingJurisdictionName']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.recordingJurisdictionName)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.recordingJurisdictionType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.recordingJurisdictionType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.recordingJurisdictionTypeOtherDescription']?.required == MortgageFieldRequiredState.Required && mortgage.subjectProperty.recordingJurisdictionType === RecordingJurisdictionType.Other && !(!!mortgage.subjectProperty.recordingJurisdictionTypeOtherDescription))
    ) {
      state = MenuItemStatus.Error;
      return state;
    }
    if (
      (mortgageFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeld']?.required == MortgageFieldRequiredState.Requested && this._calculationService.isPurposeOfLoanRefinance(mortgage) && !(!!mortgage.subjectProperty.mannerTitleCurrentlyHeld)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.mannerTitleCurrentlyHeldOtherDescription']?.required == MortgageFieldRequiredState.Requested && mortgage.subjectProperty.mannerTitleCurrentlyHeld === PropertyTitleType.Other && !(!!mortgage.subjectProperty.mannerTitleCurrentlyHeldOtherDescription)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeld']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.mannerTitleWillBeHeld)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.mannerTitleWillBeHeldOtherDescription']?.required == MortgageFieldRequiredState.Requested && mortgage.subjectProperty.mannerTitleWillBeHeld === PropertyTitleType.Other && !(!!mortgage.subjectProperty.mannerTitleWillBeHeldOtherDescription)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.estate']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.estate)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.leaseHoldExpirationDate']?.required == MortgageFieldRequiredState.Requested && mortgage.subjectProperty?.estate === this._estateLeaseHoldEnumValue && !(!!mortgage.subjectProperty.leaseHoldExpirationDate)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.groundLeaseExpirationDate']?.required == MortgageFieldRequiredState.Requested && mortgage.subjectProperty?.estate === this._estateLeaseHoldEnumValue && !(!!mortgage.subjectProperty.groundLeaseExpirationDate)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.nativeAmericanLandsType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.nativeAmericanLandsTypeOtherDescription']?.required == MortgageFieldRequiredState.Requested && mortgage.subjectProperty.nativeAmericanLandsType === NativeAmericanLandsType.Other && !(!!mortgage.subjectProperty.nativeAmericanLandsTypeOtherDescription)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.titlePreliminaryReportDate']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.titlePreliminaryReportDate)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.titleCurrentlyHeldInNames']?.required == MortgageFieldRequiredState.Requested && this._calculationService.isPurposeOfLoanRefinance(mortgage) && !(!!mortgage.subjectProperty.titleCurrentlyHeldInNames)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.titleReportItemsDescription']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.titleReportItemsDescription)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.titleReportRequiredEndorsementsDescription']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.titleReportRequiredEndorsementsDescription)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.trustClassification']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.trustClassification)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.isDeedRestricted']?.required == MortgageFieldRequiredState.Requested && (mortgage.subjectProperty.isDeedRestricted == null || mortgage.subjectProperty.isDeedRestricted == undefined)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.deedRestrictionTermMonths']?.required == MortgageFieldRequiredState.Requested && mortgage.subjectProperty.isDeedRestricted && (mortgage.subjectProperty.deedRestrictionTermMonths == null || mortgage.subjectProperty.deedRestrictionTermMonths == undefined || mortgage.subjectProperty.deedRestrictionTermMonths.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.recordingJurisdictionName']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.recordingJurisdictionName)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.recordingJurisdictionType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.recordingJurisdictionType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.recordingJurisdictionTypeOtherDescription']?.required == MortgageFieldRequiredState.Requested && mortgage.subjectProperty.recordingJurisdictionType === RecordingJurisdictionType.Other && !(!!mortgage.subjectProperty.recordingJurisdictionTypeOtherDescription))
    ) {
      state = MenuItemStatus.Pending;
    }

    return state;
  }

  getStatusForMortgageLoanInfo = (mortgageFieldsConfig: MortgageModelFieldsConfig, mortgage: UrlaMortgage) => {
    let state = MenuItemStatus.Success;

    if (
      (mortgageFieldsConfig['mortgage.mortgageTerm.mortgageAppliedFor']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.mortgageTerm.mortgageAppliedFor)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.communityLendingProductType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.communityLendingProductType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.communitySecondsRepaymentType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.subjectProperty.communitySecondsRepaymentType)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.interestRate']?.required == MortgageFieldRequiredState.Required && (mortgage.mortgageTerm.interestRate == null || mortgage.mortgageTerm.interestRate == undefined || mortgage.mortgageTerm.interestRate.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.noOfMonths']?.required == MortgageFieldRequiredState.Required && (mortgage.mortgageTerm.noOfMonths == null || mortgage.mortgageTerm.noOfMonths == undefined || mortgage.mortgageTerm.noOfMonths.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.lienPosition']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.mortgageTerm.lienPosition)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.amortizationType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.mortgageTerm.amortizationType)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.loanAmortizationPeriodType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.mortgageTerm.loanAmortizationPeriodType)) ||
      ((['FHA', 'VA', 'USDA'].includes(mortgage.mortgageTerm.mortgageAppliedFor)) && (
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.borrowerFundingFeePercent']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.borrowerFundingFeePercent == null || mortgage.governmentLoanDetail.borrowerFundingFeePercent == undefined || mortgage.governmentLoanDetail.borrowerFundingFeePercent.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.refundProratedUnearnedUpFront']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.refundProratedUnearnedUpFront == null || mortgage.governmentLoanDetail.refundProratedUnearnedUpFront == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.roundToNearest50Dollars']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.roundToNearest50Dollars == null || mortgage.governmentLoanDetail.roundToNearest50Dollars == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments == null || mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing == null || mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.lenderPaidMortgageInsurance']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.lenderPaidMortgageInsurance == null || mortgage.governmentLoanDetail.lenderPaidMortgageInsurance == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory == null || mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.sectionOfActType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.sectionOfActType)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueId']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.masterCertificateOfReasonableValueId)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate)) || (mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate && this.checkIsInvalidDate(mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears == null || mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears == undefined || mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.applicationType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.applicationType)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhavaLoanPurposeType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.fhavaLoanPurposeType)))
      ) ||
      ((mortgage.mortgageTerm.mortgageAppliedFor === 'FHA') && (
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount == null || mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaAssignmentDate']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.fhaAssignmentDate)) || (mortgage.governmentLoanDetail.fhaAssignmentDate && this.checkIsInvalidDate(mortgage.governmentLoanDetail.fhaAssignmentDate)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaEndorsementDate']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.fhaEndorsementDate)) || (mortgage.governmentLoanDetail.fhaEndorsementDate && this.checkIsInvalidDate(mortgage.governmentLoanDetail.fhaEndorsementDate)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount == null || mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaGeneralServicesAdministrationIdentifier']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.fhaGeneralServicesAdministrationIdentifier)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaInsuranceProgramType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.fhaInsuranceProgramType)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount == null || mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount == null || mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingFhaCaseIdentifier']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingFhaCaseIdentifier)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount == null || mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaMIRenewalRatePercent']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.fhaMIRenewalRatePercent == null || mortgage.governmentLoanDetail.fhaMIRenewalRatePercent == undefined || mortgage.governmentLoanDetail.fhaMIRenewalRatePercent.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaAlimonyLiabilityTreatmentType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.fhaAlimonyLiabilityTreatmentType)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.energyRelatedImprovementType)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementTypeOtherDescription']?.required == MortgageFieldRequiredState.Required && mortgage.governmentLoanDetail.energyRelatedImprovementType == 'Other' && !(!!mortgage.governmentLoanDetail.energyRelatedImprovementTypeOtherDescription)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaUnderwriterComputerizedHomesUnderwritingSystemIdentifier']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.fhaUnderwriterComputerizedHomesUnderwritingSystemIdentifier)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaAppraisalReviewerComputerizedHomesUnderwritingSystemIdentifier']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.fhaAppraisalReviewerComputerizedHomesUnderwritingSystemIdentifier)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaDirectEndorsementApprovalType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.fhaDirectEndorsementApprovalType)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaLimitedDenialParticipationIdentifier']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.fhaLimitedDenialParticipationIdentifier)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.borrowerHasOwnedOrSoldOtherRealEstate']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.borrowerHasOwnedOrSoldOtherRealEstate == null || mortgage.governmentLoanDetail.borrowerHasOwnedOrSoldOtherRealEstate == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.isRealEstateToBeSold']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.isRealEstateToBeSold == null || mortgage.governmentLoanDetail.isRealEstateToBeSold == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.isDwellingCovered']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.isDwellingCovered == null || mortgage.governmentLoanDetail.isDwellingCovered == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.borrowerOwnsMoreThanFourDwellings']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.borrowerOwnsMoreThanFourDwellings == null || mortgage.governmentLoanDetail.borrowerOwnsMoreThanFourDwellings == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.borrowerHasReceivedInformationOnLeadPaintPoisoning']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.borrowerHasReceivedInformationOnLeadPaintPoisoning == null || mortgage.governmentLoanDetail.borrowerHasReceivedInformationOnLeadPaintPoisoning == undefined)))
      ) ||
      ((mortgage.mortgageTerm.mortgageAppliedFor === 'VA') && (
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaEntitlementAmount']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.vaEntitlementAmount == null || mortgage.governmentLoanDetail.vaEntitlementAmount == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeAmount']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.vaResidualIncomeAmount == null || mortgage.governmentLoanDetail.vaResidualIncomeAmount == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaEntitlementIdentifier']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.vaEntitlementIdentifier)) ||
        (mortgageFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewerIdentifier']?.required == MortgageFieldRequiredState.Required && mortgage.subjectProperty.subjectPropertyValuation?.hasVAStaffAppraisalReviewValuationAdjustment && !(!!mortgage.subjectProperty.subjectPropertyValuation?.vaStaffAppraisalReviewerIdentifier)) ||
        (mortgageFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vAStaffAppraisalReviewValueNotificationDate']?.required == MortgageFieldRequiredState.Required && mortgage.subjectProperty.subjectPropertyValuation?.hasVAStaffAppraisalReviewValuationAdjustment && !(!!mortgage.subjectProperty.subjectPropertyValuation?.vaStaffAppraisalReviewValueNotificationDate)) ||
        (mortgage.subjectProperty.subjectPropertyValuation?.hasVAStaffAppraisalReviewValuationAdjustment && mortgage.subjectProperty.subjectPropertyValuation?.vaStaffAppraisalReviewValueNotificationDate && this.checkIsInvalidDate(mortgage.subjectProperty.subjectPropertyValuation?.vaStaffAppraisalReviewValueNotificationDate)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount == null || mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaAppraisalType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.vaAppraisalType)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaAppraisalTypeOtherDescription']?.required == MortgageFieldRequiredState.Required && mortgage.governmentLoanDetail.vaAppraisalType == 'Other' && !(!!mortgage.governmentLoanDetail.vaAppraisalTypeOtherDescription)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaLoanProcedureType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.vaLoanProcedureType)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaLoanProcedureTypeOtherDescription']?.required == MortgageFieldRequiredState.Required && mortgage.governmentLoanDetail.vaLoanProcedureType == 'Other' && !(!!mortgage.governmentLoanDetail.vaLoanProcedureTypeOtherDescription)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.vaTitleVestingType)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingTypeOtherDescription']?.required == MortgageFieldRequiredState.Required && mortgage.governmentLoanDetail.vaTitleVestingType == 'Other' && !(!!mortgage.governmentLoanDetail.vaTitleVestingTypeOtherDescription)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount == null || mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.energyRelatedImprovementType)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementTypeOtherDescription']?.required == MortgageFieldRequiredState.Required && mortgage.governmentLoanDetail.energyRelatedImprovementType == 'Other' && !(!!mortgage.governmentLoanDetail.energyRelatedImprovementTypeOtherDescription)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount == null || mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount == null || mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaExaminerComments']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.vaExaminerComments)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaClaimFolderIdentifier']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.vaClaimFolderIdentifier)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.loanMeetsVACreditStandards']?.required == MortgageFieldRequiredState.Required && (mortgage.governmentLoanDetail.loanMeetsVACreditStandards == null || mortgage.governmentLoanDetail.loanMeetsVACreditStandards == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaCreditStandardsDecisionRemarks']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.governmentLoanDetail.vaCreditStandardsDecisionRemarks)))
      ) ||
      (mortgageFieldsConfig['mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest']?.required == MortgageFieldRequiredState.Required && (mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest == null || mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest == undefined)) ||
      (mortgageFieldsConfig['mortgage.proposedHousingExpense.homeownersInsurance']?.required == MortgageFieldRequiredState.Required && (mortgage.proposedHousingExpense.homeownersInsurance == null || mortgage.proposedHousingExpense.homeownersInsurance == undefined)) ||
      (mortgageFieldsConfig['mortgage.proposedHousingExpense.supplementalPropertyInsurance']?.required == MortgageFieldRequiredState.Required && (mortgage.proposedHousingExpense.supplementalPropertyInsurance == null || mortgage.proposedHousingExpense.supplementalPropertyInsurance == undefined)) ||
      (mortgageFieldsConfig['mortgage.proposedHousingExpense.realEstateTax']?.required == MortgageFieldRequiredState.Required && (mortgage.proposedHousingExpense.realEstateTax == null || mortgage.proposedHousingExpense.realEstateTax == undefined)) ||
      (mortgageFieldsConfig['mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees']?.required == MortgageFieldRequiredState.Required && (mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees == null || mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees == undefined)) ||
      (mortgageFieldsConfig['mortgage.proposedHousingExpense.otherHousingExpense']?.required == MortgageFieldRequiredState.Required && (mortgage.proposedHousingExpense.otherHousingExpense == null || mortgage.proposedHousingExpense.otherHousingExpense == undefined)) ||
      (mortgageFieldsConfig['mortgage.proposedHousingExpense.otherHousingExpenseDescription']?.required == MortgageFieldRequiredState.Required && mortgage.proposedHousingExpense.otherHousingExpense && !(!!mortgage.proposedHousingExpense.otherHousingExpenseDescription)) ||

      (mortgageFieldsConfig['mortgage.extension.balloonTermMonths']?.required == MortgageFieldRequiredState.Required && mortgage.extension.isBalloonPaymentRequiredToPayOffLoan && (mortgage.extension.balloonTermMonths == null || mortgage.extension.balloonTermMonths == undefined || mortgage.extension.balloonTermMonths.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.extension.interestOnlyTermMonths']?.required == MortgageFieldRequiredState.Required && mortgage.extension.isInterestOnly && (mortgage.extension.interestOnlyTermMonths == null || mortgage.extension.interestOnlyTermMonths == undefined || mortgage.extension.interestOnlyTermMonths.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.ausData.negativeAmortizationType']?.required == MortgageFieldRequiredState.Required && mortgage.extension.hasNegativeAmortization && !(!!mortgage.ausData.negativeAmortizationType)) ||
      (mortgageFieldsConfig['mortgage.extension.buydownDetail.initialBuydownRate']?.required == MortgageFieldRequiredState.Required && mortgage.extension.buydownDetail.hasBuydown && (mortgage.extension.buydownDetail.initialBuydownRate == null || mortgage.extension.buydownDetail.initialBuydownRate == undefined || mortgage.extension.buydownDetail.initialBuydownRate.toString() == '')) ||

      ((mortgage.mortgageTerm.amortizationType == this._amortizationTypeArmEnumValue) && (
        (mortgageFieldsConfig['mortgage.ausData.rateAdjustmentPeriodicCap']?.required == MortgageFieldRequiredState.Required && (mortgage.ausData.rateAdjustmentPeriodicCap == null || mortgage.ausData.rateAdjustmentPeriodicCap == undefined || mortgage.ausData.rateAdjustmentPeriodicCap.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.ausData.firstRateAdjustmentMonths']?.required == MortgageFieldRequiredState.Required && (mortgage.ausData.firstRateAdjustmentMonths == null || mortgage.ausData.firstRateAdjustmentMonths == undefined || mortgage.ausData.firstRateAdjustmentMonths.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.ausData.subsequentRateAdjustmentPeriodicCap']?.required == MortgageFieldRequiredState.Required && (mortgage.ausData.subsequentRateAdjustmentPeriodicCap == null || mortgage.ausData.subsequentRateAdjustmentPeriodicCap == undefined || mortgage.ausData.subsequentRateAdjustmentPeriodicCap.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.ausData.subsequentRateAdjustmentMonths']?.required == MortgageFieldRequiredState.Required && (mortgage.ausData.subsequentRateAdjustmentMonths == null || mortgage.ausData.subsequentRateAdjustmentMonths == undefined || mortgage.ausData.subsequentRateAdjustmentMonths.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.ausData.armRateAdjustmentLifetimeCap']?.required == MortgageFieldRequiredState.Required && (mortgage.ausData.armRateAdjustmentLifetimeCap == null || mortgage.ausData.armRateAdjustmentLifetimeCap == undefined || mortgage.ausData.armRateAdjustmentLifetimeCap.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.ausData.armIndexCurrent']?.required == MortgageFieldRequiredState.Required && (mortgage.ausData.armIndexCurrent == null || mortgage.ausData.armIndexCurrent == undefined || mortgage.ausData.armIndexCurrent.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.ausData.armMarginRate']?.required == MortgageFieldRequiredState.Required && (mortgage.ausData.armMarginRate == null || mortgage.ausData.armMarginRate == undefined || mortgage.ausData.armMarginRate.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.ausData.armFullyIndexedRate']?.required == MortgageFieldRequiredState.Required && (mortgage.ausData.armFullyIndexedRate == null || mortgage.ausData.armFullyIndexedRate == undefined || mortgage.ausData.armFullyIndexedRate.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.ausData.armIndexSourceType']?.required == MortgageFieldRequiredState.Required && (mortgage.ausData.armIndexSourceType == null || mortgage.ausData.armIndexSourceType == undefined || mortgage.ausData.armIndexSourceType.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.ausData.armFloorRate']?.required == MortgageFieldRequiredState.Required && (mortgage.ausData.armFloorRate == null || mortgage.ausData.armFloorRate == undefined || mortgage.ausData.armFloorRate.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.ausData.firstPaymentAdjustmentMonths']?.required == MortgageFieldRequiredState.Required && (mortgage.ausData.firstPaymentAdjustmentMonths == null || mortgage.ausData.firstPaymentAdjustmentMonths == undefined || mortgage.ausData.firstPaymentAdjustmentMonths.toString() == ''))

      ))
    ) {
      state = MenuItemStatus.Error;
      return state;
    }

    if (
      (mortgageFieldsConfig['mortgage.mortgageTerm.mortgageAppliedFor']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.mortgageTerm.mortgageAppliedFor)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.communityLendingProductType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.communityLendingProductType)) ||
      (mortgageFieldsConfig['mortgage.subjectProperty.communitySecondsRepaymentType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.subjectProperty.communitySecondsRepaymentType)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.interestRate']?.required == MortgageFieldRequiredState.Requested && (mortgage.mortgageTerm.interestRate == null || mortgage.mortgageTerm.interestRate == undefined || mortgage.mortgageTerm.interestRate.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.noOfMonths']?.required == MortgageFieldRequiredState.Requested && (mortgage.mortgageTerm.noOfMonths == null || mortgage.mortgageTerm.noOfMonths == undefined || mortgage.mortgageTerm.noOfMonths.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.lienPosition']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.mortgageTerm.lienPosition)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.amortizationType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.mortgageTerm.amortizationType)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.loanAmortizationPeriodType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.mortgageTerm.loanAmortizationPeriodType)) ||
      ((['FHA', 'VA', 'USDA'].includes(mortgage.mortgageTerm.mortgageAppliedFor)) && (
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.borrowerFundingFeePercent']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.borrowerFundingFeePercent == null || mortgage.governmentLoanDetail.borrowerFundingFeePercent == undefined || mortgage.governmentLoanDetail.borrowerFundingFeePercent.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.refundProratedUnearnedUpFront']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.refundProratedUnearnedUpFront == null || mortgage.governmentLoanDetail.refundProratedUnearnedUpFront == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.roundToNearest50Dollars']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.roundToNearest50Dollars == null || mortgage.governmentLoanDetail.roundToNearest50Dollars == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments == null || mortgage.governmentLoanDetail.insuranceChargesIncludedInLoanPayments == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing == null || mortgage.governmentLoanDetail.insuranceChargesCollectedAtClosing == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.lenderPaidMortgageInsurance']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.lenderPaidMortgageInsurance == null || mortgage.governmentLoanDetail.lenderPaidMortgageInsurance == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory == null || mortgage.governmentLoanDetail.borrowerHasPositiveRentalHistory == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.sectionOfActType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.sectionOfActType)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueId']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.masterCertificateOfReasonableValueId)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.masterCertificateOfReasonableValueExpirationDate)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears == null || mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears == undefined || mortgage.governmentLoanDetail.masterCertificateOfReasonableValueEconomicLifeYears.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.applicationType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.applicationType)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhavaLoanPurposeType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.fhavaLoanPurposeType)))
      ) ||
      ((mortgage.mortgageTerm.mortgageAppliedFor === 'FHA') && (
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount == null || mortgage.governmentLoanDetail.fhaMiPremiumRefundAmount == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaAssignmentDate']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.fhaAssignmentDate)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaEndorsementDate']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.fhaEndorsementDate)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount == null || mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaGeneralServicesAdministrationIdentifier']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.fhaGeneralServicesAdministrationIdentifier)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaInsuranceProgramType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.fhaInsuranceProgramType)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount == null || mortgage.governmentLoanDetail.fhaPremiumAnniversaryYearToDateRemittanceAmount == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount == null || mortgage.governmentLoanDetail.fhaRefinanceInterestOnExistingLienAmount == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingFhaCaseIdentifier']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingFhaCaseIdentifier)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount == null || mortgage.governmentLoanDetail.fhaRefinanceOriginalExistingUpfrontMIPremiumAmount == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaMIRenewalRatePercent']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.fhaMIRenewalRatePercent == null || mortgage.governmentLoanDetail.fhaMIRenewalRatePercent == undefined || mortgage.governmentLoanDetail.fhaMIRenewalRatePercent.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaAlimonyLiabilityTreatmentType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.fhaAlimonyLiabilityTreatmentType)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.energyRelatedImprovementType)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementTypeOtherDescription']?.required == MortgageFieldRequiredState.Requested && mortgage.governmentLoanDetail.energyRelatedImprovementType == 'Other' && !(!!mortgage.governmentLoanDetail.energyRelatedImprovementTypeOtherDescription)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaUnderwriterComputerizedHomesUnderwritingSystemIdentifier']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.fhaUnderwriterComputerizedHomesUnderwritingSystemIdentifier)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaAppraisalReviewerComputerizedHomesUnderwritingSystemIdentifier']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.fhaAppraisalReviewerComputerizedHomesUnderwritingSystemIdentifier)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaDirectEndorsementApprovalType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.fhaDirectEndorsementApprovalType)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaLimitedDenialParticipationIdentifier']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.fhaLimitedDenialParticipationIdentifier)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.borrowerHasOwnedOrSoldOtherRealEstate']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.borrowerHasOwnedOrSoldOtherRealEstate == null || mortgage.governmentLoanDetail.borrowerHasOwnedOrSoldOtherRealEstate == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.isRealEstateToBeSold']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.isRealEstateToBeSold == null || mortgage.governmentLoanDetail.isRealEstateToBeSold == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.isDwellingCovered']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.isDwellingCovered == null || mortgage.governmentLoanDetail.isDwellingCovered == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.borrowerOwnsMoreThanFourDwellings']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.borrowerOwnsMoreThanFourDwellings == null || mortgage.governmentLoanDetail.borrowerOwnsMoreThanFourDwellings == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.borrowerHasReceivedInformationOnLeadPaintPoisoning']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.borrowerHasReceivedInformationOnLeadPaintPoisoning == null || mortgage.governmentLoanDetail.borrowerHasReceivedInformationOnLeadPaintPoisoning == undefined)))
      ) ||
      ((mortgage.mortgageTerm.mortgageAppliedFor === 'VA') && (
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaEntitlementAmount']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.vaEntitlementAmount == null || mortgage.governmentLoanDetail.vaEntitlementAmount == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeAmount']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.vaResidualIncomeAmount == null || mortgage.governmentLoanDetail.vaResidualIncomeAmount == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaEntitlementIdentifier']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.vaEntitlementIdentifier)) ||
        (mortgageFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewerIdentifier']?.required == MortgageFieldRequiredState.Requested && mortgage.subjectProperty.subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment && !(!!mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewerIdentifier)) ||
        (mortgageFieldsConfig['mortgage.subjectProperty.subjectPropertyValuation.vAStaffAppraisalReviewValueNotificationDate']?.required == MortgageFieldRequiredState.Requested && mortgage.subjectProperty.subjectPropertyValuation.hasVAStaffAppraisalReviewValuationAdjustment && !(!!mortgage.subjectProperty.subjectPropertyValuation.vaStaffAppraisalReviewValueNotificationDate)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount == null || mortgage.governmentLoanDetail.vaResidualIncomeGuidelineAmount == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaAppraisalType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.vaAppraisalType)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaAppraisalTypeOtherDescription']?.required == MortgageFieldRequiredState.Requested && mortgage.governmentLoanDetail.vaAppraisalType == 'Other' && !(!!mortgage.governmentLoanDetail.vaAppraisalTypeOtherDescription)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaLoanProcedureType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.vaLoanProcedureType)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaLoanProcedureTypeOtherDescription']?.required == MortgageFieldRequiredState.Requested && mortgage.governmentLoanDetail.vaLoanProcedureType == 'Other' && !(!!mortgage.governmentLoanDetail.vaLoanProcedureTypeOtherDescription)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.vaTitleVestingType)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaTitleVestingTypeOtherDescription']?.required == MortgageFieldRequiredState.Requested && mortgage.governmentLoanDetail.vaTitleVestingType == 'Other' && !(!!mortgage.governmentLoanDetail.vaTitleVestingTypeOtherDescription)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount == null || mortgage.governmentLoanDetail.fhaEnergyRelatedRepairsOrImprovementsAmount == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.energyRelatedImprovementType)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.energyRelatedImprovementTypeOtherDescription']?.required == MortgageFieldRequiredState.Requested && mortgage.governmentLoanDetail.energyRelatedImprovementType == 'Other' && !(!!mortgage.governmentLoanDetail.energyRelatedImprovementTypeOtherDescription)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount == null || mortgage.governmentLoanDetail.vaMaintenanceExpenseMonthlyAmount == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount == null || mortgage.governmentLoanDetail.vaUtilityExpenseMonthlyAmount == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaExaminerComments']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.vaExaminerComments)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaClaimFolderIdentifier']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.vaClaimFolderIdentifier)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.loanMeetsVACreditStandards']?.required == MortgageFieldRequiredState.Requested && (mortgage.governmentLoanDetail.loanMeetsVACreditStandards == null || mortgage.governmentLoanDetail.loanMeetsVACreditStandards == undefined)) ||
        (mortgageFieldsConfig['mortgage.governmentLoanDetail.vaCreditStandardsDecisionRemarks']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.governmentLoanDetail.vaCreditStandardsDecisionRemarks)))
      ) ||
      (mortgageFieldsConfig['mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest']?.required == MortgageFieldRequiredState.Requested && (mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest == null || mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest == undefined)) ||
      (mortgageFieldsConfig['mortgage.proposedHousingExpense.homeownersInsurance']?.required == MortgageFieldRequiredState.Requested && (mortgage.proposedHousingExpense.homeownersInsurance == null || mortgage.proposedHousingExpense.homeownersInsurance == undefined)) ||
      (mortgageFieldsConfig['mortgage.proposedHousingExpense.supplementalPropertyInsurance']?.required == MortgageFieldRequiredState.Requested && (mortgage.proposedHousingExpense.supplementalPropertyInsurance == null || mortgage.proposedHousingExpense.supplementalPropertyInsurance == undefined)) ||
      (mortgageFieldsConfig['mortgage.proposedHousingExpense.realEstateTax']?.required == MortgageFieldRequiredState.Requested && (mortgage.proposedHousingExpense.realEstateTax == null || mortgage.proposedHousingExpense.realEstateTax == undefined)) ||
      (mortgageFieldsConfig['mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees']?.required == MortgageFieldRequiredState.Requested && (mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees == null || mortgage.proposedHousingExpense.homeownersAssociationDuesAndCondominiumFees == undefined)) ||
      (mortgageFieldsConfig['mortgage.proposedHousingExpense.otherHousingExpense']?.required == MortgageFieldRequiredState.Requested && (mortgage.proposedHousingExpense.otherHousingExpense == null || mortgage.proposedHousingExpense.otherHousingExpense == undefined)) ||
      (mortgageFieldsConfig['mortgage.proposedHousingExpense.otherHousingExpenseDescription']?.required == MortgageFieldRequiredState.Requested && mortgage.proposedHousingExpense.otherHousingExpense && !(!!mortgage.proposedHousingExpense.otherHousingExpenseDescription)) ||

      (mortgageFieldsConfig['mortgage.extension.balloonTermMonths']?.required == MortgageFieldRequiredState.Requested && mortgage.extension.isBalloonPaymentRequiredToPayOffLoan && (mortgage.extension.balloonTermMonths == null || mortgage.extension.balloonTermMonths == undefined || mortgage.extension.balloonTermMonths.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.extension.interestOnlyTermMonths']?.required == MortgageFieldRequiredState.Requested && mortgage.extension.isInterestOnly && (mortgage.extension.interestOnlyTermMonths == null || mortgage.extension.interestOnlyTermMonths == undefined || mortgage.extension.interestOnlyTermMonths.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.ausData.negativeAmortizationType']?.required == MortgageFieldRequiredState.Requested && mortgage.extension.hasNegativeAmortization && !(!!mortgage.ausData.negativeAmortizationType)) ||
      (mortgageFieldsConfig['mortgage.extension.buydownDetail.initialBuydownRate']?.required == MortgageFieldRequiredState.Requested && mortgage.extension.buydownDetail.hasBuydown && (mortgage.extension.buydownDetail.initialBuydownRate == null || mortgage.extension.buydownDetail.initialBuydownRate == undefined || mortgage.extension.buydownDetail.initialBuydownRate.toString() == '')) ||

      ((mortgage.mortgageTerm.amortizationType == this._amortizationTypeArmEnumValue) && (
        (mortgageFieldsConfig['mortgage.ausData.rateAdjustmentPeriodicCap']?.required == MortgageFieldRequiredState.Requested && (mortgage.ausData.rateAdjustmentPeriodicCap == null || mortgage.ausData.rateAdjustmentPeriodicCap == undefined || mortgage.ausData.rateAdjustmentPeriodicCap.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.ausData.firstRateAdjustmentMonths']?.required == MortgageFieldRequiredState.Requested && (mortgage.ausData.firstRateAdjustmentMonths == null || mortgage.ausData.firstRateAdjustmentMonths == undefined || mortgage.ausData.firstRateAdjustmentMonths.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.ausData.subsequentRateAdjustmentPeriodicCap']?.required == MortgageFieldRequiredState.Requested && (mortgage.ausData.subsequentRateAdjustmentPeriodicCap == null || mortgage.ausData.subsequentRateAdjustmentPeriodicCap == undefined || mortgage.ausData.subsequentRateAdjustmentPeriodicCap.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.ausData.subsequentRateAdjustmentMonths']?.required == MortgageFieldRequiredState.Requested && (mortgage.ausData.subsequentRateAdjustmentMonths == null || mortgage.ausData.subsequentRateAdjustmentMonths == undefined || mortgage.ausData.subsequentRateAdjustmentMonths.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.ausData.armRateAdjustmentLifetimeCap']?.required == MortgageFieldRequiredState.Requested && (mortgage.ausData.armRateAdjustmentLifetimeCap == null || mortgage.ausData.armRateAdjustmentLifetimeCap == undefined || mortgage.ausData.armRateAdjustmentLifetimeCap.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.ausData.armIndexCurrent']?.required == MortgageFieldRequiredState.Requested && (mortgage.ausData.armIndexCurrent == null || mortgage.ausData.armIndexCurrent == undefined || mortgage.ausData.armIndexCurrent.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.ausData.armMarginRate']?.required == MortgageFieldRequiredState.Requested && (mortgage.ausData.armMarginRate == null || mortgage.ausData.armMarginRate == undefined || mortgage.ausData.armMarginRate.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.ausData.armFullyIndexedRate']?.required == MortgageFieldRequiredState.Requested && (mortgage.ausData.armFullyIndexedRate == null || mortgage.ausData.armFullyIndexedRate == undefined || mortgage.ausData.armFullyIndexedRate.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.ausData.armIndexSourceType']?.required == MortgageFieldRequiredState.Requested && (mortgage.ausData.armIndexSourceType == null || mortgage.ausData.armIndexSourceType == undefined || mortgage.ausData.armIndexSourceType.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.ausData.armFloorRate']?.required == MortgageFieldRequiredState.Requested && (mortgage.ausData.armFloorRate == null || mortgage.ausData.armFloorRate == undefined || mortgage.ausData.armFloorRate.toString() == '')) ||
        (mortgageFieldsConfig['mortgage.ausData.firstPaymentAdjustmentMonths']?.required == MortgageFieldRequiredState.Requested && (mortgage.ausData.firstPaymentAdjustmentMonths == null || mortgage.ausData.firstPaymentAdjustmentMonths == undefined || mortgage.ausData.firstPaymentAdjustmentMonths.toString() == ''))

      ))
    ) {
      state = MenuItemStatus.Pending;
    }

    return state;
  }

  getStatusForQualifyingTheBorr = (mortgageFieldsConfig: MortgageModelFieldsConfig, mortgage: UrlaMortgage) => {
    let state = MenuItemStatus.Success;

    if (
      (mortgageFieldsConfig['mortgage.transactionDetail.purchasePriceAmount']?.required == MortgageFieldRequiredState.Required && !this._calculationService.isPurposeOfLoanRefinance(mortgage) && (mortgage.transactionDetail.purchasePriceAmount == null || mortgage.transactionDetail.purchasePriceAmount == undefined)) ||
      (mortgageFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreConsidered']?.required == MortgageFieldRequiredState.Required && (mortgage.extension.additionalBorrowerAssetsAreConsidered == null || mortgage.extension.additionalBorrowerAssetsAreConsidered == undefined)) ||
      (mortgageFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreConsidered']?.required == MortgageFieldRequiredState.Required && (mortgage.extension.additionalBorrowerAssetsAreConsidered == null || mortgage.extension.additionalBorrowerAssetsAreConsidered == undefined)) ||
      (mortgageFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreNotConsidered']?.required == MortgageFieldRequiredState.Required && (mortgage.extension.additionalBorrowerAssetsAreNotConsidered == null || mortgage.extension.additionalBorrowerAssetsAreNotConsidered == undefined)) ||
      (mortgageFieldsConfig['mortgage.transactionDetail.otherPartyPaidClosingCostsAmount']?.required == MortgageFieldRequiredState.Required && (mortgage.transactionDetail.otherPartyPaidClosingCostsAmount == null || mortgage.transactionDetail.otherPartyPaidClosingCostsAmount == undefined)) ||
      (mortgageFieldsConfig['mortgage.transactionDetail.personalPropertyInSalesContractAmount']?.required == MortgageFieldRequiredState.Required && (mortgage.transactionDetail.personalPropertyInSalesContractAmount == null || mortgage.transactionDetail.personalPropertyInSalesContractAmount == undefined)) ||
      (mortgageFieldsConfig['mortgage.transactionDetail.salesContractDate']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.transactionDetail.salesContractDate)) || (mortgage.transactionDetail.salesContractDate && this.checkIsInvalidDate(mortgage.transactionDetail.salesContractDate)) ||
      (mortgageFieldsConfig['mortgage.transactionDetail.cashToSellerAtClosingAmount']?.required == MortgageFieldRequiredState.Required && (mortgage.transactionDetail.cashToSellerAtClosingAmount == null || mortgage.transactionDetail.cashToSellerAtClosingAmount == undefined)) ||
      (mortgageFieldsConfig['mortgage.transactionDetail.estimatedClosingCostsExpiration']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.transactionDetail.estimatedClosingCostsExpiration)) ||
      (mortgageFieldsConfig['mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount']?.required == MortgageFieldRequiredState.Required && (mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount == null || mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount == undefined || mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.extension.hmdaPreapprovalType']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.extension.hmdaPreapprovalType)) ||
      (mortgageFieldsConfig['mortgage.extension.relatedInvestorLoanId']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.extension.relatedInvestorLoanId)) ||
      (mortgageFieldsConfig['mortgage.ausData.freddieMacRefinanceProgram']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.ausData.freddieMacRefinanceProgram)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.isLoanManuallyUnderwritten']?.required == MortgageFieldRequiredState.Required && (mortgage.mortgageTerm.isLoanManuallyUnderwritten == null || mortgage.mortgageTerm.isLoanManuallyUnderwritten == undefined)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.underwritingComments']?.required == MortgageFieldRequiredState.Required && !(!!mortgage.mortgageTerm.underwritingComments))
    ) {
      state = MenuItemStatus.Error;
      return state;
    }

    if (
      (mortgageFieldsConfig['mortgage.transactionDetail.purchasePriceAmount']?.required == MortgageFieldRequiredState.Requested && !this._calculationService.isPurposeOfLoanRefinance(mortgage) && (mortgage.transactionDetail.purchasePriceAmount == null || mortgage.transactionDetail.purchasePriceAmount == undefined)) ||
      (mortgageFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreConsidered']?.required == MortgageFieldRequiredState.Requested && (mortgage.extension.additionalBorrowerAssetsAreConsidered == null || mortgage.extension.additionalBorrowerAssetsAreConsidered == undefined)) ||
      (mortgageFieldsConfig['mortgage.extension.additionalBorrowerAssetsAreNotConsidered']?.required == MortgageFieldRequiredState.Requested && (mortgage.extension.additionalBorrowerAssetsAreNotConsidered == null || mortgage.extension.additionalBorrowerAssetsAreNotConsidered == undefined)) ||
      (mortgageFieldsConfig['mortgage.transactionDetail.otherPartyPaidClosingCostsAmount']?.required == MortgageFieldRequiredState.Requested && (mortgage.transactionDetail.otherPartyPaidClosingCostsAmount == null || mortgage.transactionDetail.otherPartyPaidClosingCostsAmount == undefined)) ||
      (mortgageFieldsConfig['mortgage.transactionDetail.personalPropertyInSalesContractAmount']?.required == MortgageFieldRequiredState.Requested && (mortgage.transactionDetail.personalPropertyInSalesContractAmount == null || mortgage.transactionDetail.personalPropertyInSalesContractAmount == undefined)) ||
      (mortgageFieldsConfig['mortgage.transactionDetail.salesContractDate']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.transactionDetail.salesContractDate)) ||
      (mortgageFieldsConfig['mortgage.transactionDetail.cashToSellerAtClosingAmount']?.required == MortgageFieldRequiredState.Requested && (mortgage.transactionDetail.cashToSellerAtClosingAmount == null || mortgage.transactionDetail.cashToSellerAtClosingAmount == undefined)) ||
      (mortgageFieldsConfig['mortgage.transactionDetail.estimatedClosingCostsExpiration']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.transactionDetail.estimatedClosingCostsExpiration)) ||
      (mortgageFieldsConfig['mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount']?.required == MortgageFieldRequiredState.Requested && (mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount == null || mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount == undefined || mortgage.transactionDetail.borrowerReservesMonthlyPaymentCount.toString() == '')) ||
      (mortgageFieldsConfig['mortgage.extension.hmdaPreapprovalType']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.extension.hmdaPreapprovalType)) ||
      (mortgageFieldsConfig['mortgage.extension.relatedInvestorLoanId']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.extension.relatedInvestorLoanId)) ||
      (mortgageFieldsConfig['mortgage.ausData.freddieMacRefinanceProgram']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.ausData.freddieMacRefinanceProgram)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.isLoanManuallyUnderwritten']?.required == MortgageFieldRequiredState.Requested && (mortgage.mortgageTerm.isLoanManuallyUnderwritten == null || mortgage.mortgageTerm.isLoanManuallyUnderwritten == undefined)) ||
      (mortgageFieldsConfig['mortgage.mortgageTerm.underwritingComments']?.required == MortgageFieldRequiredState.Requested && !(!!mortgage.mortgageTerm.underwritingComments))
    ) {
      state = MenuItemStatus.Pending;
    }

    if (mortgage.ausData.ausResults && mortgage.ausData.ausResults.length > 0) {
      let isError = mortgage.ausData.ausResults.some(ausResult =>
        (mortgageFieldsConfig['mortgage.ausData.ausResult.caseIdentifier']?.required == MortgageFieldRequiredState.Required && !(!!ausResult.caseIdentifier)) ||
        (mortgageFieldsConfig['mortgage.ausData.ausResult.ausSystem']?.required == MortgageFieldRequiredState.Required && !(!!ausResult.ausSystem)) ||
        (mortgageFieldsConfig['mortgage.ausData.ausResult.ausSystemOtherDescription']?.required == MortgageFieldRequiredState.Required && ausResult.ausSystem == 'Other' && !(!!ausResult.ausSystemOtherDescription)) ||
        (mortgageFieldsConfig['mortgage.ausData.ausResult.decisionDateTime']?.required == MortgageFieldRequiredState.Required && !(!!ausResult.decisionDateTime)) ||
        (mortgageFieldsConfig['mortgage.ausData.ausResult.ausResultValue']?.required == MortgageFieldRequiredState.Required && !(!!ausResult.ausResultValue)) ||
        (mortgageFieldsConfig['mortgage.ausData.ausResult.recommendationDescription']?.required == MortgageFieldRequiredState.Required && !(!!ausResult.recommendationDescription)) ||
        (mortgageFieldsConfig['mortgage.ausData.ausResult.includesDocumentWaiver']?.required == MortgageFieldRequiredState.Required && (ausResult.includesDocumentWaiver == null || ausResult.includesDocumentWaiver == undefined))
      );
      if (isError) {
        state = MenuItemStatus.Error;
        return state;
      }
      let isPending = mortgage.ausData.ausResults.some(ausResult =>
        (mortgageFieldsConfig['mortgage.ausData.ausResult.caseIdentifier']?.required == MortgageFieldRequiredState.Requested && !(!!ausResult.caseIdentifier)) ||
        (mortgageFieldsConfig['mortgage.ausData.ausResult.ausSystem']?.required == MortgageFieldRequiredState.Requested && !(!!ausResult.ausSystem)) ||
        (mortgageFieldsConfig['mortgage.ausData.ausResult.ausSystemOtherDescription']?.required == MortgageFieldRequiredState.Requested && ausResult.ausSystem == 'Other' && !(!!ausResult.ausSystemOtherDescription)) ||
        (mortgageFieldsConfig['mortgage.ausData.ausResult.decisionDateTime']?.required == MortgageFieldRequiredState.Requested && !(!!ausResult.decisionDateTime)) ||
        (mortgageFieldsConfig['mortgage.ausData.ausResult.ausResultValue']?.required == MortgageFieldRequiredState.Requested && !(!!ausResult.ausResultValue)) ||
        (mortgageFieldsConfig['mortgage.ausData.ausResult.recommendationDescription']?.required == MortgageFieldRequiredState.Requested && !(!!ausResult.recommendationDescription)) ||
        (mortgageFieldsConfig['mortgage.ausData.ausResult.includesDocumentWaiver']?.required == MortgageFieldRequiredState.Requested && (ausResult.includesDocumentWaiver == null || ausResult.includesDocumentWaiver == undefined))
      );
      if (isPending) {
        state = MenuItemStatus.Pending;
      }
    }
    return state;
  }

  getStatusForHomeownershipEducation = (mortgageFieldsConfig: MortgageModelFieldsConfig, mortgage: UrlaMortgage): MenuItemStatus => {
    let state = MenuItemStatus.Success;

    if (mortgage.borrowers && mortgage.borrowers.length > 0) {
      mortgage.borrowers.forEach(borrower => {
        let homeEduState = this.getStatusForBorrowerHomeownershipEducation(mortgageFieldsConfig, borrower);
        if (state == MenuItemStatus.Error) {
        }
        else if (homeEduState == MenuItemStatus.Error || homeEduState == MenuItemStatus.Pending) {
          state = homeEduState;
        }
      });
    }

    return state;
  }

  getStatusForBorrowerHomeownershipEducation = (mortgageFieldsConfig: MortgageModelFieldsConfig, borrower: MortgageBorrower) => {
    let state = MenuItemStatus.Success;

    if (borrower.counselingEvents && borrower.counselingEvents.length > 0) {
      let isError = borrower.counselingEvents.some(counselingEvent =>
        (mortgageFieldsConfig['mortgage.borrower.counselingEvent.completedDate']?.required == MortgageFieldRequiredState.Required && !(!!counselingEvent.completedDate)) || (counselingEvent.completedDate && this.checkIsInvalidDate(counselingEvent.completedDate)) ||
        (mortgageFieldsConfig['mortgage.borrower.counselingEvent.format']?.required == MortgageFieldRequiredState.Required && !(!!counselingEvent.format)) ||
        (mortgageFieldsConfig['mortgage.borrower.counselingEvent.formatOtherDescription']?.required == MortgageFieldRequiredState.Required && counselingEvent.format == 'Other' && !(!!counselingEvent.formatOtherDescription)) ||
        (mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyIdentifier']?.required == MortgageFieldRequiredState.Required && !(!!counselingEvent.counselingAgencyIdentifier)) ||
        (mortgageFieldsConfig['mortgage.borrower.counselingEvent.agencyName']?.required == MortgageFieldRequiredState.Required && !(!!counselingEvent.counselingAgencyName)) ||
        (mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress1']?.required == MortgageFieldRequiredState.Required && !(!!counselingEvent.counselingAgencyAddress1)) ||
        (mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress2']?.required == MortgageFieldRequiredState.Required && !(!!counselingEvent.counselingAgencyAddress2)) ||
        (mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyCity']?.required == MortgageFieldRequiredState.Required && !(!!counselingEvent.counselingAgencyCity)) ||
        (mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyState']?.required == MortgageFieldRequiredState.Required && !(!!counselingEvent.counselingAgencyState)) ||
        (mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyZipCode']?.required == MortgageFieldRequiredState.Required && !(!!counselingEvent.counselingAgencyZipCode))
      );
      if (isError) {
        state = MenuItemStatus.Error;
        return state;
      }
      let isPending = borrower.counselingEvents.some(counselingEvent =>
        (mortgageFieldsConfig['mortgage.borrower.counselingEvent.completedDate']?.required == MortgageFieldRequiredState.Requested && !(!!counselingEvent.completedDate)) ||
        (mortgageFieldsConfig['mortgage.borrower.counselingEvent.format']?.required == MortgageFieldRequiredState.Requested && !(!!counselingEvent.format)) ||
        (mortgageFieldsConfig['mortgage.borrower.counselingEvent.formatOtherDescription']?.required == MortgageFieldRequiredState.Requested && counselingEvent.format == 'Other' && !(!!counselingEvent.formatOtherDescription)) ||
        (mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyIdentifier']?.required == MortgageFieldRequiredState.Requested && !(!!counselingEvent.counselingAgencyIdentifier)) ||
        (mortgageFieldsConfig['mortgage.borrower.counselingEvent.agencyName']?.required == MortgageFieldRequiredState.Requested && !(!!counselingEvent.counselingAgencyName)) ||
        (mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress1']?.required == MortgageFieldRequiredState.Requested && !(!!counselingEvent.counselingAgencyAddress1)) ||
        (mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyAddress2']?.required == MortgageFieldRequiredState.Requested && !(!!counselingEvent.counselingAgencyAddress2)) ||
        (mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyCity']?.required == MortgageFieldRequiredState.Requested && !(!!counselingEvent.counselingAgencyCity)) ||
        (mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyState']?.required == MortgageFieldRequiredState.Requested && !(!!counselingEvent.counselingAgencyState)) ||
        (mortgageFieldsConfig['mortgage.borrower.counselingEvent.counselingAgencyZipCode']?.required == MortgageFieldRequiredState.Requested && !(!!counselingEvent.counselingAgencyZipCode))
      );
      if (isPending) {
        state = MenuItemStatus.Pending;
      }
    }
    return state;
  }

  getStatusForUrla = (mortgageFieldsConfig: MortgageModelFieldsConfig, mortgage: UrlaMortgage, isMersEnabled?: boolean, isRequiredLoanAndCaseNumbers?: boolean, isTpo: boolean = false) => {
    let borrowerInfoStatus = this.getStatusForBorrowersInfo(mortgageFieldsConfig, mortgage, isTpo);
    let financialInfoStatus = this.getStatusForFinancialInfo(mortgageFieldsConfig, mortgage);
    let reoStatus = this.getStatusForReo(mortgageFieldsConfig, mortgage);
    let loanPropertyInfoStatus = this.getStatusForLoanProperty(mortgageFieldsConfig, mortgage, false);
    let declarationsStatus = this.getStatusForDeclarations(mortgageFieldsConfig, mortgage);
    let militaryStatus = this.getStatusForMilitaryService(mortgageFieldsConfig, mortgage);
    let demographicsStatus = this.getStatusForDemographics(mortgageFieldsConfig, mortgage);
    let loanOriginatorInfoStatus = this.getStatusForLoanOriginatorInfo(mortgageFieldsConfig, mortgage, isMersEnabled || false, isRequiredLoanAndCaseNumbers || true);
    let propertyLoanInfoStatus = this.getStatusForPropertyLoanInfo(mortgageFieldsConfig, mortgage);
    let titleInfoStatus = this.getStatusForTitleInfo(mortgageFieldsConfig, mortgage);
    let mortgageLoanInfoStatus = this.getStatusForMortgageLoanInfo(mortgageFieldsConfig, mortgage);
    let qualifyingTheBorrStatus = this.getStatusForQualifyingTheBorr(mortgageFieldsConfig, mortgage);
    let homeownershipEducationStatus = this.getStatusForHomeownershipEducation(mortgageFieldsConfig, mortgage);
    let reverseMortgageLoanInfoStatus = this.getStatusForReverseMortgageLoanInfo(mortgageFieldsConfig, mortgage);

    const isReverseMortgage = mortgage.isReverseMortgage;

    if (isReverseMortgage) {
      if (borrowerInfoStatus == MenuItemStatus.Error || financialInfoStatus == MenuItemStatus.Error || reoStatus == MenuItemStatus.Error ||
        loanPropertyInfoStatus == MenuItemStatus.Error || reverseMortgageLoanInfoStatus == MenuItemStatus.Error ||
        declarationsStatus == MenuItemStatus.Error || demographicsStatus == MenuItemStatus.Error || loanOriginatorInfoStatus == MenuItemStatus.Error ||
        titleInfoStatus == MenuItemStatus.Error || mortgageLoanInfoStatus == MenuItemStatus.Error || qualifyingTheBorrStatus == MenuItemStatus.Error ||
        homeownershipEducationStatus == MenuItemStatus.Error) {
  
        return MenuItemStatus.Error;
      }
  
      else if (borrowerInfoStatus == MenuItemStatus.Pending || financialInfoStatus == MenuItemStatus.Pending || reoStatus == MenuItemStatus.Pending ||
        loanPropertyInfoStatus == MenuItemStatus.Pending || reverseMortgageLoanInfoStatus == MenuItemStatus.Pending || 
        declarationsStatus == MenuItemStatus.Pending || demographicsStatus == MenuItemStatus.Pending || loanOriginatorInfoStatus == MenuItemStatus.Pending ||
        titleInfoStatus == MenuItemStatus.Pending || mortgageLoanInfoStatus == MenuItemStatus.Pending || qualifyingTheBorrStatus == MenuItemStatus.Pending ||
        homeownershipEducationStatus == MenuItemStatus.Pending) {
  
        return MenuItemStatus.Pending;
      }
  
      else {
        return MenuItemStatus.Success;
      }

    } else {
      if (borrowerInfoStatus == MenuItemStatus.Error || financialInfoStatus == MenuItemStatus.Error || reoStatus == MenuItemStatus.Error ||
        loanPropertyInfoStatus == MenuItemStatus.Error || declarationsStatus == MenuItemStatus.Error || militaryStatus == MenuItemStatus.Error ||
        demographicsStatus == MenuItemStatus.Error || loanOriginatorInfoStatus == MenuItemStatus.Error || propertyLoanInfoStatus == MenuItemStatus.Error ||
        titleInfoStatus == MenuItemStatus.Error || mortgageLoanInfoStatus == MenuItemStatus.Error || qualifyingTheBorrStatus == MenuItemStatus.Error ||
        homeownershipEducationStatus == MenuItemStatus.Error) {
  
        return MenuItemStatus.Error;
      }
  
      else if (borrowerInfoStatus == MenuItemStatus.Pending || financialInfoStatus == MenuItemStatus.Pending || reoStatus == MenuItemStatus.Pending ||
        loanPropertyInfoStatus == MenuItemStatus.Pending || declarationsStatus == MenuItemStatus.Pending || militaryStatus == MenuItemStatus.Pending ||
        demographicsStatus == MenuItemStatus.Pending || loanOriginatorInfoStatus == MenuItemStatus.Pending || propertyLoanInfoStatus == MenuItemStatus.Pending ||
        titleInfoStatus == MenuItemStatus.Pending || mortgageLoanInfoStatus == MenuItemStatus.Pending || qualifyingTheBorrStatus == MenuItemStatus.Pending ||
        homeownershipEducationStatus == MenuItemStatus.Pending) {
  
        return MenuItemStatus.Pending;
      }
  
      else {
        return MenuItemStatus.Success;
      }
    }

  }

  checkIsInvalidPhoneNumber = (phoneNumber: string) => {
    let re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    return !re.test(phoneNumber);
  }


  checkIsInvalidEmail = (email: string) => {
    return !Constants.regexPatterns.email.test(email);
  }

  checkIsInvalidSsn = (ssn: string) => {
    return !Constants.regexPatterns.ssn.test(ssn);
  }

  checkIsInvalidDate = (date, maxDate?, minDate?) => {
    let invalidDate = false;
    if (date.length === 10) {
      invalidDate = this.validateFullDate(date);
      if (invalidDate) {
        return true;
      }
      invalidDate = this.validateDay(date);
      if (invalidDate) {
        return true;
      }
      invalidDate = this.validateMonth(date);
      if (invalidDate) {
        return true;
      }
      invalidDate = this.validateMax(date, maxDate);
      if (invalidDate) {
        return true;
      }
      invalidDate = this.validateMin(date, minDate);
      if (invalidDate) {
        return true;
      }
    } else {
      return true;
    }

    return false;
  }

  private checkIfAnyOfLoanOriginationFieldsAreInvalid = (mortgageFieldsConfig: MortgageModelFieldsConfig, mortgage: UrlaMortgage, requiredStateToCheck: MortgageFieldRequiredState, 
    isMersEnabled?: boolean, isRequiredLoanAndCaseNumbers?: boolean, isTpo: boolean = false) => {

    const isLenderCaseNumberInvalid = (mortgageFieldsConfig['mortgage.mortgageTerm.lenderCaseNumber']?.required == requiredStateToCheck && isRequiredLoanAndCaseNumbers && !(!!mortgage.mortgageTerm.lenderCaseNumber));
    const isMersMortgageIdentifierInvalid = (mortgageFieldsConfig['mortgage.mortgageTerm.mersMortgageIdentifier']?.required == requiredStateToCheck && isMersEnabled && !(!!mortgage.mortgageTerm.mersMortgageIdentifier));
    const isUniversalLoanNumberInvalid = (mortgageFieldsConfig['mortgage.mortgageTerm.universalLoanNumber']?.required == requiredStateToCheck && isRequiredLoanAndCaseNumbers && !(!!mortgage.mortgageTerm.universalLoanNumber));
    const isAgencyCaseNumberInvalid = (mortgageFieldsConfig['mortgage.mortgageTerm.agencyCaseNumber']?.required == requiredStateToCheck && isRequiredLoanAndCaseNumbers && ['VA', 'FHA', 'USDA'].indexOf(mortgage.mortgageTerm?.mortgageAppliedFor) > -1 && !(!!mortgage.mortgageTerm.agencyCaseNumber));
    const isLenderLoanNumberInvalid = (mortgageFieldsConfig['mortgage.mortgageTerm.lenderLoanNumber']?.required == requiredStateToCheck && !(!!mortgage.mortgageTerm.lenderLoanNumber));
    const isClosingAgentOrderNumberInvalid = (mortgageFieldsConfig['mortgage.mortgageTerm.closingAgentOrderNumber']?.required == requiredStateToCheck && !(!!mortgage.mortgageTerm.closingAgentOrderNumber));
    const isOrganizationNameInvalid = (mortgageFieldsConfig['mortgage.originatorInformation.organizationName']?.required == requiredStateToCheck && !(!!mortgage.originatorInformation.organizationName));
    const isOrganizationNmlsInvalid = (mortgageFieldsConfig['mortgage.originatorInformation.organizationNmls']?.required == requiredStateToCheck && !(!!mortgage.originatorInformation.organizationNmls));
    const isOrganizationStateLicenseStateInvalid = (mortgageFieldsConfig['mortgage.originatorInformation.organizationStateLicenseState']?.required == requiredStateToCheck && !(!!mortgage.originatorInformation.organizationStateLicenseState));
    const isOrganizationStateLicenseInvalid = (mortgageFieldsConfig['mortgage.originatorInformation.organizationStateLicense']?.required == requiredStateToCheck && this.hasStateLicense(mortgage.originatorInformation.organizationStateLicenseState) && !(!!mortgage.originatorInformation.organizationStateLicense));
    const isOrganizationVaLenderIdInvalid = (mortgageFieldsConfig['mortgage.originatorInformation.organizationVaLenderId']?.required == requiredStateToCheck && this._calculationService.isVaLoan(mortgage) && !(!!mortgage.originatorInformation.organizationVaLenderId));
    const isOrganizationVaSponsorIdInvalid = (mortgageFieldsConfig['mortgage.originatorInformation.organizationVaSponsorId']?.required == requiredStateToCheck && this._calculationService.isVaLoan(mortgage) && !(!!mortgage.originatorInformation.organizationVaSponsorId));
    const isOriginatorFhaLenderIdInvalid = (mortgageFieldsConfig['mortgage.originatorInformation.originatorFhaLenderId']?.required == requiredStateToCheck && this._calculationService.isFhaLoan(mortgage) && !(!!mortgage.originatorInformation.originatorFhaLenderId));
    const isOriginatorFhaSponsorIdInvalid = (mortgageFieldsConfig['mortgage.originatorInformation.originatorFhaSponsorId']?.required == requiredStateToCheck && this._calculationService.isFhaLoan(mortgage) && !(!!mortgage.originatorInformation.originatorFhaSponsorId));
    const isOrganizationAddress1Invalid = (mortgageFieldsConfig['mortgage.originatorInformation.organizationAddress1']?.required == requiredStateToCheck && !(!!mortgage.originatorInformation.organizationAddress1));
    const isOrganizationAddress2Invalid = (mortgageFieldsConfig['mortgage.originatorInformation.organizationAddress2']?.required == requiredStateToCheck && !(!!mortgage.originatorInformation.organizationAddress2));
    const isOrganizationCityInvalid = (mortgageFieldsConfig['mortgage.originatorInformation.organizationCity']?.required == requiredStateToCheck && !(!!mortgage.originatorInformation.organizationCity));
    const isOrganizationStateInvalid = (mortgageFieldsConfig['mortgage.originatorInformation.organizationState']?.required == requiredStateToCheck && !(!!mortgage.originatorInformation.organizationState));
    const isOrganizationZipCodeInvalid = (mortgageFieldsConfig['mortgage.originatorInformation.organizationZipCode']?.required == requiredStateToCheck && !(!!mortgage.originatorInformation.organizationZipCode));
    const isOriginatorUnderwriterIdInvalid = (mortgageFieldsConfig['mortgage.originatorInformation.underwriterId']?.required == requiredStateToCheck && !(!!mortgage.originatorInformation.underwriterId));
    const isOriginatorNameInvalid = (mortgageFieldsConfig['mortgage.originatorInformation.name']?.required == requiredStateToCheck && !(!!mortgage.originatorInformation.name));
    const isOriginatorNmlsInvalid = (mortgageFieldsConfig['mortgage.originatorInformation.nmls']?.required == requiredStateToCheck && !(!!mortgage.originatorInformation.nmls));
    const isOriginatorStateLicenseStateInvalid = (mortgageFieldsConfig['mortgage.originatorInformation.stateLicenseState']?.required == requiredStateToCheck && !(!!mortgage.originatorInformation.stateLicenseState));
    const isOriginatorStateLicenseInvalid = (mortgageFieldsConfig['mortgage.originatorInformation.stateLicense']?.required == requiredStateToCheck && this.hasStateLicense(mortgage.originatorInformation.stateLicenseState) && !(!!mortgage.originatorInformation.stateLicense));
    const isOriginatorApplicationSignedDateInvalid = (mortgageFieldsConfig['mortgage.originatorInformation.applicationSignedDate']?.required == requiredStateToCheck && !(!!mortgage.originatorInformation.applicationSignedDate)) || (mortgage.originatorInformation.applicationSignedDate && this.checkIsInvalidDate(mortgage.originatorInformation.applicationSignedDate));
    const isOriginatorEmailInvalid = (mortgageFieldsConfig['mortgage.originatorInformation.email']?.required == requiredStateToCheck && !(!!mortgage.originatorInformation.email)) || (mortgage.originatorInformation.email && this.checkIsInvalidEmail(mortgage.originatorInformation.email));
    const isOriginatorPhoneInvalid = (mortgageFieldsConfig['mortgage.originatorInformation.phone']?.required == requiredStateToCheck && !(!!mortgage.originatorInformation.phone)) || (mortgage.originatorInformation.phone && this.checkIsInvalidPhoneNumber(mortgage.originatorInformation.phone));
    const isOriginatorFaxInvalid = (mortgageFieldsConfig['mortgage.originatorInformation.fax']?.required == requiredStateToCheck && !(!!mortgage.originatorInformation.fax)) || (mortgage.originatorInformation.fax && this.checkIsInvalidPhoneNumber(mortgage.originatorInformation.fax));
    
    return isLenderCaseNumberInvalid || isMersMortgageIdentifierInvalid || isUniversalLoanNumberInvalid || isAgencyCaseNumberInvalid || isLenderLoanNumberInvalid || isClosingAgentOrderNumberInvalid || 
      isOrganizationNameInvalid || isOrganizationNmlsInvalid || isOrganizationStateLicenseStateInvalid || isOrganizationStateLicenseInvalid || isOrganizationVaLenderIdInvalid || isOrganizationVaSponsorIdInvalid || 
      isOriginatorFhaLenderIdInvalid || isOriginatorFhaSponsorIdInvalid || isOrganizationAddress1Invalid || isOrganizationAddress2Invalid || isOrganizationCityInvalid || isOrganizationStateInvalid || isOrganizationZipCodeInvalid || 
      isOriginatorUnderwriterIdInvalid || isOriginatorNameInvalid || isOriginatorNmlsInvalid || isOriginatorStateLicenseStateInvalid || isOriginatorStateLicenseInvalid || isOriginatorApplicationSignedDateInvalid || 
      isOriginatorEmailInvalid || isOriginatorPhoneInvalid || isOriginatorFaxInvalid;
  }

  private checkIfAnyOfBorrowerContactInfoFieldsAreInvalid = (borrower: MortgageBorrower, mortgageFieldsConfig: MortgageModelFieldsConfig,
    isTitleOnlyOrNonTitledSpouse: boolean, isTpo: boolean, isCorrespondent: boolean, requiredStateToCheck: MortgageFieldRequiredState): boolean => {

    const unmarriedAddendum = borrower.domesticRelationshipType != null ||
      borrower.unmarriedRelationshipState != null;

    const borrowerAuthorizedCreditCheck = (borrower.authorizedCreditCheck == true);

    const isFirstNameInvalid = mortgageFieldsConfig['mortgage.borrower.firstName']?.required == requiredStateToCheck && !(!!borrower.firstName);
    const isMiddleNameInvalid = mortgageFieldsConfig['mortgage.borrower.middleName']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!borrower.middleName);
    const isLastNameInvalid = mortgageFieldsConfig['mortgage.borrower.lastName']?.required == requiredStateToCheck && !(!!borrower.lastName);
    const isNameSuffixInvalid = mortgageFieldsConfig['mortgage.borrower.nameSuffix']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!borrower.nameSuffix);
    const isSigningRoleInvalid = mortgageFieldsConfig['mortgage.borrower.signingRole']?.required == requiredStateToCheck && !(!!borrower.signingRole);
    const isPrimaryEmailInvalid = mortgageFieldsConfig['mortgage.borrower.primaryEmail']?.required == requiredStateToCheck && !(!!borrower.primaryEmail) || (borrower.primaryEmail && this.checkIsInvalidEmail(borrower.primaryEmail));
    const isSecondaryEmailInvalid = mortgageFieldsConfig['mortgage.borrower.secondaryEmail']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!borrower.secondaryEmail) || (borrower.secondaryEmail && this.checkIsInvalidEmail(borrower.secondaryEmail));
    const isMobilePhoneInvalid = mortgageFieldsConfig['mortgage.borrower.mobilePhone']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!borrower.mobilePhone) || (borrower.mobilePhone && this.checkIsInvalidPhoneNumber(borrower.mobilePhone));
    const isHomePhoneInvalid = mortgageFieldsConfig['mortgage.borrower.homePhone']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!borrower.homePhone) || (borrower.homePhone && this.checkIsInvalidPhoneNumber(borrower.homePhone));
    const isSsnInvalid = mortgageFieldsConfig['mortgage.borrower.socialSecNum']?.required == requiredStateToCheck && !(!!borrower.socialSecNum) || (borrower.socialSecNum && this.checkIsInvalidSsn(borrower.socialSecNum));
    const isDateOfBirthInvalid = mortgageFieldsConfig['mortgage.borrower.dateOfBirth']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!borrower.dateOfBirth) || (borrower.dateOfBirth && this.checkIsInvalidDate(borrower.dateOfBirth));
    const isResidenceStatusInvalid = mortgageFieldsConfig['mortgage.borrower.declarations.residenceStatus']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!borrower.declarations.residenceStatus);
    const isMaritalStatusInvalid = mortgageFieldsConfig['mortgage.borrower.maritalStatus']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!borrower.maritalStatus);
    const isEntityVestingTypeInvalid = mortgageFieldsConfig['mortgage.borrower.entityVestingType']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!borrower.entityVestingType);
    const isLanguagePreferenceInvalid = mortgageFieldsConfig['mortgage.borrower.languagePreference']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !isTitleOnlyOrNonTitledSpouse && !borrower.hasRefusedToProvideLanguage && !(!!borrower.languagePreference);
    const isDateAuthorizedCreditCheckInvalid = mortgageFieldsConfig['mortgage.borrower.dateAuthorizedCreditCheck']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && borrowerAuthorizedCreditCheck && !(!!borrower.dateAuthorizedCreditCheck) ||
      (!isTitleOnlyOrNonTitledSpouse && borrowerAuthorizedCreditCheck && borrower.dateAuthorizedCreditCheck && this.checkIsInvalidDate(borrower.dateAuthorizedCreditCheck));
    const isAuthorizationMethodInvalid = mortgageFieldsConfig['mortgage.borrower.authorizationMethod']?.required == requiredStateToCheck && !isTpo && !isTitleOnlyOrNonTitledSpouse && borrowerAuthorizedCreditCheck && !(!!borrower.authorizationMethod);
    const isCreditRefNumberInvalid = mortgageFieldsConfig['mortgage.borrower.creditRefNumber']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !isCorrespondent && !(!!borrower.creditRefNumber);
    const isCreditScoreInvalid = mortgageFieldsConfig['mortgage.borrower.creditScore']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!borrower.creditScore);
    const isDomesticRelationshipTypeInvalid = mortgageFieldsConfig['mortgage.borrower.domesticRelationshipType']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && unmarriedAddendum && !(!!borrower.domesticRelationshipType);
    const isUnmarriedRelationshipStateInvalid = mortgageFieldsConfig['mortgage.borrower.unmarriedRelationshipState']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && unmarriedAddendum && !(!!borrower.unmarriedRelationshipState);
    const isUnmarriedRelationshipTypeOtherInvalid = mortgageFieldsConfig['mortgage.borrower.unmarriedRelationshipTypeOther']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && unmarriedAddendum && borrower.domesticRelationshipType == DomesticRelationshipTypeEnum.Other && !(!!borrower.unmarriedRelationshipTypeOther);

    return isFirstNameInvalid || isMiddleNameInvalid || isLastNameInvalid || isNameSuffixInvalid || isSigningRoleInvalid || isPrimaryEmailInvalid ||
      isSecondaryEmailInvalid || isMobilePhoneInvalid || isHomePhoneInvalid || isSsnInvalid || isDateOfBirthInvalid || isResidenceStatusInvalid || isMaritalStatusInvalid ||
      isEntityVestingTypeInvalid || isLanguagePreferenceInvalid || isDateAuthorizedCreditCheckInvalid || isAuthorizationMethodInvalid || isCreditRefNumberInvalid ||
      isCreditScoreInvalid || isDomesticRelationshipTypeInvalid || isUnmarriedRelationshipStateInvalid || isUnmarriedRelationshipTypeOtherInvalid;
  }

  private checkIfAnyOfBorrowerAddressFieldsAreInvalid = (borrower: MortgageBorrower, address: ResidencyAddress, mortgageFieldsConfig: MortgageModelFieldsConfig, isTitleOnlyOrNonTitledSpouse: boolean,
    requiredStateToCheck: MortgageFieldRequiredState
  ): boolean => {
    const isAddress1Invalid = mortgageFieldsConfig['mortgage.borrower.residencyAddress.address1']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!address.address?.address1);
    const isAddress2Invalid = mortgageFieldsConfig['mortgage.borrower.residencyAddress.address2']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!address.address?.address2);
    const isCityInvalid = mortgageFieldsConfig['mortgage.borrower.residencyAddress.city']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!address.address?.city);
    const isStateInvalid = mortgageFieldsConfig['mortgage.borrower.residencyAddress.state']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!address.address?.state) && address.address?.country == 'us';
    const isZipcodeInvalid = mortgageFieldsConfig['mortgage.borrower.residencyAddress.zipCode']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!address.address?.zipCode) && address.address?.country == 'us';
    const isCountyInvalid = mortgageFieldsConfig['mortgage.borrower.residencyAddress.country']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!address.address?.country);
    const isResidencyBasisInvalid = mortgageFieldsConfig['mortgage.borrower.residencyAddress.residencyBasis']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && (address.residencyType == ResidencyType.FormerAddress ||
      (address.residencyType == ResidencyType.PresentAddress && (borrower.signingRole == 'Borrower' || borrower.signingRole == 'CoSigner'))) && !(!!address.residencyBasis);
    const isDurationAddressInvalid = mortgageFieldsConfig['mortgage.borrower.residencyAddress.durationAtAddress']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && (address.residencyType == ResidencyType.FormerAddress ||
      (address.residencyType == ResidencyType.PresentAddress && (borrower.signingRole == 'Borrower' || borrower.signingRole == 'CoSigner'))) && !(!!address.durationMonths) && !(!!address.durationYears);
    const isRentInvalid = mortgageFieldsConfig['mortgage.borrower.residencyAddress.rent']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && (address.residencyType == ResidencyType.FormerAddress ||
      (address.residencyType == ResidencyType.PresentAddress && (borrower.signingRole == 'Borrower' || borrower.signingRole == 'CoSigner'))) && address.residencyBasis == 'Rent' && !(!!address.rent);

    const areAnyOfAddressFieldsInvalid =
      isAddress1Invalid || isAddress2Invalid || isCityInvalid || isStateInvalid || isZipcodeInvalid || isCountyInvalid || isResidencyBasisInvalid ||
      isDurationAddressInvalid || isRentInvalid;
    return areAnyOfAddressFieldsInvalid;
  }

  private checkIfAnyOfBorrowerEmploymentFieldsAreInvalid = (employment: Employment, isTitleOnlyOrNonTitledSpouse: boolean,
    mortgageFieldsConfig: MortgageModelFieldsConfig, requiredStateToCheck: MortgageFieldRequiredState): boolean => {
    const isEmployerInvalid = mortgageFieldsConfig['mortgage.borrower.employment.employer']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!employment.employer);
    const isPositionInvalid = mortgageFieldsConfig['mortgage.borrower.employment.position']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!employment.position);
    const isStartDateInvalid = (mortgageFieldsConfig['mortgage.borrower.employment.startDate']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!employment.startDate)) || (employment.startDate && this.checkIsInvalidDate(employment.startDate));
    const areStartEndDatesValid = (mortgageFieldsConfig['mortgage.borrower.employment.endDate']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && employment.employmentType == this._formerEmployerEnumValue && !(!!employment.endDate)) ||
      (employment.employmentType == this._formerEmployerEnumValue && employment.startDate && !this.checkIsInvalidDate(employment.startDate) && employment.endDate && this.checkIsInvalidDate(employment.endDate, null, employment.startDate));
    const isAddress1Invalid = (mortgageFieldsConfig['mortgage.borrower.employment.address.address1']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!employment.address.address1));
    const isAddress2Invalid = (mortgageFieldsConfig['mortgage.borrower.employment.address.address2']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!employment.address.address2));
    const isCityInvalid = (mortgageFieldsConfig['mortgage.borrower.employment.address.city']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!employment.address.city));
    const isStateInvalid = (mortgageFieldsConfig['mortgage.borrower.employment.address.state']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!employment.address.state) && employment.address.country == 'us');
    const isZipcodeInvalid = (mortgageFieldsConfig['mortgage.borrower.employment.address.zipCode']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!employment.address.zipCode) && employment.address.country == 'us');
    const isCountryValid = (mortgageFieldsConfig['mortgage.borrower.employment.address.country']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!employment.address.country));
    const isEmployerPhoneInvalid = (mortgageFieldsConfig['mortgage.borrower.employment.employerPhone']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!employment.employerPhone)) || (employment.employerPhone && this.checkIsInvalidPhoneNumber(employment.employerPhone));
    const isYearsInLineOfWorkInvalid = (mortgageFieldsConfig['mortgage.borrower.employment.yearsInLineOfWork']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && (employment.yearsInLineOfWork == null || employment.yearsInLineOfWork == undefined || employment.yearsInLineOfWork.toString() == ''));
    const isSelfEmployedInvalid = (mortgageFieldsConfig['mortgage.borrower.employment.selfEmployed']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && (employment.selfEmployed == null || employment.selfEmployed == undefined));
    const isBorrowerOwnershipShareInvalid = (mortgageFieldsConfig['mortgage.borrower.employment.borrowerOwnershipShare']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && employment.selfEmployed && !(!!employment.borrowerOwnershipShare));
    const isSelfEmploymentFormInvalid = (mortgageFieldsConfig['mortgage.borrower.employment.selfEmploymentForm']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && employment.selfEmployed && employment.borrowerOwnershipShare === 'GreaterOrEqualTo25Percent' && !(!!employment.selfEmploymentForm));
    const isSelfEmploymentMonthlyIncomeOrLossInvalid = (mortgageFieldsConfig['mortgage.borrower.employment.selfEmploymentMonthlyIncomeOrLoss']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && employment.selfEmployed && (employment.selfEmploymentMonthlyIncomeOrLoss == null || employment.selfEmploymentMonthlyIncomeOrLoss == undefined));
    const isVerificationStatusInvalid = (mortgageFieldsConfig['mortgage.borrower.employment.verificationStatus']?.required == requiredStateToCheck && !isTitleOnlyOrNonTitledSpouse && !(!!employment.verificationStatus));
    const isEmployerIdentificationNumberInvalid = (mortgageFieldsConfig['mortgage.borrower.employment.employerIdentificationNumber']?.required == MortgageFieldRequiredState.Required && !isTitleOnlyOrNonTitledSpouse && !(!!employment.employerIdentificationNumber));

    let isMonthlyIncomeInvalid = false;
    if (!isTitleOnlyOrNonTitledSpouse && employment.employmentType == this._currentEmployerEnumValue && !employment.selfEmployed) {
      if (employment.incomes && employment.incomes.length > 0) {
        const totalEmploymentIncome = employment.incomes.reduce((acc, income) => acc + income.monthlyIncome, 0);
        isMonthlyIncomeInvalid = (mortgageFieldsConfig['mortgage.borrower.employment.monthlyIncome']?.required == requiredStateToCheck && !!!totalEmploymentIncome);
      } else {
        isMonthlyIncomeInvalid = mortgageFieldsConfig['mortgage.borrower.employment.monthlyIncome']?.required == requiredStateToCheck;
      }
    }

    const areAnyOfEmploymentFieldsInvalid = isEmployerInvalid || isPositionInvalid || isStartDateInvalid || areStartEndDatesValid ||
      isAddress1Invalid || isAddress2Invalid || isCityInvalid || isStateInvalid || isZipcodeInvalid || isCountryValid || isEmployerPhoneInvalid ||
      isYearsInLineOfWorkInvalid || isSelfEmployedInvalid || isBorrowerOwnershipShareInvalid || isSelfEmploymentFormInvalid ||
      isSelfEmploymentMonthlyIncomeOrLossInvalid || isVerificationStatusInvalid || isEmployerIdentificationNumberInvalid || isMonthlyIncomeInvalid;
    return areAnyOfEmploymentFieldsInvalid;
  }

  private calculateEmploymentDurationInMonths = (employment): number => {
    if (!employment.startDate) {
      return 0;
    }
    let endDate = DateTime.now();
    const startDate = DateTime.fromFormat(employment.startDate, 'MM/dd/yyyy');
    if (employment.employmentType === this._formerEmployerEnumValue) {
      if (!employment.endDate) {
        return 0;
      }
      endDate = DateTime.fromFormat(employment.endDate, 'MM/dd/yyyy');
    }
    const diff = endDate.diff(startDate, ["months"]);
    return Math.round(diff.months);
  }

  private validateDay = (value): boolean => {
    const invalidDate = value.split('/')[1] > 31;
    return invalidDate;
  }

  private validateMonth = (value): boolean => {
    const invalidDate = value.split('/')[0] > 12;
    return invalidDate;
  }

  private validateFullDate = (value): boolean => {
    const invalidDate = this.isValidAsFullDate(value);
    return invalidDate;
  }

  private isValidAsFullDate(value) {
    const maxDays = getDaysForMonth(value.split('/')[0], value.split('/')[2]);
    const areDaysValid = value.split('/')[1] <= maxDays;
    return !Constants.regexPatterns.date.test(value) || !areDaysValid;
  }

  private validateMax(value, maxDateInput) {
    const date = DateTime.fromJSDate(new Date(value));
    const maxDate = Constants.regexPatterns.date.test(maxDateInput as any) ? DateTime.fromJSDate(new Date(maxDateInput)) : DateTime.fromJSDate(maxDateInput);
    const invalidDate = date > maxDate;
    return invalidDate;
  }

  private validateMin(value, minDateInput) {
    const date = DateTime.fromJSDate(new Date(value));
    const minDate = Constants.regexPatterns.date.test(minDateInput as any) ? DateTime.fromJSDate(new Date(minDateInput)) : DateTime.fromJSDate(minDateInput);
    const invalidDate = date < minDate;
    return invalidDate;
  }

}
