<div class="row">
	<div class="col-md-12">
		<ul class="nav nav-tabs">
			<li class="nav-item" *ngIf="['Company', 'User', 'TpoUser'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'lead-posting'}"
					(click)="setCurrentTab('lead-posting')">
					Lead Posting
				</a>
			</li>
			<li class="nav-item" *ngIf="['Company', 'User', 'TpoUser'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'online-application'}"
					(click)="setCurrentTab('online-application')">
					Online Application
				</a>
			</li>
			<li class="nav-item" *ngIf="['Company', 'User', 'TpoUser'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'credit'}" (click)="setCurrentTab('credit')">
					Credit
				</a>
			</li>
			<li class="nav-item" *ngIf="['Company', 'User', 'TpoUser'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'e-signing'}" (click)="setCurrentTab('e-signing')">
					Document Signing
				</a>
			</li>
			<li class="nav-item" *ngIf="['Company', 'User', 'TpoUser'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'aus'}" (click)="setCurrentTab('aus')">
					AUS
				</a>
			</li>
			<li class="nav-item" *ngIf="['Company', 'User', 'TpoUser'].includes(scope) && canAccessPricing">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'pricing'}" (click)="setCurrentTab('pricing')">
					Pricing
				</a>
			</li>
			<li class="nav-item" *ngIf="['Company'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'fees'}" (click)="setCurrentTab('fees')">
					Fees
				</a>
			</li>
			<li class="nav-item" *ngIf="['Company'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'asset-verification'}"
					(click)="setCurrentTab('asset-verification')">
					Asset Verification (VOA)
				</a>
			</li>
			<li class="nav-item" *ngIf="['Company', 'User', 'TpoUser'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'employment-verification'}"
					(click)="setCurrentTab('employment-verification')">
					Income/Employment Verification (VOI/VOE)
				</a>
			</li>
			<!-- <li class="nav-item" *ngIf="['Company'].includes(scope)">
                <a class="nav-link" [ngClass]="{'active': currentTab === 'income-calculation'}"
                    (click)="setCurrentTab('income-calculation')">
                    Income Calculation
                </a>
            </li> -->
			<li class="nav-item" *ngIf="['Company', 'User', 'TpoUser'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'los'}" (click)="setCurrentTab('los')">
					LOS
				</a>
			</li>
			<li class="nav-item" *ngIf="['Company'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'lde'}" (click)="setCurrentTab('lde')">
					LDE
				</a>
			</li>
			<li class="nav-item" *ngIf="['Company'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'disclosures'}" (click)="setCurrentTab('disclosures')">
					Disclosures
				</a>
			</li>
			<li class="nav-item" *ngIf="['Company'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'title-tab'}" (click)="setCurrentTab('title-tab')">
					Title
				</a>
			</li>
			<li class="nav-item" *ngIf="['Company'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'hoi-tab'}" (click)="setCurrentTab('hoi-tab')">
					HOI
				</a>
			</li>
			<li class="nav-item" *ngIf="['Company'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'appraisal'}" (click)="setCurrentTab('appraisal')">
					Appraisal
				</a>
			</li>
			<li class="nav-item" *ngIf="['Company'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'ocr-document-processing'}"
					(click)="setCurrentTab('ocr-document-processing')">
					OCR / Document Processing
				</a>
			</li>
			<li class="nav-item" *ngIf="['Company'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'global-email'}"
					(click)="setCurrentTab('global-email')">
					Global Email
				</a>
			</li>
			<!-- <li class="nav-item" *ngIf="['Company'].includes(scope)">
                <a class="nav-link" [ngClass]="{'active': currentTab === 'voice-sms'}"
                    (click)="setCurrentTab('voice-sms')">
                    Voice / SMS
                </a>
            </li> -->
			<li class="nav-item" *ngIf="['Company'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'global-sms'}" (click)="setCurrentTab('global-sms')">
					Global SMS
				</a>
			</li>
			<li class="nav-item" *ngIf="['Company'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'credit-monitoring'}"
					(click)="setCurrentTab('credit-monitoring')">
					Credit Monitoring
				</a>
			</li>
			<li class="nav-item" *ngIf="['Company'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'reporting'}" (click)="setCurrentTab('reporting')">
					Reporting
				</a>
			</li>
			<li class="nav-item" *ngIf="['Company', 'User', 'TpoUser'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'social-media'}"
					(click)="setCurrentTab('social-media')">
					Social Media
				</a>
			</li>
			<li class="nav-item" *ngIf="['Company', 'User', 'TpoUser'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'cloud-platform'}"
					(click)="setCurrentTab('cloud-platform')">
					Cloud Platform
				</a>
			</li>
			<li class="nav-item" *ngIf="['Company'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'mers'}" (click)="setCurrentTab('mers')">
					MERS
				</a>
			</li>
			<li *ngIf="['Company'].includes(scope)" class='nav-item'>
				<a (click)="setCurrentTab('license-validation')" [ngClass]="{'active': currentTab === 'license-validation'}"
					class='nav-link'>
					License Validation
				</a>
			</li>

			<li class="nav-item" *ngIf="['Company'].includes(scope)">
				<a class="nav-link" [ngClass]="{'active': currentTab === 'mi-quote'}" (click)="setCurrentTab('mi-quote')">
					MI Quote
				</a>
			</li>
		</ul>

		<div class="tab-content">
			<div class="p-3" *ngIf="['Company', 'User', 'TpoUser'].includes(scope) && currentTab === 'lead-posting'">
				<lead-posting [leadPostingUrls]="dataForType.integrations.leadPostingUrls"></lead-posting>
			</div>
			<div class="p-3" *ngIf="['Company', 'User', 'TpoUser'].includes(scope) && currentTab === 'online-application'">
				<online-application [scope]="scope === 'TpoUser' ? 'User' : scope" [wizardUrls]="dataForType.integrations.onlineApplications.wizardUrls"
					[company]="company">
				</online-application>
			</div>
			<div class="p-3" *ngIf="['Company', 'User', 'TpoUser'].includes(scope) && currentTab === 'credit'">
				<credit [scope]="scope === 'TpoUser' ? 'User' : scope" [creditReportingEnabled]="dataForType.creditReportingEnabled"
					[creditReportDocumentType]="dataForType.creditReportDocumentType"
					[requireLosLoanLinkForCreditReporting]="dataForType.requireLosLoanLinkForCreditReporting"
					[documentTypes]="documentTypes" [creditCredentials]="creditCredentials"
					[requireSoftPullBeforeHardPull]="dataForType.requireSoftPullBeforeHardPull"></credit>
			</div>
			<div class="px-3 pt-3" *ngIf="['Company', 'User', 'TpoUser'].includes(scope) && currentTab === 'e-signing'">
				<ng-template
					[ngIf]="(['User', 'TpoUser'].includes(scope) && (systemLevel.documentSigningEnabled.value == 1 || systemLevel.losDocumentSigningEnabled.value == 1)) || scope === 'Company'"
					[ngIfElse]="eSignDisabledContent">
					<e-signing [scope]="scope === 'TpoUser' ? 'User' : scope" [documentSigningEnabled]="dataForType.documentSigningEnabled"
						[losDocumentSigningEnabled]="dataForType.losDocumentSigningEnabled" [systemLevel]="systemLevel"
						[vendors]="eSigningVendors" [documentTypes]="documentTypes"></e-signing>
				</ng-template>
				<ng-template #eSignDisabledContent>
					<div class="alert alert-info">This feature is currently disabled. Please contact your {{['User', 'TpoUser'].includes(scope) ? 'Administrator' : 'Customer Service Manager' }}.</div>
				</ng-template>
			</div>
			<div class="px-3 pt-3" *ngIf="['Company', 'User', 'TpoUser'].includes(scope) && currentTab === 'aus'">
				<aus [scope]="scope === 'TpoUser' ? 'User' : scope" [desktopUnderwriterConfig]="systemLevel.desktopUnderwriterEnabled"
					[desktopOriginatorConfig]="systemLevel.desktopOriginatorEnabled" [lpaConfig]="systemLevel.lpaEnabled"
					[credentialsForDu]="du" 
					[credentialsForDo]="do" 
					[credentialsForLpa]="lpa" [companyId]="company.companyId"
					[documentTypes]="documentTypes" [externalCompanyId]="externalCompanyId"
					[isDesktopUnderwriterEnabledForCompany]="isDesktopUnderwriterEnabledForCompany"
					[isDesktopOriginatorEnabledForCompany]="isDesktopOriginatorEnabledForCompany"
					[isLpaEnabledForCompany]="isLpaEnabledForCompany"
					[allowedRolesToFinalizeDOFindingsConfig]="systemLevel.allowedRolesToFinalizeDOFindings">
				</aus>
			</div>
			<div class="px-3 pt-3"
				*ngIf="['Company', 'User', 'TpoUser'].includes(scope) && currentTab === 'pricing' && canAccessPricing">
				<pricing *ngIf="scope === 'Company'"
					[pricingEnabled]="dataForType.pricingEnabled"
					[enabledPricingEngines]="enabledPricingEngines"
					[pricingEnabledInNewApp]="dataForType.pricingEnabledInNewApp"
					[externalLocksEnabled]="dataForType.externalPricingLockEnabled"
					[defaultPricingVendor]="dataForType.defaultPricingVendor"
					[lockDeskEmailAddress]="dataForType.lockDeskEmailAddress" [loanStatus]="loanStatus" [vendors]="pricingVendors"
					[pricingScenarioRefreshTime]="dataForType.pricingScenarioRefreshTime"
					[pricingScenarioRefreshDays]="dataForType.pricingScenarioRefreshDays"
					[pricingScenarioRefreshLoanStatuses]="dataForType.pricingScenarioRefreshLoanStatuses">
				</pricing>
				<integration-pricing *ngIf="['User', 'TpoUser'].includes(scope)" [scope]="scope" [enabledPricingEngines]="enabledPricingEngines"
					[optimalBlueCreds]="optimalBlueCreds" [lenderPriceCreds]="lenderPriceCreds"
					[pollyPriceCreds]="pollyPriceCreds" [meridianLinkPricingCreds]="meridianLinkPricingCreds"
					[loanPassPricingCreds]="loanPassPricingCreds" [loanPassIframePricingCreds]="loanPassIframePricingCreds"
					[pollyIframePricingCreds]="pollyIframePricingCreds" [systemLevel]="systemLevel">
				</integration-pricing>
			</div>
			<div class="p-3" *ngIf="['Company'].includes(scope) && currentTab === 'fees'">
				<ng-template [ngIf]="isFeesEnabledForCompany" [ngIfElse]="feesDisabledContent">
					<fees [ernstFeesEnabled]="dataForType.ernstFeesEnabled"
						[lodestarFeesEnabled]="dataForType.lodestarFeesEnabled" [mctoFeesEnabled]="dataForType.mctoFeesEnabled"
						[defaultFeeProvider]="dataForType.defaultFeeProvider" [providerCredentials]="feeProviders"
						[defaultFeeProviderVal]="defaultFeeProviderVal" [documentTypes]="documentTypes"
						(defaultProviderChanged)="onDefaultfeeproviderChanged($event)">
					</fees>
				</ng-template>
				<ng-template #feesDisabledContent>
					<div class="alert alert-info">This feature is currently disabled. Please contact your {{['User', 'TpoUser'].includes(scope) ? 'Administrator' : 'Customer Service Manager' }}.</div>
				</ng-template>
			</div>
			<div class="p-3" *ngIf="['Company'].includes(scope) && currentTab === 'asset-verification'">
				<asset-verification [digitalAssetVerificationEnabled]="dataForType.digitalAssetVerificationEnabled"
					[assetVerificationCredentials]="assetVerificationCredentials"
					[documentTypes]="documentTypes"></asset-verification>
			</div>
			<div class="p-3" *ngIf="['Company', 'User', 'TpoUser'].includes(scope) && currentTab === 'employment-verification'">
				<income-employment-verification
					[digitalEmploymentVerificationEnabled]="systemLevel.digitalEmploymentVerificationEnabled"
					[incomeEmploymentVerificationCredentials]="incomeEmploymentVerificationCredentials"
					[documentTypes]="documentTypes" [scope]="scope === 'TpoUser' ? 'User' : scope"></income-employment-verification>
			</div>
			<!-- <div class="p-3" *ngIf="['Company'].includes(scope) && currentTab === 'income-calculation'">
                <ng-template [ngIf]="isLoanBeamEnabledForCompany" [ngIfElse]="incomeCalculationDisabledContent">
                    <income-calculation
                        [loanBeamEnabled]="dataForType.loanBeamEnabled"
                        [incomeCalculationCredentials]="incomeCalculationCredentials"
                    ></income-calculation>
                </ng-template>
                <ng-template #incomeCalculationDisabledContent>
                    <div class="alert alert-info">This feature is currently disabled. Please contact your {{scope ==
                        'User' ? 'Administrator' : 'Customer Service Manager' }}.</div>
                </ng-template>
            </div> -->
			<div class="p-3" *ngIf="['Company', 'User', 'TpoUser'].includes(scope) && currentTab === 'los'">
				<ng-template [ngIf]="isLosEnabledForCompany" [ngIfElse]="losDisabledContent">
					<los-integration
						[scope]="scope"
						[losEnabled]="dataForType.losEnabled">
					</los-integration>
				</ng-template>
				<ng-template #losDisabledContent>
					<div class="alert alert-info">This feature is currently disabled. Please contact your {{['User', 'TpoUser'].includes(scope) ? 'Administrator' : 'Customer Service Manager' }}.</div>
				</ng-template>
			</div>
			<div class="p-3" *ngIf="['Company'].includes(scope) && currentTab === 'lde'">
				<ng-template [ngIf]="isLdeEnabledForCompany" [ngIfElse]="ldeDisabledContent">
					<lde-integration [scope]="scope" [ldeEnabled]="dataForType.ldeEnabled" [ldeCredentials]="ldeCredentials">
					</lde-integration>
				</ng-template>
				<ng-template #ldeDisabledContent>
					<div class="alert alert-info">This feature is currently disabled. Please contact your {{['User', 'TpoUser'].includes(scope) ? 'Administrator' : 'Customer Service Manager' }}.</div>
				</ng-template>
			</div>
			<div class="p-3" *ngIf="['Company'].includes(scope) && currentTab === 'disclosures'">
				<disclosures-integration [docMagicEnabled]="dataForType.docMagicEnabled"
					[disclosureCredentials]="disclosureCredentials" [documentTypes]="documentTypes">
				</disclosures-integration>
			</div>
			<div class="p-3" *ngIf="['Company'].includes(scope) && currentTab === 'title-tab'">
				<title-ordering [company]="company" [isTitleOrderingEnabled]="isTitleOrderingEnabled"
					[titleOrderingEnabled]="dataForType.titleOrderingEnabled"
					[titleCredentials]="titleCredentials"></title-ordering>
			</div>
			<div class="p-3" *ngIf="['Company'].includes(scope) && currentTab === 'hoi-tab'">
				<home-owners-insurance [isHomeOwnersInsuranceEnabled]="isHomeOwnersInsuranceEnabled" [company]="company"
					[homeOwnersInsuranceEnabled]="dataForType.homeOwnersInsuranceEnabled"></home-owners-insurance>
			</div>
			<div class="p-3" *ngIf="['Company'].includes(scope) && currentTab === 'appraisal'">
				<appraisal
					[scope]="scope"
					[appraisalOrderingEnabled]="dataForType.appraisalOrderingEnabled"
					[appraisalCredentials]="appraisalCredentials">
				</appraisal>
			</div>
			<div class="p-3" *ngIf="['Company'].includes(scope) && currentTab === 'ocr-document-processing'">
				<ocr-integration [ocrEnabled]="dataForType.ocrEnabled" [ocrCredentials]="ocrCredentials">
				</ocr-integration>
			</div>
			<div class="p-3" *ngIf="['Company'].includes(scope) && currentTab === 'global-email'">
				<global-email [microsoftGraph]="microsoftGraph"></global-email>
			</div>
			<!-- <div class="p-3" *ngIf="['Company'].includes(scope) && currentTab === 'voice-sms'">
                <voice-sms [disableCallRecording]="dataForType.disableCallRecording"></voice-sms>
            </div> -->
			<div class="p-3" *ngIf="['Company'].includes(scope) && currentTab === 'global-sms'">
				<global-sms></global-sms>
			</div>
			<div class="p-3" role="tabpanel" *ngIf="['Company'].includes(scope) && currentTab === 'credit-monitoring'">
				<credit-monitoring [isCreditMonitoringEnabled]="isCreditMonitoringEnabled"
					[companyExtendedModel]="dataForType.companyExtendedModel"></credit-monitoring>
			</div>
			<div class="p-3" *ngIf="['Company'].includes(scope) && currentTab === 'reporting'">
				<reporting-integration [enhancedReportingEnabled]="dataForType.enhancedReportingEnabled">
				</reporting-integration>
			</div>
			<div class="p-3" *ngIf="['Company', 'User', 'TpoUser'].includes(scope) && currentTab === 'social-media'">
				<social-media [scope]="scope === 'TpoUser' ? 'User' : scope" [socialMediaIntegrationEnabled]="dataForType.socialMediaIntegrationEnabled"
					[ayrshare]="ayrshare"></social-media>
			</div>
			<div class="p-3" *ngIf="['Company', 'User', 'TpoUser'].includes(scope) && currentTab === 'cloud-platform'">
				<cloud-platform-config-editor [scope]="scope === 'TpoUser' ? 'User' : scope" [transUnionConfig]="transUnionConfiguration"
					[rockConnection]="rockConnection">
				</cloud-platform-config-editor>
			</div>
			<div class="p-3" *ngIf="['Company'].includes(scope) && currentTab === 'mers'">
				<mers [isMersEnabled]="isMersEnabled" [mersConfig]="dataForType.mersEnabled" [mersCredential]="mersCredential"
					(mersCredsUpdated)="mersCredsUpdated($event)">
				</mers>
			</div>
			<div *ngIf="['Company'].includes(scope) && currentTab === 'license-validation'" class='p-3'>
				<license-validation (credentialChange)='onLicenseValidationCredentialChange($event)'
					[credential]='licenseValidationCredential'></license-validation>
			</div>
			<div class="p-3" *ngIf="['Company'].includes(scope) && currentTab === 'mi-quote'">
				<mortgage-insurance-quote [miQuotes]="miQuoteCreds">
				</mortgage-insurance-quote>
			</div>
		</div>
	</div>
</div>
