import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoanApplication } from 'src/app/models';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { ValidationResult } from 'src/app/models/loan/validation-result.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'submission-data-validation',
  templateUrl: './submission-data-validation.component.html',
  styleUrls: ['./submission-data-validation.component.scss']
})
export class SubmissionDataValidationComponent implements OnInit, OnDestroy {

  @Input()
  application: LoanApplication;

  @Input()
  set validationResults(results: ValidationResult[]) {
    this._validationResults = results.filter(x => x.validationType != 'Task' && x.validationType != 'CustomTask' && !x.passed);
    this.populateValidationData();
  }

  @Input()
  isUrlaInvalid: boolean = false;

  get validationResults(): ValidationResult[] {
    return this._validationResults || [];
  }

  errors: Array<any> = [];

  warnings: Array<any> = [];

  private _validationResults: ValidationResult[];

  private _contextSubscription: Subscription;

  private _loanPurposes: LoanPurpose[] = [];

  constructor(private readonly _applicationContextService: ApplicationContextService) {
    this._contextSubscription = this._applicationContextService.context.subscribe(context => {
      if (context) {
        this._loanPurposes = context.globalConfig.loanPurpose;
      }
    });
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this._contextSubscription?.unsubscribe();
  }

  populateValidationData = () => {

    this.errors = [];

    const isCompanyDeephaven = [229, 276].includes(this.application.companyId);
    const isCompanyGenway = this.application.companyId == 256;
    const isCorrespondent = this.application.channel == 'Correspondent';

    const isDeephavenCorrespondent = isCompanyDeephaven && isCorrespondent;

    const isLoanPurposeDreamBuilder = this._loanPurposes.find(lp => lp.loanPurposeId ===
      this.application?.loanPurposeId
    )?.loanPurposeName.includes('DreamBuilder');

    if (isCompanyDeephaven && this.isUrlaInvalid == true) {
      this.errors.push({ message: 'URLA Data Validation Error. Please go back to URLA and clear all red fields. ', link: 'urla' });
    }

    this.validationResults?.forEach(x => {
      if (!(x.link.toLowerCase() === 'pricing' && isLoanPurposeDreamBuilder && isCompanyGenway)) {
        this.errors.push({ message: x.errorMessage, link: x.link });
      }
    });

    if (this.application.companyId != 128) {
      if (isLoanPurposeDreamBuilder && isCompanyGenway) {
        return;
      }
      if (this.application.productPricing.price > 0 && this.application.productPricing.assignDate && ["Accepted", "Requested"].indexOf(this.application.productPricing.lockStatus) < 0) {
        this.warnings.push({ message: "Loan is not locked. You are submitting a file with a lock status of FLOAT.", link: "pricing" });
      }
    }
  }
}
