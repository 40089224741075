import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { LoanApplication } from 'src/app/models';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { EnabledBusinessChannel } from '../../../models/business-channel.model';

@Component({
  selector: 'polly-pricer-dialog',
  templateUrl: 'polly-pricer-dialog.component.html',
})
export class PollyPricerDialogComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {

  @Input()
  enabledChannels: EnabledBusinessChannel[];

  private _isTpo: boolean = false;
  private _enabledChannels: EnabledBusinessChannel[];

  private readonly _contextSubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    public activeModal: NgbActiveModal,
    private readonly _router: Router,
  ) {
    super(injector);
    this._contextSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
      this._isTpo = context.isTpo;
    });
  }

  onPricingCompleted = (application: LoanApplication) => {
    this.activeModal.close();
    const urlRoot = this._isTpo ? 'tpo' : 'admin';
    this._router.navigate([`/${urlRoot}/app-details/${application.applicationId}/pricing`], {
      state: {
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this._contextSubscription?.unsubscribe();
  }
}

