import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { chain } from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/core/services/utils';
import { ThirdPartyCredential, ThirdPartyCredentialType, ThirdPartyKeyValue } from 'src/app/models';
import { PricingVendor } from 'src/app/models/pricing/pricing-vendor';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { OBChannel } from 'src/app/modules/pricing/models/business-channel.model';
import { PricingService } from 'src/app/modules/pricing/services/pricing.service';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { RepriceFieldsDialogComponent } from '../reprice-fields-dialog/reprice-fields-dialog.component';
import { ThirdPartyCredentialsService } from 'src/app/services/third-party-credentials.service';
import { ThirdPartyIntegrationProvider } from 'src/app/models/fee/fee.model';
import { PricingCustomFieldSectionsComponent } from '../pricing-custom-field-sections/pricing-custom-field-sections.component';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import _ from 'lodash';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';

@Component({
  selector: 'ob-vendor-editor-dialog',
  templateUrl: 'ob-vendor-editor-dialog.component.html',
  styleUrls: ['./ob-vendor-editor-dialog.component.scss'],
})

export class OBVendorEditorDialogComponent implements OnInit {
  @Input()
  vendor: ThirdPartyCredential;

  @Input()
  inEditMode: boolean = true;

  @Input()
  businessChannelsNames: string[] = [];

  @Input()
  scope: 'User' | 'TpoUser' | 'Branch' | 'ExternalCompanyBranch';

  @Input()
  branchId?: number;

  @Input()
  userCompanyGuid?: string;

  @ViewChild("editorForm")
  editorForm: NgForm;

  channels: { display: string, value: string }[] = [];

  channelOptions: EnumerationItem[] = [];
  selectedChannels: EnumerationItem[] = [];
  businessChannelOriginators = {};
  businessChannelChannels = {};
  kvpOriginators: ThirdPartyKeyValue[] = [];
  kvpChannels: ThirdPartyKeyValue[] = [];
  kvpNewModeOriginators: ThirdPartyKeyValue[] = [];

  businessChannels: OBChannel[] = [];

  multiSelectSettings: IDropdownSettings = {
    idField: 'value',
    textField: 'name',
    itemsShowLimit: 4,
    allowSearchFilter: true
  };

  isChannelModeEnabled: boolean = false;
  lodaChannels: EnumerationItem[] = [];

  companyId: number;

  get isUserOrBranchLevel(): boolean {
    return ['User', 'TpoUser', 'Branch', 'ExternalCompanyBranch'].includes(this.scope);
  }

  constructor(public activeModal: NgbActiveModal,
    private readonly _modalService: NgbModal,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _pricingService: PricingService,
    private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService,
    private readonly _ctxService: ApplicationContextService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService) { }

  async ngOnInit() {
    this._ctxService.context.subscribe(context => {
      this.lodaChannels = context.globalConfig.enabledChannels;
      this.companyId = this.scope == "ExternalCompanyBranch" ? context.userPermissions.externalCompanyId : context.userPermissions.companyId;
    });

    this.channels = this.vendor.thirdPartyKeyValuePairs
      .filter(el => el.key.includes('Channel:'))
      .map(el => ({
        display: el.key.replace('Channel:', ''),
        value: el.value,
      }));

    await this.loadBusinessChannels();

    if (!this.isUserOrBranchLevel) { // company
      this.isChannelModeEnabled = this.vendor.thirdPartyKeyValuePairs.some(kvp => kvp.key == "ChannelModeEnabled" && kvp.value == "1");

      if (this.isChannelModeEnabled) {
        this.kvpChannels = chain(this.vendor.thirdPartyKeyValuePairs)
          .cloneDeep()
          .filter(el => el.key.includes('Channel:'))
          .map(el => {
            el.key = el.key.replace('Channel:', '').split("|")[0];
            return el;
          })
          .value();

        this.kvpNewModeOriginators = chain(this.vendor.thirdPartyKeyValuePairs)
          .cloneDeep()
          .filter(el => el.key.includes('Originator:'))
          .map(el => {
            const lodaChannelToOBChannel = el.key.replace('Originator:', '');
            // const lodaChannel = lodaChannelToOBChannel.split("|")[0];
            const obChannelId = lodaChannelToOBChannel.split("|")[1];

            const matchedOBChannel = this.businessChannels.find(bc => bc.businessChannelId == Number(obChannelId));
            el.key = matchedOBChannel.name;
            return el;
          })
          .value();
      }
    }
    else { // user or branch

      this.kvpOriginators = chain(this.vendor.thirdPartyKeyValuePairs)
        .cloneDeep()
        .filter(el => el.key.includes('Originator:'))
        .map(el => {
          el.key = el.key.replace('Originator:', '');
          return el;
        })
        .value();

      if (this.kvpOriginators.length === 0) {
        this.addBusinessChannel();
      }

      try {
        this._spinner.show();
        const obCred: ThirdPartyCredential = await firstValueFrom(this._thirdPartyCredentialsService.getCredentialsByVendor(ThirdPartyCredentialType.PricingVendor, ThirdPartyIntegrationProvider.OptimalBlue, undefined, this.companyId));

        this._spinner.hide();

        if (obCred) {
          this.isChannelModeEnabled = obCred.thirdPartyKeyValuePairs.some(kvp => kvp.key == "ChannelModeEnabled" && kvp.value == "1");

          if (this.isChannelModeEnabled) {
            this.kvpChannels = chain(this.vendor.thirdPartyKeyValuePairs)
              .cloneDeep()
              .filter(el => el.key.includes('Channel:'))
              .map(el => {
                el.key = el.key.replace('Channel:', '').split("|")[0];
                return el;
              })
              .value();

            chain(obCred.thirdPartyKeyValuePairs)
              .cloneDeep()
              .filter(el => el.key.includes('Channel:'))
              .map(el => {
                el.key = el.key.replace('Channel:', '').split("|")[0];
                return el;
              })
              .value()
              .filter(x => !this.kvpChannels.find(e => e.key == x.key))
              .forEach(kvp => this.kvpChannels.push(kvp));

            this.kvpNewModeOriginators = _.clone(this.kvpOriginators);

            chain(obCred.thirdPartyKeyValuePairs)
              .cloneDeep()
              .filter(el => el.key.includes('Originator:'))
              .map(el => {
                el.key = el.key.replace('Originator:', '').split("|")[0];
                return el;
              })
              .value()
              .filter(x => !this.kvpOriginators.find(o => o.key == x.key))
              .forEach(kvp => this.kvpNewModeOriginators.push(kvp));;
          }
        }

      }
      catch (e) {
        this._spinner.hide();
        this._notificationService.showError(
          e?.message || 'Unable to load business channels',
          'Company Credentials'
        );
      }
    }
  }

  saveCredential = () => {
    if (this.isChannelModeEnabled) {

      let channelModeEnabledKVP = this.vendor.thirdPartyKeyValuePairs.find(kvp => kvp.key == "ChannelModeEnabled");
      if (!channelModeEnabledKVP) {
        channelModeEnabledKVP = new ThirdPartyKeyValue("ChannelModeEnabled", "1");
        this.vendor.thirdPartyKeyValuePairs.push(channelModeEnabledKVP);
      }
      else {
        channelModeEnabledKVP.value = "1";
      }

      this.editorForm.form.markAllAsTouched();
      if (!this.editorForm.valid) {
        return;
      }

      if (this.scope === 'User') {
        this.vendor.userId = this.userCompanyGuid;
      }
      else if (this.scope == 'Branch') {
        this.vendor.branchId = String(this.branchId);
      }

      this.vendor.thirdPartyKeyValuePairs = [
        ...this.kvpChannels.filter(kvp => !!kvp.key && !!kvp.value).map(el => {
          const matchedOBChannel = this.businessChannels.find(bc => bc.name == el.value);
          return {
            key: `Channel:${el.key}|${matchedOBChannel.businessChannelId}`,
            value: el.value,
            alias: '',
            userName: '',
            password: ''
          }
        }),
        ...this.kvpNewModeOriginators.filter(kvp => !!kvp.key && !!kvp.value).map(el => {
          const matchedChannel = this.kvpChannels.find(c => c.value == el.key);
          const matchedOBChannel = this.businessChannels.find(bc => bc.name == matchedChannel.value);
          return {
            key: `Originator:${matchedChannel.key}|${matchedOBChannel.businessChannelId}`,
            value: el.value,
            alias: '',
            userName: '',
            password: ''
          }
        })
      ];

      if (!['User', 'TpoUser'].includes(this.scope)) {
        this.vendor.thirdPartyKeyValuePairs.push(channelModeEnabledKVP);
      }
    }
    else {
      if (this.isUserOrBranchLevel) {
        this.editorForm.form.markAllAsTouched();
        if (!this.editorForm.valid) {
          return;
        }
      }
      else {
        this.vendor.thirdPartyKeyValuePairs = this.selectedChannels.map(el => {
          const matchedOBChannel = this.businessChannels.find(e => e.businessChannelId == el.value);
          return {
            key: `Channel:${el.name}`,
            value: matchedOBChannel?.businessChannelId.toString() || null,
            alias: '',
            userName: '',
            password: ''
          }
        });
      }

      if (this.scope === 'Branch' || this.scope === 'ExternalCompanyBranch') {
        this.vendor.branchId = String(this.branchId);

        this.vendor.thirdPartyKeyValuePairs = chain(this.kvpOriginators)
          .cloneDeep()
          .map(el => {
            el.key = `Originator:${el.key}`;
            return el;
          })
          .value();
      }

      if (this.scope === 'User' || this.scope == 'TpoUser') {
        this.vendor.userId = this.userCompanyGuid;

        this.vendor.thirdPartyKeyValuePairs = chain(this.kvpOriginators)
          .cloneDeep()
          .map(el => {
            el.key = `Originator:${el.key}`;
            return el;
          })
          .value();
      }
    }

    const observer = {
      next: (result => {
        this.activeModal.close(result);
        this._spinner.hide();
      }),
      error: (error => {
        this._spinner.hide();
      })
    }
    this._spinner.show();
    this._systemLevelService.saveCredential(this.vendor).subscribe(observer);
  }

  onRepriceFieldsClicked = () => {
    const modalRef = this._modalService.open(RepriceFieldsDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.credentialId = this.vendor.credentialId;
    modalRef.componentInstance.vendor = PricingVendor.OptimalBlue;
  }

  addBusinessChannel = () => {
    this.kvpOriginators.push(new ThirdPartyKeyValue('', ''));
  }

  addNewModeChannel = () => {
    this.kvpChannels.push(new ThirdPartyKeyValue('', ''));
  }

  addNewModeOriginator = () => {
    this.kvpNewModeOriginators.push(new ThirdPartyKeyValue('', ''));
  }

  getOBChannelId = (name: string) => {
    return this.businessChannels?.find(e => e.name == name)?.businessChannelId;
  }

  clearOriginator = (item) => {
    item.value = '';
  }

  onCustomFieldsMappingClicked = () => {
    const modalRef = this._modalService.open(PricingCustomFieldSectionsComponent, Constants.modalOptions.fullScreen);
    modalRef.componentInstance.credentialId = this.vendor.credentialId;
    modalRef.componentInstance.vendor = this.vendor.vendorName;
  }

  private loadBusinessChannels = async () => {
    try {
      this._spinner.show();

      const result = await firstValueFrom(this._pricingService.getBusinessChannels(true));
      this._spinner.hide();

      this.businessChannels = result || [];
      this.setSelectedChannels();
      this.setChannelOptions();
      this.businessChannelsNames = result.map(el => el.name);

      this.businessChannels.forEach(businessChannel => {
        this.businessChannelOriginators[businessChannel.businessChannelId] = chain(businessChannel.originators)
          .map(originator => ({
            originatorId: originator.originatorId,
            name: Utils.getPersonsDisplayName(originator),
            userName: originator.userName
          }))
          .orderBy(["name"], ["asc"])
          .value();;
      });
    }
    catch (error) {
      this._spinner.hide();
      this._notificationService.showError(error ? error.message : 'Unable to load business channels', 'Business Channels');
    }
  }

  private setChannelOptions = (): void => {
    this.channelOptions = this.businessChannels.map(c => {
      return new EnumerationItem(c.name, c.businessChannelId)
    });
  }

  private setSelectedChannels = () => {
    const ids: string[] = [];
    this.channels.forEach(key => {
      ids.push(key.value);
    })
    this.selectedChannels = this.businessChannels
      .filter(bC => ids.includes(bC.businessChannelId.toString()))
      .map(c => new EnumerationItem(c.name, c.businessChannelId));
  }
}
