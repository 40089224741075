import {
  AfterViewInit,
  Component, Injector,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import * as feather from 'feather-icons';
import { Subscription } from 'rxjs';
import { MenuService } from 'src/app/services/menu.service';
import { v4 as uuidv4 } from 'uuid';
import { ApplicationContextBoundComponent } from "../../../../shared/components";
import { MenuItemComponent } from '../menu-item/menu-item.component';
import { TpoFeaturePermissions } from '../tpo-app-details/tpo-app-details.component';
import { UrlaValidationService } from 'src/app/services/urla-validation.service';
import { Constants } from 'src/app/services/constants';
import { MenuItemStatus } from '../../models/enums/menu-item-status.enum';
import { ApplicationContext } from 'src/app/models';
import { TransactionType } from 'src/app/models/config/loan-purpose.model';
import { LoanService } from 'src/app/services/loan';

@Component({
  selector: 'tpo-menu',
  templateUrl: './tpo-menu.component.html',
  styleUrls: ['./tpo-menu.component.scss']
})
export class TpoMenuComponent extends ApplicationContextBoundComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  loanId: number;

  @Input()
  featurePermissions: TpoFeaturePermissions;

  tab: string;

  isReadOnly: boolean = false;

  protected uniqueId: string;

  protected isManualPricingEntryAvailable: boolean = false;

  protected isCompanyPRMG: boolean = false;
  protected isCompanyPulseProcessing: boolean = false;
  protected isReverseMortgage: boolean = false;

  protected showUWDecision: boolean = false;

  private _contextSubscription: Subscription;
  private _menuItemStatusSubscription: Subscription;
  private _routerEventSubscription: Subscription;

  private _parentMenuItemsThatAreAlwaysExpanded: ParentMenuItemDescriptor[] = [
    { listItemId: 'services-menu-list', childListId: 'services-menu-ulist' }
  ];

  private _rootElement: HTMLElement;

  @ViewChildren(MenuItemComponent) menuItems:
    | QueryList<MenuItemComponent>
    | undefined;

  constructor(
    private readonly injector: Injector,
    private readonly _menuService: MenuService,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _loanService: LoanService,
    private readonly _urlaValidationService: UrlaValidationService,
  ) {
    super(injector);

    this.uniqueId = uuidv4();
    this._routerEventSubscription = this._router.events.subscribe(
      (event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          this._getInitTab();
        }
      }
    );

    this._contextSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
      this.initialize(context);
    });
  }

  ngOnInit(): void {
    (<any>$('#metismenu')).metisMenu();
    feather.replace();
    this._activatedRoute.params.subscribe(params => {
      this._getInitTab();
    });

    this.initialize(this.applicationContext);
  }

  ngAfterViewInit(): void {
    this.isReverseMortgage = this.applicationContext.globalConfig.loanPurpose.find(x =>
      x.loanPurposeId == this.applicationContext?.application?.loanPurposeId
    )?.transactionType == TransactionType.ReverseMortgage;

    this.applicationContext.application.mortgageLoan.isReverseMortgage = this.isReverseMortgage;

    const urlaFieldsConfig = this._urlaValidationService.getUrlaFieldsConfigForMortgage(this.applicationContext.application.mortgageLoan,
      !!this.applicationContext.application.losIdentifier, true);

    this._menuService.setStatus('borrowerInfo', this._urlaValidationService.getStatusForBorrowersInfo(urlaFieldsConfig, this.applicationContext.application.mortgageLoan, true));
    this._menuService.setStatus('financialInfo', this._urlaValidationService.getStatusForFinancialInfo(urlaFieldsConfig, this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('reo', this._urlaValidationService.getStatusForReo(urlaFieldsConfig, this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('loanAndProperty', this._urlaValidationService.getStatusForLoanProperty(urlaFieldsConfig, this.applicationContext.application.mortgageLoan, this.featurePermissions.isAppraisedValueHidden));
    this._menuService.setStatus('declarations', this._urlaValidationService.getStatusForDeclarations(urlaFieldsConfig, this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('militaryService', this._urlaValidationService.getStatusForMilitaryService(urlaFieldsConfig, this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('demographics', this._urlaValidationService.getStatusForDemographics(urlaFieldsConfig, this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('loanOriginatorInfo', this._urlaValidationService.getStatusForLoanOriginatorInfo(urlaFieldsConfig, this.applicationContext.application.mortgageLoan, this.featurePermissions.isMersEnabled, this.featurePermissions.isRequiredLoanAndCaseNumbers));
    this._menuService.setStatus('propertyLoanInfo', this._urlaValidationService.getStatusForPropertyLoanInfo(urlaFieldsConfig, this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('titleInfo', this._urlaValidationService.getStatusForTitleInfo(urlaFieldsConfig, this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('mortgageLoanInfo', this._urlaValidationService.getStatusForMortgageLoanInfo(urlaFieldsConfig, this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('qualifyingTheBorr', this._urlaValidationService.getStatusForQualifyingTheBorr(urlaFieldsConfig, this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('homeownershipEducation', this._urlaValidationService.getStatusForHomeownershipEducation(urlaFieldsConfig, this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('reverseMortgageLoanInfo', this._urlaValidationService.getStatusForReverseMortgageLoanInfo(urlaFieldsConfig, this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('urla', this._urlaValidationService.getStatusForUrla(urlaFieldsConfig, this.applicationContext.application.mortgageLoan, this.featurePermissions.isMersEnabled, this.featurePermissions.isRequiredLoanAndCaseNumbers, true));

    this._menuItemStatusSubscription = this._menuService.menuItemStatusChanged.subscribe((e) => {
      const menuItem = this.menuItems.find((mi) => mi.id === e.id);
      if (menuItem) {
        menuItem.status = e.status;
      }
    });
    this._rootElement = document.getElementById(this.uniqueId);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._menuItemStatusSubscription?.unsubscribe();
    this._contextSubscription?.unsubscribe();
    this._routerEventSubscription?.unsubscribe();
  }

  onListItemClicked = (e: any, tabToActivate: string) => {
    this.setExpandedItems();
    if (this.tab === tabToActivate) {
      e.preventDefault();
      return;
    }
    this.tab = tabToActivate;

    const menuItemElements = this._rootElement.getElementsByTagName('menu-item');
    Array.from(menuItemElements).forEach(menuItem => {
      const listsUnderMenuItem = menuItem.parentElement?.getElementsByTagName('li');
      Array.from(listsUnderMenuItem).forEach(list => {
        list.classList.remove('mm-active');
      });
    });
  }

  onLinkSubMenuItemClicked = () => {
    const menuItemElements = this._rootElement.getElementsByTagName('menu-item');
    Array.from(menuItemElements).forEach(menuItem => {
      const listsUnderMenuItem = menuItem.parentElement?.getElementsByTagName('li');
      Array.from(listsUnderMenuItem).forEach(list => {
        list.classList.remove('mm-active');
      });
    });
  }

  onMenuItemClicked = (id: string) => {
    this._menuService.onMenuItemClicked(id);

    // *** All the code below is hacking the metismenu so that the behavior is as we wanted for the expanse/collapse of menu items...
    // const menuItem = document.getElementById(id);

    // const allLists = this._rootElement.getElementsByClassName('tpo-parent-menu-item');
    // Array.from(allLists).forEach(element => {
    //   if (menuItem?.parentElement?.parentElement !== element) {
    //     element.classList.remove("mm-active");
    //     const childLists = element.getElementsByTagName('ul');
    //     Array.from(childLists).forEach(childList => {
    //       if (menuItem?.parentElement !== childList) {
    //         childList.classList.remove("mm-show");
    //       }
    //     })
    //   }
    // });
    const childItems = this._rootElement.getElementsByClassName('nav-item');
    Array.from(childItems).forEach(element => {
      if (element.id !== id) {
        element.classList.remove("mm-active");
      } else {
        element.classList.add("mm-active");
      }
    });
  }

  protected setExpandedItems = () => {
    this._parentMenuItemsThatAreAlwaysExpanded.forEach(item => {
      const listItem = document.getElementById(item.listItemId);
      listItem.className = "mm-active";
      document.getElementById(item.childListId).classList.add("mm-show");
      document.getElementById(item.childListId).style.height = "auto";
    })
  }

  private initialize = (context: ApplicationContext) => {
    if (!context.application) {
      return;
    }
    this.getRecentUW(context.application.applicationId);
    this.isCompanyPRMG = context.isCompanyPRMG;
    this.isCompanyPulseProcessing = context.isCompanyPulseProcessing;
    if (context.isTpo) {
      const keyDates = context.applicationKeyDatesByType;
      if (keyDates?.leIssued?.eventDate || keyDates?.initialDisclosureSent?.eventDate) {
        this.isReadOnly = true;
      }

      const menuStatuses = context.tpoMenuStatuses;
      const statusItem = menuStatuses[Constants.menu.servicesMenuItems.submission];
      const tpoSubmitted = statusItem && statusItem.toLowerCase() == MenuItemStatus.Success;
      if (tpoSubmitted) {
        this.isReadOnly = true;
      }
    }
    this.isManualPricingEntryAvailable = context.userPermissions.companyId == 302;
  }

  private _getInitTab = () => {
    const splitUrl = this._router.url.split("/");

    // init page
    setTimeout(() => {
      if (splitUrl.length < 5) {
        this.tab = 'loan-summary'
      } else {
        const pageType = splitUrl[splitUrl.length - 1];
        this.tab = pageType ? pageType : 'loan-summary';
        this.checkMenuItems(pageType);
      }
      this.setExpandedItems();
    })

  }

  private checkMenuItems = (pageType: string) => {
    const isCredit: string = 'credit-reports';
    const isAus: string = 'aus';
    const childItems = this._rootElement.getElementsByClassName('nav-item');
    if (pageType === isCredit) {
      Array.from(childItems).forEach(element => {
        if (element.id !== 'credit') {
          element.classList.remove("mm-active");
        } else {
          element.classList.add("mm-active");
        }
      });
    }
    if (pageType === isAus) {
      Array.from(childItems).forEach(element => {
        if (element.id !== isAus) {
          element.classList.remove("mm-active");
        } else {
          element.classList.add("mm-active");
        }
      });
    }
  }

  private getRecentUW = (loanId: number) => {
    this._loanService.getRecentUWDoc(loanId).subscribe({
      next: (response) => {
          if (response && response.docFiles && response.docFiles.length) {
              this.showUWDecision = true;
          }
      }
    })
  }
}

export class ParentMenuItemDescriptor {
  listItemId: string;
  childListId: string;
}
