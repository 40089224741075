import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { AuthService } from 'src/app/services/auth.service';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { NotificationService } from 'src/app/services/notification.service';
import { copyToClipboard } from 'src/utils';

@Component({
  selector: 'app-passwords-dialog',
  templateUrl: 'app-passwords-dialog.component.html'
})

export class AppPasswordsDialogComponent implements OnInit {

  @ViewChild('appPasswordsDialogForm')
  appPasswordsDialogForm: NgForm;

  serviceTypesForGenerateSecret: EnumerationItem[] = [];
  appName: string;
  serviceType: string;
  generatedClientSecret: string;
  generatedClientId: string;
  isClickedGenerateBtn: boolean = false;
  isGeneratePasswdSuccessfully: boolean = false;

  constructor(public activeModal: NgbActiveModal,
    private readonly _enumerationService: EnumerationService,
    private readonly _authService: AuthService,
    private readonly _notifyService: NotificationService,
  ) {
    this.serviceTypesForGenerateSecret = this._enumerationService.getServiceTypesForGenerateSecret;
  }

  ngOnInit() {
  }

  generateAppPassword = () => {
    this.appPasswordsDialogForm.form.markAllAsTouched();
    if (!this.appPasswordsDialogForm.form.valid) return;

    this.isClickedGenerateBtn = true;
    this._authService.generateUserAndSecretAppService(this.appName).subscribe({
      next: (response) => {
        this.generatedClientSecret = response.clientSecret;
        this.generatedClientId = response.clientId;
        this.appName = response.displayName;
        this.isGeneratePasswdSuccessfully = true
      },
      error: () => {

      }
    }).add();
  }

  copyToClipboard = (elementToBeCopied: string, name: string) => {
    copyToClipboard(elementToBeCopied);
    this._notifyService.showInfo(`Client ${name} copied to clipboard.`, "Copied!");
  }

  onCloseClicked = () => {
    if (!this.isGeneratePasswdSuccessfully) {
      this.activeModal.dismiss();
    }

    const result = new AppPasswordResult();
    result.appName = this.appName;
    result.generatedClientId = this.generatedClientId;
    result.generatedClientSecret = this.generatedClientSecret;

    this.activeModal.close(result)
  }
}

export class AppPassword {
  clientId: string;
  displayName: string;
}
export class AppPasswordResult {
  generatedClientSecret: string;
  generatedClientId: string;
  appName: string;
}