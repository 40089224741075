<div class="card-body">
    <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
            <a class="nav-link active" data-bs-toggle="tab" href="#xml" role="tab" aria-selected="true">
                Import MISMO 3.4 (xml)
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#fnm" role="tab" aria-selected="false">
                Import DU 3.2 (fnm)
            </a>
        </li>
        <li class="nav-item" *ngIf="isTpoUser && isImportFromAusAllowed">
            <a class="nav-link" data-bs-toggle="tab" href="#do" role="tab" aria-selected="false">
                Import From Fannie
            </a>
        </li>
        <li class="nav-item" *ngIf="isSuperAdmin || (!isTpoUser && losEnabled)">
            <a class="nav-link" data-bs-toggle="tab" href="#importLos" role="tab" aria-selected="false">
                Import from LOS
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#new" role="tab" aria-selected="false">
                Start New Application
            </a>
        </li>
    </ul>

    <div class="tab-content">
        <div class="tab-pane p-3 active" id="xml" role="tabpanel">
            <import-mismo [isTpoUser]="isTpoUser"
                [isAdminUser]="isAdminUser"
                [firstRole]="firstRole"
                [availableUsers]="availableUsers"
                [enabledChannels]="enabledChannels"
                [externalCompanies]="externalCompanies"
                [selectedChannel]="selectedChannel"
                [selectedCompanyId]="selectedCompanyId"
                [globalConfig]="context.globalConfig"
                [userCompanyGuid]="context.currentlyLoggedInUser.userCompanyGuid"
                [tpoEnabledRoles]="tpoRoles"
                (processMismoFile)="processMismoFile($event)"></import-mismo>
        </div>

        <div class="tab-pane p-3" id="fnm" role="tabpanel">
            <import-du
                [isTpoUser]="isTpoUser"
                [firstRole]="firstRole"
                [availableUsers]="availableUsers"
                [enabledChannels]="enabledChannels"
                [externalCompanies]="externalCompanies"
                [selectedChannel]="selectedChannel"
                [selectedCompanyId]="selectedCompanyId"
                [globalConfig]="context.globalConfig"
                [userCompanyGuid]="context.currentlyLoggedInUser.userCompanyGuid"
                [tpoEnabledRoles]="tpoRoles"
                (processDuFile)="processDuFile($event)">
              </import-du>
        </div>

        <div class="tab-pane p-3" id="do" role="tabpanel">
            <import-fannie [isTpoUser]="isTpoUser" [firstRole]="firstRole" [availableUsers]="availableUsers"
                [enabledChannels]="enabledChannels" [externalCompanies]="externalCompanies"
                [selectedChannel]="selectedChannel" [selectedCompanyId]="selectedCompanyId"
                [globalConfig]="context.globalConfig" [userCompanyGuid]="context.currentlyLoggedInUser.userCompanyGuid"
                [processingFannie]="processingFannie" [tpoEnabledRoles]="tpoRoles"
                (processFannie)="processFannie($event)"></import-fannie>
        </div>

        <div class="tab-pane p-3" id="importLos" role="tabpanel">
            <import-from-los [firstRole]="firstRole" [availableUsers]="availableUsers"
                [enabledChannels]="enabledChannels" [selectedChannel]="selectedChannel"
                [externalCompanies]="externalCompanies" [isTpoUser]="isTpoUser" [isSuperAdmin]="isSuperAdmin"
                [selectedCompanyId]="selectedCompanyId" [globalConfig]="context.globalConfig"
                [userCompanyGuid]="context.currentlyLoggedInUser.userCompanyGuid" [tpoEnabledRoles]="tpoRoles"
                (importFromLos)="importFromLos($event)"></import-from-los>
        </div>

        <div class="tab-pane p-3" id="new" role="tabpanel">
            <start-new-app [isTpoUser]="isTpoUser" [isCompanySmartfi]="isCompanySmartfi" [availableUsers]="availableUsers" [enabledChannels]="enabledChannels"
                [externalCompanies]="externalCompanies" [selectedChannel]="selectedChannel"
                [selectedCompanyId]="selectedCompanyId" [globalConfig]="context.globalConfig"
                [tpoEnabledRoles]="tpoRoles"
                [userCompanyGuid]="context.currentlyLoggedInUser.userCompanyGuid" (startNewApp)="startNewApp($event)">
            </start-new-app>
        </div>
    </div>
</div>
