import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { chain, cloneDeep, isNil, orderBy } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom, forkJoin, from, map, Observable, Observer, Subscription } from 'rxjs';
import { Utils } from 'src/app/core/services/utils';

import { DatePipe } from '@angular/common';
import {
  ApplicationContext,
  Borrower,
  Branch,
  Configuration,
  CustomData,
  DuHistoryDocument,
  EmploymentTypeEnum,
  ExternalCompany,
  HousingExpense,
  Income,
  LoanApplication,
  LpaHistoryDocument,
  MortgageBorrower,
  ProductPricing,
} from 'src/app/models';
import { AddressLookupResult } from 'src/app/models/address-lookup-result.model';
import { LeadCampaign } from 'src/app/models/config/global-config.model';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { LoanType } from 'src/app/models/config/loan-type.model';
import { LoanProduct } from 'src/app/models/config/product.model';
import { LoanDetailsInfo } from 'src/app/models/loan/loan-details-info.model';
import { MortgageCalculationDetails, MortgageFundsToCloseCalculationDetails } from 'src/app/models/mortgage-calculation-details.model';
import { MortgageDti } from 'src/app/models/mortgage-dti.model';
import { ReferralSource } from 'src/app/models/referral-source.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { User } from 'src/app/models/user/user.model';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { DocFile } from 'src/app/modules/loan-docs/models/doc-file.model';
import { PrequalDetail } from 'src/app/modules/prequal-detail/models/prequal-detail.model';
import { PrequalDetailService } from 'src/app/modules/prequal-detail/services/prequal-detail.service';
import {
  UpsertReferralSourceComponent,
} from 'src/app/modules/referral-source/components/upsert-referral-source/upsert-referral-source.component';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { MortgageCalculationService } from 'src/app/modules/urla/services/mortgage-calculation.service';
import { AgentService } from 'src/app/services/agent.service';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { LoanService } from 'src/app/services/loan';
import { MenuService } from 'src/app/services/menu.service';
import { MortgageService } from 'src/app/services/mortgage.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import Swal from 'sweetalert2';
import { DiffChecker } from '../../../../../../utils/diff-checker';
import { ComponentCanDeactivate } from '../../../../../core/route-guards/pending-changes.guard';
import { Lender } from '../../../../../models/config/lender.model';
import { LenderConfigService } from '../../../../admin/lender-config/services/lender-config.service';
import { Agent, AgentFull } from '../../../models/agent.model';
import { AppDetailsService } from '../../../services/app-details.service';
import { LenderInfoDialogComponent } from '../../loan-info/loan-details/lender-info-dialog/lender-info-dialog.component';
import { AddressDetailViewDialogComponent } from '../address-detail-view-dialog/address-detail-view-dialog.component';

@Component({
  selector: 'loan-summary-mortgage',
  templateUrl: './loan-summary-mortgage.component.html',
  styleUrls: ['./loan-summary-mortgage.component.scss']
})
export class LoanSummaryMortgageComponent extends ApplicationContextBoundComponent implements OnInit, ComponentCanDeactivate {

  @ViewChild("loanForm")
  loanForm: NgForm;

  @Input()
  applicationOutsideOfContext: LoanApplication;

  @Input()
  isUsedAsChildComponent: boolean = false;

  @Output()
  dialClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  closeDrawer: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  /**
   * @returns true if it has started loading, and false if loading has completed
   */
  loadingStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  protected loadingLoanSummary: boolean = true;

  editModeToggle: boolean = false;
  editorMode: string = 'Inline';

  application: LoanApplication;
  mortgage: UrlaMortgage;

  ftcDetails: MortgageFundsToCloseCalculationDetails;

  selectedPropertyType: string = null;

  get proposedHousingExpenseTotal(): number {
    return this._calculationService.calculateHousingExpenseTotal(this.mortgage.proposedHousingExpense);
  }

  get currentHousingExpenseTotal(): number {
    return this._calculationService.calculateHousingExpenseTotal(this.mortgage.currentHousingExpense);
  }

  purchaseCreditsTotal: number;
  reoTotal: number;
  assetTotal: number;
  liabilityTotal: number;
  incomeTotal: number;
  totalMonthlyDebts: number;
  housingPaymentsTotal: number;
  unPaidBalanceTotal: number;

  borrowers: MortgageBorrower[] = [];
  loanBorrowers: Borrower[] = [];
  incomes: Income[] = [];

  downPayment: number;
  downPaymentSources: string;

  mortgageDti: MortgageDti = null;

  piTiInfo: any;
  totalPiTi: number;

  mapId: number = Utils.getUniqueId();

  companyId: number;
  enabledChannels: EnumerationItem[] = [];
  externalCompanies: ExternalCompany[] = [];
  externalCompanyBranches: Branch[] = [];
  externalCompanyBranchesFiltered: Branch[] = [];

  loanPurposes: LoanPurpose[] = [];
  loanTypes: LoanType[] = [];
  loanPurposesFiltered: LoanPurpose[] = [];
  loanTypesFiltered: LoanType[] = [];
  lenders: Lender[] = [];
  isLenderSelectCollapsed = true;
  isCompTypeSelectCollapsed = true;
  compTypes: any = [{ name: 'Lender Paid', value: 'Lender' }, { name: 'Borrower Paid', value: 'Borrower' }, { name: 'Correspondent', value: 'Correspondent' }];

  agents: ReferralSource[];
  users: User[] = [];

  leadCampaigns: LeadCampaign[] = [];

  customData: CustomData[];

  allLoanPurposes: LoanPurpose[] = [];
  products: LoanProduct[] = [];

  ficoOnFile: number | undefined;

  productTypes: EnumerationItem[] = [];
  projectTypes: EnumerationItem[] = [];
  attachmentTypes: EnumerationItem[] = [];
  lienPositionTypes: EnumerationItem[] = [];
  amortizationTypes: EnumerationItem[] = [];
  mortgageAppliedForTypes: EnumerationItem[] = [];
  governmentRefinanceTypes: EnumerationItem[] = [];
  propertyOccupancyTypes: EnumerationItem[] = [];
  propertyTypes: EnumerationItem[] = [];
  constructionMethodOptions: EnumerationItem[] = [];
  loanPurposeOptions: EnumerationItem[] = [];
  states: EnumerationItem[] = [];
  duDocFiles: any[] = [];
  lpaDocFiles: any[] = [];
  proofOfFundsDocFiles: DocFile[] = [];

  yesNoOptions: EnumerationItem[] = [
    { name: "Yes", value: true },
    { name: "No", value: false }
  ];

  showPrequalDetailsSection: boolean = false;
  showCurrentLoanInfoSection: boolean = false;
  secondPageActionBarVisible: boolean = false;
  showQMICardBody: boolean = false;
  showTMDCardBody: boolean = false;
  showTACardBody: boolean = false;
  showREOCardBody: boolean = false;
  showPCCardBody: boolean = false;
  showTDCardBody: boolean = true;
  showCDCardBody: boolean = true;
  isHomesiteHack = false;

  isPurchase: boolean = false;
  isRefi: boolean = false;

  hiddenFields: string[] = [];

  isSubjectPropertyAddressHidden: boolean = false;
  isAppraisedValueHidden: boolean = false;
  isEstimatedValueHidden: boolean = false;

  isLoadingAusDocs: boolean = false;

  hasRateLockExpirationKeyDate: boolean = false;

  prequalDetail: PrequalDetail = new PrequalDetail();

  constructionMethodNamesByKey = {};

  realtorCompanyName: string;

  minDownPaymentDollar: number = 0;
  maxDownPaymentDollar: number = 0;

  protected secondaryReferralCompanyName: string;

  protected subjectPropertyAddressGoogleMapsUrl: string = "";

  protected duDocumentId: string;
  protected lpaDocumentId: string;

  protected loanPurposeId: number;
  protected channel: string;

  protected lockExpirationDateString: string;
  protected lockDateString: string;

  protected requestedLtv: number | null = null;

  protected isLoanReadOnly: boolean = false;

  protected branchesLoaded: boolean = false;

  private _loanPurposePurchaseEnumValue: string;
  private _loanPurposeRefiEnumValue: string;

  private _contextSubscription: Subscription;
  private _loanInfoChangesSubscription: Subscription;
  private _purposeOfLoanChangedSubscription: Subscription;
  private _keyDatesSubscription: Subscription;

  private _originalApplication: LoanApplication;
  private _originalMortgage: UrlaMortgage;
  private _originalCustomData: CustomData[];

  private _duHistoryDocuments: DuHistoryDocument[] = [];
  private _lpaHistoryDocuments: LpaHistoryDocument[] = [];

  private _allAgents: Agent[] = [];

  private _subjectPropertyGoogleMapsUrlBase = "https://www.google.com/maps/embed/v1/place?key=AIzaSyBoUoADZ4zHLAlCo9t-_99msbC5I7EbeZ8&q=";

  constructor(
    private readonly _calculationService: MortgageCalculationService,
    private readonly _mortgageService: MortgageService,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _loanService: LoanService,
    private readonly _agentService: AgentService,
    private readonly _prequalDetailService: PrequalDetailService,
    private readonly _enumsService: EnumerationService,
    private readonly _modalService: NgbModal,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _lenderService: LenderConfigService,
    private readonly _datePipe: DatePipe,
    private readonly _menuService: MenuService,
    injector: Injector,
  ) {
    super(injector);
  }

  async ngOnInit() {

    this.companyId = this.applicationContext.userPermissions.companyId;

    try {
      await Promise.all([
        this.loadLoanHiddenFields(),
        this.loadMortgageEnums(),
        this.loadLenders(),
        this.loadAllAgents()
      ]);
    } catch (error) {
      this._notifyService.showError(error?.message || 'An error occurred while loading loan summary mortgage data', 'Error');
    }

    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe(appContext => {
      if (appContext.application && appContext.currentMortgage) {
        this.initialize(appContext);
        this.initPropertyAddressMap();
      }
    });

    this._purposeOfLoanChangedSubscription = this._mortgageService.loanPurpose$
      .subscribe((updatedPurposeOfLoan: string) => {
        if (this.mortgage) {
          this.mortgage.subjectProperty.purposeOfLoan = updatedPurposeOfLoan;
          this.onMortgagePurposeOfLoanChanged();
        }
      });

    this._keyDatesSubscription = this.applicationContextService.keyDatesChanges.subscribe(() => {
      this.updateKeyDateBasedPricingFields(false);
    });

    if (this.applicationOutsideOfContext) {
      this.setLoadingState(true);
      this.application = this.applicationOutsideOfContext;
      if(!this.application.productPricing) this.application.productPricing = new ProductPricing();
      this.mortgage = this.application.mortgageLoan;
      this.createLocalApplicationContext().subscribe(localContext => {
        this.applicationContextService.updateMortgageAndApplication(localContext.currentMortgage,
          localContext.application, localContext.customData, localContext.borrowers);
        this.setLoadingState(false);
      });
    } else if (this.applicationContext.application && this.applicationContext.currentMortgage) {
      this.initialize(this.applicationContext);
    }
  }

  ngAfterViewInit() {
    if (this.mortgage) {
      this.initPropertyAddressMap();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._contextSubscription) {
      this._contextSubscription.unsubscribe();
    }
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
    if (this._purposeOfLoanChangedSubscription) {
      this._purposeOfLoanChangedSubscription.unsubscribe();
    }
    if (this._keyDatesSubscription) {
      this._keyDatesSubscription.unsubscribe();
    }
  }

  canDeactivate(): boolean | Observable<boolean> {
    // dont check when session expires
    if (!this.applicationContext) return true;

    return this.loadingLoanSummary || !this._isDirty();
  }

  confirm(): boolean | Observable<boolean> {
    return from(this.showNotSavedDialog());
  }

  onMinDownPaymentPercentChanged = () => {
    const percent = this.prequalDetail.downPaymentPercent || 0;
    const purchasePrice = this.prequalDetail.maxPurchasePrice || 0;

    this.minDownPaymentDollar = (percent / 100) * purchasePrice;
  }

  onMinDownPaymentDollarChanged = () => {
    const downPayment = this.minDownPaymentDollar || 0;
    const purchasePrice = this.prequalDetail.maxPurchasePrice || 0;

    if (purchasePrice > 0) {
      this.prequalDetail.downPaymentPercent = (downPayment / purchasePrice) * 100;
    }
  }

  onMaxDownPaymentPercentChanged = () => {
    const percent = this.prequalDetail.maxDownPaymentPercent || 0;
    const purchasePrice = this.prequalDetail.maxPurchasePrice || 0;

    this.maxDownPaymentDollar = (percent / 100) * purchasePrice;
  }

  onMaxDownPaymentDollarChanged = () => {
    const downPayment = this.maxDownPaymentDollar || 0;
    const purchasePrice = this.prequalDetail.maxPurchasePrice || 0;

    if (purchasePrice > 0) {
      this.prequalDetail.maxDownPaymentPercent = (downPayment / purchasePrice) * 100;
    }
  }

  onReferralSourceChanged = () => {
    if (this.application?.referralSource) {
      let matched = this._allAgents.find(a => a.agentId == this.application.referralSource);
      if (matched) {
        if (matched.orgName) {
          this.realtorCompanyName = matched.orgName;
        }
        else {
          if (matched.agentCompany) {
            this.realtorCompanyName = matched.agentCompany.companyName || null;
          }
          else {
            this.realtorCompanyName = null;
          }
        }
      }
    } else {
      this.realtorCompanyName = null;
    }
  }

  onSecondaryReferralSourceChanged = () => {
    if (!this.application) {
      return;
    }
    if (this.application.secondaryReferralSource) {
      let matched = this._allAgents.find(a => a.agentId == this.application.secondaryReferralSource);
      if (matched) {
        if (matched.orgName) {
          this.secondaryReferralCompanyName = matched.orgName;
        }
        else {
          if (matched.agentCompany) {
            this.secondaryReferralCompanyName = matched.agentCompany.companyName || null;
          }
          else {
            this.secondaryReferralCompanyName = null;
          }
        }
      }
    } else {
      this.secondaryReferralCompanyName = null;
    }
  }

  toggleEditModeChange = (isChecked: boolean) => {
    this.applicationContextService.toggleLoanEditMode(isChecked);
    this.editorMode = isChecked ? 'Classic' : 'Inline';
  }

  onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult) => {
    if (zipCode) {
      this.mortgage.subjectProperty.state = zipCode.state?.toLowerCase() ?? this.mortgage.subjectProperty.state;
      this.mortgage.subjectProperty.city = Utils.toTitleCase(zipCode.city);
      this.mortgage.subjectProperty.zipCode = zipCode.zipcode;
      this.mortgage.subjectProperty.county = zipCode.county;
      this.mortgage.subjectProperty.country = 'us';
    }
  }

  onDuDocFileIdChanged = () => {
    const selectedItem = this.duDocFiles.find(x => x.lsAusDocId == this.duDocumentId);
    if (selectedItem && selectedItem.lsAusDocId) {
      const parts = selectedItem.lsAusDocId.split("_");
      if (parts.length === 2) {
        if (parts[0] === "DF") {
          this.prequalDetail.duDocFileId = parts[1];
        } else {
          this.prequalDetail.duHistoryDocumentId = parts[1];
        }
      }
    } else {
      this.prequalDetail.duDocFileId = null;
      this.prequalDetail.duHistoryDocumentId = null;
    }
  }

  onLpaDocFileIdChanged = () => {
    const selectedItem = this.lpaDocFiles.find(x => x.lsAusDocId == this.lpaDocumentId);
    if (selectedItem && selectedItem.lsAusDocId) {
      const parts = selectedItem.lsAusDocId.split("_");
      if (parts.length === 2) {
        if (parts[0] === "DF") {
          this.prequalDetail.lpaDocFileId = parts[1];
        } else {
          this.prequalDetail.lpaHistoryDocumentId = parts[1];
        }
      }
    } else {
      this.prequalDetail.lpaDocFileId = null;
      this.prequalDetail.lpaHistoryDocumentId = null;
    }
  }

  onCloseDrawer = () => {
    this.closeDrawer.emit();
  };

  onHandleAddressChange = (lookupResult: AddressLookupResult) => {
    this.mortgage.subjectProperty.address1 = null; // to reset the last populated address.

    if (!lookupResult) {
      this.mortgage.subjectProperty.city = null;
      this.mortgage.subjectProperty.state = null;
      this.mortgage.subjectProperty.zipCode = null;
      this.mortgage.subjectProperty.county = null;
    }
    else {
      if (lookupResult.city) {
        this.mortgage.subjectProperty.city = lookupResult.city;
      }

      if (lookupResult.state) {
        this.mortgage.subjectProperty.state = lookupResult.state;
      }

      if (lookupResult.zipCode) {
        this.mortgage.subjectProperty.zipCode = lookupResult.zipCode;
      }

      if (lookupResult.address) {
        this.mortgage.subjectProperty.address1 = lookupResult.address;
      }

      if (lookupResult.county) {
        this.mortgage.subjectProperty.county = lookupResult.county;
      }
    }

    this.initPropertyAddressMap();
  }

  calculateTotalDue = () => {
    this.mortgage.calculatedStats.totalDue = this._calculationService.calculateTotalDue(this.mortgage);
    this.mortgage.calculatedStats.cashFromOrToTheBorrower = this._calculationService.calculateCashFromOrToTheBorrower(this.mortgage);

    this.mortgage.transactionDetail.estimatedClosingCostsAmount =
      (this.mortgage.transactionDetail.prepaidItemsEstimatedAmount ?? 0) +
      (this.mortgage.transactionDetail.prepaidEscrowsTotalAmount ?? 0) +
      (this.mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalAmount ?? 0);
  }

  onMortgageTermChanged = async (): Promise<void> => {
    await this.recalculateFirstMortgagePAndI();
  }

  productTypeChanged = () => {
    if (!this.checkApplicationLoanType()) return;
    if (!['FHA', 'VA', 'USDA'].includes(this.mortgage.mortgageTerm.mortgageAppliedFor)) {
      this.mortgage.governmentLoanDetail.sectionOfActType = null;
    }
  }

  onInterestRateChanged = async (): Promise<void> => {
    this.application.productPricing.rate = this.mortgage.mortgageTerm.interestRate;
    await this.recalculateFirstMortgagePAndI();
  }

  onProductPricingRateChanged = async (): Promise<void> => {
    this.mortgage.mortgageTerm.interestRate = this.application.productPricing.rate;
    await this.recalculateFirstMortgagePAndI();
  }

  updateMortgageAppliedFor = (): boolean => {
    if (!this.application.loanTypeId) return true;

    let currLp = this.loanTypes.find(x => x.loanTypeId == this.application.loanTypeId);
    if (currLp.mortgageAppliedForType == this.mortgage.mortgageTerm.mortgageAppliedFor)
      return true;

    this.mortgage.mortgageTerm.mortgageAppliedFor = currLp.mortgageAppliedForType;
    return true;
  }

  onMortgagePurposeOfLoanChanged = () => {
    if (!this.checkApplicationLoanPurpose()) return;

    this.isPurchase = this.isPurposeOfLoanPurchase();
    this.isRefi = this.isPurposeOfLoanRefinance();
    if (!this.isPurchase) {
      this.resetValuesForNotPurchase();
    }
    if (!this.isRefi) {
      this.resetValuesForNotRefinance();
    }
    this._calculationService.calculateMortgageStatistics(this.mortgage);
  }

  openPropertyAddressDialog = (placeID: string) => {
    let modalRef = this._modalService.open(AddressDetailViewDialogComponent, Constants.modalOptions.fullScreen);
    modalRef.componentInstance.googlePlaceId = placeID;

    modalRef.result.then(() => {
    }, () => {
    });
  }

  getWords = (str: string) => {
    return Utils.splitCamelCaseString(str);
  }

  getBorrowerFullName = (incomeBorrowerId: number | null, incomeEmploymentId?: number): string => {
    let borrower;
    if (incomeEmploymentId) {
      borrower = this.borrowers.find(b => b.employments.findIndex(e => e.employmentId == incomeEmploymentId) >= 0);
    } else {
      borrower = this.borrowers.find(b => b.borrowerId == incomeBorrowerId);
    }
    return Utils.getBorrowerFullName(borrower);
  }

  getPersonFullNames = (borrowerIds: number[], isLoanBorrower: boolean = false): string => {
    let borrowerArray = isLoanBorrower ? this.loanBorrowers : this.borrowers as any[];
    let matchedPersons = borrowerIds.map(id => borrowerArray.find(b => b.borrowerId == id));
    return matchedPersons.length > 0 ? matchedPersons.map(b => Utils.getBorrowerFullName(b)).join(",") : null;
  }

  protected onCustomDataInitialized() {
    this._originalCustomData = _.cloneDeep(this.customData);
  }

  private resetOriginalProperties() {
    this._originalApplication = _.cloneDeep(this.application);
    this._originalMortgage = _.cloneDeep(this.mortgage);
    this._originalCustomData = _.cloneDeep(this.customData);
  }

  private showNotSavedDialog = async (): Promise<boolean> => {
    const result = await Swal.fire({
      showDenyButton: true,
      title: 'Are you sure?',
      text: `You have changes that are not saved. Would you like to save your changes? If you choose Discard below, your changes will be lost.`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Save & Continue!',
      cancelButtonText: 'Cancel',
      cancelButtonColor: "#DD6B55",
      denyButtonText: `Discard & Continue!`,
      reverseButtons: true
    });

    if (result.isDenied) return true;
    if (!result.isConfirmed) return false;

    await this.saveLoanInfo();

    return true;
  }

  private setLoadingState = (value: boolean): void => {
    this.loadingLoanSummary = value;
    this.loadingStateChange.emit(value);
  }

  private checkApplicationLoanPurpose = (): boolean => {
    if (!this.mortgage.subjectProperty.purposeOfLoan) return true;

    let currLp = this.loanPurposes.find(x => x.loanPurposeId == this.application.loanPurposeId);
    if (currLp.mortgageLoanPurpose == this.mortgage.subjectProperty.purposeOfLoan)
      return true;

    let filteredLoanPurposes = this.loanPurposes.filter(x => x.mortgageLoanPurpose == this.mortgage.subjectProperty.purposeOfLoan);
    if (filteredLoanPurposes.length == 1) {
      this.application.loanPurposeId = filteredLoanPurposes[0].loanPurposeId;
      this.onAfterApplicationLoanPurposeChanged();
      return true;
    }
    else {
      // let modalRef = this._modalService.open(LoanPurposeSelectorDialog, Constants.modalOptions.small);
      // modalRef.componentInstance.dialogTitle = 'Select an Updated Loan Purpose';
      // modalRef.componentInstance.enumLabel = 'Loan Purpose';
      // modalRef.componentInstance.enumValues = filteredLoanPurposes;

      // modalRef.result.then((result) => {
      //   this.application.loanPurposeId = result;
      //   this.onPurposeOfLoanChanged();
      // }, () => {
      // });
      return false;
    }
  }

  private checkApplicationLoanType = (): boolean => {
    if (!this.mortgage.mortgageTerm.mortgageAppliedFor) return true;

    let currLp = this.loanTypes.find(x => x.loanTypeId == this.application.loanTypeId);
    if (currLp.mortgageAppliedForType == this.mortgage.mortgageTerm.mortgageAppliedFor)
      return true;

    let filteredLoanTypes = this.loanTypes.filter(x => x.mortgageAppliedForType == this.mortgage.mortgageTerm.mortgageAppliedFor);
    if (filteredLoanTypes.length == 1) {
      this.application.loanTypeId = filteredLoanTypes[0].loanTypeId;
      return true;
    }
    else {
      // let modalRef = this._modalService.open(SingleEnumSelectorDialog, Constants.modalOptions.small);
      // modalRef.componentInstance.dialogTitle = 'Select an Updated Loan Type';
      // modalRef.componentInstance.enumLabel = 'Loan Type';
      // modalRef.componentInstance.enumValues = filteredLoanTypes;

      // modalRef.result.then((result) => {
      //   this.application.loanPurposeId = result;
      //   this.productTypeChanged();
      // }, () => {
      // });
      return false;
    }
  }

  private createLocalApplicationContext = (): Observable<any> => {
    const apisToCall = {};
    const application = this.applicationOutsideOfContext;
    apisToCall['getCustomDataCall'] = this._loanService.getCustomData(this.applicationOutsideOfContext.applicationId);
    apisToCall['getBorrowersCall'] = this._loanService.getBorrowers(this.applicationOutsideOfContext.applicationId);
    apisToCall['getKeyDatesByTypeCall'] = this._loanService.getKeyDatesByType(this.applicationOutsideOfContext.applicationId);
    return forkJoin(apisToCall).pipe(map(response => {
      const localContext = {
        application: application,
        currentMortgage: application.mortgageLoan,
        customData: response['getCustomDataCall'],
        borrowers: response['getBorrowersCall'],
        keyDatesByType: response['getKeyDatesByTypeCall'],
        currentMortgageCalculationDetails: response['getMortgageCalculationDetails']
      }
      return localContext;
    }))
  }

  private setValidationStatuses = (mortgage: UrlaMortgage) => {
    const borrowersValidationStatus = this._menuService.getStatusForBorrowerInfo(mortgage);
    const loanInfoValidationStatus = this._menuService.getStatusForLoanInfo(mortgage);
    const financialInfoValidationStatus = this._menuService.getStatusForFinancialInfo(mortgage);
    setTimeout(() => {
      this._menuService.setStatus("qaBorrowerInfo", borrowersValidationStatus);
      this._menuService.setStatus("qaLoanInfo", loanInfoValidationStatus);
      this._menuService.setStatus("qaFinancialInfo", financialInfoValidationStatus);
    })
  }

  private initialize = async (appContext: ApplicationContext): Promise<void> => {
    this.setLoadingState(true);

    this.isLoanReadOnly = appContext.applicationIsReadOnly;

    this.secondPageActionBarVisible = appContext.isCallControlPanelOpen;
    this.isHomesiteHack = appContext.userPermissions.companyId == 158;

    this.application = cloneDeep(appContext.application);
    if (!this.application.productPricing) {
      this.application.productPricing = new ProductPricing();
    }
    this.loanBorrowers = cloneDeep(appContext.borrowers);
    this.mortgage = cloneDeep(appContext.currentMortgage);

    if (this.mortgage.proposedHousingExpense) {
      if (!this.mortgage.proposedHousingExpense.supplementalPropertyInsurance) {
        this.mortgage.proposedHousingExpense.supplementalPropertyInsurance = 0;
      }
    }

    if (this.mortgage.mortgageTerm) {
      this.mortgage.mortgageTerm.amount = Math.floor(this.mortgage.mortgageTerm.amount ?? 0);
    }
    if (this.mortgage.mortgageTerm && this.mortgage.mortgageTerm.totalLoanAmount) {
      this.mortgage.mortgageTerm.totalLoanAmount = Math.floor(this.mortgage.mortgageTerm.totalLoanAmount);
    }
    let borrowers = cloneDeep(appContext.currentMortgage?.borrowers) || [];
    this.borrowers = orderBy(borrowers, ['printApplicationIndex', 'jointWithBorrowerId'], ['asc', 'desc']);

    this.setValidationStatuses(this.mortgage);

    const creditScores = this.borrowers.filter(b => b.creditScore).map(b => b.creditScore);
    if (creditScores && creditScores.length) {
      this.ficoOnFile = Math.min(...creditScores);
    }

    this.editModeToggle = appContext.isLoanEditModeOpen;
    this.editorMode = appContext.isLoanEditModeOpen ? 'Classic' : 'Inline';

    this.enabledChannels = cloneDeep(appContext.globalConfig.enabledChannels);
    this.externalCompanies = _.sortBy(cloneDeep(appContext.globalConfig.externalCompanies), 'name');
    this.loanPurposes = cloneDeep(appContext.globalConfig.loanPurpose);
    this.loanTypes = cloneDeep(appContext.globalConfig.loanType);
    this.loanPurposesFiltered = this.loanPurposes;
    this.loanTypesFiltered = this.loanTypes;
    this.users = cloneDeep(appContext.globalConfig.users) || [];
    this.users.forEach(u => {
      u["userFullName"] = this.getUserFullName(u.userCompanyGuid);
    });

    this.leadCampaigns = cloneDeep(appContext.globalConfig.leadCampaigns.filter(x => x.active || x.leadCampaignId == this.application.leadCampaignId)) || [];
    this.customData = cloneDeep(appContext.customData);
    this.allLoanPurposes = cloneDeep(appContext.globalConfig.loanPurpose);

    if (appContext.globalConfig.product)
      this.products = (cloneDeep(appContext.globalConfig.product)).sort((a, b) => a.sortOrder - b.sortOrder) || [];

    this.showPrequalDetailsSection = this.allLoanPurposes.find(x => x.loanPurposeId === this.application.loanPurposeId)?.mortgageLoanPurpose == "Purchase";
    this.showCurrentLoanInfoSection = !this.showPrequalDetailsSection;

    this.isLoadingAusDocs = true;

    if (appContext.globalConfig.branches && appContext.globalConfig.branches.length > 0) {
      this.externalCompanyBranches = cloneDeep(appContext.globalConfig.branches);
      if (this.application) {
        this.onChannelSelect(this.application.channel);
        this.onExternalCompanySelect(this.application.externalCompanyId);
        this.branchesLoaded = true;
      }
    }

    await Promise.all([
      ...(this.application != null ? [
        this.loadAgents(),
        this.getPrequalDetail(),
      ] : []),
      this.updateKeyDateBasedPricingFields(true),
      this.getAllFannieMaeApprovalDocs(),
      this.getAllFreddieMacApprovalDocs(),
      this.getAllProofOfFundsDocs()
    ]);

    if (this.prequalDetail.duDocFileId) {
      this.duDocumentId = "DF_" + this.prequalDetail.duDocFileId;
    } else if (this.prequalDetail.duHistoryDocumentId) {
      this.duDocumentId = "HF_" + this.prequalDetail.duHistoryDocumentId;
    }

    if (this.prequalDetail.lpaDocFileId) {
      this.lpaDocumentId = "DF_" + this.prequalDetail.lpaDocFileId;
    } else if (this.prequalDetail.lpaHistoryDocumentId) {
      this.lpaDocumentId = "HF_" + this.prequalDetail.lpaHistoryDocumentId;
    }

    const proofOfFundsDocFilesAsOptionsForAus = this.proofOfFundsDocFiles.map(doc => {
      return {
        groupName: "Proof Of Funds",
        lsAusDocId: "DF_" + doc.docFileId,
        lsAusDocName: doc.fileName + ' - ' + this._datePipe.transform(doc.dateInserted, 'MM/dd/yyyy h:mma')
      };
    });

    this.duDocFiles = [
      ...this._duHistoryDocuments.map(doc => {
        doc["groupName"] = "DO/DU";
        doc["lsAusDocId"] = "HF_" + doc.duHistoryDocumentId;
        doc["lsAusDocName"] = doc.fileName + ' - ' + this._datePipe.transform(doc.dateInserted, 'MM/dd/yyyy h:mma');
        return doc;
      })
    ];
    this.duDocFiles = this.duDocFiles.concat(proofOfFundsDocFilesAsOptionsForAus);

    this.lpaDocFiles = [
      ...this._lpaHistoryDocuments.map(doc => {
        doc["groupName"] = "LPA";
        doc["lsAusDocId"] = "HF_" + doc.lpaHistoryDocumentId;
        doc["lsAusDocName"] = doc.fileName + ' - ' + this._datePipe.transform(doc.dateInserted, 'MM/dd/yyyy h:mma');
        return doc;
      }),
    ];
    this.lpaDocFiles = this.lpaDocFiles.concat(proofOfFundsDocFilesAsOptionsForAus);

    this.isLoadingAusDocs = false;

    let incomes = [];
    let currentEmploymentIncomes = [];
    this.borrowers.forEach(b => {
      let employmentIncomes = [];
      b.employments.forEach(e => {
        if (e.selfEmployed) {
          if (e.employmentType === EmploymentTypeEnum.CurrentEmployer) {
            currentEmploymentIncomes.push({
              borrowerId: b.borrowerId,
              typeOfIncome: 'Self Employed',
              monthlyIncome: e.selfEmploymentMonthlyIncomeOrLoss
            });
          }
          employmentIncomes.push({
            borrowerId: b.borrowerId,
            typeOfIncome: 'Self Employed',
            monthlyIncome: e.selfEmploymentMonthlyIncomeOrLoss
          });
        } else {
          if (e.employmentType === EmploymentTypeEnum.CurrentEmployer) {
            currentEmploymentIncomes.push(...e.incomes);
          }
          employmentIncomes.push(...e.incomes);
        }
      });
      incomes.push(...employmentIncomes);
      incomes.push(...b.nonEmploymentIncomes);
      currentEmploymentIncomes.push(...b.nonEmploymentIncomes);
    })

    const netpropertyRentalIncome = this.mortgage.subjectProperty?.expectedNetMonthlyRentalIncome ?? 0;

    this.incomes = incomes;
    this.incomeTotal = this.calculateTotal(currentEmploymentIncomes, "monthlyIncome") + netpropertyRentalIncome;

    if (this.mortgage) {
      if(! this.mortgage.currentHousingExpense){
        this.mortgage.currentHousingExpense = new HousingExpense();
      }

      if(! this.mortgage.proposedHousingExpense){
        this.mortgage.proposedHousingExpense = new HousingExpense();
      }

      this.purchaseCreditsTotal = this._calculationService.calculateSourceOfFundsValue(this.mortgage);
      this.reoTotal = this.calculateTotal(this.mortgage.realEstateOwned, "marketValue");
      this.assetTotal = this.calculateTotal(this.mortgage.assets, "cashMarketValue");
      this.liabilityTotal = this.calculateTotal(this.allIncludedLiabilities, "monthlyPayment");
      this.totalMonthlyDebts = this._calculationService.calculateBorrowerMonthlyDebts(this.mortgage)

      this.housingPaymentsTotal = this._calculationService.calculateHousingExpenseTotal(this.mortgage.proposedHousingExpense)
        + this._calculationService.calculateBorrowerMonthlyDebts(this.mortgage);

      this.unPaidBalanceTotal = this.calculateTotal(this.mortgage.liabilities, "unpaidBalance");

      this.piTiInfo = this._calculationService.getPiTiPaymentInfo(this.mortgage);
      this.totalPiTi = this.piTiInfo.pi + this.piTiInfo.ti;

      this.isPurchase = this.isPurposeOfLoanPurchase();
      if (!this.isPurchase) {
        this.resetValuesForNotPurchase();
      }

      this.isRefi = this.isPurposeOfLoanRefinance();
      if (!this.isRefi) {
        this.resetValuesForNotRefinance();
      }

      this.downPayment = this._calculationService.calculateDownPayment(this.mortgage);

      this.ftcDetails = appContext.currentMortgageCalculationDetails.fundsToClose;
      this.requestedLtv = appContext.currentMortgageCalculationDetails.ltv?.ltv;
    }

    this.resetOriginalProperties();

    this.loanPurposeId = this.application.loanPurposeId;
    this.channel = this.application.channel;

    if (this.channel) {
      this.products = this.products.filter(x => x.enabledChannels.indexOf(this.channel) > -1).sort((a, b) => a.sortOrder - b.sortOrder);
    }

    this.setLoadingState(false);
  }

  private calculateTotal = (items: any[], fieldName: string) => {
    return items.length > 0 ? items.reduce((prev, curr) => prev + (curr[fieldName] || 0), 0) : 0;
  }

  private loadMortgageEnums = async (): Promise<void> => {
    const result = await firstValueFrom(
      this._enumsService.getMortgageEnumerations(),
    );
    this.productTypes = result[Constants.mortgageEnumerations.mortgageAppliedForType];
    this.attachmentTypes = result[Constants.mortgageEnumerations.attachmentType];
    this.constructionMethodOptions = result[Constants.mortgageEnumerations.constructionMethodType];
    this.projectTypes = result[Constants.mortgageEnumerations.projectType];
    this.constructionMethodOptions.forEach(t => {
      this.constructionMethodNamesByKey[t.value] = t.name;
    });

    this.propertyOccupancyTypes = result[Constants.enumerations.propertyTypes];
    this.propertyTypes = result[Constants.mortgageEnumerations.propertyType];

    this.lienPositionTypes = result[Constants.mortgageEnumerations.lienPositionType];
    this.amortizationTypes = result[Constants.mortgageEnumerations.amortizationType];
    this.mortgageAppliedForTypes = result[Constants.mortgageEnumerations.mortgageAppliedForType];
    this.governmentRefinanceTypes = result[Constants.mortgageEnumerations.governmentRefinanceType];

    this.loanPurposeOptions = result[Constants.enumerations.loanPurposes];

    this.states = this._enumsService.states;

    this._loanPurposePurchaseEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.LoanPurposeType.Purchase);
    this._loanPurposeRefiEnumValue = this._enumsService.getEnumValue(Constants.enumerationValueNames.LoanPurposeType.Refinance);
  }

  private loadAgents = async (defaultSelectedAgent: AgentFull = undefined): Promise<void> => {
    const response = await firstValueFrom(this._agentService.getAllReferralSources());
    this.agents = chain(response)
      .filter(a => !isNil(a.firstName) || !isNil(a.lastName))
      .orderBy(['lastName', 'firstName'])
      .forEach(a => {
        a['agentFullName'] = Utils.getPersonsDisplayName(a) || '';
      })
      .value();

    if (defaultSelectedAgent) {
      const referralSourceToSelect = this.agents.find(a => a.firstName.toLocaleLowerCase() === defaultSelectedAgent.agent.firstName.toLocaleLowerCase() &&
        a.lastName.toLocaleLowerCase() === defaultSelectedAgent.agent.lastName.toLocaleLowerCase());
      if (referralSourceToSelect) {
        this.application.referralSource = referralSourceToSelect.agentId;
      }
    }
  }

  private initPropertyAddressMap = () => {

    if (!this.mortgage.subjectProperty.address1) {
      return;
    }

    setTimeout(() => {
      this.subjectPropertyAddressGoogleMapsUrl = this._subjectPropertyGoogleMapsUrlBase + this.mortgage.subjectProperty.address1;

      if (this.mortgage.subjectProperty.city) {
        this.subjectPropertyAddressGoogleMapsUrl += ', ' + this.mortgage.subjectProperty.city;
      }

      if (this.mortgage.subjectProperty.state) {
        this.subjectPropertyAddressGoogleMapsUrl += ', ' + this.mortgage.subjectProperty.state.toUpperCase();
      }

      if (this.mortgage.subjectProperty.zipCode) {
        this.subjectPropertyAddressGoogleMapsUrl += ' ' + this.mortgage.subjectProperty.zipCode;
      }
    })
  }

  private calculateRelatedStatsAfterLoanAmountChange = async (): Promise<void> => {
    const amount = !this.mortgage.mortgageTerm.amount ? 0 : Number(this.mortgage.mortgageTerm.amount);
    if (amount) {
      this.mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalPercent =
        (this.mortgage.mortgageInsuranceDetail.miOrFundingFeeTotalAmount / amount) * 100;
    }

    await this.recalculateFirstMortgagePAndI();
    this.mortgage.calculatedStats.totalLoanOrDrawAmount = this._calculationService.calculateTotalLoanOrDrawAmount(this.mortgage);
    this.mortgage.calculatedStats.totalMortgageLoans = this._calculationService.calculateTotalMortgage(this.mortgage);
    this.mortgage.calculatedStats.totalDueFromBorrowers = this._calculationService.calculateTotalDueFromBorrowers(this.mortgage);
    this.mortgage.calculatedStats.totalMortgageLoansAndCredits = this.mortgage.calculatedStats.totalMortgageLoans + this.mortgage.calculatedStats.totalCredit;
    this.mortgage.calculatedStats.cashFromOrToTheBorrower = this._calculationService.calculateCashFromOrToTheBorrower(this.mortgage);
  }

  private showChangeConfirmationDialogForProperty(propertyName: string) {
    return Swal.fire({
      title: 'Are you sure?',
      text: `Changing ${propertyName} will reset your Loan Status to the
       beginning when you save your changes. Are you sure you want to do this?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      cancelButtonColor: '#DC3741',
      reverseButtons: true,
    });
  }

  protected onLoanPurposeIdChange = async () => {
    const { loanPurposeId, application } = this;
    if (loanPurposeId == null || loanPurposeId === application.loanPurposeId) {
      return;
    }

    const result = await this.showChangeConfirmationDialogForProperty('Loan Purpose');

    if (result.isConfirmed) {
      application.loanPurposeId = loanPurposeId;
      this.onAfterApplicationLoanPurposeChanged();
    } else {
      this.loanPurposeId = application.loanPurposeId;
    }
  };

  protected onChannelChange = async () => {
    let { channel, application } = this;
    if (channel == null || channel === application.channel) {
      return;
    }

    const result = await this.showChangeConfirmationDialogForProperty('Channel');

    if (result.isConfirmed) {
      application.channel = channel;
      this.onChannelSelect(channel);
    } else {
      this.channel = application.channel;
    }
  };

  onChannelSelect = (channel: string) => {
    if (channel == 'Retail' || channel == 'Broker') {
      this.externalCompanyBranchesFiltered = this.externalCompanyBranches.filter(x => !x.externalCompanyId);
    } else if (channel == 'Wholesale') {
      this.externalCompanyBranchesFiltered = this.externalCompanyBranches.filter(x => !!x.externalCompanyId);
    } else {
      this.externalCompanyBranchesFiltered = this.externalCompanyBranches;
    }

    this.externalCompanyBranchesFiltered = _.sortBy(this.externalCompanyBranchesFiltered, ["branchName"], ["asc"]);

    // sync mortgage and application
    this.mortgage.channel = channel;
  }

  onExternalCompanySelect = (externalCompanyId: number) => {
    if (!this.application.externalCompanyId) {
      this.externalCompanyBranchesFiltered = this.externalCompanyBranches.filter(x => !x.externalCompanyId);
      this.loanPurposesFiltered = this.loanPurposes;
      this.loanTypesFiltered = this.loanTypes;
    } else if (this.application.externalCompanyId > 0) {
      this.loanPurposesFiltered = this.loanPurposes;
      this.loanTypesFiltered = this.loanTypes;
      this.externalCompanyBranchesFiltered = this.externalCompanyBranches.filter(x => x.externalCompanyId == externalCompanyId);
    } else {
      this.loanPurposesFiltered = this.loanPurposes;
      this.loanTypesFiltered = this.loanTypes;
      this.externalCompanyBranchesFiltered = this.externalCompanyBranches;
    }
    this.externalCompanyBranchesFiltered = _.sortBy(this.externalCompanyBranchesFiltered, ["branchName"], ["asc"]);
  }

  onBorrowerDeleted = (loanBorrowers: Borrower[]) => {
    this.loanBorrowers = loanBorrowers;
    this._loanService.getApplicationModel(this.application.applicationId, true).subscribe(loanInfoModel => {
      this.applicationContextService.updateMortgageAndApplication(loanInfoModel.mortgageLoan, loanInfoModel, undefined, loanBorrowers);
      this._spinner.hide();
    }, (error) => {
      this._spinner.hide();
      this._notifyService.showError(
        error ? error.message : 'Unable to reload loan',
        'Error!'
      );
    });
  }

  onBorrowerUpdated = (loanBorrowers: Borrower[]) => {
    this.applicationContextService.updateBorrowers(loanBorrowers);
  }

  onUpdatedPrimaryBorrower = (data: { application: LoanApplication, borrowers: Borrower[] }) => {
    this.application = data.application;
    this.mortgage = data.application.mortgageLoan || this.mortgage; // compatibilty: admin is deployed before api

    this.applicationContextService.updateMortgageAndApplication(this.mortgage, this.application, this.customData, data.borrowers);
  }

  saveLoanInfo = async (): Promise<void> => {
    this.loanForm.form.markAllAsTouched();

    if (this.application.productPricing && this.application.productPricing.productId) {
      const product = this.products.find(el => el.productId.toString() == this.application.productPricing.productId);
      if (product) {
        this.application.productPricing.productName = product.productName;
      }
    }

    if (this.showPrequalDetailsSection) {
      await this._spinner.show();

      try {
        await firstValueFrom(
          this._prequalDetailService.postPrequalDetail(this.prequalDetail),
        );
      } catch (e) {
        await this._spinner.hide();
        this._notifyService.showError(
          e.message ?? 'Unable to save Prequal Detail',
          'Error',
        );
      }
    }

    const payLoad = new LoanDetailsInfo();
    payLoad.application = cloneDeep(this.application);
    payLoad.customData = cloneDeep(this.customData);
    payLoad.application.mortgageLoan = cloneDeep(this.mortgage);

    await this._spinner.show();

    try {
      const loanDetailsInfo = await firstValueFrom(
        this._appDetailsService.saveLoanInfo(this.application.applicationId, payLoad),
      );

      if (loanDetailsInfo == null) {
        await this._spinner.hide();
      } else {
        this.application = loanDetailsInfo.application;
        this.mortgage = loanDetailsInfo.application.mortgageLoan || this.mortgage; // compatibilty: admin is deployed before api

        this.applicationContextService.updateMortgageAndApplication(this.mortgage, this.application, this.customData);

        this._notifyService.showSuccess(
          'Your loan has been saved successfully.',
          'Success!',
        );

        if (this.isUsedAsChildComponent) {
          this.closeDrawer.emit();
        }
      }
    } catch (e) {
      this._notifyService.showError(
        e.message ?? 'Unable to save Loan Info',
        'Error!',
      );

      await this._spinner.hide();
    }
  };

  onAddHomeInsuranceDialog() {
    const modalRef = this._modalService.open(UpsertReferralSourceComponent, Constants.modalOptions.fullScreen);
    this.application.referralSource = null;
    this.realtorCompanyName = null;

    modalRef.result.then((newAgent: AgentFull) => {
      this.loadAgents(newAgent);
    }, () => {
    });
  }

  selectProduct(productId: string) {
    if (!productId) {
      return;
    }

    this._appDetailsService.getProductById(Number(productId)).subscribe((response) => {
      if (response.productId == Number(productId)) {
        this.application.productPricing.term = response.term;
        if (this.mortgage.mortgageTerm)
          this.mortgage.mortgageTerm.noOfMonths = response.term;
      } else {
        this.application.productPricing.term = null;
      }
    },
      (error) => {
        this._notifyService.showError(
          error ? error.message : 'Unable to get Product',
          'Error!'
        );
      });
  }

  onBaseLoanAmountChanged = () => {
    this.recalculateLtvAndRelatedValues();
  }

  onRequestedLtvChanged = () => {
    this.recalculateLtvAndRelatedValues(this.requestedLtv);
  }

  onPurchasePriceAmountChanged = () => {
    this.recalculateLtvAndRelatedValues();
  }

  onAppraisedPropertyValueChanged = () => {
    this.recalculateLtvAndRelatedValues();
  }

  onDownpaymentAmountChanged = () => {
    this.calculateBaseLoanAmount();
    this.recalculateLtvAndRelatedValues();
  }

  private recalculateLtvAndRelatedValues = (requestedLtv: number | null = null) => {

    const observer: Observer<MortgageCalculationDetails> = {
      next: (result: MortgageCalculationDetails) => {
        this.mortgage.mortgageTerm.amount = result.ltv.baseLoanAmount;
        this.mortgage.mortgageTerm.totalLoanAmount = result.ltv.totalLoanAmount;
        this.requestedLtv = result.ltv.ltv;
        this.mortgage.transactionDetail.cltv = result.ltv.cltv;

        this.mortgage.transactionDetail.subordinateLienAmount = result.ltv.subordinateLienAmount;

        this.ftcDetails = result.fundsToClose;

        this.downPayment = this._calculationService.calculateDownPayment(this.mortgage);

        this.calculateRelatedStatsAfterLoanAmountChange();
      },
      error: (err: any) => {
        this._spinner.hide();
        this._notifyService.showError(
          err?.message ?? 'Unable to calculate LTV and related values.',
          'Error!',
        );
      },
      complete: () => {
        this._spinner.hide();
      }
    }
    this._spinner.show();
    this._mortgageService.redoMortgageCalculationDetails(this.mortgage, requestedLtv).subscribe(observer);
  }

  copyFromLoanDetails = () => {
    const products = this.applicationContext.globalConfig.product;
    const application = this.applicationContext.application;

    if (application) {
      this.prequalDetail.maxPurchasePrice = application.purchasePrice;
      const minValue = Math.min(application.presentValue, application.purchasePrice);
      if (minValue > 0) {
        this.prequalDetail.downPaymentPercent = (application.downAmount / minValue) * 100;
      }
      this.prequalDetail.applicationId = this.application.applicationId;
      if (application.productPricing && products.length > 0) {
        let loanProgramName: string = "";
        let productDetail = products.find(p => p.productId.toString() == application.productPricing.productId);
        if (productDetail) {
          loanProgramName = productDetail.productName;
        }
        this.prequalDetail.interestRate = application.productPricing.rate;
        this.prequalDetail.loanProgramDescription = loanProgramName;
      }
    } else {
      this._notifyService.showError('Missing application or lender info', 'Notice');
    }
  }

  showLenderInfoDialog() {
    const modalRef = this._modalService.open(LenderInfoDialogComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.lenderInfo = this.lenders.find(x => x.lenderId == this.application.lenderId);
  }

  get allIncludedLiabilities() {
    if (!_.isArray(this.mortgage?.liabilities)) {
      return [];
    }
    return this.mortgage.liabilities.filter(l => l.isExcluded !== true && l.payoffType != 'Full');
  }

  private loadAllAgents = () => {
    this._agentService.getAllAgents()
      .subscribe({
        next: (agents: Agent[]) => {
          this._allAgents = agents;
          this.onReferralSourceChanged();
          this.onSecondaryReferralSourceChanged();
        },
        error: (err) => {
          this._notifyService.showError(err.error.message || err.error, "Fetching agents error");
        }
      })
  }

  private onAfterApplicationLoanPurposeChanged() {
    const isPurchase = this.allLoanPurposes.find(x => x.loanPurposeId === this.application.loanPurposeId)?.mortgageLoanPurpose == "Purchase";
    const isRefi = this.allLoanPurposes.find(x => x.loanPurposeId === this.application.loanPurposeId)?.mortgageLoanPurpose == "Refinance";
    this.showPrequalDetailsSection = isPurchase;
    this.showCurrentLoanInfoSection = isRefi;
  }

  private getUserFullName = (userCompanyGuid: string) => {
    if (userCompanyGuid) {
      let user = this.users.find(u => u.userCompanyGuid == userCompanyGuid);
      return user ? Utils.getPersonsDisplayName(user) : null;
    }
    return null;
  }

  private _isDirty = (): boolean => {
    const diffCheckers = [
      new DiffChecker(
        this._originalApplication,
        this.application,
        'application',
      ),
      new DiffChecker(
        this._originalMortgage,
        this.mortgage,
        'mortgage',
      ),
      new DiffChecker(
        this._originalCustomData,
        this.customData,
        'customData',
      ),
    ];

    return diffCheckers
      .map((e) => e.calculateDiff(true))
      .some(Boolean);
  }

  private loadLoanHiddenFields = async (): Promise<void> => {
    const response = await firstValueFrom(this._appDetailsService.getLoanHiddenFields());
    this.hiddenFields = this.getHiddenFields(response);

    this.isSubjectPropertyAddressHidden = this.hiddenFields.findIndex(f => f === "Subject Property") > -1;
    this.isAppraisedValueHidden = this.hiddenFields.findIndex(f => f === "Appraised Value") > -1;
    this.isEstimatedValueHidden = this.hiddenFields.findIndex(f => f === "Estimated Value") > -1;
  }

  private updateKeyDateBasedPricingFields = async (updateOnlyIfNotSet: boolean): Promise<void> => {
    const keyDatesByType = this.applicationContext.applicationKeyDatesByType;
    this.hasRateLockExpirationKeyDate =keyDatesByType ? !!keyDatesByType['rateLockExpiration'] : false;

    if (this.application.productPricing && keyDatesByType) {
      const needToUpdateRateLockDate = !updateOnlyIfNotSet || (updateOnlyIfNotSet && !this.application.productPricing.lockDate);
      if (needToUpdateRateLockDate && keyDatesByType['rateLock'] && keyDatesByType['rateLock'].eventDate) {
        this.application.productPricing.lockDate = Utils.formatISODateWithoutTime(keyDatesByType['rateLock'].eventDate);
      }

      const needToUpdateRateLockExpirationDate = !updateOnlyIfNotSet || (updateOnlyIfNotSet && !this.application.productPricing.lockExpirationDate);
      if (needToUpdateRateLockExpirationDate && keyDatesByType['rateLockExpiration'] && keyDatesByType['rateLockExpiration'].eventDate) {
        this.application.productPricing.lockExpirationDate = Utils.formatISODateWithoutTime(keyDatesByType['rateLockExpiration'].eventDate);
      }
      this.lockExpirationDateString = this.formatLockRelatedDate(this.application.productPricing.lockExpirationDate);
      this.lockDateString = this.formatLockRelatedDate(this.application.productPricing.lockDate);
    }
  }

  private loadLenders = async (): Promise<void> => {
    const lenders = await firstValueFrom(
      this._lenderService.getAllLenders(),
    );
    this.lenders = lenders.filter((e) => e.active)
      .sort((a, b) => a.sortOrder - b.sortOrder);
  };

  private formatLockRelatedDate = (dateString: string): string => {
    if (!dateString) {
      return null;
    }
    // Extract the time portion from the string
    const timePortion = dateString.substr(11, 8); // Extracts "15:30:00"

    // Check if the time portion is not equal to "00:00:00"
    const hasTimePortion = timePortion !== "00:00:00";

    let formattedDate = "";
    const date = new Date(dateString);
    if (!hasTimePortion) {
      formattedDate = Utils.formatDateWithoutTime(date);
    } else {
      formattedDate = this._datePipe.transform(date, 'short');
    }
    return formattedDate;
  }

  private isPurposeOfLoanRefinance = () => {
    return this.isLoanPurposeSet() && this.mortgage.subjectProperty.purposeOfLoan == this._loanPurposeRefiEnumValue;
  }

  private isPurposeOfLoanPurchase = () => {
    return this.isLoanPurposeSet() && this.mortgage.subjectProperty.purposeOfLoan == this._loanPurposePurchaseEnumValue;
  }

  private isLoanPurposeSet = () => {
    return this.mortgage.subjectProperty && this.mortgage.subjectProperty.purposeOfLoan;
  }

  private getHiddenFields = (loanHiddenFields?: Configuration): Array<string> => {
    if (!loanHiddenFields?.valueStr) return [];
    return loanHiddenFields.valueStr?.split(",").map(el => el.trim());
  }

  private calculateBaseLoanAmount = () => {
    let homeValue = this._calculationService.calculateHomeValue(this.mortgage);
    this.mortgage.mortgageTerm.amount = homeValue - this.downPayment;
  }

  private getPrequalDetail = async (): Promise<void> => {
    try {
      const result = await firstValueFrom(
        this._prequalDetailService.getPrequalDetail(this.application.applicationId)
      );
      if (result != null) {
        if (result.applicationId == 0) {
          result.applicationId = this.application.applicationId;
          this.prequalDetail = result;
        } else {
          this.prequalDetail = result;
        }

      } else {
        let newPrequalDetail = new PrequalDetail();
        newPrequalDetail.applicationId = this.application.applicationId;
        newPrequalDetail.companyId = this.application.companyId;

        this.prequalDetail = newPrequalDetail;
      }

    } catch (err) {
      this._notifyService.showError(err.message || err, 'Error!');
    }
  }

  private resetValuesForNotPurchase = () => {
    if (this.mortgage.transactionDetail) {
      this.mortgage.transactionDetail.purchasePriceAmount = null;
      this.mortgage.transactionDetail.sellerPaidClosingCostsAmount = null;
    }
  }

  private resetValuesForNotRefinance = () => {
    this.mortgage.subjectProperty.originalCostYear = null;
    this.mortgage.subjectProperty.originalCost = null;
    this.mortgage.subjectProperty.amountExistingLiens = null;
    this.mortgage.subjectProperty.improvementCost = null;
    this.mortgage.subjectProperty.refiPurpose = null;
    this.mortgage.subjectProperty.describeImprovement = null;
    this.mortgage.subjectProperty.improvementStatus = null;
    this.mortgage.calculatedStats.totalPaidOffForRefinance = 0;
  }

  private getAllFannieMaeApprovalDocs = async () => {
    try {
      const result = await firstValueFrom(
        this._loanService.getAllFannieMaeApprovalDocs(this.application.applicationId)
      );

      this._duHistoryDocuments = result.sort((a, b) => new Date(b.dateInserted).getTime() - new Date(a.dateInserted).getTime()); // desc sorting;

    } catch (err) {
      this._notifyService.showError(err.message || err, 'Error!');
    }
  }

  private getAllFreddieMacApprovalDocs = async () => {
    try {
      const result = await firstValueFrom(
        this._loanService.getAllFreddieMacApprovalDocs(this.application.applicationId)
      );

      this._lpaHistoryDocuments = result.sort((a, b) => new Date(b.dateInserted).getTime() - new Date(a.dateInserted).getTime()); // desc sorting;

    } catch (err) {
      this._notifyService.showError(err.message || err, 'Error!');
    }
  }

  private getAllProofOfFundsDocs = async () => {
    try {
      const result = await firstValueFrom(
        this._loanService.getAllProofOfFundsDocs(this.application.applicationId)
      );

      result.forEach(doc => {
        doc['lsLpaDocName'] = doc.fileName + ' - ' + this._datePipe.transform(doc.dateInserted, 'MM/dd/yyyy h:mma');
      })

      this.proofOfFundsDocFiles = result.sort((a, b) => new Date(b.dateInserted).getTime() - new Date(a.dateInserted).getTime());

    } catch (err) {
      this._notifyService.showError(err.message || err, 'Error!');
    }
  }

  private async recalculateFirstMortgagePAndI(): Promise<void> {
    await this._spinner.show();

    try {
      const calculationDetails = await firstValueFrom(
        this._mortgageService.redoMortgageCalculationDetails(
          this.mortgage
        )
      );
      this.mortgage.proposedHousingExpense.firstMortgagePrincipalAndInterest =
        calculationDetails.proposedExpenses.firstMortgagePrincipalAndInterest;

      this.mortgage.proposedHousingExpense.otherMortgageLoanPrincipalAndInterest =
        calculationDetails.proposedExpenses.otherMortgageLoanPrincipalAndInterest;

      this.mortgage.calculatedStats.proposedMonthlyPaymentTotal = this._calculationService.calculateHousingExpenseTotal(this.mortgage.proposedHousingExpense);

    } catch (e) {
      this._notifyService.showError(
        e?.message || 'Unable to recalculate LTV and related values',
        'Error!'
      );
    } finally {
      await this._spinner.hide();
    }
  }
}
