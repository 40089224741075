<div *ngIf="fullBorrower; else loading">
  <form id="borrowerDetailsForm" #borrowerDetailsForm="ngForm" name="borrowerDetailsForm">
    <div class="card">
      <div class="card-header d-flex justify-content-between card-header-with-button">
        <h4 class="card-title">Basic Information</h4>
        <enable-sms-alerts-toggle [type]="'Borrower'" [phone]="fullBorrower.borrower.mobilePhone"
          [enableSms]="fullBorrower.borrower.alertViaSms"
          (enableSmsToggled)="onEnableSmsToggled($event)"></enable-sms-alerts-toggle>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="firstName">First Name</label>
              <div class="col-md-9">
                <input class="form-control" name="firstName" [(ngModel)]="fullBorrower.borrower.firstName"
                  #firstNameField="ngModel" maxlength="40" [ngClass]="{
                    'is-invalid':
                      firstNameField &&
                      firstNameField.touched &&
                      firstNameField.invalid
                  }" required />
                <div class="invalid-feedback" for="firstName">This field is required</div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="middleName">Middle Name</label>
              <div class="col-md-9">
                <input class="form-control" name="middleName" [(ngModel)]="fullBorrower.borrower.middleName"
                  maxlength="40" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="lastName">Last Name</label>
              <div class="col-md-9">
                <input class="form-control" name="lastName" [(ngModel)]="fullBorrower.borrower.lastName"
                  #lastNameField="ngModel" maxlength="40" [ngClass]="{
                    'is-invalid':
                      lastNameField &&
                      lastNameField.touched &&
                      lastNameField.invalid
                  }" required />
                <div class="invalid-feedback" for="lastName">This field is required</div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="email">Email</label>
              <div class="col-md-9">
                <div class="d-flex">
                  <input class="form-control" name="email" type="email" [(ngModel)]="fullBorrower.borrower.email"
                    #emailField="ngModel"
                    [ngClass]="{'is-invalid': emailField && emailField.touched && emailField.invalid}" trim email />

                  <a class="btn btn-outline-secondary" style="margin-left: 3px;"
                    [href]="'mailto:' + fullBorrower.borrower.email | safeUrl">
                    <i class="float-end far fa-envelope fa-email-style"></i>
                  </a>

                </div>

                <div class="invalid-feedback d-block"
                  *ngIf="emailField && emailField.touched && emailField.errors && emailField.errors.email">
                  Please enter a valid email address
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="birthDate">Date of Birth</label>
              <div class="col-md-9">
                <date-input [name]="'birthDate'" [id]="'birthDate'" [max]="maxDate" [min]="minDate"
                  [(ngModel)]="fullBorrower.borrower.birthDate">
                </date-input>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="socialSecNum">SSN</label>
              <div class="col-md-9">
                <input class="form-control" name="socialSecNum" mask="000-00-0000" placeHolderCharacter="_"
                  [showMaskTyped]="true" [(ngModel)]="fullBorrower.borrower.socialSecNum" [conceal]='true' />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-sm-1 col-md-3 col-form-label">Mobile Phone
              </label>
              <div class="col-sm-11 col-md-9">
                <div class="d-flex">
                  <input class="form-control flex-grow-1" placeholder="mobilePhone" [mask]="'(000) 000-0000'"
                    [showMaskTyped]="true" name="mobilePhone" [(ngModel)]="fullBorrower.borrower.mobilePhone" />

                  <a href="tel:{{ fullBorrower.borrower.mobilePhone }}" class="btn btn-outline-secondary"
                    style="margin-left: 3px;" *ngIf="!dialerEnabled">
                    <i class="float-end fas fa-phone fa-phone-style"></i>
                  </a>

                  <a class="btn btn-outline-secondary" style="margin-left: 3px;" *ngIf="dialerEnabled"
                    (click)="dial(fullBorrower.borrower.mobilePhone, 'mobile')">
                    <i class="float-end fas fa-phone fa-phone-style"></i>
                  </a>

                  <button class="btn btn-outline-secondary" style="margin-left: 3px;"
                    *ngIf="applicationContext.userPermissions.smsAlertsEnabled"
                    (click)="onOpenSmsChat(fullBorrower.borrower, fullBorrower.borrower.mobilePhone)">
                    <i class="float-end far fa-comment-dots fa-comment-style"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-sm-1 col-md-3 col-form-label">Home Phone

              </label>
              <div class="col-sm-11 col-md-9">
                <div class="d-flex">
                  <input class="form-control flex-grow-1" placeholder="homePhone" [mask]="'(000) 000-0000'"
                    [showMaskTyped]="true" name="homePhone" [(ngModel)]="fullBorrower.borrower.homePhone" />
                  <a href="tel:{{ fullBorrower.borrower.homePhone }}" class="btn btn-outline-secondary"
                    style="margin-left: 3px;" *ngIf="!dialerEnabled">
                    <i class="float-end fas fa-phone fa-phone-style"></i>
                  </a>

                  <a class="btn btn-outline-secondary" style="margin-left: 3px;" *ngIf="dialerEnabled"
                    (click)="dial(fullBorrower.borrower.homePhone, 'home')">
                    <i class="float-end fas fa-phone fa-phone-style"></i>
                  </a>

                  <button class="btn btn-outline-secondary" style="margin-left: 3px;"
                    *ngIf="applicationContext.userPermissions.smsAlertsEnabled"
                    (click)="onOpenSmsChat(fullBorrower.borrower, fullBorrower.borrower.homePhone)">
                    <i class="float-end far fa-comment-dots fa-comment-style"></i>
                  </button>
                </div>

              </div>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-sm-1 col-md-3 col-form-label">Work Phone</label>
              <div class="col-sm-11 col-md-9">
                <div class="d-flex">
                  <input class="form-control flex-grow-1" placeholder="workPhone" [mask]="'(000) 000-0000'"
                    [showMaskTyped]="true" name="workPhone" [(ngModel)]="fullBorrower.borrower.workPhone" />
                  <a href="tel:{{ fullBorrower.borrower.workPhone }}" class="btn btn-outline-secondary"
                    style="margin-left: 3px;" *ngIf="!dialerEnabled">
                    <i class="float-end fas fa-phone fa-phone-style"></i>
                  </a>

                  <a class="btn btn-outline-secondary" style="margin-left: 3px;" *ngIf="dialerEnabled"
                    (click)="dial(fullBorrower.borrower.workPhone, 'work')">
                    <i class="float-end fas fa-phone fa-phone-style"></i>
                  </a>

                  <button class="btn btn-outline-secondary" style="margin-left: 3px;"
                    *ngIf="applicationContext.userPermissions.smsAlertsEnabled"
                    (click)="onOpenSmsChat(fullBorrower.borrower, fullBorrower.borrower.workPhone)">
                    <i class="float-end far fa-comment-dots fa-comment-style"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="!isMortgage">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="borrowerContact">Borrower Contact</label>
              <div class="col-md-9">
                <select class="form-select" id="borrowerContact" name="borrowerContact" [name]="'borrowerContact'"
                  #borrowerContactField="ngModel" [(ngModel)]="fullBorrower.borrower.borrowerContact">
                  <option [ngValue]="null">--Select One--</option>
                  <optgroup *ngFor="let role of roles" [label]="role.roleName">
                    <ng-container *ngFor="let user of usersByRole(role.roleId)">
                      <option *ngIf="user.roleId == role.roleId" [ngValue]="user.userCompanyGuid">
                        {{ user.lastName + ', ' + user.firstName }}
                      </option>
                    </ng-container>
                  </optgroup>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-sm-1 col-md-3 col-form-label" for="portalUserName">Portal Username</label>
              <div class="col-sm-11 col-md-9">
                <div class="d-flex align-items-baseline">
                  <input class="form-control flex-grow-1" name="portalUserName" disabled
                    [(ngModel)]="fullBorrower.portalUserName" />
                  <button class="btn btn-primary flex-shrink-0" style="margin-left: 3px;"
                    (click)="portalUserNameChange()"
                    [disabled]="fullBorrower.portalUserName == fullBorrower.borrower.email" title="Copy from E-Mail">
                    Copy from Email
                  </button>
                  <button class="btn btn-primary" style="margin-left: 3px;"
                    (click)="resendInviteToBorrower(fullBorrower.borrower.borrowerId)"
                    [disabled]="fullBorrower.accountStatus != 'Invited' || currentPortalUserName != fullBorrower.portalUserName"
                    title="Resend Invite">
                    <i *ngIf="!isSendingInvite" class="far fa-envelope"></i>

                    <span *ngIf="isSendingInvite">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="!isMortgage">
          <div class="col-md-6 mb-3">

          </div>
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="portalUserName">Portal Status</label>
              <div class="col-md-9 mt-2 fw-bold">
                <portal-status-indicator [borrower]="borrowerForPortalStatus"></portal-status-indicator>
                <p class=" col-md-3 col-form-label label-success" style="font-weight: bolder; text-align: left"
                  *ngIf="fullBorrower.portalUserName && currentPortalUserName != fullBorrower.portalUserName">
                  Save Change Before Sending Invite
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="!isMortgage">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label"></label>
              <div class="col-md-9">
                <div class="form-check">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" name="doNotEmail"
                      [(ngModel)]="fullBorrower.borrower.doNotEmail">
                    Do Not Email (restricts campaign emails)</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label">Credit Monitoring Status</label>
              <div class="col-md-9">
                <button class="btn btn-primary" *ngIf="!creditEnabled" disabled>
                  Call to enable credit monitoring
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Mailing Address</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="country">Country</label>
              <div class="col-md-9">
                <select class="form-select" id="countryField" name="countryField" [name]="'countryField'"
                  #countryField="ngModel" [(ngModel)]="fullBorrower.borrower.mailingCountry" (ngModelChange)="onCountryChanged()"
                  [ngClass]="{'is-invalid' : countryField && countryField.touched && countryField.invalid}" required>
                  <option [ngValue]="null"> -- Select One -- </option>
                  <option *ngFor="let opt of countries" [ngValue]="opt.value">
                    {{ opt.name }}
                  </option>
                </select>
                <div class="invalid-feedback">This field is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="mailingStreet">Address</label>
              <div class="col-md-9">
                <address-autocomplete-input *ngIf="fullBorrower.borrower.mailingCountry == 'us'"
                  #address='ngModel'
                  (addressChange)='handleAddressChange($event)'
                  [(ngModel)]='fullBorrower.borrower.mailingStreet'
                  name='mailingStreet'
                  placeholder='Number and Street'
                ></address-autocomplete-input>
                <input *ngIf="fullBorrower.borrower.mailingCountry != 'us'" 
                class="form-control" 
                name="mailingStreet" 
                [(ngModel)]="fullBorrower.borrower.mailingStreet" />
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="mailingCity">City</label>
              <div class="col-md-9">
                <input class="form-control" name="mailingCity" [(ngModel)]="fullBorrower.borrower.mailingCity" />
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="fullBorrower.borrower.mailingCountry == 'us'">
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="mailingCity">State</label>
              <div class="col-md-9">
                <select class="form-select" id="stateField" name="stateField" [name]="'stateField'"
                  #stateField="ngModel" [(ngModel)]="fullBorrower.borrower.mailingState">
                  <option [ngValue]="null">-- Select One -- </option>
                  <option *ngFor="let state of states" [ngValue]="state.code">
                    {{ state.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <div class="row">
              <label class="col-md-3 col-form-label" for="mailingZip">ZIP Code</label>
              <div class="col-md-9">
                <zip-code-input [(ngModel)]="fullBorrower.borrower.mailingZip" [name]="'mailingZip'"
                  (selectionChanged)="onZipCodeRelatedInfoChanged($event, 'mailing')">
                </zip-code-input>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </form>
  <div class="d-flex justify-content-end">

    <button type="button" *ngIf="hasCancelButton" class="btn btn-secondary ms-2" (click)="cancel()">Cancel</button>
    <button type="button" class="btn btn-primary ms-2" [disabled]="isSaving" (click)="save()">
      <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
      <span *ngIf="isSaving">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </span>
    </button>
    <button type="button" *ngIf="hasSaveAndCloseButton" class="btn btn-primary ms-2" [disabled]="isSaving"
      (click)="save(true)">
      <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save and Close</span>
      <span *ngIf="isSaving">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </span>
    </button>
  </div>
</div>
<ng-template #loading>
  <loading-indicator [loadingMessage]="'Loading borrower...'"></loading-indicator>
</ng-template>
