import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observer } from 'firebase/messaging';
import { cloneDeep } from 'lodash';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom, Subscription } from 'rxjs';
import { Utils } from 'src/app/core/services/utils';
import { ApplicationContext, Borrower, LoanApplication, MortgageBorrower } from 'src/app/models';
import { AuthenticationResponse } from 'src/app/models/auth/authentication-response.model';
import { BorrowerEditorComponent } from 'src/app/modules/borrower/components/borrower-editor/borrower-editor.component';
import { AuthService } from 'src/app/services/auth.service';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import { DrawerOptions, DrawerService, DrawerSize, DynamicComponentInfo } from 'src/app/shared/services/drawer.service';
import { getErrorMessageOrDefault } from 'src/app/shared/utils/error-utils';
import Swal from 'sweetalert2';
import { AppDetailsService } from '../../../services/app-details.service';

declare const browser: any;

@Component({
  selector: 'loan-borrowers',
  templateUrl: './loan-borrowers.component.html',
  styleUrls: ['./loan-borrowers.component.scss']
})
export class LoanBorrowersComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {

  @ViewChild('editAppBorrowerDrawerContentRef')
  borrowerEditorDrawer: DrawerComponent;

  @ViewChild('popoverBorrowerInfo', { static: false }) popoverBorrowerInfo: PopoverDirective;

  @Input()
  actionButtonsVisible: boolean = false;

  @Input()
  title = "Borrowers";

  @Input()
  isLoanReadOnly: boolean = false;

  private _borrowers: Borrower[] = [];

  @Input() set borrowers(value: Borrower[]) {
    this._borrowers = value;
    this.canDeleteBorrower = value.length > 1;
    
    this._borrowers.forEach(b => {
      b["creditScore_New"] = b.creditScore;
      b["creditScore_Experian_New"] = b.creditScore_Experian;
      b["creditScore_TransUnion_New"] = b.creditScore_TransUnion;
      b["creditScore_Equifax_New"] = b.creditScore_Equifax;
    });
  }

  get borrowers(): Borrower[] {
    return this._borrowers;
  }

  @Output()
  deleted: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  updated: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  updatedPrimaryBorrower: EventEmitter<{ application: LoanApplication, borrowers: Borrower[] }> = new EventEmitter<any>();

  editAppBorrowerDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXLarge,
    containerWrapperId: null
  }

  applicationMode: string;

  portalMergeHover: number = -1;

  dialerEnabled: boolean = false;

  mortgageBorrowers: MortgageBorrower[] = [];

  protected applicationId: number;
  /**
   * Whether the user can delete a borrower.
   * There must be at least two borrowers to delete one.
   */
  protected canDeleteBorrower: boolean = false;
  protected disableBorrowerPortalInviteLink: boolean = false;

  private _borrowerSavedEventSubscription: Subscription;
  private _borrowerEditCancelledEventSubscription: Subscription;

  constructor(
    private readonly _notifyService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _drawerService: DrawerService,
    private readonly _navigationService: NavigationService,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _authService: AuthService,
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.applicationMode = this._navigationService.applicationMode == ApplicationMode.Classic ? 'admin' :
      this._navigationService.applicationMode == ApplicationMode.NextGen ? 'loda-nextgen' : 'admin';

    if (this.applicationContext.application) {
      this.initialize(this.applicationContext);
    }
    this.applicationContextService.loanInfoChanges.subscribe(applicationContext => {
      if (applicationContext.application) {
        this.initialize(applicationContext);
      }
    })
  }

  ngOnDestroy(): void {
    if (this._borrowerSavedEventSubscription) {
      this._borrowerSavedEventSubscription.unsubscribe();
    }
    if (this._borrowerEditCancelledEventSubscription) {
      this._borrowerEditCancelledEventSubscription.unsubscribe();
    }
  }

  getBorrowerFullName = (borrower: Borrower): string => {
    return Utils.getBorrowerFullName(borrower);
  }

  protected onOpenInBorrowerPortalClicked(borrowerId: number) {
    let portalUrl = "";
    const company = this.applicationContext.globalConfig.company.find(c => c.companyId ==
      this.applicationContext.application.companyId);
    if (company && company.borrowerURL) {
      const parts = company.borrowerURL.split('|');
      if (parts.length >= 1) {
        let host = parts[0];
        portalUrl = `${window.location.protocol}//${host}/open-from-admin`;
      } else {
        this._notifyService.showWarning('Borrower portal URL is not configured for this company.', 'Warning!');
        return;
      }
    } else {
      this._notifyService.showWarning('Borrower portal URL is not configured for this company.', 'Warning!');
      return;
    }

    const observer: Observer<AuthenticationResponse> = {
      next: (authResponse: AuthenticationResponse) => {
        portalUrl = portalUrl + "?token=" + authResponse.jwtToken;
        window.open(portalUrl, '_blank');
      },
      error: (error: Error) => {
        this._notifyService.showError(
          getErrorMessageOrDefault(error, {
            defaultMessage: 'Error getting the auth token for borrower.',
          }),
          'Error!'
        );
      },
      complete: () => void {
      }
    }

    this._spinner.show();
    this._authService.exchangeReadOnlyTokenForBorrower(borrowerId).subscribe(observer)
      .add(() => {
        this._spinner.hide();
      });
  }

  openBorrowerDetails = (borrowerId: number) => {
    if (!this.applicationId) {
      return;
    }
    const selectedBorrower = this._borrowers.find(b => b.borrowerId == borrowerId);
    const dynamicComponentInfo = new DynamicComponentInfo();
    dynamicComponentInfo.componentType = BorrowerEditorComponent;
    dynamicComponentInfo.parameters.set("borrowerId", borrowerId);
    dynamicComponentInfo.parameters.set("loanId", this.applicationId);
    this._drawerService.show("editAppBorrowerDrawer", 100, 'Editing Borrower - ' +
      (selectedBorrower
        ? this.getBorrowerFullName(selectedBorrower)
        : ''), dynamicComponentInfo)
      .then(() => {
        this._borrowerSavedEventSubscription = this.borrowerEditorDrawer.componentInstance.borrowerSaved.subscribe((updatedBorrower) => {
          this.onBorrowerSaved(updatedBorrower);
        });
        this._borrowerEditCancelledEventSubscription = this.borrowerEditorDrawer.componentInstance.borrowerEditCancelled.subscribe(() => {
          this.onBorrowerEditCancelled();
        });
      });
  }

  onBorrowerEditCancelled = () => {
    this._drawerService.hide("editAppBorrowerDrawer", 10);
  }

  onBorrowerSaved = (updatedBorrower: Borrower) => {
    const updatedBorrowerIndex = this._borrowers.findIndex(b => b.borrowerId === updatedBorrower.borrowerId);
    if (updatedBorrowerIndex >= 0) {
      this._borrowers[updatedBorrowerIndex] = {
        ...this._borrowers[updatedBorrowerIndex],
        ...updatedBorrower
      };
    }
    this.updated.emit(this._borrowers);
    this._drawerService.hide("editAppBorrowerDrawer", 10);
  }

  onBorrowerEditorClosed = () => {
    if (this._borrowerSavedEventSubscription) {
      this._borrowerSavedEventSubscription.unsubscribe();
    }
    if (this._borrowerEditCancelledEventSubscription) {
      this._borrowerEditCancelledEventSubscription.unsubscribe();
    }
  }

  mergePortal = (borrowerId: number, portalMerged: boolean) => {
    this._spinner.show();
    this._appDetailsService.setAllowPrimaryToSatisfy(this.applicationId, borrowerId, portalMerged).subscribe(result => {
      let borrower = this._borrowers.find(b => b.borrowerId === borrowerId);
      if (borrower) {
        borrower.isPortalMerged = portalMerged;
      }
      this.updated.emit(this._borrowers);
      this._notifyService.showSuccess(
        'Borrower merged succesfully.',
        'Success!'
      );
    }, (error) => {
      this._notifyService.showError(
        error ? error.message : 'An error occurred merging the borrower.',
        'Error!'
      );
    }).add(() => {
      this._spinner.hide();
    });
  }

  setBorrowerAsPrimary = (borrowerId: number) => {
    this._spinner.show();
    this._appDetailsService.setBorrowerPrimaryLoan(this.applicationId, borrowerId).subscribe(result => {
      this._borrowers.forEach(b => {
        b.isPrimary = (b.borrowerId == borrowerId);
      });
      this._borrowers.sort((a, b) => {
        if (a.isPrimary) {
          return -1;
        }
        if (b.isPrimary) {
          return 1;
        }
      });
      this.updatedPrimaryBorrower.emit({ application: result, borrowers: this._borrowers });
      this._spinner.hide();
      this._notifyService.showSuccess(
        'Borrower is set as primary.',
        'Success!'
      );
    },
      (error) => {
        this._spinner.hide();
        this._notifyService.showError(
          error ? error.message : 'Unable to set loan as Primary',
          'Error!'
        );
      });
  }

  protected onDeleteBorrowerClicked(index: number) {
    return this.showDeleteBorrowerConfirmation(index);
  }

  private async showDeleteBorrowerConfirmation(borrowerIndex: number) {
    // "Cancel" is used as "confirm" here because the colors are more suitable.

    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this borrower?',
      showCancelButton: true,
      cancelButtonText: 'Delete',
      confirmButtonText: 'Cancel',
      focusCancel: true, // "Confirm", actually.
      icon: 'question',
    });

    // "Cancel" actually means "confirm" here.
    if (result?.dismiss !== Swal.DismissReason.cancel) {
      return;
    }

    const borrower = this._borrowers[borrowerIndex];

    await this._spinner.show();

    try {
      await firstValueFrom(
        this._appDetailsService.removeBorrower(
          this.applicationId,
          borrower.borrowerId,
        ),
      );

      this._borrowers.splice(borrowerIndex, 1);
      this.borrowers = this._borrowers; // Update canDeleteBorrower.
      this.deleted.emit(this._borrowers);
    } catch (e) {
      const message = e?.message
        || `An error occurred deleting the borrower ${borrower.borrowerId}.`;
      console.error(message, e);

      this._notifyService.showError(message, 'Error!');
    } finally {
      await this._spinner.hide();
    }
  }

  private initialize = (applicationContext: ApplicationContext) => {
    if (applicationContext.application) {
      this.applicationId = applicationContext.application.applicationId;
      this.mortgageBorrowers = applicationContext.currentMortgage.borrowers;
      this.borrowers = cloneDeep(this.applicationContext.borrowers);
      if (this.applicationContext.globalConfig.disabledBorrowerInviteChannels.length && this.applicationContext.globalConfig.disabledBorrowerInviteChannels.includes(this.applicationContext.application.channel)) {
        this.disableBorrowerPortalInviteLink = true;
      }
      this.setBorrowerCreditScoreFromMortgageBorrower();
    }
    this.dialerEnabled = applicationContext.userPermissions.dialerEnabled;
  }

  protected setBorrowerCreditScoreFromMortgageBorrower = () => {
    this.borrowers.forEach(b => {
      const matched = this.mortgageBorrowers.find(mb => mb.contactId == b.borrowerId);
      if(matched){
        b["creditScore_New"] = matched.creditScore;
        b["creditScore_Experian_New"] = matched.creditScore_Experian;
        b["creditScore_TransUnion_New"] = matched.creditScore_TransUnion;
        b["creditScore_Equifax_New"] = matched.creditScore_Equifax;
      }
    })
  }

  protected getAddress1String(borrower: Borrower): string | undefined {
    return borrower.mailingStreet || undefined;
  }

  protected getAddress2String(borrower: Borrower): string | undefined {
    const city = borrower.mailingCity;

    const state = borrower.mailingState?.toUpperCase();
    const zip = borrower.mailingZip;
    const stateZip = [state, zip].filter(Boolean).join(' ') || undefined;

    return [city, stateZip].filter(Boolean).join(', ') || undefined;
  }
}
