import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep, uniqBy } from 'lodash';
import { combineLatest } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { ChannelService } from 'src/app/services/channel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { FilterOption } from 'src/app/shared/components/generic-filter/generic-filter.component';
import { instanceOfA } from 'src/utils';
import { LoanStatusAssociation, LoanStatusFlow } from '../../../models';
import { LoanStatusFlowService } from '../../../services';
import { LoanStatus } from 'src/app/models';

@Component({
  templateUrl: 'loan-status-flow.component.html',
  styleUrls: ['loan-status-flow.component.scss'],
})
export class LoanStatusFlowComponent extends ApplicationContextBoundComponent implements OnInit {

  loading: boolean;
  saving: boolean;
  areChannelsEnabled: boolean;

  availableLoanStatuses: Array<LoanStatus> = [];
  filteredLoanStatuses: Array<LoanStatus> = [];
  loanStatusAssociated: Array<LoanStatusAssociation> = [];
  sortableLoanStatusAssociations: Array<LoanStatusFlow> = [];
  selectedChannel: string;
  loanStatusesOptions: any = {
    group: {
      name: 'chart',
      pull: 'clone',
      put: false,
    },
    animation: 150,
    fallbackTolerance: 3,
    sort: false,
    onEnd: (evt) => {
      if (evt.from.id === evt.to.id) {
        return;
      }
      const parentStatusId = this.getStatusIdFromClass(evt.to.id);
      if (!this.isDropValid(evt.item.id, parentStatusId)) {
        this.removeInvalidItemAfterDrop(evt.newIndex, parentStatusId);
      }
      this.onStatusMoved();
    },
  };

  enabledChannels: EnumerationItem[] = [];
  channelsForFiltering: FilterOption[] = [];

  loanStatusAssociatedSortableOptions: any = {
    group: {
      name: 'chart',
      pull: false,
      put: false
    },
    animation: 150,
    fallbackOnBody: true,
    invertSwap: true,
    emptyInsertThreshold: 20,
    sort: true,
    onEnd: (evt) => {
      if (evt.from.id === evt.to.id && evt.newIndex === evt.oldIndex) {
        return;
      }
      this.onStatusMoved();
    },
  };

  loanPurposeName: string;

  private _companyId: number;
  private _loanPurposeId: number;
  private _statusType: string;

  constructor(
    private readonly injector: Injector,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _loanStatusFlowService: LoanStatusFlowService,
    private readonly _router: Router,
    private readonly _notificationService: NotificationService,
    private readonly _channelService: ChannelService,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _localStorageService: LocalStorageService
  ) {
    super(injector);

    this._activatedRoute.params.subscribe((params) => {
      this._loanPurposeId = parseInt(params['loanPurposeId']);
      this.loanPurposeName = params['loanPurposeName'];

      const loanPurposes = this.applicationContext.globalConfig.loanPurpose;
      const matched = loanPurposes.find(lp => lp.loanPurposeId == this._loanPurposeId);

      this.enabledChannels = this._channelService.getChannelsFromCommaDelimitedString(matched.enabledChannels);
      this.channelsForFiltering = this.enabledChannels.map(b => ({
        displayName: b.name,
        value: b.value,
        groupName: "",
        isSelected: this.enabledChannels?.length === 1,
      }));

      const selectedChannels = this._localStorageService.getItem("loanPurposeChannel") as string;
      if (selectedChannels && selectedChannels !== "All") {
        this.channelsForFiltering.forEach(channel => {
          channel.isSelected = channel.value == selectedChannels;
        });
      }
    });
    this._activatedRoute.queryParams.subscribe((params) => {
      this._statusType = params['statusType'];
    });
    this._companyId = this.applicationContext.userPermissions.companyId;
  }

  ngOnInit(): void {
    this.getData();

    this._applicationContextService.context.subscribe((context) => {
      this.areChannelsEnabled = context.globalConfig.enabledChannels.length > 0;
    });
  }

  removeFromTree({ statusId, parentStatusId }) {
    if (parentStatusId) {
      const association = this.sortableLoanStatusAssociations.find(
        (a) => a.statusId === parentStatusId
      );
      const index = association.subordinates.findIndex(
        (a) => a.statusId === statusId
      );
      association.subordinates.splice(index, 1);
    } else {
      const index = this.sortableLoanStatusAssociations.findIndex(
        (a) => a.statusId === statusId
      );
      this.sortableLoanStatusAssociations.splice(index, 1);
    }
  }

  save() {
    this.saving = true;

    const combined = combineLatest(
      this.sortableLoanStatusAssociations.map((association) => {
        return this._loanStatusFlowService.updateLoanStatusFlowTree(
          this._statusType,
          this._loanPurposeId,
          association.statusId,
          association.subordinates.map((sub) => sub.statusId).join(','),
          this.selectedChannel
        );
      })
    );

    combined.subscribe({
        next: (res) => {
          this._notificationService.showSuccess(
            "Loan status flow Updated Successfully",
            'Loan Status Flow'
          );
          this.goBack();
        },
        error: ({ error }) => {
          this._notificationService.showError(
            error || "Couldn't update loan status flow",
            'Loan Status Flow'
          );
        }
      }).add(() => this.saving = false);
  }

  cancel() {
    this.goBack();
  }

  onChannelFilterChanged = () => {
    this.selectedChannel =this.channelsForFiltering?.find(c => c.isSelected)?.value;
    this.filterLoanStatuses();
    if (!this.selectedChannel) {
      this.mapLoanStatusAssociation([]);
      return;
    }
    const filteredStatuses = this.loanStatusAssociated.filter(a => a.channel == this.selectedChannel);
    this.mapLoanStatusAssociation(filteredStatuses);
  }

  private getData() {
    this.loading = true;
    const combined = combineLatest([
      this._loanStatusFlowService.getAllLoanStatuses(
        this._statusType,
        this._companyId
      ),
      this._loanStatusFlowService.getAllLoanStatusAssociations(
        this._statusType,
        this._loanPurposeId,
        this._companyId
      ),
    ]);

    combined.subscribe({
        next: ([loanStatuses, loanStatusAssociations]) => {
          this.loanStatusAssociated = loanStatusAssociations;
          this.availableLoanStatuses = loanStatuses; // uniqBy(loanStatusAssociations, "loanStatusId");

          this.availableLoanStatuses.forEach(association => {
            const ls = loanStatuses.find(ls => ls.loanStatusId == association.loanStatusId);
            association['loanStatusName'] = ls?.loanStatusName || '';
          })

          if (!this.areChannelsEnabled) {
            this.filteredLoanStatuses = cloneDeep(this.availableLoanStatuses);
            this.mapLoanStatusAssociation(this.loanStatusAssociated);
            return;
          }
          this.onChannelFilterChanged();
        },
        error: ({ error }) => {
          this._notificationService.showError(
            error || "Couldn't load loan statuses flow",
            'Loan Status Flow'
          );
        }
      }).add(() => this.loading = false);
  }

  private filterLoanStatuses() {
    if (!this.selectedChannel) {
      this.filteredLoanStatuses = [];
      return;
    }
    this.filteredLoanStatuses = this.availableLoanStatuses.filter(ls => {
      let enabledChannels = [];
      if (ls.enabledChannels) {
        const allSpacesRemoved = ls.enabledChannels.replace(/ /g, '');
        if (allSpacesRemoved) {
          enabledChannels = allSpacesRemoved.split(',');
        }
      }
      if (enabledChannels && enabledChannels.length) {
        return enabledChannels.includes(this.selectedChannel);
      }
      return false;
    });
  }

  private prepareStatusToDisplay<T>(status: T): LoanStatusFlow {
    const result: LoanStatusFlow = new LoanStatusFlow();

    if (instanceOfA<LoanStatusFlow>(status, 'statusId')) return status;

    if (status['loanStatusName']) {
      result.displayName = status['loanStatusName'];
    }
    if (status['loanStatusId']) {
      result.statusId = status['loanStatusId'];
    }
    if (!status['subordinates']) {
      result.subordinates = [];
    }
    return result;
  }

  private mapLoanStatusAssociation(loanStatusAssociations: Array<LoanStatusAssociation>) {
    this.sortableLoanStatusAssociations = loanStatusAssociations
      .map((association: LoanStatusAssociation) => {
        const ids = association.associatedStatuses?.split(',') || [];

        const subordinates: Array<LoanStatusFlow> = [];
        ids.forEach(id => {
          const loanStatus = this.availableLoanStatuses.find(ls => ls.loanStatusId.toString() === id);
          if (loanStatus) {
            subordinates.push({
              displayName: loanStatus['loanStatusName'],
              statusId: +loanStatus.loanStatusId,
              subordinates: [],
              level: 1,
            });
          }
        })

        const status = this.availableLoanStatuses.find(
          (ls) => ls.loanStatusId === association.loanStatusId
        );
        return {
          displayName: status['loanStatusName'],
          statusId: +status.loanStatusId,
          subordinates: subordinates,
          level: 0,
        };
      }
      );
  }

  private goBack() {
    this._router.navigate(['admin/loan-config/loan-purpose']);
  }

  // drag and drop
  private onStatusMoved() {
    this.sortableLoanStatusAssociations.forEach((association, index) => {
      association.level = 0;
      if (!association.subordinates) {
        association = this.prepareStatusToDisplay(association);
        this.sortableLoanStatusAssociations[index] = association;
      }
      association.subordinates = association.subordinates.map((a, index) => {
        a = this.prepareStatusToDisplay(a);
        a.level = 1;
        return a;
      });
    });
  }

  private removeInvalidItemAfterDrop(newIndex: number, parentStatusId: string) {
    if (parentStatusId === '') {
      return this.sortableLoanStatusAssociations.splice(newIndex, 1);
    }
    const parent = this.sortableLoanStatusAssociations.find(
      (a) => a.statusId === parseInt(parentStatusId)
    );
    if (parent) {
      parent.subordinates.splice(newIndex, 1);
    }
  }

  private isDropValid(itemId: string, parentId: string): boolean {
    if (parentId === itemId) return false;

    if (parentId === '') {
      const index = this.sortableLoanStatusAssociations.findIndex(
        (a) => a.statusId === parseInt(itemId)
      );
      if (index > -1) return false;
    } else {
      const parent = this.sortableLoanStatusAssociations.find(
        (a) => a.statusId === parseInt(parentId)
      );
      if (!parent) return false;
      const index = parent.subordinates.findIndex(
        (s) => s.statusId === parseInt(itemId)
      );
      if (index > -1) return false;
    }

    return true;
  }

  private getStatusIdFromClass(value: string): string {
    return value.split('_')[1] || '';
  }
}
