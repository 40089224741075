import { Component, EventEmitter, OnInit, Input, Output, ViewChild, Injector, ElementRef, AfterViewInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observer, forkJoin, Observable, catchError, map, of, switchMap, concatMap, defaultIfEmpty } from 'rxjs';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { Utils } from 'src/app/core/services/utils';
import { LoanDoc, UserPermissions, UserType } from 'src/app/models';
import { FileDto } from 'src/app/models/borrower/file-dto.model';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { DocFile } from 'src/app/modules/loan-docs/models/doc-file.model';
import { MergeDocFilesRequest } from 'src/app/modules/loan-docs/models/merge-files/merge-doc-files-request.model';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { FileService } from 'src/app/services/file.service';
import { LoanDocService } from 'src/app/services/loan-doc.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TaskService } from 'src/app/services/task.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { MentionsUtils } from 'src/app/shared/services/mentions.utils';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { DocFilesEditDialogComponent } from 'src/app/modules/loan-docs/components/doc-files-edit-dialog/doc-files-edit-dialog.component';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageEditorWithMentionsComponent } from 'src/app/shared/components/message-editor-with-mentions/message-editor-with-mentions.component';

@Component({
  selector: 'app-task-loan-doc-preview-v2',
  templateUrl: './task-loan-doc-preview-v2.component.html',
  styleUrls: ['./task-loan-doc-preview-v2.component.scss']
})
export class TaskLoanDocPreviewComponentV2 extends ApplicationContextBoundComponent implements OnInit, AfterViewInit {

  @ViewChild("docFileFrame")
  docFileFrame: Element

  @ViewChild("docFilesEditDialog")
  docFilesEditDialog: DocFilesEditDialogComponent;

  @ViewChild('addNewDocFileBtn')
  addNewDocFileBtn: ElementRef<HTMLInputElement>;

  @ViewChild('borrowerFacingNoteEditor')
  borrowerFacingNoteEditor: MessageEditorWithMentionsComponent;

  @Input()
  url: string;

  set task(task: LoanDocDashboardTask) {
    this._task = cloneDeep(task);

    if (!this._task.docFiles || this._task.docFiles.length === 0) {
      this.frameUrl = null;
    } else if (!this.url) {
      this.file = this._task.docFiles[0];
    }

    if (this.borrowerFacingNoteEditor) {
      this.borrowerFacingNoteEditor.reset();
    }
    this.getInitialIconForDocFiles();
    this.populateStatusDropdown();
  }

  get task(): LoanDocDashboardTask {
    return this._task;
  }

  set file(file: FileDto) {
    this._file = cloneDeep(file);
    // To show the selected file preview initially
    this.onDocFileViewClicked(this._file);
  }

  get file(): FileDto {
    return this._file;
  }

  taskStatusOptions: any[] = [];

  mentionables: any[] = [];

  editingFile: DocFile;
  fileExtension: string;
  appId: number;
  loanDocs: LoanDoc[] = [];
  globalConfig: GlobalConfig;
  borrowerFacingNote: string = '';

  protected previousFileGuid: string;
  protected isDocFileEditClicked: boolean;
  protected selectAllClicked: boolean = false;

  protected savePreferences = {
    isSaveAndNextClicked: false,
    isSaveAndCloseClicked: false
  };

  protected frameUrl: SafeResourceUrl;

  private _task: LoanDocDashboardTask;
  private _file: FileDto;
  private _userPermissions: UserPermissions;
  private _isCreateClickedFromTop: boolean = false;

  @Input()
  hasSaveCancelButtons: boolean = true;

  @Output()
  cancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  changesApproved: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  changesApprovedAndNextRequested: EventEmitter<LoanDocDashboardTask> = new EventEmitter<LoanDocDashboardTask>();

  constructor(private readonly injector: Injector,
    private sanitizer: DomSanitizer,
    private readonly _enumerationService: EnumerationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _loanDocService: LoanDocService,
    private readonly _taskService: TaskService,
    private readonly _environment: EnvironmentService,
    private readonly _fileService: FileService,
    private readonly _modalService: NgbModal,
    private readonly _elementRef: ElementRef,) {
    super(injector);
  }

  ngOnInit(): void {
    let isLoanTpo = this.applicationContext.userPermissions.userType == UserType.Tpo;
    let allUsers = this.applicationContext.globalConfig.users;
    this.appId = this.task.applicationId;
    this.globalConfig = this.applicationContext.globalConfig;
    this._userPermissions = this.applicationContext.userPermissions;
    if (isLoanTpo) {
      allUsers = allUsers.concat(this.applicationContext.globalConfig.tpoUsers);
    }

    this.mentionables = this.mentionables.concat(MentionsUtils.prepareUserMentionables(this._environment.apiInfo.apiBaseUrl,
      allUsers));
    this.mentionables.push(MentionsUtils.prepareInternalContactsMentionable());
    this.mentionables.push(MentionsUtils.prepareHereMentionable());
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const thisElement = this._elementRef.nativeElement;
      const parentElement = thisElement.parentElement;
      const drawerElement = parentElement.parentElement;

      if (parentElement) {
        parentElement.style.padding = '0';
        parentElement.style.height = 'calc(100% - 43px)';
      }
      if (drawerElement && !drawerElement.classList.contains('full-screen-drawer')) {
        drawerElement.classList.add("full-screen-drawer");
      }
    });
  }

  onEditFileName = (file: FileDto, e: any) => {
    if (file.active) {
      file['shouldEdit'] = true;
    }
    e.stopPropagation();
  }

  onFileRenameCancelled = (file: FileDto, e: any) => {
    const { fileName, extension } = Utils.getFileNameAndExtension(file.fileName);
    file['fileNameWithoutExtension'] = fileName;
    file['shouldEdit'] = false;
    e.stopPropagation();
  }

  onFileRenameConfirmed = (file: FileDto, e: any) => {
    const { fileName, extension } = Utils.getFileNameAndExtension(file['originalFileName']);
    file.fileName = file['fileNameWithoutExtension'] + "." + extension;
    file['shouldEdit'] = false;
    e.stopPropagation();
  }

  onCreateDocFileClicked = (isCreateClickedFromTop?: boolean) => {
    if (!this.addNewDocFileBtn?.nativeElement) return;
    this.addNewDocFileBtn.nativeElement.click();
    this._isCreateClickedFromTop = isCreateClickedFromTop;
  }

  onAddDocFileClicked = (event: any) => {
    event.target.files.forEach((targetFile) => {
      const file = targetFile;
      let docFile = new FileDto();
      docFile.fileName = file.name;
      docFile.loanDocId = this.task.loanDocId;
      docFile.note = '';
      docFile.borrowerNote = '';
      docFile.guid = null;
      docFile.active = true;
      docFile.file = file;

      this._task.docFiles.push(docFile);
    });
    this._spinner.show();
    this.saveTask(true);
  };

  onDocFileViewClicked = (docFile: FileDto) => {
    if (docFile) {
      if (docFile.guid === this.previousFileGuid || docFile['shouldEdit']) {
        return;
      }
      this.editingFile = null;
      this.previousFileGuid = docFile.guid;

      let file = this.task.docFiles.find(dF => dF.guid == docFile.guid);
      if (file['url']) {
        this.frameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(file['url']);
        return;
      }

      this._spinner.show();

      this.getFileDownloadUrl(docFile)
        .subscribe({
          next: (url) => {
            file['url'] = url;
            this.frameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          },
          error: (err) => {
            this.frameUrl = null;
            this._notifyService.showError(err.message, 'Error');
          }
        }).add(() => this._spinner.hide());
    }
  }

  onDocFileEditClicked = (file: FileDto, e: any) => {
    e.stopPropagation();
    this.frameUrl = null;
    this.previousFileGuid = file.guid;

    this._spinner.show();
    this._fileService.getDocFile(file.guid)
      .subscribe({
        next: (docFile: DocFile) => {
          this.editingFile = null;
          this._loanDocsService.getLoanDocs(this.appId)
            .subscribe({
              next: (loanDocs) => {
                this.editingFile = docFile;
                const { fileName, extension } = Utils.getFileNameAndExtension(this.file.fileName);
                this.fileExtension = extension;
                this.loanDocs = loanDocs;
                this.isDocFileEditClicked = true;
              },
              error: (err) => {
                this._notifyService.showError("Error retrieving loan docs for app " + this.appId + '. ' + err ? err.message || err : '', 'Error');
              }
            })
        },
        error: (err) => {
          this._notifyService.showError(err ? (err.data ? err.data.message : "Error retrieving doc file") : "Error retrieving doc file", 'Error');
        }
      }).add(() => this._spinner.hide());
  }

  onDownloadDocumentClicked = (file: FileDto, e: any) => {
    e.stopPropagation();
    if (file['url']) {
      this.downloadFile(file['url'], file.fileName);
      return;
    }
    this._spinner.show();
    this.getFileDownloadUrl(file)
      .subscribe({
        next: (url) => {
          file['url'] = url;
          this.downloadFile(file['url'], file.fileName);
        },
        error: (err) => {
          this._notifyService.showError(err.message, 'Error');
        }
      }).add(() => this._spinner.hide());
  };

  checkboxFileSelected = (file: FileDto, e: any) => {
    file.selectedForAction = file.selectedForAction == true ? false : true;
    e.stopPropagation();
  }

  onSaveClicked = (saveAndNextClicked: boolean) => {
    this.savePreferences.isSaveAndNextClicked = saveAndNextClicked;
    this.savePreferences.isSaveAndCloseClicked = !saveAndNextClicked;
    this._spinner.show();
    const apiCallsToSaveFiles: any[] = [];

    this.task.docFiles.forEach(file => {
      if (file.fileName !== file['originalFileName']) {
        let request = new MergeDocFilesRequest(null, null, file.guid, file.fileName, null, false);
        apiCallsToSaveFiles.push(this._loanDocsService.mergeDocFiles(request, file.loanDocId));
        this.cleanupUiSpecificFieldsOnFileBeforeSave(file);
      }
    });

    if (!apiCallsToSaveFiles.length) {
      this.saveTask();
      return;
    }

    const observer: Observer<any> = {
      next: (value: any): void => {
        this.saveTask();
      },
      error: (err: any): void => {
        this._spinner.hide();
        this._notifyService.showError(err.message || 'Edit Document', 'Fail!');
      },
      complete: (): void => {
      }
    }

    forkJoin(apiCallsToSaveFiles).subscribe(observer);
  }

  onBorrowerFacingNoteChanged = (note: string) => {
    this._task.borrowerFacingNote = note;
  };

  isMergeVisible = () => {
    return this._task.docFiles && this._task.docFiles.filter(x => x.selectedForAction == true).length > 1;
  }

  onMergeFilesClicked = () => {
    this._spinner.show();
    let docGuids = this._task.docFiles.filter(x => x.selectedForAction == true).map(x => x.guid);

    this._loanDocsService.setMergeFiles(this._task.applicationId, this._task.loanDocId, docGuids)
      .subscribe({
        next: (response: any) => {
          this._taskService.getTaskDashboardViewById(this.task.loanDocTaskId).subscribe(task => {
            this.task = task;
            this.changesApproved.emit();
            this._notifyService.showSuccess('Successfully merged ' + docGuids.length + ' documents!', 'Success');
          })
        },
        error: (err) => {
          this._notifyService.showError(err.message || err || 'Error while merging documents!', 'Failure');
        }
      }).add(() => this._spinner.hide())
  }

  onConvertToPdfClicked = (file: FileDto, e: any) => {
    e.stopPropagation();
    this._spinner.show();
    this._fileService.convertToPdf(file.guid)
      .subscribe({
        next: (result: string) => {
          file['shouldEdit'] = false;
          const { fileName, extension } = Utils.getFileNameAndExtension(result);
          file['icon'] = this.getIconByExtension(extension);
          file['originalFileName'] = result;
          file.fileName = result;
          file['showConvertToPdf'] = false;
          file['fileNameWithoutExtension'] = fileName;
          this._notifyService.showSuccess('Conversion was successful.', 'Success!');
          file['url'] = null;
          this.changesApproved.emit();

          if (file.guid !== this.previousFileGuid) {
            return;
          }

          //to refresh frameUrl
          this.previousFileGuid = null;
          this.onDocFileViewClicked(file);
        },
        error: (err) => {
          let errorMessage = "An error occurred while converting document to pdf.";
          if (err.error) {
            errorMessage = err.error;
          }
          this._notifyService.showError(errorMessage, 'Error!');
        }
      }).add(() => this._spinner.hide());
  }

  onDeleteFileClicked = (file: FileDto, e: any) => {
    file.active = false;
    file.selectedForAction = false;
    e.stopPropagation();
  };

  onRestoreFileClicked = (file: FileDto, e: any) => {
    file.active = true;
    e.stopPropagation();
  }

  onSelectAllButtonManagement = (action: boolean) => {
    this._task.docFiles.forEach(docFile => {
      if (docFile.active) {
        docFile.selectedForAction = action;
      }
    });
    this.selectAllClicked = !this.selectAllClicked;
  }

  findExistingDrawer = () => {
    const thisElement = this._elementRef.nativeElement;
    const parentElement = thisElement.parentElement;
    const drawerElement = parentElement.parentElement;
    return drawerElement;
  }

  private getIconByExtension = (extension: string): string => {
    if (extension == 'excel' || extension == "xlx" || extension == "xls" || extension == "xlsx") {
      return "lar la-file-excel text-success";
    } else if (extension == 'pdf') {
      return "lar la-file-pdf text-danger";
    } else if (extension == "png" || extension == "jpg" || extension == "jpeg") {
      return "lar la-file-image text-info";
    } else if (extension == "wav" || extension == "mp3") {
      return "lar la-file-audio text-primary";
    } else if (extension == "txt") {
      return "lar la-file-alt text-primary";
    } else if (extension == "xml") {
      return "lar la-file-code text-danger";
    } else if (extension == "avi") {
      return "lar la-file-video text-primary";
    } else if (extension == "zip") {
      return "lar la-file-archive text-warning";
    } else if (extension == "doc") {
      return "lar la-file-word text-primary";
    } else {
      return "lar la-file-alt text-primary";
    }
  }

  private cleanupUiSpecificFieldsOnFileBeforeSave = (file: FileDto) => {
    delete file['shouldEdit'];
    delete file['originalFileName'];
    delete file['fileNameWithoutExtension'];
    delete file['url'];
    delete file['icon'];
    delete file['showConvertToPdf'];
  }

  private downloadFile = (url: string, fileName: string) => {
    let downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }


  private getFileDownloadUrl = (file: FileDto): Observable<string> => {
    return this._loanDocService.getLoanDoc(file.loanDocId).pipe(
      switchMap((loanDoc: LoanDoc) =>
        this._loanDocService.viewFile(file.guid).pipe(
          map(data => {
            const docFile = loanDoc.docFiles.find(df => df.guid === file.guid);
            let blob;
            if (file.fileName.split('.')[1] == 'fnm') {
              blob = new Blob([data], { type: 'fnm' });
            } else {
              blob = new Blob([data], { type: docFile.mimeType });
            }
            const url = window.URL.createObjectURL(blob);
            return url;
          })
        )
      )
    )
  }

  private removeInactiveFiles = (): Observable<any> => {
    const apiCallsToRemoveFiles: Observable<any>[] = [];

    this.task.docFiles.forEach(file => {
      if (!file.active) {
        apiCallsToRemoveFiles.push(this._fileService.removeFile(file.guid));
      }
    });

    if (!apiCallsToRemoveFiles.length) {
      return of(null);
    }
    return forkJoin(apiCallsToRemoveFiles).pipe(
      catchError((error) => {
        // Handle errors if needed
        console.error('Error in forkJoin:', error);
        return []; // You can return a default value or rethrow the error based on your requirement
      }),
      map((results) => {
        return results;
      })
    );
  }

  private onRequestFromBorrowerChange = () => {
    if (this.task.requestBorrower && this.globalConfig.losOptions.find(o => o.name === "conditionAutopasteTextToBorrowerFacingNote" && o.vendorName === this.applicationContext.application.losVendor)?.value == "True") {
      this.borrowerFacingNote = this.task.conditionText;
    }
    else {
      this.task.borrowerFacingNote = '';
      this.borrowerFacingNote = '';
    }

    this.populateStatusDropdown();
  };

  private populateStatusDropdown = () => {
    this.taskStatusOptions = [];
    if (this.task.requestBorrower) {
      this._enumerationService.taskStatuses.forEach((taskStatus) => {
        if (
          (taskStatus.id === 404 && this.task.taskType === 'EsignDocument') ||
          this.task.taskType === 'LosEsign'
        ) {
          return;
        }
        if (this.task.taskStatus == 'Pending') {
          if (
            !this.task.requiresReview &&
            [400, 401, 403, 404].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          } else if (
            this.task.requiresReview &&
            [400, 401].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'Submitted') {
          if (
            !this.task.requiresReview &&
            [401, 402, 403, 404].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          } else if (
            this.task.requiresReview &&
            [401, 402, 423].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'ReviewReady') {
          if ([402, 403, 423].indexOf(taskStatus.id) > -1) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'Rejected') {
          if (
            !this.task.requiresReview &&
            [401, 402, 403, 404].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          } else if (
            this.task.requiresReview &&
            [401, 402].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'NotApplicable') {
          if ([400, 404].indexOf(taskStatus.id) > -1) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'Approved') {
          if (
            !this.task.requiresReview &&
            [400, 401, 402, 403].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          } else if (
            this.task.requiresReview &&
            [400, 401, 402, 403, 423].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else {
          if (
            !this.task.requiresReview &&
            [400, 421, 422].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          } else if (
            this.task.requiresReview &&
            [400, 421, 422].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          }
        }
      });
    } else {
      this._enumerationService.taskStatuses.forEach((taskStatus) => {
        if (
          (taskStatus.id === 404 && this.task.taskType === 'EsignDocument') ||
          this.task.taskType === 'LosEsign'
        ) {
          return;
        }
        if (
          this.task.taskStatus == 'Pending' ||
          this.task.taskStatus == 'Requested'
        ) {
          if (
            !this.task.requiresReview &&
            [400, 404, 421, 422].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          } else if (
            this.task.requiresReview &&
            [400, 421, 423].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'ReviewReady') {
          if ([402, 403, 423].indexOf(taskStatus.id) > -1) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'Rejected') {
          if ([402, 423].indexOf(taskStatus.id) > -1) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'NotApplicable') {
          if ([400, 404].indexOf(taskStatus.id) > -1) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'Approved') {
          if (
            !this.task.requiresReview &&
            [400, 403].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          } else if (
            this.task.requiresReview &&
            [400, 402, 403, 423].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'ChangeOfCircumstancePending') {
          if ([426, 427].indexOf(taskStatus.id) > -1) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'ChangeOfCircumstanceRejected') {
          if ([425, 427].indexOf(taskStatus.id) > -1) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'ChangeOfCircumstanceApproved') {
          if ([425, 426].indexOf(taskStatus.id) > -1) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else if (this.task.taskStatus == 'EscalationPending') {
          if ([422].indexOf(taskStatus.id) > -1) {
            this.taskStatusOptions.push(taskStatus);
          }
        } else {
          if (
            !this.task.requiresReview &&
            [400, 422].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          } else if (
            this.task.requiresReview &&
            [400, 421, 422].indexOf(taskStatus.id) > -1
          ) {
            this.taskStatusOptions.push(taskStatus);
          }
        }
      });
    }
  };

  private saveTask = (isNewFileAdded?: boolean) => {
    const data = {
      loanDocTask: this.task,
      multipleBorrower: this.task.multipleBorrower,
      numFiles: this.task.docFiles.length,
      daysToPostpone: this.task.followUpDate
        ? moment(new Date(this.task.followUpDate), 'MM/DD/YYYY').diff(
          moment().startOf('day'),
          'days'
        )
        : 0,
    };

    this._taskService.upsertLoanDocTask(data).pipe(
      concatMap((response) => isNewFileAdded
        ? this.saveNewFile(data, response)
        : of(undefined)),
      concatMap(() => this.removeInactiveFiles()),
      concatMap(() => this.isDocFileEditClicked
        ? this.docFilesEditDialog.save()
        : of(undefined)),
      concatMap(() => this._taskService.getTaskDashboardViewById(this.task.loanDocTaskId)),
    ).subscribe({
      next: (task) => {
        this.task = task;
        this._spinner.hide();
        this._notifyService.showSuccess('Documents saved successfully', 'Success!');
        const closeToDrawer = this.savePreferences.isSaveAndCloseClicked ? true : false;

        if (this.savePreferences.isSaveAndNextClicked) {
          this.changesApprovedAndNextRequested.emit(this.task);
          this.savePreferences.isSaveAndNextClicked = false;
        } else {
          this.changesApproved.emit(closeToDrawer);
          this.savePreferences.isSaveAndCloseClicked = false;
        }
        this.isDocFileEditClicked = false;

        if (!this._task.docFiles || this._task.docFiles.length === 0) {
          this.frameUrl = null;
          this.file = null;
        } else {
          this.file = this._task.docFiles[0];
        }

      },
      error: (err) => {
        this._notifyService.showError(err.message || 'Documents not saved', 'Fail!');
      },
    }).add(() => this._spinner.hide());
  }

  private getInitialIconForDocFiles = (): void => {
    this._task.docFiles.forEach(file => {
      if (file.fileName) {
        file['shouldEdit'] = false;
        const { fileName, extension } = Utils.getFileNameAndExtension(file.fileName);
        file['icon'] = this.getIconByExtension(extension);
        file['originalFileName'] = file.fileName;
        file['showConvertToPdf'] = !file.fileName.toLowerCase().endsWith(".pdf");
        file['fileNameWithoutExtension'] = fileName;
      } else {
        file['originalFileName'] = '';
      }
    });
  }

  private saveNewFile = (data: any, responseTask: LoanDocDashboardTask): Observable<any> => {
    const newlyCreatedTask = data.loanDocTask.loanDocTaskId == 0;
    const apiCallsToSaveFiles: Observable<any>[] = [];
    this.task.docFiles.forEach((file) => {
      if (file.guid == null) {
        let autoTransition =
          this._task.taskStatus == 'ConditionImportPending'
            ? 'false'
            : newlyCreatedTask
              ? 'false'
              : 'true';
        file.loanDocId = responseTask.loanDocId
          ? responseTask.loanDocId
          : 0;
        apiCallsToSaveFiles.push(
          this._fileService.uploadFileAndSingleModel(
            [file.file],
            'api/File/UpsertFileFromTask/' +
            responseTask.loanDocTaskId +
            '?autoTransition=' +
            autoTransition +
            '&autoConvertToPdf=' +
            this._userPermissions.autoConvertToPdf +
            '&useDynamicCompression=' +
            this._userPermissions.useDynamicCompression,
            file
          )
        )
      }
    });

    if (this._isCreateClickedFromTop) {
      this.scrollToFileBoxesEndExecutor();
      this._isCreateClickedFromTop = false;
    }

    return forkJoin(apiCallsToSaveFiles).pipe(
      catchError((error) => {
        console.error('Error in forkJoin:', error);
        throw new Error('Error retrieving the files');
      }),
      defaultIfEmpty(undefined),
    );
  }

  private scrollToFileBoxesEndExecutor = () => {
    let element = document.getElementById("scroll-end-point");
    element.scrollTop = Number.MAX_SAFE_INTEGER;
  }
}

export class TaskDocChangesApprovedEvent {
  task: LoanDocDashboardTask;
  loadNextTask: boolean;
}
