<div *ngIf="!customFields?.length else cfgrid" class="row pt-3 text-center">
  <em>There are no custom fields added. Please click add button to add one.</em>
</div>
<ng-template #cfgrid>
  <p-table #customFieldsTable [value]="customFields" [paginator]="false" [autoLayout]="true"
    [reorderableColumns]="true" (onRowReorder)="onRowReorder($event)">
    <ng-template pTemplate="header">
      <tr>
        <th style="width:3rem"></th>
        <th class="col-2"> Name</th>
        <th class="col-2"> Label </th>
        <th class="col-1"> Default </th>
        <th class="col-2"> Type </th>
        <th class="col-1"> Display Mode </th>
        <th class="col-2"> Depends On Custom Field Name </th>
        <th class="col-1"> Depends On Values </th>
        <th class="col-1">Required </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-index="rowIndex">
      <tr [pReorderableRow]="index">
        <td>
          <span class="pi pi-bars" pReorderableRowHandle></span>
        </td>
        <td class="col-2">
          <input type="text" class="form-control" name="custom-field-name-{{item.tempUniquId}}" [(ngModel)]="item.name"
            #customFieldNameField="ngModel" (change)="onCustomFieldNameChanged()"
            [ngClass]="{'is-invalid': customFieldNameField && customFieldNameField.touched && customFieldNameField.invalid}"
            required />
        </td>
        <td class="col-2">
          <input type="text" class="form-control" name="custom-field-display-name-{{item.tempUniquId}}"
            [(ngModel)]="item.displayName" #customFieldDisplayNameField="ngModel"
            [ngClass]="{'is-invalid': customFieldDisplayNameField && customFieldDisplayNameField.touched && customFieldDisplayNameField.invalid}"
            required />
        </td>
        <td class="col-1">
          <input type="text" class="form-control" name="custom-field-default-value-{{item.tempUniquId}}"
            [(ngModel)]="item.defaultValue" />
        </td>
        <td class="col-2">
          <select class="form-select type-ddl" [(ngModel)]="item.type" name="custom-field-type-{{item.tempUniquId}}">
            <option value="List" selected>List</option>
            <option value="Checkbox">Checkbox</option>
            <option value="Text">Text</option>
            <option value="Number">Number </option>
            <option value="Date">Date</option>
          </select>
          <span *ngIf="item.type === 'List'" class="text-primary" (click)="openListOptionsModal(item)" role="button">
            {{'Option(' + (item.options?.length || 0) + ')'}}
          </span>
        </td>

        <td class="col-1">
          <select class="form-select" [(ngModel)]="item.displayMode" 
            name="custom-field-display-mode-{{item.tempUniquId}}" (ngModelChange)="onDisplayModeChange(item)">
            <option value="AlwaysShow" selected> Always Show</option>
            <option value="DependsOnAnyValue">Depends On Any Value</option>
            <option value="DependsOnSpecificValue">Depends On Specific Value</option>
            <option value="Hidden">Hidden</option>
          </select>
        </td>
        <td class="col-2">
          <select class="form-select" 
            [(ngModel)]="item.dependsOnCustomField"
            (ngModelChange)="onDependsOnCustomFieldChange(item)"
            name="custom-field-depends-{{item.tempUniquId}}"
            #dependsOnCustomField="ngModel" [required]="item.displayMode == 'DependsOnAnyValue' || item.displayMode == 'DependsOnSpecificValue'"
            [ngClass]="{'is-invalid' : dependsOnCustomField && dependsOnCustomField.touched && dependsOnCustomField.invalid}" 
            [disabled]="item.displayMode == 'AlwaysShow' || item.displayMode == 'Hidden'">
            <option value="null">Select One</option>
            <option *ngFor="let customField of dependsOnCustomFields" 
              [ngValue]="customField" [hidden]="customField.name == item.name">
              {{customField.name}}
            </option>
          </select>
        </td>
        <td class="col-1">
          <div *ngIf="item.displayMode == 'DependsOnSpecificValue'">
            <ng-select *ngIf="item.dependsOnCustomField && item.dependsOnCustomField.type === 'List'" name="dependsOnValues-list-{{item.tempUniquId}}"
              [(ngModel)]="item.dependsOnValues" [items]="item.dependsOnCustomField.options" bindLabel="description" bindValue="value"
              placeholder="Select One" [closeOnSelect]="false" [multiple]="true" [required]="item.displayMode == 'DependsOnSpecificValue'">
            </ng-select>

            <input *ngIf="item.dependsOnCustomField && item.dependsOnCustomField.type === 'Text'" type="text" class="form-control" name="dependsOnValues-text-{{item.tempUniquId}}"
              [(ngModel)]="item.dependsOnValue" #customFieldTextInput="ngModel" [required]="item.displayMode == 'DependsOnSpecificValue'"
              [ngClass]="{'is-invalid' : customFieldTextInput && customFieldTextInput.touched && customFieldTextInput.invalid}"/>

            <input *ngIf="item.dependsOnCustomField && item.dependsOnCustomField.type === 'Number'" class="form-control" type="number" class="form-control"
                [required]="item.displayMode == 'DependsOnSpecificValue'" name="dependsOnValues-number-{{item.tempUniquId}}" #customFieldNumberInput="ngModel"
                [ngClass]="{'is-invalid' : customFieldNumberInput && customFieldNumberInput.touched && customFieldNumberInput.invalid}"
                [(ngModel)]="item.dependsOnValue"/>

            <date-input *ngIf="item.dependsOnCustomField && item.dependsOnCustomField.type === 'Date'" class="form-control" name="dependsOnValues-text-{{item.tempUniquId}}"
              [(ngModel)]="item.dependsOnValue" [required]="item.displayMode == 'DependsOnSpecificValue'" #customFieldDateInput="ngModel"
              [ngClass]="{'is-invalid' : customFieldDateInput && customFieldDateInput.touched && customFieldDateInput.invalid}">
            </date-input>

            <div class="radio-group-container"*ngIf="item.dependsOnCustomField && item.dependsOnCustomField.type === 'Checkbox'">
              <label class="radio-group-item" *ngFor="let option of yesNoOptions">
                <input
                  type="radio"
                  [name]="'dependsOnValues-checkbox-' + item.tempUniquId"
                  [id]="item.tempUniquId + '_' + option.value"
                  [(ngModel)]="item.dependsOnValue"
                  [value]="option.value"
                  [required]="item.displayMode == 'DependsOnSpecificValue'"/>
                  {{option.name}}
              </label>
            </div>
          </div>
        </td>

        <td>
          <div class="col-1 d-flex justify-content-left align-items-center w-100">
            <div class="form-check me-2 mt-1">
              <input type="checkbox" class="form-check-input me-1" name="custom-field-is-required-{{item.tempUniquId}}"
                [(ngModel)]="item.isRequired" [disabled]="item.displayMode == 'Hidden'">
            </div>
            <confirm-delete [list]="customFields" [index]="index"></confirm-delete>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>