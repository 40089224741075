<div class="post d-flex flex-column-fluid">
  <div class="container">
    <div class="card" style="margin-top: 15px; height: 97vh;">
      <div class="card-body p-0 esign-container">
        <div class="card-px py-20 my-10">
            <div class="alert bg-light-success border border-success border-dashed d-flex flex-column flex-sm-row w-100 p-5 mb-10">
              <i class="fas fa-check fa-2x text-success me-4 mt-3"></i>
              <div class="d-flex flex-column pe-0 pe-sm-10">
                <h5 class="mb-1">{{messageTitleToUserAfterEsign}}</h5>
                <span>{{messageToUserAfterEsign}}</span>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>



