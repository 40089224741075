import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {FeeSection, FeeSectionViewType} from '../../fee-section.model';
import {splitCamelCaseAndCapitals} from '../../../../core/services/string-utils';
import {FeeViewModel} from '../../fee-view-model';
import {autoId} from '../../../../core/services/utils';
import {NotificationService} from '../../../../services/notification.service';
import {LoanFee} from '../../fees.model';
import {lastValueFrom, Subject, Subscription} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {FeeUpdaterService} from '../../services/fee-updater.service';
import {debounceTime, finalize} from 'rxjs/operators';
import {FeeUtils} from '../../utils/utils';
import {FeeContextService} from '../../services/fee-context.service';
import {FeeDisplayService} from '../../services/fee-display.service';
import addUpFeeTotals = FeeUtils.addUpFeeTotals;

@Component({
  selector: 'fees-review-section',
  templateUrl: './fees-review-section.component.html',
  styleUrls: ['./fees-review-section.component.scss'],
  providers: [FeeUpdaterService],
})
export class FeesReviewSectionComponent implements OnInit, OnDestroy {
  
  @Input() set feeSectionType(value: FeeSectionViewType) {
    this._feeSectionType = value;
    this.subscribeToFeeSectionChanges();
  }

  private _feeSectionType: FeeSectionViewType;

  /**
   * Controls mutability of fees.
   *
   * If set to true, it allows modification (mutation) to the fees including adding, removing, or
   * updating the fees. Otherwise, it disables any modification to the fees.
   *
   * By default, it is set to true, allowing changes to the fees.
   */
  @Input() editable: boolean = true;

  @Input('actions') inputActions?: FeesReviewSectionAction[];

  /**
   * This event is emitted when "edit" is clicked on a fee.
   */
  @Output() showFeeDetails = new EventEmitter<LoanFee>();

  @HostBinding('id')
  protected id: string = `fees-review-section-${autoId()}`;

  protected feeSection: FeeSection;

  protected title: string;
  protected feeViewModels: FeeViewModel[];
  protected totalFeeAmount: number;

  protected isCollapsed: boolean = false;

  private readonly _invalidateFeeChangesSubject = new Subject<void>();

  private _feeSectionChangesSubscription?: Subscription;
  private _invalidateFeeChangesSubscription: Subscription;
  private _updateEditedPayerSubscription?: Subscription;

  constructor(
    private readonly _notificationService: NotificationService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _feeContextService: FeeContextService,
    private readonly _feeDisplayService: FeeDisplayService,
    private readonly _feeUpdaterService: FeeUpdaterService
  ) {}

  ngOnInit(): void {
    if (this._feeSectionType == null) {
      this.setFeeSection();
    }

    this.subscribeToInvalidateFeeChanges();
  }

  ngOnDestroy() {
    this._feeSectionChangesSubscription?.unsubscribe();
    this._updateEditedPayerSubscription?.unsubscribe();
    this._invalidateFeeChangesSubject?.unsubscribe();
  }

  private subscribeToFeeSectionChanges(): void {
    this._feeSectionChangesSubscription?.unsubscribe();
    this._feeSectionChangesSubscription = this._feeDisplayService
      .getFeeSectionChanges$(this._feeSectionType)
      .subscribe(this.setFeeSection.bind(this));
  }

  private setFeeSection(feeSection?: FeeSection | undefined): void {
    this.feeSection = feeSection ?? ({type: FeeSectionViewType.Other, fees: []} as FeeSection);

    this.resetTitle();
    this.resetFeeViewModels();
    this.resetTotalFeeAmount();
  }

  private resetTitle(): void {
    const type = this.feeSection?.type ?? '';
    this.title = splitCamelCaseAndCapitals(type);
  }

  private resetFeeViewModels(): void {
    this.feeViewModels = this._feeContextService.getSectionFeeViewModels(this.feeSection);
  }

  protected resetTotalFeeAmount(): void {
    this.totalFeeAmount = addUpFeeTotals(this.feeViewModels);
  }

  private subscribeToInvalidateFeeChanges(): void {
    this._invalidateFeeChangesSubscription?.unsubscribe();
    this._invalidateFeeChangesSubscription = this._invalidateFeeChangesSubject
      .pipe(debounceTime(300))
      .subscribe(() => {
        this._feeContextService.checkChanges({ignoreCache: true});
      });
  }

  protected invalidateFeeChanges(): void {
    this._invalidateFeeChangesSubject.next();
  }

  /**
   * This method is used to cast the value to a FeeViewModel to use it in the template as
   * strongly typed.
   * @param {unknown} value - The value to cast to a FeeViewModel.
   * @protected
   * @returns {FeeViewModel | undefined} - The value cast to a FeeViewModel or undefined if it
   * cannot be cast.
   */
  protected castToFeeViewModel(value: unknown): FeeViewModel | undefined {
    return value instanceof FeeViewModel ? value : undefined;
  }

  protected async onDeleteFeeClick(feeViewModel: FeeViewModel): Promise<void> {
    await this._spinnerService.show();
    try {
      await lastValueFrom(this._feeContextService.deleteFee(feeViewModel.fee));
    } catch (e) {
      const message = 'Failed to delete fee. See console for more details.';
      console.error(message, e);

      this._notificationService.showError(message, 'Error');
    } finally {
      await this._spinnerService.hide();
    }
  }

  protected updateFee(feeViewModel: FeeViewModel): void {
    // noinspection JSIgnoredPromiseFromCall
    this._spinnerService.show();
    this._updateEditedPayerSubscription?.unsubscribe();

    const feeUpdater = this._feeUpdaterService.createUpdater(feeViewModel);
    this._updateEditedPayerSubscription = feeUpdater
      .updateWithResult()
      .pipe(
        finalize(() => {
          // noinspection JSIgnoredPromiseFromCall
          this._spinnerService.hide();
          feeUpdater.destroy();
        })
      )
      .subscribe(result => {
        this.emitFeeUpdate(result);
      });
    this._updateEditedPayerSubscription.add(() => {
      feeUpdater.destroy();
    });
  }

  private _updateFeeSubscription: Subscription;

  protected emitFeeUpdate(feeViewModel: FeeViewModel): void {
    this._updateFeeSubscription?.unsubscribe();
    this._updateFeeSubscription = this._feeContextService.updateFee(feeViewModel.fee).subscribe();
  }

  protected onEditFeeClick(feeViewModel: FeeViewModel): void {
    this.showFeeDetails.emit(feeViewModel.fee);
  }
}

export interface FeesReviewSectionAction {
  label: string;
  iconClass: string;
  onClick: () => void;
}
