import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PopoverModule } from "ngx-bootstrap/popover";
import { ButtonModule } from "primeng/button";
import { MultiSelectModule } from "primeng/multiselect";
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from "primeng/table";
import { SharedModule } from "src/app/shared/shared.module";
import { AgentInfoModule } from "../agent-info/agent-info.module";
import { AgentsModule } from "../agents/agents.module";
import { AppDetailsModule } from "../app-details/app-details.module";
import { BorrowerModule } from "../borrower/borrower.module";
import { ContactsModule } from "../contacts/contacts.module";
import { CorrespondenceModule } from "../correspondence/correspondence.module";
import { LeadsModule } from "../leads/leads.module";
import { LoanActivityModule } from "../loan-activity/loan-activity.module";
import { DialListRecordDetailsComponent } from './components/dial-list-record-details/dial-list-record-details.component';
import { DialListRecordsTableComponent } from './components/dial-list-records-table/dial-list-records-table.component';
import { LeadStatusComponent } from './components/lead-status/lead-status.component';
import { LoanCustomDataComponent } from './components/loan-custom-data/loan-custom-data.component';
import { DialerRoutingModule } from "./dialer-routing.module";
import { DialerComponent } from "./dialer.component";
import { ContactListsService } from "../contact-lists/services/contact-lists.service";
import { EditRecordNoteComponent } from './components/dial-list-records-table/edit-record-note/edit-record-note.component';
import { ApplicationSubstatusComponent } from './components/application-substatus/application-substatus.component';
import { DropdownModule } from "primeng/dropdown";
import { MarkManualTransferDialogComponent } from "./components/mark-manual-transfer-dialog/mark-manual-transfer-dialog.component";
import { ConversationsModule } from "../conversations/conversations.module";
import { CustomDataInfoModule } from "../custom-data-info/custom-data-info.module";

@NgModule({
  declarations: [
    DialerComponent,
    DialListRecordsTableComponent,
    DialListRecordDetailsComponent,
    LeadStatusComponent,
    LoanCustomDataComponent,
    EditRecordNoteComponent,
    ApplicationSubstatusComponent,
    MarkManualTransferDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DialerRoutingModule,
    TableModule,
    SkeletonModule,
    MultiSelectModule,
    ButtonModule,
    LeadsModule,
    CorrespondenceModule,
    LoanActivityModule,
    AppDetailsModule,
    AgentsModule,
    BorrowerModule,
    PopoverModule,
    AgentInfoModule,
    ContactsModule,
    CustomDataInfoModule,
    DropdownModule,
    ConversationsModule
  ],
  exports: [
  ],
  providers: [
    ContactListsService
  ]
})
export class DialerModule { }
