import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { FeeSystemDetails } from 'src/app/models/fee/fee-system-details.model';
import { LoanFee } from 'src/app/models/fee/fee.model';
import { Constants } from 'src/app/services/constants';
import { CalculatedFeeTypeEnum } from '../../../models/fee/calculated-fee-type.enum';
import { CreateFeeModel } from '../../../models/fee/create-fee.model';
import { FeeSectionEnum } from '../../../models/fee/fee-section.enum';
import { FeeValidationModel } from '../../../models/fee/fee-validation.model';
import { CreateFeeModalComponent } from '../modals/create-fee-modal/create-fee-modal.component';
import { FeeUtils } from '../services/fee-utils.service';
import { Utils } from 'src/app/core/services/utils';

@Component({
  selector: 'origination-charges',
  templateUrl: 'origination-charges.component.html',
  styleUrls: ['origination-charges.component.scss']
})
export class OriginationChargesComponent implements OnInit, AfterViewInit {

  @ViewChild('originationForm') originationForm: NgForm;

  @ViewChildren("feeSplitEditorPopover")
  feeSplitEditorPopover: QueryList<PopoverDirective>;

  @Input()
  public set fees(fees: LoanFee[]) {
    this._fees = fees;
    this._fees.forEach(fee => {
      fee['splitPayInfo'] = this._feeUtils.getSplitAmountAndPayerDisplayTextForAllPossiblePayers(fee, true);
    })
  }

  get fees(): LoanFee[] {
    return this._fees;
  }

  @Input()
  feeSystemDetails: FeeSystemDetails;

  @Output() onFeeAdded = new EventEmitter<CreateFeeModel>();
  @Output() onFeeValueUpdated = new EventEmitter<LoanFee>();
  @Output() onFeeRemoved = new EventEmitter<LoanFee>();
  @Output() onValidationChanged = new EventEmitter<FeeValidationModel>();
  @Output() openFeeEditor = new EventEmitter<LoanFee>();

  feeSection = FeeSectionEnum.Origination;
  pendingDeleteIndex: number = -1;

  isValidForm = true;

  selectedFeeForEditingSplit: LoanFee = null;
  selectedFeeForEditingDetails: LoanFee = null;

  private _fees: LoanFee[];

  constructor(
    private readonly _feeUtils: FeeUtils,
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    if (this.originationForm) {
      this.originationForm.statusChanges.subscribe(status => this.onFormValidationStatusChanged(status));
    }
  }

  scrollToFirstInvalidControl() {
    Utils.validateAndScrollToFirstInvalidControl(this.originationForm);
  }

  onSplitCancelled = () => {
    this.feeSplitEditorPopover?.forEach(popover => popover.hide());
  }

  onSplitApplied = (fee: LoanFee) => {
    this.feeSplitEditorPopover?.forEach(popover => popover.hide());

    const existingFee = this._fees.find(f => f.modelGuid === fee.modelGuid);
    if (!existingFee) return;

    existingFee.borrowerFeePercent = fee.borrowerFeePercent;
    existingFee.borrowerFeeDollar = fee.borrowerFeeDollar;
    existingFee.sellerFeePercent = fee.sellerFeePercent;
    existingFee.sellerFeeDollar = fee.sellerFeeDollar;
    existingFee.lenderFeePercent = fee.lenderFeePercent;
    existingFee.lenderFeeDollar = fee.lenderFeeDollar;
    existingFee.thirdPartyFeePercent = fee.thirdPartyFeePercent;
    existingFee.thirdPartyFeeDollar = fee.thirdPartyFeeDollar;
    existingFee.feePercentOf = fee.feePercentOf;

    existingFee['splitPayInfo'] = this._feeUtils.getSplitAmountAndPayerDisplayTextForAllPossiblePayers(existingFee, true);

    this.onFeeValueUpdated.emit(existingFee);
  }

  onFeeSelectedForEditingSplit = (fee: LoanFee) => {
    this.selectedFeeForEditingSplit = fee;
  }

  onFormValidationStatusChanged = (status: string) => {
    this.isValidForm = status !== "INVALID";
    this.onValidationChanged.next({ section: this.feeSection, calculatedType: null, isValid: this.isValidForm });
    if (!this.isValidForm) {
      this.originationForm.form.markAllAsTouched();
    }
  }

  openAddFeeModal() {
    const modalRef = this.modalService.open(CreateFeeModalComponent, Constants.modalOptions.medium);
    modalRef.componentInstance.feeSystemDetails = this.feeSystemDetails;
    modalRef.componentInstance.hudNumbersToExclude = [...new Set(this._fees.map(fee => fee.hudNumber))].filter(hud => hud);
    modalRef.componentInstance.feeTypesToExclude = [...new Set(this._fees.map(fee => fee.feeType))].filter(feeType => feeType);
    modalRef.componentInstance.title = 'Create Origination Charges Fee';
    modalRef.componentInstance.allowFreeformFeeEntry = false;
    modalRef.componentInstance.feeSection = this.feeSection;
    modalRef.componentInstance.calculatedFeeType = CalculatedFeeTypeEnum.Default;
    modalRef.componentInstance.restrictHudNumberEntryBySectionType = true;

    modalRef.result.then(
      (data: CreateFeeModel) => {
        this.onFeeAdded.next(data);
      }, () => { });
  }

  removeFee(fee) {
    this.pendingDeleteIndex = -1;
    this.onFeeRemoved.next(fee);
  }

  onLockStatusChanged = (fee: LoanFee) => {
    fee.isChargeOverridden = !fee.isChargeOverridden;
    this.onFeeValueUpdated.emit(fee);
  }

  onPercentChanged = (fee: LoanFee, type: 'borrower' | 'seller') => {
    switch (type) {
      case 'borrower':
        fee.borrowerFeeDollar = 0;
        break;
      case 'seller':
        fee.sellerFeeDollar = 0;
        break;
      default:
        break;
    }

    this.onFeeValueUpdated.emit(fee);
  }

  onDollarChanged = (fee: LoanFee, type: 'borrower' | 'seller') => {
    switch (type) {
      case 'borrower':
        fee.borrowerFeePercent = 0;
        break;
      case 'seller':
        fee.sellerFeePercent = 0;
        break;
      default:
        break;
    }

    this.onFeeValueUpdated.emit(fee);
  }

  onFeeEditorOpened = (fee: LoanFee) => {
    this.openFeeEditor.emit(fee);
  }
}
