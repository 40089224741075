import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { PollyPricerComponent } from "./polly-pricer.component";
import { PollyPricerDialogComponent } from "./polly-pricer-dialog/polly-pricer-dialog.component";

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    PollyPricerComponent,
    PollyPricerDialogComponent
  ],
  exports: [
    PollyPricerComponent,
    PollyPricerDialogComponent
  ]
})
export class PollyPricerModule {
  constructor() {
  }
}
