import { IHaveContactInfo } from "src/app/models/contact-info.interface";
import { IHaveName } from "src/app/models/have-name.interface";

export class BorrowerDto implements IHaveContactInfo, IHaveName {
  borrowerId: number;
  companyId: number;
  creditScore: number;
  creditScore_Equifax: number;
  creditScore_Experian: number;
  creditScore_TransUnion: number;
  scoreFromCreditReport: boolean;
  firstName: string;
  lastName: string;
  last4Ssn: string;
  userId: string;
  mobilePhone: string;
  homePhone: string;
  email: string;
  isRegister: boolean;
  isInvited: boolean;
  isPrimary: boolean;
  isPortalMerged: boolean;
  applicationId: number;
  lastTimeLogin: Date;
  lastLogin: string;
  birthDate: Date;
  mailingCountry: string;
  mailingStreet: string;
  mailingCity: string;
  mailingState: string;
  mailingZip: string;
  mailingCounty?: string;
  borrowerContact: string;
  borrowerContactName: string;
  dateCreateBorrower: Date;
  dateUpdated: Date;
  portalUserName: string;
  fullName?: string;
}
