<div *ngIf="selectedChannel && selectedBranch && selectedUserCompanyGuid" class="alert alert-light fade show mb-1 navbar-expand-lg" role="alert" id="alert-window"
  style="position: relative !important; border-bottom: 2px solid rgb(46, 168, 226) !important;">
  <div class="media">
    <i style="color:rgb(46, 168, 226)" class="fas fa-diagnoses alert-icon align-self-center font-30 me-3"></i>
    <div class="media-body align-self-center">
      <span class="mb-1 fw-bold mt-0">You as pricing as {{selectedChannel}}/{{branchName}}/{{userName}}.</span><br>
      <span> <a (click)="onChangePricingProfileClicked()" style="color:rgb(46, 168, 226)" target="_blank">Click Here To
          Change Pricing Profile</a></span>
    </div>
  </div>
</div>

<div class="flex-grow-1">

  <div *ngIf="error" class="card" style="height:90vh; width: 100%;">
    <div class="card-body">
      <div class="alert custom-alert custom-alert-danger icon-custom-alert shadow-sm my-3 ms-3" role="alert">
        <div class="media">
          <i class="la la-exclamation-triangle alert-icon text-danger font-30 me-3 align-self-center"></i>
          <div class="media-body align-self-center">
            <span>{{ error }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center align-items-center h-100 my-auto" *ngIf="preparingForPricing">
    <div class="d-flex">
      <div class="spinner-border thumb-xxs text-primary me-2 mt-2" role="status"></div>
      <h5 *ngIf="loanSyncAttempts <= 1" class="font-18 text-muted me-4">
        {{isQuickPricer ? 'Loading' : 'Preparing your loan' }}, please wait...
      </h5>
      <h5 *ngIf="loanSyncAttempts > 1" class="font-18 text-muted me-4">
        Retry attempt {{loanSyncAttempts - 1}}. Preparing your loan, please wait...
      </h5>
    </div>
  </div>

  <div class="d-flex justify-content-center align-items-center my-auto" style="height:50vh;"
    *ngIf="!selectedChannel || !selectedBranch|| !selectedUserCompanyGuid">
    <div class="row">
      <div class="col-md-12">
        <div class="mb-3" *ngIf="channels?.length > 0">
          <label for="channel" class="form-label" style="min-width: 90px;">
            Channel:
          </label>
          <select class="form-select" id="channel" name="channel" #channelInput="ngModel" [(ngModel)]="selectedChannel"
            (ngModelChange)="onLenderPriceChannelChanged($event)" required
            [ngClass]="{'is-invalid' : channelInput && channelInput.touched && channelInput.invalid}">
            <option [ngValue]="null">-- Select One --</option>
            <option [ngValue]="channel.name" *ngFor="let channel of channels">{{channel.name}}</option>
          </select>
          <div class="invalid-feedback">Please, pick a channel</div>
        </div>
        <div class="mb-3" *ngIf="channelBranches?.length > 0 && selectedChannel">
          <label for="branch" class="form-label" style="min-width: 90px;">
            Branch:
          </label>
          <p-dropdown id="branch" name="branch" styleClass="w-100" [options]="channelBranches"
            [(ngModel)]="selectedBranch" [showClear]="true" [filter]="true" filterBy="branchName"
            (ngModelChange)="onLenderPriceBranchChanged()" optionLabel="branchName" optionValue="branchId"
            [required]="true" placeholder="-- Select One --">
          </p-dropdown>
        </div>
        <div class="mb-3" *ngIf="externalBranchUsers?.length > 0 && selectedBranch">
          <label for="externalBranchUsers" class="form-label" style="min-width: 90px;">
            User:
          </label>
          <p-dropdown id="externalBranchUsers" name="externalBranchUsers" styleClass="w-100"
            [options]="externalBranchUsers" [(ngModel)]="selectedUserCompanyGuid" [showClear]="true" [filter]="true"
            filterBy="userName" (ngModelChange)="onLenderPriceUserChanged()" optionLabel="userName"
            optionValue="userCompanyGuid" [required]="true" placeholder="-- Select One --">
          </p-dropdown>
        </div>
      </div>
    </div>
  </div>

  <div style="height:90vh; width: 100%;" *ngIf="lenderPricePricerUrl && selectedUserCompanyGuid">
    <iframe sandbox="allow-same-origin allow-forms allow-scripts" [src]="lenderPricePricerUrl" width="100%"
      height="100%" #iFrame>
    </iframe>
  </div>
</div>