import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FeeTemplate } from 'src/app/models/fee/fee-template.model';
import { FeeService } from 'src/app/services/fee.service';
import { FeesEditorComponent } from '../fees-editor/fees-editor.component';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';
import { LoanFee } from 'src/app/models/fee/fee.model';
import { cloneDeep } from 'lodash';
import { DrawerOptions, DrawerSize, DrawerService } from 'src/app/shared/services/drawer.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { DrawerComponent } from 'src/app/shared/components/drawer/drawer.component';
import { LoanApplication, ApplicationContext } from 'src/app/models';
import { UrlaMortgage } from '../../urla/models/urla-mortgage.model';

@Component({
  selector: 'scenario-fees-dialog',
  templateUrl: 'scenario-fees-dialog.component.html',
  styleUrls: ['scenario-fees-dialog.component.scss']
})

export class ScenarioFeesDialogComponent extends ApplicationContextBoundComponent implements OnInit {

  title: string;
  message: string;

  @Input()
  fees: LoanFee[] = [];

  @Input()
  templates: FeeTemplate[] = [];

  @ViewChild("feesEditor")
  feesEditor: FeesEditorComponent;

  @ViewChild('feesEditorDrawer')
  feesEditorDrawer: DrawerComponent;

  @Input()
  scenarioId: number;

  application: LoanApplication | null = null;
  mortgage: UrlaMortgage;

  loanAmount: number = 0;
  totalLoanAmount: number = 0;
  purchasePrice: number = 0;
  propertyAppraisedValue: number = 0;

  feeEditorDrawerTitle: string;
  selectedFeeForEditingDetails: LoanFee;

  feeEditorDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }

  constructor(
    private readonly injector: Injector,
    public activeModal: NgbActiveModal,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _notifsService: NotificationService,
    private readonly _feeService: FeeService,
    private readonly _drawerService: DrawerService
  ) {
    super(injector);
  }

  ngOnInit() {
    if (this.applicationContext.application) {
      this.initialize(this.applicationContext);
    }
  }

  onFeeEditorDrawerOpened = (fee: LoanFee) => {
    this._drawerService.hide("feesEditorDrawer", 10);

    if (this.selectedFeeForEditingDetails) {
      setTimeout(() => {
        this.feeEditorDrawerTitle = `${fee?.hudNumber ? fee.hudNumber + ':' : ''} ${fee.name}`
        this.selectedFeeForEditingDetails = cloneDeep(fee);
        this._drawerService.show("feesEditorDrawer", 10);
      }, 500);

      return;
    } else {
      this.feeEditorDrawerTitle = `${fee?.hudNumber ? fee.hudNumber + ':' : ''} ${fee.name}`
      this.selectedFeeForEditingDetails = cloneDeep(fee);
      this._drawerService.show("feesEditorDrawer", 10);
    }
  }

  onFeeEditorDrawerSaved = (fee: LoanFee) => {
    let existingFee = this.fees.find(f => f.modelGuid === fee.modelGuid);
    if (!existingFee) return;

    existingFee = { ...existingFee, ...fee };

    this.feesEditor.updateFeeValue(fee);
    this.onFeeEditorDrawerClosed();
  }

  onFeeEditorDrawerClosed = () => {
    this._drawerService.hide("feesEditorDrawer", 10);
    this.feeEditorDrawerTitle = null;
    this.selectedFeeForEditingDetails = null;
  }

  onFeesAdded = (fees: LoanFee[]) => {
    this.fees.push(...fees);
    this.fees = [...this.fees];
  }

  onFeeUpdated = (fee: LoanFee) => {
    const index = this.fees.findIndex(f => f.modelGuid === fee.modelGuid);
    if (index === -1) return;

    this.fees[index] = { ...this.fees[index], ...fee };
    this.fees = [...this.fees];
  }

  onFeeRremoved = (fee: LoanFee) => {
    const index = this.fees.findIndex(f => f.modelGuid === fee.modelGuid);
    if (index === -1) return;

    this.fees.splice(index, 1);
  }

  onSaveClicked = () => {
    if (!this.feesEditor.isAllValid) {
      this.showInvalidFeesWarning();
      return;
    }
    this._spinnerService.show();
    this._feeService.saveScenarioFees(this.scenarioId, this.fees).subscribe(() => {
      this.activeModal.close();
    }, error => {
      const errorMessage = error.error ? error.error : "An error occurred while saving fees."
      this._notifsService.showError(errorMessage, "Error!");
    }).add(() => this._spinnerService.hide());
  }

  private showInvalidFeesWarning = () => {
    Swal.fire({
      title: 'Invalid Fees',
      text: 'There are invalid fees - please fix them and then try saving again.',
      icon: 'warning',
      confirmButtonText: 'OK',
      reverseButtons: true
    }).then(function (result: SweetAlertResult) {
      if (!result.value) {
        return;
      }
    });
  }

  private initialize = (context: ApplicationContext) => {
    this.application = context.application;
    this.mortgage = context.currentMortgage;

    if (this.application) {
      this.loanAmount = this.application.loanAmount;
      this.totalLoanAmount = this.mortgage?.mortgageTerm?.totalLoanAmount;
      this.purchasePrice = this.mortgage?.transactionDetail?.purchasePriceAmount;
      this.propertyAppraisedValue = this.mortgage?.mortgageTerm?.appraisedValue;
    }
  }
}
