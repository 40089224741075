<ng-container *ngIf="!isLoadingLoanData">
  <div *ngIf="!isUsedAsChildComponent"
    class="card pt-1 pe-1 pb-1 mb-2 tpo-report-card sticky-button-bar sticky-button-bar-vertical"
    [ngClass]="{'sticky-nav-tabs-open-dialer': secondPageActionBarVisible }">
    <div class="button-items">
      <div class="float-start">
        <div class="d-inline-flex ms-3 mt-2" style="padding-top: 2px;">
        </div>
      </div>
      <div class="float-end">
        <button type="button" [disabled]="isLoanReadOnly" class="btn btn-primary" (mousedown)="saveLoanInfo()">
          <i class="fas fa-save"></i>
          {{isLoanReadOnly ? 'Save (Readonly)' : 'Save'}}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="isUsedAsChildComponent" class="card pt-1 pe-1 pb-1 mb-2">
    <div class="button-items">
      <div class="float-start">
        <div class="d-inline-flex ms-3 mt-2" style="padding-top: 2px;">

        </div>
      </div>
      <div class="float-end">
        <a type="button" class="btn" [routerLink]="['/tpo/app-details/' + application.applicationId]"> Open
          Application
        </a>
      </div>
    </div>
  </div>
  <div id="loan-summary-pdf" [ngClass]="{'modal-body modal-body--scroll': isUsedAsChildComponent}">
    <form #loanForm="ngForm" novalidate id="loanForm" name="loanForm">

      <div class="card" id="loanAndPropertyInfo">
        <div class="card-header">
          <h4 class="card-title"><i class="fas fa-file-contract me-2"></i> Loan & Property Info</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="row py-1">
                <div class="col-6">Loan Purpose</div>
                <div class="col-6">
                  <select-input [(ngModel)]='loanPurposeId' (blur)='onLoanPurposeIdChange()'
                    [options]='loanPurposesFiltered' name='ls_loanPurposeId' [required]='true'
                    [optionValueKey]="'loanPurposeId'" [optionNameKey]="'loanPurposeName'"
                    [placeholder]="'Loan Purpose'"></select-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Loan Type</div>
                <div class="col-6">
                  <select-input [(ngModel)]="application.loanTypeId" [options]="loanTypesFiltered" name="ls_loanTypeId"
                    [required]="true" [optionValueKey]="'loanTypeId'" (blur)="updateMortgageAppliedFor()"
                    [optionNameKey]="'loanTypeName'" [placeholder]="'Loan Type'"></select-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Property Occupancy</div>
                <div class="col-6">
                  <select-input [(ngModel)]="mortgage.subjectProperty.propertyWillBe" [options]="propertyOccupancyTypes"
                    name="ls_propertyOccupancy" [placeholder]="'Property Occupancy'"></select-input>

                </div>
              </div>

              <div class="row py-1">
                <div class="col-6">Construction Method</div>
                <div class="col-6">
                  <select-input [(ngModel)]="mortgage.subjectProperty.constructionMethod"
                    [options]="constructionMethodOptions" [ngbTooltip]="missingConstructionMethod"
                    class="inline-editor-text-wrapper" name="ls_constructionMethod"
                    [placeholder]="'Construction Method'"></select-input>
                  <ng-template #missingConstructionMethod>
                    {{ constructionMethodNamesByKey[mortgage.subjectProperty.constructionMethod]}}
                  </ng-template>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Loan FICO</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0">
                    <span *ngIf="ficoOnFile" class="fw-bold">{{ficoOnFile}}</span>
                    <span *ngIf="!ficoOnFile">--</span>
                  </span>
                </div>
              </div>

              <div class="row py-1">
                <div class="col-6">Loan Product</div>
                <div class="col-6">
                  <select-input [(ngModel)]="application.productPricing.productId"
                    (blur)="selectProduct(application.productPricing.productId)" [optionValueKey]="'productId'"
                    [optionNameKey]="'productName'" [options]="products" name="ls_loanProduct"
                    [placeholder]="'Loan Product'" [disabled]="application.productPricing.assignDate"></select-input>
                </div>
              </div>

              <div class="row py-1 lender">
                <div class="col-6">Lender</div>
                <div class="col-6">
                  <span class="d-flex justify-content-start align-items-center">
                    <select-input class="d-inline-block w-100" [(ngModel)]='application.lenderId'
                      [ngStyle]="{flexGrow: isLenderSelectCollapsed ? 0 : 1}"
                      (editStateChange)="isLenderSelectCollapsed = !$event" [optionValueKey]="'lenderId'"
                      [optionNameKey]="'name'" [options]="lenders" name="ls_lender"
                      [placeholder]="'Lender'"></select-input>
                    <button *ngIf="isLenderSelectCollapsed && application.lenderId" (click)="showLenderInfoDialog()"
                      class="btn d-flex justify-content-center align-items-center ms-1 p-0" type="button">
                      <i class="fas fa-info-circle" style="color: var(--ck-color-button-on-color)"></i>
                    </button>
                  </span>
                </div>
              </div>

              <div class="row py-1">
                <div class="col-6">Interest Rate / APR</div>
                <div class="col-6">
                  <span class="d-flex justify-content-start align-items-center">
                    <i *ngIf="application &&
                    (
                      (application.productPricing && application.productPricing.lockStatus && application.productPricing.lockStatus == 'Accepted') ||
                      (!application.productPricing?.lockStatus && hasRateLockExpirationKeyDate)
                    )" class="fas fa-lock me-1" style="color: orange"></i>
                    <i *ngIf="application &&
                    (
                      (application.productPricing && application.productPricing.lockStatus &&  application.productPricing.lockStatus != 'Accepted') ||
                      (!application.productPricing?.lockStatus && !hasRateLockExpirationKeyDate)
                    )" class="fas fa-lock-open me-1" style="color: orange"></i>
                    <percent-input class="d-inline-block w-100" [inlineTextClass]="'dark-success'"
                      [rateIsTrueToTextValue]="true" [(ngModel)]="application.productPricing.rate"
                      name="ls_pp_interestRate" [digitsInfo]="'1.3-3'" [placeselect-inputholder]="'Rate'"
                      [disabled]="application.productPricing.assignDate"
                      (blur)="onProductPricingRateChanged()"></percent-input>
                    <span class="mx-2">/</span>
                    <percent-input class="d-inline-block w-100" [inlineTextClass]="'text-secondary'"
                      [rateIsTrueToTextValue]="true" [(ngModel)]="application.productPricing.apr" name="ls_apr"
                      [digitsInfo]="'1.3-3'" [placeholder]="'APR'"></percent-input>
                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Lock Expiration</div>
                <div class="col-6">
                  <span style="font-weight: bold !important;"> {{ lockExpirationDateString }} </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Comp Type</div>
                <div class="col-6">
                  <span class='d-flex justify-content-start align-items-center'>
                    <select-input class='d-inline-block w-100' [(ngModel)]='application.productPricing.compType'
                      [ngStyle]='{flexGrow: isCompTypeSelectCollapsed ? 0 : 1}'
                      (editStateChange)='isCompTypeSelectCollapsed = !$event' [optionValueKey]="'value'"
                      [optionNameKey]="'name'" [options]='compTypes' name='ls_compType'
                      [placeholder]="'Comp Type'"></select-input>
                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Compensation</div>
                <div class="col-6">
                  <span>
                    <percent-input class="d-inline-block" [disabled]="application.productPricing.assignDate"
                      [(ngModel)]="application.productPricing.comp" name="ls_comp" [digitsInfo]="'1.3-3'"
                      [placeholder]="'Compensation'">
                    </percent-input>
                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Margin</div>
                <div class="col-6">
                  <span>
                    <number-input class="inline-editor-text-wrapper" [disabled]="application.productPricing.assignDate"
                      [(ngModel)]="application.productPricing.margin" name="ls_margin" [decimals]="'3'"
                      [placeholder]="'Margin'">
                    </number-input>
                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Rebate</div>
                <div class="col-6">
                  <span>
                    <number-input class="d-inline-block" [disabled]="application.productPricing.assignDate"
                      [(ngModel)]="application.productPricing.rebate" name="ls_rebate" [decimals]="'3'"
                      [allowNegative]="true" [placeholder]="'Rebate'"></number-input></span>
                </div>
              </div>

            </div>

            <div class="col-md-6">
              <div class="row py-1">
                <div class="col-6">DTI (front / back)</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0 fw-bold">
                    <span [ngClass]="{
                      'dti-danger': mortgage.transactionDetail?.frontEndDti > 47,
                      'dti-warning':
                        mortgage.transactionDetail?.frontEndDti > 43 &&
                        mortgage.transactionDetail?.frontEndDti <= 47,
                      'dti-success': mortgage.transactionDetail?.frontEndDti <= 43
                    }">
                      {{
                      mortgage.transactionDetail?.frontEndDti > 999
                      ? '--'
                      : (mortgage.transactionDetail?.frontEndDti || 0 | number : '1.0-3')
                      }}%
                    </span>
                    /
                    <span [ngClass]="{
                      'dti-danger': mortgage.transactionDetail?.backEndDti > 47,
                      'dti-warning':
                        mortgage.transactionDetail?.backEndDti > 43 &&
                        mortgage.transactionDetail?.backEndDti <= 47,
                      'dti-success': mortgage.transactionDetail?.backEndDti <= 43
                    }">
                      {{
                      mortgage.transactionDetail?.backEndDti > 999
                      ? '--'
                      : (mortgage.transactionDetail?.backEndDti || 0 | number : '1.0-3')
                      }}%
                    </span>
                  </span>
                </div>
              </div>

              <div class="row py-1">
                <div class="col-6">LTV</div>
                <div class="col-6">
                  <percent-input [(ngModel)]="requestedLtv" [rateIsTrueToTextValue]="true" name="ls_ltv"
                    [digitsInfo]="'1.3-3'" (blur)="onRequestedLtvChanged()" [placeholder]="'LTV'"></percent-input>
                </div>
              </div>

              <div class="row py-1">
                <div class="col-6">CLTV</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0">
                    <span *ngIf="mortgage.transactionDetail?.cltv" class="fw-bold">{{(mortgage.transactionDetail?.cltv /
                      100 | percent : '1.3-3') || '--'}}</span>
                  </span>
                </div>

              </div>
              <div class="row py-1">
                <div class="col-6">Monthly Debt</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0 text-warning">
                    <span *ngIf="totalMonthlyDebts" class="fw-bold">{{totalMonthlyDebts | currency}}</span>
                    <span *ngIf="!totalMonthlyDebts">--</span>
                  </span>
                </div>
              </div>

              <div class="row py-1">
                <div class="col-6">Unpaid Balance</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0">
                    <span *ngIf="unPaidBalanceTotal" class="fw-bold">{{unPaidBalanceTotal | currency}}</span>
                    <span *ngIf="!unPaidBalanceTotal">--</span>
                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Monthly Income</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0 text-success">
                    <span *ngIf="incomeTotal" class="fw-bold">{{incomeTotal | currency}}</span>
                    <span *ngIf="!incomeTotal">--</span>
                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Current Housing</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0">
                    <span *ngIf="currentHousingExpenseTotal" class="fw-bold">{{currentHousingExpenseTotal |
                      currency}}</span>
                    <span *ngIf="!currentHousingExpenseTotal">--</span>
                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Total Monthly Payments</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0">

                    <span *ngIf="housingPaymentsTotal" class="fw-bold">{{housingPaymentsTotal | currency}}</span>
                    <span *ngIf="!housingPaymentsTotal">--</span>

                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Proposed Housing</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0">
                    <span *ngIf="proposedHousingExpenseTotal" class="fw-bold">{{proposedHousingExpenseTotal |
                      currency}}</span>
                    <span *ngIf="!proposedHousingExpenseTotal">--</span>
                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Total Monthly PITI</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0">
                    <span *ngIf="totalPiTi" class="fw-bold">{{totalPiTi | currency}}</span>
                    <span *ngIf="!totalPiTi">--</span>
                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Total Assets Value</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0">
                    <span *ngIf="assetTotal" class="fw-bold">{{assetTotal | currency}}</span>
                    <span *ngIf="!assetTotal">--</span>

                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Cash from/To Borrower</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0" [ngClass]="{
                    'text-danger': ftcDetails?.cashFromToBorrower < 0,
                    'text-success': ftcDetails?.cashFromToBorrower > 0
                  }">
                    <span *ngIf="ftcDetails?.cashFromToBorrower" class="fw-bold">
                      {{ftcDetails?.cashFromToBorrower | currency}}
                    </span>
                    <span *ngIf="!ftcDetails?.cashFromToBorrower">--</span>
                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Reserves (months)</div>
                <div class="col-6">
                  <number-input [allowNegative]="false" [(ngModel)]="mortgage.transactionDetail.reserves"
                    name="ls_monthReserves" [placeholder]="'Month Reserves'">
                  </number-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Estimated Closing Date</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0">
                    <span *ngIf="application.estimatedClosingDate" class="fw-bold">{{application.estimatedClosingDate |
                      date: 'MM/dd/yyyy'}}</span>
                    <span *ngIf="!application.estimatedClosingDate">--</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card" id="mortgageInfo">
        <div class="card-header">
          <h4 class="card-title"><i class="fas fa-file-contract me-2"></i> Mortgage Info</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="row py-1" *ngIf="!isRefi">
                <div class="col-6">Property Purchase Price</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0"
                    *ngIf="isRefi; else purchasePriceAmountInput">
                    --
                  </span>
                  <ng-template #purchasePriceAmountInput>
                    <currency-input [(ngModel)]="mortgage.transactionDetail.purchasePriceAmount"
                      (blur)="calculateTotalDue(); onPurchasePriceAmountChanged()" name="ls_propertyPurchasePrice"
                      [placeholder]="'Property Purchase Price'"></currency-input>
                  </ng-template>
                </div>
              </div>
              <div class="row py-1" [hidden]="!!mortgage.mortgageTerm.appraisedValue">
                <div class="col-6">Estimated Value</div>
                <div class="col-6">
                  <currency-input [(ngModel)]="mortgage.subjectProperty.presentValue" name="ls_estPropertyValue"
                    (blur)="onAppraisedPropertyValueChanged()"
                    [placeholder]="'Estimated Property Value'"></currency-input>
                </div>
              </div>
              <div class="row py-1" [hidden]="!mortgage.mortgageTerm.appraisedValue">
                <div class="col-6">Appraised Value</div>
                <div class="col-6">
                  <currency-input [(ngModel)]="mortgage.mortgageTerm.appraisedValue" name="ls_appraisedPropertyValue"
                    (blur)="onAppraisedPropertyValueChanged()"
                    [placeholder]="'Appraised Property Value'"></currency-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Base Loan Amount</div>
                <div class="col-6">
                  <currency-input [(ngModel)]="mortgage.mortgageTerm.amount" name="ls_baseLoanAmount"
                    [allowDecimals]="false" (blur)="onBaseLoanAmountChanged()"
                    [placeholder]="'Base Loan Amount'"></currency-input>
                </div>
              </div>

              <div class="row py-1">
                <div class="col-6">Subordinate Lien Amount</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0">

                    <span *ngIf="mortgage.transactionDetail.subordinateLienAmount"
                      class="fw-bold">{{mortgage.transactionDetail.subordinateLienAmount | currency}}</span>
                    <span *ngIf="!mortgage.transactionDetail.subordinateLienAmount">--</span>

                  </span>
                </div>
              </div>

              <div class="row py-1">
                <div class="col-6">Lien Position</div>
                <div class="col-6">
                  <select-input [(ngModel)]="mortgage.mortgageTerm.lienPosition" [options]="lienPositionTypes"
                    name="ls_lienPosition" [placeholder]="'Lien Position'"></select-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Total Loan Amount</div>
                <div class="col-6">
                  <currency-input [(ngModel)]="mortgage.mortgageTerm.totalLoanAmount" name="ls_totalLoanAmount"
                    [disabled]="true" [allowDecimals]="false" [placeholder]="'Total Loan Amount'">
                  </currency-input>
                </div>
              </div>
              <div class="row py-1" *ngIf="!isRefi">
                <div class="col-6">Down Payment</div>
                <div class="col-6">
                  <currency-input [(ngModel)]="downPayment" name="ls_downpaymentAmount" [allowDecimals]="false"
                    [placeholder]="'Down Payment Amount'" (blur)="onDownpaymentAmountChanged()">
                  </currency-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Amortization Type</div>
                <div class="col-6">
                  <select-input [(ngModel)]="mortgage.mortgageTerm.amortizationType" [options]="amortizationTypes"
                    name="ls_amortizationType" [placeholder]="'Amortization Type'"></select-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Term (Months)</div>
                <div class="col-6">
                  <number-input [allowNegative]="false" [(ngModel)]="mortgage.mortgageTerm.noOfMonths"
                    name="ls_noOfMonths" (blur)="onMortgageTermChanged()"
                    [placeholder]="'Term (Months)'"></number-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Mortgage Purpose</div>
                <div class="col-6">
                  <select-input [(ngModel)]="mortgage.subjectProperty.purposeOfLoan" [options]="loanPurposeOptions"
                    name="ls_loanPurpose" [required]="true" (blur)="onMortgagePurposeOfLoanChanged()"
                    [placeholder]="'Loan Purpose'">
                  </select-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Mortgage Type</div>
                <div class="col-6">
                  <select-input [(ngModel)]="mortgage.mortgageTerm.mortgageAppliedFor"
                    [options]="mortgageAppliedForTypes" name="ls_mortgageAppliedFor" (blur)="productTypeChanged()"
                    [placeholder]="'Mortgage Type'"></select-input>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row py-1">
                <div class="col-6">Interest Rate</div>
                <div class="col-6">
                  <percent-input [(ngModel)]="mortgage.mortgageTerm.interestRate" [rateIsTrueToTextValue]="true"
                    [disabled]="application.productPricing.assignDate" name="ls_mortgageInterestRate"
                    [digitsInfo]="'1.3-3'" (blur)="onInterestRateChanged()"
                    [placeholder]="'Interest Rate'"></percent-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Qualifying Rate</div>
                <div class="col-6">
                  <percent-input [(ngModel)]="mortgage.ausData.armQualifyingRate" name="ls_armQualifyingRate"
                    [rateIsTrueToTextValue]="true" [placeholder]="'Qualifying Rate'"></percent-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Interest Only (Months)</div>
                <div class="col-6">
                  <number-input [allowNegative]="false" [(ngModel)]="mortgage.extension.interestOnlyTermMonths"
                    name="ls_interestOnlyTermMonths" [placeholder]="'Interest Only (Months)'"></number-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Initial Fixed Period (Months)</div>
                <div class="col-6">
                  <number-input [allowNegative]="false" [(ngModel)]="mortgage.subjectProperty.constructionPeriodMonths"
                    name="ls_constructionPeriodMonths" [placeholder]="'Initial Fixed Period (Months)'"></number-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">ARM Margin</div>
                <div class="col-6">
                  <number-input [(ngModel)]="mortgage.ausData.armIndexMargin" name="ls_armIndexMargin"
                    [placeholder]="'ARM Margin'" [decimals]="'2'"></number-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">ARM Index</div>
                <div class="col-6">
                  <number-input [(ngModel)]="mortgage.ausData.armIndexCurrent" name="ls_armIndexCurrent"
                    [placeholder]="'ARM Index'" [decimals]="'2'"></number-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">MI Rate</div>
                <div class="col-6">
                  <percent-input [(ngModel)]="
                    mortgage.governmentLoanDetail.fhaMIRenewalRatePercent
                  " name="ls_miRate" [digitsInfo]="'1.3-3'" [placeholder]="'MI Rate'"></percent-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Monthly MI</div>
                <div class="col-6">
                  <span class="form-control border-0 bg-transparent display-inline px-0">
                    <span *ngIf="mortgage.proposedHousingExpense.mortgageInsurance"
                      class="fw-bold">{{mortgage.proposedHousingExpense.mortgageInsurance | currency}}</span>
                    <span *ngIf="!mortgage.proposedHousingExpense.mortgageInsurance">--</span>
                  </span>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Document Type</div>
                <div class="col-6">
                  <select-input [(ngModel)]="mortgage.subjectProperty.refinanceProgram"
                    [options]="governmentRefinanceTypes" name="ls_governmentRefinanceTypes"
                    [placeholder]="'Document Type'"></select-input>
                </div>
              </div>
              <div class="row py-1">
                <div class="col-6">Prepay Penalty</div>
                <div class="col-6">
                  <select-input [(ngModel)]="mortgage.extension.isPenaltyChargedInEventOfPrepayment"
                    [options]="yesNoOptions" name="ls_isPenaltyChargedInEventOfPrepayment"
                    [placeholder]="'Prepay Penalty'"></select-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card" id="customInfo">
        <div class="card-header">
          <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-key me-2"></i> Custom Info</h4>
        </div>
        <div class="card-body">
          <div class="row">
            <custom-data-info
              [application]="application"
              [userType]="'TPO'"
              [customData]="customData"
              [isInClassicEditMode]="true"
              [labelAlignment]="'left'">
            </custom-data-info>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="isUsedAsChildComponent" class="modal-footer w-100">
    <div class="d-flex justify-content-end">
      <button type="button" class="btn btn-secondary me-2" (click)="onCloseDrawer()">
        <i class="fa fa-close me-1"></i> Cancel
      </button>
      <button type="button" class="btn btn-primary" (click)="saveLoanInfo()">
        <span><i class="fa fa-save me-1"></i> Save</span>
      </button>
    </div>
  </div>
</ng-container>
<loading-indicator *ngIf="isLoadingLoanData"
  [loadingMessage]="'Loading loan summary, please wait...'"></loading-indicator>
