import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MultiSelectModule } from "primeng/multiselect";
import { SharedModule } from "src/app/shared/shared.module";
import { AgentTagsComponent } from "./agent-tags/agent-tags.component";
import { AgentInfoComponent } from "./agent-info.component";

@NgModule({
  declarations: [
    AgentInfoComponent,
    AgentTagsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MultiSelectModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    AgentInfoComponent,
  ]
})
export class AgentInfoModule {}