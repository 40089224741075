import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs';
import { SystemLevel } from 'src/app/models';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from 'src/app/services/system-level.service';

@Component({
  selector: 'app-integration-container',
  templateUrl: './integration-container.component.html'
})
export class IntegrationContainerComponent implements OnInit {
  systemLevel: SystemLevel;
  isLoading: boolean;

  constructor(
    private readonly _notificationService: NotificationService,
    private readonly _systemLevelService: SystemLevelService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this._systemLevelService.getSystemLevel()
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: (data: SystemLevel) => {
          this.systemLevel = JSON.parse(JSON.stringify(data));
        },
        error: (err) => {
          this._notificationService.showError(`${err?.message || 'Unable to fetch System Level Info.'}`, 'System Level');
        }
      });
  }
}
