<div class="row" cdkDropListGroup>
    <div class="col-md-8 p-5 pe-3">
        <div class="d-flex ps-3 align-items-center mb-3">
            <span>Role Type: </span>
            <div class="ms-2">
                <select class="form-select" [(ngModel)]="roleType" (change)="roleTypeChanged()">
                    <option value="Admin">Admin</option>
                    <option value="TPO">TPO</option>
                </select>
            </div>
        </div>
        <div class="card h-100 py-2">
            <div class="row m-0 align-items-center">
                <div class="col-md-6" *ngFor="let customDataFieldConfig of customDataFieldConfigsLayout | keyvalue">
                    <div class="box m-1">
                        <span class="me-2">{{customDataFieldConfig.key}}.</span>
                        <div cdkDropList
                            class="list w-100 h-100" style="min-height: 20px"
                            [cdkDropListData]="customDataFieldConfig.value"
                            (cdkDropListDropped)="drop($event)"
                            [cdkDropListEnterPredicate]="dropListEnterPredicate(customDataFieldConfig.value)">
                            <div class="d-flex" *ngFor="let customDataField of customDataFieldConfig.value" [cdkDragData]="customDataField" cdkDrag>
                                <span class="p-element pi pi-bars p-datatable-reorderablerow-handle me-2 align-self-center"></span>
                                <span class="text-ellipsis cell-mw225">{{customDataField.fieldName}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 p-5 ps-3">
        <div class="fieldset-title mb-3">
            Custom Fields
        </div>
        <div class="card p-1 h-100">
            <div class="box border-0 h-100">
                <div cdkDropList
                    [cdkDropListData]="filteredCustomDataFieldConfigModels"
                    class="list w-100 h-100" style="min-height: 100px"
                    (cdkDropListDropped)="drop($event)">
                    <div class="py-2 border-bottom" *ngFor="let customDataFieldConfig of filteredCustomDataFieldConfigModels">
                        <div class="d-flex" [cdkDragData]="customDataFieldConfig" cdkDrag>
                            <span class="p-element pi pi-bars p-datatable-reorderablerow-handle me-2 align-self-center"></span>
                            <span class="text-ellipsis cell-mw225">{{customDataFieldConfig.fieldName}}</span>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>

<div class="text-end px-5">
    <div class="col-md-12 my-1 mx-5">
      <hr />
    </div>
    <button type="submit" class="btn btn-primary right" [disabled]="isSaving" (click)="save()">
      <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
      <span *ngIf="isSaving">
        <span class="spinner-border spinner-border-sm"></span> Saving
      </span>
    </button>
  </div>