import { Injectable } from "@angular/core";
import { CreditCredentialsConfig } from "./credit-credentials-config";
import { DuCreditProvider, IProvideCreditCredentialsConfig } from "./credit-credentials-config-provider.factory";
import { CreditConfigService } from "../../global-admin/credit-config/services/credit-config.service";
import { firstValueFrom } from "rxjs";
import { GlobalCreditConfig } from "../../global-admin/credit-config/models/global-credit-config.model";

@Injectable()
export class DeephavenCreditCredentialsConfigProvider implements IProvideCreditCredentialsConfig {

  private _globalCreditConfig: GlobalCreditConfig[] = null;

  constructor(private readonly _creditConfigService: CreditConfigService) {}

  async getDuCreditProviders(): Promise<DuCreditProvider[]> {
    console.log(this._globalCreditConfig)
    if (!this._globalCreditConfig) {
      this._globalCreditConfig = await firstValueFrom(this._creditConfigService.getGlobalCreditConfigList())
    }
    return Promise.resolve(this._globalCreditConfig.map(x => ({ displayName: x.displayName, providerIdentifier: x.displayName })));
  }

  async getCreditCredentialsConfig(): Promise<CreditCredentialsConfig> {
    if (!this._globalCreditConfig) {
      this._globalCreditConfig = await firstValueFrom(this._creditConfigService.getGlobalCreditConfigList())
    }
    const config = new CreditCredentialsConfig();
    config.creditProvidersMappings = this._globalCreditConfig
    config.createDuCredentials = true;
    return config;
  }

  getCreditConfig(providerIdentifier: string): GlobalCreditConfig {
    return this._globalCreditConfig.find(x => x.displayName === providerIdentifier);
  }
}