import { Component, Injector, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApplicationContext } from 'src/app/models';
import { AmortizationService } from '../../../services/amortization.service';
import { NotificationService } from '../../../services/notification.service';
import { ApplicationContextBoundComponent } from '../../../shared/components';
import { UrlaMortgage } from '../../urla/models/urla-mortgage.model';
import { AmortizationModel } from '../models/amortization.model';

@Component({
  selector: 'amortization',
  templateUrl: 'amortization.component.html',
  styleUrls: ['./amortization.component.scss'],
})

export class AmortizationComponent extends ApplicationContextBoundComponent implements OnInit {

  isLoading: boolean = true;
  isSaving: boolean = false;
  
  loanId: number;
  mortgageId: number;

  amortizationInfo: AmortizationModel;
  mortgage: UrlaMortgage;

  initialRateChange: number;
  subsequentRateChange: number;
  subsequentRateChangeFrequency: number;

  private _loanInfoChangesSubscription: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly _amortizationService: AmortizationService,
    private readonly _notificationService: NotificationService
  ) {
    super(injector);
  }

  ngOnInit() {
    if (!this.applicationContext?.application?.applicationId) {
      this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
        if (context.application) {
          this.initializeFromContext(context);
        }
      });
    } else {
      this.initializeFromContext(this.applicationContext);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  getAmortizationInfo() {
    this.isSaving = true;
    this._amortizationService.getAmortizationInfo(this.mortgageId, this.initialRateChange, this.subsequentRateChange, this.subsequentRateChangeFrequency)
      .subscribe((result) => {
        this.amortizationInfo = result;
        this.isLoading = false;
        this.isSaving = false;
      },
        ({ error }) => {
          this._notificationService.showError(
            error ? error.message : "Couldn't get Amortization Info.",
            'Amortization'
          );
          this.isLoading = false;
          this.isSaving = false;
        });
  }

  private initializeFromContext = (context: ApplicationContext) => {
    this.loanId = context.application.applicationId;
    this.mortgageId = context.application.mortgageId;
    this.mortgage = context.currentMortgage;
    this.getAmortizationInfo();
  }
}
