import { LookupGroupEnum } from '../lookup.model';

export enum TaskStatus {
  Pending = LookupGroupEnum.LoanDocStatus,
  Submitted = 401,
  Rejected = 402,
  Approved = 403,
  NotApplicable = 404,
  Requested = LookupGroupEnum.OtherTaskStatus,
  Completed = 422,
  ReviewReady = 423,
  ConditionImportPending = 424,
  CocApproved = 427,
  CocPending = 425,
  CocRejected = 426,
  EscalationPending = 428,
  TpoSubmitted = 429
}
