import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, Subscription } from 'rxjs';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Utils } from 'src/app/core/services/utils';
import { ApplicationContext, AuthenticationRequest, UserType } from 'src/app/models';
import { AuthData } from 'src/app/models/auth/auth-data.model';
import { AuthenticationResponse } from 'src/app/models/auth/authentication-response.model';
import { LoginStatus } from 'src/app/models/auth/login-status.enum';
import { RegisterRequest } from 'src/app/models/auth/register-request.model';
import { AdminService } from 'src/app/services/admin.service';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { AuthService } from 'src/app/services/auth.service';
import { Constants } from 'src/app/services/constants';
import { NavigationService } from 'src/app/services/navigation.service';
import { TermsAndConditionsDialogComponent } from './terms-and-conditions-dialog/terms-and-conditions-dialog.component';
import Swal from 'sweetalert2';
import { Select2OptionData } from 'ng-select2';
import { EnumerationService } from 'src/app/services/enumeration-service';

@Component({
  selector: 'registration',
  templateUrl: 'registration.component.html',
})
export class RegistrationComponent implements OnInit, OnDestroy {
  registrationInfo: RegisterRequest = new RegisterRequest();
  loginInfo: AuthenticationRequest = new AuthenticationRequest();
  registerForm: UntypedFormGroup;

  companyGuid: string;
  username: string;
  token: string;

  termsAndConditionsConfirmation: boolean;

  userActive: boolean;

  errorMessage: string = '';

  done: boolean = false;

  loading: boolean = false;

  possibleLogoUrls: string[];

  companyExist: string;

  companyExistMessage: string;

  companyExistMessageIcon: string;

  isInvite: boolean;

  registerToCompany = false;

  logoUrl = this._environmentService.apiInfo.apiBaseUrl + 'company/co/register';

  errorCheckingRegistrationLink: boolean = false;

  alreadyRegistered: boolean = false;

  registrationButtonLabel: string = 'Register';

  twoFactorCodeRequired: boolean = false;

  protected needToConfirmMfaPhoneNumber: boolean = false;

  protected mfaSetupRequired: boolean = false;

  protected mfaSetupCompleted: boolean = false;

  protected mfaSettings = {
    areaCode: '+1',
    phoneNumber: '',
    verificationCodeForMfaConfigSave: '',
  };

  protected countriesOptions = {
    width: '100%',
    multiple: false,
    closeOnSelect: true,
  };

  protected countries: Array<Select2OptionData> = [];

  private _applicationContextSubscription: Subscription;

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _adminService: AdminService,
    private readonly _navigationService: NavigationService,
    private readonly _modalService: NgbModal,
    private readonly _authService: AuthService,
    private readonly _environmentService: EnvironmentService,
    private readonly _applicationContext: ApplicationContextService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _enumsService: EnumerationService
  ) {}

  ngOnInit() {
    this.possibleLogoUrls = this._navigationService.possibleLogoUrls;

    this._activatedRoute.queryParams.subscribe((queryParams) => {
      this._activatedRoute.params.subscribe((routeParams) => {
        this.registrationInfo.companyGuid = queryParams.companyGuid;
        this.username = queryParams.username;
        this.token = queryParams.token;
        this.companyGuid = queryParams.companyGuid;

        this.countries = this._enumsService.countries.map((val: any) => ({
          id: val.areaCode,
          text: `(${val.areaCode}) ${val.name}`,
          code: val.areaCode,
        }));

        if (this.username !== undefined && this.token !== undefined) {
          this.registrationInfo.email = this.username;
          this.registerToCompany = true;
          this.isInvite = true;
          this._spinner.show();
          this._adminService
            .checkRegister({ userName: this.username, token: this.token })
            .subscribe(
              (response) => {
                this.registrationInfo.email = response.email;
                this.userActive = response.userActive;
                if (this.userActive) {
                  this.registrationButtonLabel = 'Login & Link';
                }
                this.companyExist = 'success';
                this.companyExistMessage =
                  (response.userActive ? 'Link Account' : 'Registration Code') +
                  ' Verified!';
                this.companyExistMessageIcon = 'fa fa-check';
                if (this.userActive) {
                  this.registerForm.removeValidators(
                    this.confirmPasswordValidator
                  );
                  this.registerForm.removeControl('confirmPassword');
                }
              },
              (err) => {
                this.errorCheckingRegistrationLink = true;
                this.companyExistMessage =
                  err && err.error
                    ? err.error
                    : 'Unable to verify registration code.';
                if (
                  this.companyExistMessage
                    .toLocaleLowerCase()
                    .includes('please login')
                ) {
                  this.alreadyRegistered = true;
                }
                this.companyExist = 'warning';
                this.companyExistMessageIcon = 'fas fa-exclamation-triangle';
              }
            )
            .add(() => {
              this._spinner.hide();
            });
        } else {
          this.errorCheckingRegistrationLink = true;
          this.companyExistMessage = 'Error';
          this.companyExist = 'warning';
          this.companyExistMessageIcon = 'fas fa-exclamation-triangle';
          this.isInvite = true;
          this._navigationService.navigateToPath('login');
          return;
        }
      });
    });

    this.registerForm = new UntypedFormGroup(
      {
        email: new UntypedFormControl(this.registrationInfo.email),
        password: new UntypedFormControl(this.registrationInfo.password),
        confirmPassword: new UntypedFormControl(
          this.registrationInfo.confirmPassword
        ),
        twoFactorCode: new UntypedFormControl(this.loginInfo.twoFactorCode),
        termsAndConditions: new UntypedFormControl(
          this.termsAndConditionsConfirmation
        ),
        mfaAreaCode: new UntypedFormControl(this.mfaSettings.areaCode),
        mfaPhoneNumber: new UntypedFormControl(this.mfaSettings.phoneNumber),
        verificationCodeForMfaConfigSave: new UntypedFormControl(this.mfaSettings.verificationCodeForMfaConfigSave),
      },
      { validators: this.confirmPasswordValidator }
    );
  }

  ngOnDestroy() {
    if (this._applicationContextSubscription) {
      this._applicationContextSubscription.unsubscribe();
    }
  }

  // form controls
  get email() {
    return this.registerForm?.get('email');
  }
  get password() {
    return this.registerForm?.get('password');
  }
  get confirmPassword() {
    return this.registerForm?.get('confirmPassword');
  }
  get termsAndConditions() {
    return this.registerForm?.get('termsAndConditions');
  }
  get twoFactorCode() {
    return this.registerForm?.get('twoFactorCode');
  }
  get mfaAreaCode() {
    return this.registerForm?.get('mfaAreaCode');
  }
  get mfaPhoneNumber() {
    return this.registerForm?.get('mfaPhoneNumber');
  }
  get verificationCodeForMfaConfigSave() {
    return this.mfaSettings.verificationCodeForMfaConfigSave;
  }

  onGotoClicked = () => {
    this._navigationService.navigateToLogin(this.companyGuid, false);
  };

  onSendCodeClicked = () => {
    this._spinner.show();
    this._authService
      .sendTwoFactorEmailCode(this.companyGuid)
      .subscribe({
        next: () => {
          Swal.fire(
            'Sent Code',
            'We sent another code to your email. Please check your email and use that code to login.',
            'success'
          );
        },
        error: (err) => {
          this.errorMessage =
            err?.message || 'Unable to re-send the authentication code.';
        },
      })
      .add(() => {
        this._spinner.hide();
      });
  };

  onRegisterClicked = () => {
    this.errorMessage = '';

    if (!this.registerForm) {
      return;
    }
    this.registerForm.markAllAsTouched();

    if (!this.registerForm.valid) {
      return;
    }

    if (this.twoFactorCodeRequired) {
      this.onTwofactorAuthCodeEntered();
      return;
    }

    this.loading = true;
    this._spinner.show();
    this._authService
      .saveRegistration(this.registrationInfo, this.token, this.userActive)
      .subscribe({
        next: (response) => {
          this.done = true;
          this.isInvite = false;

          this.loginInfo.username = this.registrationInfo.email;
          this.loginInfo.password = this.registrationInfo.password;
          this.loginInfo.companyGuid = this.registrationInfo.companyGuid;
          this.loginInfo.userCompanyGuid = response.userCompanyGuid;
          this.loginInfo.rememberMe = false;
          this.loginInfo.scope = response.scope;
          this.loginInfo.twoFactorCode = '';

          this.registrationButtonLabel = 'Registered Successfully!';

          this.signin();
        },
        error: (error) => {
          this._spinner.hide();
          if (typeof error === 'string' && error.indexOf('Failed') > -1) {
            const data = error.split(':')[1];

            if (!data) {
              this.errorMessage = 'Unknown error confirming registration.';
            } else {
              const errors = data.split(',').map((m) => m.trim());

              const errorLookup = {
                PasswordRequiresAlphanumeric:
                  'Password must have at least one alphanumeric character',
                PasswordRequiresNonAlphanumeric:
                  'Password must have at least one non-alphanumeric character',
                PasswordRequiresDigit: 'Password must have at least one digit',
                PasswordRequiresUpper:
                  'Password must have at least one uppercase character',
                PasswordRequiresLower:
                  'Password must have at least one lowercase character',
                PasswordTooShort: 'Password must be at least 8 characters long',
              };

              if (
                Object.keys(errorLookup).some((l) =>
                  errors.some((e) => e === l)
                )
              ) {
                this.errorMessage = errorLookup[errors[0]];
              } else {
                this.errorMessage = 'Unknown error confirming registration.';
              }
            }
          } else if (error.errors) {
            error.errors.password.forEach((e) => {
              this.errorMessage += e + ' ';
            });
          } else if (error) {
            this.errorMessage = error.message;
          } else {
            this.errorMessage = 'Unknown error confirming registration.';
          }

          this.loading = false;
        },
      });
  };

  termsAndConditionsModal = () => {
    const modalRef = this._modalService.open(
      TermsAndConditionsDialogComponent,
      Constants.modalOptions.xlarge
    );

    modalRef.result.then(() => {
      this.termsAndConditionsConfirmation = true;
    });
  };

  protected onSaveNumberClicked() {
    this.loading = true;
    this._authService
      .updatePhoneAnonymous(
        this.mfaSettings.phoneNumber,
        this.mfaSettings.areaCode,
        this.loginInfo
      )
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (res) => {
          this.needToConfirmMfaPhoneNumber = true;
        },
        error: (error) => {
          this.errorMessage = error?.message || 'Unable to update phone number';
        },
      });
  }

  onChangeNumberClicked = () => {
    this.needToConfirmMfaPhoneNumber = false;
  };

  onConfirmPhoneClicked = () => {
    this.loading = true;
    this._authService
      .confirmUpdatePhoneAnonymous(
        this.mfaSettings.phoneNumber,
        this.mfaSettings.areaCode,
        this.verificationCodeForMfaConfigSave,
        this.loginInfo
      )
      .pipe(finalize(() => {}))
      .subscribe({
        next: () => {
          this.mfaSetupCompleted = true;
          this.mfaSetupRequired = false;
          this.signin();
        },
        error: (error) => {
          this.loading = false;
          if (error?.code === 'InvalidToken') {
            this.errorMessage = error.description;
          } else {
            this.errorMessage = `Couldn't process the confirmation code.`;
          }
        },
      });
  };

  private onTwofactorAuthCodeEntered = () => {
    this.signin();
  };

  private signin = () => {
    this.loading = true;
    this._spinner.show();
    this._authService.signIn(this.loginInfo).subscribe({
      next: (authResponse) => {
        if (authResponse.loginStatus === LoginStatus.Success) {
          this._spinner.hide();
          this.loading = false;
          this.persistAuthData(this.loginInfo.companyGuid, authResponse);
          this._applicationContextSubscription =
            this._applicationContext.context.subscribe(this.onAfterLogin);
        } else if (
          authResponse.loginStatus ===
          LoginStatus.TwoFactorAuthenticationRequired
        ) {
          this._spinner.hide();
          this.loading = false;
          this.twoFactorCodeRequired = true;
          this.registrationButtonLabel = 'Continue';
          this.registerForm.markAsUntouched();
        } else if (
          authResponse.loginStatus ===
          LoginStatus.TwoFactorAuthenticationSetupRequired
        ) {
          this._spinner.hide();
          this.registrationButtonLabel = 'Continue';
          this.loading = false;
          this.mfaSetupRequired = true;
          this.registerForm.markAsUntouched();
        }
      },
      error: (error) => {
        this._spinner.hide();
        this.loading = false;
        this.errorMessage =
          error?.message ||
          'Unable to login at the moment, please try again later.';
      },
    });
  };

  private onAfterLogin = (context: ApplicationContext) => {
    if (context.currentlyLoggedInUser.userType === UserType.Tpo) {
      this._navigationService.navigateToPath('tpo');
    } else {
      this._navigationService.navigateToPath('admin/setup-profile');
    }
  };

  private persistAuthData = (
    companyGuid: string,
    authResponse: AuthenticationResponse
  ): void => {
    let jwtPayload = Utils.parseJwt(authResponse.jwtToken);

    const authData: AuthData = {
      companyGuid: companyGuid,
      userCompanyGuid: authResponse.userCompanyGuid,
      token: authResponse.jwtToken,
      userName: authResponse.userName,
      role: jwtPayload.AspNetRole,
      refreshToken: authResponse.refreshToken,
      rememberMe: false,
      companies: authResponse.availableCompanies,
      expiresAt: Utils.getTokenExpireDate(jwtPayload.exp),
    };

    this._localStorageService.authorizationData = authData;
  };

  private _matchStringValidator = (
    group: AbstractControl,
    nameOne: string,
    nameTwo: string
  ): ValidationErrors | null => {
    const firstValue = group.get(nameOne)?.value;
    const secondValue = group.get(nameTwo)?.value;
    return !firstValue || !secondValue || firstValue === secondValue
      ? null
      : { stringDoesntMatch: true };
  };

  private confirmPasswordValidator = (group: AbstractControl) =>
    this._matchStringValidator(group, 'password', 'confirmPassword');
}
