import { NgModule } from '@angular/core';
import { CreditCredentialsConfigurationComponent } from './credit-credentials-configuration.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CreditCredentialsConfigurationDialogComponent } from './credit-credentials-configuration-dialog/credit-credentials-configuration-dialog.component';
import { CreditCredentialsConfigProviderFactory } from './config/credit-credentials-config-provider.factory';
import { DeephavenCreditCredentialsConfigProvider } from './config/deephaven-credit-credentials-config-provider';
import { DefaultCreditCredentialsConfigProvider } from './config/default-credit-credentials-config.provider';

@NgModule({
  declarations: [
    CreditCredentialsConfigurationComponent,
    CreditCredentialsConfigurationDialogComponent,
  ],
  imports: [SharedModule],
  exports: [
    CreditCredentialsConfigurationComponent,
    CreditCredentialsConfigurationDialogComponent,
  ],
  providers: [CreditCredentialsConfigProviderFactory, DeephavenCreditCredentialsConfigProvider, DefaultCreditCredentialsConfigProvider],
})
export class CreditCredentialsConfigurationModule {
  constructor() {}
}
