import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { MenuItem } from 'primeng/api';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { catchError } from 'rxjs/internal/operators/catchError';
import { retry } from 'rxjs/internal/operators/retry';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { LoanService } from 'src/app/services/loan';
import { LosService } from 'src/app/services/los.service';
import { NotificationService } from 'src/app/services/notification.service';
import { LoanValidationResponse, LosPackageConditionReport, LosRequiredValuesReport } from '../../../request-models/loan-validation-response.model';
import { LosDisclosureGenJob, LosDisclosureGenOptions, LosDisclosureInfo, LosDisclosureJobStatus, LosDisclosureSendOptions, LosDisclosureStatus, LosDisclosureType } from '../../../request-models/los-disclosure-gen-job';
import { LosSetFieldRequest } from "../../../request-models/los-set-field-request";

enum LosDisclosureJobTypeEnum {
  audit = "audit",
  generate = "generate",
  send = "send"
}
@Component({
  selector: 'meridian-disclosure-wizard',
  templateUrl: './meridian-disclosure-wizard.component.html',
  styleUrls: ['./meridian-disclosure-wizard.component.scss']
})
export class MeridianDisclosureWizardComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('disclosuresForm') disclosuresForm: NgForm | undefined;

  @Input() hasCancelButton: boolean = false;
  @Input() credentialId: number;
  @Input() applicationId: number;
  @Input() losIdentifier: string;
  @Input() isTpoUser: boolean = false;

  @Output() canceled = new EventEmitter<any>();

  steps: MenuItem[];

  activeStepIndex: number = 0;
  nextButtonLabel: string = "Skip >";

  atFirstStep: boolean = true;
  isStepRunning: boolean = false;
  isWizardCompleted: boolean = false;
  disableNextButton: boolean = false;

  loadingData: boolean;
  auditingLoan: boolean = false;
  isLoanDataValid: boolean = false;

  auditPassedNoRecommendations: boolean = false;

  invalidPackageConditions: LosPackageConditionReport[] = [];

  generatedJob: LosDisclosureGenJob = null;

  disclosureTypes: EnumerationItem[] = [
    new EnumerationItem("Initial", "Initial")
    // ,new EnumerationItem("Closing", "Closing")
  ];

  lockId: string = null;

  selectedDisclosureType: LosDisclosureType = null;
  selectedTab: string = "documents";

  errors: string = null;
  showComplianceReport: boolean = false;

  jobType: LosDisclosureJobTypeEnum = null;

  disclosuresAlreadyProcessed: boolean = false;

  constructor(
    private readonly _losService: LosService,
    private readonly _router: Router,
    private readonly _loanService: LoanService,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    this.steps = [
      { label: 'Audit Loan Data' },
      { label: 'Review Loan Estimate' },
      { label: 'Send Disclosures' },
    ];

    if (this.isTpoUser) {
      this._loanService.getKeyDatesByType(this.applicationId).subscribe(x => {
        if (x.initialDisclosureSent?.eventDate)
          this.disclosuresAlreadyProcessed = true;
        else
          this.syncLosLoanControl();
      });
    }
    else
      this.syncLosLoanControl();
  }

  ngOnDestroy(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setNextButtonLabel();
    });
  }

  onBackClicked = () => {
    this.navigateBackward();
  }

  onNextClicked = () => {
    this.navigateForward().then();
  }

  onCancelClicked = () => {
    if (this.hasCancelButton) {
      this.canceled.emit();
    }
    else {
      this.activeStepIndex = 0;
      this.updateCurrentStepRelatedState();
      this.ngOnInit();
    }
  }

  validateFields = (): boolean => {
    if (this.activeStepIndexWithOffset == 1) {
      if (this.disclosuresForm) {
        this.disclosuresForm.form.markAllAsTouched();
        if (!this.disclosuresForm.form.valid) {
          return false;
        }
      }
    }
    return true;
  }

  navigateForward = async () => {

    if (!this.validateFields()) {
      return;
    }

    this.errors = null;

    if (this.activeStepIndexWithOffset == 1) {
      this.generateDisclosuresJob().then();
    }

    if (this.activeStepIndexWithOffset == 2) {
      this.sendDisclosuresJob().then();
    }

    if (this.steps.length - 1 > this.activeStepIndex) {
      this.activeStepIndex++;
      this.updateCurrentStepRelatedState();
    }
  };

  navigateBackward = () => {
    if (this.activeStepIndex > 0) {
      this.activeStepIndex--;
    }

    this.errors = null;

    if (this.activeStepIndexWithOffset == 1) {
      this.generateDisclosuresJob().then();
    }

    this.updateCurrentStepRelatedState();
  }

  onDisclosureTypeChanged = () => {
    if (this.selectedDisclosureType) {
      this.auditLoanJob();
    }
  }

  skipAndGenerate = () => {
    this.goToNextStep();
  }

  onRetryJob = (type: LosDisclosureJobTypeEnum) => {
    if (type == LosDisclosureJobTypeEnum.audit) {
      this.errors = null;
      this.auditLoanJob();
    }
    else if (type == LosDisclosureJobTypeEnum.generate) {
      this.generatedJob.jobStatus = LosDisclosureJobStatus.Retry;
      this.generatedJob.disclosureInfos.filter(x => x.status == LosDisclosureStatus.Failed).forEach(x => x.status = LosDisclosureStatus.AuditCompleted);
      this.errors = null;
      this.generateDisclosuresJob().then();
    }
    else if (type == LosDisclosureJobTypeEnum.send) {
      this.generatedJob.jobStatus = LosDisclosureJobStatus.Retry;
      this.generatedJob.disclosureInfos.filter(x => x.status == LosDisclosureStatus.Failed).forEach(x => x.status = LosDisclosureStatus.DocGenCompleted);
      this.errors = null;
      this.sendDisclosuresJob().then();
    }
  }

  onProceedToLoanSubmission = () => {
    this._router.navigate([`tpo/app-details/${this.applicationId}/submission`]);
  }

  isRunning = (): boolean => {
    return !!this.lockId;
  }

  private updateCurrentStepRelatedState = () => {
    this.atFirstStep = this.activeStepIndex === 0;
    this.setNextButtonLabel();
  }

  private setNextButtonLabel = () => {
    if (this.activeStepIndexWithOffset === 1) {
      this.nextButtonLabel = "Review Loan Estimate";
    }
    else if (this.activeStepIndexWithOffset === 2) {
      this.nextButtonLabel = "Send Disclosures";
    }
  }

  get activeStepIndexWithOffset() {
    return this.steps.length === 3
      ? this.activeStepIndex + 1
      : this.activeStepIndex;
  }

  private goToNextStep = () => {
    this.updateCurrentStepRelatedState();

    this.isStepRunning = true;
    setTimeout(() => {
      this.isStepRunning = false;
      this.navigateForward().then();
    }, 2000);
  }

  private auditLoanJob = () => {
    this.jobType = LosDisclosureJobTypeEnum.audit;

    this.isStepRunning = true;
    this.auditingLoan = true;
    this.generatedJob = null;

    let options = new LosDisclosureGenOptions();
    options.disclosureType = this.selectedDisclosureType;
    options.losLoanId = this.losIdentifier;

    this._losService.auditDisclosures(this.credentialId, options, this.applicationId, this.lockId)
      .subscribe({
        next: async (job: LosDisclosureGenJob) => {
          this.generatedJob = job;
          this.auditingLoan = false;
          this.isStepRunning = false

          if (this.generatedJob.jobStatus == LosDisclosureJobStatus.Pending) {
            await this.checkJobStatus(LosDisclosureJobTypeEnum.audit);
          }

        },
        error: (err) => {
          this.auditingLoan = false;
          this.isStepRunning = false;
          this.errors = err?.message || "Couldn't get audit disclosure";
        }
      })


  }

  private generateDisclosuresJob = async (): Promise<boolean> => {
    this.jobType = LosDisclosureJobTypeEnum.generate;

    this.isStepRunning = true;
    this.loadingData = true;

    const job = _.cloneDeep(this.generatedJob);

    this.generatedJob = null;
    this.errors = null;

    let isOK = await firstValueFrom(this._losService.generateDisclosures(this.credentialId, job, this.lockId))
      .then(async (job: LosDisclosureGenJob) => {
        this.generatedJob = job;

        this.loadingData = false;
        this.isStepRunning = false;

        let checkingIsOk = true;
        if (this.generatedJob.jobStatus == LosDisclosureJobStatus.Pending) {
          checkingIsOk = await this.checkJobStatus(LosDisclosureJobTypeEnum.generate);
        }

        return checkingIsOk;
      })
      .catch((err) => {
        this.loadingData = false;
        this.isStepRunning = false;

        this.errors = err?.message || "Couldn't generate disclosure";
        return false;
      });

    return isOK;
  }

  private sendDisclosuresJob = async (): Promise<boolean> => {
    this.jobType = LosDisclosureJobTypeEnum.send;

    this.isStepRunning = true;
    this.loadingData = true;
    this.errors = null;

    let job = _.cloneDeep(this.generatedJob);

    let sendOptions = new LosDisclosureSendOptions();
    sendOptions.isFulfillmentEnabled = true;
    sendOptions.daysUntilFulfillmentTriggers = 3;

    job.sendOptions = sendOptions;
    job.disclosureInfos.forEach(x => x.documents.forEach(y => y.downloadedContent = null));

    let isOK = await firstValueFrom(this._losService.sendDisclosures(this.credentialId, job, this.lockId))
      .then(async (res: LosDisclosureGenJob) => {
        for (var i = 0; i < res.disclosureInfos.length; i++) {
          res.disclosureInfos[i].documents = _.cloneDeep(this.generatedJob.disclosureInfos[i].documents);
        }
        this.generatedJob = res;

        this.loadingData = false;
        this.isStepRunning = false;

        let checkingIsOk = true;
        if (this.generatedJob.jobStatus == LosDisclosureJobStatus.Pending) {
          checkingIsOk = await this.checkJobStatus(LosDisclosureJobTypeEnum.send);
        }
        return checkingIsOk;
      })
      .catch((err) => {
        this.loadingData = false;
        this.isStepRunning = false;

        this.errors = err?.message || "Couldn't send disclosure";
        return false;
      });

    return isOK;
  }

  private checkJobStatus = async (type: LosDisclosureJobTypeEnum): Promise<boolean> => {
    let stopped = false;

    this.errors = null;
    this.loadingData = true;

    let isOk = true;

    try {
      while (!stopped) {
        if (type == LosDisclosureJobTypeEnum.audit) {
          this.generatedJob = await firstValueFrom(this._losService.checkDisclosureAuditStatus(this.credentialId, this.generatedJob, this.lockId)) as LosDisclosureGenJob;
        }
        else if (type == LosDisclosureJobTypeEnum.generate) {
          this.generatedJob = await firstValueFrom(this._losService.checkDisclosureGenStatus(this.credentialId, this.generatedJob, this.lockId)) as LosDisclosureGenJob;
        }
        else if (type == LosDisclosureJobTypeEnum.send) {
          let job = _.cloneDeep(this.generatedJob);
          job.disclosureInfos.forEach(x => x.documents.forEach(y => y.downloadedContent = null));
          let res = await firstValueFrom(this._losService.checkDisclosureSendStatus(this.credentialId, job, this.lockId)) as LosDisclosureGenJob;
          for (var i = 0; i < res.disclosureInfos.length; i++) {
            res.disclosureInfos[i].documents = _.cloneDeep(this.generatedJob.disclosureInfos[i].documents);
          }
          this.generatedJob = res;
        }
        this.lockId = this.generatedJob.lockId || this.lockId;
        if (
          this.generatedJob.jobStatus != LosDisclosureJobStatus.Pending ||
          this.generatedJob.disclosureInfos.every(dis => dis.status != this.getDisclosurePendingStatus(type))
        ) {
          stopped = true;

          if (type == "send") {
            if (this.generatedJob.jobStatus == LosDisclosureJobStatus.Completed) {
              this._applicationContextService.updateLoanTasks();
              this._applicationContextService.updateApplicationTrackingStatuses();
              this.lockId = null;
              this._losService.pullFromLos(this.applicationId).subscribe(res => {
                this._applicationContextService.updateMortgageAndApplication(res.application?.mortgageLoan, res.application, res.customData);
                this.loadingData = false;
                this.isWizardCompleted = true;
              }, e => {
                this.loadingData = false;
                this.isWizardCompleted = true;
              })
            }
            else {
              this.loadingData = false;
              this.errors = this.generatedJob.errors ?? "Send Failed. Please click retry.";
              this._applicationContextService.updateApplicationTrackingStatuses();
            }
          }
          else if (type == "audit") {
            this.auditPassedNoRecommendations = !this.thereAreRecommendedFieldValuesInAudits(this.generatedJob) &&
              !this.thereAreRequiredFieldValuesInAudits(this.generatedJob) &&
              this.generatedJob.jobStatus != "Failed";

            this.loadingData = false;

            if (this.auditPassedNoRecommendations) {
              this.goToNextStep();
            }
          }
          else {
            this.loadingData = false;
          }
        }
        await new Promise(resolve => setTimeout(resolve, 1000)); // wait 1 sec
      }
    }
    catch (error) {
      this.errors = error.message;
      this.loadingData = false;
      isOk = false;
    }
    return isOk;
  }

  protected thereAreRequiredFieldValuesInAudits = (res: LosDisclosureGenJob): boolean => {
    return !!res.disclosureInfos.some(i => i.dataAuditRuleMessages && i.dataAuditRuleMessages.filter(x => x.severity == 'Critical' || x.severity == 'Fatal').length);
  }

  protected thereAreRecommendedFieldValuesInAudits = (res: LosDisclosureGenJob): boolean => {
    return !!res.disclosureInfos.some(i => i.dataAuditRuleMessages && i.dataAuditRuleMessages.filter(x => x.severity == 'Recommended').length);
  }

  private allDataValid = (res: LoanValidationResponse): boolean => {
    let w = 0;
    let x = 0;
    let y = 0;
    let z = 0;

    w = res.elibilityCheckRuleStatus?.filter(x => !x.passed).length || 0;
    x = res.packageConditionReports?.filter(r => !r.passed).length || 0;
    y = res.validationRulePassReports?.filter(r => !r.passed).length || 0;
    z = res?.planCodePassed ? 0 : 1;

    return (w + x + y + z) == 0;
  }

  private getDisclosurePendingStatus = (type: LosDisclosureJobTypeEnum) => {
    let pendingStatus: LosDisclosureStatus;

    switch (type) {
      case LosDisclosureJobTypeEnum.generate:
        pendingStatus = LosDisclosureStatus.DocGenPending;
        break;
      case LosDisclosureJobTypeEnum.send:
        pendingStatus = LosDisclosureStatus.PackageSent;
        break;
      case LosDisclosureJobTypeEnum.audit:
        pendingStatus = LosDisclosureStatus.AuditPending;
        break;
      default:
        break;
    }

    return pendingStatus;
  }

  protected syncLosLoanControl = () => {
    this.errors = null;
    if (!this.losIdentifier)
      this.errors = this.isTpoUser ? "You must assign pricing before you can generate disclosures." : "You must create an LOS loan before you can generate disclosures.";
    else {
      if (this.isTpoUser) {
        this.loadingData = true;
        this.isStepRunning = true;
        this._losService.autoSyncLosLoan(this.applicationId)
          .pipe(
            retry({ count: 2, delay: 1000 }),
            catchError((err) => {
              this.errors = err?.message || "Couldn't auto sync Los loan";
              this.loadingData = false;
              this.isStepRunning = false;
              throw err;
            }))
          .subscribe(() => {
            this.loadingData = false;
            this.isStepRunning = false;
          })
      }
    }
  }
  protected readonly LosDisclosureJobTypeEnum = LosDisclosureJobTypeEnum;
}
