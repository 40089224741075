import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { LeadsRoutingModule } from './leads-routing.module';
import { LeadsComponent } from './leads.component';
import { LeadMetricsComponent } from './components/lead-metrics/lead-metrics.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { LeadFilterDialogComponent } from './components/dialogs/lead-filter-dialog/lead-filter-dialog.component';
import { ChartModule } from 'primeng/chart';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { AgentsModule } from '../agents/agents.module';
import { CreditModule } from '../loan-services/credit/credit.module';
import { LeadActionsComponent } from './components/dialogs/view-lead-drawer/lead-actions/lead-actions.component';
import { EditCurrentLoanInfoDialogComponent } from './components/dialogs/view-lead-drawer/dialogs/edit-current-loan-info-dialog/edit-current-loan-info-dialog.component';
import { EditEmploymentInfoDialogComponent } from './components/dialogs/view-lead-drawer/dialogs/edit-employment-info-dialog/edit-employment-info-dialog.component';
import { EditCreditDialogComponent } from './components/dialogs/view-lead-drawer/dialogs/edit-credit-dialog/edit-credit-dialog.component';
import { ViewEmploymentHistoryDialogComponent } from './components/dialogs/view-lead-drawer/dialogs/view-employment-history-dialog/view-employment-history-dialog.component';
import { ViewCreditScoresDialogComponent } from './components/dialogs/view-lead-drawer/dialogs/view-credit-scores-dialog/view-credit-scores-dialog.component';
import { ReassignLeadUserConfirmationDialogComponent } from './components/dialogs/reassign-lead-user-confirmation-dialog/reassign-lead-user-confirmation-dialog.component';
import { EmploymentDetailsComponent } from './components/dialogs/view-lead-drawer/dialogs/edit-employment-info-dialog/employment-details/employment-details.component';
import { EmploymentDialogComponent } from './components/dialogs/view-lead-drawer/dialogs/edit-employment-info-dialog/employment-dialog/employment-dialog.component';
import { EmploymentTableComponent } from './components/dialogs/view-lead-drawer/dialogs/edit-employment-info-dialog/employment-table/employment-table.component';
import { LeadEditorComponent } from './components/lead-editor/lead-editor.component';
import { ConfirmLoanPurposeChangeDialogComponent } from './components/lead-editor/dialogs/confirm-loan-purpose-change-dialog/confirm-loan-purpose-change-dialog.component';
import { CreditPullDialogComponent } from './components/lead-editor/dialogs/credit-pull-dialog/credit-pull-dialog.component';
import { ImportLeadFromDuDialogComponent } from './components/lead-editor/dialogs/import-lead-from-du-dialog/import-lead-from-du-dialog.component';
import { LeadAssignmentInfoComponent } from './components/lead-editor/lead-assignment-info/lead-assignment-info.component';
import { LeadCurrentLoanInfoComponent } from './components/lead-editor/lead-current-loan-info/lead-current-loan-info.component';
import { LeadGeneralInfoComponent } from './components/lead-editor/lead-general-info/lead-general-info.component';
import { LeadLeadSourceOrCompaignComponent } from './components/lead-editor/lead-lead-source-or-compaign/lead-lead-source-or-compaign.component';
import { LeadLoanDetailComponent } from './components/lead-editor/lead-loan-detail/lead-loan-detail.component';
import { LeadEmploymentComponent } from './components/lead-editor/lead-employment/lead-employment.component';
import { LeadSubjectPropertyComponent } from './components/lead-editor/lead-subject-property/lead-subject-property.component';
import { LeadBorrowerIncomeComponent } from './components/lead-editor/lead-borrower-income/lead-borrower-income.component';
import { LeadEmploymentIncomeInDetailComponent } from './components/lead-editor/lead-employment-income-in-detail/lead-employment-income-in-detail.component';
import { LeadOtherIncomeInDetailComponent } from './components/lead-editor/lead-other-income-in-detail/lead-other-income-in-detail.component';
import { LeadIncomeDialogComponent } from './components/lead-editor/dialogs/lead-income-dialog/lead-income-dialog.component';
import { LeadEscrowCalculator } from './components/lead-editor/lead-current-loan-info/escrow-calculator/lead-escrow-calculator.component';
import { ViewLeadDrawerComponent } from './components/dialogs/view-lead-drawer/view-lead-drawer.component';
import { TableModule } from 'primeng/table';
import { CreditDialogComponent } from './components/dialogs/view-lead-drawer/dialogs/edit-credit-dialog/credit-dialog/credit-dialog.component';
import { CreditTableComponent } from './components/dialogs/view-lead-drawer/dialogs/edit-credit-dialog/credit-table/credit-table.component';
import { CreditDetailsComponent } from './components/dialogs/view-lead-drawer/dialogs/edit-credit-dialog/credit-details/credit-details.component';
import { TasksModule } from '../tasks/tasks.module';
import { CorrespondenceModule } from '../correspondence/correspondence.module';
import { LeadActivityComponent } from './components/dialogs/view-lead-drawer/lead-actions/lead-activity/lead-activity.component';
import { LeadCustomDataComponent } from './components/dialogs/view-lead-drawer/lead-actions/lead-custom-data/lead-custom-data.component';
import { LeadEmailHistoryComponent } from './components/dialogs/view-lead-drawer/lead-actions/lead-email-history/lead-email-history.component';
import { LeadTasksComponent } from './components/dialogs/view-lead-drawer/lead-actions/lead-tasks/lead-tasks.component';
import { LogAttemptedContactDialogComponent } from './components/dialogs/view-lead-drawer/dialogs/log-attempted-contact-dialog/log-attempted-contact-dialog.component';
import { DuplicateBorrowerEmailDialogComponent } from './components/dialogs/view-lead-drawer/dialogs/duplicate-borrower-email-dialog/duplicate-borrower-email-dialog.component';
import { ViewAgentInfoDialogComponent } from './components/dialogs/view-lead-drawer/dialogs/view-agent-info-dialog/view-agent-info-dialog.component';
import { AppDetailsModule } from '../app-details/app-details.module';
import { LeadTableModule } from '../lead-table/lead-table.module';
import { LeadTableComponent } from '../lead-table/lead-table.component';
import { PowerUserLeadTableComponent } from './components/power-user-lead-table/power-user-lead-table.component';
import { LeadIntervalConfigDialogComponent } from './components/dialogs/lead-interval-config-dialog/lead-interval-config-dialog.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { LoanAmountCalculatorModule } from '../loan-amount-calculator/loan-amount-calculator.module';
import { LeadMilestonesComponent } from './components/lead-milestones/lead-milestones.component';
import { UrlaCommonsModule } from '../urla/common/urla-commons.module';
import { CustomDataInfoModule } from '../custom-data-info/custom-data-info.module';

@NgModule({
  declarations: [
    LeadsComponent,
    LeadMetricsComponent,
    LeadMilestonesComponent,
    LeadFilterDialogComponent,
    LeadEditorComponent,
    LeadAssignmentInfoComponent,
    LeadGeneralInfoComponent,
    LeadLeadSourceOrCompaignComponent,
    LeadSubjectPropertyComponent,
    LeadLoanDetailComponent,
    LeadCurrentLoanInfoComponent,
    CreditPullDialogComponent,
    ViewLeadDrawerComponent,
    ImportLeadFromDuDialogComponent,
    ConfirmLoanPurposeChangeDialogComponent,
    LeadActionsComponent,
    EditCurrentLoanInfoDialogComponent,
    EditEmploymentInfoDialogComponent,
    EditCreditDialogComponent,
    ViewEmploymentHistoryDialogComponent,
    ViewCreditScoresDialogComponent,
    ReassignLeadUserConfirmationDialogComponent,
    EmploymentDialogComponent,
    EmploymentDetailsComponent,
    EmploymentTableComponent,
    CreditDialogComponent,
    CreditDetailsComponent,
    CreditTableComponent,
    LeadActivityComponent,
    LeadEmailHistoryComponent,
    LeadCustomDataComponent,
    LeadTasksComponent,
    LogAttemptedContactDialogComponent,
    DuplicateBorrowerEmailDialogComponent,
    ViewAgentInfoDialogComponent,
    LeadEmploymentIncomeInDetailComponent,
    LeadBorrowerIncomeComponent,
    LeadEmploymentComponent,
    LeadOtherIncomeInDetailComponent,
    LeadIncomeDialogComponent,
    LeadIntervalConfigDialogComponent,
    PowerUserLeadTableComponent,
    LeadEscrowCalculator
  ],
  imports: [
    NgSelectModule,
    CommonModule,
    SharedModule,
    LeadsRoutingModule,
    TableModule,
    MultiSelectModule,
    ChartModule,
    AgentsModule,
    CreditModule,
    TasksModule,
    CorrespondenceModule,
    AppDetailsModule,
    LeadTableModule,
    UrlaCommonsModule,
    NgCircleProgressModule.forRoot({
      radius: 25,
      space: -10,
      outerStrokeWidth: 8,
      outerStrokeColor: "#4882c2",
      outerStrokeGradientStopColor: "#4882c2",
      innerStrokeColor: "#e7e8ea",
      innerStrokeWidth: 8,
      title: "",
      showSubtitle: false,
      animation: true,
      animateTitle: false,
      animationDuration: 1000,
      showUnits: false,
      showBackground: false,
      clockwise: true,
      startFromZero: true,
      showZeroOuterStroke: true
    }),
    PopoverModule.forRoot(),
    LoanAmountCalculatorModule,
    CustomDataInfoModule
  ],
  exports: [
    LeadCustomDataComponent,
    LeadActivityComponent,
    LeadEditorComponent,
    ViewLeadDrawerComponent,
    LeadFilterDialogComponent,
    LeadMetricsComponent,
    LeadMilestonesComponent,
    LeadTableComponent
  ]
})
export class LeadsModule { }
