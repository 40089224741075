import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomDataConfig } from '../../../../../models';
import { EnumItem } from '../../../../../models/enum-item.model';
import { Lookup } from '../../../../app-details/models/lookup.model';
import { NgForm } from '@angular/forms';
import { AppDetailsService } from 'src/app/modules/app-details/services/app-details.service';

@Component({
  selector: 'custom-data-items-modal',
  templateUrl: './custom-data-items-modal.component.html',
})
export class CustomDataItemsModalComponent implements OnInit {
  @ViewChild('customDataItemForm') customDataItemForm: NgForm | undefined;

  customDataConfigModel: CustomDataConfig;
  listTypes: EnumItem[];

  lookupType: string;
  loading: boolean;
  lookupItems: Lookup[];

  constructor(
    private readonly _appService: AppDetailsService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.getLookupItems();
  }

  protected getLookupItems(): void {
    if (this.customDataConfigModel.multiLookupType === 'Custom') {
      const customDropDownOptions = this.customDataConfigModel
        .customDropDownOptions
        ? JSON.parse(this.customDataConfigModel.customDropDownOptions)
        : [];
      this.lookupItems = customDropDownOptions;
    } else {
      this._appService.getLookUps(this.customDataConfigModel.multiLookupType)
        .subscribe((response) => {
        this.lookupItems = response || [];
        this.loading = false; 
      });
    }
  }

  protected onSubmit() {
    if (this.customDataItemForm.invalid) {
      Object.keys(this.customDataItemForm.controls).forEach((key) => {
        const control = this.customDataItemForm.controls[key];
        if (control.invalid) {
          control.markAsTouched();
        }
      });
      return;
    }
    this.activeModal.close(this.lookupItems);
  }
}
