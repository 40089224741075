<div class="modal-header">
  <h5 *ngIf="inEditMode" class="modal-title"> Edit Pricing Vendor {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <h5 *ngIf="!inEditMode" class="modal-title"> Create Pricing Vendor {{(vendor && vendor.vendorName) ? ' : ' +
    vendor.vendorName : ''}} </h5>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()" aria-label="Close"></button>
</div>
<div class="modal-body">
  <div class="row mt-3">
    <div class="col-md-2 text-end">
      <label class="custom-control-label">Url</label>
    </div>
    <div class="col-md-10">
      <input type="text" class="form-control" [(ngModel)]="vendor.url">
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-2 text-end">
      <label class='custom-control-label'>API Key</label>
    </div>
    <div class='col-md-10'>
      <password-input [(ngModel)]='apiKeyItem.value' [name]="'loanpass_api_key'"></password-input>
    </div>
  </div>
  <div class='row mt-3'>
    <div class='col-md-2 text-end'>
      <label class='custom-control-label'>User Accounts</label>
    </div>
    <div class='col-md-10 d-inline-flex align-items-end'>
      <div class="form-check me-4">
        <input type="checkbox" id="allowUserAccounts" class="form-check-input" ToggleValue [true]="'1'" [false]="'0'"
          [(ngModel)]="allowUserAccountsItem.value">
        <label class="form-check-label" for="allowUserAccounts">Allow User Accounts</label>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-4 col-xs-12 text-start">
      <button class="btn btn-outline-primary" (click)="addBusinessChannel()">
        Add Business Channels
      </button>
    </div>
  </div>
  <form #editorForm="ngForm">
    <div class="row mt-3" *ngFor="let item of kvpChannels; let i = index;">
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Business Channel</label>
      </div>
      <div class="col-md-4">
        <select class="form-select" name="businessChannelName_{{i}}" [(ngModel)]="item.key" #bcNameField="ngModel"
          [ngClass]="{'is-invalid': bcNameField && bcNameField.touched && bcNameField.invalid}"
        required>
          <option value="">-- Select One --</option>
          <option *ngFor="let ele of lodaChannels" [ngValue]="ele.name">
            {{ele.name}}
          </option>
        </select>
      </div>
      <div class="col-md-2 text-end">
        <label class="custom-control-label">Pricing Profile</label>
      </div>
      <div class="col-md-3">
        <select class="form-select" name="pricingProfileName_{{i}}" [(ngModel)]="item.value" #pricingProfileNameField="ngModel"
          [ngClass]="{'is-invalid': pricingProfileNameField && pricingProfileNameField.touched && pricingProfileNameField.invalid}"
          required>
          <option value="">-- Select One --</option>
          <option *ngFor="let ele of pricingProfileOptions" [ngValue]="ele.id">
            {{ele.name}}
          </option>
        </select>
      </div>
      <div class="col-md-1">
        <confirm-delete [list]="kvpChannels" [index]="i"></confirm-delete>
      </div>
    </div>
  </form>
  <div class='row mt-3' *ngIf="vendor?.credentialId">
    <div class='col-md-2'>
    </div>
    <div class='col-md-10'>
      <button type="button" class="btn btn-info" (click)="onRepriceFieldsClicked()">
        Field Editor
      </button>
    </div>
  </div>
  <div class="row mt-3" *ngIf="vendor?.credentialId">
    <div class="col-md-2 d-flex align-items-center justify-content-end">
      <label class='custom-control-label'>Mappings</label>
    </div>
    <div class="col-md-10">
      <div class="row">
        <div class="col-md-3">
          <h5>To LoanPass</h5>
          <div>
            <button type="button" class="btn btn-primary" (click)="onFieldsMappingClicked('ToExecuteRequest')">
              Loanpass Pricing Request
            </button>
          </div>
        </div>
        <div class="col-md-9">
          <h5>To Lodasoft</h5>
          <div>
            <button type="button" class="btn btn-primary me-2" (click)="onFieldsMappingClicked('ToProductSearchRequest')">
              Lodasoft Pricing Request
            </button>
            <button type="button" class="btn btn-primary me-2" (click)="onFieldsMappingClicked('ToProduct')">
              Product
            </button>
            <button type="button" class="btn btn-primary" (click)="onFieldsMappingClicked('ToQuote')">
              Quote
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class='row mt-3' *ngIf="vendor?.credentialId">
    <div class='col-md-2 text-end'>
      <label class='custom-control-label'>Custom Fields</label>
    </div>
    <div class='col-md-10'>
      <button type="button" class="btn btn-primary" (click)="onCustomFieldsMappingClicked()">
        Manage
      </button>
    </div>
  </div>
  <div class='row mt-3' *ngIf="vendor?.credentialId">
    <div class='col-md-2 text-end'>
      <label class='custom-control-label'></label>
    </div>
    <div class='col-md-10'>
      <button type="button" class="btn btn-outline-danger" (click)="onResetClicked()">
        Reset All Configuration
      </button>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary right" (click)="saveCredential()">
    <span><i class="fa fa-save"></i> Save </span>
  </button>
</div>
