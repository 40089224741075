import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';
import { set } from 'lodash';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, combineLatest, forkJoin } from 'rxjs';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { Utils } from 'src/app/core/services/utils';
import { ApplicationContext, Borrower, DocFile, LoanApplication, Role, UserPermissions } from 'src/app/models';
import { FileDto } from 'src/app/models/borrower/file-dto.model';
import { LoanDocDashboardTask } from 'src/app/models/borrower/loan-doc-dashboard-task.model';
import { GlobalConfig, TaskPriority } from 'src/app/models/config/global-config.model';
import { TaskMessage } from 'src/app/models/message/task-message.model';
import { TrackingInfo } from 'src/app/models/tracking-info.model';
import { UserProfile } from 'src/app/models/user/user-profile.model';
import { User } from 'src/app/models/user/user.model';
import { BorrowerDto } from 'src/app/modules/contacts/models/borrower-dto.model';
import { InternalContact } from 'src/app/modules/internal-contacts/models/internal-contact.model';
import { InternalContactsService } from 'src/app/modules/internal-contacts/services/internal-contacts.service';
import { ExternalContact } from 'src/app/modules/loan-docs/models/external-contact.model';
import { NotifyPartyLoanContact } from 'src/app/modules/loan-docs/models/notify-party-loan-contact.model';
import { OriginalTask } from 'src/app/modules/loan-docs/models/original-task.model';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { ChannelService } from 'src/app/services/channel.service';
import { FileService } from 'src/app/services/file.service';
import { LoanService } from 'src/app/services/loan';
import { MentionsService } from 'src/app/services/mentions.service';
import { MessageService } from 'src/app/services/message.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TaskService } from 'src/app/services/task.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { Mentionable } from 'src/app/shared/components/message-editor-with-mentions/mentionable.model';
import { MentionsUtils } from 'src/app/shared/services/mentions.utils';
import { Agent } from 'src/app/modules/app-details/models/agent.model';
import { AgentService } from 'src/app/services/agent.service';
import { SharedUtilitiesService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'task-editor-qcu',
  templateUrl: './task-editor-qcu.component.html',
  styleUrls: ['./task-editor-qcu.component.scss'],
})
export class TaskEditorQCUComponent extends ApplicationContextBoundComponent implements OnInit {
  @Input()
  task: LoanDocDashboardTask;

  @Input()
  taskReadonly: boolean;

  @Input()
  refreshMentions: boolean = false;

  @Input()
  borrowers?: BorrowerDto[] = [];

  @Output()
  cancelClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  saveClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  appId: number;

  internalNotes: Array<TaskMessage> = [];

  historyVisible: boolean = false;

  taskTrackingInfo: TrackingInfo[];

  columns: any[];

  taskStatusOptions: any[] = [];

  externalCompanyUsers: UserProfile[] = [];

  branchUsers: UserProfile[] = [];

  internalContacts: InternalContact[] = [];

  externalContacts: ExternalContact[] = [];

  notifyPartyLoanContacts: NotifyPartyLoanContact[] = [];

  globalConfig: GlobalConfig;

  users: User[] = [];

  usersAll: User[] = [];

  roles: Role[];

  taskPriority: TaskPriority[] = [];

  mentionables: Mentionable[] = [];

  dueDayOrDate: string = 'day';

  dueDate: string;

  today: string = new Date().toISOString();

  snoozeDurationInMinutes: number = null;

  willBeSnoozed: boolean = false;

  isRefreshed: boolean = false;

  internalNoteImageProcessStatuses: Map<string, TaskImageProcessStatus> = new Map<string, TaskImageProcessStatus>();

  currentApplication: LoanApplication;

  protected isPRMG: boolean = false;
  protected isLoggedInUserTpo: boolean = false;

  protected secondaryReferralCompanyName: string;
  protected realtorCompanyName: string;
  protected referralSourceName: string;
  protected secondaryReferralSourceName: string;

  private _allAgents: Agent[] = [];

  private _loanInfoChangesSubscription: Subscription;

  private _originalTask = new OriginalTask();

  private _userPermissions: UserPermissions;

  private _internalContactsLoaded: boolean = false;

  private _externalCompanyUsersLoaded: boolean = false;

  optionsMultipleSelect = {
    width: '100%',
    multiple: true,
    theme: 'classic',
    closeOnSelect: false,
    dropdownParent: '#editTaskDialog',
  };

  optionsSelect = {
    width: '100%',
    multiple: false,
    closeOnSelect: true,
    dropdownParent: '#editTaskDialog',
  };

  readonly ngxPlacesOptions: any = {
    types: ['geocode'],
    componentRestrictions: { country: 'us' }
  }

  constructor(
    private readonly injector: Injector,
    private readonly _taskService: TaskService,
    private readonly _messageService: MessageService,
    private readonly _fileService: FileService,
    private readonly _internalContactsService: InternalContactsService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _loanDocsService: LoanDocsService,
    private readonly _loanService: LoanService,
    private readonly _channelService: ChannelService,
    private readonly _mentionsService: MentionsService,
    private readonly _environment: EnvironmentService,
    private readonly _utilityService: SharedUtilitiesService,
    private readonly _agentService: AgentService,
  ) {
    super(injector);
    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe(context => {
      this.initialize(context);
    });
  }

  ngOnInit(): void {
    this.appId = this.task.applicationId;

    this.task.note = '';
    this.task.borrowerFacingNote = '';

    if (this.task.dueDays > 0 && this.task.requestDate) {
      let newDate: any = new Date(this.task.requestDate);
      newDate.setDate(newDate.getDate() + Number(this.task.dueDays));
      this.dueDate = newDate.toISOString();
    } else {
      this.dueDate = this.task.requestDate ? this.task.requestDate : this.today;
    }

    this.columns = [
      { field: 'actionValue', header: 'Action' },
      { field: 'dateCreated', header: 'Date Created' },
      { field: 'by', header: 'By' },
    ];
    this.initializeContextBasedState(this.applicationContext);

    this.initialize(this.applicationContext);
  }
  

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  toggleTaskView = () => {
    this.historyVisible = !this.historyVisible;

    if (this.historyVisible && !this.taskTrackingInfo) {
      this._taskService.getTaskTracking(this.task.loanDocTaskId).subscribe(
        (response) => {
          this.taskTrackingInfo = _.orderBy(response, ['dateCreated'], ['desc']);
        },
        (err) => { }
      );
    }
  }

  onCancelClicked = () => {
    this.cancelClicked.emit(this.isRefreshed);
  }

  onBorrowerDeleted = (loanBorrowers: Borrower[]) => {
    this._loanService.getApplicationModel(this.currentApplication.applicationId, true).subscribe(loanInfoModel => {
      this.applicationContextService.updateMortgageAndApplication(loanInfoModel.mortgageLoan, loanInfoModel, undefined, loanBorrowers);
      this._spinner.hide();
    }, (error) => {
      this._spinner.hide();
      this._notifyService.showError(
        error ? error.message : 'Unable to reload loan',
        'Error!'
      );
    });
  }

  onBorrowerUpdated = (loanBorrowers: Borrower[]) => {
    this.applicationContextService.updateBorrowers(loanBorrowers);
  }

  onUpdatedPrimaryBorrower = (data: {application: LoanApplication, borrowers: Borrower[]}) => {
    this.currentApplication = data.application;
    let mortgage = data.application.mortgageLoan || null; // compatibilty: admin is deployed before api

    this.applicationContextService.updateMortgageAndApplication(mortgage, this.currentApplication, undefined, data.borrowers);
  }


  private initializeContextBasedState = (context: ApplicationContext) => {
    this.globalConfig = context.globalConfig;
    this.users = context.globalConfig.users.map(u => ({
      ...u,
      ['displayName']: Utils.getPersonsDisplayName(u),
    }));
    this.usersAll = context.globalConfig.usersAll;
    this.roles = context.globalConfig.roles;
    this._userPermissions = context.userPermissions;
    this.taskPriority = context.globalConfig.taskPriority;
    if (!this.task.notifyPartyId) {
      this.task.notifyPartyId = '';
    }
  };

  private initialize = (context: ApplicationContext) => {
    this.isPRMG = context.isCompanyPRMG;
    this.isLoggedInUserTpo = context.userPermissions.userType.toString() == 'TPO';

    this.loadTaskNotes();

    const completedStatus =
      this.task.taskStatus == 'Completed' ||
      this.task.taskStatus == 'NotApplicable';

    this.taskReadonly = this.taskReadonly || (this._userPermissions.readOnlyTasks && this.task.userId != this._userPermissions.userId);

    if (this.task.applicationId) {
      if (!context.application) {
        this._loanService.getApplicationModel(this.task.applicationId, false).subscribe(application => {
          this.currentApplication = application;
          this.initializeMentionables();
        });
      } else {
        this.currentApplication = context.application;
        this.initializeMentionables();
      }
      this._loanService
        .getBorrowers(this.task.applicationId)
        .subscribe(borrowers => {
          this.borrowers = borrowers;
          if (this.borrowers) {
            this.borrowers.forEach(borrower => {
              borrower.fullName = borrower.firstName ? borrower.firstName + ' ' : '';
              borrower.fullName += borrower.lastName ? borrower.lastName : '';
            })
          }
        });
    } else {
      this.mentionables = this.mentionables.concat(MentionsUtils.prepareUserMentionables(this._environment.apiInfo.apiBaseUrl,
        this.users));
      this.mentionables.push(MentionsUtils.prepareInternalContactsMentionable());
      this.mentionables.push(MentionsUtils.prepareHereMentionable());
    }

    this.setOriginalTask();
  };

  private loadTaskNotes = () => {
    if (!this.task.loanDocTaskId) {
      return;
    }

    const combined = combineLatest([
      this._messageService.getInternalTaskMessages(this.task.loanDocTaskId),
      this._messageService.getBorrowerTaskMessage(this.task.loanDocTaskId)
    ]);

    combined.subscribe(([internalNotes, borrowerNotes]) => {

      this.internalNotes = internalNotes;

      const originalInternalNotes = _.cloneDeep(internalNotes);

      for (let i = 0; i <= this.internalNotes.length - 1; i++) {
        const note = this.internalNotes[i];
        note.content = MentionsUtils.generateDisplayHtmlWithMentions(note.content);
        note['uniqueId'] = uuidv4();
        originalInternalNotes[i]['uniqueId'] = note['uniqueId'];
        const internalNotesImgCount = this.replaceImagesWithLoaderIcons(note);
        const taskImageProcessingStatus: TaskImageProcessStatus = { numberOfImagesLeftToProcess: internalNotesImgCount, processedContent: note.content }
        this.internalNoteImageProcessStatuses.set(note['uniqueId'], taskImageProcessingStatus);
      }

      originalInternalNotes.forEach(note => {
        note.content = MentionsUtils.generateDisplayHtmlWithMentions(note.content);
        this.generateDisplayHtmlWithImages(note.content, note);
      });

      // refresh mentions from alerts
      if (this.refreshMentions) {
        this._mentionsService.publish({ type: "reload" });
      }
    })
  };


  onMessageChanged = (message: string, noteField: string) => {
    set(this.task, noteField, message);
  };

  private getContactRoles = (channel: string): Role[] => {
    let contactRoles = [];
    if (this._userPermissions.enabledChannels && channel) {
      const enabledChannels = this._channelService.getChannelsFromCommaDelimitedString(this._userPermissions.enabledChannels)
        .map((c) => c.value);
      if (enabledChannels.includes(channel)) {
        this.globalConfig.channelRoles[channel.toLowerCase()].forEach(role => {
          if (role.isLoanContact) {
            const roleChannel = role.roleChannels.find(roleChannel => roleChannel.channel === channel);
            role.orderByChannel = roleChannel.order || null;
            role.order = role.orderByChannel;
            contactRoles.push(role);
          }
        }
        );
      }
    } else {
      this.globalConfig.roles.forEach((role) => {
        if (role.isLoanContact) {
          contactRoles.push(role);
        }
      });
    }
    contactRoles.sort((a, b) => a.orderByChannel - b.orderByChannel);
    return contactRoles;
  };

  private buildNotifyPartyLists = () => {
    if (this.currentApplication) {
      const contactRoles = this.getContactRoles(this.currentApplication.channel);
      contactRoles.forEach(contactRole => {
        const internalContact = this.internalContacts.find(contact => contact.roleId === contactRole.roleId);
        if (internalContact && internalContact.userId) {
          const userAsInternalContact = this.users.find(user => user.userCompanyGuid === internalContact.userId) ||
            this.externalCompanyUsers.find(externalUser => externalUser.userCompanyGuid === internalContact.userId) ||
            this.branchUsers.find(externalUser => externalUser.userCompanyGuid === internalContact.userId);
          if (userAsInternalContact) {
            this.notifyPartyLoanContacts.push(
              new NotifyPartyLoanContact(
                userAsInternalContact.userCompanyGuid,
                contactRole.roleId,
                userAsInternalContact.firstName,
                userAsInternalContact.lastName,
                contactRole.roleName,
                contactRole.order
              )
            );
            const fullName = (userAsInternalContact.firstName + ' ' + userAsInternalContact.lastName).trim();
            const contactRoleMentionable: Mentionable = {
              id: '@' + fullName,
              name: contactRole.roleName,
              avatarUrl: 'assets/images/male.png',
              isOnline: true,
              mentionCode: `@{${fullName}:User:${userAsInternalContact.userCompanyGuid}}`,
            };
            this.addToMentionables(MentionsUtils.toMentionable(this._environment.apiInfo.apiBaseUrl, userAsInternalContact));
            this.mentionables.push(contactRoleMentionable);
          }
        }
      });
      this.branchUsers = this.branchUsers.filter(
        branchUser => !this.notifyPartyLoanContacts.find(contact => contact.userId === branchUser.userCompanyGuid)
      );
      this.branchUsers.forEach(branchUser => {
        this.addToMentionables(MentionsUtils.toMentionable(this._environment.apiInfo.apiBaseUrl, branchUser));
      });
    }
  };

  private loadInternalContacts = () => {
    this._internalContactsService
      .getInternalContacts(this.task.applicationId)
      .subscribe((result) => {
        this.internalContacts = result;

        //we need the external company user loaded before we can build notify party list
        if (this._externalCompanyUsersLoaded) {
          this.buildNotifyPartyLists();
        }
      }).add(() => this._internalContactsLoaded = true);
  };

  private setOriginalTask = () => {
    this._originalTask.taskStatus = this.task.taskStatus;
    this._originalTask.requiresReview = this.task.requiresReview;
    this._originalTask.reviewPartyId = this.task.reviewPartyId;
  };

  private addToMentionables = (mentionable: Mentionable) => {
    const existing = this.mentionables.find(m => m.mentionCode === mentionable.mentionCode);
    if (existing) {
      return;
    }
    this.mentionables.push(mentionable);
  }

  private loadExternalCompanyUsers = (externalCompanyId: number) => {
    this._internalContactsService.getExternalCompanyUsers(this.currentApplication.companyId, externalCompanyId)
      .subscribe(users => {
        this.externalCompanyUsers = users.filter(u => u.branchId != this.currentApplication.branchId);
        this.branchUsers = users.filter(u => u.branchId == this.currentApplication.branchId);
        this.externalCompanyUsers.forEach(externalCompanyUser => {
          this.addToMentionables(MentionsUtils.toMentionable(this._environment.apiInfo.apiBaseUrl, externalCompanyUser));
        })
        //we need the internal contacts loaded before we can build notify party list
        if (this._internalContactsLoaded) {
          this.buildNotifyPartyLists();
        }
      },
        (error) => { }
      ).add(() => this._externalCompanyUsersLoaded = true);
  };

  isInNotifyPartyList = (userId: string) => {
    return (
      this.notifyPartyLoanContacts &&
      !!this.notifyPartyLoanContacts.find((user) => user.userId == userId)
    );
  };

  onDocFilesChanged = (event: any) => {
    event.target.files.forEach((targetFile) => {
      const file = targetFile;
      let docFile = new FileDto();
      docFile.fileName = file.name;
      docFile.loanDocId = this.task.loanDocId;
      docFile.note = '';
      docFile.borrowerNote = '';
      docFile.guid = null;
      docFile.active = true;
      docFile.file = file;
      this.task.docFiles.push(docFile);
    });
  };

  isMergeVisible = () => {
    return (
      this.task.docFiles &&
      this.task.docFiles.filter((x) => x.selectedForAction == true).length > 1
    );
  };

  isValid = () => {
    if (this.task.taskStatus == 'ConditionImportPending') {
      return !!this.task.documentTypeId;
    } else {
      return true;
    }
  };

  saveTask = () => {
    if (!this.task.documentTypeId) {
      this.task.documentTypeId = null;
    }
    if (
      (!this.task.reviewPartyId || this.task.reviewPartyId == '') &&
      this.task.requiresReview
    ) {
      return this._notifyService.showError(
        'You must select a Review Party',
        'Error'
      );
    }

    if (
      (!this.task.loanDocTaskId || this.task.loanDocTaskId == 0) &&
      !this.task.multipleBorrower &&
      this.task.requestBorrower
    ) {
      return this._notifyService.showError(
        'You must assign a Borrower to this task.',
        'Error'
      );
    }

    if (this.task.notifyPartyId == '') {
      this.task.notifyPartyId = null;
    }

    if (
      (this.task.reviewPartyId && this.task.reviewPartyId == '') ||
      !this.task.requiresReview
    ) {
      this.task.reviewPartyId = null;
      this.task.requiresReview = false;
    }

    if (this.task.loanDocTaskId != 0) {
      //TODO - what is this twisted logic? Why are we getting the task back from the database???
      this._spinner.show();
      this._taskService.getTaskDashboardViewById(this.task.loanDocTaskId)
        .subscribe({
          next: (response) => {
            this.task.loanDocId = response.loanDocId;
            if (this.task.followUpDate != response.followUpDate) {
              this.setFollowUpDate();
            }

            this.createOrUpdateTaskWithUploadedFiles(
              this.task,
              [],
            );
            this.saveClicked.emit();
          },
          error: (error) => {
            this._spinner.hide();
            this._notifyService.showError(
              error && error.error
                ? error.error.message
                : 'An error occurred while saving the task.',
              'Error!'
            );
          }
        });
    } else {
      this.setFollowUpDate();
      this.createOrUpdateTaskWithUploadedFiles(this.task, []);
      this.saveClicked.emit();
    }
  };

  addDays = (date: Date, days: number): Date => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  onDeleteFileClicked = (docFile: FileDto) => {
    docFile.confirmDelete = true;
  };

  onDeleteFileCancelClicked = (docFile: FileDto) => {
    docFile.confirmDelete = false;
  };

  onDeleteFileConfirmClicked = (docFile: FileDto) => {
    docFile.active = false;
  };

  removeFileFormLoanDoc = (docFiles: DocFile[], index: number) => {
    let file = docFiles[index];
    if (file != null) {
      if (file.guid == null) {
        docFiles.splice(index, 1);
      } else {
        file.active = false;
      }
    }
  };

  onOpenLoanDocClicked = (docFile: FileDto) => {
    if (!docFile.guid && docFile.file) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(docFile.file);
      reader.onload = (event) => {
        const blob = new Blob([reader.result], { type: docFile.file.type });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      };
      return;
    }
    this._spinner.show();
    this._loanDocsService.viewLoanDocContent(docFile.guid).subscribe(
      (data) => {
        this._spinner.hide();
        const blob = new Blob([data], { type: (data as any).type });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      },
      (error) => {
        this._spinner.hide();
      }
    );
  };

  requestDateChange = (date) => {
    let newDate = new Date(date)
    this.task.requestDate = newDate.toISOString();
  }

  upsertLoanDocTask = (data) => {
    this._spinner.show();
    this._taskService.upsertLoanDocTask(data).subscribe((responseTask) => {
      const newlyCreatedTask = data.loanDocTask.loanDocTaskId == 0;
      if (this.task.docFiles.length == 0) {
        this._spinner.hide();
        this._notifyService.showSuccess("Task has been successfully saved.",
          'Success!'
        );

        if (this.willBeSnoozed && this.snoozeDurationInMinutes) {
          this.updateSnoozeTask(() => {
            this.saveClicked.emit();
          });
        }
        else {
          this.saveClicked.emit();
        }
      } else {
        let promises = [];
        this.task.docFiles.forEach((file) => {
          if (file.guid == null) {
            let autoTransition =
              this._originalTask.taskStatus == 'ConditionImportPending'
                ? 'false'
                : newlyCreatedTask
                  ? 'false'
                  : 'true';
            file.loanDocId = responseTask.loanDocId
              ? responseTask.loanDocId
              : 0;
            promises.push(
              this._fileService.uploadFileAndSingleModel(
                [file.file],
                'api/File/UpsertFileFromTask/' +
                responseTask.loanDocTaskId +
                '?autoTransition=' +
                autoTransition +
                '&autoConvertToPdf=' +
                this._userPermissions.autoConvertToPdf +
                '&useDynamicCompression=' +
                this._userPermissions.useDynamicCompression,
                file
              )
            );
          } else if (file.guid != null && file.active == false) {
            promises.push(this._fileService.removeFile(file.guid));
          } else if (file.linkWithTask) {
            let autoTransition =
              this._originalTask.taskStatus == 'ConditionImportPending'
                ? 'false'
                : newlyCreatedTask
                  ? 'false'
                  : 'true';
            let loanDocTaskId =
              this.task.loanDocTaskId || responseTask.loanDocTaskId;
            promises.push(
              this._fileService.linkFileToTask(
                loanDocTaskId,
                file.guid,
                autoTransition
              )
            );
          }
        });
        if (promises.length === 0) {
          this._spinner.hide();
          this.saveClicked.emit();
          this._notifyService.showSuccess("Task has been successfully saved.",
            'Success!'
          );
          return;
        }
        this._spinner.show();
        forkJoin(promises).subscribe(
          (responses) => {
            let bug = 0;
            responses.forEach((response) => {
              if (response == false) {
                bug++;
              }
            });
            this._spinner.hide();
            this._notifyService.showSuccess("Task has been successfully saved.",
              'Success!'
            );

            if (this.willBeSnoozed && this.snoozeDurationInMinutes) {
              this.updateSnoozeTask(
                () => {
                  this.saveClicked.emit();
                }
              );
            }
            else {
              this.saveClicked.emit();
            }
          },
          (error) => {
            this._spinner.hide();
            this.cancelClicked.emit(this.isRefreshed);
            this._notifyService.showError(
              error && error.error
                ? error.error.message
                : 'An error occurred while saving the task.',
              'Error!'
            );
          }
        );
      }
    },
      (error) => {
        this._spinner.hide();
        this._notifyService.showError(
          error && error.error
            ? error.error.message
            : 'An error occurred while saving the task.',
          'Error!'
        );
      }
    );
  };

  updateSnoozeTask = (cb: Function) => {
    this._taskService.updateSnoozeDate(this.task.loanDocTaskId, this.snoozeDurationInMinutes).subscribe((response) => {
      this.task.snoozeDate = response.snoozeDate;
      this._notifyService.showSuccess('Task snoozed successfully.', 'Success!');
      cb();
    }, (err) => {
      this._notifyService.showError(err ? err.message || err : "An error occurred while snoozing tasks.", "Error!");
    })
  };

  getSnoozeDurationTime = (time: number): string => {
    return time < 60 ? time + " mins" : (Math.ceil(time / 60) + " hours");
  }

  dueDateChange = () => {
    if (this.task.requestDate && this.dueDate < this.task.requestDate) {
      this.dueDate = this.task.requestDate;
    } else if (this.dueDate < this.today) {
      this.dueDate = this.today;
    }
    let time = new Date(this.dueDate).getTime() - new Date(this.task.requestDate ? this.task.requestDate : this.today).getTime();
    this.task.dueDays = Math.ceil(time / (1000 * 3600 * 24));
    this.task.dueDate = new Date(this.dueDate).toISOString();
  }

  hasFilesForUploading = () => {
    return _.some(
      this.task.docFiles.filter((file) => file.confirmDelete !== true)
    );
  };

  snoozeTask = () => {
    if (this.snoozeDurationInMinutes) {
      this.willBeSnoozed = true;
    }
  }

  private setFollowUpDate = () => {
    if (!this.task.followUpDate) return;

    const followUpDate = new Date(this.task.followUpDate);
    const now = new Date();
    const date = new Date(followUpDate.getFullYear(), followUpDate.getMonth(), followUpDate.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
    this.task.followUpDate = this.addDays(date, 1).toISOString();
  }

  private addUploadedFilesToLoanDocTask = (
    task: LoanDocDashboardTask,
    uploadedFiles: Array<File>
  ) => {
    let docFiles = [];
    uploadedFiles.forEach((file) => {
      docFiles.push({
        guid: null,
        file: file,
        fileName: file.name,
        loanDocId: task.loanDocId,
        note: '',
        borrowerNote: '',
        active: true,
      });
    });
    task.docFiles = task.docFiles.concat(docFiles);
  };

  private createOrUpdateTaskWithUploadedFiles = (
    task: LoanDocDashboardTask,
    uploadedFiles: File[],
  ) => {
    this.addUploadedFilesToLoanDocTask(task, uploadedFiles);

    const data = {
      loanDocTask: task,
      multipleBorrower: task.multipleBorrower,
      numFiles: task.docFiles.length,
      daysToPostpone: task.followUpDate
        ? moment(new Date(task.followUpDate), 'MM/DD/YYYY').diff(
          moment().startOf('day'),
          'days'
        )
        : 0,
    };
    this.upsertLoanDocTask(data);
  };

  private generateDisplayHtmlWithImages = (displayText: string, note) => {

    const regexp = new RegExp(
      /<img class="task-note-image" src="(([\w]|[^a-zA-Z0-9\">])+)/g
    );
    let results = regexp.exec(displayText);
    if (!results || results.length < 2) {
      note.content = displayText;
      return displayText;
    }

    this._utilityService.getImageFileContentAsBase64(results[1], (response) => {
      results[1] = response.changingThisBreaksApplicationSecurity;

      const displayHtml = displayText.replace(
        results[0],
        `<img class="note-image" src="${results[1]}`
      );

      let imageProcessingStatus = this.internalNoteImageProcessStatuses.get(note.uniqueId);
      if (imageProcessingStatus) {
        if (imageProcessingStatus.numberOfImagesLeftToProcess) {
          imageProcessingStatus.numberOfImagesLeftToProcess--;
          if (imageProcessingStatus.numberOfImagesLeftToProcess === 0) {
            imageProcessingStatus.processedContent = displayHtml;
            return;
          }
        }
      }
      this.generateDisplayHtmlWithImages(displayHtml, note);
    })
  };

  private replaceImagesWithLoaderIcons = (note): number => {

    const imageTags = note.content.match(/<img\s+[^>]*src="([^"]*)"[^>]*>/g);

    if (imageTags && imageTags.length) {
      imageTags.forEach(img => {
        note.content = note.content.replace(
          img,
          `<i class="fas fa-sync-alt fa-spin mx-1 mt-1" style="font-size: 1rem; margin-top: 4px; margin-bottom: 4px;"></i>`
        );
      });
      return imageTags.length;
    }
    return 0;
  };

  private setSecondaryReferralCompanyName = () => {
    if (this.currentApplication.secondaryReferralSource) {
      let matched = this._allAgents.find(a => a.agentId == this.currentApplication.secondaryReferralSource);
      if (matched) {
        if (matched.orgName) {
          this.secondaryReferralCompanyName = matched.orgName;
        }
        else {
          if (matched.agentCompany) {
            this.secondaryReferralCompanyName = matched.agentCompany.companyName || null;
          }
          else {
            this.secondaryReferralCompanyName = null;
          }
        }
      }
    } else {
      this.secondaryReferralCompanyName = null;
    }
  }

  private setRealtorCompanyName = () => {
    if (this.currentApplication.referralSource) {
      let matched = this._allAgents.find(a => a.agentId == this.currentApplication.referralSource);
      if (matched) {
        if (matched.orgName) {
          this.realtorCompanyName = matched.orgName;
        }
        else {
          if (matched.agentCompany) {
            this.realtorCompanyName = matched.agentCompany.companyName || null;
          }
          else {
            this.realtorCompanyName = null;
          }
        }
      }
    } else {
      this.realtorCompanyName = null;
    }
  }

  private setReferralSourceName = () => {
    if (this.currentApplication.referralSource) {
      let matched = this._allAgents.find(a => a.agentId == this.currentApplication.referralSource);
      if (matched) {
        this.referralSourceName = matched.firstName;
        this.referralSourceName += matched.firstName ? ' ' : '';
        this.referralSourceName += matched.lastName;
      }
    } else {
      this.referralSourceName = null;
    }
  }

  private setSecondaryReferralSourceName = () => {
    if (this.currentApplication.secondaryReferralSource) {
      let matched = this._allAgents.find(a => a.agentId == this.currentApplication.secondaryReferralSource);
      if (matched) {
        this.secondaryReferralSourceName = matched.firstName;
        this.secondaryReferralSourceName += matched.firstName ? ' ' : '';
        this.secondaryReferralSourceName += matched.lastName;
      }
    } else {
      this.secondaryReferralSourceName = null;
    }
  }

  private loadAllAgents = () => {
    this._agentService.getAllAgents()
      .subscribe({
        next: (agents: Agent[]) => {
          this._allAgents = agents;
          this.setReferralSourceName();
          this.setSecondaryReferralSourceName();
          this.setSecondaryReferralCompanyName();
          this.setRealtorCompanyName();
        },
        error: (err) => {
          this._notifyService.showError(err.error.message || err.error, "Fetching agents error");
        }
      })
  }

  private initializeMentionables = () => {
    this.mentionables = this.mentionables.concat(MentionsUtils.prepareUserMentionables(this._environment.apiInfo.apiBaseUrl,
      this.users));
    this.mentionables.push(MentionsUtils.prepareInternalContactsMentionable());
    this.mentionables.push(MentionsUtils.prepareHereMentionable());

    if (this.currentApplication.externalCompanyId) {
      this.loadExternalCompanyUsers(this.currentApplication.externalCompanyId);
    } else {
      this._externalCompanyUsersLoaded = true;
    }
    this.loadInternalContacts();
    this.loadAllAgents();
  }
}

export class TaskImageProcessStatus {
  processedContent: string;
  numberOfImagesLeftToProcess: number;
}
