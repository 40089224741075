import { Injectable } from "@angular/core";
import { CreditCredentialsConfig } from "./credit-credentials-config";
import { DuCreditProvider, IProvideCreditCredentialsConfig } from "./credit-credentials-config-provider.factory";
import { LoanServicesService } from "src/app/services/loan";
import { firstValueFrom } from "rxjs";
import { GlobalCreditConfig } from "../../global-admin/credit-config/models/global-credit-config.model";

@Injectable()
export class DefaultCreditCredentialsConfigProvider implements IProvideCreditCredentialsConfig {

  private readonly _creditProvidersMappings = [
    { fannieMaeId: '292', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: 'AccurateFinancialServices' },
    { fannieMaeId: '31', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: 'ACRAnet' },

    // TODO: Sean verify: Mapped to SharperLending, cause name is "Advantage Credit Bureau - SharperLending"?
    { fannieMaeId: '313', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: 'SharperLending' },

    // TODO: Sean verify: The name says "Advantage Credit Bureau (powered by MeridianLink)", but not in affiliate list, also maps to DCICreditServices on the Excel file
    { fannieMaeId: '310', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: 'DCICreditServices' },

    // TODO: Sean verify: The name says "Advantage Credit, Inc. by Credit Interlink", but not in affiliate list, also maps to DCICreditServices on the Excel file,
    // Do we map the subvendor to AdvantageCredit?
    { fannieMaeId: '308', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: 'AdvantageCredit' },

    { fannieMaeId: '226', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 1, meridianLink_TechnicalAffiliateCode: '226', encompass_SubVendor: null },
    { fannieMaeId: '138', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 2, meridianLink_TechnicalAffiliateCode: '138', encompass_SubVendor: null },
    { fannieMaeId: '139', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 4, meridianLink_TechnicalAffiliateCode: '016', encompass_SubVendor: null },
    { fannieMaeId: '76', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 5, meridianLink_TechnicalAffiliateCode: '076', encompass_SubVendor: null },
    { fannieMaeId: '911', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: 'CBCInnovis' },

    // TODO: Sean verify: The name says "Advantage Credit Bureau (powered by MeridianLink)", but not in affiliate list, also maps to DCICreditServices on the Excel file
    // Fannie name is "Certified Credit Reporting, Inc.", but the affiliate name is "Certified Credit Reporting, Inc. via MeridianLink".
    // DO these really map?
    { fannieMaeId: '911', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 7, meridianLink_TechnicalAffiliateCode: '071', encompass_SubVendor: null },
    { fannieMaeId: '128', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 8, meridianLink_TechnicalAffiliateCode: '128', encompass_SubVendor: null },
    { fannieMaeId: '86', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 10, meridianLink_TechnicalAffiliateCode: '086', encompass_SubVendor: null },
    { fannieMaeId: '1', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: 'CoreLogic' },
    { fannieMaeId: '904', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: 'CoreLogic' },
    { fannieMaeId: '298', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 9, meridianLink_TechnicalAffiliateCode: '298', encompass_SubVendor: null },

    // TODO: Sean verify: The Fannie name says ""Credit Information Systems ML", but affiliate list has a name "Credit Information Systems - CIS"
    // Do these map??
    { fannieMaeId: '311', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: 'CreditInformationSystems' },

    // TODO: Sean verify: The name says "Credit Interlink Test", but no tech affiliates and no Encompass provider
    { fannieMaeId: '916', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: null },

    { fannieMaeId: '24', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 12, meridianLink_TechnicalAffiliateCode: '024', encompass_SubVendor: null },
    { fannieMaeId: '17', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 101, meridianLink_TechnicalAffiliateCode: '017', encompass_SubVendor: null },

    // TODO: Sean verify: The name says "Credit Quick Services", but no tech affiliates and no Encompass provider
    { fannieMaeId: '116', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: null },

    { fannieMaeId: '287', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 15, meridianLink_TechnicalAffiliateCode: '181', encompass_SubVendor: null },
    { fannieMaeId: '161', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 16, meridianLink_TechnicalAffiliateCode: '161', encompass_SubVendor: null },
    { fannieMaeId: '301', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 29, meridianLink_TechnicalAffiliateCode: '304', encompass_SubVendor: null },
    { fannieMaeId: '4', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: 'Equifax' },
    { fannieMaeId: '912', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: 'Equifax' },
    { fannieMaeId: '3', lodaVendorName: 'FactualData', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: null },
    { fannieMaeId: '303', lodaVendorName: 'FactualData', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: null },
    { fannieMaeId: '918', lodaVendorName: 'FactualData', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: null },
    { fannieMaeId: '130', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: 'Covius' },
    { fannieMaeId: '17', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 58, meridianLink_TechnicalAffiliateCode: '017', encompass_SubVendor: null },
    { fannieMaeId: '2', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: 'InformativeResearch' },
    { fannieMaeId: '913', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: 'InformativeResearch' },
    { fannieMaeId: '305', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 18, meridianLink_TechnicalAffiliateCode: '309', encompass_SubVendor: null },
    { fannieMaeId: '309', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 100, meridianLink_TechnicalAffiliateCode: '328', encompass_SubVendor: null },
    { fannieMaeId: '78', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 20, meridianLink_TechnicalAffiliateCode: '078', encompass_SubVendor: null },

    // TODO: Sean verify: The name says "MeridianLink Test", what technical affiliate should this map to?
    { fannieMaeId: '909', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: null },

    // TODO: Sean verify: The name says "MeridianLink, Inc", what technical affiliate should this map to?
    { fannieMaeId: '281', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: null },

    { fannieMaeId: '277', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: 'MFICreditSolutions' },
    { fannieMaeId: '6', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: 'NCOCS' },

    // TODO: Sean verify: The name says NCRA Test, but no tech affiliates and no Encompass provider
    { fannieMaeId: '915', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: null },

    // TODO: Sean verify: The name says ""Online Information Services", but no tech affiliates, is it the same as Online Mortgage Reports from Encompass?
    { fannieMaeId: '204', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: 'OnlineMortgageReports' },

    // TODO: Sean verify: The name says "Partners Credit and Verification Solutions", but I took the encompass mapping from the initial excel file
    { fannieMaeId: '251', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: 'OldRepublic' },

    { fannieMaeId: '299', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 22, meridianLink_TechnicalAffiliateCode: '294', encompass_SubVendor: null },
    { fannieMaeId: '295', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 23, meridianLink_TechnicalAffiliateCode: '232', encompass_SubVendor: null },
    { fannieMaeId: '302', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 25, meridianLink_TechnicalAffiliateCode: '302', encompass_SubVendor: null },
    { fannieMaeId: '229', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 27, meridianLink_TechnicalAffiliateCode: '229', encompass_SubVendor: null },

    // TODO: Sean verify: These 2 below are dupes with name "SettlementOne Data, LLC"
    { fannieMaeId: '290', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 28, meridianLink_TechnicalAffiliateCode: '290', encompass_SubVendor: null },
    { fannieMaeId: '307', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 28, meridianLink_TechnicalAffiliateCode: '290', encompass_SubVendor: null },

    { fannieMaeId: '282', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: 'SharperLending' },
    { fannieMaeId: '908', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: 'SharperLending' },

    // TODO: Sean verify: The name says "SL Solutions", but no tech affiliates and no Encompass provider, can this be SharpLending??
    { fannieMaeId: '5', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: null },

    // TODO: Sean verify: The name says "Test Credit Agency", what exactly is this??
    { fannieMaeId: '200', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: null },

    { fannieMaeId: '201', lodaVendorName: 'CredCoHardPull', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: null },
    { fannieMaeId: '270', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: 'TransUnion' },

    // TODO: Sean verify: The name says "Unisource Credit, LLC", but no tech affiliates and no Encompass provider
    { fannieMaeId: '314', lodaVendorName: 'EncompassCredit', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: 'TransUnion' },

    { fannieMaeId: '63', lodaVendorName: 'MeridianLinkHardPull', meridianLink_TechnicalAffiliateId: 31, meridianLink_TechnicalAffiliateCode: '063', encompass_SubVendor: null },
    { fannieMaeId: '51', lodaVendorName: 'Xactus', meridianLink_TechnicalAffiliateId: null, meridianLink_TechnicalAffiliateCode: null, encompass_SubVendor: null },

  ];

  constructor(private readonly _loanService: LoanServicesService) { }

  async getDuCreditProviders(): Promise<DuCreditProvider[]> {
    const duAgencies = await firstValueFrom(this._loanService.getDuAgencies(true));
    return Promise.resolve(duAgencies.map(x => ({ displayName: x.name, providerIdentifier: x.providerNumber })));
  }

  async getCreditCredentialsConfig(): Promise<CreditCredentialsConfig> {
    return Promise.resolve({
      createDuCredentials: true,
      creditProvidersMappings: this._creditProvidersMappings
    });
  }

  getCreditConfig(providerIdentifier: string): GlobalCreditConfig {
    return this._creditProvidersMappings.find(x => x.fannieMaeId === providerIdentifier);
  }
}