export enum PricingVendor {
  Unknown = 'Unknown',
  Lodasoft = 'Lodasoft',
  OptimalBlue = 'OptimalBlue',
  LoanPass = 'LoanPass',
  LenderPrice = 'LenderPrice',
  Polly = 'Polly',
  MeridianLink = 'MeridianLink',
  Mortech = 'Mortech',
  LoanPassIframe = 'LoanPassIframe',
  LenderPriceIframe = 'LenderPriceIframe',
  PollyIframe = 'PollyIframe',
}
