<!-- Mobile  -->
<div *ngIf="!isLoanHeaderExpanded" class="loan-expand__btn">
  <button type="button" class="btn btn-outline-secondary loan-btn" placement="bottom" [popover]="loanHeader"
    containerClass="loan-expand__container">
    <span class="fw-bolder button-item-text"> Loan Summary </span>
    <i class="fas fa-angle-down ms-2"></i>
  </button>
</div>

<!-- Desktop -->
<ng-container *ngIf="isLoanHeaderExpanded" [ngTemplateOutlet]="loanHeader"></ng-container>

<ng-template #loanHeader>
  <div class="loan-header">
    <!-- Loan Summary -->
    <button type="button" class="btn btn-outline-secondary loan-btn" placement="bottom"
      [popover]="loanSummaryPopoverTemplate" containerClass="loan-summary-details" #loanSummaryPopover="bs-popover"
      (onShown)="onShownPopover(loanSummaryPopover)" (onHidden)="onHiddenPopover()">
      <i class="fas fa-user me-1" style="font-size: smaller"></i>
      <span class="fw-bolder button-item-text"> {{ borrowerName }}</span>
      <i class="fas fa-angle-down ms-2"></i>
    </button>
    <ng-template #loanSummaryPopoverTemplate>
      <div class="card report-card border-0">
        <div class="card-header border-bottom-0 pb-0">
          <h4 class="card-title">Details</h4>
        </div>
        <div class="card-body pt-1">
          <div class="row d-flex justify-content-center">
            <div class="col">
              <h3 class="m-0" title="Loan ID">{{applicationIdWithPadding}}</h3>
              <p class="mb-0 text-truncate text-muted" title="Loan Purpose / Loan Type"><span class="text-success"><i
                    class="mdi mdi-checkbox-marked-circle-outline me-1"></i></span>{{ loanPurposeName }}
                {{ loanTypeName ? '- ' + loanTypeName : '' }}</p>
              <p class="mb-0 text-truncate text-dark" title="LOS Loan Number">LOS# {{ application.refNumber }}</p>
            </div>
            <div class="col align-self-center px-2">
              <div class="d-flex align-items-center justfy-content-center my-2">
                <i class="fas fa-map-pin fa-2x me-2"></i>
                <p class="text-dark font-weight-semibold mb-0" title="Subject Property Address"> {{ mortgage.subjectProperty.address1 || 'No Street Address' }}
                  <span *ngIf="
                            mortgage.subjectProperty.city ||
                            mortgage.subjectProperty.state ||
                            mortgage.subjectProperty.zipCode
                          ">
                    &nbsp;
                    {{
                    mortgage.subjectProperty.city
                    ? mortgage.subjectProperty.city + ','
                    : ''
                    }}
                    {{ mortgage.subjectProperty.state | uppercase }}
                    {{ mortgage.subjectProperty.zipCode }}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card border-0" *ngIf="borrowers.length">
        <div class="card-header border-bottom-0 pb-0">
          <h4 class="card-title">Borrowers</h4>
        </div>
        <div class="card-body pt-1">
          <div class="table-responsive">
            <table class="table table-hover mb-0">
              <thead class="thead-light">
                <tr>
                  <th>Name</th>
                  <th>Credit Score</th>
                  <th *ngIf="!isTpo">Portal Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let borrower of borrowers | sort : 'isPrimary'; let i = index">
                  <td>{{ borrower.fullName }}
                    <button type="button" id="buttonBorrowerInfo" class="btn ps-0 pb-0 pt-0" data-bs-container="body"
                      data-bs-toggle="popover" placement="right" [popover]="templateBorrowerInfo" data-trigger="click"
                      [outsideClick]="true" [containerClass]="'templateBorrowerInfo'">
                      <i class="fa fa-address-book" style="font-size: 1.3em"></i>
                    </button>
                    <ng-template #templateBorrowerInfo>
                      <table-cell-contact-info [contactInfo]="borrower" [emailEnabled]="false" [recordType]="'Borrower'"
                        [recordId]="borrower.borrowerId" [applicationId]="application.applicationId"
                        [isLinksDisabled]="isTpo"
                        [dialerEnabled]="!isTpo" [isManualDial]="true">
                      </table-cell-contact-info>

                      <div class="row mt-2 contact-info-address">
                        <span><strong>Address:</strong> {{ borrower.mailingStreet }}
                          <br />
                          {{ borrower.mailingCity }}
                          {{
                          borrower.mailingState
                          ? borrower.mailingState.toUpperCase()
                          : ''
                          }}
                          {{
                          borrower.mailingZip ? ', ' + borrower.mailingZip : ''
                          }}
                        </span>
                      </div>
                    </ng-template>
                  </td>
                  <td>
                    {{ borrower.creditScore || '--' }}
                    <button type="button" class="btn ps-0 pb-0 pt-0" data-bs-container="body" data-bs-toggle="popover"
                      placement="right" [popover]="templateCreditInfo" data-trigger="click" [outsideClick]="true"
                      [containerClass]="'templateCreditInfo'">
                      <i class="fa fa-flag-checkered"></i>
                    </button>

                    <ng-template #templateCreditInfo>
                      <div class="row mt-2 credit-scores-popover">
                        <ul style="list-style: none;">
                          <li><span>Experian:</span> {{ borrower.creditScore_Experian || '--' }}</li>
                          <li><span>TransUnion:</span> {{ borrower.creditScore_TransUnion || '--' }}</li>
                          <li><span>Equifax:</span> {{ borrower.creditScore_Equifax || '--' }}</li>
                        </ul>
                      </div>
                    </ng-template>
                  </td>
                  <td *ngIf="!isTpo">
                    <portal-status-indicator [borrower]="borrower" [applicationId]="application.applicationId"
                    [disabledInviteLink]="disableBorrowerPortalInviteLink">
                    </portal-status-indicator>
                  </td>
                </tr>
              </tbody>
            </table>

          </div><!--end table-responsive-->
        </div>
      </div>
    </ng-template>

    <!-- Loan Status -->
    <button type="button" class="btn btn-outline-secondary loan-btn" placement="bottom"
      *ngIf="!options || (options && options.showStatusSummary)"
      [popover]="loanStatusPopoverTemplate" containerClass="loan-status" #loanStatusPopover="bs-popover"
      (onShown)="onShownPopover(loanStatusPopover)" (onHidden)="onHiddenPopover()">
      <i class="fas fa-circle me-1" style="color: #773bd8; font-size: smaller"></i>
      <span class="text-truncate" style="max-width: 86px;display: inline-flex;">
        <span id="currentLoanStatusName" class="fw-bolder button-item-text" style="overflow: hidden;text-overflow: ellipsis;"
          [title]="getTitle()">
          {{ currentLoanStatusName }}</span>
      </span>
      <i class="fas fa-angle-down ms-2"></i>
    </button>
    <ng-template #loanStatusPopoverTemplate>
      <div class="d-flex justify-content-between">
        <h4 class="card-title">
          <strong> Loan Status </strong>
        </h4>
        <span>
          <a (click)="onLoanStatusHistoryClicked()" class="text-primary" style="font-size: small">
            <i class="fa fa-clock me-1" style="color: #3aaf80"></i> View History
          </a>
        </span>
      </div>
      <hr />
      <loan-status-summary [application]="application" style="min-width: 600px"></loan-status-summary>
    </ng-template>

    <!-- LOS/LDE -->
    <button *ngIf="showLosLdeButton" type="button" class="btn btn-outline-secondary loan-btn" placement="bottom"
      [popover]="losPopoverPopoverTemplate" containerClass="los-lde" #losPopoverPopover="bs-popover"
      (onShown)="onShownPopover(losPopoverPopover)" (onHidden)="onHiddenPopover()">
      <span *ngIf="losEnabled">
        <i class="fas fa-circle me-1" [ngClass]="{
            'los-lde-referenced': this.losConnectionStatus == 'Referenced',
            'los-lde-fullsync': this.losConnectionStatus == 'FullSync',
            'los-lde-none': this.losConnectionStatus == 'None',
            'los-lde-lostoloda': this.losConnectionStatus == 'LosToLoda'
          }" style="font-size: smaller"></i>
        <span class="fw-bolder button-item-text"> LOS </span>
      </span>
      <span *ngIf="losEnabled && ldeEnabled"> / </span>
      <span *ngIf="ldeEnabled">
        <i class="fas fa-circle me-1" [ngClass]="{
            'los-lde-referenced': this.ldeConnectionStatus == 'Referenced',
            'los-lde-fullsync': this.ldeConnectionStatus == 'FullSync',
            'los-lde-none': this.ldeConnectionStatus == 'None',
            'los-lde-lostoloda': this.ldeConnectionStatus == 'LosToLoda'
          }" style="font-size: smaller"></i>
        <span class="fw-bolder button-item-text"> LDE </span>
      </span>
      <i class="fas fa-angle-down ms-2"></i>
    </button>
    <ng-template #losPopoverPopoverTemplate>
      <los-lde-summary (losSyncDirectionUpdated)="onLosSyncDirectionUpdated()"
        (syncInfoUpdated)="onLdeSyncInfoUpdated()">
      </los-lde-summary>
    </ng-template>

    <!-- LTV -->
    <button *ngIf="!isSolarTransactionType" type="button" class="btn btn-outline-secondary loan-btn" placement="bottom"
      [popover]="loanRatiosPopoverTemplate" containerClass="loan-ratios" #loanRatiosPopover="bs-popover"
      (onShown)="onShownPopover(loanRatiosPopover)" (onHidden)="onHiddenPopover()">
      <i class="fas fa-circle me-1" style="color: #d83b3b; font-size: smaller" *ngIf="!mortgage?.transactionDetail?.ltv"></i>
      <span class="fw-bolder button-item-text">
        LTV {{ (mortgage?.transactionDetail?.ltv / 100) | percent : '1.1-3' }}
      </span>
      <i class="fas fa-angle-down ms-2"></i>
    </button>
    <ng-template #loanRatiosPopoverTemplate>
      <loan-ltv-summary *ngIf="ltvDetails"
        [ltvDetails]="ltvDetails"
        [mortgageId]="mortgage.mortgageId"
        (closeRequested)="onCloseLoanRatios()">
      </loan-ltv-summary>
      <loading-indicator *ngIf="!ltvDetails"></loading-indicator>
    </ng-template>

    <!-- FICO -->
    <button type="button" class="btn btn-outline-secondary loan-btn" placement="bottom" [popover]="ficoPopoverTemplate"
      containerClass="fico" #ficoPopover="bs-popover" (onShown)="onShownPopover(ficoPopover)"
      (onHidden)="onHiddenPopover()">
      <i class="fas fa-circle me-1" style="color: #d83b3b; font-size: smaller" *ngIf="!ficoOnFile"></i>
      <span class="fw-bolder button-item-text">
        FICO {{ ficoOnFile ? ficoOnFile : '--' }}
      </span>
      <i class="fas fa-angle-down ms-2"></i>
    </button>
    <ng-template #ficoPopoverTemplate>
      <loan-fico-summary [isTpo]="isTpo" [applicationId]="application.applicationId" [borrowers]="borrowers"></loan-fico-summary>
    </ng-template>

    <!-- Pricing Details -->
    <button *ngIf="!isSolarTransactionType" type="button" class="btn btn-outline-secondary loan-btn" placement="bottom"
      [popover]="pricingDetailsPopoverTemplate" containerClass="pricing-details" #pricingDetailsPopover="bs-popover"
      (onShown)="onShownPopover(pricingDetailsPopover)" (onHidden)="onHiddenPopover()">
      <i *ngIf="
          application &&
          (
            (application.productPricing && pricingLockStatus && 
            (pricingLockStatus == 'Accepted' || pricingLockStatus == 'CancelRequested')) ||
            (!pricingLockStatus && hasRateLockExpirationKeyDate)
          )
        " class="fas fa-lock me-1" style="color: orange"></i>
      <i *ngIf="
          application &&
          (
            (application.productPricing && pricingLockStatus && 
            pricingLockStatus != 'Accepted' && pricingLockStatus != 'CancelRequested') ||
            !application.productPricing ||
            (!pricingLockStatus && !hasRateLockExpirationKeyDate)
          )
        " class="fas fa-lock-open me-1" style="color: orange"></i>
      <ng-container *ngIf="application && application.productPricing">
        <span class="fw-bolder button-item-text">
          {{ interestRate | percent : '1.3-3' }}
        </span>
        <i class="fas fa-angle-down ms-2"></i>
      </ng-container>
      <ng-container *ngIf="application && !application.productPricing">
        <span class="fw-bolder button-item-text"> Not Priced </span>
        <i class="fas fa-angle-down ms-2"></i>
      </ng-container>
      <ng-container *ngIf="!application">
        <span class="fw-bolder button-item-text"> Loading... </span>
        <i class="fas fa-sync fa-spin ms-2"></i>
      </ng-container>
    </button>
    <ng-template #pricingDetailsPopoverTemplate>
      <loan-pricing-summary
        [isTpo]="isTpo"
        [isPRMG]="isPRMG"
        [applicationId]="application.applicationId"
        [borrowers]="borrowers"
        (repriceLoanClicked)="onRepriceLoanClicked()"
        [application]="application">
      </loan-pricing-summary>
    </ng-template>

    <!-- Title Status Indicator -->
    <button *ngIf="isTitleOrderValid !== undefined && isTitleOrderValid !== null" type="button" class="btn btn-outline-secondary loan-btn pe-none"
    placement="bottom" [popover]="titleLeAndCDTemplate" containerClass="title-le-cd" #titleLeAndCDPopover="bs-popover">
    <span>
      <i class="fas fa-circle me-1" [ngClass]="{
      'los-lde-lostoloda': isTitleOrderValid,
      'los-lde-none': !isTitleOrderValid
    }" style="font-size: smaller"></i>
    <span class="fw-bolder button-item-text"> Title </span>
    </span>
  </button>

    <!-- LE / CD -->
    <button *ngIf="isDisclosureValid != null" type="button" class="btn btn-outline-secondary loan-btn"
      [ngClass]="{'bg-color-light-green': isDisclosureValid, 'bg-color-light-red': !isDisclosureValid, 'pe-none': isDisclosureValid}"
      placement="bottom" [popover]="lEandCDPopoverTemplate" containerClass="le-cd" #lEandCDPopover="bs-popover"
      (onShown)="onShownPopover(lEandCDPopover)" (onHidden)="onHiddenPopover()">
      <span>
        <i class="fas fa-circle me-1" [ngClass]="{
        'los-lde-none': !doesDisclosureIncludeCD && !isDisclosureValid,
        'los-lde-lostoloda': doesDisclosureIncludeCD || (!doesDisclosureIncludeCD && isDisclosureValid)
      }" style="font-size: smaller"></i>
        <span class="fw-bolder button-item-text"> LE </span>
      </span>
      <span> / </span>
      <span>
        <i class="fas fa-circle me-1" [ngClass]="{
        'los-lde-none': (doesDisclosureIncludeCD && !isDisclosureValid),
        'los-lde-lostoloda': isDisclosureValid && doesDisclosureIncludeCD
      }" style="font-size: smaller"></i>
        <span class="fw-bolder button-item-text"> CD </span>
      </span>
      <i *ngIf="!isDisclosureValid" class="fas fa-angle-down ms-2"></i>
    </button>
    <ng-template #lEandCDPopoverTemplate>
      <le-cd-validation
      [appId]="application.applicationId"
      [languagePreference]="defaultLanguagePreference"
      >
      </le-cd-validation>
    </ng-template>


    <!-- DTI -->
    <button *ngIf="!isSolarTransactionType" type="button" class="btn btn-outline-secondary loan-btn" placement="bottom"
      [popover]="dtiPopoverTemplate" containerClass="dti" #dtiPopover="bs-popover" [adaptivePosition]="!isLoanHeaderExpanded"
      (onShown)="onShownPopover(dtiPopover)" (onHidden)="onHiddenPopover()">
      <span class="fw-bolder button-item-text"> DTI </span>
      <span *ngIf="dtiDetails" class="fw-bolder" [ngClass]="{
          'dti-danger': dtiDetails?.frontEndDti > 47,
          'dti-warning':
          dtiDetails?.frontEndDti > 43 && dtiDetails?.frontEndDti <= 47,
          'dti-success': dtiDetails?.frontEndDti <= 43
        }">
        {{
          dtiDetails?.frontEndDti > 999 ? '--' : dtiDetails?.frontEndDti || 0
        }}%
      </span>
      <ng-container *ngIf="!dtiDetails">
        <i class="fas fa-sync fa-spin ms-2"></i>
      </ng-container>
      /
      <span *ngIf="dtiDetails" class="fw-bolder" [ngClass]="{
          'dti-danger': dtiDetails?.backEndDti > 47,
          'dti-warning':
          dtiDetails?.backEndDti > 43 && dtiDetails?.backEndDti <= 47,
          'dti-success': dtiDetails?.backEndDti <= 43
        }">
        {{
          dtiDetails?.backEndDti > 999 ? '--' : dtiDetails?.backEndDti || 0
        }}%
      </span>
      <ng-container *ngIf="!dtiDetails">
        <i class="fas fa-sync fa-spin ms-2"></i>
      </ng-container>
      <i class="fas fa-angle-down ms-2"></i>
    </button>
    <ng-template #dtiPopoverTemplate>
      <loan-dti-summary
        [mortgageId]="mortgage.mortgageId"
        [dti]="dtiDetails"
        (dtiRecalculationStarted)="onDtiRecalculationStarted()"
        (dtiRecalculationCompleted)="onDtiRecalculationCompleted($event)"
        (closeRequested)="onCloseDtiDetailsClicked()">
      </loan-dti-summary>
    </ng-template>

    <!-- Characteristics -->
    <button type="button" class="btn btn-outline-secondary loan-btn" placement="bottom"
      *ngIf="!options || (options && options.showCharacteristicsSummary)"
      [popover]="characteristicsPopoverTemplate" containerClass="characteristics" #characteristicsPopover="bs-popover"
      (onShown)="onShownPopover(characteristicsPopover)" (onHidden)="onHiddenPopover()">
      <span class="fw-bolder button-item-text"> Characteristics </span>
      <i class="fas fa-angle-down ms-2"></i>
    </button>
    <ng-template #characteristicsPopoverTemplate>
      <characteristics [loanCharacteristics]="loanCharacteristics" [borrowerCharacteristics]="borrowerCharacteristics"
        [application]="application" (characteristicsSaved)="onCharacteristicsSaved()"
        (onClose)="onCharacteristicsPopoverClose()">
      </characteristics>
    </ng-template>

    <!-- FTC -->
    <button *ngIf="!isSolarTransactionType" type="button" class="btn btn-outline-secondary loan-btn" placement="bottom"
      [popover]="ftcPopoverTemplate" containerClass="ftc" #ftcPopover="bs-popover"
      (onShown)="onShownPopover(ftcPopover)" (onHidden)="onHiddenPopover()">
      <span class="fw-bolder button-item-text">
        FTC ({{ (ftcDetails?.cashFromToBorrower | currency) || '--' }})
      </span>
      <ng-container *ngIf="!ftcDetails">
        <i class="fas fa-sync fa-spin ms-2"></i>
      </ng-container>
      <i class="fas fa-angle-down ms-2"></i>
    </button>
    <ng-template #ftcPopoverTemplate>
      <loan-ftc-summary
        [ftc]="ftcDetails"
        [mortgage]="mortgage"
        [isRefinance]="isRefinance"
        (ftcRecalculationStarted)="onFtcRecalculationStarted()"
        (ftcRecalculationCompleted)="onFtcRecalculationCompleted($event)">
    </loan-ftc-summary>
    </ng-template>

    <!-- Solar Summary -->
    <button *ngIf="isSolarTransactionType" type="button" class="btn btn-outline-secondary loan-btn" placement="bottom"
      [popover]="solarSummaryPopoverTemplate" containerClass="solar-summary" #solarSummaryPopover="bs-popover"
      (onShown)="onShownPopover(solarSummaryPopover)" (onHidden)="onHiddenPopover()">
      <span class="fw-bolder button-item-text"> Summary </span>
      <i class="fas fa-angle-down ms-2"></i>
    </button>
    <ng-template #solarSummaryPopoverTemplate>
      <span class="alert alert-info">Summary Info Goes here</span>
    </ng-template>
  </div>
</ng-template>

