import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ApplicationContext, Borrower } from 'src/app/models';
import { ConditionsTask } from 'src/app/models/task/conditons-task.model';
import { User } from 'src/app/models/user/user.model';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { AdminConditionTableComponent } from './components/admin-condition-table/admin-condition-table.component';
import { Subscription } from "rxjs";
import { LoanService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from 'src/app/services/constants';
import { MenuItemStatus } from '../tpo/models/enums/menu-item-status.enum';

@Component({
  selector: 'conditions-tasks',
  templateUrl: 'conditions-tasks.component.html',
  styleUrls: ['conditions-tasks.component.scss'],
})
export class ConditionsTasksComponent extends ApplicationContextBoundComponent implements OnInit {

  loading: boolean = false;
  appId: number;

  borrowers: Borrower[];

  users: User[];

  conditionsTasks: ConditionsTask[];

  context: ApplicationContext;

  isTpo: boolean = false;

  resubmitResult = null

  @ViewChild(AdminConditionTableComponent) conditionsTable!: AdminConditionTableComponent;

  protected isTpoSubmitted: boolean = false;
  protected lastResubmissionDate?: Date;

  private _loanInfoChangesSubscription: Subscription;
  private _loanTaskChangesSubscription: Subscription;

  constructor(
    private readonly _loanService: LoanService,
    private readonly _notifyService: NotificationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    if (!this.applicationContext?.application?.applicationId) {
      this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
        this.isTpo = context.isTpo;
        if (context.application) {
          this.initialize(context);
        }
      });
    } else {
      this.isTpo = this.applicationContext.isTpo;
      this.initialize(this.applicationContext);
    }

  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
    if (this._loanTaskChangesSubscription) {
      this._loanTaskChangesSubscription.unsubscribe();
    }
  }

  resubmitTpoLoan = () => {
    this._spinner.show();
    this._loanService.resubmitTpoLoan(this.appId).subscribe({
      next: (res) => {
        this.resubmitResult = res;
        this.lastResubmissionDate = new Date();
        if (res.passed) {
          this._notifyService.showSuccess('Resubmit to UW', "Successful");
        } else {
          this._notifyService.showError(res.errorMessage || 'Resubmit to UW failed', "Error");
        }
      },
      error: (err) => {
        this._notifyService.showError(err ? err.message : '', "Error");
      }
    }).add(() => { this._spinner.hide(); });
  }

  protected closeErrors = () => {
    this.resubmitResult = null;
  }

  private initialize = (context: ApplicationContext) => {
    this.appId = context.application.applicationId;
    this.borrowers = context.borrowers;
    this.users = context.globalConfig.users;
    this.context = context;

    this.isTpoSubmitted = this.context.applicationKeyDatesByType 
        ? !!this.context.applicationKeyDatesByType['tpoSubmission'] : false;
    this.lastResubmissionDate = this.context.applicationKeyDatesByType 
    ? this.context.applicationKeyDatesByType['tpoResubmission']?.eventDateUtc : undefined;
  }
}
