import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { SharedModule } from 'src/app/shared/shared.module';
import { DocumentPreparationBorrowerV2Component } from "./components/document-preparation-borrower-v2/document-preparation-borrower-v2.component";
import { DocumentPreparationBorrowersV2Component } from "./components/document-preparation-borrowers-v2/document-preparation-borrowers-v2.component";
import { DocumentPreparationDeedComponent } from "./components/document-preparation-deed/document-preparation-deed.component";
import { DocumentPreparationLegalDescriptionComponent } from "./components/document-preparation-legal-description/document-preparation-legal-description.component";
import { DocumentPreparationLenderInfoComponent } from "./components/document-preparation-lender-info/document-preparation-lender-info.component";
import { DocumentPreparationLoanInfoComponent } from "./components/document-preparation-loan-info/document-preparation-loan-info.component";
import { DocumentPreparationPropertyInfoComponent } from "./components/document-preparation-property-info/document-preparation-property-info.component";
import { DocumentPreparatioRenewalAndExtensionComponent } from "./components/document-preparation-renewal-and-extension/document-preparation-renewal-and-extension.component";
import { DocumentPreparationSellerV2Component } from "./components/document-preparation-seller-v2/document-preparation-seller-v2.component";
import { DocumentPreparationSellersV2Component } from "./components/document-preparation-sellers-v2/document-preparation-sellers-v2.component";
import { DocumentPreparationSignerComponent } from "./components/document-preparation-signer/document-preparation-signer.component";
import { DocumentPreparationTitleCompanyComponent } from "./components/document-preparation-title-company/document-preparation-title-company.component";
import { DocumentPreparationTrustPartyComponent } from "./components/document-preparation-trust-party/document-preparation-trust-party.component";
import { DocumentPreparationComponent } from "./components/document-preparation.component";
import { DocumentPreparationInvoicingComponent } from './components/document-preparation-invoicing/document-preparation-invoicing.component';
import { InvoicingModule } from '../../invoicing/invoicing.module';
import { DocumentPreparationCustomInfoComponent } from './components/document-preparation-custom-info/document-preparation-custom-info.component';
import { AppDetailsService } from '../services/app-details.service';
import { CustomDataInfoModule } from '../../custom-data-info/custom-data-info.module';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    InvoicingModule,
    CustomDataInfoModule
  ],
  declarations: [
    DocumentPreparationComponent,
    DocumentPreparationLoanInfoComponent,
    DocumentPreparationPropertyInfoComponent,
    DocumentPreparationLegalDescriptionComponent,
    DocumentPreparationBorrowerV2Component,
    DocumentPreparationBorrowersV2Component,
    DocumentPreparationTrustPartyComponent,
    DocumentPreparationSellerV2Component,
    DocumentPreparationSellersV2Component,
    DocumentPreparationSignerComponent,
    DocumentPreparationDeedComponent,
    DocumentPreparationTitleCompanyComponent,
    DocumentPreparationLenderInfoComponent,
    DocumentPreparatioRenewalAndExtensionComponent,
    DocumentPreparationInvoicingComponent,
    DocumentPreparationCustomInfoComponent,
  ],
  exports: [

  ],
  providers: [
    AppDetailsService
  ]
})
export class DocumentPreparationModule {
  constructor() {
  }
}
