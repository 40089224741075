import { FeeTypeEnum } from "src/app/models/fee/fee-type.enum";
import { LoanPurposeTypeEnum, MortgageAppliedForTypeEnum } from "../../app-details/components/title-history/models/title-order.model";
import { AmortizationType } from "../../reports/mortgage-call/models/mcr-audit-data.model";
import { FeeSectionEnum } from "src/app/models/fee/fee-section.enum";
import { SigningRole } from "src/app/models";
import { ProductPriceValidationItem } from "../../pricing/models/pricing/product-price-validation.model";

export class DisclosureTracking {
  disclosureTrackingId: number;
  losDisclosureTrackingId: string;
  disclosureTrackingMortgageSnapshotId: number;
  disclosureTrackingMortgageSnapshot: DisclosureTrackingMortgageSnapshot;
  mortgageId: number;
  disclosureHistoryId: number;
  disclosureRequestStoredFileId: number;
  status: DisclosureTrackingStatus;
  disclosureSent: Date;
  disclosureSource: DisclosureSourceEnum;
  requestingUserId: string;
  requestingUserTimeZone: string;
  disclosureVersion: DisclosureVersion;
  includeInTimeline: boolean;
  leSent: boolean;
  cdSent: boolean;
  safeHarborSent: boolean;
  settlementServiceProviderListSent: boolean;
  postConsummationDisclosureSent: boolean;
  affiliatedBusinessDisclosureSent: boolean;
  charmBookletSent: boolean;
  specialInfoBookletSent: boolean;
  helocBrochureSent: boolean;
  firstAppraisalSent: boolean;
  subsequentAppraisalSent: boolean;
  avmSent: boolean;
  homeCounselingDisclosureSent: boolean;
  highCostDisclosureSent: boolean;
  brokerDisclosed: boolean;
  intentToProceedSent: boolean;
  intentToProceedReceived: Date;
  intentToProceedReceivedByUserId: string;
  intentToProceedReceivedMethod: string;
  intentToProceedComments: string;
  manuallyFulfilledDate: Date;
  fulfilledByUserId: string;
  manualFulfillmentPresumedReceivedDate: Date;
  manualFulfillmentActualReceivedDate: Date;
  deliveryMethod: DocumentDeliveryMethod;
  deliveryMethodOtherDescription: string;
  changeOfCircumstanceDetail: ChangeOfCircumstanceDetail;
  borrowers: DisclosureTrackingBorrower[];
  disclosedFees: DisclosureTrackingFee[];
  documents: DisclosureTrackingDocument[];
  companyId: number;
  insertedBy: string;
  updatedBy: string;
  dateInserted: string;
  dateUpdated: string;

  // does not exist in API
  fulfillmentMethod: string;
  documentSigningOrderId: number;
}

export class DisclosureTrackingMortgageSnapshot {
  disclosureTrackingMortgageSnapshotId: number;
  hash: string;
  mortgageAppliedFor: MortgageAppliedForTypeEnum;
  noOfMonths: number;
  purposeOfLoan: LoanPurposeTypeEnum;
  amortizationType: AmortizationType;
  totalLoanAmount: number;
  appraisedValue: number;
  purchasePrice: number;
  refinancePurpose: RefinancePurposeTypeEnum;
  governmentRefinanceType: GovernmentRefinanceTypeEnum;
  hasPrepaymentPenalty: boolean;
  apr: number;
  propertyAddress: string;
  propertyZip: string;
  borrowerCount: number;
  productName: string;
  productCode: string;
  isPricingValid: boolean;
  ltv: number;
  cltv: number;
  hcltv: number;
  frontEndDTI: number;
  backEndDTI: number;
  cashFromToBorrower: number;
  fees: DisclosureTrackingMortgageSnapshotFee[];
  closingDate: Date;
  firstPaymentDate: Date;
  lockDate: Date;
  lockExpirationDate: Date;
  rateSheetDate: Date;
  companyId: number;
  insertedBy: string;
  updatedBy: string;
  dateInserted: Date;
  dateUpdated: Date;
}

export class DisclosureTrackingMortgageSnapshotFee {
  feeType: FeeTypeEnum;
  feeSection: FeeSectionEnum;
  borrowerTotal: number;
}

export class DisclosureTrackingBorrower {
  disclosureTrackingBorrowerId: number;
  disclosureTrackingId: number;
  documentSigningRecipientId: number;
  borrowerId: number;
  eConsentEventId: number;
  receivedMethod: string;
  borrowerType: SigningRole;
  presumedReceivedDate: Date;
  actualReceivedDate: Date;
  messageViewedDate: Date;
  signedDate: Date;
  signedFromIp: string;
  companyId: number;
  insertedBy: string;
  updatedBy: string;
  dateInserted: string;
  dateUpdated: string;
}

export class CocReason {
  changeOfCircumstanceReasonId: number;
  order: number;
  title: string;
  defaultComments: string;
  disclosureReason: DisclosureReason = null;
  reasonCategory: string = null;
  disclosureReasonOtherDescription: string;
  companyId: number;
  insertedBy: string;
  updatedBy: string;
  dateInserted: string;
  dateUpdated: string;

  constructor() {
    this.disclosureReason = null;
    this.reasonCategory = null;
  }
}

export class DisclosureTrackingFee {
  loanFeeHistoryId: number;
}

export enum ChangeOfCircumstanceStatus {
  Requested = "Requested", // TODO: if request make them editable
  Approved = "Approved",
  Disclosed = "Disclosed",
  Cancelled = "Cancelled",
  Rejected = "Rejected",
}

export class ChangeOfCircumstanceDetail {
  changeOfCircumstanceId?: number;
  mortgageId?: number;
  disclosureTrackingId?: number;
  loanDocTaskId?: number;
  changeOfCircumstanceStatus: ChangeOfCircumstanceStatus = ChangeOfCircumstanceStatus.Requested;
  disclosureReason?: DisclosureReason | string;
  changeOfCircumstanceReasonId: number;
  disclosureReasonOtherDescription: string;
  disclosureReasonAdditionalDescription: string;
  disclosureComments: string;
  hasChangeOfCircumstance?: boolean;
  changesOfCircumstanceReceivedDate?: Date;
  isFeeLevelDisclosure: boolean;
  changeOfCircumstanceReason: ChangeOfCircumstanceReasonType = null;
  feeDetail: ChangeOfCircumstanceFeeDetail[] = [];
}

export class ChangeOfCircumstanceFeeDetail {
  changeOfCircumstanceFeeId: number;
  changeOfCircumstanceId: number;
  baselineLoanFeeHistoryId?: number;
  updatedDisclosedLoanFeeHistoryId: number;
}

export interface ToleranceBucket {
  bucketType: string,
  feeTotalLoan: number,
  feeTotalDisclosed: number,
  isOverLimit: boolean,
  loanFees: [
    {
      feeType: string,
      feeSection: string,
      borrowerTotal: number
    }
  ],
  disclosedFees: [
    {
      feeType: string,
      feeSection: string,
      borrowerTotal: number
    }
  ]
}

export interface DisclosureValidationResponse {
  redisclosureWithCoCRequired: boolean,
  redisclosureWithCoCRequiredToAvoidCure: boolean,
  toleranceBuckets: ToleranceBucket[],
  fees: DisclosureValidationFeeDetail[],
  highCostDetail: HighCostTestValidationDetail[],
  validity: string,
  validityDetail: string,
  items: ProductPriceValidationItem[]
}

export class HighCostTestValidationDetail {
  order: number;
  highCostMortgageComputationType: HighCostMortgageComputationTypeEnum;
  stateComputationTypeState: string;
  highCostMortgageComputationName: string;
  isHighCostLoan: boolean;
  regulationZDeterminationReason: string;
}

export enum HighCostMortgageComputationTypeEnum {
  Section32Hoepa = "Section32Hoepa",
  Section35FederalHpml = "Section35FederalHpml",
  FannieMaePointsAndFees = "FannieMaePointsAndFees",
  FreddieMacPointsAndFees = "FreddieMacPointsAndFees",
  AtrQualifiedMortgage = "AtrQualifiedMortgage",
  StateHighCostTest = "StateHighCostTest",
  TridFederal = "TridFederal"
}

export class DisclosureValidationFeeDetail {
  loanValue: number;
  disclosedValue: number;
  disclosedLoanFeeHistoryId?: number;
  currentLoanFeeModelGuid?: number;
  didIncrease?: boolean;
  feeName?: string;
  feeType?: string;
  feeSection?: string;
  hudNumber?: string;
}

export class DisclosureTrackingDocument {
  title: string;
  docFileId: number;
  losDocFileId: string;
}

export enum ChangeOfCircumstanceReasonType {
  LoanAmountChange = "LoanAmountChange",
  LoanTypeChange = "LoanTypeChange",
  LoanProgramChange = "LoanProgramChange",
  BorrowerIncomeNotVerifiedOrVerifiedAtDifferentAmount = "BorrowerIncomeNotVerifiedOrVerifiedAtDifferentAmount",
  AppraisedValueDifferentFromEstimatedValue = "AppraisedValueDifferentFromEstimatedValue",
  AdditionalServiceIsNecessaryBasedOnTitleReport = "AdditionalServiceIsNecessaryBasedOnTitleReport",
  RecordingFeesIncreasedFromUnanticipatedDocuments = "RecordingFeesIncreasedFromUnanticipatedDocuments",
  BorrowerTakingTitleHasChanged = "BorrowerTakingTitleHasChanged",
  BorrowerAddedOrDroppedFromLoan = "BorrowerAddedOrDroppedFromLoan",
  LoanLocked = "LoanLocked",
  LoanLockExtended = "LoanLockExtended",
  ChangeInSettlementFee = "ChangeInSettlementFee",
  ClericalError = "ClericalError",
  Other = "Other"
}

export enum DisclosureReason {
  Item24HourAdvancedPreview = "Item24HourAdvancedPreview",
  AdditionOfPrepaymentPenalty = "AdditionOfPrepaymentPenalty",
  APR = "APR",
  ClericalErrorCorrection = "ClericalErrorCorrection",
  DelayedSettlementDate = "DelayedSettlementDate",
  Eligibility = "Eligibility",
  Expiration = "Expiration",
  InterestRateDependentCharges = "InterestRateDependentCharges",
  LoanProduct = "LoanProduct",
  Other = "Other",
  RevisionsRequestedByBorrower = "RevisionsRequestedByBorrower",
  SettlementCharges = "SettlementCharges",
  ToleranceCure = "ToleranceCure"
}

export enum DisclosureTrackingStatus {
  Active = "Active",
  Denied = "Denied"
}

export enum DisclosureVersion {
  None = "None",
  Initial = "Initial",
  Revised = "Revised"
}

export enum DocumentDeliveryMethod {
  CourierDeliveryService = "CourierDeliveryService",
  ElectronicDelivery = "ElectronicDelivery",
  InPerson = "InPerson",
  Other = "Other",
  USPSFirstClassMail = "USPSFirstClassMail"
}

export enum DisclosureSourceEnum {
  DocMagic = "DocMagic",
  LOS = "LOS"
}

export enum RefinancePurposeTypeEnum {
  ChangeInRateTerm = "ChangeInRateTerm",
  CashOutOther = "CashOutOther",
  CashOutHomeImprovement = "CashOutHomeImprovement",
  CashOutDebtConsolidation = "CashOutDebtConsolidation",
  CashOutLimited = "CashOutLimited",
  NoCashOutStreamlinedRefinance = "NoCashOutStreamlinedRefinance",
  NoCashOutFHAStreamlinedRefinance = "NoCashOutFHAStreamlinedRefinance",
  NoCashOutOther = "NoCashOutOther",
  NoCashOutFREOwnedRefinance = "NoCashOutFREOwnedRefinance",
  Other = "Other"
}

export enum RefinancePrimaryPurposeTypeEnum {
  DebtConsolidation = "DebtConsolidation",
  HomeImprovement = "HomeImprovement",
  Other = "Other",
  AssetAcquisition = "AssetAcquisition",
  CapitalizedInterestTaxesInsuranceOrFees = "CapitalizedInterestTaxesInsuranceOrFees",
  Cash = "Cash",
  Convenience = "Convenience",
  Education = "Education",
  EquityBuyout = "EquityBuyout",
  InterestRateReduction = "InterestRateReduction",
  MortgageTermReduction = "MortgageTermReduction",
  PayoffLeaseholdInterest = "PayoffLeaseholdInterest",
  PrimaryLienPayoff = "PrimaryLienPayoff",
  SecondaryLienPayoff = "SecondaryLienPayoff",
  SpecialPurpose = "SpecialPurpose",
  UnsecuredLienPayoff = "UnsecuredLienPayoff",
  Unspecified = "Unspecified"
}

export enum GovernmentRefinanceTypeEnum {
  Alternative = "Alternative",
  FullDocumentation = "FullDocumentation",
  HOPEforHomeowners = "HOPEforHomeowners",
  InterestRateReductionRefinanceLoan = "InterestRateReductionRefinanceLoan",
  PriorFHA = "PriorFHA",
  StreamlineWithAppraisal = "StreamlineWithAppraisal",
  StreamlineWithoutAppraisal = "StreamlineWithoutAppraisal",
  Unknown = "Unknown",
  Other = "Other",
  CashOutVA = "CashOutVA",
  Fha203K = "Fha203K",
  RateTerm = "RateTerm",
  Short = "Short",
  Simple = "Simple",
  StreamlineCredit = "StreamlineCredit",
  StreamlineNoCredit = "StreamlineNoCredit",
  LimitedDocumentation = "LimitedDocumentation",
  NoDepositVerification = "NoDepositVerification",
  NoDepositVerificationEmploymentVerificationOrIncomeVerification = "NoDepositVerificationEmploymentVerificationOrIncomeVerification",
  NoDocumentation = "NoDocumentation",
  NoEmploymentVerificationOrIncomeVerification = "NoEmploymentVerificationOrIncomeVerification",
  NoIncomeNoEmploymentNoAssetsOn1003 = "NoIncomeNoEmploymentNoAssetsOn1003",
  NoIncomeOn1003 = "NoIncomeOn1003",
  NoRatio = "NoRatio",
  NoVerificationOfStatedAssets = "NoVerificationOfStatedAssets",
  NoVerificationOfStatedIncome = "NoVerificationOfStatedIncome",
  NoVerificationOfStatedIncomeEmploymentOrAssets = "NoVerificationOfStatedIncomeEmploymentOrAssets",
  NoVerificationOfStatedIncomeOrAssets = "NoVerificationOfStatedIncomeOrAssets",
  NoVerificationOfStatedIncomeOrEmployment = "NoVerificationOfStatedIncomeOrEmployment",
  OnePaystub = "OnePaystub",
  OnePaystubAndOneW2AndVerbalVerificationOfEmploymentOrOneYear1040 = "OnePaystubAndOneW2AndVerbalVerificationOfEmploymentOrOneYear1040",
  OnePaystubAndVerbalVerificationOfEmployment = "OnePaystubAndVerbalVerificationOfEmployment",
  Reduced = "Reduced",
  StreamlineRefinance = "StreamlineRefinance",
  VerbalVerificationOfEmployment = "VerbalVerificationOfEmployment"
}
