<form #form='ngForm'>
  <div class='modal-header' #modalHeader>
    <h2 class='modal-title'>Add Fee</h2>

    <button
      (click)='onClickCancel()'
      aria-label='Close'
      class='btn-close'
      type='button'
    ></button>
  </div>

  <div class='modal-body overflow-visible'>
    <ng-container *ngIf='!isNonSpecificFee; else templateHudNumber'>
    <span class='form-group flex-grow-1'>
      <div class='d-flex gap-1 align-items-center justify-content-between'>
        <label for="fee-type">Fee Type</label>
        <ng-container *ngTemplateOutlet='nonSpecificCheckbox'></ng-container>
      </div>
      <ng-select
        #control='ngModel'
        (ngModelChange)='onChangeFeeTypeOption($event)'
        [class.is-invalid]='control.invalid && control.touched'
        id="fee-type"
        [items]='feeTypeOptions'
        [loading]='isLoadingOptions'
        [ngModel]='selectedFeeTypeOption'
        bindLabel='name'
        groupBy="groupName"
        name='feeType'
        placeholder='Select Fee Type'
        required
      ></ng-select>

      <span
        *ngIf='control.errors?.required'
        class='invalid-feedback'
      >
        Fee Type is required
      </span>
      <span
        *ngIf='control.errors?.conflict'
        class='invalid-feedback'
      >
        The Fee Type is already in use
      </span>
    </span>
    </ng-container>

    <ng-template #templateHudNumber>
      <span class='form-group flex-grow-1'>
        <div class='d-flex gap-1 align-items-center justify-content-between'>
          <label [for]='id("hud-number")'>HUD Number</label>
          <ng-container *ngTemplateOutlet='nonSpecificCheckbox'></ng-container>
        </div>
        <input
          #control='ngModel'
          (ngModelChange)='onChangeHudNumber($event)'
          [class.is-invalid]='control.invalid && control.touched'
          [dropSpecialCharacters]='false'
          [id]='id("hud-number")'
          [mask]='hudNumberInputConfig.mask'
          [minlength]='hudNumberInputConfig.minlength'
          [ngModel]='hudNumber'
          [patterns]='hudNumberInputConfig.patterns'
          [prefix]='hudNumberInputConfig.prefix'
          [showMaskTyped]='true'
          class='form-control'
          name='hudNumber'
          placeholder='Enter HUD Number'
          required
          type='text'
        />

        <span
          *ngIf='control.errors?.required'
          class='invalid-feedback'
        >
          HUD Number is required
        </span>
        <span
          *ngIf='control.errors?.conflict'
          class='invalid-feedback'
        >
          The HUD Number is already in use
        </span>
        <span
          *ngIf='control.errors?.minlength'
          class='invalid-feedback'
        >
          HUD Number must be {{ hudNumberInputConfig.minlength }} characters
        </span>
      </span>
    </ng-template>

    <ng-template #nonSpecificCheckbox>
      <span class='form-check d-flex flex-row gap-1 ms-0 align-items-center'>
        <input
          (ngModelChange)='onChangeIsNonSpecificFee($event)'
          [id]='id("non-specific-fee")'
          [ngModel]='isNonSpecificFee'
          class='form-check-input ms-0 my-auto'
          name='isNonSpecificFee'
          type='checkbox'
        />
        <label [for]='id("non-specific-fee")'>Non-specific Fee</label>
      </span>
    </ng-template>

    <span class='form-group col-span-2'>
      <div class='d-flex gap-1 align-items-center justify-content-between'>
        <label [for]='id("fee-name")'>Fee Name</label>
        <span
          *ngIf='!isNonSpecificFee'
          class='form-check d-flex flex-row gap-1 ms-0 align-items-center'
        >
          <input
            [ngModel]='isFeeNameFromType'
            (ngModelChange)='onChangeIsFeeNameFromType($event)'
            [id]='id("is-fee-name-from-type")'
            class='form-check-input ms-0 my-auto'
            name='isFeeNameFromType'
            type='checkbox'
          />
          <label [for]='id("is-fee-name-from-type")'>
            Same as Fee Type
          </label>
        </span>
      </div>
      <input
        #control='ngModel'
        [(ngModel)]='feeName'
        [class.is-invalid]='control.invalid && control.touched'
        [disabled]='isFeeNameFromType && !isNonSpecificFee'
        [id]='id("fee-name")'
        class='form-control'
        name='feeName'
        placeholder='Enter Fee Name'
        required
        type='text'
      />
      <span class='invalid-feedback'>Fee Name is required</span>
    </span>
  </div>

  <div class='modal-footer gap-2'>
    <button
      (click)='onClickCancel()'
      aria-label='Cancel'
      class='btn btn-secondary'
      type='button'
    >
      Cancel
    </button>

    <button
      (click)='onClickAdd()'
      [disabled]='isAddingFee'
      aria-label='Add'
      class='btn btn-primary'
      type='button'
    >
      <ng-container *ngIf='isAddingFee'>
        <span class='spinner-border spinner-border-sm me-1' role='status'></span>
      </ng-container>

      Add
    </button>
  </div>
</form>
