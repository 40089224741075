import { Injectable } from '@angular/core';
import { DataService } from '../core/services/data.service';
import { Observable } from 'rxjs/internal/Observable';
import { ValidESignTokenResponse } from '../models/valid-esign-token-response';
import { ValidateESignPropertyInfoResponse } from '../models/validate-esign-property-info-response';
import { CreateSignerViewRequest } from '../models/create-signer-view-request';
import { CreateSignerViewResponse } from '../models/create-signer-view-response';

@Injectable({
  providedIn: 'root'
})
export class SigningService {

  constructor(private readonly _dataService: DataService) { }

  openSignerView = (loanDocTaskId: number, data) => {
    const url = 'api/Signing/LoanDocTask/' + loanDocTaskId + '/OpenSignerView';
    return this._dataService.post(url, data);
  }

  openSenderView = (loanDocId: number, data) => {
    const url = 'api/Signing/LoanDoc/' + loanDocId + '/OpenSenderView';
    return this._dataService.post(url, data);
  }

  openCorrectView = (documentSigningOrderId: number, data) => {
    const url = 'api/Signing/order/' + documentSigningOrderId + '/open-view/correct';
    return this._dataService.post(url, data);
  }

  openEditView = (documentSigningOrderId: number, data) => {
    const url = 'api/Signing/order/' + documentSigningOrderId + '/open-view/edit';
    return this._dataService.post(url, data);
  }

  onSenderViewSubmitted = (urlParams: string) => {
    let url = `api/Signing/OnSenderViewSubmitted/${urlParams}`;
    return this._dataService.post(url, {});
  }

  OnSignerViewSubmitted = (urlParams: string) => {
    let url = `api/Signing/OnSignerViewSubmitted/${urlParams}`;
    return this._dataService.post(url, {});
  }

  // e-signing
  confirmEsignToken = (eSignToken: string): Observable<ValidESignTokenResponse> => {
    let url = `api/Signing/signing/confirm-esign-token/${eSignToken}`;
    return this._dataService.post(url, {});
  }

  validateLoanInfo = (eSignToken: string, subjectPropertyZip: string): Observable<ValidateESignPropertyInfoResponse> => {
    let url = `api/Signing/signing/validate-loan-info/${eSignToken}?subjectPropertyZip=${subjectPropertyZip}`;
    return this._dataService.post(url, {});
  }

  openEsignerViewForAnonymousEsign = (
    eSignToken: string,
    privateToken: string,
    loanDocTaskId: number,
    request: CreateSignerViewRequest
  ): Observable<CreateSignerViewResponse> => {
    return this._dataService.postWithoutAuth(
      `api/Signing/signing/LoanDocTask/${loanDocTaskId}/open-signer-view/${eSignToken}?pt=${privateToken}`,
      request
    );
  };

  onEsignerViewSubmitted = (eSignToken: string, loanDocTaskId: number, action: string): Observable<any> => {
    let url = `api/Signing/signing/on-signer-view-submitted/${eSignToken}?action=${action}&loanDocTaskId=${loanDocTaskId}`;
    return this._dataService.postWithoutAuth(url, {});
  }
}
