<!-- TODO -->


<div class="row">
  <div class="col-sm mb-2">
    <ng-multiselect-dropdown name="chatMediumFilter" id="chatMediumFilter" [placeholder]="'-- Filter --'"
      [settings]="multiSelectSettings" [data]="chatMediums" [(ngModel)]="selectedChatMediums"
      (ngModelChange)="onSelectedChatMediumsChanged()" #chatMediumFilter="ngModel">
    </ng-multiselect-dropdown>
  </div>
  <div class="col-sm-auto mb-2 d-flex align-items-center">
    <div class="form-check form-switch mb-0">
      <input [(ngModel)]="showArchive" class="form-check-input" type="checkbox"
          id="showArchiveToggle" name="showArchiveToggle" (change)="onShowArchiveChanged();"/>
    </div>
    <span class="form-label mb-0">Show Archived</span>
  </div>
</div>

<div class="row">
  <div class="col-sm-12 mb-2">
    <form name="searchForm" id="searchForm" class='search-form' action="#" method="get">
      <i class='ti-search search-icon'></i>
      <input type="search" name="searchInput" id="searchInput" [formControl]='searchControl'
        class="form-control top-search mb-0 search-input" placeholder="Search in conversations">
    </form>
  </div>
</div>

<div class="row">
  <div class="col-sm ms-1 mb-2 d-flex align-items-center">
    <div class="form-check form-switch mb-0">
      <input [(ngModel)]="filterByUnreadMessages" class="form-check-input" type="checkbox"
          id="filterByUnreadMessagesToggle" name="filterByUnreadMessagesToggle" (change)="filterConversations()"/>
    </div>
    <span class="form-label mb-0">Filter By Unread Messages</span>
  </div>
  <div class="col-sm-auto mb-2 d-flex align-items-center">
    <button class="btn btn-primary btn-sm" (click)="markAllAsRead()" [disabled]="isMarkingMessagesAsRead">
      <i *ngIf="isMarkingMessagesAsRead" class="fa fa-sync-alt fa-spin"></i> Mark All as Read
    </button>
  </div>
</div>

<div class="row" *ngIf="usersReportingTo">
  <div class="col-sm-12 mb-2">
    <select class="form-select" name="conversations-list-dropdown" id="conversations-list-dropdown"
      [(ngModel)]="impersonatedUser" (ngModelChange)="onImpersonatedUserChanged()">
      <option value=""> {{ impersonatedUser ? '-- Stop Impersonating --' : '-- Impersonate User --' }} </option>
      <option *ngFor="let option of usersReportingTo" [ngValue]="option.userId">
        {{option.firstName}} {{option.lastName}}</option>
    </select>
  </div>
</div>

<div class="row" *ngIf="conversationOwners.length != 0">
  <div class="col-sm-12 mb-2">
    <select class="form-select" name="conversation-owner-list-dropdown" id="conversation-owner-list-dropdown"
            [(ngModel)]="selectedConvoOwner" (ngModelChange)="filterConversations()">
      <option value=""> {{ selectedConvoOwner ? '-- Stop Filtering By Conversation Owner --' : '-- Filter By Conversation Owner --'}} </option>
      <option *ngFor="let option of conversationOwners" [ngValue]="option.userCompanyGuid">
        {{option.firstName}} {{option.lastName}}</option>
    </select>
  </div>
</div>

<div class="row conversation-list-container" style="height: 100%;">
  <infinite-scroll (scrolled)="onScroll()" [height]="conversations.length > 0 ? '10px' : '0px'">
    <div class="col-sm-12 mb-2 mt-2" *ngIf="!reOrdering">
      <conversation-list-item
        *ngFor="let conversation of conversations | orderBy: 'mostRecentMessageDate': 'desc'"
        [conversation]="conversation" (conversationSelected)="onConversationSelected($event)">
      </conversation-list-item>
      <div *ngIf="isLoadingMore && !allRecordsHaveBeenLoaded"
        style="text-align: center; display: flex; align-items: center; justify-content: center; height: 25px;">
        Fetching 1000 more conversations...
        <span class="spinner-border spinner-border-sm ml-4" role="status" aria-hidden="true"></span>
      </div>
      <div *ngIf="allRecordsHaveBeenLoaded && conversations.length > 0" class="all-loaded-message">
        All conversations have been loaded
      </div>
      <div *ngIf="!isLoadingMore && allRecordsHaveBeenLoaded && !hasNoSearchResult && !conversations.length" class="no-conversation">
        No conversation found
      </div>
      <div *ngIf='hasNoSearchResult' class='no-search-result'>
        No search result for "{{searchControl.value}}"
      </div>
    </div>
  </infinite-scroll>
</div>
