import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import {
  ApplicationContext,
  CreditReport,
  CreditVendor,
  CreditVendorEnum,
  LoanApplication,
  LosAppOperationResult,
  MortgageBorrower,
  ThirdPartyCredential,
} from 'src/app/models';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { CreditBorrower } from '../../../credit/models/credit-borrower.model';
import { ServicesPermissions } from '../../../credit/models/services-permissions.model';
import { ImportLiability } from '../../../credit/models/import-liability.model';
import { firstValueFrom, Subscription } from 'rxjs';
import { MenuService } from 'src/app/services/menu.service';
import { AdminService } from 'src/app/services/admin.service';
import { CreditService } from '../../../credit/services/credit.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';
import * as _ from 'lodash';
import { cloneDeep, isEmpty } from 'lodash';
import { MenuItemStatus } from 'src/app/modules/tpo/models/enums/menu-item-status.enum';
import { Constants } from 'src/app/services/constants';
import { LosService } from '../../../../../services/los.service';
import { formatDate } from '@angular/common';
import { AppDetailsService } from 'src/app/modules/app-details/services/app-details.service';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { getErrorMessageOrDefault } from 'src/app/shared/utils/error-utils';

@Component({
  selector: 'loan-credit',
  templateUrl: './loan-credit.component.html',
  styleUrls: ['./loan-credit.component.scss']
})
export class LoanCreditComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  embedded: boolean = false;

  tab: 'CreditRun' | 'CreditReportHistory' | 'Liabilities' = 'CreditRun';

  loanStatusType: string;

  isTpoUser: boolean;

  isServicesEnabled: boolean = true;

  creditInfoId: number;

  borrowers: CreditBorrower[];

  borrowerGroups: CreditBorrower[][] = [];

  creditVendors: ThirdPartyCredential[];

  creditVendor: string | CreditVendor;

  servicesPermissions: ServicesPermissions;

  loadingHistory: boolean = false;

  isLatestRunCalculationsFinished: boolean = false;

  creditHistory: CreditReport[] = [];

  liabilitiesHistory: ImportLiability[];

  permissionLoaded: boolean;

  companyId: number;

  isPrequalificationCheckReadonly: any = {};

  protected prequalificationCheckLoading: boolean;

  protected isLoanReadOnly: boolean = false;
  protected isCompanyDeephaven: boolean = false;

  protected showSaveButtonForCreditAuthInfo: boolean;

  protected mortgage: UrlaMortgage;

  protected application: LoanApplication;

  private _loanInfoChangesSubscription: Subscription;

  private _borrowerCreditAuthInfo: Map<number, CreditAuthInfo> = new Map<number, CreditAuthInfo>();

  @Output()
  creditRunCompleted: EventEmitter<any> = new EventEmitter<any>();

  private get _shouldPullFromLos(): boolean {
    return this.creditVendor === CreditVendorEnum.EncompassCredit;
  }

  constructor(
    injector: Injector,
    private readonly _menuService: MenuService,
    private readonly _adminService: AdminService,
    private readonly _creditService: CreditService,
    private readonly _losService: LosService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _appContextService: ApplicationContextService,
    private readonly _enumsService: EnumerationService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.companyId = this.applicationContext.userPermissions.companyId;
    if (this.applicationContext?.application?.applicationId) {
      this.isLoanReadOnly = this.applicationContext.applicationIsReadOnly;
      this.getInitData(this.applicationContext);
    }
    this.creditInfoId = null;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  onAuthorizedCreditChanged = (borrower: MortgageBorrower) => {
    const oldValue = this._borrowerCreditAuthInfo.get(borrower.borrowerId).authorizedCreditCheck;
    this.showSaveButtonForCreditAuthInfo = oldValue !== borrower.authorizedCreditCheck;
    const today = new Date();
    borrower.dateAuthorizedCreditCheck = borrower.authorizedCreditCheck ? formatDate(today, 'MM/dd/yyyy', 'en-US') : undefined;
    if (!borrower.authorizedCreditCheck) {
      borrower.authorizationMethod = undefined;
    }
  }

  onCreditAuthMethodChanged = (borrower: MortgageBorrower) => {
    const oldValue = this._borrowerCreditAuthInfo.get(borrower.borrowerId).authorizationMethod;
    this.showSaveButtonForCreditAuthInfo = oldValue !== borrower.authorizationMethod;
  }

  onCreditAuthDateChanged = (borrower: MortgageBorrower) => {
    const oldValue = this._borrowerCreditAuthInfo.get(borrower.borrowerId).dateAuthorizedCreditCheck;
    this.showSaveButtonForCreditAuthInfo = oldValue !== borrower.dateAuthorizedCreditCheck;
  }

  loadHistory = () => {
    this.loadingHistory = true;

    const result = this._creditService.getCreditReportHistory(true, this.application?.applicationId);

    result.subscribe({
      next: (result) => {
        result.forEach(r => {
          r['dateToSortWith'] = new Date(r.date);
        })
        this.creditHistory = _.orderBy(result, ['dateToSortWith', 'creditID', 'joint'], ['desc', 'desc', 'desc']);

        if (this.borrowers != null) {
          this.borrowers.forEach((borrower) => {
            borrower.latestCreditReport = new CreditReport();
            const borrowerHistories = this.creditHistory.filter(history => history.borrowerId === borrower.borrowerId);
            if (borrowerHistories.length > 0) {
              borrower.latestCreditReport = borrowerHistories[0];
            }
          });
          this.isLatestRunCalculationsFinished = true;
        }
        this.setStatusIcons();

        this.loadingHistory = false;
      },
      error: (error) => {
        this.loadingHistory = false;
        this._notifyService.showError(error?.message || "Couldn't load credit report history.", "Error!");
      }
    });
  }

  onCreditRunCompleted = async (isSuccess: boolean) => {
    if (isSuccess) {
      const losLoan = await this.onAfterCreditRunSuccessfullyCompleted();
      if (losLoan) {
        this.applicationContextService.updateMortgageAndApplication(
          losLoan.application?.mortgageLoan,
          losLoan.application,
          losLoan.customData
        );
      } else {
        this.applicationContextService.reloadApplicationAndMortgagePostAction(this.application?.applicationId).subscribe();
      }
      this.initializeCreditDataForLoan();
    }
    this.creditRunCompleted.emit();
  }

  onAfterCreditRunSuccessfullyCompleted = async (): Promise<LosAppOperationResult> => {
    try {
      const creditVendors = await firstValueFrom(this._creditService.getCreditVendorsForLoan(this.application?.applicationId));
      this.creditVendors = creditVendors || [];
    } catch (err) {
      this._notifyService.showError(
        getErrorMessageOrDefault(err, {
          defaultMessage: 'An error occurred while getting credit vendors for the application.',
        }),
        'Error!'
      );
    }

    if (!this._shouldPullFromLos) {
      return;
    }
    try {
      const losLoan = await firstValueFrom(this._losService.pullFromLos(this.application?.applicationId));
      if (losLoan) {
        this._notifyService.showSuccess('Credit data pulled from Encompass and credit run completed successfully.', 'Success');
        return losLoan;
      }
    } catch (err) {
      this._notifyService.showError(
        getErrorMessageOrDefault(err, {
          defaultMessage: 'An error occurred while pulling the application from LOS.',
        }),
        'Error!'
      );
    }
  };

  onReissueSucceeded = (integrationHistoryId: number) => {
    const creditRun = this.creditHistory.find(history => history.integrationHistoryId === integrationHistoryId);
    if (creditRun) {
      this.openTab('CreditRun');
    }

    this.applicationContextService.reloadApplicationAndMortgagePostAction(this.application?.applicationId).subscribe();
  }

  onImportLiabilitiesClicked = (creditInfoId: number) => {
    this.creditInfoId = creditInfoId;
    const creditInfo = this.creditHistory.find(cH => cH.creditID === creditInfoId);
    if (creditInfo) {
      this._spinner.show();
      this._creditService.getLiabilitiesHistory(this.creditInfoId).subscribe(result => {
        if (!result || !result.liabilitiesBySocialSecurityNumber) {
          this._notifyService.showWarning("No liabilities found in credit report", 'Warning');
          this._spinner.hide();
          return;
        }
        const keys = Object.keys(result.liabilitiesBySocialSecurityNumber);
        this.liabilitiesHistory = result.liabilitiesBySocialSecurityNumber;
        this._spinner.hide();
        if (keys && keys.length > 0) {
          this.openTab('Liabilities');
        } else {
          this._notifyService.showWarning("No liabilities found in credit report", 'Warning');
        }

      }, (err) => {
        this._spinner.hide();
        this._notifyService.showError(err ? err.error.message || err.message : '', 'Error');
      });
    }
  }

  onYesNoOptionChanged = (borrower) => {
    // FIXME: If the today date is changed after component init, the dirty check
    //  will be broken.
    const today = new Date();
    borrower.dateAuthorizedCreditCheck = borrower.authorizedCreditCheck ? formatDate(today, 'MM/dd/yyyy', 'en-US') : undefined;
  }

  openTab = (name: 'CreditRun' | 'CreditReportHistory' | 'Liabilities') => {
    if (name !== "Liabilities") {
      this.creditInfoId = null;
    }
    if (this.tab !== name) {
      this.tab = name;
    }
  };

  saveLoanMortgage = async () => {
    this.prequalificationCheckLoading = true;
    const application = this.application;
    const fallbackMortgage = this.mortgage;

    try {
      const loanInfo = {
        application,
        customData: null,
      };

      await this._spinner.show();
      const result = await firstValueFrom(
        this._appDetailsService.saveLoanInfo(
          application.applicationId,
          loanInfo,
        ),
      );
      const applicationResult = result.application;

      this._appContextService.updateMortgageAndApplication(
        applicationResult.mortgageLoan,
        applicationResult,
      );

      this._notifyService.showSuccess(
        'Mortgage has been saved successfully.',
        'Success!'
      );

      return true;
    }
    catch (e) {
      application.mortgageLoan = fallbackMortgage;
      const message = (e.error?.message || e.message) || 'An error occurred.';

      this._notifyService.showError(
        message,
        'Error!',
      );
      this.prequalificationCheckLoading = false;
      return false;
    } finally {
      await this._spinner.hide();
      this.prequalificationCheckLoading = false;
    }
  }

  private getInitData = (context: ApplicationContext) => {
    this.application = context.application;
    this.isTpoUser = context.isTpo;
    this.borrowers = context.borrowers as unknown as CreditBorrower[];
    this.mortgage = context.currentMortgage;

    this.initializeBorrowerCreditAuthInfo();

    this.mortgage.borrowers.forEach((borrower: MortgageBorrower) => {
      const authorizationMethods = cloneDeep(this._enumsService.authorizationMethods);
      if (borrower.authorizationMethod !== "Internet") {
        const indexForInternetOption = authorizationMethods.findIndex(m => m.value === 'Internet');
        if (indexForInternetOption >= 0) {
          authorizationMethods.splice(indexForInternetOption, 1);
        }
      }
      borrower['authorizationMethods'] = authorizationMethods;
    });
    this.initializeCreditDataForLoan();
  };

  private initializeCreditDataForLoan = () => {
    this.loadingHistory = true;
    this._creditService.getCreditVendorsForLoan(this.application?.applicationId).subscribe({
      next: (creditVendors) => {
        const creditBorrowers: CreditBorrower[] = this.applicationContext.borrowers as any[];
        this.initialize(creditBorrowers, creditVendors);
      },
      error: (error) => {
        this._notifyService.showError(error?.message || "Couldn't load data.", "Error!");
        this.loadingHistory = false;
      }
    });
  }

  private initialize = (borrowers: CreditBorrower[], creditVendors: ThirdPartyCredential[]) => {
    this.isCompanyDeephaven = this.applicationContext.isCompanyDeepHaven;
    this.borrowers = borrowers || [];
    this.borrowerGroups = [];
    this.borrowerGroups = borrowers.length > 0 ? Object.values(_.groupBy(borrowers, 'mortgageAppId')) : [];
    this.creditVendors = creditVendors?.filter(vendor =>
      vendor.vendorName === CreditVendorEnum.MeridianLinkSoftPull ||
      vendor.vendorName === CreditVendorEnum.MeridianLinkHardPull ||
      vendor.vendorName === CreditVendorEnum.Xactus ||
      vendor.vendorName === CreditVendorEnum.FactualDataHardPull ||
      vendor.vendorName === CreditVendorEnum.FactualDataSoftPull ||
      vendor.vendorName === CreditVendorEnum.EncompassCredit ||
      vendor.vendorName === CreditVendorEnum.ISoftPull ||
      vendor.vendorName === CreditVendorEnum.CredCoSoftPull ||
      vendor.vendorName === CreditVendorEnum.CredCoHardPull
    ) || [];

    if (this.isTpoUser) {
      this.getPermissions();
    } else {
      this.setPermissionsForAdminUser();
    }
    this.loadHistory();
  }

  private getPermissions = () => {
    this._adminService.getTpoConfiguration()
      .subscribe({
        next: (result) => {
          this.servicesPermissions = {
            ausPermissions: result.ausPermissions,
            creditPermissions: result.creditPermissions,
            isVOAAllowed: result.isVOAAllowed,
            isVOIVOEAllowed: result.isVOIVOEAllowed,
            columnSelectorEnabled: result.columnSelectorEnabled,
            isPullEnabled: true,
            isLinkedToLos: this.loanStatusType === "linked"
          }

          this.isServicesEnabled = this.servicesPermissions.creditPermissions !== "0" ||
            this.servicesPermissions.ausPermissions !== "0" ||
            this.servicesPermissions.isVOAAllowed ||
            this.servicesPermissions.isVOIVOEAllowed
          this.permissionLoaded = true;
        }, error: (err) => {
          this._notifyService.showError(err?.message || 'unable to load TPO configurations', 'Error');
          this.permissionLoaded = false;
        }
      });
  }

  private initializeBorrowerCreditAuthInfo = () => {
    this.mortgage.borrowers.forEach((borrower) => {
      this._borrowerCreditAuthInfo.set(borrower.borrowerId, {
        authorizedCreditCheck: borrower.authorizedCreditCheck,
        authorizationMethod: borrower.authorizationMethod,
        dateAuthorizedCreditCheck: borrower.dateAuthorizedCreditCheck
      });
    });
    this.showSaveButtonForCreditAuthInfo = false;
  }

  private setPermissionsForAdminUser = () => {
    this.servicesPermissions = {
      ausPermissions: "1",
      creditPermissions: "2",
      isVOAAllowed: true,
      isVOIVOEAllowed: true,
      columnSelectorEnabled: true,
      isPullEnabled: true,
      isLinkedToLos: true
    }
    this.permissionLoaded = true;
  }

  private setStatusIcons = () => {
    const failedCredits = this.borrowers.filter(borrower => borrower.latestCreditReport && !isEmpty(borrower.latestCreditReport) && !borrower.latestCreditReport.successful);
    const noCreditHistory = this.borrowers.filter(borrower => !borrower.latestCreditReport || isEmpty(borrower.latestCreditReport));
    const successfulRunCredit = failedCredits.length == 0 && noCreditHistory.length == 0;
    const noCreditRecord = failedCredits.length == 0 && noCreditHistory.length > 0;

    let status = MenuItemStatus.Loading;

    if (failedCredits.length > 0) {
      status = MenuItemStatus.Error;
    } else if (noCreditRecord) {
      status = MenuItemStatus.Pending;
    } else if (successfulRunCredit) {
      status = MenuItemStatus.Success;
    }
    this._menuService.setStatus(Constants.menu.servicesMenuItems.credit, status);
  }
}

export class CreditAuthInfo {
  authorizedCreditCheck: boolean;
  authorizationMethod: string;
  dateAuthorizedCreditCheck: string;
}
