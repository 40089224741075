export module CustomDataUtils {
  const singleSelectOperatorTypes = getMultiSelectOperatorTypes();
  const selectFieldTypes = getSelectFieldTypes();

  export function getMultiSelectOperatorTypes(): Set<string> {
    return new Set(['In', 'NotIn', 'ArrayContainsAny', 'ArrayContainsAll']);
  }

  export function isMultiSelectOperatorType(operatorType: string): boolean {
    return singleSelectOperatorTypes.has(operatorType);
  }

  export function getSelectFieldTypes(): Set<string> {
    return new Set([
      'ExternalCompany',
      'LoanType',
      'LoanPurpose',
      'LoanStatus',
      'AgentLoanStatus',
      'LeadStatus',
      'User',
      'Product',
      'Lender',
      'LookupList',
      'LeadCampaign',
      'Branch',
      'LeadRoute',
      'LeadRouteGroup',
      'RedistributionRoute',
      'RedistributionRouteGroup',
      'TimeZone',
      'SubStatus',
      'Channel',
      'ChannelFlags',
      'CompanyStatus',
      'AgentTags',
      'CompensationType',
      'DisclosurePath',
      'IncomeVerificationMethod',
      'IncomeVerificationType',
      'DocumentationType',
      'MortgagePartyType',
      'MortgageEntityType',
      'EntityVestingType',
      'ContactTrackingTag',
    ]);
  }

  export function isSelectFieldType(fieldType: string): boolean {
    return selectFieldTypes.has(fieldType);
  }
}
