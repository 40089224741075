import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { initializeApp } from 'firebase/app';
import { ImageCropperModule } from 'ngx-image-cropper';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { CompanySwitchComponent } from './components/company-switch/company-switch.component';
import { EmailUnsubscribeComponent } from './components/email-unsubscribe/email-unsubscribe.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginCompanyPickerDialogComponent } from './components/login-company-picker-dialog/login-company-picker-dialog.component';
import { LoginComponent } from './components/login/login.component';
import { MicrosoftLoginRedirectComponent } from './components/microsoft-login-redirect/microsoft-login-redirect.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SettingComponent } from './components/setting/setting.component';
import { CoreModule } from './core/core.module';
import { DataService } from './core/services/data.service';
import { DocumentService } from './core/services/document.service';
import { httpInterceptorProviders } from './core/services/http-interceptors';
import { LocalStorageService } from './core/services/local-storage.service';
import { LayoutModule } from './layout/layout.module';
import { AgentTypeService } from './modules/admin/company/services/agent-type.service';
import { AlignmentService } from './modules/admin/company/services/alignment.service';
import { CompanyUsersService } from './modules/admin/company/services/company-users.service';
import { RolesService } from './modules/admin/company/services/roles.service';
import { EmailCampaignConfigService } from './modules/admin/email-campaign-config/services/email-campaign-config.service';
import { AmortizationModule } from './modules/amortization/amortization.module';
import { BorrowerModule } from './modules/borrower/borrower.module';
import { ChangeOfCircumstanceModule } from './modules/change-of-circumstance/change-of-circumstance.module';
import { ConditionsTasksModule } from './modules/conditions-tasks/conditions-tasks.module';
import { DialerService } from './modules/dialer/services/dialer.service';
import {
  DocumentSigningRequestConfirmationComponent,
} from './modules/document-signing/document-signing-request-confirmation/document-signing-request-confirmation.component';
import { ExpressionBuilderModule } from './modules/expression-builder/expression-builder.module';
import { FeesModule } from './modules/fees/fees.module';
import { FileContactsModule } from './modules/file-contacts/file-contacts.module';
import { NextGenLayoutModule } from './modules/loda-nextgen/layout/nextgen-layout.module';
import { NewApplicationModule } from './modules/new-application/new-application.module';
import { PricingModule } from './modules/pricing/pricing.module';
import { TpoModule } from './modules/tpo/tpo.module';
import { AgentService } from './services/agent.service';
import { AuthService } from './services/auth.service';
import { ChannelService } from './services/channel.service';
import { ChatService } from './services/chat.service';
import { CheckListService } from './services/check-list.service';
import { ContactListService } from './services/contact-list.service';
import { DocumentTemplateService } from './services/document-template.service';
import { EmailService } from './services/email.service';
import { EnumerationService } from './services/enumeration-service';
import { FirebaseMessageService, FirebaseService, FirebaseServicesService } from './services/firebase';
import { GlobalConfigService } from './services/global-config.service';
import { LeadRouteService } from './services/lead-route.service';
import { LoanService } from './services/loan';
import { LogService } from './services/log.service';
import { LosService } from './services/los.service';
import { MentionsService } from './services/mentions.service';
import { MenuService } from './services/menu.service';
import { MortgageCallReportService } from './services/mortgage-call-report.service';
import { MortgageService } from './services/mortgage.service';
import { NavigationService } from './services/navigation.service';
import { NotificationService } from './services/notification.service';
import { PipelineService } from './services/pipeline.service';
import { ProductService } from './services/product.service';
import { SignalRService } from './services/signalr.service';
import { SystemLevelService } from './services/system-level.service';
import { UserService } from './services/user.service';
import { ZipCodeService } from './services/zipcode.service';
import { SharedModule } from './shared/shared.module';
import { ThemeModule } from './theme/theme.module';
import { MergeFieldsService } from './services/merge-fields.service';
import { ErrorComponent } from './components/error/error.component';
import {
  DocumentESigningRequestConfirmationComponent,
} from './modules/document-signing/document-esigning-request-confirmation-component/document-esigning-request-confirmation-component';
import { SessionTimeoutComponent } from './components/session-timeout/session-timeout.component';
import { SessionTimeoutSyncComponent } from './components/session-timeout/session-timeout-sync/session-timeout-sync.component';
import { TitleService } from './services/title.service';
import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { TpoConfigService } from './modules/admin/tpo-config/services/tpo-config.service';
import { ThirdPartyCredentialsService } from './services/third-party-credentials.service';
import { StoredFileService } from './services/stored-file.service';
import { CalendarDaysService } from './services/calendar-days.service';
import { EmailPreferenceCenterService } from './modules/admin/email-campaign-config/services/email-preference-center.service';
import { DashboardV2Service } from './modules/dashboardv2/services/dashboardv2.service';
import { ContactsService } from './modules/contacts/services/contacts.service';
import { LeadsService } from './modules/leads/services/leads.service';
import { InvoicingModule } from './modules/invoicing/invoicing.module';
import { MortgageCtoFeeWizardModule } from './modules/fees/mortgage-cto-fee-wizard/mortgage-cto-fee-wizard.module';
import { LenderPriceMockModule } from './components/lenderprice-mock/lenderprice-mock.module';
import { MiQuoteService } from './services/mi-quote.service';
import { ShortLinkRedirectComponent } from './components/short-link-redirect/short-link-redirect.component';
import { ExternalAuthCallbackComponent } from './components/ext-auth-callback/ext-auth-callback.component';
import { TagManagementService } from './modules/admin/tag-management/services/tag-management.service';
import { ESignViaTokenComponent } from './components/e-sign-via-token/e-sign-via-token.component';
import { ESignViaTokenConfirmationComponent } from './components/e-sign-via-token-confirmation/e-sign-via-token-confirmation.component';
import { UWDecisionModule } from './modules/uw-decision/uw-decision.module';

const firebaseConfig = environment.firebase;
delete firebaseConfig.vapidKey
initializeApp(firebaseConfig);

const MODULES = [ThemeModule, LayoutModule, ExpressionBuilderModule, NextGenLayoutModule];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ExternalAuthCallbackComponent,
    CompanySwitchComponent,
    LoginCompanyPickerDialogComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    DocumentSigningRequestConfirmationComponent,
    EmailUnsubscribeComponent,
    ErrorComponent,
    DocumentESigningRequestConfirmationComponent,
    MicrosoftLoginRedirectComponent,
    SessionTimeoutComponent,
    SessionTimeoutSyncComponent,
    ShortLinkRedirectComponent,
    ESignViaTokenComponent,
    ESignViaTokenConfirmationComponent
  ],
  imports: [
    ReactiveFormsModule,
    CoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    TpoModule,
    NewApplicationModule,
    RouterModule,
    PricingModule,
    FeesModule,
    AmortizationModule,
    ConditionsTasksModule,
    ChangeOfCircumstanceModule,
    UWDecisionModule,
    BorrowerModule,
    ImageCropperModule,
    FileContactsModule,
    InvoicingModule,
    ...MODULES,
    MortgageCtoFeeWizardModule,
    LenderPriceMockModule,
  ],
  providers: [
    LoanService,
    UserService,
    AuthService,
    LocalStorageService,
    NavigationService,
    MenuService,
    GlobalConfigService,
    DataService,
    EnumerationService,
    MortgageService,
    PipelineService,
    SystemLevelService,
    RolesService,
    NotificationService,
    AgentTypeService,
    CompanyUsersService,
    LosService,
    AlignmentService,
    ChannelService,
    CheckListService,
    ContactListService,
    DocumentService,
    httpInterceptorProviders,
    AgentService,
    TagManagementService,
    ContactsService,
    LeadsService,
    ZipCodeService,
    DocumentTemplateService,
    EmailService,
    ChatService,
    DialerService,
    LogService,
    SignalRService,
    FirebaseMessageService,
    FirebaseServicesService,
    FirebaseService,
    LeadRouteService,
    MentionsService,
    ProductService,
    EmailCampaignConfigService,
    EmailPreferenceCenterService,
    MortgageCallReportService,
    MergeFieldsService,
    TitleService,
    TpoConfigService,
    CurrencyPipe,
    PercentPipe,
    DecimalPipe,
    ThirdPartyCredentialsService,
    StoredFileService,
    CalendarDaysService,
    DashboardV2Service,
    MiQuoteService,
    { provide: Window, useValue: window },
    // {
    //   provide: ErrorHandler,
    //   useClass: GlobalErrorHandler,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
