<form #customDataSettingForm="ngForm" novalidate id="customDataSettingForm" name="customDataSettingForm">
  <div class="row mb-3">
    <div class="col-md-3">
      <input type="text" class="form-control" [(ngModel)]="customDataConfigModel.fieldName" required
        name="fieldName_{{number}}" #fieldName="ngModel"  [pattern]="alphaNumericRegex"
        [ngClass]="{'is-invalid' : fieldName && fieldName.touched && fieldName.invalid}">
      <div class="invalid-feedback" *ngIf="fieldName.errors?.['pattern']">FieldName can only contain letters, numbers, and underscores.</div>
      <div class="invalid-feedback" *ngIf="fieldName.errors?.['required']">FieldName is required</div>
    </div>
    <div class="col-md-3">
      <input type="text" class="form-control" name="labelText_{{number}}" [(ngModel)]="customDataConfigModel.labelText">
    </div>
    <div class="col-md-2">
      <select class="form-select standard" [(ngModel)]="customDataConfigModel.fieldType"
        (ngModelChange)="onDataTypeChanged()" name="fieldType_{{number}}">
        <option [ngValue]="0">-- Select One --</option>
        <option *ngFor="let item of fieldTypeOptions" value={{item.value}}>
          {{item.name}}
        </option>
      </select>
    </div>
    <div class="col-md-2">
      <select class="form-select standard" [(ngModel)]="customDataConfigModel.editorType"
        [disabled]="!filteredEditorTypeOptions || filteredEditorTypeOptions.length === 0"
        name="editorType_{{number}}">
        <option [ngValue]="0">-- Select One --</option>
        <option *ngFor="let item of filteredEditorTypeOptions" value={{item.value}}>
          {{item.name}}
        </option>
      </select>
    </div>
    <div class="col-md-2 pt-1">
      <button *ngIf="['Multiselect', 'Select'].includes(customDataConfigModel.editorType)" type="button" class="btn btn-soft-primary btn-sm" (click)="openCustomDataItemsModal()">
        Customize Items
      </button>
      <button *ngIf="customDataConfigModel.customDataFieldConfigId"
        type="button" class="pull-right btn btn-soft-danger btn-sm"
        (click)="removeCustomData()"
        [disabled]="removing">
        {{removing ? 'Removing' : 'Remove'}}
      </button>
      <button *ngIf="!customDataConfigModel.customDataFieldConfigId"
        type="button" class="pull-right btn btn-soft-success btn-sm"
        (click)="saveCustomData()"
        [disabled]="saving">
        {{saving ? 'Saving' : 'Save'}}
      </button>
    </div>
  </div>
</form>
