import { CustomData } from "src/app/models";
import { IHaveName } from "src/app/models/have-name.interface";
import { MilitaryStatuses } from "src/app/services/constants";
import { ContactTrackingTag } from "../../admin/tag-management/models/tag.model";

export class Lead implements IHaveName {
  leadId: number = null;
  borrowerId: number;
  coBorrowerId: number;
  applicationId: number;
  attachmentType: PropertyAttachmentType = PropertyAttachmentType.Detached;
  branchId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  nickname: string;
  suffix: string;
  socialSecurityNumber: string;
  birthDate: Date;
  email: string;
  mobilePhone: string;
  phone: string;
  workPhone: string;
  fax: string;
  hasServedInMilitary: boolean;
  militaryStatus: MilitaryStatuses;
  militaryTourEndDate: Date;
  isVaFundingFeeExempt: boolean;
  isFirstUseOfVaBenefit: boolean;
  isPlannedUnitDevelopment: boolean;
  isSurvivingSpouseOfMilitary: boolean;
  presentAddress1: string;
  presentAddress2: string;
  presentCity: string;
  presentState: string;
  presentZip: string;
  mailingAddress1: string;
  mailingAddress2: string;
  mailingCity: string;
  mailingState: string;
  mailingZip: string;
  bestTimeToContact: string;
  bankruptcyType: number;
  hasForeclosure: boolean;
  coFirstName: string;
  coMiddleName: string;
  coLastName: string;
  coSuffix: string;
  coSocialSecurityNumber: string;
  coBirthDate: Date;
  coCreditScore: number;
  coEmail: string;
  coMobilePhone: string;
  coPhone: string;
  coWorkPhone: string;
  coFax: string;
  coHasServedInMilitary: boolean;
  coMilitaryStatus: MilitaryStatuses;
  coMilitaryTourEndDate: Date;
  coIsVaFundingFeeExempt: boolean;
  coIsFirstUseOfVaBenefit: boolean;
  coIsSurvivingSpouseOfMilitary: boolean;
  coPresentAddress1: string;
  coPresentAddress2: string;
  coPresentCity: string;
  coPresentState: string;
  coPresentZip: string;
  coMailingAddress1: string;
  coMailingAddress2: string;
  coMailingCity: string;
  coMailingState: string;
  coMailingZip: string;
  coBestTimeToContact: string;
  coBankruptcyType: number;
  coHasForeclosure: boolean;
  constructionMethod: PropertyConstructionMethod = null;
  creditScore: number;
  subjectPropertyAddress1: string;
  subjectPropertyAddress2: string;
  subjectPropertyCity: string;
  subjectPropertyState: string;
  subjectPropertyZip: string;
  purchasePrice: number;
  yearOfPurchase : number;
  loanAmount: number;
  appraisedValue: number;
  cashOutAmount: number;
  downPayment: number;
  interestRate: number;
  loanTypeId: number = null;
  loanPurposeId: number = null;
  firstTimeHomeBuyer: boolean;
  purchaseTimeFrame: string;
  projectType: ProjectType = null;
  propertyFound: boolean;
  propertyUse: LeadPropertyUse = null;
  propertyWillBe: PropertyWillBeType = null;
  channel: Channel = null;
  loanRequestPurpose : string;
  currentLoanUnpaidPrincipalBalance: number;
  currentLoanPrincipalAndInterestPayment: number;
  currentLoanEstimatedValue: number;
  currentLoanInterestRate: number;
  currentLoanTerm: number;
  currentLTV : number;
  firstMortgageBalance : number;
  secondMortgageBalance : number;

  leadContactUserId: string;
  leadContactAssignmentDate: Date;
  leadCampaignId: number;
  leadStatusId: number = null;
  referralSource: number;
  secondaryReferralSource: number;
  referredTo: number;
  insideReferralSource: string;
  leadSource: string;
  leadRefId: string;
  tcpaComplianceToken : string;

  losInstanceId: string;
  losRefNumber: string;
  monsterInsightsUrl: string;
  internalNotes: string;
  postLeadRequestJson: string;
  contactAttempts: number;
  firstAttemptedContact: Date;
  firstSuccessfulContact: Date;
  lastAttemptedContact: Date;
  lastSuccessfulContact: Date;
  redistributionCount: number;
  lastRedistributionDate: Date;
  fetchCount: number;
  lastFetchDate: Date;
  contactListId: number;
  contactListRecordId: number;
  leadListIds: number[];
  readonly timeZone: string;
  mostRecentNote: string;

  tags: ContactTrackingTag[];

  companyId: number;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;
  customData: CustomData[] = [];
  escrow: number;
  noOfUnits: number = 1;


  authorizationMethod?: string = null;
  authorizedCreditCheck?: boolean = false;
  dateAuthorizedCreditCheck?: string = null;
  coAuthorizationMethod?: string = null;
  coAuthorizedCreditCheck?: boolean = false;
  coDateAuthorizedCreditCheck?: string = null;
}

export enum PropertyAttachmentType {
  Attached = "Attached",
  Detached = "Detached",
  SemiDetached  = "SemiDetached"
}

export enum PropertyConstructionMethod {
  Manufactured = "Manufactured",
  MobileHome = "MobileHome",
  Modular = "Modular",
  OnFrameModular = "OnFrameModular",
  SiteBuilt = "SiteBuilt",
  MHAdvantage = "MHAdvantage",
  Other = "Other",
}

export enum ProjectType {
  Condominium = "Condominium",
  PlannedUnitDevelopment = "PlannedUnitDevelopment",
  NotInAProject = "NotInAProject",
  CommonInterestApartment = "CommonInterestApartment",
  Cooperative = "Cooperative",
  Unknown = "Unknown",
}

export enum PropertyWillBeType {
  PrimaryResidence = "PrimaryResidence",
  SecondaryResidence = "SecondaryResidence",
  Investment = "Investment",
}

export enum LeadPropertyUse {
  Primary = "Primary",
  SecondaryOrVacation = "SecondaryOrVacation",
  InvestmentOrRental = "InvestmentOrRental"
}

export enum Channel {
  Retail = "Retail" ,
  Wholesale = "Wholesale" ,
  Broker = "Broker",
  Correspondent = "Correspondent",
  NonDelegatedCorrespondent = "NonDelegatedCorrespondent"
}
