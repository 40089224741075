<div id="{{id}}" [attr.name]="name" class="rightnav" [ngClass]="{'tpo-drawer': isTpo, 'margin-top-panel' : isCallControlPanelOpen }">
  <div class="notification-header box-shadow-drawer">
    <div class="row align-items-center">
      <div class="d-flex" [ngClass]="{'ps-0': hasShrinkExpandButton}">
        <button style="padding: 0 6px;" *ngIf="hasShrinkExpandButton" type="button" class="btn btn-outline-secondary" [title]="isShrink ? 'Shrink' : 'Expand'" (click)="onDrawerSizeToggleClicked()">
          <i class="fas" [ngClass]="{'fa-long-arrow-alt-left': !isShrink, 'fa-long-arrow-alt-right': isShrink}"></i>
        </button>
        <h4 class="notification-title flex-grow-1">{{title}}</h4>
        <a href="javascript:void(0)" class="closebtn" (click)="onCloseClicked()">&times;</a>
      </div>
    </div>
  </div>
  <div class="notification-footer box-shadow-inset">
  </div>
  <ng-container #container *ngIf="isOpen" [ngTemplateOutlet]="templateRef">
  </ng-container>
  <div class="drawer-content-wrapper">
    <ng-container #dynamicComponentContainer></ng-container>
  </div>
</div>
