import { Injectable, Injector } from '@angular/core';
import { DeephavenCreditCredentialsConfigProvider } from './deephaven-credit-credentials-config-provider';
import { DefaultCreditCredentialsConfigProvider } from './default-credit-credentials-config.provider';
import { CreditCredentialsConfig } from './credit-credentials-config';
import { ApplicationContext } from 'src/app/models';
import { GlobalCreditConfig } from '../../global-admin/credit-config/models/global-credit-config.model';

@Injectable()
export class CreditCredentialsConfigProviderFactory {
  constructor(
    private readonly _injector: Injector,
    private readonly _deephavenCreditCredentialsConfigProvider: DeephavenCreditCredentialsConfigProvider,
    private readonly _defaultCreditCredentialsConfigProvider: DefaultCreditCredentialsConfigProvider
  ) {}

  create = (context: ApplicationContext): IProvideCreditCredentialsConfig | null => {
    let provider: IProvideCreditCredentialsConfig | null = null;
    if (context.isCompanyDeepHaven) {
      provider = this._deephavenCreditCredentialsConfigProvider;
    } else {
      provider = this._defaultCreditCredentialsConfigProvider;
    }
    return provider;
  };
}

export interface IProvideCreditCredentialsConfig {

  getCreditCredentialsConfig(): Promise<CreditCredentialsConfig>;

  getDuCreditProviders(): Promise<DuCreditProvider[]>;

  getCreditConfig(providerIdentifier: string): GlobalCreditConfig
}

export interface DuCreditProvider {
  displayName: string
  providerIdentifier: string;
}
