import {Injectable} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {DataService} from '../core/services/data.service';
import {ContactListColumnDefinition, ContactListType} from '../models';
import {GlobalConfig} from '../models/config/global-config.model';
import {ErnstOptionsModel} from '../models/fee/ernst-options.model';
import {ErnstRequestFeeModel} from '../models/fee/ernst-request-fee.model';
import {EscrowSchedule} from '../models/fee/escrow-schedule.model';
import {FeeDefinitionModel} from '../models/fee/fee-definition.model';
import {FeeSectionEnum} from '../models/fee/fee-section.enum';
import {FeeSystemDetails} from '../models/fee/fee-system-details.model';
import {FeeTemplate} from '../models/fee/fee-template.model';
import {FeeTypeEnum} from '../models/fee/fee-type.enum';
import {FeesToUpdateRequest, LoanFee} from '../models/fee/fee.model';
import {ThirdPartyFeeOptions} from '../models/fee/third-party-fees-options.model';
import {TitleAgent} from '../models/fee/title-agent.model';
import {UpdateFeesFromEscrowScheduleRequest} from '../models/fee/update-fees-from-escrow-schedule-request.model';
import {UpdateFeesFromEscrowScheduleResponse} from '../models/fee/update-fees-from-escrow-schedule-response.model';
import {ExpressionBuilderDialogService} from '../modules/expression-builder/services/expression-builder-dialog.service';
import {ExpressionService} from '../modules/expression-builder/services/expression.service';
import {ContactListService} from './contact-list.service';
import {LoanFeeHistory} from '../models/loan/loan-fee-history.model';
import { OnDemandFormsDTO } from '../models/fee/on-demand-forms-dto';
import { EnumerationService } from './enumeration-service';

@Injectable()
export class FeeService {

  private _feeSectionDisplayNames: any = {};

  private _feeShortSectionDisplayNames: any = {};

  constructor(
    private readonly _dataService: DataService,
    private readonly _contactListService: ContactListService,
    private readonly _expressionService: ExpressionBuilderDialogService,
    private readonly _enumService: EnumerationService
  ) {
    this.initializeSectionDisplayNames();
    this.initializeShortSectionDisplayNames();
  }

  updateFeesFromLoan = (applicationId: number): Observable<LoanFee[]> => {
    const url = `api/loan/${applicationId}/fees/loanValues/updateAll?save=false`;
    return this._dataService.post(url, []);
  }

  getFees = (applicationId: number): Observable<LoanFee[]> => {
    const url = `api/loan/${applicationId}/fees`;
    return this._dataService.get(url);
  };

  getScenarioFees = (scenarioId: number): Observable<LoanFee[]> => {
    const url = `api/scenario/${scenarioId}/fees`;
    return this._dataService.get(url);
  };

  getFeeTemplates = (applicationId: number): Observable<FeeTemplate[]> => {
    const url = `api/loan/${applicationId}/fees/templates`;
    return this._dataService.get(url);
  };

  getMctoWizardUrl = (applicationId: number): Observable<string> => {
    const url = `api/loan/${applicationId}/fees/mcto-wizard-url`;
    return this._dataService.get(url, { observe: 'body', responseType: 'text' });
  };

  getTemplateFeesForApplication = (applicationId: number, templateId: number): Observable<LoanFee[]> => {
    const url = `api/loan/${applicationId}/fees/templates/${templateId}/getFees`;
    return this._dataService.get(url);
  }

  removeFee = (applicationId: number, modelGuid: string): Observable<any> => {
    const url = `api/loan/${applicationId}/fees/${modelGuid}`;
    return this._dataService.delete(url);
  }

  saveFees = (applicationId: number, fees: readonly LoanFee[]): Observable<LoanFee[]> => {
    const url = `api/loan/${applicationId}/fees/update`;
    return this._dataService.post(url, fees);
  }

  saveScenarioFees = (scenarioId: number, fees: LoanFee[]): Observable<LoanFee[]> => {
    const url = `api/scenario/${scenarioId}/fees/update`;
    return this._dataService.post(url, fees);
  }

  importLoanFeesToScenario = (scenarioId: number, applicationId: number): Observable<LoanFee[]> => {
    const url = `api/pricing/scenarios/${scenarioId}/import-fees/${applicationId}`;
    return this._dataService.post(url, null);
  }

  isRealEstateFee(fee: LoanFee | FeeDefinitionModel) {
    return this.getRealEstateFeeTypes().includes(fee.feeType);
  }

  getRealEstateFeeTypes(): FeeTypeEnum[] {
    return [FeeTypeEnum.BuyerAgentRealEstateCommission, FeeTypeEnum.SellerAgentRealEstateCommission,
    FeeTypeEnum.RealEstateCommissionTotal, FeeTypeEnum.RealEstateCommissionBuyersBroker, FeeTypeEnum.RealEstateCommissionSellersBroker];
  }

  isRealEstateFeeType(feeType: FeeTypeEnum) {
    return this.getRealEstateFeeTypes().includes(feeType);
  }

  updateLoanFeeFromLoanValues = (applicationId: number, feesToUpdate: FeesToUpdateRequest): Observable<LoanFee[]> => {
    const url = `api/loan/${applicationId}/fees/loanValues/update`;
    return this._dataService.post(url, feesToUpdate);
  }

  getErnstFeeOptions = (applicationId: number): Observable<ErnstOptionsModel> => {
    const url = `api/ernst/${applicationId}/options`;
    return this._dataService.get(url);
  };

  getThirdPartyFeeOptions = (applicationId: number, feeProvider: string): Observable<ThirdPartyFeeOptions> => {
    const url = `api/fees/third-party/${applicationId}/options?feeProvider=${feeProvider}`;
    return this._dataService.get(url);
  };

  requestThirdPartytFees = (applicationId: number, feeRequestOptions: ThirdPartyFeeOptions): Observable<LoanFee[]> => {
    feeRequestOptions.applicationId = applicationId;
    const url = `api/fees/third-party/${applicationId}/requestFees`;
    return this._dataService.post(url, feeRequestOptions);
  }

  requestErnstFees = (applicationId: number, feeRequestOptions: ErnstOptionsModel): Observable<ErnstRequestFeeModel> => {
    feeRequestOptions.applicationId = applicationId;

    const url = `api/ernst/${applicationId}/requestFees`;
    return this._dataService.post(url, feeRequestOptions);
  }

  getSupportedFeeProviders = (): Observable<string[]> => {
    const url = `api/integration/services/Fees/supported-providers`;
    return this._dataService.get(url);
  }

  getConfiguredFeeProvidersForApp = (applicationId?: number): Observable<string[]> => {
    let url = `api/integration/services/Fees/configured-providers`;
    if (applicationId) {
      url = url + `?applicationId=${applicationId}`;
    }
    return this._dataService.get(url);
  }

  isVendorEnabled = (provider: string, skipCredentialCheck: boolean = false): Observable<boolean> => {
    let url = `api/integration/${provider}/Fees/enabled`;
    if (skipCredentialCheck) {
      url += '?skipCredentialCheck=true';
    }
    return this._dataService.get(url);
  }

  // fee definitions
  getFeeDefinitions = (): Observable<FeeDefinitionModel[]> => {
    const url = `api/Admin/fees/definitions`;
    return this._dataService.get(url);
  };

  insertFeeDefinition = (definition: FeeDefinitionModel): Observable<FeeDefinitionModel> => {
    const url = `api/Admin/fees/definitions`;
    return this._dataService.post(url, definition);
  }

  insertFeeDefinitionList = (definitions: FeeDefinitionModel[]): Observable<FeeDefinitionModel[]> => {
    const url = `api/Admin/fees/definitions/List`;
    return this._dataService.post(url, definitions);
  }

  getFeeDefinition = (feeDefinitionId: number): Observable<FeeDefinitionModel> => {
    const url = `api/Admin/fees/definitions/${feeDefinitionId}`;
    return this._dataService.get(url);
  }

  updateFeeDefinition = (feeDefinitionId: number, feeDefinition: FeeDefinitionModel): Observable<FeeDefinitionModel> => {
    const url = `api/Admin/fees/definitions/${feeDefinitionId}`;
    return this._dataService.put(url, feeDefinition);
  }

  deleteFeeDefinition = (feeDefinitionId: number): Observable<FeeDefinitionModel> => {
    const url = `api/Admin/fees/definitions/${feeDefinitionId}`;
    return this._dataService.delete(url);
  }

  getContactListColumnsForExpressionBuilder(): Observable<ContactListColumnDefinition[]> {
    return this._contactListService.getContactList().pipe(
      map((response) => {
        const contactList = response.find(contact => contact.contactListType === ContactListType.Application && contact.isCustom === false);
        return this._contactListService.setContactListColumns(contactList?.columns);
      })
    );
  }

  getExpressionHTML = (globalConfig: GlobalConfig, expressionGroupId: number): Observable<string> => {
    if (expressionGroupId) {
      return forkJoin({
        expressionGroup: this._expressionService.getExpressionGroup(expressionGroupId),
        expressionColumns: this.getContactListColumnsForExpressionBuilder()
      })
        .pipe(map(result => {
          let expression = new ExpressionService(globalConfig, this._enumService);
          return expression.getExpressionHtml(result.expressionGroup, result.expressionColumns)
        }))
    }
    else {
      return of(undefined);
    }
  }

  getExpressionHTMLs = (globalConfig: GlobalConfig, expressionGroupIds: number[]): Observable<{ html: string, expressionGroupId: number }[]> => {
    if (expressionGroupIds.length > 0) {
      return forkJoin({
        expressionGroups: forkJoin(expressionGroupIds.map(id => this._expressionService.getExpressionGroup(id))),
        expressionColumns: this.getContactListColumnsForExpressionBuilder()
      })
        .pipe(map(result => {
          let expression = new ExpressionService(globalConfig, this._enumService);
          return result.expressionGroups.map(g => {
            return {
              html: expression.getExpressionHtml(g, result.expressionColumns),
              expressionGroupId: g.expressionGroupId
            }
          })
        }))
    }
    else {
      return of([]);
    }

  }

  // fee templates
  getConfigFeeTemplates = (): Observable<FeeTemplate[]> => {
    const url = `api/Admin/fees/templates`;
    return this._dataService.get(url);
  };

  getConfigFeeTemplate = (feeTemplateId: number): Observable<FeeTemplate> => {
    const url = `api/Admin/fees/templates/${feeTemplateId}`;
    return this._dataService.get(url);
  };

  insertFeeTemplate = (feeTemplate: FeeTemplate): Observable<FeeTemplate> => {
    const url = `api/Admin/fees/templates`;
    return this._dataService.post(url, feeTemplate);
  }

  updateFeeTemplate = (feeTemplateId: number, feeTemplate: FeeTemplate): Observable<FeeTemplate> => {
    const url = `api/Admin/fees/templates/${feeTemplateId}`;
    return this._dataService.put(url, feeTemplate);
  }

  deleteFeeTemplate = (feeDefinitionId: number): Observable<FeeDefinitionModel> => {
    const url = `api/Admin/fees/templates/${feeDefinitionId}`;
    return this._dataService.delete(url);
  }

  getFeeSectionDisplayName = (feeSection: FeeSectionEnum): string => {
    return this._feeSectionDisplayNames[feeSection]
  }

  getShortFeeSectionDisplayName = (feeSection: FeeSectionEnum): string => {
    return this._feeShortSectionDisplayNames[feeSection]
  }

  getFeeSystemDetails = (): Observable<FeeSystemDetails> => {
    const url = `api/fees/system-details`;
    return this._dataService.get(url);
  }

  getTitleAgents = (body: any): Observable<TitleAgent[]> => {
    const url = `api/fees/third-party/${body.applicationId}/title-agents`;
    return this._dataService.post(url, body);
  }

  getEscrowFeeSchedules = (fees: LoanFee[]): Observable<EscrowSchedule> => {
    const url = `api/fees/escrow-schedule/get-from-fees`;
    return this._dataService.post(url, fees);
  }

  generateProformaLe = (applicationId: number): Observable<OnDemandFormsDTO> => {
    const url = `api/encompass/generate-proforma-le/${applicationId}`;
    return this._dataService.get(url);
  }

  updateEscrowFeeSchedules = (request: UpdateFeesFromEscrowScheduleRequest): Observable<UpdateFeesFromEscrowScheduleResponse> => {
    const url = `api/fees/escrow-schedule/update-fees`;
    return this._dataService.post(url, request);
  }

  getLoanFeesHistory = (loanFeeHistoryIds: number[]): Observable<LoanFeeHistory[]> => {
    let url = `api/loan/fees/history`;

    if (loanFeeHistoryIds.length) {
      url = url + "?" + loanFeeHistoryIds.map(id => "loanFeeHistoryIds=" + id).join("&");
    }

    return this._dataService.get(url);
  }

  private initializeSectionDisplayNames = () => {
    this._feeSectionDisplayNames[FeeSectionEnum.Origination] = 'A. ORIGINATION CHARGES 800. Items Payable in Connection with Loan';
    this._feeSectionDisplayNames[FeeSectionEnum.Escrow] = 'G. INITIAL ESCROW PAYMENT AT CLOSING 1000. Reserves Deposited with Lender';
    this._feeSectionDisplayNames[FeeSectionEnum.RealEstateCommission] = 'Real Estate Commission';
    this._feeSectionDisplayNames[FeeSectionEnum.Services] = 'C. SERVICES BORROWER CAN SHOP FOR 1100. Title Charge';
    this._feeSectionDisplayNames[FeeSectionEnum.ServicesNoShop] = 'B. SERVICES BORROWER DID NOT SHOP FOR';
    this._feeSectionDisplayNames[FeeSectionEnum.GovernmentTaxesAndFees] = 'E. TAXES AND OTHER GOVERNMENT FEES 1200. Government Recording & Transfer Charges';
    this._feeSectionDisplayNames[FeeSectionEnum.Other] = 'H. OTHER 1300. Additional Settlement Charges';
    this._feeSectionDisplayNames[FeeSectionEnum.Prepaids] = 'F. PREPAID 900. Items Required by Lender to Be Paid in Advance';
  }

  private initializeShortSectionDisplayNames = () => {
    this._feeShortSectionDisplayNames[FeeSectionEnum.Origination] = 'A. ORIGINATION CHARGES 800.';
    this._feeShortSectionDisplayNames[FeeSectionEnum.Escrow] = 'G. INITIAL ESCROW PAYMENT AT CLOSING 1000.';
    this._feeShortSectionDisplayNames[FeeSectionEnum.RealEstateCommission] = 'Real Estate Commission';
    this._feeShortSectionDisplayNames[FeeSectionEnum.Services] = 'C. SERVICES BORROWER CAN SHOP FOR 1100.';
    this._feeShortSectionDisplayNames[FeeSectionEnum.ServicesNoShop] = 'B. SERVICES BORROWER DID NOT SHOP FOR';
    this._feeShortSectionDisplayNames[FeeSectionEnum.GovernmentTaxesAndFees] = 'E. TAXES AND OTHER GOVERNMENT FEES 1200.';
    this._feeShortSectionDisplayNames[FeeSectionEnum.Other] = 'H. OTHER 1300. Additional Settlement Charges';
    this._feeShortSectionDisplayNames[FeeSectionEnum.Prepaids] = 'F. PREPAID 900.';
  }
}
