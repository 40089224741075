<div class="table-responsive" [hidden]="fees.length === 0">
  <table class="table mb-0 table-centered">
    <thead>
      <tr>
        <th class="min-width-300p"></th>
        <th class="min-width-120p">Monthly</th>
        <th width="60px">Months</th>
        <th class="max-width-150p">Borrower Paid</th>
        <th class="max-width-100p">Seller Concessions</th>
        <th class="min-width-150p">Total Fee</th>
        <th class="min-width-25p" *ngIf="showImpound">Impound</th>
        <th width="50px"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let fee of fees; let i = index">
        <tr>
          <td class="align-top">
            <span>
              <label
                [ngStyle]="{
                  fontWeight: fee.isSummaryFee ? 'bold' : '',
                  marginLeft:
                    !fee.isSummaryFee && fee.sumInHudNumber ? '10px' : null
                }"
              >
                <span *ngIf="fee.sumInHudNumber !== null"
                  >&nbsp;&nbsp;&nbsp;</span>
                  <span *ngIf="!fee.isSummaryFee" class="me-2">
                    <a
                      class="text-primary"
                      style="font-size: 0.8em"
                      (click)="onFeeEditorOpened(fee)"
                    >
                      <i class="fa fa-external-link-alt"></i>
                    </a>
                  </span>
                {{ fee.hudNumber ? fee.hudNumber + ' - ' : '' }} {{ fee.name }}
              </label>
            </span>
          </td>
          <td class="min-height-49p align-top">
            <currency-input
              *ngIf="
                (fee.calculatedFeeType === monthlyFeeType ||
                  fee.calculatedFeeType === upFrontMonthlyPremium) &&
                !fee.isChargeOverridden
              "
              [id]="'monthlyFee_' + fee.modelGuid"
              [name]="'monthlyFee_' + fee.modelGuid"
              [(ngModel)]="fee.calculatedValues.monthlyFee"
              [hidden]="fee.isSummaryFee"
              [disabled]="!fee.calculatedValues.isChargeUserEditable"
              [required]="true"
              (change)="onMonthlyChanged(fee)"
            >
            </currency-input>
            <span
              *ngIf="
                (fee.calculatedFeeType === monthlyFeeType ||
                  fee.calculatedFeeType === upFrontMonthlyPremium) &&
                fee.isChargeOverridden
              "
              >--</span
            >
          </td>
          <td class="align-top">
            <input
              *ngIf="
                (fee.calculatedFeeType === monthlyFeeType ||
                  fee.calculatedFeeType === upFrontMonthlyPremium) &&
                !fee.isChargeOverridden
              "
              type="number"
              numeric
              [allowNegative]="false"
              id="months_{{ fee.modelGuid }}"
              name="months_{{ fee.modelGuid }}"
              [(ngModel)]="fee.calculatedValues.months"
              [hidden]="fee.isSummaryFee"
              [disabled]="!fee.calculatedValues.isChargeUserEditable"
              class="form-control numeric-without-arrows"
              (ngModelChange)="onMonthsChanged(fee)"
            />
            <span
              *ngIf="
                (fee.calculatedFeeType === monthlyFeeType ||
                  fee.calculatedFeeType === upFrontMonthlyPremium) &&
                fee.isChargeOverridden
              "
              >--</span
            >
          </td>
          <td class="align-top">
            <div class="d-flex">
              <currency-input
                [id]="'borrowerDollar_' + fee.modelGuid"
                [name]="'borrowerDollar_' + fee.modelGuid"
                [(ngModel)]="fee.borrowerFeeDollar"
                [hidden]="fee.isSummaryFee"
                [disabled]="
                  !fee.calculatedValues.isChargeUserEditable ||
                  !fee.isChargeOverridden
                "
                [required]="true"
                (change)="onBorrowerFeeDollarChanged(fee)"
              >
              </currency-input>

              <button
                *ngIf="allowLockStatusChange"
                class="btn btn-sm fee-lock-icon-btn"
                [hidden]="fee.isSummaryFee"
                type="button"
                (click)="onLockStatusChanged(fee)"
                [ngClass]="{
                  'fee-lock-icon-btn--unlocked': fee.isChargeOverridden,
                  'fee-lock-icon-btn--locked': !fee.isChargeOverridden
                }"
              >
                <span
                  title="{{ fee.isChargeOverridden ? 'Unlocked' : 'Locked' }}"
                >
                  <i
                    class="fas fa-lock"
                    [ngClass]="{
                      'fee-icon-unlocked': fee.isChargeOverridden,
                      'fee-icon-locked': !fee.isChargeOverridden,
                      'fa-lock-open': fee.isChargeOverridden,
                      'fa-lock': !fee.isChargeOverridden
                    }"
                  ></i>
                </span>
              </button>
            </div>
          </td>
          <td class="align-top">
            <currency-input
              [id]="'sellerConcessions_' + fee.modelGuid"
              [name]="'sellerConcessions_' + fee.modelGuid"
              *ngIf="fee.canSellerConcessionsApply"
              [hidden]="fee.isSummaryFee"
              [(ngModel)]="fee.calculatedValues.sellerConcessions"
              (change)="onSellerConcessionChange(fee)"
            >
            </currency-input>
            <div
              class="invalid-feedback d-block"
              *ngIf=" fee.calculatedValues?.borrowerTotal > 0 &&
                fee.calculatedValues?.sellerConcessions >
                fee.calculatedValues?.borrowerTotal
              "
            >
              This field cannot be greater than
              {{ fee.calculatedValues.borrowerTotal | currency }}
            </div>
          </td>
          <td class="align-top">
            <currency-input
              [id]="'totalFee_' + fee.modelGuid"
              [name]="'totalFee_' + fee.modelGuid"
              [hidden]="fee.isSummaryFee"
              [(ngModel)]="fee.calculatedValues.totalFee"
              [disabled]="true"
            >
            </currency-input>
          </td>
          <td *ngIf="showImpound && fee.calculatedFeeType == monthlyFeeType" class="align-top">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input m-auto"
                type="checkbox"
                id="isImpound_{{ fee.modelGuid }}"
                name="isImpound_{{ fee.modelGuid }}"
                [(ngModel)]="fee.isImpound"
                (ngModelChange)="onIsImpoundChanged(fee)"
              />
            </div>
          </td>
          <td class="align-top">
            <span *ngIf="deleteAllowed && fee.isSummaryFee">
              <a
                class="text-danger"
                [hidden]="pendingDeleteIndex == i"
                (click)="pendingDeleteIndex = i"
                ><i class="fa fa-trash-alt"></i
              ></a>
              <a
                class="text-success mx-2"
                [hidden]="pendingDeleteIndex != i"
                (click)="removeFee(fee)"
                ><i class="fa fa-check"></i
              ></a>
              <a
                class="text-danger"
                [hidden]="pendingDeleteIndex != i"
                (click)="pendingDeleteIndex = -1"
                ><i class="fa fa-times"></i
              ></a>
            </span>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
