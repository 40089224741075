import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/internal/operators/finalize';
import { ThirdPartyCredential } from 'src/app/models/third-party/third-party-creds.model';
import { ThirdPartyKeyValue } from 'src/app/models/third-party/third-party-key-value.model';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { ThirdPartyCredentialsService } from 'src/app/services/third-party-credentials.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { ScopeType } from '../../integrations.component';

@Component({
  selector: 'app-esigning-credentials-editor-dialog',
  templateUrl: './esigning-credentials-editor-dialog.component.html',
  styleUrls: ['./esigning-credentials-editor-dialog.component.scss']
})
export class EsigningCredentialsEditorDialogComponent extends ApplicationContextBoundComponent implements OnInit {

  @ViewChild('credentialForm') credentialForm: NgForm | undefined;

  @Input()
  credential: ThirdPartyCredential;

  @Input()
  documentTypes: Array<DocumentType>;

  @Input()
  inEditMode: boolean = true;

  @Input()
  scope: ScopeType;

  keyValuePairs = {
    accountId: null,
    savedDocumentTypeId: null,
    useCustomAccount: null,
    suppressDocuSignEmails: null,
    reviewRequired: null
  };

  isSaving: boolean;
  disabledChannel: boolean = false;

  constructor(
    private readonly injector: Injector,
    public activeModal: NgbActiveModal,
    private readonly _thirdPartyCredentialsService: ThirdPartyCredentialsService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _notificationService: NotificationService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.keyValuePairs.accountId = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'AccountId') || new ThirdPartyKeyValue("AccountId","");
    this.keyValuePairs.useCustomAccount = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'UseCustomAccount') || new ThirdPartyKeyValue("UseCustomAccount","'0'");
    this.keyValuePairs.savedDocumentTypeId = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'SavedDocumentTypeId') || new ThirdPartyKeyValue("SavedDocumentTypeId",null);
    this.keyValuePairs.suppressDocuSignEmails = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'SuppressDocuSignEmails') || new ThirdPartyKeyValue("SuppressDocuSignEmails","'1'");
    this.keyValuePairs.reviewRequired = this.credential.thirdPartyKeyValuePairs.find(el => el.key === 'ReviewRequired') || new ThirdPartyKeyValue("ReviewRequired","");

    if(!this.keyValuePairs.savedDocumentTypeId.value){
      this.keyValuePairs.savedDocumentTypeId.value = null;
    }
    else {
      this.keyValuePairs.savedDocumentTypeId.value = Number(this.keyValuePairs.savedDocumentTypeId.value);
    }
  }

  saveCredential = () => {

    this.credentialForm.form.markAllAsTouched();
    if (!this.credentialForm.form.valid) return;
    
    if(this.credential.vendorName != 'DocuSign'){
      delete this.keyValuePairs.suppressDocuSignEmails;
    }

    if(this.credential.vendorName == 'EncompassPOS'){
      delete this.keyValuePairs.accountId;
    }

    this.credential = this._thirdPartyCredentialsService.setCredentialKeyValuePairs(this.credential, this.keyValuePairs);

    this.credential.companyId = this.applicationContext.userPermissions.companyId;

    if (this.scope === 'User') {
      this.credential.userId = this.applicationContext.userPermissions.userId;
    }

    this.isSaving = true;
    this._systemLevelService.saveCredential(this.credential)
      .pipe(finalize(() => this.isSaving = false))
      .subscribe({
        next: res => {
          this._notificationService.showSuccess(`Credential settings saved successfully.`, this.scope === 'User' ? 'Profile' : 'System Level');
          this.activeModal.close(res);
        },
        error: error => {
          this._notificationService.showError(`${error ? error.message : 'Unable to save.'}`, this.scope === 'User' ? 'Profile' : 'System Level');
        }
      });

  }

}
