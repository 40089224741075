<div class="modal-header">
  <h5 class="modal-title">Edit Custom Data Items</h5>
</div>
<form #customDataItemForm="ngForm" novalidate id="customDataItemForm" name="customDataItemForm">
<div class="modal-body">
  <div class="row mb-3">
    <div class="col-md-12">
      <label class="custom-control-label">List Type</label>
      <select name="listType" class="form-select standard" 
        [(ngModel)]="customDataConfigModel.multiLookupType" 
        (change)="getLookupItems()">
        <option value="">-- Select One --</option>
        <option *ngFor="let item of listTypes" value={{item.name}}>
          {{item.name}}
        </option>
      </select>
    </div>
  </div>
  <loading-indicator *ngIf="loading" [loadingMessage]="'Loading items'"></loading-indicator>
  <div class="row mb-3" *ngFor="let item of lookupItems; let index = index">
    <div class="col-md-6">
      <label class="custom-control-label">Name</label>
      <input type="text" 
             class="form-control" 
             [(ngModel)]="item.name" 
             [disabled]="customDataConfigModel.multiLookupType !== 'Custom'"
             required
             name="name_{{index}}"
             #name="ngModel"
             [pattern]="alphaNumericRegex"
             [ngClass]="{'is-invalid': name && name.touched && name.invalid}">
      <div class="invalid-feedback" *ngIf="name.errors?.['required']">
        Name is required
      </div>
    </div>
    <div [ngClass]="{'col-md-6': customDataConfigModel.multiLookupType !== 'Custom', 'col-md-5': customDataConfigModel.multiLookupType === 'Custom'}">
      <label class="custom-control-label">Value</label>
      <input type="text" 
             class="form-control" 
             [(ngModel)]="item.value" 
             name="value_{{index}}"
             #value="ngModel"
             required
             [pattern]="alphaNumericRegex"
             [ngClass]="{'is-invalid': value && value.touched && value.invalid}"
             [disabled]="customDataConfigModel.multiLookupType !== 'Custom'">
      <div class="invalid-feedback" *ngIf="value.errors?.['required']">
        Value is required
      </div>
    </div>
    <div class="col-md-1 text-center pt-4" *ngIf="customDataConfigModel.multiLookupType === 'Custom'">
      <a (click)="lookupItems.splice(index, 1)">
        <i class="far fa-trash-alt text-danger mt-2"></i>
      </a>
    </div>
  </div>
  <div class="row mb-3" *ngIf="customDataConfigModel.multiLookupType === 'Custom' && !loading">
    <div class="col-md-4">
      <button type="button" class="btn btn-soft-primary w-100" (click)="lookupItems.push({ name: '', value: '', type: 'Custom' })">
        Add
      </button>
    </div>
  </div>
</div>
<div class="modal-footer form-footer">
  <button
    type="button"
    class="btn btn-secondary right"
    (click)="activeModal.dismiss()"
  >
    Cancel
  </button>
  <button
    type="submit"
    class="btn btn-primary right"
    (click)="onSubmit()"
  >
    Ok
  </button>
</div>
</form>
