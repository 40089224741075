import { Component, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as feather from 'feather-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { ApplicationContext, RecentApp, ThirdPartyCredentialType, UserData, UserProfile } from 'src/app/models';
import { TpoConfiguration } from 'src/app/modules/admin/tpo-config/models/tpo-configuration.model';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase';
import { SignalRService } from 'src/app/services/signalr.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreditCredentialsConfigurationDialogComponent } from 'src/app/modules/credit-credentials-configuration/credit-credentials-configuration-dialog/credit-credentials-configuration-dialog.component';
import { Constants } from 'src/app/services/constants';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { CreditConfigService } from 'src/app/modules/global-admin/credit-config/services/credit-config.service';

@Component({
  selector: 'tpo-layout',
  templateUrl: 'tpo-layout.component.html',
  styleUrls: ['./tpo-layout.component.scss'],
})
export class TpoLayoutComponent extends ApplicationContextBoundComponent {

  currentlyLoggedInUser: UserData;
  currentlyLoggedInUserProfile: UserProfile;
  recentApps: Array<RecentApp> = [];
  companyLogoUrl: string = "";
  userAvatarUrl: string;
  isNewApplicationEnabled: boolean;

  overrideCssUrl: string = null;

  tpoConfig: TpoConfiguration;

  currentYear: number;

  protected faviconUrl: string;

  protected userIsImpersonating: boolean = true;
  protected companyName: string = "Kreayda Labs";

  private _applicationContextSubscription: Subscription;
  private _activatedRouteSubscription: Subscription;

  constructor(private readonly injector: Injector,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _authService: AuthService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _environmentService: EnvironmentService,
    private readonly _signalRService: SignalRService,
    private readonly _firebaseService: FirebaseService,
    private readonly _environment: EnvironmentService,
    private readonly _modalService: NgbModal,
    private readonly _router: Router,
    private readonly _localStorageService: LocalStorageService,
    private readonly _creditConfigService: CreditConfigService
  ) {
    super(injector);

    this._applicationContextSubscription = this.applicationContextService.context.subscribe(async context => {
      const company = context.globalConfig.company.find(c => c.companyId == context.userPermissions.companyId);
      this.overrideCssUrl = this._environmentService.apiInfo.publicApiBaseUrl + `api/PublicApi/custom-css/${company.companyGUID}/tpo`;
      this.tpoConfig = context.tpoConfig;
      this.currentYear = new Date().getFullYear();

      this._signalRService.run(context);
      this._firebaseService.run(context);

      // TODO: Remove this after testing
      if (!context.isCompanyLfhs && !context.isCompanyOpenWholesale && 
          !context.isCompanyMortgegProcessing && !context.isCompanyPulseProcessing &&
          !context.isCompanyGenway && !context.isCompanySmartfi
        ) {
        await this.checkIfCreditCredentialsAreSetup(context);
      }
    });

    this.faviconUrl = this._environment.faviconUrl;
  }

  ngOnInit() {
    feather.replace();

    const authData = this._localStorageService.authorizationData;
    this.userIsImpersonating = authData && !!authData.impersonatingForLoanId;

    this._activatedRouteSubscription = this._activatedRoute.data.subscribe((data) => {
      this.companyLogoUrl = this._environmentService.apiInfo.apiBaseUrl + `company/br/logo/${this.applicationContext.userPermissions.companyId}`;
      this.currentlyLoggedInUser = this.applicationContext.currentlyLoggedInUser;
      this.currentlyLoggedInUserProfile = this.applicationContext.currentlyLoggedInUserProfile.userProfile;
      this.isNewApplicationEnabled = this.applicationContext.userPermissions.showNewSubmissionForm;
      if (this.currentlyLoggedInUser.avatar === 'assets/images/male.png') {
        this.userAvatarUrl = this.currentlyLoggedInUser.avatar;
      } else {
        this.userAvatarUrl = this._environmentService.apiInfo.apiBaseUrl + this.currentlyLoggedInUser.avatar;
      }
    });

    this.recentApps = this.applicationContext.userPermissions.recentApplications;
    const body: HTMLBodyElement | null = document.querySelector('#loda-body');
    if (body) {
      body.classList.add('tpo-background');
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._applicationContextSubscription?.unsubscribe();
    this._activatedRouteSubscription?.unsubscribe();
  }

  onLogoutClicked = () => {
    this._spinner.show();
    this._authService.logout().subscribe(() => {
      this._spinner.hide();
    }, error => {
      this._spinner.hide();
    });
  };

  protected onStopImpersonatingClicked = () => {
    this.userIsImpersonating = false;
    this._router.navigate(['end-impersonation-mode'], {
      queryParams: {  },
    });
  }

  private checkIfCreditCredentialsAreSetup = async (context: ApplicationContext) => {
    const creditVendorForProfile = context.currentlyLoggedInUserProfile.thirdPartyCredentials.find(c => c.credentialType === ThirdPartyCredentialType.CreditVendor);
    let needToShowCreditConfigPopup = true;
    if (context.isCompanyDeepHavenUat) {
      const creditConfig = await this._creditConfigService.getGlobalCreditConfigList();
      if (!creditConfig) {
        needToShowCreditConfigPopup = false;
      }
    }
    if (!creditVendorForProfile && needToShowCreditConfigPopup) {
      let modalRef = this._modalService.open(CreditCredentialsConfigurationDialogComponent, {
        ...Constants.modalOptions.large,
        windowClass: 'myCustomModalClass',
      });

      modalRef.result.then(() => {
      }, () => {
      });
    }
  }
}
