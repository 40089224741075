import {
  Branch,
  ChannelRoles,
  Company,
  CustomDataConfig,
  DisclosureLoanPlan,
  ExternalCompany,
  KeyDateConfig,
  KeyDateGroup,
  LoanStatus,
} from 'src/app/models';
import { Role } from 'src/app/models/role.model';
import { User } from 'src/app/models/user/user.model';
import { DocumentType } from 'src/app/models/document-type.model';
import { LoanPurpose } from './loan-purpose.model';
import { LoanType } from './loan-type.model';
import { Alignment } from 'src/app/modules/internal-contacts/models/alignment.model';
import { StackingOrderDocTypes } from './stacking-order-doc-types.model';
import { LeadStatus } from '../lead-status.model';
import { Task } from '../task/task.model';
import { LeadSource } from '../lead-source.model';
import { AgentType } from 'src/app/modules/admin/company/models/agent-type.model';
import { LeadStatusAssociation } from './lead-status-association.model';
import { LoanStatusAssociation } from './loan-status-association.model';
import { LoanProduct } from './product.model';
import { CustomTask } from '../task/custom-task.model';
import {
  BorrowerCharacteristic,
  LoanCharacteristic,
} from './characteristics.model';
import { EnumerationItem } from '../simple-enum-item.model';
import { AgentList } from 'src/app/modules/agents/models/agent-list.model';
import { StoredFileModel } from '../stored-file.model';
import { UtilityProvider } from "../../modules/admin/solar-config/models/utility-provider.model";
import { CountryEnumerationItem } from 'src/app/services/enumeration-service';
import { Utils } from 'src/app/core/services/utils';
import { IEnvironment } from 'src/app/core/services/environment/environment.interface';
import { LosOption } from '../../modules/admin/los-config/models';
import { Tag } from 'src/app/modules/admin/tag-management/models/tag.model';

export class GlobalConfig {
  loanPurpose: LoanPurpose[] = [];
  loanStatus: LoanStatus[] = [];
  leadStatus: LeadStatus[] = [];
  leadSource: LeadSource[] = [];
  subStatuses: SubStatus[] = [];
  loanType: LoanType[] = [];
  lender: Lender[] = [];
  product: LoanProduct[] = [];
  roles: Role[] = [];
  users: User[] = [];
  countries: CountryEnumerationItem[] = [];
  company: Company[] = [];
  customDataConfig: CustomDataConfig[];
  externalCompanies: ExternalCompany[] = [];
  keyDates: KeyDateConfig[] = [];
  keyDateGroups: KeyDateGroup[] = [];
  branches: Branch[] = [];
  states: State[] = [];
  accessibleUsersForLeads: AccessibleUsers[] = [];
  accessibleUsersForLoans: AccessibleUsers[] = [];
  usersReportingTo: any[] = [];
  channelRoles: ChannelRoles;
  alignment: Alignment[];
  stackingOrderDocTypes: StackingOrderDocTypes[] = [];
  documentType: DocumentType[] = [];
  subStatus: SubStatus[] = [];
  lookup: Lookup[] = [];
  leadCampaigns: LeadCampaign[] = [];
  taskCategory: TaskCategory[] = [];
  taskPriority: TaskPriority[] = [];
  tasks: Task[] = [];
  customTask: CustomTask[] = [];
  lookupLeadRoutes: any[] = []; //TODO:can't find the proper interface for this. this lookup does not get returned from the backend
  lookupLeadRouteGroups: any[] = []; //TODO:can't find the proper interface for this. this lookup does not get returned from the backend
  lookupRedistributionRoutes: any[] = []; //TODO:can't find the proper interface for this. this lookup does not get returned from the backend
  lookupRedistributionRouteGroups: any[] = []; //TODO:can't find the proper interface for this. this lookup does not get returned from the backend
  documentTemplates: DocumentTemplate[] = [];
  agentType: AgentType[] = [];
  leadStatusAssociation: LeadStatusAssociation[] = [];
  loanStatusAssociation: LoanStatusAssociation[] = [];
  firstRole: Role;
  applyTo: Lookup[] = [];
  borrowerCharacteristics: BorrowerCharacteristic[] = [];
  loanCharacteristics: LoanCharacteristic[] = [];
  losOptions: LosOption[] = [];
  agentList: AgentList[] = [];
  tags: Tag[] = [];
  taskCategoryCharacteristicType: TaskCategoryCharacteristicType;
  taskCategoryOptionType: TaskCategoryOptionType;
  /** branches enabled for logged in user */
  enabledBranches: Branch[] = [];
  enabledChannels: EnumerationItem[] = [];
  disabledBorrowerInviteChannels: string[] = [];
  companyStatuses: EnumerationItem[] = [];
  utilityProviders: UtilityProvider[] = [];
  compensationTypes: EnumerationItem[] = [];
  disclosurePaths: EnumerationItem[] = [];
  mortgagePartyTypes: EnumerationItem[] = [];
  mortgageEntityTypes: EnumerationItem[] = [];
  applicationCopyReasons: EnumerationItem[] = [];

  private readonly _environment: IEnvironment

  private _tpoUsers: User[] = [];
  private _tpoUsersKeyedById: { [key: string]: User } = {} as { [key: string]: User };

  private _allUsers: User[] = [];
  private _allUsersKeyedById: { [key: string]: User } = {} as { [key: string]: User };

  get tpoUsers(): User[] {
    return this._tpoUsers;
  }

  set tpoUsers(users: User[]) {
    this._tpoUsers = users;
    // Convert this into a dictionary keyed by userCompanyGuid
    this._tpoUsersKeyedById = {};
    users.forEach((user) => {
      this._tpoUsersKeyedById[user.userCompanyGuid] = user;
    });
  }

  get usersAll(): User[] {
    return this._allUsers;
  }

  //TODO: this is a temporary solution to get all users including TPO users
  //When usersAll returns all users including TPO users, this can be removed
  get allUsersIncludingTpo(): User[] {
    return [...this._allUsers, ...this._tpoUsers];
  }

  set usersAll(users: User[]) {
    this._allUsers = users;
    // Convert this into a dictionary keyed by userCompanyGuid
    this._allUsersKeyedById = {};
    users.forEach((user) => {
      this._allUsersKeyedById[user.userCompanyGuid] = user;
    });
  }

  constructor(environment: IEnvironment) {
    this._environment = environment;
  }

  getUser = (userCompanyGuid: string): User => {
    let user = this._allUsersKeyedById[userCompanyGuid];
    if (!user) {
      user = this._tpoUsersKeyedById[userCompanyGuid];
    }
    return user;
  }

  getUserAvatarUrl = (userCompanyGuid: string): string => {
    let user = this._allUsersKeyedById[userCompanyGuid];
    let avatarUrl = 'assets/images/male.png';
    if (user) {
      avatarUrl = user.avatarId ? `${this._environment.apiInfo.apiBaseUrl}avatars/${user.avatarId}` : 'assets/images/male.png';
    }
    return avatarUrl;
  }

  getUserDisplayName = (userCompanyGuid: string): string => {
    let user = this._allUsersKeyedById[userCompanyGuid];
    let displayName = null;
    if (!user) {
      user = this._tpoUsersKeyedById[userCompanyGuid];
    }
    if (user) {
      displayName = Utils.getPersonsDisplayName(user);
    }
    return displayName;
  }

  getUserFullName = (userCompanyGuid: string): string => {
    let user = this._allUsersKeyedById[userCompanyGuid];
    let displayName = null;
    if (!user) {
      user = this._tpoUsersKeyedById[userCompanyGuid];
    }
    if (user) {
      displayName = Utils.getPersonsFullName(user);
    }
    return displayName;
  }
}

export interface DocumentTemplate {
  documentTemplateCategory: string;
  documentTemplateId: number;
  documentTemplateName: string;
  documentTemplateType: number;
  documentTypeId: number;
  order: number;
  restrictedUserRoles: number[];
}

export interface SubStatus {
  companyId: number;
  dateInserted: string;
  dateUpdated: string;
  hideFromCounter: boolean;
  insertedBy: string;
  isSuspenseSubStatus: boolean;
  order: number;
  selectByRole: string;
  subStatusId: number;
  subStatusName: string;
  supressBorrowerNotification: boolean;
  updatedBy: string;
}
export interface Lender {
  active: boolean;
  address: string;
  address2: string;
  aeEmail: string;
  aeFax: string;
  aeMobile: string;
  aeName: string;
  aePhone: string;
  city: string;
  companyId: number;
  dateInserted: string;
  dateUpdated: string;
  externalCompanyId: number;
  fhaLenderId: number;
  insertedBy: string;
  lenderId: number;
  ldeVendor: string;
  mortgageeClause: string;
  name: string;
  payoffEmail: string;
  payoffEmailInstructions: string;
  payoffFax: string;
  payoffFaxInstructions: string;
  payoffNotes: string;
  payoffPhone: string;
  payoffPhoneInstructions: string;
  phone: string;
  sortOrder: number;
  state: string;
  titleClause: string;
  updatedBy: string;
  vaLenderId: number;
  zip: string;
  website: string;
}
export interface Lookup {
  companyId: number;
  dateInserted: string;
  dateUpdated: string;
  id: number;
  insertedBy: string;
  lookupDescription: string;
  lookupGroup: string;
  lookupOrder: number;
  lookupTypeID: number;
  lookupValue: string;
  updatedBy: string;
}
export interface LeadCampaign {
  leadCampaignId: number;
  name: string;
  leadSourceName: string;
  category: string;
  leadSourceGroup: string;
  leadSourceProvider: string;
  responseCode: string;
  leadRoutingEnabled: boolean;
  costPerLead: number;
  costPerMonth: number;
  totalNumberOfLeads: number;
  dataSegmentGroup: string;
  loanTypeGroup: string;
  artworkGroup: string;
  mailDate?: Date;
  inHomeDate?: Date;
  email: string;
  note: string;
  active: boolean;
  companyId: number;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;
  rateSheetStoredFileId: number;
  marketingMediaStoredFileId: number;
  rateSheetStoredFile: StoredFileModel;
  marketingMediaStoredFile: StoredFileModel;
  primaryRoleContactId: string;
  telephonyServiceId : number;
}

export interface TaskCategory {
  characteristicType: string;
  companyId: number;
  dateInserted: string;
  dateUpdated: string;
  enabledChannels: string;
  fields: CharacteristicField[];
  insertedBy: string;
  onlineAppEnabled: boolean;
  order: number;
  questionText: string;
  taskCategoryId: number;
  taskCategoryName: string;
  taskOption: string;
  tpoEnabled: boolean;
  updatedBy: string;
}
export interface CharacteristicField {
  characteristicFieldId: number;
  companyId: number;
  dateInserted: string;
  dateUpdated: string;
  displayName: string;
  fieldKey: string;
  fieldType: string;
  insertedBy: string;
  order: number;
  taskCategoryId: number;
  updatedBy: string;
}
export interface TaskPriority {
  companyId: number;
  dateInserted: string;
  dateUpdated: string;
  id: number;
  insertedBy: string;
  lookupDescription: string;
  lookupGroup: string;
  lookupOrder: number;
  lookupTypeID: number;
  lookupValue: string;
  updatedBy: string;
}

export interface State {
  [key: string]: string
}

export interface TaskCategoryCharacteristicType {
  [key: number]: string
}

export interface TaskCategoryOptionType {
  [key: number]: string
}

export enum LeadSourceGroupOptions {
  SelfGen = 'Self-Gen',
  Referral = 'Referral',
  HouseReferral = 'House Referral',
  DirectMail = 'Direct Mail',
  BankingBridge = 'Banking Bridge'
}

export interface AccessibleUsers {
  active?: boolean;
  firstName: string;
  lastName: string;
  userCompanyGuid: string;
  userType: string;
}
