import { Injectable } from '@angular/core';
import { concatMap, forkJoin, Observable, Observer, of, Subject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as _ from 'lodash';
import { keys } from 'lodash';

import {
  ApplicationContext,
  Borrower,
  Branch,
  Configuration,
  CustomData,
  KeyDate,
  KeyDatesByType,
  LoanApplication,
  Profile,
  RecentAgent,
  RecentApp,
  RecentLead,
  Role,
  ThirdPartyCredentialArray,
  ThirdPartyCredentialType,
  UserData,
  UserPermissions,
  UserProfile,
  UserType,
} from '../models';

import { LoanService } from './loan/loan.service';
import { UserService } from './user.service';
import { GlobalConfigService } from './global-config.service';
import { MortgageService } from './mortgage.service';
import { CountryEnumerationItem, EnumerationService } from './enumeration-service';
import { UrlaMortgage } from '../modules/urla/models/urla-mortgage.model';
import { ChannelService } from './channel.service';
import { MergeFieldsService } from './merge-fields.service';
import { EnumerationItem } from '../models/simple-enum-item.model';
import { LoanPurpose } from '../models/config/loan-purpose.model';
import { Title } from '@angular/platform-browser';
import { ReleaseNotes } from '../models/release-notes.model';
import { TpoConfigService } from '../modules/admin/tpo-config/services/tpo-config.service';
import { GlobalConfig } from '../models/config/global-config.model';
import { MortgageDti } from '../models/mortgage-dti.model';
import { MortgageCalculationService } from '../modules/urla/services/mortgage-calculation.service';
import { BorrowerDto } from '../modules/contacts/models/borrower-dto.model';
import { MortgageCalculationDetails } from '../models/mortgage-calculation-details.model';
import { KeyDatesService } from './key-dates.service';
import { EnvironmentService } from '../core/services/environment/environment.service';
import { CompanyService } from '../modules/global-admin/company/services/company.service';
import { MenuItemStatus } from '../modules/tpo/models/enums/menu-item-status.enum';
import { getErrorMessageOrDefault, ignoreError } from '../shared/utils/error-utils';
import { ConfigurationService } from './configuration.service';

@Injectable({ providedIn: 'root' })
export class ApplicationContextService {

  private _applicationContext: ApplicationContext;

  private _loanInfoLoadErrorSubject: Subject<string> =
    new Subject<string>();

  private _loanInfoSubject: Subject<ApplicationContext> =
    new Subject<ApplicationContext>();

  private _loanTasksSubject: Subject<ApplicationContext> =
    new Subject<ApplicationContext>();

  private _loggedInUserInfoSubject: Subject<ApplicationContext> =
    new Subject<ApplicationContext>();

  private _contextSubject: Subject<ApplicationContext> =
    new Subject<ApplicationContext>();

  private _callControlPanelStateSubject: Subject<ApplicationContext> =
    new Subject<ApplicationContext>();

  private _niceInContactVisibilityStateSubject: Subject<boolean> =
    new Subject<boolean>();

  private _applicationVersionUpdatedSubject: Subject<ReleaseNotes> =
    new Subject<ReleaseNotes>();

  private _loanLosLdeChangeSubject: Subject<ApplicationContext> =
    new Subject<ApplicationContext>();

  private _keyDatesChangeSubject: Subject<KeyDateChangesEvent> =
    new Subject<KeyDateChangesEvent>();

  private _callsOrLeadsOnOffStatusChangedEvent: Subject<any> = new Subject<any>();

  private _mortgageDtiChangedEvent: Subject<MortgageDti> = new Subject<MortgageDti>();

  private _mortgageIncomeOrLiabilitiesChangedEvent: Subject<UrlaMortgage> = new Subject<UrlaMortgage>();

  private _applicationTrackingStatusesChangedEvent: Subject<{ [menuKey: string]: MenuItemStatus } | undefined> = new Subject<{ [menuKey: string]: MenuItemStatus } | undefined>();

  private _sendEmailDialogOpenedEvent: Subject<any> = new Subject<any>();
  private _sendEmailDialogClosedEvent: Subject<any> = new Subject<any>();

  get context(): Observable<ApplicationContext> {
    if (this._applicationContext) {
      return of(this._applicationContext);
    }
    return this.initializeApplicationContext().pipe(map(context => {
      this._applicationContext = context;
      return context;
    }));
  }

  get callsOrLeadsOnOffStatusChangedEvent(): Subject<any> {
    return this._callsOrLeadsOnOffStatusChangedEvent;
  }

  get sendEmailDialogOpenedEvent(): Subject<any> {
    return this._sendEmailDialogOpenedEvent;
  }

  get sendEmailDialogClosedEvent(): Subject<any> {
    return this._sendEmailDialogClosedEvent;
  }

  get mortgageDtiChangedEvent(): Subject<MortgageDti> {
    return this._mortgageDtiChangedEvent;
  }

  get mortgageIncomeOrLiabilitiesChangedEvent(): Subject<UrlaMortgage> {
    return this._mortgageIncomeOrLiabilitiesChangedEvent;
  }

  get loanLosLdeChanges(): Subject<ApplicationContext> {
    return this._loanLosLdeChangeSubject;
  }

  get changes(): Subject<ApplicationContext> {
    return this._contextSubject;
  }

  get loanInfoLoadError(): Subject<string> {
    return this._loanInfoLoadErrorSubject;
  }

  get loanInfoChanges(): Subject<ApplicationContext> {
    return this._loanInfoSubject;
  }

  get loanTasksChanges(): Subject<ApplicationContext> {
    return this._loanTasksSubject;
  }

  get callControlPanelStatChanges(): Subject<ApplicationContext> {
    return this._callControlPanelStateSubject;
  }

  get niceInContactVisibilityStateChanges(): Subject<boolean> {
    return this._niceInContactVisibilityStateSubject;
  }

  get loggedInUserChanges(): Subject<ApplicationContext> {
    return this._loggedInUserInfoSubject;
  }

  get applicationVersionUpdated(): Subject<ReleaseNotes> {
    return this._applicationVersionUpdatedSubject;
  }

  get keyDatesChanges(): Subject<KeyDateChangesEvent> {
    return this._keyDatesChangeSubject;
  }

  get applicationTrackingStatusesChangedEvent(): Subject<{ [menuKey: string]: MenuItemStatus } | undefined> {
    return this._applicationTrackingStatusesChangedEvent;
  }

  constructor(
    private readonly _userService: UserService,
    private readonly _configService: GlobalConfigService,
    private readonly _channelService: ChannelService,
    private readonly _loanService: LoanService,
    private readonly _mortgageService: MortgageService,
    private readonly _calculationService: MortgageCalculationService,
    private readonly _enumsService: EnumerationService,
    private readonly _mergeFieldsService: MergeFieldsService,
    private readonly _tpoConfigService: TpoConfigService,
    private readonly _titleService: Title,
    private readonly _keyDatesService: KeyDatesService,
    private readonly _environmentService: EnvironmentService,
    private readonly _companyService: CompanyService,
    private readonly _configurationService: ConfigurationService,
    private window: Window
  ) { }

  reset = () => {
    this._applicationContext = null;
    this._contextSubject.next(this._applicationContext);
  };

  updateApplicationVersion = (releaseNotes: ReleaseNotes) => {
    this._applicationVersionUpdatedSubject.next(releaseNotes);
  }

  mortgageDtiChanged = (updatedMortgageDti: MortgageDti) => {
    this._mortgageDtiChangedEvent.next(updatedMortgageDti);
  }

  mortgageIncomeOrLiabilitiesChanged = () => {
    this._mortgageIncomeOrLiabilitiesChangedEvent.next(this._applicationContext.currentMortgage);
  }

  updateApplicationTrackingStatuses = () => {
    const observer: Observer<{ [menuKey: string]: MenuItemStatus } | undefined> = {
      next: (menuStatuses: { [menuKey: string]: MenuItemStatus } | undefined) => {
        this._applicationContext.tpoMenuStatuses = menuStatuses;
        this._applicationTrackingStatusesChangedEvent.next(menuStatuses);
      },
      error: (err: any) => {
      },
      complete: () => {
      }
    }
    this._loanService.getMenuStatuses(this._applicationContext.application.applicationId).subscribe(observer);
  }

  niceInContactVisibilityChanged = (isVisible: boolean) => {
    this._applicationContext.isNiceInContactVisible = isVisible
    this._niceInContactVisibilityStateSubject.next(isVisible);
  }

  updateMortgage = (mortgage: UrlaMortgage) => {
    if (!this._applicationContext) {
      return;
    }

    const observer: Observer<MortgageCalculationDetails> = {
      next: (calcs: MortgageCalculationDetails) => {
        this.setMortgageAndInitialize(mortgage, calcs);
        this._loanInfoSubject.next(this._applicationContext);
        this._contextSubject.next(this._applicationContext);
      },
      error: (err: any) => {
      },
      complete: () => {
      }
    }
    this._mortgageService.getMortgageCalculationDetails(mortgage.mortgageId).subscribe(observer);
  };

  updateLoanTasks = () => {
    if (!this._applicationContext) {
      return;
    }
    this._loanTasksSubject.next(this._applicationContext);
  };

  updateApplication = (application: LoanApplication = null, refreshLoanReadonlyInfo: boolean = false) => {
    if (!this._applicationContext) {
      return;
    }
    this._applicationContext.application = application;
    if (refreshLoanReadonlyInfo) {
      this._loanService.isLoanReadOnly(application.applicationId).subscribe(isReadOnly => {
        this._applicationContext.applicationIsReadOnly = isReadOnly;
        this._loanInfoSubject.next(this._applicationContext);
        this._contextSubject.next(this._applicationContext);
      });
    } else {
      this._loanInfoSubject.next(this._applicationContext);
      this._contextSubject.next(this._applicationContext);
    }
  };

  updateKeyDates = (keyDates: KeyDate[]) => {
    if (!keyDates) {
      return;
    }
    this._applicationContext.applicationKeyDates = keyDates;
    this._loanService.getKeyDatesByType(this._applicationContext.application.applicationId).subscribe(keyDatesByType => {
      this._applicationContext.applicationKeyDatesByType = keyDatesByType;
      this._keyDatesChangeSubject.next({
        keyDates,
        keyDatesByType,
      });
    });
  }

  updateSubStatuses = () => {
    this._loanService.getAvailableSubStatuses(this._applicationContext.application.applicationId).subscribe(subStatuses => {
      this._applicationContext.subStatuses = subStatuses;
      this._contextSubject.next(this._applicationContext);
    });
  }

  updateLoanReadOnlyStatus = (applicationId: number) => {
    this._loanService.isLoanReadOnly(applicationId).subscribe(isReadOnly => {
      this._applicationContext.applicationIsReadOnly = isReadOnly;
      this._loanInfoSubject.next(this._applicationContext);
      this._contextSubject.next(this._applicationContext);
    });
  }

  // LOS / LDE
  updateLoanAfterLosLdeChange = (result: UpdateLoanAfterLosLdeChangeModel) => {
    if (result) {
      const { application, customData, loanBorrowers } = result;

      this._applicationContext.application = application || this._applicationContext.application;

      const observer: Observer<MortgageCalculationDetails> = {
        next: (calcs: MortgageCalculationDetails) => {
          this.setMortgageAndInitialize(application.mortgageLoan || this._applicationContext.currentMortgage, calcs);

          this._applicationContext.borrowers = loanBorrowers.map(x => x as Borrower) || this._applicationContext.borrowers;
          // prevent from undefined errors
          this._applicationContext.customData = customData?.length > 0 ? [ ...customData ] : this._applicationContext.customData;

          this._contextSubject.next(this._applicationContext);
          this._loanInfoSubject.next(this._applicationContext);
          this._loanTasksSubject.next(this._applicationContext);
          this._loanLosLdeChangeSubject.next(this._applicationContext);
        },
        error: (err: any) => {
        },
        complete: () => {
        }
      }
      this._mortgageService.getMortgageCalculationDetails(result.application.mortgageLoan.mortgageId).subscribe(observer);
    }
  };

  updateLoanAfterLosLdeUnlinkChange = (application: LoanApplication) => {
    this._applicationContext.application = application;

    this._contextSubject.next(this._applicationContext);
    this._loanInfoSubject.next(this._applicationContext);
    this._loanLosLdeChangeSubject.next(this._applicationContext);
  };

  updateApplicationAfterLosLdeSyncChange = (application: LoanApplication) => {
    if (!this._applicationContext) {
      return;
    }

    this._applicationContext.application = application;

    this._contextSubject.next(this._applicationContext);
    this._loanInfoSubject.next(this._applicationContext);
    this._loanLosLdeChangeSubject.next(this._applicationContext);
  };

  refreshApplicationAfterLosLdeRefChange = (application: LoanApplication) => {
    this._applicationContext.application = application;

    this._contextSubject.next(this._applicationContext);
    this._loanInfoSubject.next(this._applicationContext);
    this._loanLosLdeChangeSubject.next(this._applicationContext);
  };

  updateMortgageAndApplication = (mortgage: UrlaMortgage, application: LoanApplication, customData: CustomData[] | undefined = undefined, borrowers: Borrower[] | undefined = undefined) => {
    if (!this._applicationContext) {
      return;
    }

    const observer: Observer<MortgageCalculationDetails> = {
      next: (calcs: MortgageCalculationDetails) => {
        this.setMortgageAndInitialize(mortgage, calcs);
        this._applicationContext.application = application;
        if (customData !== undefined) {
          this._applicationContext.customData = customData;
        }
        if (borrowers !== undefined) {
          this._applicationContext.borrowers = borrowers;
        }
        this._contextSubject.next(this._applicationContext);
        this._loanInfoSubject.next(this._applicationContext);
      },
      error: (err: any) => {
      },
      complete: () => {
      }
    }
    this._mortgageService.getMortgageCalculationDetails(mortgage.mortgageId).subscribe(observer);
  }

  updateBorrowers = (borrowers?: Borrower[]): Observable<Borrower[]> => {
    if (borrowers) {
      this._applicationContext.borrowers = borrowers;
      return of(this._applicationContext.borrowers);
    } else {
      return this._loanService.getApplicationModel(this._applicationContext.application.applicationId, true).pipe(
        catchError((error) => {
          this._loanInfoLoadErrorSubject.next(error.message);
          return of(error);
        }),
        switchMap((application: LoanApplication) =>
          this._loanService.getBorrowers(this._applicationContext.application.applicationId).pipe(
            catchError((error) => {
              this._loanInfoLoadErrorSubject.next(error.message);
              return of(error);
            }),
            switchMap(borrowers => {
              if (application.solarId || !application.mortgageId) {
                this._applicationContext.application = application;
                this._applicationContext.borrowers = borrowers;

                this._contextSubject.next(this._applicationContext);
                this._loanInfoSubject.next(this._applicationContext);

                this.setWindowTitle(borrowers, application.mailingStreet);

                return of(borrowers);
              }

              return this._mortgageService.getMortgageCalculationDetails(this._applicationContext.application.mortgageId).pipe(
                map((calcs: MortgageCalculationDetails) => {
                  if (application.mortgageLoan) {
                    this.setMortgageAndInitialize(application.mortgageLoan, calcs);
                  }
                  this._applicationContext.application = application;
                  this._applicationContext.borrowers = borrowers;

                  this._contextSubject.next(this._applicationContext);
                  this._loanInfoSubject.next(this._applicationContext);

                  this.setWindowTitle(borrowers, application.mailingStreet);

                  return borrowers;
                }),
                catchError(err => {
                  throw err;
                }))
            })
          )
        ));
    }
  }

  updateRecentAppsList = (recentlyVisited: RecentApp[]) => {
    this._applicationContext.userPermissions.recentApplications = recentlyVisited || [];
    this._contextSubject.next(this._applicationContext);
  }

  updateRecentLeadsList = (recentlyVisited: RecentLead[]) => {
    this._applicationContext.userPermissions.recentLeads = recentlyVisited || [];
    this._contextSubject.next(this._applicationContext);
  }

  updateRecentAgentsList = (recentlyVisited: RecentAgent[]) => {
    this._applicationContext.userPermissions.recentAgents = recentlyVisited || [];
    this._contextSubject.next(this._applicationContext);
  }

  updateUserProfile = (profile: UserProfile) => {
    this._applicationContext.currentlyLoggedInUserProfile.userProfile = profile;
    //this._applicationContext.currentlyLoggedInUser.avatar = profile.userImageData;
    this._contextSubject.next(this._applicationContext);
  }

  updateRoles = (roles: Role[]) => {
    this._applicationContext.globalConfig.roles = _.orderBy(roles, ['order', 'asc']);
    if (!this._applicationContext.globalConfig.channelRoles) {
      return;
    }
    keys(this._applicationContext.globalConfig.channelRoles).forEach(key => {
      this._applicationContext.globalConfig.channelRoles[key] = [];
    });
    roles.forEach(role => {
      role.roleChannels.forEach(roleChannel => {
        const channelRoles = this._applicationContext.globalConfig.channelRoles[roleChannel.channel.toLowerCase()]
        channelRoles.push(role);
      })
    })
    this._contextSubject.next(this._applicationContext);
  }

  updateLoanPurposeList = (loanPurposes: Array<LoanPurpose>) => {
    this._applicationContext.globalConfig.loanPurpose = loanPurposes;
    this._contextSubject.next(this._applicationContext);
  }

  updateUserProfileThirdPartyCredentials = (thirdPartyCredentials: ThirdPartyCredentialArray,
    credentialType: ThirdPartyCredentialType) => {
    const existingNonCreditCredentials = this._applicationContext.currentlyLoggedInUserProfile.thirdPartyCredentials.filter(c =>
      c.credentialType !== credentialType);
    const allCredentialsWithNewCredentialsAdded = existingNonCreditCredentials.concat(thirdPartyCredentials);

    this._applicationContext.currentlyLoggedInUserProfile.thirdPartyCredentials = allCredentialsWithNewCredentialsAdded;
    this._contextSubject.next(this._applicationContext);
  }

  updateUserPermission = (key: string, value: boolean) => {
    this._applicationContext.userPermissions[key] = value;
    this._contextSubject.next(this._applicationContext);
  }

  toggleCallControlPanel = (enabled: boolean) => {
    if (this._applicationContext) {
      this._applicationContext.isCallControlPanelOpen = enabled;
      this._callControlPanelStateSubject.next(this._applicationContext);
    }
  }

  toggleLoanEditMode = (enabled: boolean) => {
    this._applicationContext.isLoanEditModeOpen = enabled;
    this._contextSubject.next(this._applicationContext);
  }

  resetLoanInfo = () => {
    // If the loan info is already not there, do not fire the change events
    if (!this._applicationContext || !this._applicationContext.application) {
      return this._applicationContext;
    }
    this._applicationContext.currentMortgage = null;
    this._applicationContext.currentMortgageCalculationDetails = null;
    this._applicationContext.application = null;
    this._applicationContext.customData = null;
    this._applicationContext.borrowers = null;

    this._contextSubject.next(this._applicationContext);
    this._loanInfoSubject.next(this._applicationContext);
    this._loanTasksSubject.next(this._applicationContext);

    return this._applicationContext;
  };

  private tryGetMenuStatusesOf = (application: LoanApplication) =>
    this._applicationContext.currentlyLoggedInUser.userType === UserType.Tpo ||
      application.externalCompanyId
      ? this._loanService.getMenuStatuses(application.applicationId)
      : (of(undefined) as
        | ReturnType<typeof this._loanService.getMenuStatuses>
        | Observable<undefined>);

  private handleLoanInfoLoadError = <T>(
    error: any,
    _: Observable<T>
  ): Observable<T | undefined> => {
    this._loanInfoLoadErrorSubject.next(
      getErrorMessageOrDefault(error, {
        defaultMessage: 'Error loading loan information',
      })
    );
    return of(undefined);
  };

  private getApplicationData = (loanId: number, includeMortgage: boolean) => {
    return this._loanService.getApplicationModel(loanId, includeMortgage).pipe(
      catchError(this.handleLoanInfoLoadError),
      concatMap((application: LoanApplication) =>
        forkJoin({
          application: of(application),
          menuStatuses: this.tryGetMenuStatusesOf(application).pipe(catchError(ignoreError)),
          calculationDetails:
            application.mortgageId && !application.solarId
              ? this._mortgageService
                .getMortgageCalculationDetails(application.mortgageId)
                .pipe(catchError(ignoreError))
              : of(undefined),
        })
      )
    );
  }

  updateLoanInfo = (loanId: number): Observable<ApplicationContext> => {
    // Fire and forget asyncronously, we do NOT need the results of this guy below!
    this._loanService.openApplication(loanId).subscribe();

    return forkJoin({
      applicationData: this.getApplicationData(loanId, true),
      isReadOnly: this._loanService.isLoanReadOnly(loanId).pipe(catchError(ignoreError)),
      keyDates: this._keyDatesService
        .getKeyDates(loanId)
        .pipe(catchError(ignoreError)) as Observable<KeyDate[]>,
      keyDatesByType: this._loanService.getKeyDatesByType(loanId).pipe(catchError(ignoreError)),
      subStatuses: this._loanService.getAvailableSubStatuses(loanId).pipe(catchError(ignoreError)),
      customData: this._loanService.getCustomData(loanId).pipe(catchError(ignoreError)),
      borrowers: this._loanService.getBorrowers(loanId).pipe(catchError(ignoreError)) as Observable<
        Borrower[]
      >,
    }).pipe(
      tap(
        ({
          applicationData: { application, menuStatuses, calculationDetails },
          isReadOnly,
          keyDates,
          keyDatesByType,
          customData,
          borrowers,
          subStatuses,
        }) => {
          this._applicationContext.applicationIsReadOnly = isReadOnly;

          if (application.mortgageLoan, calculationDetails) {
            this.setMortgageAndInitialize(application.mortgageLoan, calculationDetails);
          }

          this._applicationContext.applicationKeyDatesByType = keyDatesByType;
          this._applicationContext.applicationKeyDates = keyDates;
          this._applicationContext.subStatuses = subStatuses || [];

          this._applicationContext.application = application;
          this._applicationContext.tpoMenuStatuses = menuStatuses;
          this._applicationContext.borrowers = borrowers;
          this._applicationContext.customData = customData;

          this._contextSubject.next(this._applicationContext);
          this._loanInfoSubject.next(this._applicationContext);
          this._loanTasksSubject.next(this._applicationContext);
          this._keyDatesChangeSubject.next({
            keyDates,
            keyDatesByType,
          });

          this.setWindowTitle(borrowers, application.mailingStreet);
        }
      ),
      map(() => this._applicationContext)
    );
  };

  reloadApplicationAndMortgagePostAction = (loanId: number): Observable<ApplicationContext> => {
    // Fire and forget asyncronously, we do NOT need the results of this guy below!
    this._loanService.openApplication(loanId).subscribe();

    return forkJoin({
      applicationData: this.getApplicationData(loanId, true),
      borrowers: this._loanService.getBorrowers(loanId).pipe(catchError(ignoreError)) as Observable<
        Borrower[]
      >,
    }).pipe(
      tap(({ applicationData: { application, menuStatuses, calculationDetails }, borrowers }) => {
        this.setMortgageAndInitialize(application.mortgageLoan, calculationDetails);

        this._applicationContext.application = application;
        this._applicationContext.tpoMenuStatuses = menuStatuses;
        this._applicationContext.borrowers = borrowers;

        this._contextSubject.next(this._applicationContext);
        this._loanInfoSubject.next(this._applicationContext);
      }),
      map(() => this._applicationContext)
    );
  };

  updateLoginInfo = () => {
    this.initializeApplicationContext().subscribe((context) => {
      this._applicationContext = context;
      this._loggedInUserInfoSubject.next(context);
      this._contextSubject.next(this._applicationContext);
    });
  };

  reloadLoan = () => {
    return forkJoin({
      applicationData: this._loanService
        .getApplicationModel(this._applicationContext.application.applicationId, true)
        .pipe(
          catchError(this.handleLoanInfoLoadError),
          concatMap((application: LoanApplication) =>
            this.tryGetMenuStatusesOf(application).pipe(
              catchError(ignoreError),
              map(menuStatuses => ({ application, menuStatuses }))
            )
          )
        ),
      calculationDetails: this._mortgageService
        .getMortgageCalculationDetails(this._applicationContext.application.mortgageId)
        .pipe(catchError(ignoreError)),
    }).pipe(
      tap(({ applicationData: { application, menuStatuses }, calculationDetails }) => {
        this.setMortgageAndInitialize(application.mortgageLoan, calculationDetails);

        this._applicationContext.application = application;
        this._applicationContext.tpoMenuStatuses = menuStatuses;

        this._contextSubject.next(this._applicationContext);
        this._loanInfoSubject.next(this._applicationContext);
      }),
      map(() => this._applicationContext)
    );
  };

  reloadCompanies = () => {
    return this._companyService.getAllCompanies().pipe(
      map((companies) => {
        if (companies) {
          this._applicationContext.globalConfig.company = companies || [];
          this._contextSubject.next(this._applicationContext);
        }

        return companies || [];
      })
    )
  }

  getMergeFieldsByTypes(types: string[]): EnumerationItem[] {
    return this._applicationContext.mergeFields.filter(el => types.includes(el.groupName));
  };

  sendEmailDialogLaunched = () => {
    this._sendEmailDialogOpenedEvent.next(true);
  }

  sendEmailDialogClosed = () => {
    this._sendEmailDialogClosedEvent.next(true);
  }

  callsOrLeadsOnOffStatusChanged = () => {
    this._callsOrLeadsOnOffStatusChangedEvent.next(true);
  }

  private getEnabledBranches = (context: ApplicationContext): Branch[] => {
    if (context.userPermissions.superAdmin || context.userPermissions.admin) {
      return _.cloneDeep(context.globalConfig.branches);
    } else {
      const userBranchesIds = context.userPermissions.branchIds || [];
      const usersReportingTo = context.globalConfig.usersReportingTo.map(x => x.userId);
      return context.globalConfig.branches?.filter(b => (userBranchesIds.indexOf(b.branchId) > -1) || b.accountExecutiveUserId == context.userPermissions.userId || usersReportingTo.indexOf(b.accountExecutiveUserId) > -1);
    }
  }

  private initializeApplicationContext = (): Observable<ApplicationContext> => {
    return forkJoin({
      commonEnums: this._enumsService.getCommonEnumerations(),
      mortgageEnums: this._enumsService.getMortgageEnumerations(),
      pricingEnums: this._enumsService.getPricingEnumerations(),
      user: this._userService.getLoggedInUserData(),
      userProfile: this._userService.getProfile('0'),
      permissions: this._userService
        .getUserPermissions(true)
        .pipe(
          concatMap(permissions =>
            this._configService
              .getConfig(permissions.companyId)
              .pipe(map(config => ({ permissions, config }))),
          ),
        ),
      countries: this._enumsService.getAllCountries(),
      mergeFields: this._mergeFieldsService.getAllMergeFields(),
      disabledInvite: this._configurationService.getCompanyConfiguration('BorrowerInviteDisabledChannels')
    }).pipe(
      map(args => {
        // Flatten the results
        const {
          permissions: { permissions, config },
          ...rest
        } = args;
        return { permissions, config, ...rest };
      }),
      concatMap(args =>
        (args.permissions.userType === UserType.Tpo || args.user.userType === UserType.Tpo)
          ? this._tpoConfigService
            .getTpoConfigs()
            .pipe(map(tpoConfig => ({ ...args, tpoConfig })))
          : of({ ...args, tpoConfig: undefined }),
      ),
      map(args => {
        const applicationContext = this.populateContextFieldsFromApiResults(
          args.user,
          args.userProfile,
          args.config,
          args.commonEnums,
          args.mortgageEnums,
          args.pricingEnums,
          args.permissions,
          args.mergeFields,
          args.countries,
          args.disabledInvite
        );

        if (args.tpoConfig) {
          applicationContext.tpoConfig = args.tpoConfig;
        }

        return applicationContext;
      }),
    );
  };

  private populateContextFieldsFromApiResults = (user: UserData, profile: Profile, config: GlobalConfig,
    commonEnums: any,
    mortgageEnums: any,
    pricingEnums: any,
    permissions: UserPermissions,
    mergeFields: EnumerationItem[],
    countries: CountryEnumerationItem[],
    disabledInvite: Configuration): ApplicationContext => {
    let context = new ApplicationContext();
    context.currentlyLoggedInUser = user;
    context.currentlyLoggedInUserProfile = profile;

    context.globalConfig = new GlobalConfig(this._environmentService);
    Object.assign(context.globalConfig, config);

    context.globalConfig.loanStatus = _.orderBy(context.globalConfig.loanStatus, ['order', 'asc']);
    context.globalConfig.leadStatus = _.orderBy(context.globalConfig.leadStatus, ['order', 'asc']);
    context.globalConfig.subStatus = _.orderBy(context.globalConfig.subStatus, ['order', 'asc']);
    context.globalConfig.keyDates = _.orderBy(context.globalConfig.keyDates, ['displayName', 'asc']);
    context.globalConfig.tasks = _.orderBy(context.globalConfig.tasks, ['taskName', 'asc']);
    context.globalConfig.roles = _.orderBy(context.globalConfig.roles, ['order', 'asc']);
    context.globalConfig.users = _.orderBy(context.globalConfig.users, [['lastName', 'firstName'], ['asc', 'asc']]);
    context.globalConfig.disclosurePaths = this._enumsService.getDisclosurePaths();
    context.globalConfig.mortgageEntityTypes = this._enumsService.getMortgageEntityType();
    context.globalConfig.mortgagePartyTypes = this._enumsService.getMortgagePartyType();
    context.globalConfig.applicationCopyReasons = this._enumsService.getApplicationCopyReason();
    context.globalConfig.countries = countries;
    context.commonEnums = commonEnums;
    context.mortgageEnums = mortgageEnums;
    context.pricingEnums = pricingEnums;
    context.userPermissions = permissions;
    context.globalConfig.enabledBranches = this.getEnabledBranches(context);
    context.globalConfig.enabledChannels = this._channelService
      .getChannelsFromCommaDelimitedString(
        context.userPermissions.enabledChannels,
        true
      );
    context.globalConfig.companyStatuses = this._enumsService.getCompanyStatuses();
    context.globalConfig.compensationTypes = this._enumsService.getCompensationTypes();
    context.mergeFields = mergeFields;

    this.setDisabledBorrowerInviteChannels(context, disabledInvite);
    return context;
  }

  private setDisabledBorrowerInviteChannels = (context : ApplicationContext, disabledInvite: Configuration) => {
    if (disabledInvite?.valueStr?.trim()) {
      const channels = disabledInvite.valueStr?.split(',');
      if (!channels) {
        return;
      }

      let disabledBorrowerInviteChannels = [];
      channels.forEach(disabledChannel => {
        const companyChannels = context.globalConfig.enabledChannels;

        const channel = companyChannels.find(c => c.name == disabledChannel);
        if (channel) {
          disabledBorrowerInviteChannels.push(channel.name);
        }
      });
      context.globalConfig.disabledBorrowerInviteChannels = disabledBorrowerInviteChannels;
    }
  }


  private setWindowTitle = (borrowers: Borrower[], mailingStreet: string) => {
    const primaryBorrower = borrowers.find(b => b.isPrimary);
    if (primaryBorrower) {
      const { lastName, firstName } = primaryBorrower;
      const hostname = this.window.location.hostname;
      if (!hostname.toLowerCase().includes('leo.prmg.net')) {
        this._titleService.setTitle(`Lodasoft - ${lastName}, ${firstName} ${mailingStreet ? '(' + mailingStreet + ')' : ''}`);
      } else {
        this._titleService.setTitle(`LEO - ${lastName}, ${firstName} ${mailingStreet ? '(' + mailingStreet + ')' : ''}`);
      }
    }
  }

  private setMortgageAndInitialize(mortgage: UrlaMortgage, calculationDetails: MortgageCalculationDetails) {
    this._applicationContext.currentMortgage = mortgage;
    this._applicationContext.currentMortgageCalculationDetails = calculationDetails;
    this._mortgageService.initializeMortgage(this._applicationContext.currentMortgage);
    this._calculationService.calculateMortgageStatistics(this._applicationContext.currentMortgage);
    if (this._applicationContext.application) {
      this._applicationContext.application.mortgageLoan = mortgage;
    }
  }
}

export interface UpdateLoanAfterLosLdeChangeModel {
  application: LoanApplication;
  loanBorrowers: BorrowerDto[];
  customData: CustomData[];
}

export interface KeyDateChangesEvent {
  keyDates: KeyDate[];
  keyDatesByType: KeyDatesByType;
}
