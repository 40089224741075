import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {cloneDeep, orderBy} from 'lodash';
import {NgxSpinnerService} from 'ngx-spinner';
import {Observer, Subscription} from 'rxjs';
import {autoId, removeControlErrors, Utils} from 'src/app/core/services/utils';
import {EnumerationItem} from 'src/app/models/simple-enum-item.model';
import {ExternalContactsService} from 'src/app/modules/external-contacts/services/external-contacts.service';
import {ExternalContact} from 'src/app/modules/loan-docs/models/external-contact.model';
import {NotificationService} from 'src/app/services/notification.service';
import {KeyOfType} from '../../../../../utils/types';
import {FeeViewModel} from '../../../fees-v2/fee-view-model';
import {FeeUpdater, FeeUpdaterService} from '../../../fees-v2/services/fee-updater.service';
import {
  FeeCalculatedValues,
  FeePaidTo,
  FeeSectionType,
  FeeType,
  LoanFee,
} from '../../../fees-v2/fees.model';
import {FeePayerType} from '../../../fees-v2/fee-payer';
import {AbstractControl, FormGroup, NgForm} from '@angular/forms';
import {EnumerationService} from '../../../../services/enumeration-service';
import {Constants} from '../../../../services/constants';

@Component({
  templateUrl: 'fee-details-editor.component.html',
  selector: 'fee-details-editor',
  styleUrls: ['fee-details-editor.component.scss'],
  providers: [FeeUpdaterService],
})
export class FeeDetailsEditorComponent implements OnInit, OnDestroy {
  @Input()
  showBasicInfo: boolean = false;

  protected isOddDaysInterest: boolean = false;

  @Input()
  set fee(value: LoanFee) {
    if (!value) {
      this._feeViewModel = undefined;
      return;
    }

    const fee = cloneDeep(value);

    this.isOddDaysInterest = fee.feeType === FeeType.OddDaysInterest;

    this.isDiscountFee =
      fee.feeType === FeeType.DiscountFee || fee.feeType === FeeType.LoanDiscountPoints;
    this.isPercentFee = !!fee.feePercentOf;

    fee.feeType = fee.feeType ?? null;
    fee.feePercentOf = fee.feePercentOf ?? null;
    fee.paidToType = fee.paidToType ?? null;
    fee.refundable = !!fee.refundableConditions || !!fee.refundableAmount;

    if (!fee.calculatedValues) {
      fee.calculatedValues = {};
    }

    this._feeViewModel = FeeViewModel.fromFee(fee);
    this._feeUpdater = this._feeUpdaterService.createUpdater(this._feeViewModel);

    this._isFromSectionAOrBOrC =
      fee.feeSection === FeeSectionType.Origination ||
      fee.feeSection === FeeSectionType.ServicesNoShop ||
      fee.feeSection === FeeSectionType.Services;
    this.onPaidToTypeChanged();

    this.calculateBorrowerPTC();
    this.calculateBorrowerFinanced();

    this.calculateBLO();
    this.resetCanBeReimbursable();
  }

  get fee(): LoanFee | undefined {
    return this._feeViewModel?.fee;
  }

  @Input()
  fees: LoanFee[] = [];

  @Output()
  cancel = new EventEmitter<never>();

  @Output()
  save = new EventEmitter<LoanFee>();

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  protected get form(): FormGroup {
    return this.ngForm.form;
  }

  private readonly _id: string = autoId();

  protected FeePayerType = FeePayerType;

  protected errors: Map<AbstractControl, Record<string, string>> = new Map();
  protected isUpdating: boolean = false;

  feeTypes: EnumerationItem[] = [];
  paidToTypes: EnumerationItem[] = [];
  feePercentOfs: EnumerationItem[] = [];

  totalFeePercent: number;
  borrowerPaidToClosing: number;
  totalBSLO: number;

  isDiscountFee: boolean = false;
  isPercentFee: boolean = false;

  isExistHud: boolean = false;

  protected canBeReimbursable: boolean = false;

  protected externalContacts: EnumerationItem[] = null;

  protected shouldAskForServiceProviderToPayTo: boolean = false;

  private _feeViewModel?: FeeViewModel;
  private _feeUpdater?: FeeUpdater;
  private _checkAfterUpdateConfig: PayerKeyConfig | null = null;

  private _isFromSectionAOrBOrC: boolean = false;

  private _initEnumerationsSubscription?: Subscription;
  private _feeUpdatesSubscription?: Subscription;
  private _feeUpdatingStateSubscription?: Subscription;

  constructor(
    private readonly _feeUpdaterService: FeeUpdaterService,
    private readonly _enumerationService: EnumerationService,
    private readonly _externalContactsService: ExternalContactsService,
    private readonly _notificationService: NotificationService,
    private readonly _spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.initEnumerations();
    this.subscribeToFeeUpdates();
    this.subscribeToFeeUpdatingState();
  }

  ngOnDestroy() {
    this._initEnumerationsSubscription?.unsubscribe();
    this._feeUpdatesSubscription?.unsubscribe();
    this._feeUpdatingStateSubscription?.unsubscribe();
    this._feeUpdater?.destroy();
  }

  private initEnumerations(): void {
    this._initEnumerationsSubscription?.unsubscribe();

    this._initEnumerationsSubscription = this._enumerationService
      .getFeeEnumerations()
      .subscribe(enums => {
        const feeEnumKeys = Constants.feeEnumerations;
        this.feeTypes = enums[feeEnumKeys.feeType];
        this.paidToTypes = enums[feeEnumKeys.feePaidTo];
        this.feePercentOfs = enums[feeEnumKeys.feePercentOfField];
      });
  }

  private subscribeToFeeUpdates(): void {
    this._feeUpdatesSubscription?.unsubscribe();
    this._feeUpdatesSubscription = this._feeUpdater.fee$.subscribe(fee => {
      this._feeViewModel = fee; // FIXME: This might not be necessary.

      setTimeout(() => {
        this.checkAfterUpdate();
      });
    });
  }

  private subscribeToFeeUpdatingState(): void {
    this._feeUpdatingStateSubscription?.unsubscribe();
    this._feeUpdatingStateSubscription = this._feeUpdater.updatingState$.subscribe(isUpdating => {
      // FIXME: Disabling the form causes the error status of the controls to be lost.
      // if (isUpdating) {
      //   this.form.disable();
      // } else {
      //   this.form.enable();
      // }

      this.isUpdating = isUpdating;
    });
  }

  protected id(elementId: string): string {
    return `${this._id}-${elementId}`;
  }

  protected onPaidToTypeChanged(): void {
    this.shouldAskForServiceProviderToPayTo =
      this._isFromSectionAOrBOrC && this.fee.paidToType === FeePaidTo.ThirdPartyProvider;
    if (this.shouldAskForServiceProviderToPayTo) {
      this.populateExternalContacts();
    } else {
      this.fee.externalContactId = null;
    }
  }

  protected onHudNumberChanged(): void {
    this.isExistHud = false;
    let matchedFee = this.fees.find(
      f =>
        f.hudNumber &&
        this.fee.hudNumber &&
        f.modelGuid != this.fee.modelGuid &&
        f.hudNumber == this.fee.hudNumber
    );
    if (matchedFee) {
      this.isExistHud = true;
    }
  }

  protected onFeePercentOfChanged(): void {
    this.isPercentFee = !!this.fee.feePercentOf;
    if (this.isPercentFee) {
      this.fee.borrowerFeeDollar = 0;
      this.fee.sellerFeeDollar = 0;
      this.fee.lenderFeeDollar = 0;
      this.fee.thirdPartyFeeDollar = 0;
    } else {
      this.fee.borrowerFeePercent = 0;
      this.fee.sellerFeePercent = 0;
      this.fee.lenderFeePercent = 0;
      this.fee.thirdPartyFeePercent = 0;
    }

    this.updateFee();
  }

  protected onPercentChange(payerType: FeePayerType, percent?: number): void {
    const keyConfig = getPayerConfigByPayerType(payerType);
    if (!keyConfig) {
      return;
    }
    const {percentKey, dollarKey} = keyConfig;

    if (percent) {
      this.fee[percentKey] = percent;
    }
    this.fee[dollarKey] = 0;

    this._checkAfterUpdateConfig = keyConfig;

    this.updateFee();
  }

  protected onDollarChange(payerType: FeePayerType, amount?: number): void {
    const keyConfig = getPayerConfigByPayerType(payerType);
    if (!keyConfig) {
      return;
    }

    const {dollarKey, percentKey} = keyConfig;

    if (amount) {
      this.fee[dollarKey] = amount;
    }
    this.fee[percentKey] = 0;

    this._checkAfterUpdateConfig = keyConfig;

    this.updateFee();
  }

  protected onSaveClick(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this._notificationService.showError(
        'Please fix all errors in the form before saving.',
        'Error'
      );
      return;
    }

    if (this.isExistHud) {
      return;
    }

    const fee = this.fee;
    this.fee = null;
    this.save.emit(fee);
  }

  protected onCancelClick(): void {
    this.fee = null;

    this.cancel.emit();
  }

  protected onUpdateOddDaysInterest(fee: LoanFee): void {
    this.fee = fee;
    this.updateFee();
  }

  private populateExternalContacts = () => {
    if (this.externalContacts) {
      return;
    }
    const observer: Observer<ExternalContact[]> = {
      next: (externalContacts: ExternalContact[]): void => {
        const contacts = [];
        externalContacts.forEach(ec => {
          const item = new EnumerationItem(
            ec.companyName || ec.orgName || Utils.getPersonsDisplayName(ec),
            ec.externalContactId
          );
          contacts.push(item);
        });
        this.externalContacts = orderBy(contacts, x => x.name, ['asc']);
      },
      error: (err: any): void => {
        let errorMessage = 'An error occurred while retrieving external contacts.';
        if (err && err.message) {
          errorMessage = err.message;
        }
        this._notificationService.showError(errorMessage, 'Error!');
      },
      complete: (): void => {},
    };
    this._spinner.show();
    this._externalContactsService
      .getExternalContacts(this.fee.applicationId)
      .subscribe(observer)
      .add(() => {
        this._spinner.hide();
      });
  };

  private calculateBorrowerFinanced() {
    this.fee.financedBorrowerAmount =
      (this.fee.calculatedValues.borrowerPaidAtClosing ?? 0) - (this.borrowerPaidToClosing ?? 0);
  }

  private calculateBorrowerPTC() {
    this.borrowerPaidToClosing =
      (this.fee.calculatedValues.borrowerPaidAtClosing ?? 0) -
      (this.fee.financedBorrowerAmount ?? 0);
  }

  private calculateBLO() {
    if (this.isDiscountFee) {
      this.totalBSLO =
        (this.fee.calculatedValues.lenderTotal ?? 0) +
        (this.fee.calculatedValues.thirdPartyTotal ?? 0);
    } else {
      this.totalBSLO =
        (this.fee.calculatedValues.borrowerTotal ?? 0) +
        (this.fee.calculatedValues.sellerTotal ?? 0) +
        (this.fee.calculatedValues.lenderTotal ?? 0) +
        (this.fee.calculatedValues.thirdPartyTotal ?? 0);
    }
  }

  protected updateFee(): void {
    this._feeUpdater.update();
    this.resetCanBeReimbursable();
  }

  private checkAfterUpdate(): void {
    const config = this._checkAfterUpdateConfig;
    if (!config) {
      return;
    }

    const {paidOutsideClosingKey, calculatedAmountKey} = config;

    const paidOutsideClosing = this.fee[paidOutsideClosingKey];
    const calculatedAmount = this.fee.calculatedValues[calculatedAmountKey];
    const control = this.form.get(paidOutsideClosingKey);

    if (calculatedAmount != null && calculatedAmount >= paidOutsideClosing) {
      this._checkAfterUpdateConfig = null;
      if (control) {
        removeControlErrors(control, ['exceedsTotal']);
      }
      return;
    }

    if (control) {
      control.setErrors({
        exceedsTotal: 'Paid outside closing amount cannot exceed the total amount',
      });
    }
  }

  protected onPaidOutsideClosingAmountChange(payerType: FeePayerType, amount?: number): void {
    const keyConfig = getPayerConfigByPayerType(payerType);
    if (!keyConfig) {
      return;
    }

    const {paidOutsideClosingKey} = keyConfig;

    if (amount) {
      this.fee[paidOutsideClosingKey] = amount;
    }

    this._checkAfterUpdateConfig = keyConfig;

    this.updateFee();
  }

  private resetCanBeReimbursable(): void {
    const fee = this.fee;
    this.canBeReimbursable =
      fee.paidOutsideClosingBorrowerAmount > 0 ||
      fee.paidOutsideClosingBrokerAmount > 0 ||
      fee.paidOutsideClosingLenderAmount > 0 ||
      fee.paidOutsideClosingSellerAmount > 0 ||
      fee.paidOutsideClosingThirdPartyAmount > 0;

    if (!this.canBeReimbursable) {
      delete fee.isReimbursable;
    }
  }
}

interface PayerKeyConfig {
  paidOutsideClosingKey: KeyOfType<LoanFee, number>;
  percentKey: KeyOfType<LoanFee, number>;
  dollarKey: KeyOfType<LoanFee, number>;
  calculatedAmountKey: KeyOfType<FeeCalculatedValues, number>;
}

function getPayerConfigByPayerType(payerType: FeePayerType): PayerKeyConfig | undefined {
  switch (payerType) {
    case FeePayerType.Borrower:
      return {
        paidOutsideClosingKey: 'paidOutsideClosingBorrowerAmount',
        percentKey: 'borrowerFeePercent',
        dollarKey: 'borrowerFeeDollar',
        calculatedAmountKey: 'borrowerTotal',
      };
    case FeePayerType.Seller:
      return {
        paidOutsideClosingKey: 'paidOutsideClosingSellerAmount',
        percentKey: 'sellerFeePercent',
        dollarKey: 'sellerFeeDollar',
        calculatedAmountKey: 'sellerTotal',
      };
    case FeePayerType.Other:
      return {
        paidOutsideClosingKey: 'paidOutsideClosingLenderAmount',
        percentKey: 'lenderFeePercent',
        dollarKey: 'lenderFeeDollar',
        calculatedAmountKey: 'lenderTotal',
      };
    case FeePayerType.ThirdParty:
      return {
        paidOutsideClosingKey: 'paidOutsideClosingThirdPartyAmount',
        percentKey: 'thirdPartyFeePercent',
        dollarKey: 'thirdPartyFeeDollar',
        calculatedAmountKey: 'thirdPartyTotal',
      };
    default:
      console.error(`Unexpected paid to type: ${payerType}`);
      return undefined;
  }
}
