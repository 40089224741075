<div class="card" id="borrowers">
  <div class="card-header">
    <h4 class="card-title"><i class="fas fa-users me-2"></i> {{title}}
      <button
        type="button" *ngIf="!isLoanReadOnly && borrowers.length && actionButtonsVisible" class="btn btn-outline-primary btn-sm pull-right" [routerLink]="[
        '/' +
          applicationMode +
          '/app-details/' +
          applicationId +
          '/select-borrower'
      ]">
        Add Borrower
      </button>
    </h4>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="d-none d-sm-block">
        <table class="table table-hover mb-0 table-borderless">
          <thead>
            <tr class="border-bottom">
              <th class="text-secondary" *ngIf="applicationId && actionButtonsVisible">Actions</th>
              <th style="width: 20%" class="text-secondary">Name</th>
              <th style="width: 20%" class='text-secondary'>Address</th>
              <th class="text-secondary">Credit Score</th>
              <th *ngIf="applicationId" class="text-secondary">Portal Status</th>
              <th class="text-secondary">Portal Merge</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="
            let borrower of borrowers | sort : 'isPrimary';
            let i = index
          ">
              <td *ngIf="applicationId && actionButtonsVisible">
                <ng-container *ngTemplateOutlet='deleteBorrowerButtonTemplate; context: {$implicit: i}'>
                </ng-container>
                <span class="mx-1" *ngIf="!borrower.isPrimary">|</span>
                <a class="mx-1" *ngIf="!borrower.isPrimary" class="ms-2"
                  (click)="setBorrowerAsPrimary(borrower.borrowerId)">Set Primary
                </a>
              </td>

              <!-- Borrower Name -->
              <td>
                <a (click)="openBorrowerDetails(borrower.borrowerId)" class="nav nav-link pb-0 pt-0 pe-1 ps-0 d-inline" [ngStyle]="{'pointer-events': applicationId && borrower.borrowerId ? 'auto' : 'none' }">
                  {{ getBorrowerFullName(borrower) }}
                </a>

                <button type="button" id="buttonBorrowerInfo" class="btn ps-0 pb-0 pt-0" data-bs-container="body"
                  data-bs-toggle="popover" #popoverBorrowerInfo="bs-popover" placement="right" [popover]="templateBorrowerInfo" data-trigger="click"
                  [outsideClick]="true" [containerClass]="'templateBorrowerInfo'">
                  <i class="fa fa-address-book" style="font-size: 1.3em"></i>
                </button>
                <ng-template #templateBorrowerInfo>
                  <table-cell-contact-info
                    [contactInfo]="borrower"
                    [emailEnabled]="false"
                    [recordType]="'Borrower'"
                    [recordId]="borrower.borrowerId"
                    [applicationId]="applicationId"
                    [dialerEnabled]="dialerEnabled"
                    [popoverBorrowerInfo]="popoverBorrowerInfo"
                    [isManualDial]="true">
                  </table-cell-contact-info>
                </ng-template>
              </td>

              <!-- Borrower Address -->
              <td>
                <!-- Street -->
                <p *ngIf='getAddress1String(borrower) as address1' class='mb-1 one-line' [title]='address1'>
                  {{ address1 }}
                </p>

                <!-- City, State, Zip -->
                <p *ngIf='getAddress2String(borrower) as address2' class='mb-0 one-line' [title]='address2'>
                  {{ address2 }}
                </p>
              </td>

              <td>
                {{ borrower['creditScore_New'] || '--' }}
                <ng-container *ngIf="applicationId">
                  <button type="button" id="buttonCreditInfo" class="btn ps-0 pb-0 pt-0" data-bs-container="body"
                    data-bs-toggle="popover" placement="right" [popover]="templateCreditInfo" data-trigger="click"
                    [outsideClick]="true" [containerClass]="'templateCreditInfo'">
                    <i class="fa fa-flag-checkered"></i>
                  </button>

                  <ng-template #templateCreditInfo>
                    <div class="row mt-2 credit-scores-popover">
                      <ul style="list-style: none;">
                        <li><span>Experian:</span> {{ borrower['creditScore_Experian_New'] || '--' }}</li>
                        <li><span>TransUnion:</span> {{ borrower['creditScore_TransUnion_New'] || '--' }}</li>
                        <li><span>Equifax:</span> {{ borrower['creditScore_Equifax_New'] || '--' }}</li>
                      </ul>
                    </div>
                  </ng-template>
                </ng-container>
              </td>
              <td *ngIf="applicationId">
                <portal-status-indicator
                  [borrower]="borrower"
                  [applicationId]="applicationId"
                  [disabledInviteLink]="disableBorrowerPortalInviteLink">
                </portal-status-indicator>
                <span *ngIf="borrower.isRegister" [ngbTooltip]='"Open in Borrower Portal"'>
                  <button (click)='onOpenInBorrowerPortalClicked(borrower.borrowerId)' class='btn mx-1 p-0'>
                    <i class='fas fa-globe'></i>
                  </button>
                </span>
              </td>
              <td>
                <div *ngIf="borrower.isPrimary">
                  <span class="label text-success">Primary</span>
                </div>

                <div *ngIf="applicationId && !borrower.isPrimary && borrower.isPortalMerged">
                  <a class="me-2 table-icon-button text-success text-decoration" (mouseover)="portalMergeHover = i"
                    (mouseout)="portalMergeHover = -1" (click)="
                  mergePortal(borrower.borrowerId, !borrower.isPortalMerged)
                ">
                    {{ portalMergeHover == i ? 'Unmerge' : 'Merged' }}
                  </a>
                </div>

                <div *ngIf="applicationId && !borrower.isPortalMerged && !borrower.isPrimary">
                  <a class="me-2 table-icon-button text-info text-decoration" (mouseover)="portalMergeHover = i"
                    (mouseout)="portalMergeHover = -1" (click)="
                  mergePortal(borrower.borrowerId, !borrower.isPortalMerged)
                ">
                    {{ portalMergeHover == i ? 'Merge' : 'Not Merged' }}
                  </a>
                </div>
              </td>
            </tr>
            <tr *ngIf="borrowers.length == 0">
              <td colspan="4" style="height: 50px; text-align: center">
                No records found
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card d-sm-none" *ngFor="
      let borrower of borrowers | sort : 'isPrimary';
      let i = index
    ">
        <div class="card-header">
          <a (click)="openBorrowerDetails(borrower.borrowerId)" class="nav nav-link pb-0 pt-0 ps-0 d-inline"
            style="font-weight: 700">
            {{ getBorrowerFullName(borrower) }}
          </a>
          <ng-container *ngTemplateOutlet='deleteBorrowerButtonTemplate; context: {$implicit: i}'>
          </ng-container>
          <span class="mx-1">|</span>

          <span class="mx-1" *ngIf="!borrower.isPrimary">|</span>
          <a class="mx-1" *ngIf="!borrower.isPrimary" class="ms-2"
            (click)="setBorrowerAsPrimary(borrower.borrowerId)">Set Primary
          </a>
        </div>
        <div class="card-body">
          <div class="row mt-2">
            <span><strong>Address:</strong> {{ borrower.mailingStreet }} <br />
              {{ borrower.mailingCity }}
              {{
              borrower.mailingState
              ? borrower.mailingState.toUpperCase()
              : ''
              }}, {{ borrower.mailingZip }}
            </span>
          </div>
          <table-cell-contact-info [contactInfo]="borrower" [emailEnabled]="false" [recordType]="'Borrower'"
            [recordId]="borrower.borrowerId" [applicationId]="applicationId"
            [dialerEnabled]="dialerEnabled" [isManualDial]="true">
          </table-cell-contact-info>
          <portal-status-indicator [borrower]="borrower" [applicationId]="applicationId"
            [disabledInviteLink]="disableBorrowerPortalInviteLink">
          </portal-status-indicator>
        </div>

        <div class="card-footer">
          <div *ngIf="borrower.isPrimary">
            <label class="me-2">Portal Merge: </label>
            <span class="label text-success">Primary</span>
          </div>

          <div *ngIf="!borrower.isPrimary && borrower.isPortalMerged">
            <label class="me-2">Portal Merge: </label>
            <a class="me-2 table-icon-button text-success text-decoration" (mouseover)="portalMergeHover = i"
              (mouseout)="portalMergeHover = -1" (click)="
            mergePortal(borrower.borrowerId, !borrower.isPortalMerged)
          ">
              {{ portalMergeHover == i ? 'Unmerge' : 'Merged' }}
            </a>
          </div>

          <div *ngIf="!borrower.isPortalMerged && !borrower.isPrimary">
            <label class="me-2">Portal Merge: </label>
            <a class="me-2 table-icon-button text-info text-decoration" (mouseover)="portalMergeHover = i"
              (mouseout)="portalMergeHover = -1" (click)="
            mergePortal(borrower.borrowerId, !borrower.isPortalMerged)
          ">
              {{ portalMergeHover == i ? 'Merge' : 'Not Merged' }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<drawer [title]="'Editing Borrower'" #editAppBorrowerDrawerContentRef [name]="'editAppBorrowerDrawer'" [options]="editAppBorrowerDrawerOptions"
  (closed)="onBorrowerEditorClosed()">
</drawer>

<ng-template let-index #deleteBorrowerButtonTemplate>
  <!-- Need a wrapper because disabled buttons don't display tooltips -->
  <span [ngbTooltip]='canDeleteBorrower
         ? null
         : "Cannot delete the only borrower in the application"'
        [attr.title]='canDeleteBorrower ? "Delete borrower" : null'>
    <button (click)='onDeleteBorrowerClicked(index)' [disabled]='!canDeleteBorrower'
            class='btn mx-1 p-0'>
      <i [style.color]='(canDeleteBorrower ? "#1f9ad6" : "#a4abc5") + " !important"'
         class='fas fa-trash-alt'></i>
    </button>
  </span>
</ng-template>
