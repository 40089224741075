import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData } from 'ng-select2';
import { Borrower } from 'src/app/models';
import { ExternalContactsService } from 'src/app/modules/external-contacts/services/external-contacts.service';
import { InternalContact } from 'src/app/modules/internal-contacts/models/internal-contact.model';
import { InternalContactsService } from 'src/app/modules/internal-contacts/services/internal-contacts.service';
import { ExternalContact } from 'src/app/modules/loan-docs/models/external-contact.model';
import { AddContactsToEmailDialogComponent, Contacts, SelectedContacts } from '../add-contacts-to-email-dialog/add-contacts-to-email-dialog.component';
import { LoanDocsService } from 'src/app/modules/loan-docs/services/loan-docs.service';
import { AddAttachmentDialogComponent, LoanDocsByCategory } from '../add-attachment-dialog/add-attachment-dialog.component';
import { Constants } from 'src/app/services/constants';
import { DocFile } from 'src/app/modules/loan-docs/models/doc-file.model';
import { CorrespondenceService } from '../../services/correspondence.service';
import { EmailTemplate } from '../../models/email-template.model';
import { NgForm } from '@angular/forms';
import { Message } from 'src/app/models/message.model';
import { Utils } from 'src/app/core/services/utils';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import * as ClassicEditor from 'src/app/shared/utils/ckeditor5/classic/build/ckeditor';
import { NotificationService } from 'src/app/services/notification.service';
import { isArray } from 'lodash';
import { firstValueFrom, Subscription } from 'rxjs';
import { User } from 'src/app/models/user/user.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { VideoRecorderDialogComponent } from 'src/app/shared/modules/video-recorder/video-recorder-dialog/video-recorder-dialog.component';
import { JobApiService } from 'src/app/services/job-api.service';
import { BorrowersService } from 'src/app/modules/borrower/services/borrowers.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'send-email',
  templateUrl: 'send-email.component.html',
  styleUrls: ['send-email.component.scss'],
})
export class SendEmailComponent
  extends ApplicationContextBoundComponent
  implements OnInit
{
  @ViewChild('sendEmailForm')
  sendEmailForm: NgForm;

  @Input()
  loanId: number;

  @Input()
  body: string = '';

  @Input()
  forSendingVideoEmailFromTemplate: boolean = false;

  @Input()
  uploadedVideoBlob: Blob[] | undefined;

  @Input()
  set to(to: string) {
    this.commaDelimitedRecipientEmailAddresses = to;
  }

  @Input()
  showBorrowerPicker: boolean = true;

  @Input()
  set borrowers(borrowers: Borrower[]) {
    if (!isArray(borrowers)) {
      return;
    }
    this._borrowers = borrowers;
    this.borrowerOptions = borrowers.map((b) => {
      const option: Select2OptionData = {
        id: b.borrowerId.toString(),
        text: (b.firstName ? b.firstName + ' ' : '') + b.lastName,
      };
      return option;
    });
    const primaryBorrower = borrowers.find((b) => b.isPrimary);
    if (primaryBorrower) {
      this.selectedBorrowerIds.push(primaryBorrower.borrowerId.toString());
      this.commaDelimitedRecipientEmailAddresses = primaryBorrower.email;
    }
  }

  @Input()
  set attachments(attachments: DocFile[]) {
    this._attachments = attachments;
    if (attachments) {
      this._selectedFilesToAttach = attachments;
      this.numberOfFilesAttached = attachments.length;
    }
  }

  get attachments(): DocFile[] {
    return this._attachments;
  }

  htmlEditor = ClassicEditor;

  selectedBorrowerIds: string[] = [];

  commaDelimitedRecipientEmailAddresses: string = '';
  commaDelimitedBccEmailAddresses: string = '';
  commaDelimitedCcEmailAddresses: string = '';

  impersonationFrom: string = null;
  impersonationFromUserId: string = null;

  extendedInternalContacts: any;

  internalContacts: InternalContact[] = [];
  externalContacts: ExternalContact[] = [];

  borrowerOptions: Select2OptionData[] = [];

  emailTemplates: EmailTemplate[] = [];
  sendEmailSpinner: string = 'sendEmailSpinner';

  subject: string;
  appendEmailSignature: boolean = false;

  isLoading: boolean = false;

  willAddCcOrBcc: boolean = false;
  useTemplateEmail: boolean = false;

  numberOfFilesAttached: number;

  selectedEmailTemplateId: number = null;

  validationDone: boolean = false;

  showSendFrom: boolean = false;

  optionsMultipleSelect = {
    width: '100%',
    multiple: true,
    theme: 'classic',
    closeOnSelect: false,
    dropdownParent: '#sendEmailSms',
    placeholder: 'Select Borrower',
  };

  htmlEditorConfig: any = {};

  ckEditorConfig = Constants.defaultCkEditorConfig;

  protected uploadedVideoBytes: string | undefined;

  private _recipientEmailAddresses: string[] = [];
  private _loanDocs: LoanDocsByCategory[] = [];

  private _selectedContacts: SelectedContacts = new SelectedContacts();
  private _selectedContactsForCc: SelectedContacts = new SelectedContacts();
  private _selectedContactsForBcc: SelectedContacts = new SelectedContacts();

  private _selectedFilesToAttach: DocFile[] = [];
  private _attachments: DocFile[] = [];
  private _borrowers: Borrower[] = [];

  private _applicationContextSubscription: Subscription;

  constructor(
    private readonly _modalService: NgbModal,
    private readonly _internalContactsService: InternalContactsService,
    private readonly _externalContactsService: ExternalContactsService,
    private readonly _loanDocService: LoanDocsService,
    private readonly _correspondenceService: CorrespondenceService,
    private readonly _jobserService: JobApiService,
    private readonly _notificationService: NotificationService,
    private readonly _configurationService: ConfigurationService,
    private readonly _borrowerService: BorrowersService,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly injector: Injector
  ) {
    super(injector);

    this.htmlEditorConfig = {
      toolbar: {
        shouldNotGroupWhenFull: true,
      },
    };
  }

  async ngOnInit() {
    this._applicationContextSubscription =
      this.applicationContextService.context.subscribe((context) => {
        this.initialize();
      });
    if (this.forSendingVideoEmailFromTemplate) {
      await this.loadVideoTemplate(this.selectedBorrowerIds[0]);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._applicationContextSubscription) {
      this._applicationContextSubscription.unsubscribe();
    }
  }

  initialize() {
    this.isLoading = true;
    const users = this.applicationContext.globalConfig.usersAll.concat(
      this.applicationContext.globalConfig.tpoUsers
    );
    this._configurationService
      .getCompanyConfiguration('AllowEmailImpersonation')
      .subscribe((allowEmailImpersonation) => {
        this.showSendFrom = allowEmailImpersonation.value === 1;
      });
    this._internalContactsService.getInternalContacts(this.loanId).subscribe(
      (internalContacts) => {
        this.internalContacts = internalContacts;
        this.populateInternalContactsList(users);
        this._externalContactsService
          .getExternalContacts(this.loanId)
          .subscribe(
            (externalContacts) => {
              this.externalContacts = externalContacts;
              this._loanDocService.getLoanDocs(this.loanId).subscribe(
                (loanDocs) => {
                  this._loanDocs =
                    this._loanDocService.generateLoanDocsByCategory(
                      loanDocs,
                      this.applicationContext.globalConfig.documentType,
                      this.applicationContext.userPermissions,
                      this.applicationContext.isTpo
                    );
                  this._correspondenceService
                    .getLoanEmailTemplates(
                      this.applicationContext.userPermissions.userId
                    )
                    .subscribe(
                      (templates) => {
                        this.emailTemplates = templates;
                        this.isLoading = false;
                      },
                      (error) => {
                        this.isLoading = false;
                        this._notificationService.showError(
                          error && error.message
                            ? error.message
                            : 'An error occurred while getting email tamplates.',
                          'Error!'
                        );
                      }
                    );
                  this.isLoading = false;
                },
                (error) => {
                  this.isLoading = false;
                  this._notificationService.showError(
                    error && error.message
                      ? error.message
                      : 'An error occurred while getting loan docs.',
                    'Error!'
                  );
                }
              );
            },
            (error) => {
              this.isLoading = false;
              this._notificationService.showError(
                error && error.message
                  ? error.message
                  : 'An error occurred while getting external contacts.',
                'Error!'
              );
            }
          );
      },
      (error) => {
        this.isLoading = false;
        this._notificationService.showError(
          error && error.message
            ? error.message
            : 'An error occurred while getting internal contacts.',
          'Error!'
        );
      }
    );
  }

  getEmailToSend = (): Message => {
    let email = new Message();
    email.applicationId = this.loanId;
    email.to = this.commaDelimitedRecipientEmailAddresses;
    email.subject = this.subject;
    email.body = this.body;
    email.bcc = this.commaDelimitedBccEmailAddresses;
    email.cc = this.commaDelimitedCcEmailAddresses;
    email.attachmentGuids = this._selectedFilesToAttach
      .map((f) => f.guid)
      .toString();
    const bodyWithAttachments = Utils.parseEmailImages(this.body);
    email.imageAttachments = bodyWithAttachments.attachments;
    email.body = bodyWithAttachments.body;
    return email;
  };

  onSelectedEmailTemplateChanged = () => {
    const selectedEmailTemplate = this.emailTemplates.find(
      (t) => t.emailTemplateId == this.selectedEmailTemplateId
    );
    if (selectedEmailTemplate) {
      this._correspondenceService
        .getEmailTemplateForLoan(
          selectedEmailTemplate.emailTemplateId,
          this.loanId
        )
        .subscribe(
          (template) => {
            this.body = template.emailText;
            this.subject = template.subject;
          },
          (error) => {
            this._notificationService.showError(
              error && error.message
                ? error.message
                : 'An error occurred while getting email tamplate.',
              'Error!'
            );
          }
        );
    }
  };

  onAddAttachmentsClicked = () => {
    const modalRef = this._modalService.open(
      AddAttachmentDialogComponent,
      Constants.modalOptions.large
    );
    modalRef.componentInstance.loanDocsByCategory = this._loanDocs;
    modalRef.result.then(
      (selectedFiles: DocFile[]) => {
        this._selectedFilesToAttach = selectedFiles;
        this.numberOfFilesAttached = selectedFiles.length;
      },
      () => {}
    );
  };

  onSelectedBorrowersChanged = () => {
    this.populateCommaDelimitedRecipientEmailAddresses(
      this.selectedBorrowerIds,
      this._selectedContacts
    );
  };

  onAddContactsAsCcToEmailClicked = () => {
    const modalRef = this.openAddContactsToEmailDialog(
      this._selectedContactsForCc
    );
    modalRef.result.then(
      (selectedContacts: SelectedContacts) => {
        this._selectedContactsForCc = selectedContacts;
        this.commaDelimitedCcEmailAddresses = '';
        const uniqueEmails =
          this.generateUniqueEmailAddressListFrom(selectedContacts);
        this.commaDelimitedCcEmailAddresses = uniqueEmails.join(',');
      },
      () => {}
    );
  };

  onAddContactsAsBccToEmailClicked = () => {
    const modalRef = this.openAddContactsToEmailDialog(
      this._selectedContactsForBcc
    );
    modalRef.result.then(
      (selectedContacts: SelectedContacts) => {
        this._selectedContactsForBcc = selectedContacts;
        this.commaDelimitedBccEmailAddresses = '';
        const uniqueEmails =
          this.generateUniqueEmailAddressListFrom(selectedContacts);
        this.commaDelimitedBccEmailAddresses = uniqueEmails.join(',');
      },
      () => {}
    );
  };

  onAddContactsToEmailClicked = () => {
    const modalRef = this.openAddContactsToEmailDialog(this._selectedContacts);
    modalRef.result.then(
      (selectedContacts: SelectedContacts) => {
        this._selectedContacts = selectedContacts;
        this.populateCommaDelimitedRecipientEmailAddresses(
          this.selectedBorrowerIds,
          this._selectedContacts
        );
      },
      () => {}
    );
  };

  onImpersonationFromChanged = () => {
    if (this.impersonationFromUserId) {
      let contact = this.extendedInternalContacts.find(
        (ic) => ic.userId == this.impersonationFromUserId
      );
      if (contact) {
        this.impersonationFrom = contact.email;
      } else {
        this.impersonationFrom = null;
      }
    } else {
      this.impersonationFrom = null;
    }
  };

  onReady(editor: ClassicEditor): void {
    // editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    //   return new Base64UploadAdapter(loader);
    // };
  }

  validate = (): boolean => {
    this.validationDone = true;
    if (this.sendEmailForm) {
      this.sendEmailForm.form.markAllAsTouched();
      return this.sendEmailForm.form.valid;
    }
  };

  protected recordVideo = () => {
    const modalRef = this._modalService.open(VideoRecorderDialogComponent, {
      ...Constants.modalOptions.large,
      scrollable: false,
    });
    modalRef.result.then(
      async (video) => {
        try {
          this._spinnerService.show(this.sendEmailSpinner);
          await this.addRecordedVideo(video);
        } finally {
          this._spinnerService.hide(this.sendEmailSpinner);
        }
      },
      (err) => {}
    );
  };

  private async loadVideoTemplate(selectedBorrowerIds: string) {
    try {
      this._spinnerService.show(this.sendEmailSpinner);
      const template: any = await firstValueFrom(
        this._borrowerService.getVideoTemplateByBorrowerIds(selectedBorrowerIds)
      );
      this.subject = template.subject;
      this.body = template.body;
      const videoMessageLinkPlaceholder = '${VideoMessageLink}';
      if (this.body.includes(videoMessageLinkPlaceholder)) {
        await this.addRecordedVideo(
          this.uploadedVideoBlob,
          videoMessageLinkPlaceholder
        );
      }
    } catch (error) {
      this._notificationService.showError(
        error && error.message
          ? error.message
          : 'An error occurred while loading the video email.',
        'Error!'
      );
    }
    finally {
      this._spinnerService.hide(this.sendEmailSpinner);
    }
  }

  private addRecordedVideo = async (
    data: Blob[],
    placeholder: string = null
  ) => {
    const file = new File(data, 'uploaded-video.webm', { type: 'video/webm' });
    Utils.toBase64(file).subscribe((base64) => {
      this.uploadedVideoBytes = base64;
    });
    try {
      const gifBlob = await firstValueFrom(
        this._jobserService.convertToGif(file)
      );
      if (gifBlob) {
        Utils.toBase64(gifBlob).subscribe((base64Gif) => {
          const imageTag = `<div><a href="link" target="_blank" videodata="${this.uploadedVideoBytes}">
            <img src="${base64Gif}" alt="Animated GIF" width="320" height="240">
          </a></div>`;
          this.body =
            placeholder != null
              ? this.body.replace(placeholder, imageTag)
              : (this.body += imageTag);
        });
      }
    } catch (error) {
      this._notificationService.showError(
        error && error.message
          ? error.message
          : 'An error occurred while preparing video preview.',
        'Error!'
      );
    }
  };

  private populateCommaDelimitedRecipientEmailAddresses = (
    selectedBorrowerIds: string[],
    selectedContacts: SelectedContacts
  ) => {
    this.commaDelimitedRecipientEmailAddresses = '';
    this._recipientEmailAddresses = [];
    this.addSelectedBorrowersEmailAddresses(selectedBorrowerIds);
    this.addContactsEmailAddresses(selectedContacts);
    this.commaDelimitedRecipientEmailAddresses =
      this._recipientEmailAddresses.join(',');
  };

  private addSelectedBorrowersEmailAddresses = (
    selectedBorrowerIds: string[]
  ) => {
    const selectedBorrowers = this._borrowers.filter((b) =>
      selectedBorrowerIds.includes(b.borrowerId.toString())
    );
    selectedBorrowers.forEach((borrower: Borrower) => {
      if (borrower.email) {
        const lowerCaseEmail = borrower.email.trim().toLocaleLowerCase();
        if (!this._recipientEmailAddresses.includes(lowerCaseEmail)) {
          this._recipientEmailAddresses.push(lowerCaseEmail);
        }
      }
    });
  };

  private addContactsEmailAddresses = (selectedContacts: SelectedContacts) => {
    this.addEmailsToRecipientAddresses(
      selectedContacts.selectedInternalContactEmails
    );
    this.addEmailsToRecipientAddresses(
      selectedContacts.selectedExternalContactEmails
    );
  };

  private addEmailsToRecipientAddresses = (emails: string[]) => {
    emails.forEach((email: string) => {
      if (email && email != 'undefined') {
        const lowerCaseEmail = email.trim().toLocaleLowerCase();
        if (!this._recipientEmailAddresses.includes(lowerCaseEmail)) {
          this._recipientEmailAddresses.push(lowerCaseEmail);
        }
      }
    });
  };

  private generateUniqueEmailAddressListFrom = (
    selectedContacts: SelectedContacts
  ): string[] => {
    const uniqueEmails: string[] = [];
    const emails = selectedContacts.selectedInternalContactEmails.concat(
      selectedContacts.selectedExternalContactEmails
    );
    emails.forEach((email: string) => {
      if (email && email != 'undefined') {
        const lowerCaseEmail = email.trim().toLocaleLowerCase();
        if (!uniqueEmails.includes(lowerCaseEmail)) {
          uniqueEmails.push(lowerCaseEmail);
        }
      }
    });
    return uniqueEmails;
  };

  private openAddContactsToEmailDialog = (
    selectedContacts: SelectedContacts
  ): NgbModalRef => {
    const modalRef = this._modalService.open(
      AddContactsToEmailDialogComponent,
      {
        size: 'md',
        backdrop: 'static',
        centered: true,
      }
    );
    modalRef.componentInstance.applicationContext = this.applicationContext;
    const contacts: Contacts = {
      internalContacts: this.internalContacts,
      externalContacts: this.externalContacts,
    };
    modalRef.componentInstance.contacts = contacts;
    modalRef.componentInstance.selectedContacts = selectedContacts;
    return modalRef;
  };

  private getAssociates = (stackingOrderId: number): number[] => {
    var associates = [];
    this.applicationContext.globalConfig.stackingOrderDocTypes.forEach(
      (stackingOrder) => {
        if (stackingOrder.stakingOrderId == stackingOrderId) {
          if (stackingOrder.associate != null) {
            associates = stackingOrder.associate
              .split(',')
              .map((a) => Number(a));
          }
        }
      }
    );
    return associates;
  };

  private populateInternalContactsList = (users: User[]) => {
    this.extendedInternalContacts = this.internalContacts
      .filter((internalContact) => internalContact.userId)
      .map((internalContact) => {
        const matchingUser = users.find(
          (u) => u.userCompanyGuid == internalContact.userId
        );
        let formattedName = this.getFormattedName(
          matchingUser?.lastName,
          matchingUser?.firstName
        );
        formattedName =
          formattedName === '' || matchingUser?.active
            ? formattedName
            : `${formattedName} - (Inactive)`;
        return {
          ...internalContact,
          roleName: this.getRoleNameById(internalContact.roleId),
          formattedName,
          email: matchingUser?.email,
        };
      });
  };

  private getFormattedName(lastName: string, firstName: string) {
    if (firstName && lastName) {
      return `${lastName}, ${firstName}`;
    }
    if (firstName) {
      return firstName;
    }
    if (lastName) {
      return lastName;
    }
    return '';
  }

  private getRoleNameById(roleId: number) {
    return (
      this.applicationContext.globalConfig.roles.find((u) => u.roleId == roleId)
        ?.roleName || ''
    );
  }
}
