import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { chain, isNil } from 'lodash';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { Utils } from 'src/app/core/services/utils';
import { LoanApplication } from 'src/app/models';
import { LeadCampaign } from 'src/app/models/config/global-config.model';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { LoanType } from 'src/app/models/config/loan-type.model';
import { ReferralSource } from 'src/app/models/referral-source.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { UpsertReferralSourceComponent } from 'src/app/modules/referral-source/components/upsert-referral-source/upsert-referral-source.component';
import { AgentService } from 'src/app/services/agent.service';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { AgentFull } from '../../../models/agent.model';
import { AppDetailsService } from '../../../services/app-details.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'loan-referral-info',
  templateUrl: './loan-referral-info.component.html',
  styleUrls: ['./loan-referral-info.component.scss'],
  viewProviders: [formViewProvider]
})
export class LoanReferralInfoComponent implements OnInit {

  @Input()
  application: LoanApplication;

  @Output()
  loanPurposeChanged = new EventEmitter();

  enabledChannels: EnumerationItem[] = [];
  loanPurposeOptions: EnumerationItem[] = [];
  loanTypeOptions: EnumerationItem[] = [];
  leadCampaigns: LeadCampaign[] = [];
  referralSources: ReferralSource[] = [];

  isLeadCampaignDirty: boolean;

  isAdmin: boolean;

  isReferralIdDirty: boolean;
  isPurchase: boolean = true;

  private _allLoanPurposes: LoanPurpose[] = [];
  private _allLoanTypes: LoanType[] = [];

  private _companyId: number;

  protected channel: string;
  protected loanPurposeId: number;

  constructor(
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _agentService: AgentService,
    private readonly _modalService: NgbModal,
    private readonly _notifyService: NotificationService
  ) {
  }

  ngOnInit(): void {
    this._applicationContextService.context.subscribe(context => {
      this.enabledChannels = context.globalConfig.enabledChannels;
      this._allLoanPurposes = context.globalConfig.loanPurpose;
      this._allLoanTypes = context.globalConfig.loanType;
      this._companyId = context.userPermissions.companyId;
      this.filterLoanTypesAndPurposes(this.application);
      this.leadCampaigns = context.globalConfig.leadCampaigns.filter(x => x.active || x.leadCampaignId == this.application.leadCampaignId);
      this.isAdmin = context.userPermissions.admin;
      this.loadReferralSources();
    });

    this.loanPurposeId = this.application.loanPurposeId;
    this.channel = this.application.channel;
  }

  static setApplicationDefaults(application: LoanApplication) {
    application.leadSource ??= "";
    application.leadRefId ??= "";
  }

  onReferralIdChanged = () => {
    this.isReferralIdDirty = true;
  }

  onLeadCampaignChanged = () => {
    this.isLeadCampaignDirty = true;
  }

  onAddNewReferralSourceClicked = () => {
    const modalRef = this._modalService.open(UpsertReferralSourceComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.agents = this.referralSources;

    modalRef.result.then((newAgent: AgentFull) => {
      this.loadReferralSources(newAgent);
    }, () => {
    });
  }

  onLoanTypeChanged = () => {
    this._applicationContextService.updateApplication(this.application);
  }

  loanTermValuesChanged() {
    if (!this.application.subordinateLienAmount) {
      this.application.subordinateLienAmount = 0;
    }
    if (!this.application.cashOutAmount) {
      this.application.cashOutAmount = 0;
    }
    if (!this.application.mipAmount) {
      this.application.mipAmount = 0;
    }

    if (!this.isPurchase) {
      this.application.purchasePrice = null;
    }
  }

  private showChangeConfirmationDialogForProperty(propertyName: string) {
    return Swal.fire({
      title: 'Are you sure?',
      text: `Changing ${propertyName} will reset your Loan Status to the
       beginning after you save. Are you sure you want to do this?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      cancelButtonColor: '#DC3741',
      reverseButtons: true,
    });
  }

  private confirmChannel = () => {
    this.application.channel = this.channel;
  }

  private discardChannel = () => {
    this.channel = this.application.channel;
  }

  showChannelConfirmationDialog = async () => {
    const result
      = await this.showChangeConfirmationDialogForProperty('Channel');

    if (result.isConfirmed) {
      this.confirmChannel();
    } else {
      this.discardChannel();
    }
  }

  private setMortgageLoanPurpose = () => {
    const applicationLoanPurpose = this._allLoanPurposes.find(
      (p) => (p.loanPurposeId == this.loanPurposeId)
    );
    if (applicationLoanPurpose) {
      this.isPurchase =
        ['Refinance', 'RefinanceCashOut'].indexOf(applicationLoanPurpose.mortgageLoanPurpose) ===
        -1;
    }
  };

  private onChangeLoanPurpose = () => {
    this.loanPurposeChanged.emit();
    this.loanTermValuesChanged();
  }

  private confirmLoanPurposeId = () => {
    this.application.loanPurposeId = this.loanPurposeId;
    this.setMortgageLoanPurpose();
    this.onChangeLoanPurpose();
  }

  private discardLoanPurposeId = () => {
    this.loanPurposeId = this.application.loanPurposeId;
  }

  showLoanPurposeChangeConfirmationDialog = async () => {
    const result = await this.showChangeConfirmationDialogForProperty('Loan Purpose');
    if (result.isConfirmed) {
      this.confirmLoanPurposeId();
    } else {
      this.discardLoanPurposeId();
    }
  }

  private filterLoanTypesAndPurposes = (application: LoanApplication) => {
    // No need to filter purposes or types when there is no branch id or external company id.
    if (!application.externalCompanyId || !this.application.branchId) {
      this.loanPurposeOptions = this._allLoanPurposes.map(
        (lp) => new EnumerationItem(lp.loanPurposeName, lp.loanPurposeId)
      );
      this.loanTypeOptions = this._allLoanTypes.map(
        (lt) => new EnumerationItem(lt.loanTypeName, lt.loanTypeId)
      );
      return;
    }
    this._appDetailsService.getAllBranchLoanOptions(application.branchId, this._companyId).subscribe((response) => {
      let allowedLoanTypes = response.allowedLoanTypes.split(',');
      this._allLoanTypes.forEach((lt) => {
        if (allowedLoanTypes.includes(lt.loanTypeId.toString())) {
          this.loanTypeOptions.push(
            new EnumerationItem(lt.loanTypeName, lt.loanTypeId)
          );
        }
      });

      let allowedLoanPurposes = response.allowedLoanPurposes.split(',');
      this._allLoanPurposes.forEach((lp) => {
        if (allowedLoanPurposes.includes(lp.loanPurposeId.toString())) {
          this.loanPurposeOptions.push(
            new EnumerationItem(lp.loanPurposeName, lp.loanPurposeId)
          );
        }
      });
    });
  };

  private loadReferralSources = (defaultSelectedAgent: AgentFull = null) => {
    this._agentService.getAllReferralSources().subscribe(response => {
      this.referralSources = chain(response)
        .filter(a => !isNil(a.firstName) || !isNil(a.lastName))
        .map(agent => ({
          ...agent,
          displayName: Utils.getPersonsDisplayName(agent)
        }))
        .orderBy(['lastName', 'firstName'])
        .value();

      if (defaultSelectedAgent) {
        const referralSourceToSelect = this.referralSources.find(a => a.firstName.toLocaleLowerCase() === defaultSelectedAgent.agent.firstName.toLocaleLowerCase() &&
          a.lastName.toLocaleLowerCase() === defaultSelectedAgent.agent.lastName.toLocaleLowerCase());
        if (referralSourceToSelect) {
          this.application.referralSource = referralSourceToSelect.agentId;
        }
      }
    });
  }
}
