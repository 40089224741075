import { CalculatedFeeTypeEnum } from './calculated-fee-type.enum';
import { EscrowDisbursement } from './escrow-fee-schedule.model';
import { FeeCalculatedValues } from './fee-calculated-values.model';
import { FeePaidToEnum } from './fee-paid-to.enum';
import { FeePercentOfFieldEnum } from './fee-percent-of-field.enum';
import { FeeSectionEnum } from './fee-section.enum';
import { FeeTypeEnum } from './fee-type.enum';

export enum FeeSources {
  Manual = "Manual",
  Template = "Template",
  Ernst = "Ernst",
  Los = "Los",
  FeeIntegration = "FeeIntegration",
  Loan = "Loan",
  Permanent = "Permanent",
  ImportedFile = "ImportedFile"
}

export enum ThirdPartyIntegrationProvider {
  Connexions = "Connexions",
  DocMagic = "DocMagic",
  Ernst = "Ernst",
  StatesTitle = "StatesTitle",
  MicrosoftGraph = "MicrosoftGraph",
  Ayrshare = "Ayrshare",
  Sisense = "Sisense",
  FormFree = "FormFree",
  DocuSign = "DocuSign",
  MeridianLink = "MeridianLink",
  TurboInsurance = "TurboInsurance",
  FannieMae = "FannieMae",
  FreddieMac = "FreddieMac",
  Equifax = "Equifax",
  RockConnection = "RockConnection",
  Halycon = "Halycon",
  OpenAI = "OpenAI",
  AmazonWebServices = "AmazonWebServices",
  Mers = "Mers",
  LenderPrice = "LenderPrice",
  OptimalBlue = "OptimalBlue",
  Lodestar = "Lodestar",
  Encompass = "Encompass",
  MortgageDirector = "MortgageDirector",
  Xactus = "Xactus",
  Reggora = "Reggora",
  Polly = "Polly",
  MortgageCTO = "MortgageCTO",
  Dda = "Dda",
  Visionet = "Visionet",
  LoanPass = "LoanPass",
  PmiRatePro = "PmiRatePro",
  ISoftPull = "ISoftPull",
  LoanPassIframe = "LoanPassIframe",
  LenderPriceIframe = "LenderPriceIframe",
  SoftPro = "SoftPro",
  CalyxPath = "CalyxPath",
  BytePro = "BytePro",
  Truv = "Truv",
  Custom = "Custom"
}

export class LoanFee {
  applicationId?: number | null;
  pricingScenarioId?: number | null;
  externalContactId?: number;
  hudNumber?: string | null;
  name?: string | null;
  feeSection: FeeSectionEnum;
  feeType: FeeTypeEnum;
  feeTypeOtherDescription?: string;
  feeSource: FeeSources;
  feeSourceIntegration: ThirdPartyIntegrationProvider;
  financedBorrowerAmount: number;
  latestFeeValueSource: FeeSources
  sumInHudNumber?: number | null;
  isSummaryFee: boolean;
  isImpound?: boolean | null;
  isChargeUserEditable: boolean;
  effectiveDate?: Date | null;
  escrowDisbursements: EscrowDisbursement[] = [];
  calculatedFeeType?: CalculatedFeeTypeEnum | null;
  calculatedValues: FeeCalculatedValues = new FeeCalculatedValues();
  allowSplit: boolean;
  canSellerConcessionsApply: boolean;
  canSetPaidTo: boolean;
  isAprFee: boolean;
  feePercentOf: FeePercentOfFieldEnum;
  borrowerFeeDollar?: number;
  borrowerFeePercent?: number;
  sellerFeeDollar?: number;
  sellerFeePercent?: number;
  lenderFeeDollar?: number;
  lenderFeePercent?: number;
  thirdPartyFeeDollar?: number;
  thirdPartyFeePercent?: number;
  isChargeOverridden?: boolean | null;
  paidOutsideClosing?: number | null;
  paidOutsideClosingBorrowerAmount?: number | null;
  paidOutsideClosingBrokerAmount?: number | null;
  paidOutsideClosingSellerAmount?: number | null;
  paidOutsideClosingLenderAmount?: number | null;
  paidOutsideClosingThirdPartyAmount?: number | null;
  paidToType: FeePaidToEnum;
  paidTo?: string | null;
  borrowerSelectedServiceProvider: boolean | null;
  canShop: boolean | null;
  note: string | null;
  showOnHUD: boolean | null;
  includeInRegulationZPointsAndFeesCalculations?: boolean | null;
  includeInJurisdictionPointsAndFeesCalculations?: boolean | null;
  originalTotalFee: number | null;
  sourceFeeTemplateId?: number;
  isOptionalCost?: boolean;
  isLoanCredit?: boolean;
  escrowPaymentFrequency?: EscrowPaymentFrequencyEnum | null;
  escrowPremiumPaymentType?: EscrowPremiumPaymentTypeEnum | null;
  escrowInsurancePolicyId?: string;
  isBorrowerChosenProvider?: boolean;
  hasRequiredServiceProvider?: boolean;

  modelGuid: string | null;
  companyId: number;
  insertedBy?: string | null;
  updatedBy?: string | null;
  dateInserted?: Date | null;
  dateUpdated?: Date | null;
  isReimbursable?: boolean;
}

export class FeesToUpdateRequest {
  updateCharges?: boolean;
  feesToUpdate: LoanFee[];
  existingLoanFees: LoanFee[];
}

export enum EscrowPaymentFrequencyEnum {
  Annual = "Annual",
  Monthly = "Monthly",
  Other = "Other",
  Quarterly = "Quarterly",
  SemiAnnual = "SemiAnnual",
  Unequal = "Unequal"
}

export enum EscrowPremiumPaymentTypeEnum {
  CollectAtClosing = "CollectAtClosing",
  PaidOutsideOfClosing = "PaidOutsideOfClosing",
  Waived = "Waived"
}




