import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import { UWDecisionComponent } from './components/uw-decision.component';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        SharedModule,
        CommonModule
    ],
    declarations: [
        UWDecisionComponent
    ],
    exports: [
        UWDecisionComponent
    ],
    providers: [
        
    ]
})
export class UWDecisionModule {
  constructor() {

  }
}


