import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from '../core/services/data.service';
import { Company, Profile, UserData, UserPermissions } from '../models';
import { CacheService } from './cache.service';

@Injectable()
export class UserService {

  constructor(private readonly _dataService: DataService,
    private readonly _cacheService: CacheService) { }

  getLoggedInUserData = (): Observable<UserData> => {
    return this._dataService.get('api/auth/userdata').pipe(
      map((user) => {
        if (user.avatar === 'content/img/avatars/male.png') {
          user.avatar = 'assets/images/male.png';
        }
        return user;
      })
    );
  };

  getProfile = (userId: string): Observable<Profile> => {
    return this._dataService.get(`api/admin/profile/${userId}`);
  };

  getUserCompany = (userName: string): Observable<Array<Company>> => {
    return this._dataService.post(
      `api/auth/AllCompanyForUserName?username=${encodeURIComponent(
        userName
      )}`,
      {}
    );
  };

getUserPermissions = (bypassCache: boolean = false): Observable<UserPermissions> => {
  const url = 'api/admin/permissionMenu';
    return bypassCache ? this._dataService.get(url) : this._cacheService.wrap<UserPermissions>(url, this._dataService.get(url), 60, false, true);
  };
}
