

<div class="flex-grow-1">
  <div *ngIf="error" class="card" style="height:90vh; width: 100%;">
    <div class="card-body">
      <div class="alert custom-alert custom-alert-danger icon-custom-alert shadow-sm my-3 ms-3" role="alert">
        <div class="media">
          <i class="la la-exclamation-triangle alert-icon text-danger font-30 me-3 align-self-center"></i>
          <div class="media-body align-self-center">
            <span>{{ error }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center align-items-center h-100 my-auto" *ngIf="preparingForPricing">
    <div class="d-flex">
      <div class="spinner-border thumb-xxs text-primary me-2 mt-2" role="status"></div>
      <h5 *ngIf="loanSyncAttempts <= 1" class="font-18 text-muted me-4">
        {{isQuickPricer ? 'Loading' : 'Preparing your loan' }}, please wait...
      </h5>
      <h5 *ngIf="loanSyncAttempts > 1" class="font-18 text-muted me-4">
        Retry attempt {{loanSyncAttempts - 1}}. Preparing your loan, please wait...
      </h5>
    </div>
  </div>

  <div class="d-flex align-items-center justify-content-center h-100 my-auto" style="min-height: 603px;"
    *ngIf="!selectedProfileChannel">
    <div>
      <h4 class="card-title mb-3">Polly - Pricing Profile:</h4>
      <select class="form-select" id="pricingProfile" name="pricingProfile" #pricingProfileInput="ngModel"
        [(ngModel)]="selectedProfileChannel" (ngModelChange)="onPollyPriceChannelChanged($event)" required
        [ngClass]="{'is-invalid' : pricingProfileInput && pricingProfileInput.touched && pricingProfileInput.invalid}">
        <option [ngValue]="null">--Select One--</option>
        <ng-container *ngFor="let profile of groupedVendorProfiles">
          <optgroup *ngIf="profile.externalCompanyName else noCompanyGroup" label="{{profile.externalCompanyName}}">
            <ng-container *ngFor="let compCred of profile.externalCompanyCreds">
              <ng-container *ngIf="compCred.branchName else noBranchGroup">
                <option disabled class="option-group">
                  {{compCred.branchName}}
                </option>
                <option *ngFor="let cred of compCred.branchCreds" [ngValue]="cred">
                  &nbsp;&nbsp;{{cred.name}}</option>
              </ng-container>
              <ng-template #noBranchGroup>
                <option *ngFor="let cred of compCred.branchCreds" [ngValue]="cred">
                  {{cred.name}}</option>
              </ng-template>
            </ng-container>
          </optgroup>
          <ng-template #noCompanyGroup>
            <option *ngFor="let cred of profile.externalCompanyCreds" [ngValue]="cred">
              {{cred.name}}</option>
          </ng-template>
        </ng-container>
      </select>
      <div class="invalid-feedback">Please, pick a pricing profile</div>
    </div>
  </div>

  <div style="height:90vh; width: 100%;" *ngIf="pollyPricerUrl && selectedProfileChannel">
    <iframe sandbox="allow-same-origin allow-forms allow-scripts" [src]="pollyPricerUrl | safeUrl" 
      width="100%" height="100%" #iFrame>
    </iframe>
  </div>
</div>