<p-table #dt *ngIf="userId" [value]="tasks" [paginator]="true" [rows]="25" [rowsPerPageOptions]="[25, 50, 100]"
    [globalFilterFields]="globalFilterFields" [showCurrentPageReport]="true" [(selection)]="selectedRows"
    [autoLayout]="true" responsiveLayout="scroll" [scrollable]="scrollable" (onHeaderCheckboxToggle)="handleHeaderCheckboxToggle()"
    [scrollHeight]="(scrollable && expandToVisibleScreen) ? scrollHeight : null"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    styleClass="p-datatable-gridlines p-datatable-sm" sortField="snoozeDate" sortMode="single" [sortOrder]="1"
    id="tbl_taskGrid" (sortFunction)="customSort($event)" [customSort]="true">
    <ng-template pTemplate="caption">
        <div class="p-d-flex">
            <div>
                <button *ngIf="canDeleteTasks()" class="btn btn-soft-primary mx-1" (click)="deleteTasks()">
                    <span><i class="fa fa-trash-alt fa-lg"></i> Delete</span>
                </button>
                <button *ngIf="!isLeadTaskFilter" class="btn btn-soft-primary mx-1" (click)="reassignTasks()"
                    [disabled]="!selectedRows || selectedRows.length == 0">
                    <span><i class="fa fa-user fa-lg"></i> Reassign</span>
                </button>
                <button class="btn btn-soft-primary mx-1" (click)="postponeTasks()"
                    [disabled]="!selectedRows || selectedRows.length == 0">
                    <span><i class="far fa-calendar fa-lg"></i> Postpone</span>
                </button>
                <button class="btn btn-soft-primary mx-1" (click)="snoozeTasks()"
                    [disabled]="!selectedRows || selectedRows.length == 0">
                    <span><i class="far fa-calendar fa-lg"></i> Snooze</span>
                </button>
                <button class="btn btn-soft-primary mx-1" (click)="changeStatus()"
                    [disabled]="!selectedRows || selectedRows.length == 0">
                    <span><i class="far fa-calendar fa-lg"></i> Status</span>
                </button>
            </div>
            <div class="p-ml-auto d-flex">
                <div *ngIf="showFilters">
                    <button *ngFor="let stat of taskStats" class="btn btn-soft-primary mx-1"
                        [ngClass]="{'btn-primary active': stat.id == filterId}" (click)="changeFilter(stat)">
                        <span>{{stat.name}}</span>
                    </button>
                </div>

                <span class="p-input-icon-right ">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" class="form-control" (ngModelChange)="dt.first=0"
                        (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
                </span>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr (dragenter)="onDragEnterHeader($event)">
            <th style="width: 164px;">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                Action
            </th>
            <th [pSortableColumn]="['leadId','fullName']" *ngIf="(filterId > 10) && (16 > filterId)">
                Lead / Borrower
                <p-sortIcon [field]="['leadId','fullName']"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'applicationName'" *ngIf="((11 > filterId) || (filterId > 15)) && !appId">
                App # (Borrower) / Resp. Party
                <p-sortIcon [field]="'applicationName'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'fullName'" *ngIf="((11 > filterId) || (filterId > 15)) && appId">
                Borrower / Resp. Party
                <p-sortIcon [field]="'fullName'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="['leadId','fullName, applicationName']" *ngIf="!filterId">Lead/Borrower/App Info
                <p-sortIcon [field]="['leadId','fullName, applicationName']"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'docTypeText'" *ngIf="(11 > filterId) || (filterId > 15)">
                Document Type
                <p-sortIcon [field]="'docTypeText'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'description'">
                Description
                <p-sortIcon [field]="'description'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'note'" style="min-width: 110px; width:400px; word-wrap: break-word;">
                Note
                <p-sortIcon [field]="'note'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'snoozeDate'" style="width:160px;">
                Due / Requested
                <p-sortIcon [field]="'snoozeDate'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'docFiles'" *ngIf="(11 > filterId) || (filterId > 15) || showViewFiles">
                View File
                <p-sortIcon [field]="'docFiles'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'urgency'" *ngIf="(11 > filterId) || (filterId > 15)">
                Flags
                <p-sortIcon [field]="'urgency'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'taskStatus'">
                Status
                <p-sortIcon [field]="'taskStatus'"></p-sortIcon>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
        <tr class="drop_zone" id="row.loanDocTaskId" (dragover)="onDragOver($event)" (dragenter)="onDragEnter($event)"
            (dragleave)="onDragLeave($event)" (drop)="onDrop($event,row)">
            <td>
                <!-- Actions Start -->
                <p-tableCheckbox [value]="row"
                    *ngIf="(canDelete || allowBulkActions) && !(row.taskType == 'EsignDocument' || row.taskType == 'LosEsign')">
                </p-tableCheckbox>

                <!-- last condition is taken from checkbox -->
                <span class="me-1 text-muted"
                    *ngIf="row.taskType !== 'GatherPayment' && row.taskType !== 'RequestAppraisal'
                    && row.taskType !== 'QuickClientUpdateReferralLead'
                    && row.taskType !== 'QuickClientUpdatePreApproved'
                    && row.taskType !== 'QuickClientUpdateInProcess'
                        && !((row.taskType == 'EsignDocument' || row.taskType == 'LosEsign') && (filterId == 1 || filterId == 2))
                        && (canDelete || allowBulkActions) && !(row.taskType == 'EsignDocument' || row.taskType == 'LosEsign')">
                    |
                </span>

                <a (click)="editTaskModal(row)"
                    *ngIf="row.taskType !== 'GatherPayment' && row.taskType !== 'RequestAppraisal'
                    && row.taskType !== 'QuickClientUpdateReferralLead'
                    && row.taskType !== 'QuickClientUpdatePreApproved'
                    && row.taskType !== 'QuickClientUpdateInProcess' &&
                            !((row.taskType == 'EsignDocument' || row.taskType == 'LosEsign') && (filterId == 1 || filterId == 2))">
                    <i class="fas fa-pencil-alt" style="color:#1f9ad6 !important"></i>
                </a>

                <span class="text-muted mx-1"
                    *ngIf="row.taskType !== 'GatherPayment'
                        && row.taskType !== 'RequestAppraisal'
                        && row.taskType !== 'QuickClientUpdateReferralLead'
                        && row.taskType !== 'QuickClientUpdatePreApproved'
                        && row.taskType !== 'QuickClientUpdateInProcess'
                        && canDelete
                        && !row.restrictDelete
                        && ((row.taskType == 'EsignDocument' || row.taskType == 'LosEsign') && row.taskStatus === 'Cancelled')">
                    |
                </span>

                <span>
                    <a (click)="activeDeletionId = row.loanDocTaskId;" *ngIf="row.taskType !== 'GatherPayment'
                            && row.taskType !== 'RequestAppraisal'
                            && row.taskType !== 'QuickClientUpdateReferralLead'
                            && row.taskType !== 'QuickClientUpdatePreApproved'
                            && row.taskType !== 'QuickClientUpdateInProcess'
                            && canDelete
                            && !row.restrictDelete
                            && ((row.taskType == 'EsignDocument' || row.taskType == 'LosEsign') && row.taskStatus === 'Cancelled')
                            && (!activeDeletionId || activeDeletionId != row.loanDocTaskId)">
                        <i class="fas fa-trash-alt" style="color:#1f9ad6 !important"></i>
                    </a>
                    <a *ngIf="activeDeletionId == row.loanDocTaskId" (click)="activeDeletionId = -1;" class="mx-1">
                        <i class="fa fa-times danger"></i>
                    </a>
                    <a *ngIf="activeDeletionId == row.loanDocTaskId" (click)="confirmDeleteTask(row)" class="mx-1">
                        <i class="fa fa-check-circle danger"></i>
                    </a>
                </span>

                <a (click)="editTaskModal(row, 'eSign')" *ngIf="(row.taskType == 'EsignDocument' || row.taskType == 'LosEsign') && row.taskStatus != 'Completed' && row.userId == userId &&
                                row.borrowerId == null">
                    eSign
                </a>

                <a (click)="onResendEsignNotificationClicked(row)" *ngIf="(row.taskType == 'EsignDocument' || row.taskType == 'LosEsign') && row.taskStatus == 'Pending' && row.borrowerId">
                    <span> | </span>Send Reminder
                </a>

                <span class="text-muted mx-1" *ngIf="11 > filterId
                        && row.taskType != 'PerformAction'
                        && row.taskType !== 'QuickClientUpdateReferralLead'
                        && row.taskType !== 'QuickClientUpdatePreApproved'
                        && row.taskType !== 'QuickClientUpdateInProcess'
                        && !(row.taskType == 'EsignDocument' || row.taskType == 'LosEsign')
                        && row.taskType !== 'RequestAppraisal'">
                    |
                </span>

                <ng-container *ngIf="filterId <= 10 && row.taskType != 'PerformAction' && !(row.taskType == 'EsignDocument' || row.taskType == 'LosEsign')
                    && row.taskType !== 'RequestAppraisal'
                    && row.taskType !== 'QuickClientUpdateReferralLead'
                    && row.taskType !== 'QuickClientUpdatePreApproved'
                    && row.taskType !== 'QuickClientUpdateInProcess'">
                    <a (click)="editTaskModal(row, 'docGen')" *ngIf="row.taskType == 'GenerateDocument'
                            || row.taskType === 'RequestHoi'
                            || (row.taskType === 'GatherPayment' && filterId != 10 && row.taskStatus !== 'Approved')">
                        {{row.taskStatus === 'Completed' ? 'Update' : 'Start'}}
                    </a>
                    <a (click)="editTaskModal(row, 'upload')" *ngIf="row.taskType != 'OnlineApplication'
                            && row.taskType != 'DigitalAssetVerification'
                            && row.taskType != 'GenerateDocument'
                            && row.taskType != 'RequestInformation'
                            && row.taskType !== 'RequestHoi'
                            && row.taskType !== 'Checklist'
                            && row.taskType !== 'DocumentTypeReview'
                            && (row.taskType !== 'GatherPayment' || filterId == 10 || row.taskStatus === 'Approved')">
                        Upload
                    </a>
                </ng-container>


                <span class="text-muted mx-1" *ngIf="row.applicationId
                        && ((row.taskType === 'RequestTitle' && !['Completed', 'Approved'].includes(row.taskStatus)) || row.taskType === 'RequestAppraisal')
                        && row.taskType !== 'GatherPayment'">
                    |
                </span>

                <a (click)="editTaskModal(row)" *ngIf="row.applicationId
                        && ((row.taskType === 'RequestTitle' && !['Completed', 'Approved'].includes(row.taskStatus))
                        || row.taskType === 'RequestAppraisal'
                        || row.taskType === 'QuickClientUpdateReferralLead'
                        || row.taskType === 'QuickClientUpdatePreApproved'
                        || row.taskType === 'QuickClientUpdateInProcess'
                        || row.taskType === 'Checklist'
                        || row.taskType === 'DocumentTypeReview')">
                    Start
                </a>

                <span *ngIf="row.loanDocId > 0 && row.docFiles?.length && row.documentTypeId > -1 && !row.losLoanDocId"
                    class="text-muted mx-1">|</span>
                <li class="dropdown" style="list-style-type: none; display: inline"
                    *ngIf="row.loanDocId > 0 && row.docFiles?.length && row.documentTypeId > -1 && !row.losLoanDocId">
                    <a class="dropdown-toggle" data-bs-toggle="dropdown">
                        <i class="fas fa-caret-down"></i>
                    </a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" (click)="syncLoanDocWithLos(row.loanDocId, row)">Sync with LOS</a>
                    </div>
                </li>

                <span *ngIf="isMergeVisible(row)" class="text-muted mx-1">|</span>
                <a *ngIf="isMergeVisible(row)" class="badge text-info fw-bold hoverBadge" href="javascript:void(0);"
                    (click)="onMergeFilesClicked(row)"> MERGE
                </a>
            </td>
            <!-- Actions End -->
            <td *ngIf="(!filterId && row.leadId) || ((filterId > 10) && (16 > filterId))">
                <!-- Lead / Borrower -->
                <span>
                    <strong>Lead: </strong>
                    <a style='white-space: nowrap' href="javascript: void(0);" *ngIf="showLeadAndBorrowerNamesAsLinks"
                        (click)="onLeadClicked(row.leadId)">Lead
                        #{{row.leadId}} {{row.applicationName}}</a>
                    <span *ngIf="!showLeadAndBorrowerNamesAsLinks">
                        #{{row.leadId}} {{row.applicationName}}
                    </span>
                </span>
                <br /><br />
                <span>
                    <strong>{{row.fullName !== ' ' ? "Borr: " : ""}}</strong>
                    <a href="javascript: void(0);" (click)="onLeadClicked(row.leadId)"
                        *ngIf="showLeadAndBorrowerNamesAsLinks">{{row.fullName}}</a>
                    <span *ngIf="!showLeadAndBorrowerNamesAsLinks">
                        {{row.fullName}}
                    </span>
                </span>
            </td>
            <td *ngIf="(!filterId && row.applicationId) || ((11 > filterId) || (filterId > 15))">
                <!-- App# Borrower -->
                <span *ngIf="!appId">
                    <a class="text-cell-primary" style='white-space: nowrap'
                        [routerLink]="['/' + applicationMode + '/app-details/' + row.applicationId ]">
                        {{row.applicationName}}</a>
                    <br />
                    <a style="white-space: nowrap"
                        [routerLink]="['/' + applicationMode + '/app-details/' + row.applicationId ]">1{{
                        row.applicationId | padLeft:'0': 9 }} </a>
                </span>
                <span *ngIf="appId">
                    <a href="javascript: void(0);" (click)="onBorrowerClicked(row.borrowerId, row.applicationId)">
                        {{row.fullName || '--'}}
                    </a>
                </span>
                <br />
                <span class="text-muted" *ngIf="(filterId != 1) && (filterId != 2)">
                    <!-- Responsible Party -->
                    <span *ngIf="row.taskStatus === 'ReviewReady'">
                        {{row.reviewParty}}
                    </span>
                    <span *ngIf="row.taskStatus !== 'ReviewReady'">
                        {{row.responsibleParty}}
                    </span>
                </span>
            </td>
            <td *ngIf="(11 > filterId) || (filterId > 15)">
                <!-- Doc Type -->
                <div style='overflow:auto'>
                    {{row.docTypeText}}
                </div>
            </td>
            <td>
                <!-- Description -->
                <div style='overflow:auto;word-break:break-word;'>
                    {{row.description}}
                </div>
                <div *ngIf="row.conditionText" style='overflow:auto;word-break:break-word;'>
                    <br>
                    <span class="text-muted" style='font-style: italic'>
                        Condition Text: {{row.conditionText}}
                    </span>
                </div>
            </td>
            <td style="min-width: 110px;">
                <!-- Note -->
                <task-note-display (hover)="onTaskNoteHovered(row)" [task]="row"></task-note-display>
            </td>
            <td>
                <!-- Due Date -->
                <div class="d-flex">
                    <span class="text-cell-primary">
                        <span *ngIf="isSnoozed(row); else dueDateContent">
                            <span class="text-danger">Snoozed until</span>
                            <br />
                            {{ row.snoozeDate | date: 'short':'UTC' }}
                        </span>
                        <ng-template #dueDateContent>
                            {{ row.dueDate | date: 'short':'UTC'}}
                        </ng-template>

                        <i *ngIf="isOverdue(row)" class="fas fa-exclamation-triangle label-danger"></i>
                    </span>
                </div>
                <div><span class="text-muted">{{row.requestDate | date: 'short'}}</span></div>
            </td>
            <td *ngIf="(11 > filterId) || (filterId > 15) || showViewFiles" style="min-width:150px;max-width: 400px;">
                <!-- View File -->
                <div *ngIf="row.docFiles && row.docFiles.length > 5 && !row.showAllDocs" class="dropzone">
                    <div>
                        There are {{row.docFiles.length}} files. <span (click)="row.showAllDocs = true;"
                            class="link text-info">Show All</span>
                    </div>
                </div>
                <div *ngIf="row.docFiles && ((row.docFiles.length > 0 && row.docFiles.length <= 5) || row.showAllDocs)"
                    style="max-height: 300px; overflow-y: scroll; overflow-x: hidden;" class="dropzone">
                    <div *ngFor="let file of docFilesFilter(row); let index = index" [ngClass]="{'b-top': index != 0}">
                        <view-file [file]="file" (viewDocFile)="viewDocClicked($event, row, file)"
                            (fileDeleted)="onFileDeleted($event, row)" [appId]="row.applicationId"
                            [globalConfig]="globalConfig" (delete)="deleteFile($event, row)"
                            (fileCheckStatusChanged)="onFileCheckStatusChanged($event, file)">
                        </view-file>
                    </div>
                </div>
                <div class="dragover p-2">
                    <span>DROP FILES HERE</span>
                </div>
            </td>
            <td *ngIf="(11 > filterId) || (filterId > 15)">
                <!-- Flags -->
                <span *ngIf="row.taskPriority === 'Low'" class="label label-info"><i
                        class="fa fa-arrow-down padding-5"></i>
                    4- Low</span>
                <span *ngIf="row.taskPriority === 'Normal'" class="label label-info"> 3- Normal</span>
                <span *ngIf="row.taskPriority === 'High'" class="label label-warning"><i
                        class="fa fa-arrow-up padding-5"></i>
                    2- High</span>
                <span *ngIf="row.taskPriority === 'Immediate'" class="label label-danger"><i
                        class="fa fa-flag padding-5"></i>
                    1- Immediate</span>
                <br *ngIf="row.condition === true || row.condition === 'true'" />
                <span *ngIf="row.condition === true && row.printInternally !== true && row.printExternally !== true" class="badge badge-outline-dark">
                    Condition</span>
                <span *ngIf="row.condition === true && row.printInternally === true" class="badge badge-outline-dark">
                    Internal Condition</span>
                <span *ngIf="row.condition === true && row.printExternally === true" class="badge badge-outline-dark">
                    External Condition</span>
                <br *ngIf="row.requiresReview === true || row.requiresReview === 'true'" />
                <span *ngIf="row.requiresReview === true || row.requiresReview === 'true'" class="label label-warning">
                    Review Required</span>
                <span *ngIf="row.taskType === 'DigitalAssetVerification'" class="badge badge-outline-dark"> VOA </span>
                <br />
                <span *ngIf="row.existingLenderId && row.taskType === 'RequestPayoff'" class="label label-info">
                    Payoff - </span>
                <span *ngIf="row.hoiAgentId && row.taskType === 'RequestHoi'" class="label label-info"> HOI -
                </span>
                <span *ngIf="row.titleAgentId && row.taskType === 'RequestTitle'" class="label label-info">
                    Title - </span>
                <span *ngIf="row.escrowAgentId && row.taskType === 'RequestEscrow'" class="label label-info">
                    Escrow - </span>
            </td>
            <td>
                <!-- Status -->
                <div *ngIf="row.requestBorrower">
                    <!-- for borrower -->

                    <div *ngIf="!(row.taskType == 'EsignDocument' || row.taskType == 'LosEsign')">
                        <button *ngIf="row.taskStatus === 'Approved'"
                            class="btn btn-success btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                            data-offset="-90">
                            Approved<i class="mdi mdi-chevron-down"></i>
                        </button>
                        <button *ngIf="row.taskStatus === 'NotApplicable'"
                            class="btn btn-info btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                            data-offset="-90">
                            Not Applicable<i class="mdi mdi-chevron-down"></i>
                        </button>
                        <button *ngIf="row.taskStatus === 'Submitted'"
                            class="btn btn-info btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                            data-offset="-90">
                            Submitted<i class="mdi mdi-chevron-down"></i>
                        </button>
                        <button *ngIf="row.taskStatus === 'Pending'"
                            class="btn btn-info btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                            data-offset="-90">
                            Pending<i class="mdi mdi-chevron-down"></i>
                        </button>
                        <button *ngIf="row.taskStatus === 'Rejected'"
                            class="btn btn-danger btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                            data-offset="-90">
                            Rejected<i class="mdi mdi-chevron-down"></i>
                        </button>
                        <button *ngIf="row.taskStatus === 'ReviewReady'"
                            class="btn btn-warning btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                            data-offset="-90">
                            Review Ready<i class="mdi mdi-chevron-down"></i>
                        </button>
                        <button *ngIf="row.taskStatus === 'TpoSubmitted'"
                            class="btn btn-info btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                            data-offset="-90">
                            Tpo Submitted<i class="mdi mdi-chevron-down"></i>
                        </button>

                        <div class="dropdown-menu fixed-pos-ddl-menu">
                            <div class="list-group" *ngIf="row.taskStatus === 'TpoSubmitted'">
                                <a class="list-group-item list-group-item-action"
                                    (click)="setTaskStatus('Rejected', row)">Rejected</a>
                                <a class="list-group-item list-group-item-action" *ngIf="row.requiresReview"
                                    (click)="setTaskStatus('ReviewReady', row)">Review Ready</a>
                                <a class="list-group-item list-group-item-action" *ngIf="!row.requiresReview"
                                    (click)="setTaskStatus('Approved', row)">Approved</a>
                            </div>
                            <div class="list-group" *ngIf="row.taskStatus !== 'TpoSubmitted'">
                                <a *ngIf="row.taskStatus !== 'Pending' && row.taskStatus !== 'Rejected' && row.taskStatus !== 'ReviewReady' && row.taskStatus !== 'Submitted'"
                                    class="list-group-item list-group-item-action"
                                    (click)="setTaskStatus('Pending', row)">Pending</a>
                                <a *ngIf="row.taskStatus !== 'Submitted' && row.taskStatus !== 'NotApplicable' && row.taskStatus !== 'ReviewReady'"
                                    class="list-group-item list-group-item-action"
                                    (click)="setTaskStatus('Submitted', row)">Submitted</a>
                                <a *ngIf="row.taskStatus !== 'Rejected' && row.taskStatus !== 'Pending' && row.taskStatus !== 'NotApplicable'"
                                    class="list-group-item list-group-item-action"
                                    (click)="setTaskStatus('Rejected', row)">Rejected</a>
                                <a *ngIf="row.taskStatus !== 'Approved' && row.taskStatus !== 'NotApplicable' && !(row.requiresReview && (row.taskStatus === 'Submitted' || row.taskStatus === 'Rejected' || row.taskStatus === 'Pending'))"
                                    class="list-group-item list-group-item-action"
                                    (click)="setTaskStatus('Approved', row)">Approved</a>
                                <a *ngIf="row.taskStatus !== 'NotApplicable' && row.taskStatus !== 'Approved' && !row.requiresReview"
                                    class="list-group-item list-group-item-action"
                                    (click)="setTaskStatus('NotApplicable', row)">Not
                                    Applicable</a>
                                <a *ngIf="row.requiresReview && row.taskStatus !== 'ReviewReady' && (row.taskStatus !== 'Submitted' || row.taskStatus !== 'Approved')"
                                    class="list-group-item list-group-item-action"
                                    (click)="setTaskStatus('ReviewReady', row)">Review
                                    Ready</a>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="row.taskType == 'EsignDocument' || row.taskType == 'LosEsign'">
                        <button *ngIf="row.taskStatus === 'Approved'" data-bs-toggle="dropdown" data-offset="-90"
                            class="btn btn-success btn-xs dropdown-toggle fixed-pos-ddl-btn">
                            Approved
                        </button>
                        <button *ngIf="row.taskStatus === 'NotApplicable'" data-bs-toggle="dropdown" data-offset="-90"
                            class="btn btn-info btn-xs dropdown-toggle fixed-pos-ddl-btn">
                            Not Applicable
                        </button>
                        <button *ngIf="row.taskStatus === 'Submitted'" data-bs-toggle="dropdown" data-offset="-90"
                            class="btn btn-info btn-xs dropdown-toggle fixed-pos-ddl-btn">
                            Submitted
                        </button>
                        <button *ngIf="row.taskStatus === 'Pending'" data-bs-toggle="dropdown" data-offset="-90"
                            class="btn btn-info btn-xs dropdown-toggle fixed-pos-ddl-btn">
                            Pending
                        </button>
                        <button *ngIf="row.taskStatus === 'Rejected'" data-bs-toggle="dropdown" data-offset="-90"
                            class="btn btn-danger btn-xs dropdown-toggle fixed-pos-ddl-btn">
                            Rejected
                        </button>
                        <button *ngIf="row.taskStatus === 'ReviewReady'" data-bs-toggle="dropdown"
                            class="btn btn-warning btn-xs dropdown-toggle fixed-pos-ddl-btn" data-offset="-90">
                            Review Ready<i class="mdi mdi-chevron-down"></i>
                        </button>
                        <button *ngIf="row.taskStatus === 'TpoSubmitted'" data-bs-toggle="dropdown" data-offset="-90"
                            class="btn btn-info btn-xs dropdown-toggle fixed-pos-ddl-btn">
                            Tpo Submitted
                        </button>
                        <div class="dropdown-menu fixed-pos-ddl-menu" *ngIf="row.taskStatus === 'ReviewReady'">
                            <div class="list-group">
                                <a class="list-group-item list-group-item-action"
                                    (click)="setTaskStatus('Approved', row)">Approved</a>
                            </div>
                        </div>
                    </div>
                    <span class="pl-0 text-muted"
                        *ngIf="row.taskStatus === 'Approved' && !(row.taskType == 'EsignDocument' || row.taskType == 'LosEsign')">
                        On {{row.completeDate | date:'short'}}
                    </span>
                    <span class="pl-0 text-muted"
                        *ngIf="row.taskStatus === 'NotApplicable' && !(row.taskType == 'EsignDocument' || row.taskType == 'LosEsign')">
                        On {{row.completeDate | date:'short'}}
                    </span>
                    <span class="pl-0 text-muted"
                        *ngIf="row.taskStatus === 'Submitted' && !(row.taskType == 'EsignDocument' || row.taskType == 'LosEsign')">
                        On {{row.submittedDate | date:'short'}}
                    </span>
                </div>
                <div *ngIf="!row.requestBorrower">
                    <!-- for user -->

                    <div *ngIf="!(row.taskType == 'EsignDocument' || row.taskType == 'LosEsign')">
                        <button *ngIf="row.taskStatus === 'Approved'"
                            class="btn btn-success btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                            data-offset="-90">
                            Approved<i class="mdi mdi-chevron-down"></i>
                        </button>
                        <button *ngIf="row.taskStatus === 'NotApplicable'"
                            class="btn btn-info btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                            data-offset="-90">
                            Not Applicable<i class="mdi mdi-chevron-down"></i>
                        </button>
                        <button *ngIf="row.taskStatus === 'Completed'"
                            class="btn btn-success btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                            data-offset="-90">
                            Completed<i class="mdi mdi-chevron-down"></i>
                        </button>
                        <button *ngIf="row.taskStatus === 'Pending'"
                            class="btn btn-danger btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                            data-offset="-90">
                            Pending<i class="mdi mdi-chevron-down"></i>
                        </button>
                        <button *ngIf="row.taskStatus === 'Requested'"
                            class="btn btn-info btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                            data-offset="-90">
                            Requested<i class="mdi mdi-chevron-down"></i>
                        </button>
                        <button *ngIf="row.taskStatus === 'Rejected'"
                            class="btn btn-danger btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                            data-offset="-90">
                            Rejected<i class="mdi mdi-chevron-down"></i>
                        </button>
                        <button *ngIf="row.taskStatus === 'ReviewReady'"
                            class="btn btn-warning btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                            data-offset="-90">
                            Review Ready<i class="mdi mdi-chevron-down"></i>
                        </button>
                        <button *ngIf="row.taskStatus === 'ConditionImportPending'"
                            class="btn btn-info btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                            data-offset="-90">
                            Import Pending<i class="mdi mdi-chevron-down"></i>
                        </button>
                        <button *ngIf="row.taskStatus === 'EscalationPending'"
                            class="btn btn-warning btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                            data-offset="-90">
                            Escalation Pending<i class="mdi mdi-chevron-down"></i>
                        </button>
                        <button *ngIf="row.taskStatus === 'ChangeOfCircumstancePending'"
                            class="btn btn-info btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                            data-offset="-90">
                            COC Pending<i class="mdi mdi-chevron-down"></i>
                        </button>
                        <button *ngIf="row.taskStatus === 'ChangeOfCircumstanceRejected'"
                            class="btn btn-warning btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                            data-offset="-90">
                            COC Rejected<i class="mdi mdi-chevron-down"></i>
                        </button>
                        <button *ngIf="row.taskStatus === 'ChangeOfCircumstanceApproved'"
                            class="btn btn-success btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                            data-offset="-90">
                            COC Approved<i class="mdi mdi-chevron-down"></i>
                        </button>
                        <button *ngIf="row.taskStatus === 'TpoSubmitted'"
                            class="btn btn-info btn-xs dropdown-toggle fixed-pos-ddl-btn" data-bs-toggle="dropdown"
                            data-offset="-90">
                            Tpo Submitted<i class="mdi mdi-chevron-down"></i>
                        </button>

                        <div class="dropdown-menu fixed-pos-ddl-menu">
                            <div class="list-group" *ngIf="row.taskStatus === 'TpoSubmitted'">
                                <a class="list-group-item list-group-item-action"
                                    (click)="setTaskStatus('Rejected', row)">Rejected</a>
                                <a class="list-group-item list-group-item-action" *ngIf="row.requiresReview"
                                    (click)="setTaskStatus('ReviewReady', row)">Review Ready</a>
                                <a class="list-group-item list-group-item-action" *ngIf="!row.requiresReview"
                                    (click)="setTaskStatus('Completed', row)">Completed</a>
                            </div>
                            <div class="list-group" *ngIf="row.taskStatus === 'ChangeOfCircumstancePending'">
                                <a class="list-group-item list-group-item-action"
                                    (click)="setTaskStatus('ChangeOfCircumstanceApproved', row)">COC Approved</a>
                                <a class="list-group-item list-group-item-action"
                                    (click)="setTaskStatus('ChangeOfCircumstanceRejected', row)">COC Rejected</a>
                            </div>
                            <div class="list-group" *ngIf="row.taskStatus === 'ChangeOfCircumstanceRejected'">
                                <a class="list-group-item list-group-item-action"
                                    (click)="setTaskStatus('ChangeOfCircumstancePending', row)">COC Pending</a>
                            </div>
                            <div class="list-group" *ngIf="row.taskStatus === 'ChangeOfCircumstanceApproved'">
                                <a class="list-group-item list-group-item-action"
                                    (click)="setTaskStatus('ChangeOfCircumstancePending', row)">COC Pending</a>
                            </div>
                            <div class="list-group" *ngIf="row.taskStatus === 'EscalationPending'">
                                <a class="list-group-item list-group-item-action"
                                    (click)="setTaskStatus('Completed', row)">Completed</a>
                            </div>
                            <div class="list-group" *ngIf="row.taskStatus !== 'TpoSubmitted' && row.taskStatus !== 'ChangeOfCircumstancePending' &&
                                    row.taskStatus !== 'ChangeOfCircumstanceRejected' && row.taskStatus !== 'ChangeOfCircumstanceApproved' &&
                                    row.taskStatus !== 'EscalationPending'">
                                <a class="list-group-item list-group-item-action"
                                    *ngIf="row.taskStatus !== 'Pending' && row.taskStatus !== 'Rejected' && row.taskStatus !== 'ReviewReady'"
                                    (click)="setTaskStatus('Pending', row)">Pending</a>
                                <a class="list-group-item list-group-item-action"
                                    *ngIf="row.taskStatus !== 'Requested' && row.taskStatus !== 'ReviewReady' && row.taskStatus !== 'Rejected' &&
                                            row.taskStatus !== 'Completed' && row.taskStatus != 'Approved' && row.taskStatus != 'ConditionImportPending'"
                                    (click)="setTaskStatus('Requested', row)">Requested</a>
                                <a class="list-group-item list-group-item-action"
                                    *ngIf="row.taskStatus === 'ReviewReady' || row.taskStatus === 'Approved'"
                                    (click)="setTaskStatus('Rejected', row)">Rejected</a>
                                <a class="list-group-item list-group-item-action"
                                    *ngIf="row.taskStatus === 'ReviewReady'"
                                    (click)="setTaskStatus('Approved', row)">Approved</a>
                                <a class="list-group-item list-group-item-action"
                                    *ngIf="row.taskStatus !== 'Completed' && row.taskStatus !== 'NotApplicable' && !row.requiresReview"
                                    (click)="setTaskStatus('Completed', row)">Completed</a>
                                <a class="list-group-item list-group-item-action"
                                    *ngIf="row.taskStatus !== 'NotApplicable' && row.taskStatus !== 'Approved' && !row.requiresReview"
                                    (click)="setTaskStatus('NotApplicable', row)">Not Applicable</a>
                                <a class="list-group-item list-group-item-action"
                                    *ngIf="row.requiresReview && (row.taskStatus !== 'Submitted' || row.taskStatus !== 'Approved')"
                                    (click)="setTaskStatus('ReviewReady', row)">Review Ready</a>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="row.taskType == 'EsignDocument' || row.taskType == 'LosEsign'">
                        <button *ngIf="row.taskStatus === 'Approved'" data-bs-toggle="dropdown" data-offset="-90"
                            class="btn btn-success btn-xs dropdown-toggle fixed-pos-ddl-btn">
                            Approved
                        </button>
                        <button *ngIf="row.taskStatus === 'NotApplicable'" data-bs-toggle="dropdown" data-offset="-90"
                            class="btn btn-success btn-xs dropdown-toggle fixed-pos-ddl-btn">
                            Not Applicable
                        </button>
                        <button *ngIf="row.taskStatus === 'Completed'" data-bs-toggle="dropdown" data-offset="-90"
                            class="btn btn-success btn-xs dropdown-toggle fixed-pos-ddl-btn">
                            Completed
                        </button>
                        <button *ngIf="row.taskStatus === 'Pending'" data-bs-toggle="dropdown" data-offset="-90"
                            class="btn btn-danger btn-xs dropdown-toggle fixed-pos-ddl-btn">
                            Pending
                        </button>
                        <button *ngIf="row.taskStatus === 'Requested'" data-bs-toggle="dropdown" data-offset="-90"
                            class="btn btn-info btn-xs dropdown-toggle fixed-pos-ddl-btn">
                            Requested
                        </button>
                        <button *ngIf="row.taskStatus === 'Rejected'" data-bs-toggle="dropdown" data-offset="-90"
                            class="btn btn-danger btn-xs dropdown-toggle fixed-pos-ddl-btn">
                            Rejected
                        </button>
                        <button *ngIf="row.taskStatus === 'ReviewReady'" data-bs-toggle="dropdown" data-offset="-90"
                            class="btn btn-warning btn-xs dropdown-toggle fixed-pos-ddl-btn">
                            Review Ready<i class="mdi mdi-chevron-down"></i>
                        </button>
                        <button *ngIf="row.taskStatus === 'ConditionImportPending'" data-bs-toggle="dropdown"
                            data-offset="-90" class="btn btn-info btn-xs dropdown-toggle fixed-pos-ddl-btn">
                            Import Pending
                        </button>
                        <button *ngIf="row.taskStatus === 'EscalationPending'" data-bs-toggle="dropdown"
                            data-offset="-90" class="btn btn-warning btn-xs dropdown-toggle fixed-pos-ddl-btn">
                            Escalation Pending
                        </button>
                        <button *ngIf="row.taskStatus === 'ChangeOfCircumstancePending'" data-bs-toggle="dropdown"
                            data-offset="-90" class="btn btn-info btn-xs dropdown-toggle fixed-pos-ddl-btn">
                            COC Pending
                        </button>
                        <button *ngIf="row.taskStatus === 'ChangeOfCircumstanceRejected'" data-bs-toggle="dropdown"
                            data-offset="-90" class="btn btn-warning btn-xs dropdown-toggle fixed-pos-ddl-btn">
                            COC Rejected
                        </button>
                        <button *ngIf="row.taskStatus === 'ChangeOfCircumstanceApproved'" data-bs-toggle="dropdown"
                            data-offset="-90" class="btn btn-success btn-xs dropdown-toggle fixed-pos-ddl-btn">
                            COC Approved
                        </button>
                        <button *ngIf="row.taskStatus === 'TpoSubmitted'" data-bs-toggle="dropdown" data-offset="-90"
                            class="btn btn-info btn-xs dropdown-toggle fixed-pos-ddl-btn">
                            Tpo Submitted
                        </button>
                        <div class="dropdown-menu fixed-pos-ddl-menu" *ngIf="row.taskStatus === 'ReviewReady'">
                            <div class="list-group">
                                <a class="list-group-item list-group-item-action"
                                    (click)="setTaskStatus('Approved', row)">Approved</a>
                            </div>
                        </div>
                    </div>
                    <span class="pl-0 text-muted"
                        *ngIf="row.taskStatus === 'Approved' && !(row.taskType == 'EsignDocument' || row.taskType == 'LosEsign')">
                        On {{row.completeDate | date:'short'}}
                    </span>
                    <span class="pl-0 text-muted"
                        *ngIf="row.taskStatus === 'NotApplicable' && !(row.taskType == 'EsignDocument' || row.taskType == 'LosEsign')">
                        On {{row.completeDate | date:'short'}}
                    </span>
                    <span class="pl-0 text-muted"
                        *ngIf="row.taskStatus === 'Completed' && !(row.taskType == 'EsignDocument' || row.taskType == 'LosEsign')">
                        On {{row.completeDate | date:'short'}}
                    </span>
                </div>
                <div *ngIf="!row.requestBorrower && row.taskStatus === 'Requested'" class="p-ml-auto ms-1">
                    <form class="form-inline">
                        <div class="button-items btn-group-left" style="position: relative">
                            <button class="btn btn-secondary btn-sm d-flex align-items-center" type="button"
                                data-bs-container="body" data-bs-toggle="popover" placement="bottom"
                                [popover]="templateFollowUp" data-trigger="click" [outsideClick]="true"
                                [adaptivePosition]="false" (click)="followUp = today;">
                                Follow-Up <i class="mdi mdi-chevron-down ms-1"></i>
                            </button>
                            <ng-template #templateFollowUp>
                                <ngb-datepicker #dpFollowUp id="dpFollowUp_{{row.loanDocTaskId}}"
                                    name="dpFollowUp_{{row.loanDocTaskId}}" [(ngModel)]="followUp"
                                    (click)="$event.stopPropagation()">
                                </ngb-datepicker>
                                <hr class="my-0">
                                <button class="btn btn-secondary m-2 w-webkit-fill-available"
                                    (click)="updateFollowUp(row)">Select
                                    Date</button>
                            </ng-template>
                        </div>
                    </form>
                </div>
                <div *ngIf="!row.requestBorrower && (row.taskStatus === 'Pending' || row.taskStatus === 'ReviewReady' || row.taskStatus === 'Rejected')"
                    class="p-ml-auto mt-1">
                    <form class="form-inline d-flex">
                        <div class="button-items btn-group-left">
                            <div style="position: relative">
                                <a #postponeBtn="bs-popover" class="d-flex align-items-center" type="button"
                                    data-bs-container="body" data-bs-toggle="popover" placement="bottom"
                                    [popover]="templatePostpone" data-trigger="click" [outsideClick]="true"
                                    [adaptivePosition]="true" (click)="followUp = today;">
                                    Postpone <i class="mdi mdi-chevron-down ms-1"></i>
                                </a>
                            </div>
                            <ng-template #templatePostpone>
                                <ngb-datepicker #dpPostpone id="dpPostpone_{{row.loanDocTaskId}}"
                                    name="dpPostpone_{{row.loanDocTaskId}}" [(ngModel)]="followUp" firstDayOfWeek="7"
                                    (click)="$event.stopPropagation()"></ngb-datepicker>
                                <hr class="my-0">
                                <a class="btn btn-secondary m-2 w-webkit-fill-available"
                                    (click)="updateFollowUp(row)">Select
                                    Date</a>
                            </ng-template>
                        </div>

                        <div class="button-items btn-group-left ms-1">
                            <div style="position: relative">
                                <a class="d-flex align-items-center" type="button" #snoozeButton="bs-popover"
                                    data-bs-container="body" data-bs-toggle="popover" placement="start"
                                    [popover]="templateSnooze" data-trigger="click" [outsideClick]="true"
                                    [adaptivePosition]="true">
                                    Snooze <i class="far fa-clock ms-1"></i>
                                </a>
                            </div>
                            <ng-template #templateSnooze>
                                <div class="minutePicker">
                                    <div class="w-100 text-end">
                                        <button type="button" class="close" (click)="snoozeButton.hide()"> <span
                                                aria-hidden="true">x</span></button>
                                    </div>
                                    <div class="minute_picker_innser">
                                        <h3>Snooze Duration</h3>
                                        <div class="minut_select_wrap">
                                            <select class="form-select" [(ngModel)]="snoozeDurationInMinutes"
                                                name="snoozeDropdown">
                                                <option [ngValue]="15">15 mins</option>
                                                <option [ngValue]="30">30 mins</option>
                                                <option [ngValue]="45">45 mins</option>
                                                <option [ngValue]="60">1 hour</option>
                                                <option [ngValue]="120">2 hours</option>
                                                <option [ngValue]="180">3 hours</option>
                                                <option [ngValue]="240">4 hours</option>
                                            </select>
                                        </div>
                                        <div class="w-100 text-center my-2">
                                            <button class="btn btn-primary" (click)="updateSnooze(row)">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </form>
                </div>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td [attr.colspan]="filterId == 11 || filterId == 12 ? '8' : '11'" class="text-center"> There are no
                tasks </td>
        </tr>
    </ng-template>
</p-table>

<drawer #editLeadOrBorrowerEditorDrawerInTasks [title]="'Editing Lead'"
    [name]="'editLeadOrBorrowerEditorDrawerInTasksV2'" [options]="editLeadDrawerOptions"
    (closed)="onClosedLeadOrBorrowerEditorDrawer()">
</drawer>

<drawer #qcuTaskEditorDrawer [title]="'Editing Task'" [name]="'qcuTaskEditorDrawer'"
    [options]="editQcuTaskDrawerOptions" (closed)="onClosedQcuTaskEditorDrawer()">
</drawer>

<drawer #taskTableDynamicDrawer [title]="'Details'" [hasShrinkExpandButton]="false" [name]="'taskTableDynamicDrawer'"
    [options]="taskTableDynamicDrawerOptions" (closed)="onClosedTaskTableDynamicDrawerDrawer()">
</drawer>
