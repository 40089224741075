import { Component, Injector, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { KeyDatesService } from 'src/app/services/key-dates.service';
import { KeyDateHistoryItemViewModel } from '../../models/key-date-history-item-view.model';
import { KeyDateHistoryItem } from '../../models/key-date-history-item.model';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';

@Component({
  selector: 'key-dates-history-dialog',
  templateUrl: 'key-dates-history-dialog.component.html',
  styleUrls: ['key-dates-history-dialog.component.scss']
})

export class KeyDatesHistoryDialogComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  appId: number;

  @Input()
  keyDateId: number;

  keyDatesHistory: KeyDateHistoryItemViewModel[];

  columns: any[];

  dateFormat = "MMM, dd YYYY h:mm a";
  dateFormatShort = "MM/dd/YYYY";

  constructor(
    private readonly injector: Injector,
    public activeModal: NgbActiveModal,
    private readonly _keyDatesService: KeyDatesService,
    private readonly _spinner: NgxSpinnerService) {
    super(injector);
  }

  ngOnInit(): void {
    this.loadKeyDatesHistory();
    this.columns = [
      { field: 'eventDate', header: 'Event Date' },
      { field: 'insertedBy', header: 'Inserted By' },
      { field: 'dateInserted', header: 'Last Updated' },
    ];
  }

  onCancelClicked = () => {
    this.activeModal.close('cancel');
  }

  loadKeyDatesHistory = () => {
    this._spinner.show();
    this._keyDatesService.getKeyDatesHistory(this.appId, this.keyDateId).subscribe((result: KeyDateHistoryItem[]) => {
      if (result) {
        this.keyDatesHistory = result.map(i => {
          const userDisplayName = this.applicationContext.globalConfig.getUserDisplayName(i.insertedBy);
          return new KeyDateHistoryItemViewModel(i.eventDate,
            userDisplayName, i.dateInserted, i.eventDateUtc);
        });
        this._spinner.hide();
      }
    }, error => {
      this._spinner.hide();
    })
  }
}
