import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { formViewProvider } from 'src/app/core/services/form-view.provider';
import { ApplicationContext, LeadStatus } from 'src/app/models';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { LoanType } from 'src/app/models/config/loan-type.model';
import { ReferralSource } from 'src/app/models/referral-source.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { User } from 'src/app/models/user/user.model';
import { AgentFull } from 'src/app/modules/app-details/models/agent.model';
import { Lead } from 'src/app/modules/leads/models/lead.model';
import { LeadsService } from 'src/app/modules/leads/services/leads.service';
import { UpsertReferralSourceComponent } from 'src/app/modules/referral-source/components/upsert-referral-source/upsert-referral-source.component';
import { AgentService } from 'src/app/services/agent.service';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { ConfirmLoanPurposeChangeDialogComponent } from '../dialogs/confirm-loan-purpose-change-dialog/confirm-loan-purpose-change-dialog.component';
import { ChannelService } from 'src/app/services/channel.service';
import { TagManagementService } from 'src/app/modules/admin/tag-management/services/tag-management.service';
import {Tag } from 'src/app/modules/admin/tag-management/models/tag.model';

@Component({
  selector: 'lead-assignment-info',
  templateUrl: './lead-assignment-info.component.html',
  styleUrls: ['./lead-assignment-info.component.scss'],
  viewProviders: [formViewProvider],
})
export class LeadAssignmentInfoComponent extends ApplicationContextBoundComponent implements OnInit, OnChanges {

  @Input() lead: Lead;
  @Input() isDrawer: boolean = false;
  @Input() showTags: boolean = false;
  @Input() isSolar: boolean = false;

  @Output()
  leadUpdated: EventEmitter<Lead> = new EventEmitter<Lead>();

  @Output()
  loanPurposeChanged: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  showTagsUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild("loanPurpose")
  loanPurposeComponent: ElementRef<HTMLSelectElement>;

  @ViewChild("loanType")
  loanTypeComponent: ElementRef<HTMLSelectElement>;

  @ViewChild("loanChannel")
  loanChannelComponent: ElementRef<HTMLSelectElement>;

  isTpo: boolean = false;
  agentsLoading: boolean = false;
  loadingLeadTags: boolean = false;
  loadingLoadedLeadsList: boolean = false;

  enabledChannels: EnumerationItem[] = [];

  leadStatuses: LeadStatus[] = [];
  referralSources: ReferralSource[] = [];
  insideReferralSources: User[] = [];
  users: User[] = [];

  allLeadTags: Tag[] = [];
  leadTags: Tag[] = [];
  newListTag: string = null;

  loanPurposes: LoanPurpose[] = [];
  loanTypes: LoanType[] = [];

  multiSelectSettings: IDropdownSettings = {
    idField: 'tagId',
    textField: 'name',
    allowSearchFilter: true,
    clearSearchFilter: true,
    noDataAvailablePlaceholderText: "No tags available",
    closeDropDownOnSelection: true,
    enableCheckAll: false
  }

  private _loanPurpose_state_confirming: boolean = false;
  private _loanPurpose_value_previous: number;
  private _configLeadStatuses: LeadStatus[] = [];
  private _allLoanPurposes: LoanPurpose[] = [];

  constructor(
    private readonly injector: Injector,
    private readonly _leadsService: LeadsService,
    private readonly _agentService: AgentService,
    private readonly _notifyService: NotificationService,
    private readonly _modalService: NgbModal,
    private readonly _spinnerService: NgxSpinnerService,
    private readonly _channelService: ChannelService,
    private readonly _tagService: TagManagementService,
    private cdr: ChangeDetectorRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getLeadTags();
    this.getAgents();

    this.applicationContextService.context.subscribe((context) => {
      this.isTpo = context.isTpo;

      if (this.isTpo) {
        this.users = context.globalConfig.tpoUsers || [];
        this.insideReferralSources = context.globalConfig.tpoUsers || [];
        this.enabledChannels = this.initEnabledChannelsForTpo(context);
      } else {
        this.users = context.globalConfig.users || [];

        // for loda and solar
        const isSolarAndLoda = context.userPermissions.companyId == 2 || context.userPermissions.companyId == 170;
        if (isSolarAndLoda) {
          this.insideReferralSources = (context.globalConfig.users || []).concat(context.globalConfig.tpoUsers || []);
          this.enabledChannels = _.uniqBy(context.globalConfig.enabledChannels, "name");
        } else {
          this.insideReferralSources = context.globalConfig.users || [];
          this.enabledChannels = _.uniqBy(context.globalConfig.enabledChannels.filter(c => c.name != "Wholesale"), "name");
        }
      }

      this._allLoanPurposes = context.globalConfig.loanPurpose;
      this.filterLoanPurposesByChannel();

      if (this.loanPurposes.length == 1) {
        this.lead.loanPurposeId = this.loanPurposes[0].loanPurposeId;
        this.onLoanPurposeChanged();
      }

      this.loanTypes = context.globalConfig.loanType;
      if (this.loanTypes.length == 1) {
        this.lead.loanTypeId = this.loanTypes[0].loanTypeId;
      }

      this._configLeadStatuses = context.globalConfig.leadStatus;
      if (!this.lead.leadId) {
        this.resetLeadStatus();
      } else {
        this._leadsService.getLeadStatusesForLoanPurpose(this.lead.loanPurposeId, this.lead.leadStatusId)
          .subscribe({
            next: (statuses) => {
              this.leadStatuses = statuses;
            }, error: (err) => {
              this._notifyService.showError(err ? err.message || err : '', 'Error!');
            }
          });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {

    let oldValue = changes.lead?.previousValue?.loanPurposeId || undefined;
    let newValue = changes.lead?.currentValue?.loanPurposeId || undefined;

    if (newValue == oldValue) {
      return;
    }

    // don't prompt for new leads
    if (!this.lead.leadId) {
      this.resetLeadStatus();
      return;
    }

    if (this._loanPurpose_state_confirming) {
      this._loanPurpose_state_confirming = false;
      return;
    }

    if (oldValue !== undefined && newValue !== undefined) {
      this.viewLoanPurposeConfirm();

      this._loanPurpose_state_confirming = true;
      this._loanPurpose_value_previous = oldValue;
    }

    if (this.lead["loanPurpose_value_old"] == undefined) {
      this.lead["loanPurpose_value_old"] = oldValue;
    }
  }

  onChannelChanged = () => {
    this.filterLoanPurposesByChannel();
  }

  onAddNewAgentClicked = () => {
    let modalRef = this._modalService.open(UpsertReferralSourceComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.agents = this.referralSources;

    modalRef.result.then((newAgent: AgentFull) => {
      this.getAgents(newAgent);
    }, () => {
    });
  }

  private filterLoanPurposesByChannel = () => {
    if (this.lead.channel) {
      this.loanPurposes = _.cloneDeep(this._allLoanPurposes.filter(lp => lp.enabledChannels.includes(this.lead.channel)));
    }
    else {
      this.loanPurposes = _.cloneDeep(this._allLoanPurposes);
    }
  }

  private resetLeadStatus = () => {
    this.leadStatuses = this._configLeadStatuses;
    this.lead.leadStatusId = this.leadStatuses[0].loanStatusId; // for new loan
  }

  private cancelChangeLoanPurpose = () => {
    this.lead.loanPurposeId = this._loanPurpose_value_previous;
  }

  private okChangeLoanPurpose = () => {
    this._loanPurpose_state_confirming = false;
    this.lead["loanPurpose_value_changed"] = (this.lead["loanPurpose_value_old"] != this.lead.loanPurposeId);
    this.resetLeadStatus();
  }

  private viewLoanPurposeConfirm = () => {
    let modalRef = this._modalService.open(ConfirmLoanPurposeChangeDialogComponent, Constants.modalOptions.xlarge);

    modalRef.result.then(() => {
      this.okChangeLoanPurpose();
    }, () => {
      this.cancelChangeLoanPurpose();
    });
  }

  private getAgents = (defaultSelectedAgent: AgentFull = null) => {
    this.agentsLoading = true;
    this._agentService.getAllReferralSources().subscribe((referralSources) => {

      this.referralSources = _.orderBy(referralSources, ["lastName", "firstName"], ['asc', 'asc'])
        .filter(s => s.firstName || s.lastName);

      this.referralSources.forEach(rs => {
        rs["displayName"] = rs.lastName ? rs.lastName + (rs.firstName ? ', ' + rs.firstName : '') : "";
      });
      this.agentsLoading = false;
      if (defaultSelectedAgent) {
        const referralSourceToSelect = this.referralSources.find(rs => rs.firstName.toLocaleLowerCase() === defaultSelectedAgent.agent.firstName.toLocaleLowerCase() &&
          rs.lastName.toLocaleLowerCase() === defaultSelectedAgent.agent.lastName.toLocaleLowerCase());
        if (referralSourceToSelect) {
          this.lead.referralSource = referralSourceToSelect.agentId;
        }
      }
    });
  }

  selectTag = (tag: Tag) => {
    let matchedTag = this.allLeadTags.find(t => t.tagId == tag.tagId);
    if (matchedTag) {
      this.addToLeadTags(matchedTag);
    }
  }

  deSelectTag = (tag: Tag) => {
    let matchedTag = this.allLeadTags.find(t => t.tagId == tag.tagId);
    if (matchedTag) {
      this.deleteFromLeadTags(matchedTag);
    }
  }

  deleteFromLeadTags = (tag: Tag) => {
    if (!this.lead.leadId) {
      this.leadTags = this.leadTags.filter(l => l.tagId != tag.tagId);
      this.cdr.detectChanges();  
      return;
    };
  }

  // assign list to lead
  addToLeadTags = (tag: Tag) => {
    if (!this.lead.leadId) {
      this.leadTags = _.uniqBy([...this.leadTags, tag], "tagId");
      this.cdr.detectChanges();  
      return;
    }
  }

  addNewTag = () => {

    let isExist = this.allLeadTags.some(list => list.name?.toLowerCase() === this.newListTag.toLowerCase());
    if (isExist) {
      this._notifyService.showWarning("This tag has already exist in the list", "Warning");
      return
    }

    this._spinnerService.show();

    const newtag: Tag = {
      name: this.newListTag,
      restrictedToContactListTypes: '4',
      companyId: this.applicationContext.userPermissions.companyId
    }
    this._tagService.postTag(newtag)
      .subscribe({
        next: (addedtag: Tag) => {
          this.newListTag = null;
          this.leadTags = [...this.leadTags,addedtag]
          this.allLeadTags = [...this.allLeadTags, addedtag];
          this.selectTag(addedtag);
          
          this._notifyService.showSuccess("New Tag created successfully", "Success");
        },
        error: (err) => {
          this._notifyService.showError("Error encountered while creating tag", "Error");
        },
        complete: () => {
          this._spinnerService.hide();
        }
      });
  }

  onLoanPurposeChanged = () => {
    this.isSolar = false;

    // purchase
    if (this.lead.loanPurposeId == 1) {
      this.lead.cashOutAmount = null;
    }

    // refinance
    if (this.lead.loanPurposeId == 2) {
      this.lead.downPayment = null;

      // remove on init
      if (!this.lead.leadId) {
        this.lead.propertyWillBe = null;
      }
    }

    // solar
    if (this.lead.loanPurposeId == 1467) {
      this.isSolar = true;

      this.lead.channel = null;
      this.lead.loanTypeId = null;
      this.lead.leadContactUserId = null;
      this.lead.referralSource = null;
      this.lead.insideReferralSource = null;
      this.lead.referredTo = null;
    }

    this.loanPurposeChanged.emit(this.lead.loanPurposeId);
  }

  private getLeadTags = () => {
    this.loadingLeadTags = true;
    this._tagService.getTagList().subscribe({
      next: (allTags) => {
        const filteredTags = allTags.filter(tag => {
          const contactListTypes = tag.restrictedToContactListTypes
            ? tag.restrictedToContactListTypes.replace(/\s/g, '').split(',')
            : [];
          return contactListTypes.includes('4');
        });

        this.allLeadTags = _.orderBy(filteredTags, ['name'], ['asc']);

        const leadTagIds = !this.lead.tags ? [] : this.lead.tags.map(lt => lt.tagId);   

        this.leadTags = this.allLeadTags.filter(lt => leadTagIds.includes(lt.tagId));

        if (this.lead?.leadId) {
          this.showTags = this.leadTags?.length > 0;
          this.showTagsUpdated.emit(this.showTags);
        }
      
      },
      error: (error) => {
        this._notifyService.showError(error?.message || "Error encountered while loading lists", "Error!");
      }
    }).add(() => this.loadingLeadTags = false);
  }

  private initEnabledChannelsForTpo = (context: ApplicationContext): EnumerationItem[] => {
    let externalCompanyId: number;

    const user = context.globalConfig.tpoUsers.find(u => u.userCompanyGuid === context.currentlyLoggedInUser.userCompanyGuid);

    if (user) {
      const branch = context.globalConfig.branches.find(b => b.branchId === user.branchId);
      externalCompanyId = branch?.externalCompanyId || user.externalCompanyId;
    }

    const externalCompanies = context.globalConfig.externalCompanies.filter(x => x.externalCompanyId === externalCompanyId);
    if (externalCompanies.length === 0) return [];

    const availableChannelNames = this._channelService
      .getChannelsFromCommaDelimitedString(externalCompanies[0]?.enabledChannels)
      .map(c => c.name);

    return context.globalConfig.enabledChannels.filter(c => availableChannelNames.includes(c.name));
  }
}
