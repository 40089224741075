<div class="card border-top border-0 border-4 border-white">
  <div class="card-header">
    <h4 class="card-title col-5" style="display: inline-block;"><i class="fas fa-columns me-2"></i> Amortization Table
    </h4>
  </div>
  <div class="card-body">
    <loading-indicator *ngIf="isLoading" [loadingMessage]="'Loading amortization...'"></loading-indicator>
    <div *ngIf="!isLoading">
      <div class="my-4" *ngIf="mortgage?.mortgageTerm?.amortizationType == 'ARM'">
        <div class="row">
          <div class="col-md-4">
            <label class="form-label" for="initialRateChange">Initial Rate Change</label>
            <input type="number" class="form-control" id="initialRateChange" name="initialRateChange"
              [(ngModel)]="initialRateChange">
          </div>
          <div class="col-md-4">
            <label class="form-label" for="subsequentRateChange">Subsequent Rate Change</label>
            <input type="number" class="form-control" id="subsequentRateChange" name="subsequentRateChange"
              [(ngModel)]="subsequentRateChange">
          </div>
          <div class="col-md-4">
            <label class="form-label" for="subsequentRateChangeFrequency">Subsequent Rate Change Frequency</label>
            <input type="number" class="form-control" id="subsequentRateChangeFrequency"
              name="subsequentRateChangeFrequency" [(ngModel)]="subsequentRateChangeFrequency">
          </div>
        </div>
        <br>
        <div class="button-items">
          <button class="btn btn-primary" (click)="getAmortizationInfo()" style="float: right;" [disabled]="isSaving">
            <span *ngIf="isSaving" class="spinner-border spinner-border-sm" role="status"
              aria-hidden="true"></span>Calculate
          </button>
        </div>
        <br><br>
      </div>
      <div class="table-responsive" [hidden]="!amortizationInfo">
        <table class="table mb-0 table-centered">
          <thead>
            <tr>
              <th>Loan Information</th>
              <th>Loan Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Product</td>
              <td>{{amortizationInfo.product}}</td>
            </tr>
            <tr>
              <td>Total Loan Amount</td>
              <td>{{amortizationInfo.totalLoanAmount | currency}}</td>
            </tr>
            <tr>
              <td>Additional Amount Financed</td>
              <td>{{amortizationInfo.additionalAmountFinanced | currency}}</td>
            </tr>
            <tr>
              <td>Total Amount Financed</td>
              <td>{{amortizationInfo.totalAmountFinanced | currency}}</td>
            </tr>
            <tr>
              <td>Finance Charge</td>
              <td>{{amortizationInfo.financeCharge | currency}}</td>
            </tr>
            <tr>
              <td>Total Payments</td>
              <td>{{amortizationInfo.totalPayments | currency}}</td>
            </tr>
            <tr>
              <td>{{mortgage?.mortgageTerm?.amortizationType != 'FixedRate' ? 'Interest Rate' : 'APR'}}</td>
              <td>{{(amortizationInfo.apr/100) | percent:'1.3-3'}}</td>
            </tr>
          </tbody>
        </table>
        <br>
        <p-table [value]="amortizationInfo.amortizationTable" [paginator]="true" [rows]="25"
          *ngIf="amortizationInfo.amortizationTable" [showCurrentPageReport]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [rowsPerPageOptions]="[10,25,50]" styleClass="p-datatable-gridlines">
          <ng-template pTemplate="header">
            <tr>
              <th>Month #</th>
              <th *ngIf="mortgage?.mortgageTerm.amortizationType != 'FixedRate'">Rate</th>
              <th>Principal</th>
              <th>Interest</th>
              <th>Payment</th>
              <th *ngIf="mortgage?.mortgageTerm?.mortgageAppliedFor == 'USDA'">USDA Annual Fee</th>
              <th *ngIf="mortgage?.mortgageTerm?.mortgageAppliedFor == 'USDA'">Payment w/ Annual Fee</th>
              <th>Balance</th>
              <th>Resulting LTV</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-tableInfo>
            <tr>
              <td>{{tableInfo.period}}</td>
              <td *ngIf="mortgage?.mortgageTerm.amortizationType != 'FixedRate'">{{(tableInfo.rate / 100) | percent:'1.3-3'}}</td>
              <td>{{tableInfo.principal | currency}}</td>
              <td>{{tableInfo.interest | currency}}</td>
              <td>{{tableInfo.payment | currency}}</td>
              <td *ngIf="mortgage?.mortgageTerm?.mortgageAppliedFor == 'USDA'">{{tableInfo.usdaAnnualFee | currency}}</td>
              <td *ngIf="mortgage?.mortgageTerm?.mortgageAppliedFor == 'USDA'">{{tableInfo.paymentWithUSDAAnnualFee | currency}}</td>
              <td>{{tableInfo.balance | currency}}</td>
              <td>{{(tableInfo.ltv / 100) | percent :'1.3-3'}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>