import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { debounce } from 'lodash';
import { Subscription, firstValueFrom } from 'rxjs';
import { ApplicationContext, LoanApplication } from 'src/app/models';
import { LosService } from 'src/app/services/los.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { getErrorMessageOrDefault, tryGetErrorMessage } from 'src/app/shared/utils/error-utils';
import { VendorProfile } from '../../models/business-channel.model';
import { LoanPassIframeLockPriceMessage } from '../../models/pricing/loan-pass-iframe-lock-price-message.model';
import { OriginatorCompensation } from '../../models/pricing/originator-compensation.model';
import { PricingService } from '../../services/pricing.service';
import { PricingTransactionType } from '../pricing-details/pricing-details.component';
import { LoanPassFieldMappingsService } from 'src/app/services/loan-pass-field-mappings.service';
import { LoanPassCreditApplicationField } from 'src/app/models/loan-pass/loanpass-credit-application.field.model';
import { UrlaMortgage } from 'src/app/modules/urla/models/urla-mortgage.model';
import { ProductSearchRequest, ProductSearchRequestInfo } from '../../models/pricing/product-search-request-info.model';
import _ from 'lodash';

@Component({
  selector: 'loanpass-pricer',
  templateUrl: 'loanpass-pricer.component.html',
})
export class LoanPassPricerComponent
  extends ApplicationContextBoundComponent
  implements OnInit, OnDestroy
{
  @Input()
  transactionType: PricingTransactionType = null;

  @Output()
  pricingCancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  pricingCompleted: EventEmitter<LoanApplication> =
    new EventEmitter<LoanApplication>();

  @Output()
  priceLockClicked: EventEmitter<LoanPassIframeLockPriceMessage> =
    new EventEmitter<LoanPassIframeLockPriceMessage>();

  iFrame: HTMLIFrameElement | undefined;

  preparingForPricing: boolean = false;
  losSyncCompleted: boolean = false;
  loanCreateAttempts: number = 0;
  loanSyncAttempts: number = 0;
  error: string = null;

  get originalProductSearchRequest(): ProductSearchRequest {
    return this._originalProductSearchRequest;
  }

  protected application: LoanApplication;
  protected mortgage: UrlaMortgage;

  protected loanPassPricerUrl: SafeResourceUrl;

  private _clientAccessId: string;
  private _userId: string;
  private _branchId: number;
  private _loanpassOrigin = 'https://app.loanpass.io';
  private _iframeUserState: 'pending' | 'logged-in' = 'pending';

  private _credentialsToUse: VendorProfile = null;

  private _originatorCompensation: OriginatorCompensation;

  private _defaultLoanPassCreditApplicationFieldsForQuickPricer: LoanPassCreditApplicationField[];

  private _loanPassCreditApplicationFieldsForApplication: LoanPassCreditApplicationField[];

  private _externalPipelineRecordId: string;

  private _sentInitializationMessagesToIframe: boolean = false;

  private _loanInfoChangesSubscription: Subscription;

  private _contextSubscription: Subscription;

  private _originalProductSearchRequest: ProductSearchRequest;

  constructor(
    private readonly injector: Injector,
    private readonly _losService: LosService,
    private readonly _domSanitizer: DomSanitizer,
    private readonly _pricingService: PricingService,
    private readonly _loanpassFieldMappingsService: LoanPassFieldMappingsService
  ) {
    super(injector);
    this._contextSubscription =
      this.applicationContextService.context.subscribe((context) => {
        this._branchId =
          context.currentlyLoggedInUserProfile?.userProfile?.branchId;
        this._userId = context.currentlyLoggedInUser.userCompanyGuid;
      });
    this._loanInfoChangesSubscription =
      this.applicationContextService.loanInfoChanges.subscribe((context) => {
        if (context.application) {
          this.initApplicationRelatedContext(context);
          this.initializeDebounce();
        }
      });
  }

  async ngOnInit() {
    if (this.applicationContext?.application?.applicationId) {
      this.initApplicationRelatedContext(this.applicationContext);
      this.initializeDebounce();
    } else {
      this.initializeDebounce();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._loanInfoChangesSubscription?.unsubscribe();
    this._contextSubscription?.unsubscribe();

    window.removeAllListeners();
  }

  onLoadIframe = () => {
    setTimeout(() => {
      this.iFrame = document.getElementById(
        `loan-pass-pricer-iframe`
      ) as HTMLIFrameElement;
      if (!this.iFrame || !this.iFrame.contentWindow) {
        return;
      }
      console.log('connecting...');
      this.iFrame.contentWindow.postMessage(
        {
          message: 'connect',
        },
        this._loanpassOrigin
      );
    });
  };

  onCancelClicked() {
    this.pricingCancelled.emit();
  }

  initializeDebounce = debounce(this.initialize, 1000);

  private initApplicationRelatedContext = (context: ApplicationContext) => {
    this.application = this.applicationContext.application;
    this.mortgage = this.applicationContext.currentMortgage;
    this._userId = this.application.userId || this._userId;
    this._branchId = this.application.branchId || this._branchId;
  };

  private async initialize() {
    const successsFullyGotCreds = await this.tryGetLoanPassIframeCredentials();
    if (!successsFullyGotCreds) {
      return;
    }

    await this.getOriginatorCompensation();

    if (!this.application) {
      this._defaultLoanPassCreditApplicationFieldsForQuickPricer = await this.getDefaultLoanPassCreditApplicationFields();
      await this.initializeLoanPassIframe();
      return;
    } else {
      this._loanPassCreditApplicationFieldsForApplication = await this.getLoanPassCreditApplicationFieldsForApplication();
      this._externalPipelineRecordId = await this.getExternalPipelineRecordId();
    }
    await this.initializePricingRequest();

    // if (this.applicationContext.userPermissions.userType == UserType.Tpo) {
    //   if (!this.losSyncCompleted) {
    //     if (!this.applicationContext.application.losIdentifier) {
    //       while (this.loanCreateAttempts < 3 && !this.losSyncCompleted) {
    //         this.loanCreateAttempts++;
    //         await this.autoCreateLosLoan();
    //       }
    //     } else {
    //       while (this.loanSyncAttempts < 3 && !this.losSyncCompleted) {
    //         this.loanSyncAttempts++;
    //         await this.autoSyncLosLoan();
    //       }
    //     }
    //   } else {
    //     await this.initializeLoanPassIframe();
    //   }
    // } else {
    await this.initializeLoanPassIframe();
    // }
  }

  private getOriginatorCompensation = async () => {
    try {
      this._originatorCompensation = await firstValueFrom(
        this._pricingService.getActiveOriginatorCompensationForUser(
          this._userId,
          this._branchId
        )
      );
      this.error = undefined;
    } catch (e) {
      this.error = getErrorMessageOrDefault(e, {
        defaultMessage:
          'There was an error attempting to get originator compensation.',
      });
    } finally {
      this.preparingForPricing = false;
    }
  };

  private initializeLoanPassIframe = async () => {
    this.preparingForPricing = true;
    try {
      this.error = undefined;
      let decodedUrl = decodeURIComponent(this._credentialsToUse.url);
      this.loanPassPricerUrl =
        this._domSanitizer.bypassSecurityTrustResourceUrl(decodedUrl);
      if (this._credentialsToUse?.url) {
        this._clientAccessId = decodedUrl.split('/').pop();
      }

      window.addEventListener('message', (event) => {
        if (event.origin !== this._loanpassOrigin) {
          //console.warn("received message from unexpected origin", event);
          return;
        }

        console.log('received message', event.data);
        switch (event.data.message) {
          case 'listening':
            this.handleListening();
            break;
          case 'price-lock':
            this.priceLockClicked.emit({
              credentialId: this._credentialsToUse.credentialId,
              ...event.data,
            });
            break;
        }
      });
    } catch (e) {
      this.error =
        e.message?.replace(
          '{' + this.application?.losIdentifier + '}',
          this.application?.refNumber
        ) || 'There was an error attempting to Loan Pass IFrame.';
    } finally {
      this.preparingForPricing = false;
    }
  };

  private tryGetLoanPassIframeCredentials = async () => {
    this.preparingForPricing = true;
    try {
      const credentials = await firstValueFrom(
        this._pricingService.getPricingCredentials(
          this.application?.applicationId
        )
      );
      this.error = undefined;

      let credentialsToUse: VendorProfile = null;
      if (credentials) {
        const loanPassIframeCredentials = credentials[
          'loanPassIframe'
        ] as VendorProfile[];
        if (loanPassIframeCredentials) {
          credentialsToUse = loanPassIframeCredentials.find(
            (c) => c.userCompanyGuid === this._userId
          );
          if (!credentialsToUse) {
            credentialsToUse = loanPassIframeCredentials.find(
              (c) => c.branchId === this._branchId
            );
          }
          if (!credentialsToUse && loanPassIframeCredentials.length) {
            credentialsToUse = loanPassIframeCredentials[0];
          }
        }
        if (!credentialsToUse) {
          this.error =
            'We were not able to identify credentials to use for Loan Pass IFrame.';
          return false;
        }
        if (!credentialsToUse.username || !credentialsToUse.password) {
          this.error =
            'The credentials that we identified for Loan Pass IFrame does not have a username or password set.';
          return false;
        }
        if (!credentialsToUse.url) {
          this.error =
            'We were not able to get the URL to use for Loan Pass Iframe.';
          return false;
        }
        this._credentialsToUse = credentialsToUse;
        return true;
      }
    } catch (e) {
      const errorMessage = tryGetErrorMessage(e);
      if (errorMessage) {
        this.error =
          'Error fetching credentials for Loan Pass Iframe - ' + errorMessage;
      } else {
        this.error =
          'There was an error fetching credentials for Loan Pass IFrame.';
      }
      return false;
    } finally {
      this.preparingForPricing = false;
    }
  };

  private getLoanPassCreditApplicationFieldsForApplication = async (): Promise<
    LoanPassCreditApplicationField[]
  > => {
    this.preparingForPricing = true;
    try {
      const loanPassCreditApplicationFieldsForApplication =
        await firstValueFrom(
          this._loanpassFieldMappingsService.getLoanPassCreditApplicationFieldsForApplication(
            this._credentialsToUse.credentialId,
            this.application.applicationId
          )
        );
      this.error = undefined;
      this.preparingForPricing = true;
      return loanPassCreditApplicationFieldsForApplication;
    } catch (e) {
      console.error(e);
      const errorMessage = getErrorMessageOrDefault(e, {
        defaultMessage:
          'An error occurred while getting LoanPass credit application fields for application.',
      });
      this.error = errorMessage;
      this.preparingForPricing = false;
    }
  };

  private copyProductSearchCriteriFromDefaults = (
    requestInfo: ProductSearchRequestInfo,
    defaultProductSearchRequestInfo: ProductSearchRequestInfo
  ) => {
    requestInfo.request.amortizationTypes =
      defaultProductSearchRequestInfo.request.amortizationTypes;
    requestInfo.request.loanTerms =
      defaultProductSearchRequestInfo.request.loanTerms;
    requestInfo.request.productTypes =
      defaultProductSearchRequestInfo.request.productTypes;
    requestInfo.request.armFixedTerms =
      defaultProductSearchRequestInfo.request.armFixedTerms;
  };

  private initializePricingRequest = async () => {
    const defaultProductSearchRequestInfo = await this._pricingService.getDefaultPricingRequest(undefined);
    let productSearchRequest = defaultProductSearchRequestInfo.request;
    if (this.mortgage) {
      try {
        this.preparingForPricing = true;
        const response = await firstValueFrom(this._pricingService.getMortgagePricingRequest(this.mortgage.mortgageId));
        this.copyProductSearchCriteriFromDefaults(response, defaultProductSearchRequestInfo);
        const productSearchRequestInfo = _.merge(
          defaultProductSearchRequestInfo,
          response
        );
        productSearchRequest = productSearchRequestInfo.request;

        if (!productSearchRequest.desiredRate && this.mortgage.mortgageTerm.interestRate) {
          productSearchRequest.desiredRate = this.mortgage.mortgageTerm.interestRate / 100;
          productSearchRequest.desiredPrice = null;
        } else {
          productSearchRequest.desiredPrice = 100;
        }
        if (productSearchRequest.loanLevelDebtToIncomeRatio) {
          if (productSearchRequest.loanLevelDebtToIncomeRatio >= 999) {
            productSearchRequest.loanLevelDebtToIncomeRatio = 0.01;
          } else {
            productSearchRequest.loanLevelDebtToIncomeRatio = productSearchRequest.loanLevelDebtToIncomeRatio / 100;
          }
        }
        if (productSearchRequest.loanInformation && productSearchRequest.loanInformation.upfrontPmiMipFfGfPercent) {
          productSearchRequest.loanInformation.upfrontPmiMipFfGfPercent = productSearchRequest.loanInformation.upfrontPmiMipFfGfPercent / 100;
        }

        if (productSearchRequest.loanInformation.waiveEscrows == null) {
          productSearchRequest.loanInformation.waiveEscrows = false;
        }

        productSearchRequest.loanInformation.ltv = this.calculateLtv(productSearchRequest);
        if ( productSearchRequest.loanInformation.secondLienAmount == 0 || !productSearchRequest.loanInformation.secondLienAmount) {
          productSearchRequest.loanInformation.cltv = productSearchRequest.loanInformation.ltv;
        }
      } catch (e) {
        this.error = getErrorMessageOrDefault(e, {
          defaultMessage:
            'An error occurred while getting mortgage pricing request.',
        });
      } finally {
        this.preparingForPricing = false;
      }
    }
    this._originalProductSearchRequest = _.cloneDeep(productSearchRequest);
  };

  private calculateLtv = (request: ProductSearchRequest): number => {
    const minValue = this.minOfSalesPriceAndAppraisedValue(request);
    if (minValue == 0) {
      return 0;
    }
    let ltv = request.loanInformation.baseLoanAmount / minValue;
    return ltv;
  };

  private minOfSalesPriceAndAppraisedValue = (
    request: ProductSearchRequest
  ): number => {
    var salesPrice = request.propertyInformation.salesPrice
      ? request.propertyInformation.salesPrice
      : Number.MAX_VALUE;
    var appraisedValue = request.propertyInformation.appraisedValue
      ? request.propertyInformation.appraisedValue
      : Number.MAX_VALUE;
    var min = Math.min(salesPrice, appraisedValue);
    return min != Number.MAX_VALUE ? min : 0;
  };

  private getDefaultLoanPassCreditApplicationFields = async (): Promise<
    LoanPassCreditApplicationField[]
  > => {
    this.preparingForPricing = true;
    const defaultProductSearchRequest =
      await this._pricingService.getDefaultPricingRequest(undefined);
    try {
      const defaultLoanPassCreditApplicationFields = await firstValueFrom(
        this._loanpassFieldMappingsService.getDefaultLoanPassCreditApplicationFields(
          this._credentialsToUse.credentialId,
          defaultProductSearchRequest.request
        )
      );
      this.error = undefined;
      this.preparingForPricing = true;
      return defaultLoanPassCreditApplicationFields;
    } catch (e) {
      console.error(e);
      const errorMessage = getErrorMessageOrDefault(e, {
        defaultMessage:
          'An error occurred while getting external pipeline record from LoanPass.',
      });
      this.error = errorMessage;
      this.preparingForPricing = false;
    }
  };

  private getExternalPipelineRecordId = async (): Promise<string> => {
    this.preparingForPricing = true;
    try {
      const externalPipelineRecord = await firstValueFrom(
        this._loanpassFieldMappingsService.getExternalPipelineRecord(
          this._credentialsToUse.credentialId,
          this.application.applicationId
        )
      );
      this.error = undefined;
      this.preparingForPricing = true;
      return externalPipelineRecord.externalPipelineRecordId;
    } catch (e) {
      console.error(e);
      const errorMessage = getErrorMessageOrDefault(e, {
        defaultMessage:
          'An error occurred while getting external pipeline record from LoanPass.',
      });
      this.error = errorMessage;
      this.preparingForPricing = false;
    }
  };

  private autoCreateLosLoan = async () => {
    this.preparingForPricing = true;
    try {
      const losAppOpResult = await firstValueFrom(
        this._losService.autoCreateLosLoan(this.application.applicationId)
      );
      this.error = undefined;
      this.losSyncCompleted = true;
      this.applicationContextService.updateMortgageAndApplication(
        losAppOpResult.application?.mortgageLoan,
        losAppOpResult.application,
        losAppOpResult.customData
      );
    } catch (e) {
      console.error(e);
      this.error =
        e.message?.replace(
          '{' + this.application.losIdentifier + '}',
          this.application.refNumber
        ) || 'There was an error attempting to prepare your loan for pricing.';
      this.preparingForPricing = false;
    }
  };

  private autoSyncLosLoan = async () => {
    this.preparingForPricing = true;
    try {
      const losAppOpResult = await firstValueFrom(
        this._losService.autoSyncLosLoan(this.application.applicationId)
      );
      this.error = undefined;
      this.losSyncCompleted = true;
      this.applicationContextService.updateMortgageAndApplication(
        losAppOpResult.application?.mortgageLoan,
        losAppOpResult.application,
        losAppOpResult.customData
      );
    } catch (e) {
      console.error(e);
      this.error =
        e.message?.replace(
          '{' + this.application.losIdentifier + '}',
          this.application.refNumber
        ) || 'There was an error attempting to prepare your loan for pricing.';
      this.preparingForPricing = false;
    }
  };

  private setExternalPipelineRecordId = async () => {
    if (!this._externalPipelineRecordId) {
      return;
    }
    this.iFrame.contentWindow.postMessage(
      {
        message: 'set-pipeline-record-id',
        pipelineRecordId: this._externalPipelineRecordId,
      },
      this._loanpassOrigin
    );
  };

  private setOriginatorCompensationFields = () => {
    this.iFrame.contentWindow.postMessage(
      {
        message: 'set-fields',
        fields: [
          {
            fieldId: 'field@compensation-minimum',
            value: {
              type: 'number',
              value: this._originatorCompensation.compensationMinimum,
            },
          },
          {
            fieldId: 'field@compensation-maximum',
            value: {
              type: 'number',
              value: this._originatorCompensation.compensationMaximum,
            },
          },
          {
            fieldId: 'field@compensation-percent',
            value: {
              type: 'number',
              value: this._originatorCompensation.compensationPercent,
            },
          },
          {
            fieldId: 'field@compensation-fixed-amount',
            value: {
              type: 'number',
              value: this._originatorCompensation.compensationFixedAmount,
            },
          },
        ],
      },
      this._loanpassOrigin
    );
  };

  private setLoanPassCreditApplicationFieldsForQuickPricer = () => {
    this.iFrame.contentWindow.postMessage(
      {
        message: 'set-fields',
        fields: this._defaultLoanPassCreditApplicationFieldsForQuickPricer,
      },
      this._loanpassOrigin
    );
  };

  private setLoanPassCreditApplicationFieldsForApplication = () => {
    this.iFrame.contentWindow.postMessage(
      {
        message: 'set-fields',
        fields: this._loanPassCreditApplicationFieldsForApplication,
      },
      this._loanpassOrigin
    );
  };

  private setEnablePriceLockingButton = () => {
    this.iFrame.contentWindow.postMessage(
      {
        message: 'enable-price-locking',
        lockRequestLabel: this.transactionType
          ? this.transactionType == PricingTransactionType.reprice
            ? 'Reprice'
            : 'Program Change'
          : 'Assign Pricing',
      },
      this._loanpassOrigin
    );
  };

  private sendInitializationMessagesToIframe = () => {
    if (this._originatorCompensation) {
      this.setOriginatorCompensationFields();
    }
    if (this.application) {
      this.setEnablePriceLockingButton();
      this.setExternalPipelineRecordId();
      this.setLoanPassCreditApplicationFieldsForApplication();
    } else {
      // Quick Pricer
      this.setLoanPassCreditApplicationFieldsForQuickPricer();
    }
    this._sentInitializationMessagesToIframe = true;
  };

  private handleListening = () => {
    switch (this._iframeUserState) {
      case 'pending':
        console.log('sending log in message...');
        if (!this.iFrame) {
          return;
        }
        this.iFrame.contentWindow.postMessage(
          {
            message: 'log-in',
            clientAccessId: this._clientAccessId,
            emailAddress: this._credentialsToUse.username,
            password: this._credentialsToUse.password,
          },
          this._loanpassOrigin
        );

        this._iframeUserState = 'logged-in';
        break;
      case 'logged-in':
        console.log('log in completed');
        // Here, we do not know if the login succeeded or not, since iframe does not give us any indication that it failed or not
        // Call the initialization messages after a timeout, hoping it succeeded
        setTimeout(() => {
          if (!this._sentInitializationMessagesToIframe) {
            this.sendInitializationMessagesToIframe();
          }
        }, 5000);
        break;
    }
  };
}


