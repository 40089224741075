<div class="card">
    <div *ngIf="iframeUrl">
        <iframe
            [src]="iframeUrl | safeUrl"
            style="width: 100%; min-height: 500px !important; height: 75vh;"
        ></iframe>
    </div>

    <div class="alert alert-danger border-0" role="alert" *ngIf="errorMessage">
        <strong>{{errorMessage}}</strong>
    </div>
</div>