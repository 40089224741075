<form class="form-horizontal" id="credentialForm" name="credentialForm" #credentialForm="ngForm">
    <div class="modal-header">
        <h5 class="modal-title">Credential</h5>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <credential-modal-basic [scope]="scope" [credential]="credential" [disabledChannel]="disabledChannel"
                    (onChangeVendor)="onChangeVendor($event)">
                </credential-modal-basic>
            </div>
            <div class="col-md-12 mt-1" *ngIf="credential.vendorName && affiliatesApplicable">
                <label class="custom-control-label">Technical Affiliate</label>
                <div class="d-flex">
                    <div class="flex-grow-1">
                        <select class="form-select" name="technicalAffiliate" #ta="ngModel" [disabled]="isLoadingAffiliates"
                            [ngClass]="{'is-invalid' : ta && ta.touched && ta.invalid}"
                            [(ngModel)]="keyValuePairs.technicalAffiliate.value"
                            (change)="onTechnicalAffiliateChanged(keyValuePairs.technicalAffiliate.value)" required>
                            <option [ngValue]="null">-- Select One --</option>
                            <option *ngFor="let item of technicalAffiliates"
                                [ngValue]="item.creditTechnicalAffiliateId?.toString()">
                                {{item.name}}
                            </option>
                        </select>
                        <div class="invalid-feedback">Technical affiliate is required.</div>
                    </div>
            
                    <div *ngIf="isLoadingAffiliates" class="spinner-border spinner-border-sm ms-2 mt-1" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="credential.vendorName.includes('Meridian') && keyValuePairs.technicalAffiliate.value == '5'">
                <div class="col-md-6 mt-1">
                    <label class="custom-control-label">Surrogate Login Username</label>
                    <input type="text" class="form-control"
                        name="surrogateLoginUsername"
                        [(ngModel)]="keyValuePairs.surrogateLoginUsername.value">
                </div>
    
                <div class="col-md-6 mt-1">
                    <label class="custom-control-label">Soft Pull Loan Purpose String</label>
                    <input type="text" class="form-control"
                        name="softPullLoanPurposeString"
                        [(ngModel)]="keyValuePairs.softPullLoanPurposeString.value">
                </div>
            </ng-container>

            <div class="col-md-12 mt-1" *ngIf="credential.vendorName === 'EncompassCredit'">
                <label class="custom-control-label">Credit Provider</label>
                <div class="d-flex">
                    <div class="flex-grow-1">
                        <select class="form-select" name="creditVendorId" #cv="ngModel" [disabled]="isLoadingAffiliates"
                            [ngClass]="{'is-invalid' : cv && cv.touched && cv.invalid}"
                            [(ngModel)]="keyValuePairs.creditVendorId.value" required>
                            <option value="">-- Select One --</option>
                            <option *ngFor="let item of creditProviders" [ngValue]="item.value">
                                {{item.name}}
                            </option>
                        </select>
                        <div class="invalid-feedback">Credit provider is required.</div>
                    </div>
            
                    <div *ngIf="isLoadingAffiliates" class="spinner-border spinner-border-sm ms-2 mt-1" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-1" *ngIf="credential.vendorName !== 'EncompassCredit'">
                <label class="custom-control-label">Url</label>
                <div class="d-flex align-items-center w-100">
                    <div style="width: inherit">
                        <input type="text" class="form-control"
                            [ngClass]="{'is-invalid': url && url.touched && url.invalid }" name="url" #url="ngModel"
                            [(ngModel)]="credential.url"
                            [disabled]="isUrlDisabled && credential.vendorName !== 'Xactus' && credential.vendorName !== 'CredCoSoftPull' &&
                                credential.vendorName !== 'CredCoHardPull' && credential.vendorName !== 'FactualDataSoftPull' && credential.vendorName !== 'FactualDataHardPull'"
                            [required]="credential.vendorName === 'Xactus'">
                        <div class="invalid-feedback">Url is required.</div>
                    </div>
                    <i *ngIf="keyValuePairs.technicalAffiliate.value && credential.vendorName !== 'Xactus'"
                        class="fa fa-pencil-alt cursor text-primary ms-2" (click)="isUrlDisabled = false;"
                        title="Enable Url"></i>
                </div>
            </div>

            <div class="col-md-6 mt-1" *ngIf="credential.vendorName !== 'ISoftPull'">
                <label class="custom-control-label">Username</label>
                <input type="text" class="form-control"
                    [ngClass]="{'is-invalid': userName && userName.touched && userName.invalid }" name="userName"
                    #userName="ngModel" [(ngModel)]="credential.userName"
                    [required]="credential.vendorName === 'Xactus' || credential.vendorName === 'EncompassCredit'">
                <div class="invalid-feedback">Username is required.</div>
            </div>

            <div class="col-md-6 mt-1" *ngIf="credential.vendorName !== 'ISoftPull'">
                <label class="custom-control-label">Password</label>
                <password-input [name]="'password'" [(ngModel)]="credential.password"
                    [required]="credential.vendorName === 'Xactus' || credential.vendorName === 'EncompassCredit'"></password-input>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6" *ngIf="credential.vendorName !== 'ISoftPull'">
                <label class="custom-control-label">Default Bureaus</label>
                <ng-select width="100%" name="bureaus-dropdown" bindLabel="name" bindValue="value" appendTo="body"
                    placeholder="Select Bureau" [closeOnSelect]="false" [multiple]="true"
                    [(ngModel)]="selectedDefaultBureaus" [items]="bureaus">
                    <ng-template ng-header-tmp>
                        <div>
                            <a class="d-block" (click)="onSelectAllDefaultBureaus()"
                                style="font-size: 1.1em; color: inherit">- Select All -</a>
                        </div>
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-md-6">
                <div class="form-check form-switch form-switch-success"
                    [ngStyle]="{'margin-top': credential.vendorName !== 'ISoftPull' ? '2.5em' : '1.5em'}">
                    <input type="checkbox" class="form-check-input" id="isDefaultForOnlineApp"
                        name="isDefaultForOnlineApp" [(ngModel)]="isDefaultForOnlineApp">
                    <label style="padding-left: 4px;" class="form-check-label" for="isDefaultForOnlineApp">
                        Is default for online app
                    </label>
                </div>
            </div>
        </div>
        <div class="row"
            *ngIf="credential.vendorName == 'FactualDataSoftPull' || credential.vendorName == 'FactualDataHardPull' || credential.vendorName == 'ISoftPull'">
            <div class="col-md-12 mt-1">
                <label class="custom-control-label">Api Key</label>
                <div class="d-flex align-items-center w-100">
                    <div style="width: inherit">
                        <input type="text" class="form-control" name="apiKey" #apiKey="ngModel"
                            [(ngModel)]="keyValuePairs.apiKey.value">
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="credential.vendorName == 'EncompassCredit'">
            <div class="col-md-12 mt-1">
                <label class="custom-control-label">Client Id</label>
                <div class="d-flex align-items-center w-100">
                    <div style="width: inherit">
                        <input type="text" class="form-control" name="creditClientId" #creditClientId="ngModel"
                            [(ngModel)]="keyValuePairs.creditClientId.value">
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="credential.vendorName == 'ISoftPull'">
            <div class="col-md-12 mt-1">
                <label class="custom-control-label">Secret Access Token</label>
                <password-input [name]="'secretAccessToken'" [(ngModel)]="keyValuePairs.secretAccessToken.value" [required]="false" [validationFieldName]="'Secret Access Token'"></password-input>
            </div>
        </div>
    </div>
    <div class="modal-footer form-footer">
        <button type="button" class="btn btn-secondary right" (click)="activeModal.dismiss()">
            Cancel
        </button>
        <button type="button" class="btn btn-primary right" (click)="saveCredential()" [disabled]="isSaving">
            <span *ngIf="!isSaving"><i class="fa fa-save me-2"></i> Save</span>
            <span *ngIf="isSaving">
                <span class="spinner-border spinner-border-sm"></span> Saving
            </span>
        </button>
    </div>
</form>
