<div class="modal-body" id="editTaskDialog">
    <div class="row text-end">
        <a href="javascript: void(0);" (click)="toggleTaskView()" *ngIf="task.loanDocTaskId > 0">Show Task
            {{historyVisible
            ? "Details" :
            "History"}}</a>
        <hr>
    </div>
    <form #editTaskForm="ngForm" id="editTaskForm" name="editTaskForm">
        <div [hidden]="historyVisible">
            <div class="row">
                <div [ngClass]="appId ? 'col-md-6' : 'col-md-11'">
                    <div class="row" *ngIf="!isLoggedInUserTpo && task.loanDocTaskId > 0">
                        <div class="col-md-12">
                            <div class="form-group mb-3 row">
                                <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Status</label>
                                <div class="col-md-10">
                                    <select class="form-select" id="taskStatus" [(ngModel)]="task.taskStatus"
                                        name="taskStatus" disabled>
                                        <option value="ConditionImportPending"
                                            *ngIf="task.taskStatus=='ConditionImportPending'">
                                            Condition Import Pending</option>
                                        <option *ngFor="let status of taskStatusOptions" value="{{status.value}}"
                                            [selected]="task.taskStatus == status.value">
                                            {{status.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="!isLoggedInUserTpo">
                        <div class="col-md-6" *ngIf="task.applicationId && task.taskType !== 'EsignDocument'">
                            <div class="form-group mb-3 row">
                                <label class="col-md-4 form-label align-self-center mb-lg-0 text-end">Borrower</label>
                                <div class="col-md-8">
                                    <select class="form-select" id="borrowerId" [(ngModel)]="task.borrowerId"
                                        name="borrowerId" [disabled]="(isBorrowerTaskSelected || isMyTaskSelected)"
                                        [hidden]="task.loanDocTaskId == 0">
                                        <option *ngFor="let borrower of borrowers" value="{{borrower.borrowerId}}"
                                            [selected]="task.borrowerId==borrower.borrowerId">
                                            {{borrower.firstName + ' ' + borrower.lastName}}
                                        </option>
                                    </select>
                                    <div [hidden]="task.loanDocTaskId > 0">
                                        <ng-select2 id="borrowerIdMulti" name="borrowerIdMulti"
                                            [disabled]="(isBorrowerTaskSelected || isMyTaskSelected)"
                                            class="select2 select2-multiple" [(ngModel)]="task.multipleBorrower"
                                            [options]="optionsMultipleSelect" multiple="multiple">
                                            <option *ngFor="let borrower of borrowers" value="{{borrower.borrowerId}}">
                                                {{borrower.firstName + ' ' + borrower.lastName}}
                                            </option>
                                        </ng-select2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="task.taskType !== 'EsignDocument' && !isLoggedInUserTpo">
                            <div class="form-group mb-3 row">
                                <label class="col-md-4 form-label align-self-center mb-lg-0 text-end">Priority</label>
                                <div class="col-md-8">
                                    <select class="form-select" id="taskPriority" [(ngModel)]="task.taskPriority"
                                        name="taskPriority" [disabled]="taskReadonly">
                                        <option *ngFor="let priority of taskPriority" value="{{priority.lookupValue}}">
                                            {{priority.lookupDescription}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="task.leadId">
                        <div class="col-md-6">
                            <div class="form-group mb-3 row">
                                <label class="col-md-4 form-label align-self-center mb-lg-0 text-end">Assigned
                                    To</label>
                                <div class="col-md-8">
                                    <select class="form-select" id="assignedTo" [(ngModel)]="task.userId"
                                        name="assignedTo" [disabled]="taskReadonly">
                                        <option *ngFor="let user of users | sort: 'asc':'displayName'"
                                            value="{{user.userCompanyGuid}}">
                                            {{user.lastName + ", " + user.firstName}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="!isLoggedInUserTpo && task.applicationId && !isPHM">
                        <div class="col-md-12">
                            <div class="form-group mb-3 row">
                                <label class="col-md-2 form-label mb-lg-0 text-end align-self-center">Doc
                                    Type</label>
                                <div class="col-md-10">
                                    <ng-select2 class="select2 mb-3 custom-select" id="documentTypeId"
                                        *ngIf="taskStatusOptions" [(ngModel)]="task.documentTypeId"
                                        name="documentTypeId" [disabled]="taskReadonly"
                                        [required]="task.taskStatus=='ConditionImportPending' && !isPHM" [options]="optionsSelect"
                                        (ngModelChange)="checkPopulateDescription()" placeholder="Select doc type">
                                        <option *ngFor="let documentType of documentTypes"
                                            value="{{documentType.documentTypeId}}"
                                            [selected]="task.documentTypeId == documentType.documentTypeId">
                                            {{documentType.documentTypeName}}
                                        </option>
                                    </ng-select2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group mb-3 row">
                                <label
                                    class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Description</label>
                                <div class="col-sm-10">
                                    <input class="form-control" placeholder="Description" id="escalationDescription"
                                        name="escDescription" type="text" [(ngModel)]="task.description"
                                        [disabled]="taskReadonly || isLoggedInUserTpo">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!(isLoggedInUserTpo && isPRMG)" class="row">
                        <div class="col-md-12">
                            <div class="form-group mb-3 row">
                                <label for="notes" class="col-sm-2 form-label mb-lg-0 text-end">Notes</label>
                                <div class="col-sm-10">
                                    <message-editor-with-mentions #messageEditor [disabled]="taskReadonly"
                                        [mentionables]="mentionables"
                                        (messageChanged)="onMessageChanged($event, 'note')">
                                    </message-editor-with-mentions>
                                    <div *ngIf="internalNotes && internalNotes.length > 0"
                                        class="card card-height mt-3">
                                        <div class="card-body">
                                            <div class="card" *ngFor="let note of internalNotes">
                                                <div class="card-body">
                                                    <span class="note-pre-wrap"
                                                        [innerHtml]="internalNoteImageProcessStatuses.get(note['uniqueId']).processedContent | safeHtml">
                                                    </span>
                                                    <div>{{note.senderFirstName}} {{note.senderLastName}}
                                                        ({{note.postTime | date:'MMM d, y h:mm:ss a'}})
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="task.taskType !== 'EsignDocument'">
                        <div class="col-md-12">
                            <div class="form-group mb-3 row">
                                <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Request
                                    Date</label>
                                <div class="col-sm-4">
                                    <input id="requestDate" class="form-control" placeholder="Request Date"
                                        name="RequestDate" type="text"
                                        value="{{task.requestDate | date:'MM/dd/yyyy h:mma'}}" disabled>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="task.taskType !== 'EsignDocument'">
                        <div class="col-md-12">
                            <div class="form-group mb-3 row">
                                <div class="radio radio-info form-check-inline col-sm-2 text-end align-self-center">
                                    <input type="radio" id="dueDayOrDate1" value="day" name="radioInline"
                                        [(ngModel)]="dueDayOrDate"
                                        [disabled]="taskReadonly || isEsignDocumentWithNoLoanDocTaskId || isLoggedInUserTpo || dueDayOrDate == 'dateInput'">
                                    <label for="dueDayOrDate1" class="form-label align-self-center mb-lg-0 text-end">Due
                                        Days</label>
                                </div>
                                <div class="col-md-2">
                                    <input class="form-control" placeholder="Due Days" name="dueDays" type="text"
                                        [(ngModel)]="task.dueDays" (ngModelChange)="dueDate = $event; dueDaysChange()"
                                        [disabled]="taskReadonly || isEsignDocumentWithNoLoanDocTaskId || isLoggedInUserTpo || dueDayOrDate == 'dateInput'">
                                </div>
                                <div class="radio form-check-inline col-sm-2 text-end align-self-center">
                                    <input type="radio" id="dueDayOrDate2" value="dateInput" name="radioInline"
                                        [(ngModel)]="dueDayOrDate"
                                        [disabled]="taskReadonly || isEsignDocumentWithNoLoanDocTaskId || isLoggedInUserTpo || dueDayOrDate == 'dateInput'">
                                    <label for="dueDayOrDate2" class="form-label align-self-center mb-lg-0 text-end">Due
                                        Date</label>
                                </div>
                                <div class="col-sm-4">
                                    <input id="dueDate" class="form-control" name="dueDate" type="date"
                                        [disabled]="dueDayOrDate == 'day' || taskReadonly"
                                        [ngModel]="dueDate | date:'yyyy-MM-dd'"
                                        (ngModelChange)="dueDate = $event; dueDateChange()">
                                </div>
                                <div class="col-sm-2 d-flex align-items-center justify-content-center"
                                    *ngIf="task.loanDocTaskId">
                                    <div class="button-items btn-group-left"
                                        [hidden]="taskReadonly || isEsignDocumentWithNoLoanDocTaskId || isLoggedInUserTpo || dueDayOrDate == 'dateInput'">
                                        <button class="btn btn-primary" type="button" #snoozeButton
                                            data-bs-container="body" data-bs-toggle="popover" placement="top"
                                            [popover]="templateSnooze" data-trigger="click" [outsideClick]="true"
                                            [adaptivePosition]="false" [disabled]="taskReadonly">
                                            Snooze <i class="far fa-clock ms-1"></i>
                                        </button>
                                        <i class="ms-1 text-info" *ngIf="willBeSnoozed">Will snooze for {{
                                            getSnoozeDurationTime(snoozeDurationInMinutes) }}</i>
                                        <ng-template #templateSnooze>
                                            <div class="minutePicker">
                                                <div class="w-100 text-end">
                                                    <button type="button" class="close" (click)="snoozeButton.click()">
                                                        <span aria-hidden="true">x</span></button>
                                                </div>
                                                <div class="minute_picker_innser">
                                                    <h3>Snooze Duration</h3>
                                                    <div class="minut_select_wrap">
                                                        <select class="form-select"
                                                            [(ngModel)]="snoozeDurationInMinutes" name="snoozeDropdown">
                                                            <option [ngValue]="null">-- Select One --</option>
                                                            <option [ngValue]="15">15 mins</option>
                                                            <option [ngValue]="30">30 mins</option>
                                                            <option [ngValue]="45">45 mins</option>
                                                            <option [ngValue]="60">1 hour</option>
                                                            <option [ngValue]="120">2 hours</option>
                                                            <option [ngValue]="180">3 hours</option>
                                                            <option [ngValue]="240">4 hours</option>
                                                        </select>
                                                    </div>
                                                    <div class="w-100 text-center my-2">
                                                        <button class="btn btn-primary"
                                                            (click)="snoozeTask(); snoozeButton.click()">Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="!isLoggedInUserTpo">
                        <div class="col-md-12">
                            <div class="form-group mb-3 row">
                                <label class="col-sm-2 form-label align-self-center mb-lg-0 text-end">Follow
                                    Up</label>
                                <div class="col-sm-4">
                                    <input id="requestDate" class="form-control" placeholder="Follow Up"
                                        name="FollowUpDate" type="date"
                                        [ngModel]="task.followUpDate | date:'yyyy-MM-dd'"
                                        (ngModelChange)="task.followUpDate = $event" [disabled]="taskReadonly">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="task.applicationId">
                        <div class="col-md-6">
                            <div class="form-group mb-3 row">
                                <label class="col-md-4 form-label align-self-center mb-lg-0 text-end">Responsible
                                    Party</label>
                                <div class="col-md-8">
                                    <select class="form-select" id="responsibleUser" [(ngModel)]="task.userId"
                                        name="responsibleUser"
                                        [disabled]="taskReadonly || isEsignDocumentWithNoLoanDocTaskId || isLoggedInUserTpo">
                                        <option value="">-- Select One --</option>
                                        <ng-container *ngFor="let user of notifyPartyLoanContacts">
                                            <optgroup
                                                *ngIf="notifyPartyLoanContacts && notifyPartyLoanContacts.length > 0"
                                                label="{{user.roleName}}">
                                                <option class="user-select" value="{{user.userId}}"
                                                    [selected]="user.userId == task.userId">
                                                    {{user.lastName + ", " + user.firstName}}
                                                </option>
                                            </optgroup>
                                        </ng-container>
                                        <optgroup label="All Branch Users" *ngIf="!isLoanTpo">
                                            <ng-container *ngFor="let user of branchUsers">
                                                <option *ngIf="user.active" value="{{user.userCompanyGuid}}"
                                                    [selected]="user.userCompanyGuid == task.userId && !isInNotifyPartyList(task.userId)">
                                                    {{user.lastName + ", " + user.firstName}}
                                                </option>
                                            </ng-container>
                                        </optgroup>
                                        <optgroup label="All Users" *ngIf="!isLoanTpo">
                                            <ng-container *ngFor="let user of users">
                                                <option *ngIf="user.active" value="{{user.userCompanyGuid}}"
                                                    [selected]="user.userCompanyGuid == task.userId && !isInNotifyPartyList(task.userId)">
                                                    {{user.lastName + ", " + user.firstName}}
                                                </option>
                                            </ng-container>
                                        </optgroup>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="task.taskType !== 'EsignDocument' && !isLoggedInUserTpo">
                            <div class="form-group mb-3 row">
                                <label class="col-md-4 form-label align-self-center mb-lg-0 text-end">Notify
                                    Party</label>
                                <div class="col-md-8">
                                    <select class="form-select" id="notifyParty" [(ngModel)]="task.notifyPartyId"
                                        name="notifyParty" [disabled]="taskReadonly">
                                        <option value="">-- Select One --</option>
                                        <ng-container *ngFor="let user of notifyPartyLoanContacts">
                                            <optgroup
                                                *ngIf="notifyPartyLoanContacts && notifyPartyLoanContacts.length > 0"
                                                label="{{user.roleName}}">
                                                <option class="user-select" value="{{user.userId}}"
                                                    [selected]="user.userId == task.notifyPartyId">
                                                    {{user.lastName + ", " + user.firstName}}
                                                </option>
                                            </optgroup>
                                        </ng-container>
                                        <optgroup label="TPO All Users" *ngIf="isLoanTpo">
                                            <option class="user-select" *ngFor="let user of externalCompanyUsers"
                                                [selected]="user.userCompanyGuid == task.notifyPartyId"
                                                value="{{user.userCompanyGuid}}">
                                                {{user.lastName + ", " + user.firstName}}
                                            </option>
                                        </optgroup>
                                        <optgroup label="All Users">
                                            <ng-container *ngFor="let user of users">
                                                <option *ngIf="user.active" value="{{user.userCompanyGuid}}"
                                                    [selected]="user.userCompanyGuid == task.notifyPartyId && !isInNotifyPartyList(task.notifyPartyId)">
                                                    {{user.lastName + ", " + user.firstName}}
                                                </option>
                                            </ng-container>
                                        </optgroup>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="appId > 0 && !isLoggedInUserTpo ">
                        <div class="col-md-6">
                            <div class="form-group mb-3 row">
                                <label class="col-md-4 form-label align-self-center mb-lg-0 text-end">Review
                                    Required</label>
                                <div class="col-md-8">
                                    <input class="form-check-input" type="checkbox" id="requiresReview"
                                        name="requiresReview" [(ngModel)]="task.requiresReview"
                                        [checked]="task.requiresReview" (change)="requiresReviewChanged()"
                                        [disabled]="taskReadonly || !canEditReviewStatus || isEsignDocumentWithNoLoanDocTaskId">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="isEsignDocumentWithNoLoanDocTaskId">
                            <span class="text-muted">*You have to upload a doc before you can add review parties.</span>
                        </div>
                        <div class="col-md-6" *ngIf="task.requiresReview">
                            <div class="form-group mb-3 row">
                                <label class="col-md-4 form-label align-self-center mb-lg-0 text-end">Review Party</label>
                                <div class="col-md-8">
                                    <select class="form-select " id="reviewParty" [(ngModel)]="task.reviewPartyId"
                                        name="reviewParty" [disabled]="taskReadonly || !(!completedStatus && task.requiresReview)">
                                        <option value="">-- Select One --</option>
                                        <ng-container *ngFor="let user of notifyPartyLoanContacts">
                                            <optgroup
                                                *ngIf="notifyPartyLoanContacts && notifyPartyLoanContacts.length > 0"
                                                label="{{user.roleName}}">
                                                <option class="user-select" value="{{user.userId}}"
                                                    [selected]="user.userId == task.reviewPartyId">
                                                    {{user.lastName + ", " + user.firstName}}
                                                </option>
                                            </optgroup>
                                        </ng-container>
                                        <optgroup label="All Users">
                                            <ng-container *ngFor="let user of users">
                                                <option *ngIf="user.active" value="{{user.userCompanyGuid}}"
                                                    [selected]="user.userCompanyGuid == task.reviewPartyId && !isInNotifyPartyList(task.reviewPartyId)">
                                                    {{user.lastName + ", " + user.firstName}}
                                                </option>
                                            </ng-container>
                                        </optgroup>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="task.applicationId">
                        <div class="col-md-12">
                            <div class="form-group mb-3 row">
                                <label class="col-md-2 form-label align-self-center mb-lg-0 text-end">Condition</label>
                                <div class="col-md-1 d-flex">
                                    <input class="form-check-input align-self-center m-0" type="checkbox" id="condition"
                                        name="condition" [(ngModel)]="task.condition" [checked]="task.condition"
                                        (ngModelChange)="onTaskConditionChanged()"
                                        [disabled]="taskReadonly || isLoggedInUserTpo">
                                </div>
                                <label class="col-md-1 form-label align-self-center mb-lg-0 text-end"
                                    *ngIf="task.condition">Id</label>
                                <div class="col-md-2" *ngIf="task.condition">
                                    <input class="form-control" type="text" id="conditionId" name="conditionId"
                                        [(ngModel)]="task.conditionId" [disabled]="taskReadonly || isLoggedInUserTpo">
                                </div>
                                <label class="col-md-2 form-label align-self-center mb-lg-0 text-end"
                                    *ngIf="task.condition">Type</label>
                                <div class="col-md-4" *ngIf="task.condition">
                                    <select class="form-select" [(ngModel)]="task.conditionType" name="conditionType"
                                        [disabled]="taskReadonly || isLoggedInUserTpo">
                                        <option value="">-- Select One --</option>
                                        <option value="Approval">Approval</option>
                                        <option value="Docs">Docs</option>
                                        <option value="Funding">Funding</option>
                                        <option value="Closing">Closing</option>
                                        <option value="Purchase">Purchase</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="task.condition && task.applicationId">
                        <div class="col-md-12">
                            <div class="form-group mb-3 row">
                                <label class="col-md-2 form-label align-self-center mb-lg-0 text-end">Condition
                                    Text</label>
                                <div class="col-md-10">
                                    <textarea class="form-control" placeholder="Exact condition text"
                                        name="conditionText" [(ngModel)]="task.conditionText"
                                        [disabled]="taskReadonly || isLoggedInUserTpo"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="task.applicationId">
                        <div class="col-md-12"
                            [hidden]="((task.borrowerId == undefined) && (!task.multipleBorrower || task.multipleBorrower.length == 0))">
                            <div class="form-group mb-3 row">
                                <label class="col-md-2 form-label align-self-center mb-lg-0 text-end"
                                    style="padding-left: 0;">
                                    Request From Borrower </label>
                                <div class="col-md-10">
                                    <input class="form-check-input" type="checkbox" id="requestBorrower"
                                        name='requestBorrower' [(ngModel)]="task.requestBorrower"
                                        [checked]="task.requestBorrower" (change)="onRequestFromBorrowerChange()"
                                        [disabled]="taskReadonly">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="task.requestBorrower && task.applicationId">
                        <div class="col-md-12">
                            <div class="form-group mb-3 row">
                                <label for="borrowerFacingNote" class="col-sm-2 form-label mb-lg-0 text-end">Borrower
                                    Facing Note</label>
                                <div class="col-sm-10">
                                    <message-editor-with-mentions #borrowerFacingNoteEditor [disabled]="taskReadonly"
                                        [mentionables]="mentionables" [messageToSend]="borrowerFacingNote"
                                        (messageChanged)="onMessageChanged($event, 'borrowerFacingNote')">
                                    </message-editor-with-mentions>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="borrowerNotes && borrowerNotes.length > 0">
                        <div class="col-md-12">
                            <div class="form-group mb-3 row">
                                <label class="col-sm-2 form-label mb-lg-0 text-end"></label>
                                <div class="col-sm-10">
                                    <ul>
                                        <li *ngFor="let note of borrowerNotes">
                                            <span class="note-pre-wrap" [innerHtml]="note.content | safeHtml"></span>
                                            <div>{{note.senderFirstName}} {{note.senderLastName}}
                                                ({{note.postTime | date:'MMM d, y h:mm:ss a'}})</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="task.taskType == 'EsignDocument' && shouldShowEsignTaskAction()">
                        <h4 class="mb-3">Choose contacts for document signing</h4>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group mb-3 row">
                                    <label class="col-md-2 form-label align-self-center mb-lg-0 text-end">Loan
                                        Borrowers</label>
                                    <div class="col-md-10">
                                        <p-multiSelect [options]="borrowers" optionValue="borrowerId"
                                            optionLabel="fullName" defaultLabel="None selected"
                                            [disabled]="taskReadonly" [(ngModel)]="recipientBorrowerContactIds"
                                            name="borrowers" (onChange)="setBorrowerIds(recipientBorrowerContactIds)">
                                        </p-multiSelect>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group mb-3 row">
                                    <label class="col-md-2 form-label align-self-center mb-lg-0 text-end">Internal
                                        Contacts</label>
                                    <div class="col-md-10">
                                        <p-multiSelect [options]="internalContacts" optionValue="internalContactId"
                                            optionLabel="displayName" defaultLabel="None selected"
                                            [disabled]="taskReadonly" [(ngModel)]="recipientInternalContactIds"
                                            name="internalContact"
                                            (onChange)="setInternalContactIds(recipientInternalContactIds)">
                                        </p-multiSelect>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group mb-3 row">
                                    <label class="col-md-2 form-label align-self-center mb-lg-0 text-end">External
                                        Contacts</label>
                                    <div class="col-md-10">
                                        <p-multiSelect [options]="externalContacts" optionValue="externalContactId"
                                            optionLabel="fullName" defaultLabel="None selected"
                                            [disabled]="taskReadonly" [(ngModel)]="recipientExternalContactIds"
                                            name="externalContact"
                                            (onChange)="setExternalContactIds(recipientExternalContactIds)">
                                        </p-multiSelect>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="recipientBorrowerContactIds && recipientBorrowerContactIds.length > 0">
                            <div class="col-md-12">
                                <div class="form-group mb-3 row">
                                    <label for="borrowerFacingNote"
                                        class="col-sm-2 form-label mb-lg-0 text-end">Borrower
                                        Facing Note</label>
                                    <div class="col-sm-10">
                                        <textarea class="form-control" placeholder="Add a borrower facing note"
                                            id="borrowerFacingNote" name="borrowerFacingNote"
                                            [(ngModel)]="task.borrowerFacingNote" type="text" rows="4"
                                            [disabled]="taskReadonly"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group row" *ngIf="task.helpUrl">
                        <label class="col-sm-2 form-label mb-lg-0 text-end">Help me!</label>
                        <div class="col-sm-10">
                            <a target="_blank" href="{{task.helpUrl}}">Click Here</a>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="task.hasTemplateDocument && task.templateDocumentUrl">
                        <label class="col-sm-2 form-label mb-lg-0 text-end">Template Document</label>
                        <div class="col-md-10">
                            <a target="_blank" href="{{task.templateDocumentUrl.replace('Download', 'View')}}">View</a>
                            / <a target="_blank" href="{{task.templateDocumentUrl}}"><i class="fas fa-download"></i>
                                Download</a>
                        </div>
                    </div>
                    <div class="form-group row"
                        *ngIf="task.applicationId && (task.taskType == 'EsignDocument' && shouldShowEsignTaskAction()) && !isBorrowerTaskSelected">
                        <label for="files" class="col-sm-2 form-label mb-lg-0 text-end"> {{task.taskType ==
                            'GenerateDocument' ?
                            'Generate Document' :
                            'Upload/View'}} </label>
                        <div class="col-sm-10">
                            <div class="btn-group" *ngIf="task.taskType == 'EsignDocument'">
                                <input type="file" id="newLoanDocFile" name="newLoanDocFile" style="display:none"
                                    multiple (change)="onDocFilesChanged($event)" #addNewDocFileBtn />
                                <button type="button" class="btn btn-primary btn-sm waves-effect waves-light ms-3 mx-1"
                                    (click)="onAddNewDocFileClicked()" [disabled]="taskReadonly">
                                    Add New File
                                </button>
                                <button type="button" class="btn btn-primary btn-sm waves-effect waves-light"
                                    (click)="onLinkExistingDocumentsClicked()" [disabled]="taskReadonly">
                                    Link Documents
                                </button>
                                <button type="button" class="btn btn-primary btn-sm waves-effect waves-light ms-1"
                                    (click)="onMergeFilesClicked()" *ngIf="isMergeVisible()">
                                    Merge Documents
                                </button>
                            </div>
                            <div class="form-group row" *ngIf="task.docFiles.length">
                                <table class="tblFile mt-4">
                                    <tr>
                                        <th style="width: 40%;"> File name </th>
                                        <th class="text-center"> Action </th>
                                    </tr>
                                    <tr *ngIf="task.docFiles.length == 0">
                                        <td colspan="2" style="text-align: center">Empty</td>
                                    </tr>
                                    <tr *ngFor="let docFile of task.docFiles; let index = index">
                                        <td>
                                            <input *ngIf="task.docFiles.length > 1" type="checkbox"
                                                (click)="toggleFileSelected(docFile)" />
                                            <a (click)="onOpenLoanDocClicked(docFile)"
                                                [ngClass]="{'text-decoration-line-through': !docFile.active}"
                                                title="Show the Document">
                                                {{docFile.fileName}}
                                            </a>
                                        </td>
                                        <td class="text-center">
                                            <div *ngIf="docFile.active">
                                                <span *ngIf="docFile.guid != null">
                                                    <a href="javascript:void(0);"
                                                        (click)="onDownloadDocumentClicked(docFile)" title="Download">
                                                        <i class="fas fa-download"></i>
                                                    </a>
                                                    <span
                                                        [hidden]="docFile.mimeType == 'application/pdf' || docFile.convertedToPdf"
                                                        class="text-muted mx-1">|</span>
                                                    <a [hidden]="docFile.mimeType == 'application/pdf' || docFile.convertedToPdf"
                                                        (click)="onConvertToPdfClicked(docFile)" title="Convert to PDF">
                                                        <i class='far fa-file-pdf'></i>
                                                    </a>
                                                    <span class="text-muted mx-1">|</span>
                                                    <a href="javascript:void(0);" (click)="onDeleteFileClicked(docFile)"
                                                        *ngIf="docFile.confirmDelete != true">
                                                        <i class="far fa-trash-alt"></i>
                                                    </a>
                                                    <a *ngIf="docFile.confirmDelete == true" href="javascript:void(0);"
                                                        class="table-icon-button mx-2"
                                                        (click)="onDeleteFileCancelClicked(docFile)">
                                                        <i class="fa fa-times hoverDanger"></i>
                                                    </a>
                                                    <a *ngIf="docFile.confirmDelete == true" href="javascript:void(0);"
                                                        class="table-icon-button"
                                                        (click)="onDeleteFileConfirmClicked(docFile)">
                                                        <i class="fa fa-check-circle hoverSuccess"></i>
                                                    </a>
                                                </span>
                                                <span *ngIf="docFile.guid == null">
                                                    <a class="btn btn-danger"
                                                        (click)="removeFileFormLoanDoc(task.docFiles, index)">
                                                        Remove
                                                    </a>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div *ngIf="isMergeDocInfoTextVisible()" class="fs-6 text-primary">
                                <i class="fa fa-info-circle ms-1"></i>
                                Merge Document option is only available for saved documents.
                            </div>
                            <div [ngClass]="{'mt-4': !task.docFiles.length}" class="row">
                                <div class="col-md-12">
                                    <div class="form-group mb-3 row" style="border: 2px dashed #889988">
                                        <file-upload [(ngModel)]="uploadedFiles" multiple="true" name="files"
                                            [disabled]="taskReadonly" (ngModelChange)="onFileUploadChanged($event)">
                                        </file-upload>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="isEsignTaskStatusVisible">
                        <label class="col-sm-2 form-label mb-lg-0 align-self-center text-end"> eSign </label>
                        <div class="col-sm-10">
                            <div class="btn-group">
                                <button type="button" class="btn btn-primary btn-sm waves-effect waves-light"
                                    (click)="onRefreshESignOrderClicked()" [disabled]="refreshingEsignOrder">
                                    Refresh eSign Order
                                </button>
                                <button type="button" class="btn btn-primary btn-sm ms-1 waves-effect waves-light"
                                    (click)="onDownloadESignSentDocumentClicked()">
                                    View eSign Document
                                </button>
                                <!-- <button type="button" class="btn btn-primary btn-sm ms-1 waves-effect waves-light"
                                        (click)="onCorrectESignOrderClicked()" [disabled]="!allowCorrectEsign">
                                        Correct eSign Order
                                    </button> -->
                                <button *ngIf="eSignOrder.status === 'Sent' || eSignOrder.status === 'Pending'"
                                    type="button" class="btn btn-danger btn-sm ms-1 waves-effect waves-light"
                                    (click)="onCancelESignOrderClicked()" [disabled]="!allowCancelEsign">
                                    Cancel Order
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="isEsignTaskStatusVisible">
                        <label class="col-sm-2 form-label mb-lg-0 align-self-center text-end"></label>
                        <div class="col-sm-10">
                            <div class="row">
                                <label class="col-sm-4 form-label"> {{eSignOrder?.documentSigningVendor ?
                                    eSignOrder.documentSigningVendor : 'DocuSign'}} eSign Order ID: </label>
                                <div class="col-sm-8"> {{eSignOrder ? eSignOrder.thirdPartyOrderId : ''}}
                                </div>
                            </div>
                            <div class="row">
                                <label class="col-sm-4 form-label"> Order Status: </label>
                                <div class="col-sm-8"> {{eSignOrder ? eSignOrder.status : ''}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="shouldShowEsignTaskAction() && (isMyTaskSelected || isBorrowerTaskSelected) && !taskReadonly"
                        class="text-center">
                        <button type="button" [disabled]="isBorrowerTaskSelected" class="btn btn-primary"
                            (click)="signDocument()">
                            Sign Document
                        </button>
                    </div>
                    <div *ngIf="isEsignTaskStatusVisible && task.agentId" class="alert alert-info border-0 mx-5">
                        <i class="fa fa-info-circle text-primary me-1"></i>
                        This eSign task must be completed by Agent <strong>{{agentNameAndPortalAddress}}</strong> in the
                        agent portal.
                    </div>

                </div>
            </div>
        </div>
        <div [hidden]="!historyVisible">
            <p-table #dt [value]="taskTrackingInfo" [paginator]="true" [rows]="25" *ngIf="taskTrackingInfo"
                [rowsPerPageOptions]="[10,25,50]"
                [globalFilterFields]="['actionValue','dateCreated','firstName','lastName','userName']"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                styleClass="p-datatable-gridlines">
                <ng-template pTemplate="caption">
                    <div class="p-d-flex">
                        <span class="p-input-icon-right p-ml-auto">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" class="form-control"
                                (input)="dt.filterGlobal($event.target.value, 'contains')"
                                (ngModelChange)="dt.first=0"
                                placeholder="Search keyword" />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th *ngFor="let col of columns" [pSortableColumn]="'dateCreated'">
                            {{col.header}}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-taskTrackingInfo>
                    <tr>
                        <td> {{taskTrackingInfo.actionValue}} </td>
                        <td> {{taskTrackingInfo.dateCreated | date: 'MM/dd/yyyy h:mma'}} </td>
                        <td>
                            <span
                                *ngIf="taskTrackingInfo.by!=null && (taskTrackingInfo.userProfile.firstName != null || taskTrackingInfo.userProfile.lastName != null)">
                                {{taskTrackingInfo.userProfile.firstName}} {{taskTrackingInfo.userProfile.lastName}}
                            </span>
                            <span
                                *ngIf="taskTrackingInfo.by!=null && taskTrackingInfo.userProfile.firstName == null && taskTrackingInfo.userProfile.lastName == null">
                                {{taskTrackingInfo.userProfile.userName}}
                            </span>
                            <span *ngIf="taskTrackingInfo.borrowerId!=null">
                                {{taskTrackingInfo.borrower.firstName}} {{taskTrackingInfo.borrower.lastName}}
                            </span>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="3" class="text-center"> No history found. </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </form>
</div>

<div class="modal-footer form-footer" [ngClass]="{'justify-content-center': !hostedInModal}">
    <button type="button" *ngIf="hostedInModal" class="btn btn-secondary" (click)="activeModal.dismiss(isRefreshed)">
        Cancel
    </button>
    <button type="button" class="btn btn-primary"
        *ngIf="task.taskType == 'EsignDocument' && shouldShowEsignTaskAction()" [disabled]="uploadingFiles || !hasFilesForUploading() || taskReadonly || !(recipientBorrowerContactIds.length || recipientInternalContactIds.length ||
          recipientExternalContactIds.length)" (click)="createLoanDocAndUploadFiles()">Prepare Document for eSign
    </button>
    <button type="button" class="btn btn-primary" *ngIf="task.taskStatus === 'ConditionImportPending'"
        [disabled]="(taskReadonly || !isValid()) || (task.taskType == 'EsignDocument' && !isEsignProcessCompleted)"
        (click)="task.taskStatus='Pending'; saveTask()"><i class="fa fa-save"></i> Save & Activate</button>
    <button type="button" class="btn btn-primary" *ngIf="task.taskStatus === 'ConditionImportPending'"
        [disabled]="(taskReadonly || !isValid()) || (task.taskType == 'EsignDocument' && !isEsignProcessCompleted)"
        (click)="task.taskStatus='Completed'; saveTask()"><i class="fa fa-save"></i> Save & Complete</button>
    <button type="button" class="btn btn-primary" (click)="saveTask()"
        [disabled]="(taskReadonly || !isValid()) || (task.taskType == 'EsignDocument' && !isEsignProcessCompleted)">
        <i class="fa fa-save me-2"></i>Save
    </button>
</div>
