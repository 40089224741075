<div class="alert alert-dismissible d-flex align-items-center mb-0" *ngIf="isSubmitError"
  [ngClass]="{'alert-outline-danger': isSubmitError}" role="alert">
  <i class="ti ti-checks alert-icon me-2"></i>
  <div *ngIf="isSubmitError">
    <strong>Error:</strong> {{errorMessage? errorMessage : 'Credit Request Failed.'}}
  </div>
  <button type="button" class="btn-close" data-bs-dismiss="alert" (click)="closeErrors()" aria-label="Close"></button>
</div>
<div class="card-body">
  <div class="row align-items-center mb-2">
    <div class="col">
      <b> Filter By Status: </b>
    </div>
  </div>
  <select class="form-select" (change)="onCreditReportFilterChanged()" [(ngModel)]="currentStatusFilter"
    style="width:200px">
    <option value="all">All</option>
    <option value="success"> Successful </option>
    <option value="error"> Failures </option>
  </select>
</div>
<div class="card-body">
  <div class="table-responsive">
    <table class="table mb-0 table-centered">
      <thead class="table-light text-center">
        <tr>
          <th> Borrower Name </th>
          <th> Reference # </th>
          <th> Pull Type </th>
          <th> Scores </th>
          <th> Loan Doc </th>
          <th> Date </th>
          <th> Vendor </th>
          <th> Actions </th>
        </tr>
      </thead>
      <tbody class="text-center">
        <tr *ngFor="let creditReport of historyToShow | sort: 'date'">
          <td> {{creditReport.borrowerName}} </td>
          <td> {{creditReport.refNumber}}
            <br>
          </td>
          <td> {{creditReport.joint ? 'Joint' : 'Individual'}} </td>
          <td>
            <span *ngIf="creditReport.equifaxRequested != null && creditReport.successful == true">
              Equifax
              <b> {{creditReport.equifax}} </b>
            </span>
            <span *ngIf="creditReport.experianRequested != null && creditReport.successful == true">
              Experian
              <b> {{creditReport.experian}} </b>
            </span>
            <span *ngIf="creditReport.transUnionRequested != null && creditReport.successful == true">
              TransUnion
              <b> {{creditReport.transUnion}} </b>
            </span>
            <span *ngIf="creditReport.successful !== true" class="label-danger"> Credit Pull Failed </span>
          </td>
          <td>
            <a *ngIf="(creditReport.loanDocId)" (click)="openLoanDoc(creditReport.loanDocId)" title="Loan Doc">
              <i class="fa fa-lg far fa-file-pdf"></i>
            </a>
          </td>
          <td> {{creditReport.date | date: 'MM/dd/yyyy'}} </td>
          <td> {{creditReport.creditVendor}} </td>
          <td>
            <a *ngIf="(creditReport.refNumber) && creditReport.integrationHistoryId" title="Re-issue Report"
              (click)="onReissueClicked(creditReport.integrationHistoryId)">
              <i class='fas fa-undo-alt'></i>
            </a>
            <a *ngIf="(creditReport.successful === true) && creditReport.integrationHistoryId" class="mx-2"
              title="View Report" (click)="openReport(creditReport.integrationHistoryId)">
              <i class='fa-lg far fa-file-pdf'></i>
            </a>
            <span *ngIf="creditReport.showImport && creditReport.successful != false && creditReport.creditVendor !== 'EncompassCredit'"
              [ngClass]="{'ms-2': !creditReport.successful}" [rowSpan]="numberOfReportsJointWith(creditReport)">
              <a title="Import Liabilities" (click)="openImportLiabilitiesTab(creditReport.creditID)">
                <i class='fa fa-lg fa-file-import'></i>
              </a>
            </span>
          </td>
        </tr>
        <tr *ngIf="creditHistory && !creditHistory.length">
          <td colSpan="7">
            <div class="text-center">
              <span> No data found </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
