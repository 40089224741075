import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap } from '@angular/router';
import * as _ from 'lodash';
import { firstValueFrom, Observer } from 'rxjs';
import { CreateSignerViewRequest } from 'src/app/models/create-signer-view-request';
import { CreateSignerViewResponse } from 'src/app/models/create-signer-view-response';
import { ESignOrderDetail, ValidateESignPropertyInfoResponse } from 'src/app/models/validate-esign-property-info-response';
import { Constants } from 'src/app/services/constants';
import { SigningService } from 'src/app/services/signing.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'e-sign-via-token',
  templateUrl: 'e-sign-via-token.component.html',
  styleUrls: ['./e-sign-via-token.component.scss'],
})
export class ESignViaTokenComponent implements OnInit {

  @ViewChild('loanValidationForm')
  loanValidationForm: NgForm;

  protected alert: Alert;
  protected loadingMessage: string;

  protected isLoading: boolean = false;
  protected readyToSign: boolean = false;
  protected needEconsent: boolean = false;
  protected needToValidateLoan: boolean = false;

  protected zipCodePlaceHolder = 'XXXXX';
  protected zipCodeMask = '00000';
  protected zipCodePattern = '^[0-9]{5}?$';

  protected ssnPlaceHolder = 'XXXX';
  protected ssnMask = '0000';
  protected ssnPattern = '^[0-9]{4}?$';

  protected subjectPropertyZipCode: string;
  protected borrowerSsn: string;

  protected initialToken: string;

  protected signerViewUrl: SafeResourceUrl;
  protected documentSigningVendor: string;
  protected econsentGiven: boolean = false;

  protected isPropertyZipRequired?: boolean = false;
  protected isLast4DigitsOfSsnRequired?: boolean = false;

  protected esignOrderPackage: ValidateESignPropertyInfoResponse;

  private _ordersToSign: ESignOrderDetail[] = [];

  private _indexOfCurrentOrder: number = 0;

  private _finishedOrderIds: number[] = [];

  constructor(
    private readonly _signingService: SigningService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _domSanitizer: DomSanitizer,
    private readonly _authService: AuthService
  ) {

    this._authService.clearToken();

    this._activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this._activatedRoute.queryParams.subscribe((params) => {
        this.initialToken = params[Constants.token];
        if (this.initialToken) {
          this.confirmEsignToken(this.initialToken);
        } else {
          this.alert = new Alert(
            'There is no token.',
            'There needs to be a token in the e-sign URL.',
            AlertType.Error
          );
        }
      });
    });
  }

  ngOnInit() {
  }

  protected onSubmitClicked() {
    if (!this.isFormValid()) {
      return;
    }
    this.alert = null;
    this.validateLoan();
  }

  private startOverTheProcess = async () => {
    this.needEconsent = false;
    this.readyToSign = false;
    await this.confirmEsignToken(this.initialToken);
  };

  private confirmEsignToken = async (token: string): Promise<void> => {
    this.isLoading = true;
    this.loadingMessage = 'Validating token, please wait...';

    try {
      const response = await firstValueFrom(
        this._signingService.confirmEsignToken(token)
      );
      this.needToValidateLoan =
        response.requiresSsnLastFourDigits ||
        response.requiresSubjectPropertyZip;
      this.isLast4DigitsOfSsnRequired = response.requiresSsnLastFourDigits;
      this.isPropertyZipRequired = response.requiresSubjectPropertyZip;
    } catch (err) {
      this.alert = new Alert(
        'A problem occurred.',
        "An error occurred validating your token. It's either invalid or has expired.",
        AlertType.Error
      );
    }
    this.isLoading = false;
  };

  private validateLoan = () => {
    const observer: Observer<ValidateESignPropertyInfoResponse> = {
      next: (response: any) => {
        this.isLoading = false;
        this.loadingMessage = '';
        this.needToValidateLoan = false;
        this.esignOrderPackage = response;
        let ordersToSign = this.esignOrderPackage.eSignOrderDetail.filter((o) => !!o.publicToken && !!o.privateToken);
        this._ordersToSign = _.orderBy(ordersToSign, ['signingOrder'], ['asc']);
        if (this._ordersToSign.length > 0) {
          this.startEsignProcess();
        } else {
          this.alert = new Alert(
            'No orders to sign.',
            'There are no orders to sign at this time.',
            AlertType.Warning
          );
        }
      },
      error: (err: any) => {
        const errorMessage = 'An error occurred while validating your application. Please contact your loan officer for assistance.';
        this.alert = new Alert(
          'A problem occurred.',
          errorMessage,
          AlertType.Error
        );
      },
      complete: () => { },
    };

    this.isLoading = true;
    this.loadingMessage = 'Validating loan, please wait...';
    // const last4DigitsOfSsn = this.borrowerSsn?.slice(-4);
    this._signingService
      .validateLoanInfo(
        this.initialToken,
        this.subjectPropertyZipCode
      )
      .subscribe(observer)
      .add(() => {
        this.isLoading = false;
      });
  };

  private startEsignProcess = () => {
    if (this._ordersToSign.length > 0) {
      this.gotoEsignView(this._ordersToSign[0]);
    }
  };

  private gotoEsignView = (order: ESignOrderDetail) => {
    const observer: Observer<CreateSignerViewResponse> = {
      next: (response: any) => {
        this.documentSigningVendor = response.documentSigningVendor;
        this.signerViewUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(
          response.signerViewUrl
        );
        this.readyToSign = true;
        window.addEventListener(
          'message',
          (event) => {
            if (event.data.shouldCloseEsignDocIframe) {
              setTimeout(() => {
                const orderIdThatIsJustSigned = event.data.orderId;
                if (!this._finishedOrderIds.includes(orderIdThatIsJustSigned)) {
                  this._finishedOrderIds.push(orderIdThatIsJustSigned);
                  // Find the index of the order after this one
                  console.log('Order just signed: ', orderIdThatIsJustSigned + ", index: " + this._indexOfCurrentOrder);
                  const indexOfOrderJustSigned = this._ordersToSign.findIndex(o => o.signingOrder === orderIdThatIsJustSigned);
                  const indexOfNextOrder = indexOfOrderJustSigned + 1;
                  if (indexOfNextOrder < this._ordersToSign.length) {
                    this._indexOfCurrentOrder = indexOfNextOrder;
                    this.gotoEsignView(this._ordersToSign[indexOfNextOrder]);
                  }
                }
              }, 2000);
            }
          },
          false
        );
      },
      error: (err: any) => {
        let errorMessage = 'The data entered does not match our records, please try again.';
        if (err.status == 403) {
          errorMessage =
            'You do not have permission to esign for this loan.';
        } else {
          // Here, we need to start over
          this.startOverTheProcess();
        }
        this.alert = new Alert(
          'A problem occurred.',
          errorMessage,
          AlertType.Error
        );
      },
      complete: () => { },
    };

    const isAdmin = window.location.href.includes("/admin/");
    const returnUrl = `${window.location.protocol}//${window.location.host}/${isAdmin ? 'admin' : 'tpo'}/esign-confirmation/${order.publicToken}/${this._indexOfCurrentOrder + 1}/${this._ordersToSign.length}/${order.signingOrder}`;

    let request = new CreateSignerViewRequest();
    request.returnUrl = returnUrl;
    request.loanDocTaskId = order.loanDocTaskId;
    request.applicationId = this.esignOrderPackage.applicationId;
    request.borrowerId = this.esignOrderPackage.borrowerId;

    this.isLoading = true;
    this.loadingMessage = 'Fetching esign URL, please wait...';
    this._signingService
      .openEsignerViewForAnonymousEsign(
        order.publicToken,
        encodeURIComponent(order.privateToken),
        order.loanDocTaskId,
        request
      )
      .subscribe(observer)
      .add(() => {
        this.isLoading = false;
      });
  };

  private isFormValid = (): boolean => {
    if (this.loanValidationForm) {
      this.loanValidationForm.form.markAllAsTouched();
      return !this.loanValidationForm.form.invalid;
    }
    return true;
  };
}

export class Alert {
  title: string;
  message: string;
  type: AlertType = AlertType.Info;

  constructor(title: string, message: string, type: AlertType) {
    this.title = title;
    this.message = message;
    this.type = type;
  }
}

export enum AlertType {
  Info = 0,
  Warning = 1,
  Error = 2,
  Success = 3
}

