<loading-indicator *ngIf="loadingKeyDates" [loadingMessage]="'Loading key dates, please wait...'">
</loading-indicator>
<div *ngIf="!loadingKeyDates">
  <form #keyDatesForm="ngForm" id="keyDatesForm" name="keyDatesForm">
    <div class="card" *ngIf="loanKeyDatesGroups.length === 0">
      <div *ngFor="let keyDate of loanKeyDates; let i = index">
        <div class="card-header">
          <h4 class="card-title"> </h4>

        </div>
        <div class="card-body">
          <div class="grid-layout-row">
            <div class="col-md-4">
              <label for="keyDate_{{i}}" class="form-label"> {{keyDate.displayName}} </label>
              <input type="date" autocomplete="off" class="form-control" id="keyDate_{{i}}" name="keyDate_{{i}}"
                [(ngModel)]="keyDate.eventDate"
                [disabled]="keyDate.editByRole == null || keyDate.editByRole.indexOf(userRoleId) === -1">
            </div>
            <div class="col-md-4">
              <button class="btn btn-secondary" (click)="onShowLoanKeyDatesHistoryClicked(keyDate.keyDateId)"
                [disabled]="!keyDate.keyDateId">
                Show History
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6" *ngIf="loanKeyDatesGroups.length > 0 && visibleFirstColumn">
        <ng-container class="col-md-5" *ngFor="let group of loanKeyDatesGroups | filter: { 'column': 1 } | sort: 'asc':'order'">
          <div class="card col-md-12" *ngIf="group.keyDateConfigurations.length > 0"
            [ngClass]="{'min-height': group.keyDateConfigurations.length > 1}">
            <div class="card-header d-flex justify-content-between">
              <h4 class="card-title flex-grow-1"> {{group.keyDateGroupName}} </h4>
              <div>
                <button
                  [disabled]="isLoanReadOnly"
                  class="me-2"
                  [ngClass]="[keyDateGroupEditEnabledStatuses.get(group.keyDateGroupId) ? 'btn btn-sm btn-outline-primary ' : 'btn btn-sm btn-outline-secondary']"
                  (click)="onEditModeToggled(group.keyDateGroupId)">
                  <i style="margin-right: 5px;"
                    [ngClass]="[keyDateGroupEditEnabledStatuses.get(group.keyDateGroupId) ? 'fas fa-lock-open' : 'fas fa-lock']"></i>{{keyDateGroupEditEnabledStatuses.get(group.keyDateGroupId)
                  ?
                  'Save...' : 'Edit'}}
                </button>
                <button
                  class="btn btn-sm btn-outline-danger"
                  *ngIf="keyDateGroupEditEnabledStatuses.get(group.keyDateGroupId)"
                  (click)="onCancelEditClicked(group.keyDateGroupId)">
                  <i style="margin-right: 5px;"
                    class="fa fa-times"></i>
                  Cancel
                </button>
              </div>
            </div>
            <div class="ps-3 pe-3">
              <div class="grid-layout-row">
                <div *ngFor="let keyDate of group.keyDateConfigurations | sort: 'asc':'order'; let i = index;" style="margin: 5px 0">
                  <div class="col-md-12 d-flex text-center key-date-row">
                    <label [outsideClick]='true' [popover]='keyDate.displayName' [title]='keyDate.displayName'
                           class='text-start form-label margin-top-6 text-ellipsis key-date-row__name'>
                      <span class="text-center me-2 circle"
                        [ngClass]="{'circle-green': keyDate.eventDate != null, 'circle-default': keyDate.eventDate == null}">
                        <i class="fas fa-check"></i> </span>
                      {{keyDate.displayName}}
                    </label>
                    <div style="margin-left: 6px;margin-right: 15px;">
                      <date-input *ngIf="keyDateGroupEditEnabledStatuses.get(group.keyDateGroupId)" type="date"
                        autocomplete="off" id="keyDate{{i}}-{{group.keyDateGroupId}}"
                        name="keyDate{{i}}-{{group.keyDateGroupId}}" [(ngModel)]="keyDate.eventDate"
                        (blur)="onKeyDateChanged(keyDate)"
                        (selectedDate)="onKeyDateChanged(keyDate)"
                        [readonly]="keyDate.editByRole == null || keyDate.editByRole.indexOf(userRoleId) === -1">
                      </date-input>
                      <label
                        [ngClass]="[keyDateGroupEditEnabledStatuses.get(group.keyDateGroupId) ? 'display-none' : '']"
                        class="d-flex label-placement align-items-start text-start mt-2"
                        *ngIf="keyDate.eventDate == null">--</label>
                        <label class="d-flex label-placement align-items-start text-start mt-2"
                        *ngIf="!keyDateGroupEditEnabledStatuses.get(group.keyDateGroupId) && keyDate.eventDateUtc">{{keyDate.eventDateUtc | date : 'MM/dd/yyyy'}}</label>
                      <label class="d-flex label-placement align-items-start text-start mt-2"
                        *ngIf="!keyDateGroupEditEnabledStatuses.get(group.keyDateGroupId) && !keyDate.eventDateUtc">{{keyDate.eventDate | date : 'MM/dd/yyyy'}}</label>
                    </div>
                    <button
                      [ngClass]="[keyDateGroupEditEnabledStatuses.get(group.keyDateGroupId) || !keyDate.keyDateId ? 'display-none' : '']"
                      class="btn btn-link btn-view-history" title="View History"
                      (click)="onShowLoanKeyDatesHistoryClicked(keyDate.keyDateId)">
                      <i class="mdi mdi-clock-outline"></i>
                    </button>
                  </div>
                  <hr>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col-md-6" *ngIf="loanKeyDatesGroups.length > 0">
        <ng-container class="col-md-5" *ngFor="let group of loanKeyDatesGroups | filter: { 'column': 2 } | sort: 'asc':'order';">
          <div class="card col-md-12" *ngIf="group.keyDateConfigurations.length > 0">
            <div class="card-header d-flex justify-content-between">
              <h4 class="card-title flex-grow-1"> {{group.keyDateGroupName}} </h4>
              <div>
                <button
                  [disabled]="isLoanReadOnly"
                  class="me-2"
                  [ngClass]="[keyDateGroupEditEnabledStatuses.get(group.keyDateGroupId) ? 'btn btn-sm btn-outline-primary ' : 'btn btn-sm btn-outline-secondary']"
                  (click)="onEditModeToggled(group.keyDateGroupId)">
                  <i style="margin-right: 5px;"
                    [ngClass]="[keyDateGroupEditEnabledStatuses.get(group.keyDateGroupId) ? 'fas fa-lock-open' : 'fas fa-lock']"></i>{{keyDateGroupEditEnabledStatuses.get(group.keyDateGroupId)
                  ?
                  'Save...' : 'Edit'}}
                </button>
                <button
                  class="btn btn-sm btn-outline-danger"
                  *ngIf="keyDateGroupEditEnabledStatuses.get(group.keyDateGroupId)"
                  (click)="onCancelEditClicked(group.keyDateGroupId)">
                  <i style="margin-right: 5px;"
                    class="fa fa-times"></i>
                  Cancel
                </button>
              </div>
            </div>
            <div class="ps-3 pe-3">
              <div class="grid-layout-row">
                <div *ngFor="let keyDate of group.keyDateConfigurations | sort: 'asc':'order'; let i = index;" style="margin: 5px 0">
                  <div class="col-md-12 d-flex text-center key-date-row">
                    <label [outsideClick]='true' [popover]='keyDate.displayName' [title]='keyDate.displayName'
                           class='text-start form-label margin-top-6 text-ellipsis key-date-row__name'>
                      <span class="text-center me-2 circle"
                        [ngClass]="{'circle-green': keyDate.eventDate != null, 'circle-default': keyDate.eventDate == null}">
                        <i class="fas fa-check"></i> </span>
                      {{keyDate.displayName}}
                    </label>
                    <div style="margin-left: 6px;margin-right: 15px;">
                      <date-input *ngIf="keyDateGroupEditEnabledStatuses.get(group.keyDateGroupId)" type="date"
                        autocomplete="off" id="keyDate{{i}}-{{group.keyDateGroupId}}"
                        name="keyDate{{i}}-{{group.keyDateGroupId}}" [(ngModel)]="keyDate.eventDate"
                        (blur)="onKeyDateChanged(keyDate)"
                        (selectedDate)="onKeyDateChanged(keyDate)"
                        [readonly]="keyDate.editByRole == null || keyDate.editByRole.indexOf(userRoleId) === -1">
                      </date-input>
                      <label
                        [ngClass]="[keyDateGroupEditEnabledStatuses.get(group.keyDateGroupId) ? 'display-none' : '']"
                        class="d-flex label-placement align-items-start text-start mt-2"
                        *ngIf="keyDate.eventDate == null">--</label>
                      <label class="d-flex label-placement align-items-start text-start mt-2"
                        *ngIf="!keyDateGroupEditEnabledStatuses.get(group.keyDateGroupId) && keyDate.eventDateUtc">{{keyDate.eventDateUtc | date : 'MM/dd/yyyy'}}</label>
                      <label class="d-flex label-placement align-items-start text-start mt-2"
                        *ngIf="!keyDateGroupEditEnabledStatuses.get(group.keyDateGroupId) && !keyDate.eventDateUtc">{{keyDate.eventDate | date : 'MM/dd/yyyy'}}</label>
                    </div>
                    <button
                      [ngClass]="[keyDateGroupEditEnabledStatuses.get(group.keyDateGroupId) || !keyDate.keyDateId ? 'display-none' : '']"
                      class="btn btn-link btn-view-history" title="View History"
                      (click)="onShowLoanKeyDatesHistoryClicked(keyDate.keyDateId)">
                      <i class="mdi mdi-clock-outline"></i>
                    </button>
                  </div>
                  <hr>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </form>
</div>
