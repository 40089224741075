import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MakeProvider } from 'src/app/core/abstract-value-accessor';
import { BaseUrlaInputComponent } from '../base-urla-input.component';

@Component({
  selector: 'urla-text-input',
  templateUrl: 'urla-text-input.component.html',
  providers: [MakeProvider(UrlaTextInputComponent)]
})
export class UrlaTextInputComponent extends BaseUrlaInputComponent {

  @Output()
  editClickEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  copyClicked: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  pattern: string;

  @Input()
  mask: string;

  @Input()
  validation: boolean = false;

  @Input()
  hasEditButton = false;

  @Input()
  isEditableEvenIfItHasEditButton: boolean = false;

  @Input()

  @Input()
  maxlength: number;

  @Input()
  copyTitleText: string;

  @Input()
  copyButtonVisible: boolean = false;

  protected errorPreviewNotClickedYet: boolean = true;

  constructor() {
    super();
  }

  protected onErrorPreviewClicked() {
    this.errorPreviewNotClickedYet = false;
  }
}
