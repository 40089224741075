import { AfterViewInit, Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/services/notification.service';
import { AgentForTable } from '../../models/agent-for-table.model';
import { AgentsService } from '../../services/agents.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { User } from 'src/app/models/user/user.model';
import { AgentTableConfig } from './agent-table-config.model';
import { ApplicationMode, NavigationService } from 'src/app/services/navigation.service';
import { remove } from 'lodash';
import { Utils } from 'src/app/core/services/utils';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { Agent } from 'src/app/modules/app-details/models/agent.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { MultiSelect } from 'primeng/multiselect';
import { AgentsBulkAssignContactDialogComponent } from '../agents-bulk-assign-contact-dialog/agents-bulk-assign-contact-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/services/constants';
import { TagManagementService } from 'src/app/modules/admin/tag-management/services/tag-management.service';
import { Tag } from 'src/app/modules/admin/tag-management/models/tag.model';
import { firstValueFrom } from 'rxjs';
import { getErrorMessageOrDefault } from 'src/app/shared/utils/error-utils';

@Component({
  selector: 'agent-table',
  templateUrl: './agent-table.component.html',
  styleUrls: ['./agent-table.component.scss']
})
export class AgentTableComponent extends ApplicationContextBoundComponent implements OnInit, AfterViewInit {

  @Input()
  set agents(agents: AgentForTable[]) {
    this._allAgents = agents;
    this.filteredAgents = [...this._allAgents];
    this.populateAgentData();
  }

  @Input()
  agentTableConfig: AgentTableConfig;

  @Input()
  agentTypeId: number;

  @Input()
  set selectedColumns(selectedColumns: any) {
    this._selectedColumns = selectedColumns;
    if (selectedColumns) {
      this._selectedColumns.sort((a, b) => a.order - b.order);
      this._localStorageService.setItem(this._selectedColumnsLocalStorageKeyName, this.selectedColumns);
    }
  }

  @Output()
  addNewClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  editClicked: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  viewApplicationsClicked: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  viewSelectAgentTypeModal: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  selectAssociatedAgent: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  backClicked: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('dt') dt: any;

  @ViewChild('searchText') searchText: any;

  @ViewChild('mobileColumnSelector')
  mobileColumnSelector: MultiSelect;

  //agentLists: AgentList[] = [];

  tagLists: Tag[] = null;

  globalFilterFields: string[] = [];

  isSuperAdmin: boolean;

  isTpoUser: boolean;

  selectedRows: any[] = [];

  listFilterValue: string = "";

  companyName: number;

  users: User[];

  dialerEnabled: boolean = false;

  applicationMode: string;

  filteredAgents: AgentForTable[] = [];

  globalSearchString: string;

  baseAvatarUrl: string;

  tableState: any;

  get selectedColumns(): any { return this._selectedColumns; }

  private _allAgents: AgentForTable[] = [];

  private _tagsToSearchBy: EnumerationItem[] = [];
  private _selectedColumns: any;
  private _selectedColumnsLocalStorageKeyName: string = "agents-selectedColumns";
  private _states: EnumerationItem[];

  constructor(private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    private readonly _agentService: AgentsService,
    private readonly _tagService: TagManagementService,
    private readonly _environmentService: EnvironmentService,
    private readonly _modalService: NgbModal,
    private readonly route: ActivatedRoute,
    private readonly _navigationService: NavigationService,
    private readonly injector: Injector,
    private readonly _localStorageService: LocalStorageService) {
    super(injector);
    this.scrollOffset = 240;
    this.isSuperAdmin = this.applicationContext.userPermissions.superAdmin;
    this.companyName = this.applicationContext.userPermissions.companyId;
    this.dialerEnabled = this.applicationContext.userPermissions.dialerEnabled;
    this.users = this.applicationContext.globalConfig.users;
    this.isTpoUser = this.applicationContext.userPermissions.userType === 'TPO';
    this._states = Object.keys(this.applicationContext.globalConfig.states).map(key => new EnumerationItem(this.applicationContext.globalConfig.states[key], key));
  }

  async ngOnInit() {
    if (this.agentTableConfig?.localStorageKey) {
      this._selectedColumnsLocalStorageKeyName = this.agentTableConfig.localStorageKey;
    }
    this.getScreenSize();
    this.baseAvatarUrl = this._environmentService.apiInfo.apiBaseUrl;

    this.applicationMode = this._navigationService.applicationMode == ApplicationMode.Classic ? 'admin' :
    this._navigationService.applicationMode == ApplicationMode.NextGen ? 'loda-nextgen' : 'admin';

    this.loadColumnsToDisplayOnTable();
    this.selectedColumns.forEach(column => {
      this.globalFilterFields.push(column.field);
    });

    if (this.agentTableConfig.extraGlobalFilterFields) {
      this.agentTableConfig.extraGlobalFilterFields.forEach((field) => {
        this.globalFilterFields.push(field);
      });
    }

    //await this.populateAgentData(!!!this.tagLists);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.tableState = this._localStorageService.getItem('agents-table-state');
      this.selectedRows = [];
    });
  }

  onGlobalSearchStringChanged = () => {
    const globalSearchCache: any = {};
    globalSearchCache.globalSearchString = this.globalSearchString;
    let filteredAgents = this._allAgents;
    if (this._tagsToSearchBy) {
      filteredAgents = this.doFilterByTags(this._tagsToSearchBy, filteredAgents);
    }
    this.filteredAgents = Utils.filter(this.globalFilterFields, this.globalSearchString, filteredAgents);
    this.dt.first = 0;
  }

  populateAgentTagName = (agent:AgentForTable,  tagId: number) => {
    const tagList = this.tagLists.find(t => t.tagId == tagId);
    if (tagList && tagList.name) {
      agent.agentTagNames.push(tagList.name);
      return;
    }
    // new tag
    this._spinner.show();
    this._tagService.getTagById(tagId).subscribe((response) => {
      this.tagLists = this.tagLists.sort((a, b) => a.name.localeCompare(b.name));
      if (response && response.name) {
        agent.agentTagNames.push(response.name);
      }
      this._spinner.hide();
    }, (err) => {
      this._spinner.hide();
      this._notifyService.showError(err.message || 'Error fetching agent list!', "Error");
    });
  }

  private loadAgentTags = async () => {
    this._spinner.show();
    try {
      this.tagLists = await firstValueFrom(this._tagService.getTagList())
    } catch (err) {
      this._notifyService.showError(
        getErrorMessageOrDefault(err, {
          defaultMessage: 'Error retrieving tags.',
        }),
        'Error!'
      );
    } finally {
      this._spinner.hide();
    }
  }

  populateAgentData = async (refreshTags: boolean = true) => {
    
    if (refreshTags)
    {
      await this.loadAgentTags();
    }

    this._allAgents.forEach(agent => {
      const user = this.users.find(user => user.userCompanyGuid == agent.agentContactUserId);
      if (user) {
        agent.agentContactUserName = user.lastName + (user.lastName ? ', ' : '') + user.firstName;
      }
        agent['agentTagNames'] = [];
        if (agent.tags && agent.tags.length > 0) {
          agent.tags.forEach(tag => {
            const matchedTag = this.tagLists.find(t => t.tagId === tag.tagId);
            if (matchedTag) {
                agent['agentTagNames'].push(matchedTag.name);
            }
        });
      }
      if (agent.mailingState){
        const matchedState = this._states.find(s => s.value == agent.mailingState.toLowerCase());
        if(matchedState){
          agent["mailingStateDescription"] = matchedState.name;
        }
      }
    });
  }
  addAgent = (agent: AgentForTable) => {
    const user = this.users.find(user => user.userCompanyGuid == agent.agentContactUserId);
    if (user) {
      agent.agentContactUserName = user.lastName + (user.lastName ? ', ' : '') + user.firstName;
    }
    agent.agentTagNames = [];
    if (agent.tags && agent.tags.length > 0) {
      agent.tags.forEach(contactTrackingTag => {
        this.populateAgentTagName(agent, contactTrackingTag.tagId);
      });
    }
    this.filteredAgents.splice(0, 0, agent);
    this._allAgents.splice(0, 0, agent);
    this.filteredAgents = [...this.filteredAgents];
  }

  updateAgent = (agent: AgentForTable) => {
    const user = this.users.find(user => user.userCompanyGuid == agent.agentContactUserId);
    if (user) {
      agent.agentContactUserName = user.lastName + (user.lastName ? ', ' : '') + user.firstName;
    }
    agent.agentTagNames = [];
    if (agent.tags && agent.tags.length > 0) {
      agent.tags.forEach(contactTrackingTag => {
        this.populateAgentTagName(agent, contactTrackingTag.tagId);
      });
    }
    let index = this.filteredAgents.findIndex(a => a.agentId == agent.agentId);
    this.filteredAgents[index] = agent;
    this.filteredAgents = [...this.filteredAgents];

  }
  deleteAgent = (agentId: number) => {
    const self = this;
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to delete this Agent?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then(function (result: SweetAlertResult) {
      if (!result.value) {
        return;
      }
      self._spinner.show();
      self._agentService.deleteAgent(agentId).subscribe({
        next: () => {

          remove(self._allAgents, (agent: Agent) => agent.agentId === agentId);
          remove(self.filteredAgents, (agent: Agent) => agent.agentId === agentId);
          self.filteredAgents = [...self.filteredAgents];
          self._spinner.hide();
          self._notifyService.showSuccess('Agent deleted succesfully', 'Success');
        }, error: (err) => {
          self._spinner.hide();
          self._notifyService.showError(err?.message || 'Unable to delete agent due to some reason.', "Error");
        }
      });
    });
  }

  onAddNewClicked = () => {
    this.addNewClicked.emit();
  }

  onEditClicked = (agentId: number) => {
    this.editClicked.emit(agentId);
  }

  onViewApplicationsClicked = (agentId: number) => {
    this.viewApplicationsClicked.emit(agentId);
  }

  onAddAsClicked = (agentId: number) => {
    this.viewSelectAgentTypeModal.emit(agentId);
  }

  onAssociateClicked = (agentId: number) => {
   this.selectAssociatedAgent.emit(agentId);
  }

  onBackClicked = () => {
    this.backClicked.emit();
  }

  searchByKeyword = async (allAgents: AgentForTable[], keyword: string) => {
    this._allAgents = allAgents;
    await this.populateAgentData();
    this.filteredAgents = Utils.filter(this.globalFilterFields, keyword, this._allAgents);
  }

  searchByTags = (tags: EnumerationItem[]) => {
    this._tagsToSearchBy = tags;
    const searchString = this.globalSearchString;
    const filteredAgents = Utils.filter(this.globalFilterFields, searchString, this._allAgents);

    this.filteredAgents = this.doFilterByTags(tags, filteredAgents);
  }

  getSortedColumns = () => {
    return this.selectedColumns.sort((a, b) => a.order - b.order);
  }

  addedToDialList() {
    this.selectedRows = [];
  }

  openColumnSelectorForMobile = () => {
    setTimeout(() => {
      this.mobileColumnSelector?.containerViewChild?.nativeElement?.click();
    })
  }

  openAssignContactDialog = () => {
    let modalRef = this._modalService.open(AgentsBulkAssignContactDialogComponent, {
      ...Constants.modalOptions.medium,
      scrollable: false
    });
    let agentIds = this.selectedRows.map(row => row.agentId);
    modalRef.componentInstance.agentIds = agentIds;

    modalRef.result.then((newContactUser: User) => {
      // update contact of agents
      let allMatcheds = this.filteredAgents.filter(a => agentIds.includes(a.agentId));
      allMatcheds.forEach(agent => {
        agent.agentContactUserId = newContactUser.userCompanyGuid;
        agent.agentContactUserName = Utils.getPersonsDisplayName(newContactUser);
      });

      this.selectedRows = [];

    }, () => {
    });
  }

  private loadColumnsToDisplayOnTable = () => {
    const selectedColumns = <[]>(
      this._localStorageService.getItem(this._selectedColumnsLocalStorageKeyName)
    );

    if (!(selectedColumns && selectedColumns.length)) {
      this.selectedColumns = [];
      this.agentTableConfig.columns.forEach(column => {
        if (column.visible) {
          this.selectedColumns.push(column);
        }
      });
      this._localStorageService.setItem(this._selectedColumnsLocalStorageKeyName, this.selectedColumns);
    }
    else {
      this.selectedColumns = [];
      selectedColumns.forEach(column => {
        this.agentTableConfig.columns.forEach(c => {
          if (column['field'] === c.field) {
            this.selectedColumns.push(column);
          }
        })
      });
      this._localStorageService.setItem(this._selectedColumnsLocalStorageKeyName, this.selectedColumns);
    }
  }

  private doFilterByTags = (tags: EnumerationItem[], agentsToFilter: AgentForTable[]): AgentForTable[] => {
    
    if (tags.length === 0) {
      return agentsToFilter;
    }
    const selectedTagIds = tags.map(t => t.value);
    const filteredAgents = agentsToFilter.filter(agent => {
      if (!agent.tags || agent.tags.length === 0) {
        return false;
      }
      return agent.tags.some(agentTag => 
        selectedTagIds.includes(agentTag.tagId)
      );
    });
    return filteredAgents;
  }

  // agentsCustomSort(event: SortEvent) {
  //   Utils.primeTableCustomSort(event);
  // } 
}
