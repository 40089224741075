import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { DocumentType } from 'src/app/models';
import { Constants } from 'src/app/services/constants';
import { DocFile } from '../../models/doc-file.model';
import { DocSyncStatus, SyncDocFile } from '../../models/sync-loan-docs/sync-doc-files.model';
import { SyncLoanDoc } from '../../models/sync-loan-docs/sync-loan-doc.model';
import { SyncLodaDoc } from '../../models/sync-loan-docs/sync-loda-doc-with-los-lde.model';
import { LoanDocsService } from '../../services/loan-docs.service';
import { DocMappingForVendorDialogComponent } from '../doc-mapping-for-vendor-dialog/doc-mapping-for-vendor-dialog.component';

@Component({
    selector: 'sync-loda-los-docs-accordion',
    templateUrl: 'sync-loda-los-docs-accordion.component.html',
    styleUrls: ['sync-loda-los-docs-accordion.component.scss']
})

export class SyncLodaLosDocsAccordionComponent implements OnChanges {

    @Input()
    doc: SyncLodaDoc;

    @Input()
    docTypes: DocumentType[];

    @Input()
    companyId: number;

    @Input()
    losVendor: string;

    @Input()
    set loanDoc(loanDoc: SyncLoanDoc[]) {
        this._filteredDocs = [...loanDoc];
    }

    @Input()
    docSyncList: SyncLodaDoc[];

    @Input()
    set isClickedSelectAll(isClickedSelectAll: boolean) {
        this.onSelectAllWholeDocumentsClicked(isClickedSelectAll);
    }

    @Input()
    set docFilter(filter: string) {
        this._docFilter = filter;
        if (filter !== 'All') {
            this.filteredDocs = this._filteredDocs.filter(file => file.docFiles && file.docFiles.find(doc => doc.sync === filter));
        } else {
            this.filteredDocs = [...this._filteredDocs];
        }
    }
    get docFilter(): string {
        return this._docFilter;
    }

    selectedFiles: SyncDocFile[] = [];

    filteredDocs: SyncLoanDoc[] = [];

    private _filteredDocs: SyncLoanDoc[] = [];

    private _docFilter: string;

    constructor(
        private readonly _loanDocsService: LoanDocsService,
        private readonly _spinner: NgxSpinnerService,
        private readonly _modalService: NgbModal
    ) { }

    ngOnChanges(_: SimpleChanges): void {
        this.selectedFiles = this._filteredDocs.reduce((files, doc) => {
            const docFiles = doc.docFiles.filter(({ checked }) => checked);
            if (docFiles.length > 0 && docFiles.every(({ checked }) => checked)) {
                files.push(doc as any);
            }
            files.push(...docFiles);
            return files;
        }, [] as SyncDocFile[]);
    }

    onSelectAllWholeDocumentsClicked = (isClicked: boolean) => {
        this.docSyncList.forEach(doc => doc.loanDocs = doc.loanDocs.map(
            this._mapDocsChecked.bind(this, isClicked))
        );
        this.filteredDocs = this.filteredDocs.map(this._mapDocsChecked.bind(this, isClicked));
    }

    onSelectAllByDocumentClicked = (isChecked: boolean): void => {
        const doc = this.docSyncList.find(({ docDisplayId: id }) => id === this.doc.docDisplayId);
        if (doc) {
            doc.loanDocs = doc.loanDocs.map(this._mapDocsChecked.bind(this, isChecked));
            this.filteredDocs = doc.loanDocs.filter(ld => ld.docFiles.some(file => file.sync === this.docFilter || this.docFilter === 'All')).map((doc) => doc);
        }
    }

    onToggleCheckboxClicked = (id: string, file: any, isChecked: boolean): void => {
        const doc = this.docSyncList.find(({ docDisplayId: id }) => id === this.doc.docDisplayId);
        if (doc) {
            const loanDoc = doc.loanDocs.find(ld => ld.loanDocId === file.loanDocId);
            if (loanDoc) {
                const existOne = loanDoc.docFiles.find(d => d.globalId === id);
                if (existOne) {
                    existOne.checked = isChecked;
                }
            }
        } 
    }

    viewLoanDocument = (file: DocFile) => {
        this._spinner.show();
        this._loanDocsService.viewLoanDocContent(file.guid).subscribe({
            next: (data) => {
                const blob = new Blob([data], { type: data['type'] });
                const url = window.URL.createObjectURL(blob);
                window.open(url);
            },
            error: (error) => { }
        }).add(() => this._spinner.hide())
    }

    filesLengthToRender = (docFiles: SyncDocFile[], docFilter: string) => {
        if (this.docFilter === 'All') {
            return docFiles.length;
        }
        return docFiles.filter(file => file.sync === docFilter).length;
    }

    hasNoneFilteredFile = (loanDocs: SyncLoanDoc[], docFilter: string) => {
        return !loanDocs.some(ldoc => ldoc.docFiles.some(file => file.sync === docFilter));
    }

    hasFilteredFile = (docFiles: SyncDocFile[], docFilter: string) => {
        if (docFiles) {
            return docFiles.some(file => file.sync === docFilter);
        }
    }

    isFileNotRestricted(file: SyncDocFile) {
        return file.sync !== 'Synced' && file.sync !== 'Unattached' && file.sync !== 'Deleted' && file.sync !== 'Unmapped';
    }

    hasRestrictedFile = (docFiles: SyncDocFile[], docFilter: string) => {
        if (this.isFilterNotRestricted(docFilter)) {
            return false;
        }
        return docFiles.some(file => file.sync === docFilter);
    }

    hasAllRestrictedFiles = (docFiles: SyncDocFile[]) => {
        return docFiles.every(file => file.sync === 'Synced' || file.sync === 'Unmapped' || file.sync === 'Unattached' || file.sync === 'Deleted')
    }

    onAddMappingForVendorClicked = (file: SyncLoanDoc) => {
        const modalRef = this._modalService.open(DocMappingForVendorDialogComponent, Constants.modalOptions.medium)
        modalRef.componentInstance.title = `Add ${file.title} Mapping`;
        modalRef.componentInstance.docTypes = this.docTypes;
        modalRef.componentInstance.doc = file;
        modalRef.componentInstance.companyId = this.companyId;
        modalRef.componentInstance.losVendor = this.losVendor;
        modalRef.result.then((result) => {
            if (result === 'cancel') {
                return;
            }
            result['loanDocDisplayId'] = file.loanDocDisplayId;
            result.docFiles.forEach((file: SyncDocFile) => {
                file.sync = DocSyncStatus.PullFromLos;
                file.disabled = false;
                file.doSync = false;
                file.checked = false;
            });
            this.updateLoanDoc(result);

        }, (res) => {
        });
    }

    isFilterNotRestricted = (docFilter: string) => {
        return docFilter !== 'Synced' && docFilter !== 'Unmapped' && docFilter !== 'Unattached' && docFilter !== 'Deleted';
    }

    private updateLoanDoc = (loanDoc: SyncLoanDoc) => {
        this.docSyncList.forEach(doc => {
            const index = doc.loanDocs.findIndex(lDoc => lDoc.loanDocDisplayId === loanDoc.loanDocDisplayId);
            if (index > -1) {
                doc.loanDocs[index] = loanDoc;
            }
        })
    }

    private _mapDocsChecked(checked: boolean, doc: SyncLoanDoc) {
        return {
            ...doc,
            docFiles: doc.docFiles.map(file => ({
                ...file,
                checked: (!file.disabled && (file.sync === this.docFilter || this.docFilter === 'All')) && checked
            })),
        };
    }
}
