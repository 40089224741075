import { AfterViewInit, Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { MenuItem } from 'primeng/api';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { catchError } from 'rxjs/internal/operators/catchError';
import { retry } from 'rxjs/internal/operators/retry';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { LoanService } from 'src/app/services/loan';
import { LosService } from 'src/app/services/los.service';
import { NotificationService } from 'src/app/services/notification.service';
import { LoanValidationResponse, LosPackageConditionReport, LosRequiredValuesReport } from '../../../request-models/loan-validation-response.model';
import { LosDisclosureGenJob, LosDisclosureGenOptions, LosDisclosureInfo, LosDisclosureJobStatus, LosDisclosureSendOptions, LosDisclosureStatus, LosDisclosureType } from '../../../request-models/los-disclosure-gen-job';
import { LosSetFieldRequest } from '../../../request-models/los-set-field-request';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { finalize, Subscription, tap } from 'rxjs';
import { Utils } from 'src/app/core/services/utils';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ViewMode } from 'src/app/modules/fees/loan-fees.component';

enum LosDisclosureJobTypeEnum {
  audit = "audit",
  generate = "generate",
  send = "send"
}

@Component({
  selector: 'encompass-disclosure-wizard',
  templateUrl: './encompass-disclosure-wizard.component.html',
  styleUrls: ['./encompass-disclosure-wizard.component.scss']
})
export class EncompassDisclosureWizardComponent extends ApplicationContextBoundComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('disclosuresForm') disclosuresForm: NgForm | undefined;

  @Input() hasCancelButton: boolean = false;
  @Input() credentialId: number;
  @Input() applicationId: number;
  @Input() losIdentifier: string;
  @Input() isTpoUser: boolean = false;

  @Output() canceled = new EventEmitter<any>();

  steps: MenuItem[];

  activeStepIndex: number = 0;
  nextButtonLabel: string = "Skip >";

  atFirstStep: boolean = true;
  isStepRunning: boolean = false;
  isWizardCompleted: boolean = false;
  disableNextButton: boolean = false;
  loanIsSubmitted: boolean = false;

  loadingData: boolean;
  correctingData: boolean;

  isLoanDataValid: boolean = false;
  loanValidationResponse: LoanValidationResponse = null;

  incorrectValues: LosRequiredValuesReport[] = [];
  correctedValues: { [fieldId: string]: LosSetFieldRequest } = {};
  auditPassedNoRecommendations: boolean = false;

  invalidPackageConditions: LosPackageConditionReport[] = [];

  generatedJob: LosDisclosureGenJob = null;

  disclosureTypes: EnumerationItem[] = [
    new EnumerationItem("Initial", "Initial")
    // ,new EnumerationItem("Closing", "Closing")
  ];

  lockId: string = null;

  selectedDisclosureType: LosDisclosureType = null;
  selectedTab: string = "documents";

  errors: string = null;
  showComplianceReport: boolean = false;

  jobType: LosDisclosureJobTypeEnum = null;

  validateStepSkipped: boolean = false;
  disclosuresAlreadyProcessed: boolean = false;
  disclosuresSentForManualProcessing: boolean = false;
  preventDisclosures: boolean = false;
  preventDisclosuresErrorMessage: string = '';

  sendForManualDisclosure: boolean = false;
  loanIsTbd: boolean = false;
  errorMessageContact: string = 'Please reach out to your AE if you feel you are seeing this message in error.';
  skipSyncBeforeAudit: boolean = false;

  constructor(
    private readonly injector: Injector,
    private readonly _losService: LosService,
    private readonly _router: Router,
    private readonly _sanitizer: DomSanitizer,
    private readonly _loanService: LoanService,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _notificationService: NotificationService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.steps = [
      { label: 'Validate Loan Data' },
      { label: 'Audit Loan Data' },
      { label: 'Review Loan Estimate' },
      { label: 'Send Disclosures' },
    ];

    if (this.isTpoUser) {
      if (this.applicationContext.isCompanyPRMG) {
        this.skipSyncBeforeAudit = true;
        this.errorMessageContact = 'Please contact TPOSupport@prmg.net if you feel you are seeing this message in error.';
      }

      let errorMsg = '';
      this._loanService.getKeyDatesByType(this.applicationId).subscribe(x => {
        if (x.tpoSubmission?.eventDate) {
          this.loanIsSubmitted = true;
        }

        if (x.initialDisclosureSent?.eventDate) {
          this.disclosuresAlreadyProcessed = true;
        } else if (x.tpoManualDisclosureRequested?.eventDate) {
          this.disclosuresSentForManualProcessing = true;
        } else if (this.applicationContext.currentMortgage.subjectProperty?.address1?.toLowerCase() == "tbd") {
          this.preventDisclosures = true;
          errorMsg += "Loans with a 'TBD' subject property address are not eligible for disclosures.";
          this.preventDisclosuresErrorMessage += "The following step(s) must be resolved before you can generate disclosures:<br/><br/><br/>" + errorMsg + "<br/><br/>" + this.errorMessageContact;
        } else {
          this._loanService.validatePreDisclosures(this.applicationId).subscribe(result =>{
            if (!x.ausCompleted?.eventDate
              && !result.passed) {
              this.preventDisclosures = true;
              errorMsg += "Step 2 - AUS MUST be run on the loan.<br/>";
            }
  
            if (!this.applicationContext.application.productPricing.pricingVendor) {
              this.preventDisclosures = true;
              errorMsg += "Step 3 - Pricing MUST be applied to the loan.<br/>";
            }
  
            if (!x.feeWizardRun?.eventDate) {
              this.preventDisclosures = true;
              errorMsg += "Step 4 - Fees MUST be run on the loan.<br/>";
            } else if (x.feeWizardRun.eventDate
              && this.applicationContext.application.productPricing.assignDate
              && this.applicationContext.tpoConfig.requireFeesAfterPricingAssignment
              && new Date(x.feeWizardRun.dateUpdated || x.feeWizardRun.dateInserted) < new Date(this.applicationContext.application.productPricing.assignDate)
            ) {
              this.preventDisclosures = true;
              errorMsg += "Step 4 - Fees MUST be re-run on the loan. Pricing was changed after fees were applied.<br/>";
            }
  
            if (!this.preventDisclosures) {
              this.syncLosLoanControl();
            } else {
              this.preventDisclosuresErrorMessage += "The following step(s) must be resolved before you can generate disclosures:<br/><br/><br/>" + errorMsg + "<br/><br/>" + this.errorMessageContact;
            }
          })
        }
      });
    } else {
      this.syncLosLoanControl();
    }
  }

  ngOnDestroy(): void {
    if (this.isRunning()) {
      this._losService.unlockApplication(this.credentialId, this.applicationId, this.lockId).subscribe();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setNextButtonLabel();
    });
  }

  onBackClicked = () => {
    this.navigateBackward();
  }

  onNextClicked = () => {
    this.navigateForward().then();
  }

  onCancelClicked = () => {
    if (this.hasCancelButton) {
      this.canceled.emit();
    }
    else {
      this.activeStepIndex = 0;
      this.updateCurrentStepRelatedState();
      this.ngOnInit();
    }
  }

  validateFields = (): boolean => {
    if (this.activeStepIndexWithOffset == 2) {
      if (this.disclosuresForm) {
        this.disclosuresForm.form.markAllAsTouched();
        if (!this.disclosuresForm.form.valid) {
          return false;
        }
      }
    }
    return true;
  }

  navigateForward = async () => {

    if (!this.validateFields()) {
      return;
    }

    this.errors = null;

    if (this.activeStepIndexWithOffset == 1) {
      this.selectedDisclosureType = null;
      this.generatedJob = null;

      if (
        (this.loanValidationResponse && this.thereAreInvalidFieldValues(this.loanValidationResponse)) ||
        this.incorrectValues.length
      ) {
        const isOK = await this.correctData();
        if (!isOK) {
          return;
        }
      }
      else {
        this.selectedDisclosureType = LosDisclosureType.Initial;
        this.onDisclosureTypeChanged();
      }
    }

    if (this.activeStepIndexWithOffset == 2) {
      this.generateDisclosuresJob().then();
    }

    if (this.activeStepIndexWithOffset == 3) {
      this.sendDisclosuresJob().then();
    }

    if (this.steps.length - 1 > this.activeStepIndex) {
      this.activeStepIndex++;
      this.updateCurrentStepRelatedState();
    }
  };

  navigateBackward = () => {
    if (this.activeStepIndex > 0) {
      this.activeStepIndex--;
    }

    this.errors = null;

    if (this.activeStepIndexWithOffset == 1) {
      this.validateLoan();
    }

    if (this.activeStepIndexWithOffset == 2) {
      this.selectedDisclosureType = null;
      this.generatedJob = null;
    }

    if (this.activeStepIndexWithOffset == 3) {
      this.generateDisclosuresJob().then();
    }

    this.updateCurrentStepRelatedState();
  }

  onDisclosureTypeChanged = () => {
    if (this.selectedDisclosureType) {
      this.auditLoanJob();
    }
  }

  correctAndReAudit = () => {
    this.correctData().then();
  }

  skipAndGenerate = () => {
    this.goToNextStep();
  }

  onRetryJob = (type: LosDisclosureJobTypeEnum) => {
    if (type == LosDisclosureJobTypeEnum.audit) {
      this.errors = null;
      this.auditLoanJob();
    }
    else if (type == LosDisclosureJobTypeEnum.generate) {
      this.generatedJob.jobStatus = LosDisclosureJobStatus.Retry;
      this.generatedJob.disclosureInfos.filter(x => x.status == LosDisclosureStatus.Failed).forEach(x => x.status = LosDisclosureStatus.AuditCompleted);
      this.errors = null;
      this.generateDisclosuresJob().then();
    }
    else if (type == LosDisclosureJobTypeEnum.send) {
      this.generatedJob.jobStatus = LosDisclosureJobStatus.Retry;
      this.generatedJob.disclosureInfos.filter(x => x.status == LosDisclosureStatus.Failed).forEach(x => x.status = LosDisclosureStatus.DocGenCompleted);
      this.errors = null;
      this.sendDisclosuresJob().then();
    }
  }

  onProceedToLoanSubmission = () => {
    this._router.navigate([`tpo/app-details/${this.applicationId}/submission`]);
  }

  isRunning = (): boolean => {
    return !!this.lockId;
  }

  private updateCurrentStepRelatedState = () => {
    this.atFirstStep = this.activeStepIndex === 0;
    this.setNextButtonLabel();
  }

  private setNextButtonLabel = () => {
    if (this.activeStepIndexWithOffset === 1) {
      this.nextButtonLabel = this.loanValidationResponse && this.thereAreInvalidFieldValues(this.loanValidationResponse) ? "Correct Incorrect Values" : "Next >";
    }
    else if (this.activeStepIndexWithOffset === 2) {
      this.nextButtonLabel = "Review Loan Estimate";
    }
    else if (this.activeStepIndexWithOffset === 3) {
      this.nextButtonLabel = "Send Disclosures";
    }
  }

  get activeStepIndexWithOffset() {
    return this.steps.length === 4
      ? this.activeStepIndex + 1
      : this.activeStepIndex;
  }

  private goToNextStep = () => {
    this.updateCurrentStepRelatedState();

    this.isStepRunning = true;
    setTimeout(() => {
      this.isStepRunning = false;
      this.navigateForward().then();
    }, 2000);
  }

  private validateLoan = () => {
    this.loadingData = true;
    this.isLoanDataValid = false;
    this.loanValidationResponse = null;
    this.invalidPackageConditions = [];
    this.errors = null;
    this.isStepRunning = true;
    this.generatedJob = null;
    this.validateStepSkipped = false;
    this.correctedValues = {};

    let observable;

    if (!this.lockId) {
      observable = this._losService.lockApplication(this.credentialId, this.applicationId)
        .pipe(
          switchMap((res) => {
            this.lockId = res;
            return this._losService.validateLoanForDisclosureGen(this.credentialId, this.applicationId, this.lockId, false);
          })
        )
    }
    else {
      observable = this._losService.validateLoanForDisclosureGen(this.credentialId, this.applicationId, this.lockId, false);
    }

    observable
      .subscribe({
        next: async (res: LoanValidationResponse) => {
          this.loanValidationResponse = _.clone(res);
          //API could update the lock if flood is ran
          if (this.loanValidationResponse.updateLosLockId)
            this.lockId = this.loanValidationResponse.updateLosLockId;

          this.loadingData = false;
          this.isStepRunning = false;

          this.setNextButtonLabel();

          this.invalidPackageConditions = this.loanValidationResponse.packageConditionReports?.filter(r => !r.passed) || [];

          this.isLoanDataValid = this.allDataValid(this.loanValidationResponse);
          if (!this.isLoanDataValid) {
            if (!this.loanValidationResponse.elibilityCheckRuleStatus && !this.loanValidationResponse.packageConditionReports && !this.loanValidationResponse.planCodeConflicts && !this.loanValidationResponse.validationRulePassReports)
              this.errors = this.loanValidationResponse.errorMsg;
            if (this.thereAreHardStops(this.loanValidationResponse)) {
              if (this.lockId) {
                await firstValueFrom(this._losService.unlockApplication(this.credentialId, this.applicationId, this.lockId));
              }
              this.markFileForManualDisclosures();
            }
            else if (this.thereAreInvalidFieldValues(this.loanValidationResponse)) {
              this.collectIncorrectValues(this.loanValidationResponse);
            }
          } else if (!this.loanValidationResponse.passed) {
            this.errors = this.loanValidationResponse.errorMsg;
          } else {
            this.goToNextStep();
          }
        },
        error: (err) => {
          this.loadingData = false;
          this.isStepRunning = false;
          this.errors = err?.message || JSON.parse(err?.error)?.message || "Couldn't validate loan";
          if (this.errors.indexOf("Service Unavailable") > -1) {
            this.markFileForManualDisclosures();
          }
          this.validateStepSkipped = true;
        }
      });
  }

  private correctData = async (): Promise<boolean> => {

    let filledFields = Object.keys(this.correctedValues).filter(fieldId => !!this.correctedValues[fieldId].requestedValue).map(fieldId => this.correctedValues[fieldId]);

    if (!filledFields.length) {
      return Promise.resolve(true);
    }

    this.loadingData = true;
    this.generatedJob = null;
    this.correctingData = true;

    let isOK = await firstValueFrom(this._losService.updateIncorrectLoanFields(this.credentialId, filledFields, this.applicationId, this.lockId))
      .then(() => {

        this.loadingData = false;
        this.correctingData = false;

        if (this.activeStepIndexWithOffset == 1) {
          this.validateLoan();
        }
        else {
          this.auditLoanJob(); // re-audit
        }
        return true;
      })
      .catch((err) => {
        this.loadingData = false;
        this.correctingData = false;

        this._notificationService.showError(
          err?.message || "Couldn't correct data",
          'Disclosure'
        );
        return false;
      })

    return isOK;
  }

  private _generateProformaLeSubscription?: Subscription;
  leDocumentUrlToView: SafeResourceUrl;
  viewMode: ViewMode;
  
  private generateProformaLe(): void {
    this.loadingData = true;

    this._generateProformaLeSubscription?.unsubscribe();
    this._generateProformaLeSubscription = this._loanService
      .generateProformaLe(this.applicationId)
      .pipe(
        tap(dto => {
          const doc = dto?.documents?.[0];
          if (doc == null) {
            throw new Error('No document found in the response.');
          }

          const pdf = doc?.pdf;
          if (!pdf) {
            throw new Error('PDF is missing in the response.');
          }
          if (!pdf.content) {
            throw new Error('PDF content is missing in the response.');
          }

          const urlToViewFile = Utils.generateFileUrlFromBase64(pdf.content);
          this.leDocumentUrlToView = this._sanitizer.bypassSecurityTrustResourceUrl(urlToViewFile);
          this.viewMode = ViewMode.LeDocView;
        }),
        finalize(() => {
          this.loadingData = false;
        })
      )
      .subscribe({
        error: err => {
          this.errors = err?.message || "Error Generating Loan Estimate.";
        },
      });
  }

  private auditLoanJob = () => {
    this.jobType = LosDisclosureJobTypeEnum.audit;

    this.isStepRunning = true;
    this.loadingData = true;
    this.generatedJob = null;
    this.correctedValues = {};

    let options = new LosDisclosureGenOptions();
    options.disclosureType = this.selectedDisclosureType;
    options.losLoanId = this.losIdentifier;

    this._losService.auditDisclosures(this.credentialId, options, this.applicationId, this.lockId)
      .subscribe({
        next: async (job: LosDisclosureGenJob) => {
          this.generatedJob = job;
          this.loadingData = false;
          this.isStepRunning = false;

          if (this.generatedJob.jobStatus == LosDisclosureJobStatus.Pending) {
            await this.checkJobStatus(LosDisclosureJobTypeEnum.audit);
          }
        },
        error: (err) => {
          this.loadingData = false;
          this.isStepRunning = false;
          this.errors = err?.message || "Couldn't get audit disclosure";
        }
      })
  }

  private generateDisclosuresJob = async (): Promise<boolean> => {
    this.jobType = LosDisclosureJobTypeEnum.generate;

    this.isStepRunning = true;
    this.loadingData = true;

    const job = _.cloneDeep(this.generatedJob);

    this.generatedJob = null;
    this.errors = null;

    let isOK = await firstValueFrom(this._losService.generateDisclosures(this.credentialId, job, this.lockId))
      .then(async (job: LosDisclosureGenJob) => {
        this.generatedJob = job;

        this.loadingData = false;
        this.isStepRunning = false;

        let checkingIsOk = true;
        if (this.generatedJob.jobStatus == LosDisclosureJobStatus.Pending) {
          checkingIsOk = await this.checkJobStatus(LosDisclosureJobTypeEnum.generate);
        }

        return checkingIsOk;
      })
      .catch((err) => {
        this.loadingData = false;
        this.isStepRunning = false;

        this.errors = err?.message || "Couldn't generate disclosure";
        return false;
      });

    return isOK;
  }

  private sendDisclosuresJob = async (): Promise<boolean> => {
    this.jobType = LosDisclosureJobTypeEnum.send;

    this.isStepRunning = true;
    this.loadingData = true;
    this.errors = null;

    let job = _.cloneDeep(this.generatedJob);

    let sendOptions = new LosDisclosureSendOptions();
    sendOptions.isFulfillmentEnabled = true;
    sendOptions.daysUntilFulfillmentTriggers = 3;
    sendOptions.defaultRecipientEmail = "_";

    job.sendOptions = sendOptions;
    job.disclosureInfos.forEach(x => x.documents.forEach(y => y.downloadedContent = null));

    let isOK = await firstValueFrom(this._losService.sendDisclosures(this.credentialId, job, this.lockId))
      .then(async (res: LosDisclosureGenJob) => {
        for (var i = 0; i < res.disclosureInfos.length; i++) {
          res.disclosureInfos[i].documents = _.cloneDeep(this.generatedJob.disclosureInfos[i].documents);
        }
        this.generatedJob = res;

        this.loadingData = false;
        this.isStepRunning = false;

        let checkingIsOk = true;
        if (this.generatedJob.jobStatus == LosDisclosureJobStatus.Pending) {
          checkingIsOk = await this.checkJobStatus(LosDisclosureJobTypeEnum.send);
        }
        else {
          checkingIsOk = false;
          if (this.generatedJob.errors.indexOf("Index and length must refer to a location within the string.") > -1 || this.generatedJob.errors.indexOf('[BadRequest] packages.') > -1) {
            this.markFileForManualDisclosures();
          }
          else {
            this.errors = this.generatedJob.errors || "Send Failed. Please click retry.";
          }
        }
        return checkingIsOk;
      })
      .catch((err) => {
        this.loadingData = false;
        this.isStepRunning = false;

        this.errors = err?.message || "Couldn't send disclosure";
        return false;
      });

    return isOK;
  }

  private checkJobStatus = async (type: LosDisclosureJobTypeEnum): Promise<boolean> => {
    let stopped = false;

    this.errors = null;
    this.loadingData = true;

    let isOk = true;

    try {

      while (!stopped) {
        if (type == LosDisclosureJobTypeEnum.audit) {
          this.generatedJob = await firstValueFrom(this._losService.checkDisclosureAuditStatus(this.credentialId, this.generatedJob, this.lockId)) as LosDisclosureGenJob;
        } else if (type == LosDisclosureJobTypeEnum.generate) {
          this.generatedJob = await firstValueFrom(this._losService.checkDisclosureGenStatus(this.credentialId, this.generatedJob, this.lockId)) as LosDisclosureGenJob;
        } else if (type == LosDisclosureJobTypeEnum.send) {
          let job = _.cloneDeep(this.generatedJob);
          job.disclosureInfos.forEach(x => x.documents.forEach(y => y.downloadedContent = null));
          let res = await firstValueFrom(this._losService.checkDisclosureSendStatus(this.credentialId, job, this.lockId)) as LosDisclosureGenJob;
          for (var i = 0; i < res.disclosureInfos.length; i++) {
            res.disclosureInfos[i].documents = _.cloneDeep(this.generatedJob.disclosureInfos[i].documents);
          }
          this.generatedJob = res;
        }
        this.lockId = this.generatedJob.lockId || this.lockId;
        if (
          this.generatedJob.jobStatus != LosDisclosureJobStatus.Pending ||
          this.generatedJob.disclosureInfos.every(dis => dis.status != this.getDisclosurePendingStatus(type))
        ) {
          stopped = true;

          if (type == "send") {
            if (this.generatedJob.jobStatus == LosDisclosureJobStatus.Completed) {
              this._applicationContextService.updateLoanTasks();
              this._applicationContextService.updateApplicationTrackingStatuses();
              if (this.lockId) {
                await firstValueFrom(this._losService.unlockApplication(this.credentialId, this.applicationId, this.lockId));
                this.lockId = null;
              }

              this._losService.pullFromLos(this.applicationId).subscribe(res => {
                this._applicationContextService.updateMortgageAndApplication(res.application?.mortgageLoan, 
                  res.application, res.customData);
                this.loadingData = false;
                this.isWizardCompleted = true;
              }, e => {
                this.loadingData = false;
                this.isWizardCompleted = true;
              });
            } else {
              this.loadingData = false;
              this.errors = this.generatedJob.errors || "Send Failed. Please click retry.";
              if (this.generatedJob.errors.indexOf("Index and length must refer to a location within the string.") > -1 || this.generatedJob.errors.indexOf('[BadRequest] packages.') > -1) {
                this.markFileForManualDisclosures();
              }
              this._applicationContextService.updateApplicationTrackingStatuses();
            }

          } else if (type == "audit") {
            if (this.isTpoUser) {
              let found = false;
              this.generatedJob.disclosureInfos.forEach(disInfo => {
                disInfo.dataAuditRuleMessages?.forEach(msg => {
                  if (msg.losFieldId == 'LE1.X1') {
                    msg.severity = 'Required';
                    found = true;
                    disInfo.status = LosDisclosureStatus.Failed;
                    if (!disInfo.errors)
                      disInfo.errors = 'Fatal, business rule violations';
                  }
                });
              });
              if (found) {
                this.generatedJob.jobStatus = LosDisclosureJobStatus.Failed;
                this.generatedJob.errors = this.generatedJob.errors || "Please Save & Re-Audit";
              }
            }

            this.generatedJob.disclosureInfos.forEach(disInfo => {
              this.collectIncorrectValuesFromAuditRules(disInfo);
            });

            this.auditPassedNoRecommendations = !this.thereAreRecommendedFieldValuesInAudits(this.generatedJob) &&
              !this.thereAreRequiredFieldValuesInAudits(this.generatedJob) &&
              this.generatedJob.jobStatus != "Failed";

            this.loadingData = false;

            if (this.auditPassedNoRecommendations) {
              this.goToNextStep();
            }
          }
          else {
            this.loadingData = false;
          }
        }
        await new Promise(resolve => setTimeout(resolve, 1000)); // wait 1 sec
      }
    }
    catch (error) {
      this.errors = error.message;
      this.loadingData = false;
      isOk = false;
    }

    return isOk;
  }

  private markFileForManualDisclosures = () => {
    this.errors = "This file requires a second review validation, disclosures can't be processed automatically and have been flagged to be sent out by our Disclosure Team manually.";
    this.sendForManualDisclosure = true;
    const request = {
      "TpoManualDisclosureRequested": new Date().toISOString()
    }

    //TPO Submit loan
    this._losService.submitTpoLoan(this.credentialId, this.applicationId, this.lockId).subscribe().add(() => {
      this._loanService.saveKeyDatesByType(request, this.applicationId).subscribe(x => {
        this._losService.autoSyncLosLoan(this.applicationId, true, true).subscribe().add(() => {
          this._applicationContextService.updateApplicationTrackingStatuses();
        });
      });
    })
  }

  private thereAreHardStops = (res: LoanValidationResponse): boolean => {
    const failed = !!res.elibilityCheckRuleStatus?.filter(r => !r.passed).length;
    if (failed) {
      return res.elibilityCheckRuleStatus.filter(r => !r.passed).some(x => ['BrokerAffiliatedWithEscrow',
        'LoanIsTBD',
        'LoanTypeNotSupported',
        'UnsupportedDisclosureType',
        'LoanIsDPA',
        'ItemizationFeesIssue',
        'UnsupportedPropertyState',
        'MissingBorrowerEmails',
        'LoanHasLockVariance',
        'LoanMissingBrokerInformation',
        'LoanHasCompensationConflict',
        'LoanProgramNotSupported',
        'NmlsComplianceReviewFailed',
        'BorrowerMissingValidPhoneNumber',
        'AmortizationTypeNotSupported',
        'ManualLocksNotSupported',
        'LoanHasBuyDownOption',
        'UspsVerificationHasRun'].indexOf(x.eligibilityFaultType) > -1);
    }

    if (res.exceptionType === 'KeyNotFoundException')
      return true;

    return false;
  }

  private thereAreInvalidFieldValues = (res: LoanValidationResponse): boolean => {
    const failed = !!res.validationRulePassReports?.filter(r => !r.passed).length;
    if (failed) {
      return res.validationRulePassReports.some(r => r.incorrectValues.length);
    }
    return false;
  }

  protected thereAreRequiredFieldValuesInAudits = (res: LosDisclosureGenJob): boolean => {
    return !!res.disclosureInfos.some(i => i.dataAuditRuleMessages && i.dataAuditRuleMessages.filter(x => x.severity == 'Required').length);
  }

  protected thereAreRecommendedFieldValuesInAudits = (res: LosDisclosureGenJob): boolean => {
    return !!res.disclosureInfos.some(i => i.dataAuditRuleMessages && i.dataAuditRuleMessages.filter(x => x.severity == 'Recommended').length);
  }

  protected thereAreCorrectableFieldValues = (res: LosDisclosureGenJob): boolean => {
    return !!res.disclosureInfos.some(i => i.dataAuditRuleMessages && i.dataAuditRuleMessages.filter(x => !!x.losFieldId).length);
  }

  private allDataValid = (res: LoanValidationResponse): boolean => {
    let w = 0;
    let x = 0;
    let y = 0;
    let z = 0;

    w = res.elibilityCheckRuleStatus?.filter(x => !x.passed).length || 0;
    x = res.packageConditionReports?.filter(r => !r.passed).length || 0;
    y = res.validationRulePassReports?.filter(r => !r.passed).length || 0;
    z = res?.planCodePassed ? 0 : 1;

    return (w + x + y + z) == 0;
  }

  private collectIncorrectValues = (res: LoanValidationResponse): void => {
    let arr = [];
    res.validationRulePassReports?.forEach(r => {
      arr = arr.concat(...r.incorrectValues);
    });
    this.incorrectValues = arr;

    this.incorrectValues.forEach(val => {
      let req = new LosSetFieldRequest();
      req.fieldId = val.fieldId;
      req.format = val.format;
      req.type = val.type;
      req.requestedValue = null;

      this.correctedValues[val.fieldId] = req;
    });
  }

  private collectIncorrectValuesFromAuditRules = (res: LosDisclosureInfo): void => {
    res.dataAuditRuleMessages?.forEach(val => {
      if (val.losFieldId) {
        let req = new LosSetFieldRequest();
        req.fieldId = val.losFieldId;
        req.format = val.dataFormat;
        req.type = val.dataType;
        req.requestedValue = null;
        req.borrowerPairId = res.metadata?.borrowerPairId;

        this.correctedValues[val.losFieldId] = req;
      }
    });
  }

  private getDisclosurePendingStatus = (type: LosDisclosureJobTypeEnum) => {
    let pendingStatus: LosDisclosureStatus;

    switch (type) {
      case LosDisclosureJobTypeEnum.generate:
        pendingStatus = LosDisclosureStatus.DocGenPending;
        break;
      case LosDisclosureJobTypeEnum.send:
        pendingStatus = LosDisclosureStatus.PackageSent;
        break;
      case LosDisclosureJobTypeEnum.audit:
        pendingStatus = LosDisclosureStatus.AuditPending;
        break;
      default:
        break;
    }

    return pendingStatus;
  }

  protected syncLosLoanControl = () => {
    this.errors = null;
    if (!this.losIdentifier)
      this.errors = this.isTpoUser ? "You must import fees before you can generate disclosures." : "You must create an LOS loan before you can generate disclosures.";
    else {
      if (this.isTpoUser) {
        this.loadingData = true;
        this.isStepRunning = true;

        if (this.skipSyncBeforeAudit) {
          this.validateLoan();
        }
        else {
          this._losService.autoSyncLosLoan(this.applicationId, true, true)
            .pipe(
              retry({ count: 2, delay: 1000 }),
              catchError((err) => {
                this.errors = err?.message || "Couldn't auto sync Los loan";
                this.loadingData = false;
                this.isStepRunning = false;
                throw err;
              }))
            .subscribe(() => {
              this.validateLoan();
            });
        }
      }
      else {
        this.validateLoan();
      }
    }
  }
}
