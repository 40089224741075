import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Utils } from "src/app/core/services/utils";
import { EnumerationItem } from "src/app/models/simple-enum-item.model";
import { FilterOption } from "src/app/shared/components/generic-filter/generic-filter.component";
import { RowHeight } from "src/app/shared/models/table-config.model";
import { DrawerOptions, DrawerService, DrawerSize } from "src/app/shared/services/drawer.service";
import { Agent } from "../app-details/models/agent.model";
import { AgentTableConfig } from "./components/agent-table/agent-table-config.model";
import { AgentTableComponent } from "./components/agent-table/agent-table.component";
import { AgentForTable } from "./models/agent-for-table.model";
import { AgentsService } from "./services/agents.service";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { TagManagementService } from "../admin/tag-management/services/tag-management.service";

@Component({
  selector: 'agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.scss'],
})
export class AgentsComponent implements OnInit, OnDestroy {

  @ViewChild("agentTable")
  agentTable: AgentTableComponent;

  agents: Agent[];

  agentId: number = 0;

  tagsForFiltering: FilterOption[] = [];

  agentTableConfig: AgentTableConfig;

  addAgentEditorDrawerOpened: boolean;

  editAgentEditorDrawerOpened: boolean;

  editAgentTab: string

  addAgentsDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXLarge,
    containerWrapperId: null
  }

  editAgentsDrawerOptions: DrawerOptions = {
    size: DrawerSize.XXXLarge,
    containerWrapperId: null
  }

  private _agentUpsertedSubscription: Subscription

  constructor(
    private readonly _agentsService: AgentsService,
    private readonly _tagService: TagManagementService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _drawerService: DrawerService,
    private readonly _router: Router,
    public _datePipe: DatePipe,
  ) {
    let navigation = this._router.getCurrentNavigation();
    if (navigation && navigation.extras.state) {
      let agentInfo = navigation.extras.state;
      if(agentInfo.agentId){
        this.onEditClicked(agentInfo.agentId)
      }
    }
  }

  ngOnInit(): void {
    this.agentTableConfig = {
      columns: [
        { field: 'displayName', header: 'Name', order: 1, visible: true },
        { field: 'portalStatus', header: 'Portal Status', order: 2, visible: true },
        { field: 'contactInfo', header: 'Contact Info', order: 3, visible: true },
        { field: 'mailingState', header: 'State', order: 4, visible: true },
        { field: 'orgName', header: 'Company Name', order: 5, visible: true },
        { field: 'lastSuccessfulContact', header: 'Attempted Contact / Last Success', order: 6, visible: true },
        { field: 'assistantName', header: 'Assistant Name / Info', order: 7, visible: true },
        { field: 'agentContactUserName', header: 'Agent Contact', order: 8, visible: true },
        { field: 'agentTagNames', header: 'Tags', order: 9, visible: true },
        { field: 'dateInserted', header: 'Date Created', order: 10, visible: true },
      ],
      rowHeight: RowHeight["60px"],
      forAgent: true,
      forRemainContact: false,
      isButtonsVisible: true,
      extraGlobalFilterFields: ['fullName', 'tags', 'mailingStateDescription'],
      scrollable: true
    };

    this.getAgents();

    this._agentUpsertedSubscription =
      this._agentsService.agentsTableChanges$.subscribe(() => this.getAgents());
  }

  ngOnDestroy(): void {
    this._agentUpsertedSubscription?.unsubscribe();
  }

  onAddNewClicked = () => {
    this.editAgentTab = 'agentInfo';
    this.addAgent();
  }

  onApplicationsClicked = (agentId: number) => {
    this.editAgentTab = 'applications';
    this.editAgent(agentId);
  }

  onEditClicked = (agentId: number) => {
    this.editAgentTab = 'agentInfo';
    this.editAgent(agentId);
  }

  onAgentEditedOrUpdated = (agent: AgentForTable) => {
    agent['displayName'] = Utils.getPersonsDisplayName(agent);
    agent['fullName'] = Utils.getPersonsFullName(agent);
    //for export to csv we need the table source to contain the data. export does not work with html manipulated data
    agent['dateCreated'] = this._datePipe.transform(agent.dateInserted, 'short');
    agent['contactInfo'] = '';
    if (agent.mobilePhone) {
      agent['contactInfo'] = `M: ${agent.mobilePhone} `;
    }
    if (agent.email) {
      agent['contactInfo'] += `Email: ${agent.email}`;
    }
    if (agent.userProfile && agent.userProfile.confirmationDate) {
      agent['portalStatus'] = 'Live';
    } else if (agent.userProfile && !agent.userProfile.confirmationDate) {
      agent['portalStatus'] = 'Not Confirmed';
    } else if (!agent.userProfile) {
      agent['portalStatus'] = 'Not Invited';
    }
    if (this.agentId == 0) {
      this.agentTable.addAgent(agent);
    }
    else {
      this.agentTable.updateAgent(agent);
    }
  }

  onAgentEditorDrawerClosed = () => {
    this.addAgentEditorDrawerOpened = false;
    this._drawerService.hide("addAgentEditorDrawer", 10);
    this.editAgentEditorDrawerOpened = false;
    this._drawerService.hide("editAgentEditorDrawer", 10);
    this.editAgentTab = 'agentInfo';
  }

  onTagsFilterChanged = (tags: EnumerationItem[]) => {
    this.agentTable.searchByTags(tags);
  }

  addAgent = () => {
    this.agentId =  0;

    if (this.addAgentEditorDrawerOpened) {
      this._drawerService.hide("addAgentEditorDrawer", 10);
    }

    if (this.editAgentEditorDrawerOpened) {
      this._drawerService.hide("editAgentEditorDrawer", 10);
    }

    setTimeout(() => {
      this.addAgentEditorDrawerOpened = true;
      this._drawerService.show("addAgentEditorDrawer", 10);
    }, 100);
  }

  editAgent = (agentId: number) => {
    this.agentId = agentId;

    if (this.addAgentEditorDrawerOpened) {
      this._drawerService.hide("addAgentEditorDrawer", 10);
    }

    if (this.editAgentEditorDrawerOpened) {
      this._drawerService.hide("editAgentEditorDrawer", 10);
    }

    setTimeout(() => {
      this.editAgentEditorDrawerOpened = true;
      this._drawerService.show("editAgentEditorDrawer", 10);
    }, 100);
  }

  private loadTagsForFiltering = () => {
    this._tagService.getTagList().subscribe((response) => {
      let agentLists = response
        .filter(tag => {
          const contactListTypes = tag.restrictedToContactListTypes
            ? tag.restrictedToContactListTypes.replace(/\s/g, '').split(',')
            : [];
          return contactListTypes.includes('2');
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      this.tagsForFiltering = agentLists.map(al => {
        return new FilterOption({ 
          displayName: al.name, 
          value: al.tagId,
          groupName: "Select Tags"
        });
      });
    });
  }

  private getAgents = () => {
    this._spinner.show();
    this._agentsService.getAllAgents().subscribe({
      next: (response) => {
        this.agents = response;
        this.loadTagsForFiltering();
        this.agents.forEach(agent => {
          agent['displayName'] = Utils.getPersonsDisplayName(agent);
          agent['fullName'] = Utils.getPersonsFullName(agent);
          //for export to csv we need the table source to contain the data. export does not work with html manipulated data
          agent['dateCreated'] = this._datePipe.transform(agent.dateInserted, 'short');
          agent['contactInfo'] = '';
          if (agent.mobilePhone) {
            agent['contactInfo'] = `M: ${agent.mobilePhone} `;
          }
          if (agent.email) {
            agent['contactInfo'] += `Email: ${agent.email}`;
          }
          if (agent.userProfile && agent.userProfile.confirmationDate) {
            agent['portalStatus'] = 'Live';
          } else if (agent.userProfile && !agent.userProfile.confirmationDate) {
            agent['portalStatus'] = 'Not Confirmed';
          } else if (!agent.userProfile) {
            agent['portalStatus'] = 'Not Invited';
          }
        });
      },
      error: (err) => {

      }
    }).add(() => this._spinner.hide());
  }
}
