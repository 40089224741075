<p-table #dt1 [columns]="selectedColumns" [reorderableColumns]="true" [value]="filteredPipelineData" [paginator]="true"
  [rows]="25" [resizableColumns]="true" responsiveLayout="scroll" name="pipeline-table-v2"
  [rowsPerPageOptions]="[10, 25, 50, 100]" [globalFilterFields]="globalFilterFields" [(selection)]="selectedRows"
  [autoLayout]="true" (onSort)="customSort($event)" styleClass="p-datatable-gridlines p-datatable-sm"
  [scrollable]="true" [scrollHeight]="scrollHeight" stateStorage="local" stateKey="{{tableStateName}}" (onStateSave)="onStateSave($event)"
  sortMode="multiple" [multiSortMeta]="
    !tableState?.multiSortMeta?.length
      ? [{ field: 'appCreateDate', order: -1 }]
      : tableState.multiSortMeta
  " currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
  <ng-template pTemplate="caption">
    <div class="p-d-flex justify-content-xs-center flex-xs-wrap justify-content-between">
      <div class="btn-group mb-xs-2">
        <button *ngIf="!isTpoUser"  class="btn btn-md btn-soft-primary" (click)="filterByStatus('All')">
          All
        </button>
        <button *ngIf="!isTpoUser" class="btn btn-md btn-soft-primary" (click)="filterByStatus('Normal')">
          Normal
        </button>
        <button *ngIf="!isTpoUser" class="btn btn-md btn-soft-primary" (click)="filterByStatus('Caution')">
          Coming Due
        </button>
        <button *ngIf="!isTpoUser" class="btn btn-md btn-soft-primary" (click)="filterByStatus('Warn')">
          Overdue
        </button>
      </div>

      <!-- desktop -->
      <div *ngIf="!isTpoUser" class="hide-on-mobile">
        <button class="btn btn-primary mx-2" (click)="onCustomExportToCSV()">
          Export To CSV
        </button>
        <button *ngIf="!!_singleChannelFilter" class="btn btn-primary" (click)="onReassigContactsClicked()">
          Reassign Internal Contacts
        </button>
      </div>


      <div class="ms-xs-0 d-flex justify-content-xs-between w-xs-100">
        <!-- mobile -->
        <div class="btn-group hide-on-non-mobile">
          <button
            *ngIf="!isTpoUser"
            type="button"
            class="btn text-primary"
            title="Export To CSV"
            (click)="onCustomExportToCSV()"
          >
            <i class="fas fa-file-csv"></i>
          </button>

          <button
            *ngIf="!isTpoUser && !!_singleChannelFilter"
            type="button"
            class="btn text-primary"
            title="Reassign Internal Contacts"
            (click)="onReassigContactsClicked()"
          >
            <i class="fas fa-people-arrows"></i>
          </button>

          <button
            type="button"
            class="btn text-primary"
            data-bs-container="body"
            data-bs-toggle="popover"
            data-trigger="click"
            placement="bottom"
            (click)="openColumnSelectorForMobile()"
            [popover]="mobileColumnSelectorPopover"
            [outsideClick]="true"
            [containerClass]="'mobile-col-selector-popover'"
          >
            <i class="fa fa-cog"></i>
          </button>
          <ng-template #mobileColumnSelectorPopover>
            <p-multiSelect #mobileColumnSelector [options]="columns" [(ngModel)]="selectedColumns" optionLabel="header"
              selectedItemsLabel="{0} columns selected" name="selectedColumns" ngDefaultControl defaultLabel="Choose Columns"
              (onPanelHide)="selectedColumnsChanged()">
            </p-multiSelect>
          </ng-template>
        </div>

        <!-- desktop -->
        <p-multiSelect [options]="columns" [(ngModel)]="selectedColumns" optionLabel="header" class="hide-on-mobile"
          selectedItemsLabel="{0} columns selected" name="selectedColumns" ngDefaultControl defaultLabel="Choose Columns"
          (onPanelHide)="selectedColumnsChanged()">
        </p-multiSelect>

        <!-- desktop and mobile -->
        <span class="p-input-icon-right ms-2 ms-xs-0">
          <i class="pi pi-search"></i>
          <input #globalFilter pInputText type="text" class="form-control" placeholder="Search keyword"
            [(ngModel)]="globalSearchString" (ngModelChange)="onGlobalSearchStringChanged($event)" />
        </span>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th class="justify-content-left">
        <p-tableHeaderCheckbox *ngIf="!isTpoUser"></p-tableHeaderCheckbox>
        <span class="ms-2" *ngIf="!isTpoUser">Check All</span>
        <span class="ms-2" *ngIf="isTpoUser">Detail</span>
      </th>
      <th [ngStyle]="{'min-width': col.minWidth ? col.minWidth : null}" *ngFor="let col of columns" 
        [pSortableColumn]="col.sortField || col.field" pReorderableColumn>
        {{ col.header }}
        <p-sortIcon [field]="col.sortField || col.field" class="pull-right"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-selectedColumns="columns">
    <tr class="table-row-height--80" [ngClass]="{
        'row-warning': rowData['applicationState'] == 'Caution',
        'row-danger': rowData['applicationState'] == 'Warn'
      }">
      <td>
        <span *ngIf="!isTpoUser">
          <span>
            <p-tableCheckbox [value]="rowData"> </p-tableCheckbox>
            <span class="mx-1">|</span>
          </span>
          <a [routerLink]="[
              url + rowData.applicationId
            ]" class="text-cell-primary">{{rowData['applicationIdWithPadding']}}</a>
          <br />
          <span class="text-muted text-ellipsis cell-mw150" title="{{ rowData.loanPurposeName }} {{
              rowData.loanTypeName ? '- ' + rowData.loanTypeName : ''
            }}">{{ rowData.loanPurposeName }}
            {{ rowData.loanTypeName ? '- ' + rowData.loanTypeName : '' }}</span>
        </span>
        <span *ngIf="isTpoUser" class="d-flex align-items-left justify-content-left w-100">
          <button class="btn btn-sm btn-primary" (click)="onViewLoanDetailsClicked(rowData)">
            Detail
          </button>
        </span>
        <ng-container *ngIf="!isTpoUser && channelsEnabled && rowData['canBeOpenedInTpoPortal']">
          <br />
          <a (click)="onOpenInTpoClicked(rowData)" class="text-cell-primary">Open in TPO</a>
        </ng-container>
      </td>
      <td *ngFor="let col of selectedColumns" [ngSwitch]="col.field">
        <span *ngSwitchCase="'lastName'">
          <span class="text-cell-primary text-ellipsis cell-mw225"
            title="{{ rowData.lastName + ', ' + rowData.firstName }}">{{
            (rowData.lastName || '--') + ', ' + (rowData.firstName || '--')
            }}</span>
          <br />
          <span class="text-muted text-ellipsis cell-mw200" title="{{ rowData.mailingStreet || '--' }} {{
              rowData.mailingCity
            }}{{ rowData.mailingCity ? ',' : '' }} {{
              rowData.mailingState ? rowData.mailingState.toUpperCase() : ''
            }} {{ rowData.mailingZip }}">{{ rowData.mailingStreet || '--' }} {{ rowData.mailingCity
            }}{{ rowData.mailingCity ? ',' : '' }}
            {{
            rowData.mailingState ? rowData.mailingState.toUpperCase() : ''
            }}
            {{ rowData.mailingZip }}</span>
        </span>

        <span *ngSwitchCase="'mobilePhone'">
          <table-cell-contact-info [applicationId]="rowData.applicationId" [contactInfo]="rowData" [recordType]="'Borrower'" [smsEnabled]="!isTpoUser"
            [recordId]="rowData.primaryBorrowerId" [dialerEnabled]="dialerEnabled"></table-cell-contact-info>
        </span>

        <span *ngSwitchCase="'loanStatusName'">
          <span class="text-cell-primary text-ellipsis cell-mw200" title="{{ rowData['loanStatusName'] }} {{
              rowData['subStatusName'] && rowData['subStatusName'].length > 0
                ? '- ' + rowData['subStatusName']
                : ''
            }}">{{ rowData['loanStatusName'] }}
            {{
            rowData['subStatusName'] && rowData['subStatusName'].length > 0
            ? '- ' + rowData['subStatusName']
            : ''
            }}</span>
          <br />
          <span class="text-muted">
            {{ rowData['enteredLoanStatus'] }}
          </span>
        </span>
        <span *ngSwitchCase="'primaryRoleContact'" (click)="onShowLoanTeam($event, rowData)">
          <div class="d-flex" *ngIf="rowData.primaryRoleContact">
            <div class="img-group">
              <a class="user-avatar user-avatar-group">
                <img src="{{ baseAvatarUrl }}avatars/user-profile/{{
                    rowData.primaryRoleContactId
                  }}" onerror="this.src='assets/images/male.png'" alt="user" class="rounded-circle thumb-xs" />
              </a>
              <a class="avatar-box thumb-xs align-self-center">
                <span class="avatar-title bg-soft-info rounded-circle font-13 font-weight-normal ms-1">+{{
                  rowData.internalContactCount - 1 }}</span>
              </a>
            </div>
            <span style="padding-left: 10px" class="align-self-center text-cell-primary">{{ rowData.primaryRoleContact
              }}</span>
          </div>
        </span>
        <span *ngSwitchCase="'appCreateDate'">
          <span class="text-cell-primary">{{
            rowData.appCreateDate | date: 'short'
            }}</span>
          <br />
          <span class="text-muted">Last Updated {{ rowData.lastUpdated }}</span>
        </span>
        <span *ngSwitchCase="'lastAttemptedContact'">
          <span class="text-cell-primary"><i *ngIf="rowData.lastSuccessfulContact"
              class="fa fa-check text-success me-2"></i>{{ rowData.lastSuccessful }}</span>
          <br />
          <span class="text-muted">
            {{
            rowData.lastAttemptedContact !== rowData.lastSuccessfulContact
            ? 'Last Attempted ' + rowData.lastAttempted
            : '--'
            }}</span>
        </span>
        <span *ngSwitchCase="'interestRate'">
          <span class="text-cell-primary">{{
            rowData.interestRate ? rowData.interestRate : '--'
            }}</span>
        </span>
        <span *ngSwitchCase="'loanAmount'">
          <span class="text-cell-primary">{{
            rowData.loanAmount | currency
            }}</span>
          <br />
          <span class="text-muted">{{
            rowData.ltv ? 'LTV ' + rowData.ltv : '--'
            }}</span>
        </span>

        <span *ngSwitchCase="'leadSource'">
          <div *ngIf="
              rowData.referralSource || rowData.leadSource || rowData.leadRefId
            ">
            <span class="text-cell-primary">{{
              rowData.referralSource ? rowData.referralSource : '--'
              }}</span>
            <br />
            <span class="text-muted">{{
              rowData.leadSource ? rowData.leadSource : '--'
              }}</span>
            <br />
            <span class="text-muted">{{
              rowData.leadRefId ? 'Ref ID: ' + rowData.leadRefId : '--'
              }}</span>
          </div>
        </span>

        <span *ngSwitchCase="'lenderName'">
          <span class="text-cell-primary text-ellipsis cell-mw200" title="{{ rowData.lenderName }}{{
              rowData.channel ? ' - ' + rowData.channel : ''
            }}">{{ rowData.lenderName
            }}{{ rowData.channel ? ' - ' + rowData.channel : '' }}</span>
          <br />
          <span class="text-muted text-ellipsis cell-mw200">{{
            rowData.productName ? rowData.productName : '--'
            }}</span>
        </span>
        <span *ngSwitchCase="'lockStatus'">
          <span class="text-cell-primary">
            <i *ngIf="rowData.lockStatus && rowData.lockStatus == 'Accepted'" class="fas fa-lock me-1" style="color: orange"></i>
            <i *ngIf="rowData.lockStatus && rowData.lockStatus != 'Accepted'" class="fas fa-lock-open me-1" style="color: orange"></i>
            {{ rowData.lockStatus }}
          </span>
          <br />
          <span class="text-muted">
            {{ !rowData.lockExpirationDate ? "" : (rowData.lockExpirationDate | date: 'short') }}
          </span>
        </span>
        <span *ngSwitchCase="'docPrepFeesPaid'">
          {{ rowData['docPrepFeesPaid'] | currency}}
        </span>
        <span *ngSwitchDefault>
          <span *ngIf="!col['isCustomField'] && col.field?.includes('Date') && col.dataType != 'keyDate'">
            {{ rowData[col.field] | date: 'short' }}
          </span>

          <span *ngIf="!col['isCustomField'] && (!col.field?.includes('Date') || col.dataType == 'keyDate')">
            {{ rowData[col.field] }}
          </span>
          <span *ngIf="col['isCustomField']">
            <custom-field-editor 
              [model]="rowData['customData'][col['customDataConfig'].customDataFieldConfigId]" 
              [editConfig]="col['customDataConfig']"
              [dataType]="col['customDataConfig'].fieldType" 
              [readonly]="true" 
              [isInClassicEditMode]="false" 
              [labelVisible]="false" 
              *ngIf="rowData['customData'] && rowData['customData'][col['customDataConfig'].customDataFieldConfigId]">
            </custom-field-editor>
          </span>
        </span>
      </td>
    </tr>
  </ng-template>
</p-table>

<context-menu class="list-group shadow-lg" #contextMenuContacts style="pointer-events: all">
  <ng-container *ngIf="loanTeam">
    <ng-template contextMenuItem [subMenu]="subMenu" *ngFor="let player of loanTeam"
      class="ngcontext-menu-first-level">
      <div (mouseenter)="setCurrentLoanContact(player)" style="margin: 0px">
        <i class="fa fa-user pr-1"></i><span class="mx-2 text-cell-primary">{{ player.label }}
          <span class="text-muted mx-1 fw-normal">({{ player.role }})</span></span>
      </div>
    </ng-template>
  </ng-container>

  <ng-template *ngIf="!loanTeam" contextMenuItem>
    <loading-indicator></loading-indicator>
  </ng-template>
  <context-menu #subMenu class="shadow-lg">
    <ng-template contextMenuItem>
      <div *ngIf="currentLoanContact">
        <div style="text-align: center">
          <img [src]="currentLoanContact.user.userImage" onerror="this.src='assets/images/male.png'"
            class="rounded-circle" alt="..." width="100px" height="100px" />
          <div class="card-body text-center border-0">
            <h5 class="card-title">{{ currentLoanContact.name }}</h5>
            <p class="text-muted">{{ currentLoanContact.role }}</p>
          </div>
        </div>
        <ul>
          <li class="list-group-item text-primary" *ngIf="currentLoanContact.user.homePhone">
            <a href="tel:{{ currentLoanContact.user.homePhone }}"><i class="fa fa-phone mx-1"></i> H:
              {{
              currentLoanContact.user.homePhone
              ? currentLoanContact.user.homePhone
              : '
              - '
              }}</a>
          </li>
          <li class="list-group-item text-primary" *ngIf="currentLoanContact.user.cellPhone">
            <a href="tel:{{ currentLoanContact.user.cellPhone }}"><i class="fa fa-phone mx-1"></i>M:
              {{
              currentLoanContact.user.cellPhone
              ? currentLoanContact.user.cellPhone
              : ' -
              '
              }}</a>
          </li>
          <li class="list-group-item text-primary" *ngIf="currentLoanContact.user.email">
            <a href="mailo:{{ currentLoanContact.user.email }}"><i class="fa fa-envelope mx-1"></i>{{
              currentLoanContact.user.email }}</a>
          </li>
        </ul>
      </div>
    </ng-template>
  </context-menu>
</context-menu>
