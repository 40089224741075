import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { firstValueFrom, forkJoin, Subscription } from 'rxjs';
import { CreditVendorEnum, SystemLevel, ThirdPartyCredentialType } from 'src/app/models';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { LoanServicesService } from 'src/app/services/loan';
import { NotificationService } from 'src/app/services/notification.service';
import { getErrorMessageOrDefault } from 'src/app/shared/utils/error-utils';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreditTechnicalAffiliate } from 'src/app/models/credit/credit-technical-affiliate.model';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { CreditCredentialsConfigProviderFactory, DuCreditProvider, IProvideCreditCredentialsConfig } from './config/credit-credentials-config-provider.factory';
import { CreditCredentialsConfig } from './config/credit-credentials-config';

@Component({
  selector: 'credit-credentials-configuration',
  templateUrl: 'credit-credentials-configuration.component.html',
  styleUrls: ['./credit-credentials-configuration.component.scss'],
})
export class CreditCredentialsConfigurationComponent
  implements OnInit, OnDestroy {
  @ViewChild('createCreditCredentialsForm')
  createCreditCredentialsForm: NgForm;

  @Output()
  credentialsSaved: EventEmitter<void> = new EventEmitter<void>();

  protected searchString: string = '';

  protected showAllAvailableVendorsInDropDown: boolean = false;

  protected selectedProviderIdentifier: string = '';

  protected loading: boolean = false;

  protected saving: boolean = false;

  protected errorLoadingProviders: boolean = false;

  protected preSelectedDuCreditProviders: DuCreditProvider[] = [];

  protected allDuCreditProviders: DuCreditProvider[] = [];

  protected filteredDuCreditProviders: DuCreditProvider[] = [];

  protected userName: string = '';

  protected password: string = '';

  protected clientId: string = '';

  protected providerId: string = '';

  protected subscriberId: string = '';

  protected needsClientId: boolean = false;
  protected needsProviderId: boolean = false;
  protected needsSubscriberId: boolean = false;

  private _providerIdentifiersToShowAsButtonSelections = [
    '128',
    '78',
    '277',
    '287',
    '128',
    '308',
    '63',
    '229',
    '913',
    '912',
    '311',
    '277',
    '6',
    '299',
    '51',
    '301',
  ];

  private _contextSubscription: Subscription;

  private _userId: string;

  private _companyId: number = 0;
  private _channels: string;

  private _technicalAffiliates: CreditTechnicalAffiliate[] = null;

  private _lodaCreditProvider: string = '';
  private _lodaCreditProviderUrl: string = '';
  private _lodaEncompass_SubVendor: string = '';
  private _lodaCreditProviderTechnicalAffiliateId: number = 0;

  private _creditCredentialsConfig: CreditCredentialsConfig;
  private _creditCredentialsConfigProvider: IProvideCreditCredentialsConfig;

  private _systemLevel: SystemLevel;

  private _hasSystemLevelDuCreds: boolean = false;
  private _hasSystemLevelFannieCreds: boolean = false;

  constructor(
    private readonly _loanService: LoanServicesService,
    private readonly _notifyService: NotificationService,
    private readonly _applicationContextService: ApplicationContextService,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _creditCredentialsConfigProviderFactory: CreditCredentialsConfigProviderFactory
  ) { }

  async ngOnInit() {
    this._contextSubscription =
      this._applicationContextService.context.subscribe(async (context) => {
        this._creditCredentialsConfigProvider =
          this._creditCredentialsConfigProviderFactory.create(context);
        this._creditCredentialsConfig =
          await this._creditCredentialsConfigProvider.getCreditCredentialsConfig();

        this._userId = context.userPermissions.userId;
        this._companyId = context.userPermissions.companyId;
        this._channels = context.userPermissions.enabledChannels;
        if (context.isCompanyDeepHaven) {
          this._providerIdentifiersToShowAsButtonSelections = [];
        }
        this.loadDuProviders();
      });
  }

  ngOnDestroy(): void {
    this._contextSubscription?.unsubscribe();
  }

  protected onSearchStringChanged = () => {
    this.filteredDuCreditProviders = this.preSelectedDuCreditProviders.filter(
      (x) =>
        x.displayName.toLowerCase().includes(this.searchString.toLowerCase())
    );
  };

  protected onIDontSeeMyVendorClicked = () => {
    this.selectedProviderIdentifier = null;
    this.showAllAvailableVendorsInDropDown = true;
  };

  protected onBackToVendorButtonsViewClicked = () => {
    this.showAllAvailableVendorsInDropDown = false;
  };

  protected onCreditVendorSelectionChanged = () => { };

  protected onSaveClicked = () => {

    if (this.createCreditCredentialsForm) {
      this.createCreditCredentialsForm.form.markAllAsTouched();
      if (!this.createCreditCredentialsForm.form.valid) {
        return;
      }
    }

    const keyValuePairsForAusCredential: any[] = [];
    const keyValuePairsForCreditCredential: any[] = [{
      key: 'ExperianDefault',
      value: "true",
      companyId: this._companyId,
    }, {
      key: 'EquifaxDefault',
      value: "true",
      companyId: this._companyId,
    }, {
      key: 'TransunionDefault',
      value: "true",
      companyId: this._companyId,
    }];

    const selectedDuCreditProvider = this.allDuCreditProviders.find(
      (x) => x.providerIdentifier === this.selectedProviderIdentifier
    );
    if (selectedDuCreditProvider) {
      const keyValuePairForCreditProvider = {
        key: 'CreditProvider:' + selectedDuCreditProvider.displayName,
        value: this.userName + ',' + this.password,
        alias: selectedDuCreditProvider.displayName,
        userName: this.userName,
        password: this.password,
        companyId: this._companyId,
      };

      const keyValuePairForDefaultCreditProvider = {
        key: 'DefaultCreditProvider',
        value: selectedDuCreditProvider.displayName,
        companyId: this._companyId,
      };

      keyValuePairsForAusCredential.push(keyValuePairForCreditProvider);
      keyValuePairsForAusCredential.push(keyValuePairForDefaultCreditProvider);
    }

    let ausCredentialToCreate = null;

    if (this._hasSystemLevelDuCreds) {
      ausCredentialToCreate = {
        credentialType: ThirdPartyCredentialType.AusVendor,
        vendorName: 'DU',
        thirdPartyKeyValuePairs: keyValuePairsForAusCredential,
        alias: '',
        url: '',
        userName: null,
        password: null,
        userId: this._userId,
        companyId: this._companyId,
      };
    } else if (this._hasSystemLevelFannieCreds) {
      ausCredentialToCreate = {
        credentialType: ThirdPartyCredentialType.AUSOriginator,
        vendorName: 'FannieMae',
        thirdPartyKeyValuePairs: keyValuePairsForAusCredential,
        alias: '',
        url: '',
        userName: null,
        password: null,
        userId: this._userId,
        companyId: this._companyId,
      };
    }

    let creditCredentialToCreate = null;
    if (this._lodaCreditProvider) {
      if (this._lodaCreditProviderTechnicalAffiliateId) {
        const keyValuePairForTechnicalAffiliate = {
          key: 'TechnicalAffiliate',
          value: this._lodaCreditProviderTechnicalAffiliateId,
          companyId: this._companyId,
        };
        keyValuePairsForCreditCredential.push(
          keyValuePairForTechnicalAffiliate
        );
      }
      else if (this._lodaCreditProvider === CreditVendorEnum.EncompassCredit) {
        const keyValuePairForCreditClientId = {
          key: 'CreditVendorId',
          value: this._lodaEncompass_SubVendor,
          companyId: this._companyId,
        };
        keyValuePairsForCreditCredential.push(
          keyValuePairForCreditClientId
        );
      }

      creditCredentialToCreate = {
        credentialType: ThirdPartyCredentialType.CreditVendor,
        vendorName: this._lodaCreditProvider,
        thirdPartyKeyValuePairs: keyValuePairsForCreditCredential,
        url: this._lodaCreditProviderUrl,
        userName: this.userName,
        password: this.password,
        userId: this._userId,
        companyId: this._companyId,
        channels: this._channels,
      };
    }

    const apiCalls: any[] = [];
    if (this._creditCredentialsConfig.createDuCredentials && ausCredentialToCreate) {
      apiCalls.push(
        this._systemLevelService.saveCredential(ausCredentialToCreate)
      );
    }
    if (creditCredentialToCreate) {
      apiCalls.push(
        this._systemLevelService.saveCredential(creditCredentialToCreate)
      );
    }

    if (apiCalls.length) {
      this._spinner.show();
      this.saving = true;
      forkJoin(apiCalls)
        .subscribe(
          (results) => {
            this.showSuccessMessage();
          },
          (errors) => {
            this._notifyService.showError(
              getErrorMessageOrDefault(errors, {
                defaultMessage: 'Error saving credit credentials.',
              }),
              'Error!'
            );
          }
        )
        .add(() => {
          this.saving = false;
          this._spinner.hide();
        });
    }
  };

  protected onProviderSelected = async (provider: DuCreditProvider) => {
    this.selectedProviderIdentifier = provider.providerIdentifier;
    this.onAfterProviderIdentifierSelected(provider.providerIdentifier);
  };

  protected onAfterProviderIdentifierSelected = async (
    providerNumber: string
  ) => {
    this.selectedProviderIdentifier = providerNumber;
    this.showAllAvailableVendorsInDropDown = true;

    const creditConfig = this._creditCredentialsConfigProvider.getCreditConfig(providerNumber);

    if (creditConfig && creditConfig.lodaVendorName) {
      this._lodaCreditProvider = creditConfig.lodaVendorName;
      if (creditConfig.lodaVendorName === CreditVendorEnum.MeridianLinkHardPull) {
        if (!this._technicalAffiliates) {
          this._technicalAffiliates = await this.getTechnicalAffiliates();
          if (this._technicalAffiliates && this._technicalAffiliates.length) {
            const technicalAffiliate = this._technicalAffiliates.find(
              (a) => a.creditTechnicalAffiliateId === creditConfig.meridianLink_TechnicalAffiliateId);
            if (technicalAffiliate) {
              this._lodaCreditProviderUrl = technicalAffiliate.url;
              this._lodaCreditProviderTechnicalAffiliateId =
                technicalAffiliate.creditTechnicalAffiliateId;
            }
          }
        } else {
          const technicalAffiliate = this._technicalAffiliates.find(
            (a) => a.creditTechnicalAffiliateId === creditConfig.meridianLink_TechnicalAffiliateId);
          if (technicalAffiliate) {
            this._lodaCreditProviderUrl = technicalAffiliate.url;
            this._lodaCreditProviderTechnicalAffiliateId =
              technicalAffiliate.creditTechnicalAffiliateId;
          }
        }
      } else {
        this._lodaCreditProviderUrl = null;
        if (creditConfig.lodaVendorName === CreditVendorEnum.EncompassCredit) {
          this._lodaEncompass_SubVendor = creditConfig.encompass_SubVendor;
        } else if (creditConfig.lodaVendorName === CreditVendorEnum.Xactus) {
          this._lodaCreditProviderUrl =
            'https://www.ultraamps.com/uaweb/mismo3';
        } else if (
          creditConfig.lodaVendorName === CreditVendorEnum.ISoftPull
        ) {
          this._lodaCreditProviderUrl =
            'https://app.isoftpull.com/api/v2/reports';
        }
      }
    }

    this.needsClientId = this._lodaCreditProvider === CreditVendorEnum.EncompassCredit && creditConfig.encompass_NeedsClientId;
    this.needsProviderId = this._lodaCreditProvider === CreditVendorEnum.EncompassCredit && creditConfig.encompass_NeedsProviderId;
    this.needsSubscriberId = this._lodaCreditProvider === CreditVendorEnum.EncompassCredit && creditConfig.encompass_NeedsSubscriberId;
  };

  private getTechnicalAffiliates = async (): Promise<
    CreditTechnicalAffiliate[]
  > => {
    this._spinner.show();
    try {
      const technicalAffiliates = await firstValueFrom(
        this._loanService.getTechnicalAffiliates(
          CreditVendorEnum.MeridianLinkHardPull
        )
      );
      return technicalAffiliates.filter((a) => a.mlcid && a.code);
    } catch (error) {
      this._notifyService.showError(
        getErrorMessageOrDefault(error, {
          defaultMessage: 'Error getting credit technical affiliates.',
        }),
        'Error!'
      );
    } finally {
      this._spinner.hide();
    }
  };

  private loadDuProviders = async () => {
    try {
      this.loading = true;
      const data = await firstValueFrom(this._systemLevelService.getSystemLevel());
      this._systemLevel = JSON.parse(JSON.stringify(data));

      this._hasSystemLevelDuCreds = this._systemLevel.thirdPartyCredentials.filter(el => el.vendorName === 'DU')?.length > 0;
      this._hasSystemLevelFannieCreds = this._systemLevel.thirdPartyCredentials.filter(el => el.vendorName === 'FannieMae' &&
        el.credentialType === 'AUSOriginator')?.length > 0;

      const allDuCreditProviders =
        await this._creditCredentialsConfigProvider.getDuCreditProviders();
      this.allDuCreditProviders = _.orderBy(
        allDuCreditProviders,
        ['displayName'],
        ['asc']
      );
      var duCreditProviders = [];
      if (this._providerIdentifiersToShowAsButtonSelections.length === 0) {
        duCreditProviders = [...allDuCreditProviders];
      } else {
        this._providerIdentifiersToShowAsButtonSelections.forEach(
          (providerIdentifier: string) => {
            const provider = allDuCreditProviders.find(
              (x) => x.providerIdentifier === providerIdentifier
            );
            if (provider) {
              duCreditProviders.push({
                displayName: provider.displayName,
                providerIdentifier: provider.providerIdentifier,
              });
            }
          }
        );
      }

      this.preSelectedDuCreditProviders = _.orderBy(
        duCreditProviders,
        ['displayName'],
        ['asc']
      );

      this.filteredDuCreditProviders = [...this.preSelectedDuCreditProviders];
    } catch (error) {
      this.errorLoadingProviders = true;
      this._notifyService.showError(
        getErrorMessageOrDefault(error, {
          defaultMessage: 'Error getting credit providers.',
        }),
        'Error!'
      );
    } finally {
      this.loading = false;
    }
  };

  private showSuccessMessage = () => {
    Swal.fire(
      'Success!',
      'Your credentials have been saved successfully.',
      'success'
    ).then(() => {
      this.credentialsSaved.emit();
    });
  };
}

