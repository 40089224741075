<link rel="stylesheet" type="text/css" [href]="overrideCssUrl | safeUrl">

<div class="alert alert-light fade show mb-1 navbar-expand-lg" *ngIf="userIsImpersonating" role="alert"
  id="alert-window" style="position: relative !important; border-bottom: 2px solid rgb(46, 168, 226) !important;">
  <div class="media">
    <i style="color:rgb(46, 168, 226)" class="fas fa-diagnoses alert-icon align-self-center font-30 me-3"></i>
    <div class="media-body align-self-center">
      <span class="mb-1 fw-bold mt-0">You as an Admin are currently viewing the loan in impersonation mode.</span><br>
      <span> <a (click)="onStopImpersonatingClicked()" style="color:rgb(46, 168, 226)" target="_blank">Click Here To
          Stop Impersonating</a></span>
    </div>
  </div>
</div>

<div class="topbar" id="tpo-header">
  <div class="container container-width">
    <tpo-header></tpo-header>
  </div>
</div>

<router-outlet></router-outlet>

<div class="container-fluid footer-style">
  <div class="page-wrapper">
    <div class="row">
      <div class="col-md-12">
        <div class="text-center text-sm-start mb-0 ms-2" *ngIf="tpoConfig && tpoConfig.footerMergedHtml"
          [innerHtml]="tpoConfig.footerMergedHtml"></div>
        <div class="text-center text-sm-start mb-0 ms-2" *ngIf="!tpoConfig || !tpoConfig.footerMergedHtml">
          &copy;
          {{currentYear}} Lodasoft
        </div>
      </div>
    </div>
  </div>
</div>

<favicon-loader [faviconUrl]="faviconUrl"></favicon-loader>