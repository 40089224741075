import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "src/app/core/services/data.service";
import { Tag } from "../models/tag.model";


@Injectable()
export class TagManagementService {
  constructor(private readonly _dataService: DataService) { }

  getTagList = (): Observable<any[]> => {
    const url = "api/tag/list";
    return this._dataService.get(url);
  }

  getTagById = (tagId: number): Observable<any> => {
    const url = `api/tag/${tagId}`; 
    return this._dataService.get(url);
  }

  updateTag = (tag: any): Observable<Tag> => {
    const url = `api/tag/${tag.tagId}`;
    return this._dataService.post(url, tag);
  }

  deleteTag = (tagId: number, replaceWithTagId?: number): Observable<any> => {
    let url = `api/tag/${tagId}`;
    if (replaceWithTagId && replaceWithTagId !== 0) {
      url += `?replaceWithTagId=${replaceWithTagId}`;
    }
    return this._dataService.delete(url);
  }

  postTag = (tag: any): Observable<Tag> => {
    const url = `api/tag/`;
    return this._dataService.post(url, tag);
  }

}