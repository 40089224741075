<label class='control-label pull-left' *ngIf="label && dataType !== 'Boolean'">{{ label }}</label>

<input type="text" class="form-control" [(ngModel)]="model" [ngModelOptions]="{ standalone: true }"
       (change)='modelValueChanged()'
       *ngIf="(['None', 'String', 'Double', 'Enum', ''].includes(dataType) || dataType === undefined) && !lodasoftFullName" />

<number-input *ngIf="['Integer', 'Decimal'].includes(dataType)" [allowNegative]='true'
              [(ngModel)]='model' (change)='modelValueChanged()' [decimals]='3' [ngModelOptions]='{ standalone: true }'>
</number-input>

<currency-input *ngIf="dataType === 'Currency'" class="form-control text-left"
                [ngClass]="{'is-invalid' : currencyInputModel && currencyInputModel.touched && currencyInputModel.invalid}"
                [(ngModel)]='model' (change)="modelValueChanged()" #currencyInputModel='ngModel' [ngModelOptions]='{ standalone: true }'>
</currency-input>

<percent-input *ngIf="dataType === 'Rate'" class="form-control text-left"
              [rateIsTrueToTextValue]="true"
               [ngClass]="{'is-invalid' : percentInputModel && percentInputModel.touched && percentInputModel.invalid}" type='text'
               [(ngModel)]='model' 
               (blur)="modelValueChanged()"
               [ngModelOptions]='{ standalone: true }' #percentInputModel='ngModel'>
</percent-input>

<label *ngIf="dataType === 'Boolean'" style='margin-top: 0.75em;'>
  <input type='checkbox' [(ngModel)]='model' [ngModelOptions]='{ standalone: true }' ToggleValue [true]="'1'"
         (change)='modelValueChanged()' [false]="'0'">
  <i></i>{{ label }}
</label>

<date-input [(ngModel)]="model" (ngModelChange)="modelValueChanged()"
            *ngIf="['DateTime', 'Date'].includes(dataType)"></date-input>

<input *ngIf="dataType === 'Time'" type="time" [(ngModel)]="model" class="form-control" (ngModelChange)="modelValueChanged()" style="max-width:140px">

<input class="form-control" type="email" [(ngModel)]="model"
       (change)="modelValueChanged()"
       [ngModelOptions]='{ standalone: true }' trim *ngIf="dataType === 'Email'" />

<input class="form-control" type="text" data-mask="(999) 999-9999" [(ngModel)]="model"
       (change)='modelValueChanged()' [ngModelOptions]='{ standalone: true }'
       *ngIf="['MobilePhone', 'LandLinePhone', 'FaxPhone'].includes(dataType)">

<select class="form-select" [(ngModel)]="model" [ngModelOptions]="{ standalone: true }" *ngIf="isSingleSelect && !(lodasoftFullName || dataType === 'EntityVestingType')"
        (change)='modelValueChanged()'>
  <option value=''>-- Select One --</option>
  <option *ngFor='let item of selectData' [value]='getSelectOptionValue(item)'>
    {{ getSelectOptionName(item) }}
  </option>
</select>

<select class="form-select" [(ngModel)]="model" [ngModelOptions]="{ standalone: true }" *ngIf="isSingleSelect && (lodasoftFullName || dataType === 'EntityVestingType')"
        (change)='modelValueChanged()'>
  <option value=''>-- Select One --</option>
  <option *ngFor='let item of selectData' [value]='item.value'>
    {{ item.name }}
  </option>
</select>

<ng-select2 class="select2 mb-3 select2-multiple" *ngIf="isMultiSelect" [(ngModel)]="multiSelectModel"
            [ngModelOptions]='{ standalone: true }' [options]='optionsMultipleSelect' [data]='multiselectData'
            (valueChanged)='multiSelectValueChanged($event)'>
</ng-select2>

<textarea class="form-control" cols="30" rows="5" [(ngModel)]="model" *ngIf="dataType === 'Multiline'"
          (change)='modelValueChanged()' style='resize: none;'></textarea>
