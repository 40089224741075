<div class="card">
  <div class="card-header">
    <h4 class="card-title fee-section-header">
      <div>
        <i
          [hidden]="isValidForm"
          class="fas fa-exclamation-triangle alert-icon text-danger align-self-center me-1"
        ></i>
        A. ORIGINATION CHARGES 800. Items Payable in Connection with Loan
      </div>
      <fee-totals [fees]="fees"></fee-totals>
    </h4>
  </div>
  <div class="card-body">
    <form #originationForm="ngForm">
      <a
        class="btn btn-sm btn-soft-primary me-2"
        (click)="openAddFeeModal()"
        role="button"
      >
        <i class="fas fa-plus me-2"></i>Add Fee
      </a>

      <div class="table-responsive" [hidden]="fees.length === 0">
        <table class="table mb-0 table-centered">
          <thead>
            <tr>
              <th class="min-width-300p"></th>
              <th class="max-width-400p">Borrower Paid</th>
              <th class="max-width-400p">Seller Paid</th>
              <th class="min-width-150p">Total Fee</th>
              <th width="50px"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let fee of fees; let i = index">
              <tr>
                <td>
                  <span
                    [ngStyle]="{
                      fontWeight: fee.isSummaryFee ? 'bold' : '',
                      marginLeft:
                        !fee.isSummaryFee && fee.sumInHudNumber ? '10px' : null
                    }"
                  >
                    <span *ngIf="fee.sumInHudNumber !== null"
                      >&nbsp;&nbsp;&nbsp;</span
                    >
                    <span *ngIf="!fee.isSummaryFee" class="me-2">
                      <a
                        class="text-primary"
                        style="font-size: 0.8em"
                        (click)="onFeeEditorOpened(fee)"
                      >
                        <i class="fa fa-external-link-alt"></i>
                      </a>
                    </span>
                    <label>
                      {{ fee.hudNumber ? fee.hudNumber + ' - ' : '' }}
                      {{ fee.name }}
                    </label>
                  </span>
                </td>
                <td>
                  <div class="row">
                    <currency-input
                      class="col-md-6"
                      [id]="'of_borrowerDollar_' + fee.modelGuid"
                      [name]="'of_borrowerDollar_' + fee.modelGuid"
                      [hidden]="fee.isSummaryFee"
                      [(ngModel)]="fee.borrowerFeeDollar"
                      (change)="onDollarChanged(fee, 'borrower')"
                    ></currency-input>
                    <percent-input
                      class="col-md-6"
                      *ngIf="!!fee.feePercentOf"
                      [id]="'of_borrowerPercent_' + fee.modelGuid"
                      [name]="'of_borrowerPercent_' + fee.modelGuid"
                      [(ngModel)]="fee.borrowerFeePercent"
                      [hidden]="fee.isSummaryFee"
                      (ngModelChange)="onPercentChanged(fee, 'borrower')"
                    ></percent-input>
                  </div>
                </td>
                <td>
                  <div class="row">
                    <currency-input
                      class="col-md-6"
                      [id]="'of_sellerDollar_' + fee.modelGuid"
                      [name]="'of_sellerDollar_' + fee.modelGuid"
                      [hidden]="fee.isSummaryFee"
                      [(ngModel)]="fee.sellerFeeDollar"
                      (change)="onDollarChanged(fee, 'seller')"
                    ></currency-input>
                    <percent-input
                      class="col-md-6"
                      *ngIf="!!fee.feePercentOf"
                      [id]="'of_sellerPercent_' + fee.modelGuid"
                      [name]="'of_sellerPercent_' + fee.modelGuid"
                      [(ngModel)]="fee.sellerFeePercent"
                      [hidden]="fee.isSummaryFee"
                      (ngModelChange)="onPercentChanged(fee, 'seller')"
                    ></percent-input>
                  </div>
                </td>
                <td>
                  <div class="d-flex">
                    <currency-input
                      [id]="'of_totalFee_' + fee.modelGuid"
                      [name]="'of_totalFee_' + fee.modelGuid"
                      [(ngModel)]="fee.calculatedValues.totalFee"
                      [hidden]="fee.isSummaryFee"
                      [disabled]="true"
                    >
                    </currency-input>
                  </div>
                </td>
                <td *ngIf="!fee.isSummaryFee">
                  <a
                    class="text-danger"
                    [hidden]="pendingDeleteIndex == i"
                    (click)="pendingDeleteIndex = i"
                    ><i class="fa fa-trash-alt"></i
                  ></a>
                  <a
                    class="text-success mx-2"
                    [hidden]="pendingDeleteIndex != i"
                    (click)="removeFee(fee)"
                    ><i class="fa fa-check"></i
                  ></a>
                  <a
                    class="text-danger"
                    [hidden]="pendingDeleteIndex != i"
                    (click)="pendingDeleteIndex = -1"
                    ><i class="fa fa-times"></i
                  ></a>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </form>
  </div>
</div>

<ng-template #templateFeeSplitEditor>
  <fee-split-editor
    [fee]="selectedFeeForEditingSplit"
    (applied)="onSplitApplied($event)"
    (cancelled)="onSplitCancelled()"
  >
  </fee-split-editor>
</ng-template>
