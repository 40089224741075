import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { concatMap, from} from 'rxjs';
import { WizardUrl } from 'src/app/models';
import { MessageHistoryItem } from 'src/app/models/message.model';
import { ISaveAgentEvent } from 'src/app/modules/agent-info/agent-info.component';
import { Agent, AgentCompany, AgentFull } from 'src/app/modules/app-details/models/agent.model';
import { CorrespondenceService } from 'src/app/modules/correspondence/services/correspondence.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { copyToClipboard } from 'src/utils';
import { AgentsService } from '../../services/agents.service';

@Component({
  selector: 'upsert-agent',
  templateUrl: './upsert-agent.component.html',
  styleUrls: ['./upsert-agent.component.scss'],
})
export class UpsertAgentComponent extends ApplicationContextBoundComponent implements OnInit {

  @Input()
  agentId: number;

  @Input()
  isDrawer: boolean = false;

  @Input()
  isManualDial: boolean = true;

  @Input()
  tab?: 'agentInfo' | 'applications' | 'history' | 'notes' | 'companyInfo' = 'agentInfo'

  agentFull: AgentFull;

  loading: boolean = false;

  agentMessageHistory: MessageHistoryItem[] = null;
  currentUserIsPrimaryRoleUser: boolean = false;
  wizardUrls: WizardUrl[];

  @Output()
  closeDrawer: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onAddEditAgent: EventEmitter<Agent> = new EventEmitter<Agent>();

  @Output()
  onDialClicked = new EventEmitter<any>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly _correspondenceService: CorrespondenceService,
    private readonly _agentsService: AgentsService,
    private readonly _router: Router,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notifyService: NotificationService,
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (!this.agentId) {
      this.agentId = Number(this.route.snapshot.paramMap.get('id'));
    }

    this.loading = true;
    this._agentsService.getAgent(this.agentId).subscribe({
      next: (response) => {
        this.agentFull = response;
        this.wizardUrls = this.agentFull.onlineApplications?.wizardUrls;
      }, error: (error) => {
        const fallbackMessage = 'An error occurred while retrieving Agent';
        const message = error?.message || fallbackMessage;
        this._notifyService.showError(message, 'Error');
      }
    }).add(() => this.loading = false);
    this._correspondenceService.getMessagesSentToAgent(this.agentId).subscribe({
      next: (messageHistory) => {
        this.agentMessageHistory = messageHistory;
      },
      error: (error) => {
        const fallbackMessage = 'An error occurred while retrieving message history';
        const message = error?.message || fallbackMessage;
        console.error(fallbackMessage, error);

        this._notifyService.showError(message, 'Error');
      }
    });
    this.currentUserIsPrimaryRoleUser = this.applicationContext.globalConfig.firstRole?.roleId == this.applicationContext.userPermissions.roleId;
  }

  copyLink = (wizardUrl: WizardUrl) => {
    copyToClipboard(wizardUrl.url);
    this._notifyService.showInfo("Link copied to clipboard", "Info");
  }

  selectTab = (tab: any) => {
    this.tab = tab;
  }

  closeWindow = () => {
    if (this.isDrawer) {
      this.closeDrawer.emit();
    }
    else if (this.route.snapshot.params && this.route.snapshot.params.appId) {
      if (this.route.snapshot.params.remainContactPage === "true") {
        this._router.navigate(['admin/remain-contact/' + this.route.snapshot.params.appId]);
      } else {
        this._router.navigate(['admin/app-details/' + this.route.snapshot.params.appId]);
      }
    } else {
      this._router.navigate(['admin/agents']);
    }
  }

  onAgentCompanySaved(agentCompany: AgentCompany) {
    this.agentFull.agent.agentCompanyId = agentCompany.agentCompanyId;
    this.agentFull.agent.agentCompany = agentCompany;
  }

  savedAgent = (event: ISaveAgentEvent) => {
    from(this._spinner.show()).pipe(
      concatMap(() => this._agentsService.saveAgent(event.agent, event.suppressInvite)),
    ).subscribe({
      next: (response) => {
        this.agentFull = response;
        this._notifyService.showSuccess('Agent saved successfuly', 'Success');
        this.onUpsertAction(response, event);
      },
      error: (error) => {
        const fallbackMessage = 'An error occurred while saving Agent';
        const message = error?.message || fallbackMessage;
        console.error(fallbackMessage, error);
        this._notifyService.showError(message, 'Error');
      }
    }).add(() => this._spinner.hide());

  }

  private onUpsertAction = (response, event) => {
    this._agentsService.updateAgentsTable(response.agent); // when agents view is active and agent is upserted from other parent components
    if (this.isDrawer) {
      this.onAddEditAgent.emit(response.agent);
      this.closeDrawer.emit();
    } else if (event.isClosedAfterSave) {
      this.closeWindow();
    } else {
      this._router.navigate(['admin/agents/upsertagent/' + response.agent.agentId]);
    }
  }
}
