import {Component, EventEmitter, Injector, Input, Output, SimpleChanges} from '@angular/core';
import {Select2OptionData} from 'ng-select2';
import {GlobalConfig} from 'src/app/models/config/global-config.model';
import {ChannelService} from 'src/app/services/channel.service';
import {ApplicationContextBoundComponent} from 'src/app/shared/components';
import {CustomDataService} from '../../services/custom-data.service';
import {firstValueFrom} from 'rxjs';
import {CustomDataUtils} from '../../custom-data-utils';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { NotificationService } from 'src/app/services/notification.service';
import { AppDetailsService } from 'src/app/modules/app-details/services/app-details.service';

@Component({
  selector: 'custom-data-field',
  templateUrl: 'custom-data-field.component.html',
})
export class CustomDataFieldComponent extends ApplicationContextBoundComponent {
  @Input() dataType: string;
  @Input() model;
  @Input() operatorType?: string;
  @Input() label?: string;
  @Input() selectFieldTypes: Set<string>;
  @Input() lodasoftFullName?: string;
  @Output() valueChanged = new EventEmitter();

  lookupData: GlobalConfig;
  multiselectData: Array<Select2OptionData> = [];
  multiSelectModel = [];
  isMultiSelect: boolean;
  isSingleSelect: boolean;
  selectData = [];

  optionsMultipleSelect = {
    width: '100%',
    multiple: true,
    theme: 'classic',
    closeOnSelect: false,
  };

  constructor(
    injector: Injector,
    private readonly _customDataService: CustomDataService,
    private readonly _channelService: ChannelService,
    private readonly _enumService: EnumerationService,
    private readonly _appDetailsService: AppDetailsService,
    private readonly _notificationService: NotificationService
  ) {
    super(injector);
    this.applicationContextService.context.subscribe(res => {
      this.lookupData = res.globalConfig;
    });
  }

  modelValueChanged() {
    this.valueChanged.emit(this.model);
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (!simpleChanges.dataType && !simpleChanges.multiSelectTypes && !simpleChanges.operatorType) {
      return;
    }
    // backward compatibility
    this.replaceChannelWithString();

    const isSelectType = this.selectFieldTypes.has(this.dataType) || (!!this.lodasoftFullName && this.dataType === 'Enum') || this.dataType === 'EntityVestingType';
    const isMultiSelectOperator = CustomDataUtils.isMultiSelectOperatorType(this.operatorType);

    if (isMultiSelectOperator && isSelectType) {
      this.isMultiSelect = true;
      this.isSingleSelect = false;
      this.initMultiSelect();
    } else if (!isMultiSelectOperator && isSelectType) {
      this.isMultiSelect = false;
      this.isSingleSelect = true;
    } else {
      this.isMultiSelect = false;
      this.isSingleSelect = false;
    }

    // noinspection JSIgnoredPromiseFromCall
    if (this.lodasoftFullName) {
      this.getEnumTypes(this.lodasoftFullName);
    } 
    else if (this.dataType === 'EntityVestingType') {
      this.getEntityVestingTypes()
    } 
    else {
      this.setSelectData();
    }
    
  }

  handleTimeChange(value: string) {
    const arr = value.split(':');
    let hour = arr[0];
    const rest = arr[1];
    if (hour[1].length < 2) {
      hour = `0${hour[1]}`;
    }
    value = `${hour}:${rest}`;
  }

  multiSelectValueChanged(value?: Array<string>) {
    this.model.values = value || [];
  }

  private async setSelectData(): Promise<void> {
    this.selectData =
      this.isSingleSelect || this.isMultiSelect
        ? await firstValueFrom(
            this._customDataService.getSelectData(this.lookupData, this.dataType)
          )
        : [];

    this.multiselectData = this.isMultiSelect
      ? this.selectData?.map(item => ({
          id: this.getSelectOptionValue(item),
          text: this.getSelectOptionName(item),
        }))
      : [];
  }

  getEnumTypes = (typeName: string) => {
    this._enumService.getEnumerationsByType(typeName).subscribe({
      next: (response) => {
        this.selectData = response;
        
        this.multiselectData = this.isMultiSelect
          ? this.selectData?.map(item => ({
              id: item.value,
              text: item.name,
            }))
          : [];
      },
      error: (error) => {
        this._notificationService.showError(
          error?.message || 'Unable to load option',
          'Load Option'
        );
      }
    })
  }

  getEntityVestingTypes = () => {
    this._appDetailsService.getLookUps('EntityVestingType').subscribe({
      next: (response) => {
        this.selectData = response;
        
        this.multiselectData = this.isMultiSelect
          ? this.selectData?.map(item => ({
              id: item.value,
              text: item.name,
            }))
          : [];
      },
      error: (error) => {
        this._notificationService.showError(
          error?.message || 'Unable to load option',
          'Load Option'
        );
      }
    })
  }

  getSelectOptionValue(item) {
    return this._customDataService.getSelectOptionValue(this.dataType, item);
  }

  getSelectOptionName(item) {
    return this._customDataService.getSelectOptionName(this.dataType, item);
  }

  private initMultiSelect = () => {
    this.multiSelectModel = this.model.values;
  };

  // backwards compatibility
  private replaceChannelWithString = () => {
    if (this.dataType === 'Channel') {
      if (this.model.operatorType === 'In' || this.model.operatorType === 'NotIn') {
        this.model.tags.forEach((tag, index) => {
          if (tag && isNaN(tag)) {
            this.model.tags[index] = this._channelService.getChannelsNumber([tag]).toString();
          }
        });
        this.model.values.forEach(val => {
          if (isNaN(val)) {
            val = this._channelService.getChannelsNumber([val]).toString();
          }
        });
      } else {
        if (this.model.value && isNaN(this.model.value)) {
          this.model.value = this._channelService.getChannelsNumber([this.model.value]).toString();
        }
      }
    } else if (this.dataType === 'ChannelFlags') {
      if (this.model.value && !isNaN(this.model.value)) {
        this.model.value = this._channelService.getChannels(+this.model.value)[0]?.value;
      }
    }
  };
}
