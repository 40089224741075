export class CreateSignerViewRequest {
  loanDocTaskId: number;
  thirdPartyOrderId: string;
  name: string;
  email: string;
  thirdPartyRecipientId: string;
  returnUrl: string;
  vendorMetadata: string;
  applicationId: number;
  leadId: number;
  borrowerId: number;
}
