export class KeyDateHistoryItemViewModel {
    eventDate: string;
    eventDateUtc?: Date;
    insertedBy: string;
    dateInserted: string;

    constructor(eventDate: string, insertedBy: string, dateInserted: string, eventDateUtc?: Date) {
        this.eventDate = eventDate;
        this.eventDateUtc = eventDateUtc;
        this.insertedBy = insertedBy;
        this.dateInserted = dateInserted;
    }
}