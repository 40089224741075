import { SyncDocFile } from "./sync-doc-files.model";

export class SyncLoanDoc {
    applicationId: number;
    borrowerID: number;
    description: string;
    docFiles: SyncDocFile[];
    documentTypeId: number;
    loanDocId: number;
    losLoanDocId: string;
    note: string;
    title: string;
    loanDocDisplayId: string;
}