<div>
  <ng-multiselect-dropdown name="agentListsDropdown" [placeholder]="'-- Select --'" [settings]="multiSelectSettings"
          [data]="allAgentTags" [(ngModel)]="agentTags" (ngModelChange)="onTagsChange($event)">
  </ng-multiselect-dropdown>
</div>

<div class="input-group mt-2" *ngIf="!agentType">
  <input
    type="text"
    class="form-control"
    placeholder="New Tag"
    [(ngModel)]="newListTag"
    [ngModelOptions]="{standalone: true}"
    #model="ngModel"
  />
  
  <span class="input-group-append">
    <button
      class="btn btn-soft-primary border"
      type="button"
      [disabled]="!newListTag"
      (click)="addNewTag()"
    >
      Add New Tag
    </button>
  </span>
</div>
