import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ChangePasswordComponent} from './components/change-password/change-password.component';
import {CompanySwitchComponent} from './components/company-switch/company-switch.component';
import {EmailUnsubscribeComponent} from './components/email-unsubscribe/email-unsubscribe.component';
import {ErrorComponent} from './components/error/error.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {LoginComponent} from './components/login/login.component';
import {MicrosoftLoginRedirectComponent} from './components/microsoft-login-redirect/microsoft-login-redirect.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {SettingComponent} from './components/setting/setting.component';
import {ApplicationContextResolver} from './core/resolvers/application-context.resolver';
import {PendingChangesGuard} from './core/route-guards/pending-changes.guard';
import {LayoutAdminComponent} from './layout/admin/admin.component';
import {AmortizationComponent} from './modules/amortization/components/amortization.component';
import {ChangeOfCircumstanceComponent} from './modules/change-of-circumstance/change-of-circumstance.component';
import {ConditionsTasksComponent} from './modules/conditions-tasks/conditions-tasks.component';
import {
  DocumentESigningRequestConfirmationComponent,
} from './modules/document-signing/document-esigning-request-confirmation-component/document-esigning-request-confirmation-component';
import {
  DocumentSigningRequestConfirmationComponent,
} from './modules/document-signing/document-signing-request-confirmation/document-signing-request-confirmation.component';
import {LoanFeesComponent} from './modules/fees/loan-fees.component';
import {FileContactsComponent} from './modules/file-contacts/components/file-contacts.component';
import {InternalContactsComponent} from './modules/internal-contacts/components/internal-contacts.component';
import {KeyDatesComponent} from './modules/key-dates/components/key-dates.component';
import {LoanActivityCardComponent} from './modules/loan-activity/loan-activity-card/loan-activity-card.component';
import {AusComponent, TwnComponent, VoaComponent} from './modules/loan-services';
import {AppraisalComponent} from './modules/loan-services/appraisal/components/appraisal/appraisal.component';
import {DisclosuresWizardComponent} from './modules/loan-services/disclosure/components/disclosures-wizard/disclosures-wizard.component';
import {NextGenLayoutComponent} from './modules/loda-nextgen/layout/components/nextgen-layout.component';
import {PricingComponent, PricingHistoryTableComponent} from './modules/pricing';
import {SetupProfileComponent} from './modules/profile/components/setup-profile/setup-profile.component';
import {SubmissionComponent} from './modules/submission';
import {EscalationHistoryComponent} from './modules/tpo/components/escalation-history/escalation-history.component';
import {TpoLoanSummaryComponent} from './modules/tpo/components/loan-summary';
import {TpoAppDetailsComponent} from './modules/tpo/components/tpo-app-details/tpo-app-details.component';
import {TpoPipelineComponent} from './modules/tpo/components/tpo-pipeline/tpo-pipeline.component';
import {TpoQuickPricerComponent} from './modules/tpo/components/tpo-quick-pricer/tpo-quick-pricer.component';
import {TpoSettingsComponent} from './modules/tpo/components/tpo-settings/tpo-settings.component';
import {EmailPreferenceCenterComponent} from './shared/components/email-preference-center/email-preference-center.component';
import {CanActivateAdminRoleRoutesGuard} from './shared/route-guards/can-activate-admin-role-routes.guard';
import {CanActivateAdminRoutesGuard} from './shared/route-guards/can-activate-admin-routes.guard';
import {CanActivateTpoRoutesGuard} from './shared/route-guards/can-activate-tpo-routes.guard';
import {RemainContactComponent} from './modules/remain-contact/components/remain-contact/remain-contact.component';
import {LeadEditorComponent} from './modules/leads/components/lead-editor/lead-editor.component';
import {DocumentPreparationComponent} from './modules/app-details/document-preparation/components/document-preparation.component';
import {
  ApplicationPricingScenariosComponent,
} from './modules/pricing/components/application-pricing-scenarios/application-pricing-scenarios.component';
import {TpoLayoutComponent} from './layout/tpo/tpo-layout/tpo-layout.component';
import {TpoNewApplicationComponent} from './modules/tpo/components/tpo-new-application/tpo-new-application.component';
import {LoanCreditComponent} from './modules/loan-services/loan-credit/components/loan-credit/loan-credit.component';
import {AtrQmManagementComponent} from './modules/app-details/components/atr-qm-management/components/atr-qm-management.component';
import {LenderPriceMockComponent} from './components/lenderprice-mock/lenderprice-mock.component';
import {TpoAppraisalManagementComponent} from './modules/tpo/components/tpo-appraisal-management/tpo-appraisal-management.component';
import {TpoCdRequestComponent} from './modules/tpo/components/tpo-cd-request/tpo-cd-request.component';
import {CanActivateBroadcastManagerRoutesGuard} from './modules/broadcast-manager/can-activate-broadcast-manager-route.guard';
import {TpoManualLockComponent} from './modules/tpo/components/tpo-manual-lock/tpo-manual-lock.component';
import { ShortLinkRedirectComponent } from './components/short-link-redirect/short-link-redirect.component';
import { TpoVaPaymentComponent } from './modules/tpo/components/tpo-va-payment/tpo-va-payment.component';
import { ExternalAuthCallbackComponent } from './components/ext-auth-callback/ext-auth-callback.component';
import { EditPricingComponent } from './modules/edit-pricing/edit-pricing.component';
import { ESignViaTokenComponent } from './components/e-sign-via-token/e-sign-via-token.component';
import { ESignViaTokenConfirmationComponent } from './components/e-sign-via-token-confirmation/e-sign-via-token-confirmation.component';
import { UWDecisionComponent } from './modules/uw-decision/components/uw-decision.component';

const routes: Routes = [
  { path: 'switch-company', component: CompanySwitchComponent },
  { path: 'login', component: LoginComponent },
  { path: 'lenderprice-mock', component: LenderPriceMockComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'campaign/unsubscribe', component: EmailUnsubscribeComponent },
  { path: 'email-preference-center', component: EmailPreferenceCenterComponent },
  { path: 'sl/:shortCode', component: ShortLinkRedirectComponent},
  { path: 'ext-auth-callback', component: ExternalAuthCallbackComponent },
  {
    path: 'microsoft-login-redirect',
    component: MicrosoftLoginRedirectComponent,
  },
  {
    path: 'loda-nextgen',
    component: NextGenLayoutComponent,
    resolve: {
      applicationContext: ApplicationContextResolver,
    },
    canActivate: [],
    canActivateChild: [],
    data: {
      breadcrumb: 'Home',
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/loda-nextgen/nextgen.module').then(
            (m) => m.NextGenModule
          ),
      },
    ],
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./modules/registration/registration.module').then(
        (m) => m.RegistrationModule
      ),
  },
  {
    path: 'open-loan-in-tpo',
    loadChildren: () =>
      import('./modules/open-loan-in-tpo/open-loan-in-tpo.module').then(
        (m) => m.OpenLoanInTpoModule
      ),
  },
  {
    path: 'end-impersonation-mode',
    loadChildren: () =>
      import('./modules/end-impersonation-mode/end-impersonation-mode.module').then(
        (m) => m.EndImpersonationModeModule
      ),
  },
  {
    path: 'global-admin',
    component: LayoutAdminComponent,
    resolve: {
      applicationContext: ApplicationContextResolver,
    },
    data: {
      breadcrumb: 'Home',
    },
    canActivate: [CanActivateAdminRoutesGuard],
    canActivateChild: [CanActivateAdminRoutesGuard],
    loadChildren: () =>
      import('./modules/global-admin/global-admin.module').then(
        (m) => m.GlobalAdminModule
      ),
  },
  {
    path: 'admin',
    component: LayoutAdminComponent,
    resolve: {
      applicationContext: ApplicationContextResolver,
    },
    canActivate: [CanActivateAdminRoutesGuard],
    canActivateChild: [CanActivateAdminRoutesGuard],
    data: {
      breadcrumb: 'Home',
    },
    children: [
      {
        path: 'change-password',
        data: {
          breadcrumb: 'Change Password',
        },
        component: ChangePasswordComponent,
      },
      {
        path: 'settings',
        data: {
          breadcrumb: 'Settings',
        },
        component: SettingComponent,
      },
      { path: '', redirectTo: 'dashboardv2', pathMatch: 'full' },
      {
        path: 'quick-pricer',
        data: {
          breadcrumb: 'Quick Pricer',
        },
        loadChildren: () =>
          import('./modules/quick-pricer/quick-pricer.module').then(
            (m) => m.QuickPricerModule
          ),
      },
      {
        path: 'social-media-interactions',
        data: {
          breadcrumb: 'Social Media Interactions',
        },
        loadChildren: () =>
          import(
            './modules/social-media-interactions/social-media-interactions.module'
          ).then((m) => m.SocialMediaInteractionsModule),
      },
      {
        path: 'contact-lists',
        data: {
          breadcrumb: 'Contact Lists',
        },
        loadChildren: () =>
          import('./modules/contact-lists/contact-lists.module').then(
            (m) => m.ContactListsModule
          ),
      },
      {
        path: 'applications',
        data: {
          breadcrumb: 'My Applications',
        },
        loadChildren: () =>
          import('./modules/applications/applications.module').then(
            (m) => m.ApplicationsModule
          ),
      },
      {
        path: 'applications/new',
        data: {
          breadcrumb: 'New Application',
        },
        loadChildren: () =>
          import('./modules/new-application/new-application.module').then(
            (m) => m.NewApplicationModule
          ),
      },
      {
        path: 'app-details/:id',
        loadChildren: () =>
          import('./modules/app-details/app-details.module').then(
            (m) => m.AppDetailsModule
          ),
      },
      {
        path: 'borrowers',
        data: {
          breadcrumb: 'Borrowers',
        },
        loadChildren: () =>
          import('./modules/borrower/borrower.module').then(
            (m) => m.BorrowerModule
          ),
      },
      {
        path: 'export-documents/:id',
        data: {
          breadcrumb: 'Export Documents',
        },
        loadChildren: () =>
          import('./modules/export-loan-docs/export-loan-docs.module').then(
            (m) => m.ExportLoanDocsModule
          ),
      },
      {
        path: 'dashboard',
        data: {
          breadcrumb: 'Classic Dashboard',
        },
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'dashboardv2',
        data: {
          breadcrumb: 'Dashboard',
        },
        loadChildren: () =>
          import('./modules/dashboardv2/dashboardv2.module').then(
            (m) => m.DashboardV2Module
          ),
      },
      {
        path: 'pipeline',
        data: {
          breadcrumb: 'Pipeline',
        },
        loadChildren: () =>
          import('./modules/admin-pipeline/admin-pipeline.module').then(
            (m) => m.AdminPipelineModule
          ),
      },
      {
        path: 'profile/:id',
        data: {
          breadcrumb: 'Profile',
        },
        canActivate: [],
        loadChildren: () =>
          import('./modules/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: 'profile',
        data: {
          breadcrumb: 'Profile',
        },
        canActivate: [],
        loadChildren: () =>
          import('./modules/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: 'setup-profile',
        canDeactivate: [PendingChangesGuard],
        data: {
          breadcrumb: 'Setup Profile',
        },
        component: SetupProfileComponent,
      },
      {
        path: 'company',
        data: {
          breadcrumb: 'Company',
          isDisabled: true,
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import('./modules/admin/company/company.module').then(
            (m) => m.CompanyModule
          ),
      },
      {
        path: 'external-companies',
        data: {
          breadcrumb: 'External Companies',
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import(
            './modules/admin/external-company-management/external-company-management.module'
          ).then((m) => m.ExternalCompanyManagementModule),
      },
      {
        path: 'loan-config',
        data: {
          breadcrumb: 'Loan Config',
          isDisabled: true,
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import('./modules/admin/loan-config/loan-config.module').then(
            (m) => m.LoanConfigModule
          ),
      },
      {
        path: 'los-config',
        data: {
          breadcrumb: 'LOS Config',
          isDisabled: true,
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import('./modules/admin/los-config/los-config.module').then(
            (m) => m.LosConfigModule
          ),
      },
      {
        path: 'ocr-config',
        data: {
          breadcrumb: 'OCR Config',
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import('./modules/admin/ocr-config/ocr-config.module').then(
            (m) => m.OcrConfigModule
          ),
      },
      {
        path: 'lead-config',
        data: {
          breadcrumb: 'Lead Config',
          isDisabled: true,
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import('./modules/admin/lead-config/lead-config.module').then(
            (m) => m.LeadConfigModule
          ),
      },
      {
        path: 'short-links',
        data: {
          breadcrumb: 'Route Config',
          isDisabled: true,
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import('./modules/admin/short-links/short-links.module').then(
            (m) => m.ShortLinksModule
          ),
      },
      {
        path: 'task-config',
        data: {
          breadcrumb: 'Task Config',
          isDisabled: true,
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import('./modules/admin/task-config/task-config.module').then(
            (m) => m.TaskConfigModule
          ),
      },
      {
        path: 'contacts',
        data: {
          breadcrumb: 'Contacts',
        },
        loadChildren: () =>
          import('./modules/contacts/contacts.module').then(
            (m) => m.ContactsModule
          ),
      },
      {
        path: 'external-organizations',
        data: {
          breadcrumb: 'External Organizations',
        },
        loadChildren: () =>
          import('./modules/external-organizations/external-organizations.module').then(
            (m) => m.ExternalOrganizationsModule
          ),
      },
      {
        path: 'remain-contact/:appId',
        data: {
          breadcrumb: 'Remain Contact',
        },
        loadChildren: () =>
          import('./modules/remain-contact/remain-contact.module').then(
            (m) => m.RemainContactModule
          ),
      },
      {
        path: 'leads',
        data: {
          breadcrumb: 'Leads',
        },
        loadChildren: () =>
          import('./modules/leads/leads.module').then((m) => m.LeadsModule),
      },
      {
        path: 'dialer',
        data: {
          breadcrumb: 'Dialer',
        },
        loadChildren: () =>
          import('./modules/dialer/dialer.module').then((m) => m.DialerModule),
      },
      {
        path: 'call-history',
        data: {
          breadcrumb: 'Call History',
        },
        loadChildren: () =>
          import('./modules/call-history/call-history.module').then((m) => m.CallHistoryModule),
      },
      {
        path: 'agents',
        data: {
          breadcrumb: 'Agents',
        },
        loadChildren: () =>
          import('./modules/agents/agents.module').then((m) => m.AgentsModule),
      },
      {
        path: 'calendar',
        data: {
          breadcrumb: 'Calendar',
        },
        loadChildren: () =>
          import('./modules/events/events.module').then((m) => m.EventsModule),
      },
      {
        path: 'system-level',
        data: {
          breadcrumb: 'System Level',
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import('./modules/system-level/system-level.module').then(
            (m) => m.SystemLevelModule
          ),
      },
      {
        path: 'search',
        data: {
          breadcrumb: 'Search',
        },
        loadChildren: () =>
          import('./modules/search/search.module').then((m) => m.SearchModule),
      },
      {
        path: 'configuration-issues',
        data: {
          breadcrumb: 'Configuration Issues',
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import(
            './modules/admin/configuration-issues/configuration-issues.module'
          ).then((m) => m.ConfigurationIssuesModule),
      },
      {
        path: 'webhook-subscription',
        data: {
          breadcrumb: 'Webhook Subscriptions',
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import(
            './modules/admin/webhook-subscription/webhook-subscription.module'
          ).then((m) => m.WebhookSubscriptionModule),
      },
      {
        path: 'tag-management',
        data: {
          breadcrumb: 'Tag Management',
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import(
            './modules/admin/tag-management/tag-management.module'
          ).then((m) => m.TagManagementModule),
      },
      {
        path: 'tpo-config',
        data: {
          breadcrumb: 'TPO Config',
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import('./modules/admin/tpo-config/tpo-config.module').then(
            (m) => m.TpoConfigModule
          ),
      },
      {
        path: 'checklists',
        data: {
          breadcrumb: 'Checklists',
          isDisabled: true,
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import('./modules/admin/checklists/checklists.module').then(
            (m) => m.ChecklistsModule
          ),
      },
      {
        path: 'reporting-config',
        data: {
          breadcrumb: 'Reporting Config',
          isDisabled: true,
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import(
            './modules/admin/reporting-config/reporting-config.module'
          ).then((m) => m.ReportingConfigModule),
      },
      {
        path: 'lender-config',
        data: {
          breadcrumb: 'Lender Config',
          isDisabled: true,
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import('./modules/admin/lender-config/lender-config.module').then(
            (m) => m.LenderConfigModule
          ),
      },
      {
        path: 'solar-config',
        data: {
          breadcrumb: 'Solar Config',
          isDisabled: true,
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import('./modules/admin/solar-config/solar-config.module').then(
            (m) => m.SolarConfigModule
          ),
      },
      {
        path: 'loan-beam-config',
        data: {
          breadcrumb: 'Loan Beam Config',
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import(
            './modules/admin/loan-beam-config/loan-beam-config.module'
          ).then((m) => m.LoanBeamConfigModule),
      },
      {
        path: 'document-generation-config',
        data: {
          breadcrumb: 'Document Generation Config',
          isDisabled: true,
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import(
            './modules/admin/document-generation-config/document-generation-config.module'
          ).then((m) => m.DocumentGenerationConfigModule),
      },
      {
        path: 'document-config',
        data: {
          breadcrumb: 'Document Config',
          isDisabled: true,
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import('./modules/admin/document-config/document-config.module').then(
            (m) => m.DocumentConfigModule
          ),
      },

      {
        path: 'task-metrics',
        data: {
          breadcrumb: 'Task Metrics',
        },
        loadChildren: () =>
          import(
            './modules/reports/task-metrics/task-metrics-report.module'
          ).then((m) => m.TaskMetricsReportModule),
      },
      {
        path: 'sales-report',
        data: {
          breadcrumb: 'Sales Report',
        },
        loadChildren: () =>
          import('./modules/reports/sales-report/sales-report.module').then(
            (m) => m.SalesReportModule
          ),
      },
      {
        path: 'warm-transfer-config',
        data: {
          breadcrumb: 'Warm Transfer Config',
        },
        loadChildren: () =>
          import(
            './modules/admin/warm-transfer-config/warm-transfer-config.module'
          ).then((m) => m.WarmTransferConfigModule),
      },
      {
        path: 'pipeline-metrics',
        data: {
          breadcrumb: 'Pipeline Metrics',
        },
        loadChildren: () =>
          import(
            './modules/reports/pipeline-metrics/pipeline-metrics-report.module'
          ).then((m) => m.PipelineMetricsReportModule),
      },
      {
        path: 'pipeline-waterfall',
        data: {
          breadcrumb: 'Pipeline Waterfall',
        },
        loadChildren: () =>
          import(
            './modules/reports/pipeline-waterfall/pipeline-waterfall-report.module'
          ).then((m) => m.PipelineWaterfallReportModule),
      },
      {
        path: 'agent-dashboard',
        data: {
          breadcrumb: 'Dialer Dashboard',
        },
        loadChildren: () =>
          import(
            './modules/reports/dialer-dashboard/dialer-dashboard.module'
          ).then((m) => m.DialerDashboardModule),
      },
      {
        path: 'dialer-config',
        data: {
          breadcrumb: 'Voice / SMS Config',
          isDisabled: true,
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import('./modules/admin/dialer-config/dialer-config.module').then(
            (m) => m.DialerConfigModule
          ),
      },
      {
        path: 'agent-config',
        data: {
          breadcrumb: 'Agent Config',
          isDisabled: true,
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import('./modules/admin/agent-config/agent-config.module').then(
            (m) => m.AgentConfigModule
          ),
      },
      {
        path: 'email-campaign-config',
        data: {
          breadcrumb: 'Email Config',
          isDisabled: true,
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import(
            './modules/admin/email-campaign-config/email-campaign-config.module'
          ).then((m) => m.EmailCampaignConfigModule),
      },
      {
        path: 'reporting-dashboard',
        data: {
          breadcrumb: 'Reporting Dashboard',
        },
        loadChildren: () =>
          import(
            './modules/reports/reporting-dashboard/reporting-dashboard.module'
          ).then((m) => m.ReportingDashboardModule),
      },
      {
        path: 'kpi-dashboard',
        data: {
          breadcrumb: 'KPI Dashboard',
        },
        loadChildren: () =>
          import('./modules/reports/kpi-dashboard/kpi-dashboard.module').then(
            (m) => m.KpiDashboardModule
          ),
      },
      {
        path: 'campaigns',
        data: {
          breadcrumb: 'Campaigns',
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import('./modules/campaign/campaign.module').then(
            (m) => m.CampaignModule
          ),
      },
      {
        path: 'broadcast-manager',
        data: {
          breadcrumb: 'Broadcast Manager',
        },
        canActivate: [CanActivateBroadcastManagerRoutesGuard],
        canActivateChild: [CanActivateBroadcastManagerRoutesGuard],
        loadChildren: () =>
          import('./modules/broadcast-manager/broadcast-manager.module').then(
            (m) => m.BroadcastManagerModule
          ),
      },
      {
        path: 'mortgage-call',
        data: {
          breadcrumb: 'Mortgage Call',
        },
        loadChildren: () =>
          import(
            './modules/reports/mortgage-call/mortgage-call-report.module'
          ).then((m) => m.MortgageCallReportModule),
      },
      {
        path: 'profitability-report',
        data: {
          breadcrumb: 'Profitability Report',
        },
        canActivate: [CanActivateAdminRoleRoutesGuard],
        canActivateChild: [CanActivateAdminRoleRoutesGuard],
        loadChildren: () =>
          import(
            './modules/reports/profitability-report/profitability-report.module'
          ).then((m) => m.ProfitabilityReportModule),
      },
      {
        path: 'lead-campaign-performance',
        data: {
          breadcrumb: 'Lead Campaign Performance',
        },
        loadChildren: () =>
          import(
            './modules/reports/lead-campaign-performance/lead-campaign-performance.module'
          ).then((m) => m.LeadCampaignPerformanceModule),
      },
      {
        path: 'performance-report',
        data: {
          breadcrumb: 'Performance Report',
        },
        loadChildren: () =>
          import(
            './modules/reports/performance-report/performance-report.module'
          ).then((m) => m.PerformanceReportModule),
      },
      {
        path: 'formfree-takeownership',
        loadChildren: () =>
          import('./modules/take-ownership/take-ownership.module').then(
            (m) => m.TakeOwnershipModule
          ),
      },
      {
        path: 'esign-via-sms/:loanDocTaskId',
        loadChildren: () =>
          import('./modules/esign-email-sms/esign-email-sms.module').then(
            (m) => m.EsignEmailSmsModule
          ),
      },
      {
        path: 'esign-via-email/:loanDocTaskId',
        loadChildren: () =>
          import('./modules/esign-email-sms/esign-email-sms.module').then(
            (m) => m.EsignEmailSmsModule
          ),
      },
      {
        path: 'wizard',
        data: {
          breadcrumb: 'Wizard',
        },
        loadChildren: () =>
          import('./modules/admin/wizard/wizard.module').then(
            (m) => m.WizardModule
          ),
      },
      {
        path: 'messaging-report',
        data: {
          breadcrumb: 'Messaging report',
        },
        loadChildren: () =>
          import(
            './modules/reports/messaging-report/messaging-report.module'
          ).then((m) => m.MessagingReportModule),
      },
      {
        path: 'communications-report',
        data: {
          breadcrumb: 'Communications Report',
        },
        loadChildren: () =>
          import(
            './modules/reports/communications-report/communications-report.module'
          ).then((m) => m.CommunicationsReportModule),
      },
      {
        path: 'contact-list-details',
        data: {
          breadcrumb: 'Contact List Details',
        },
        loadChildren: () =>
          import('./modules/contact-list-details/contact-list-details.module').then((m) => m.ContactListDetailsModule),
      },
      {
        path: 'outbound-dial-report',
        data: {
          breadcrumb: 'Outbound Dial Report',
        },
        loadChildren: () =>
          import(
            './modules/reports/outbound-dial-report/outbound-dial-report.module'
          ).then((m) => m.OutboundDialReportModule),
      },
    ],
  },
  {
    path: 'tpo',
    canActivate: [CanActivateTpoRoutesGuard],
    canActivateChild: [CanActivateTpoRoutesGuard],
    data: {
      breadcrumb: 'TPO',
    },
    component: TpoLayoutComponent,
    resolve: {
      applicationContext: ApplicationContextResolver,
    },
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'settings',
        data: {
          breadcrumb: 'Settings',
        },
        component: TpoSettingsComponent,
      },
      {
        path: 'esign-via-email/:loanDocTaskId',
        loadChildren: () =>
          import('./modules/esign-email-sms/esign-email-sms.module').then(
            (m) => m.EsignEmailSmsModule
          ),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import(
            './modules/tpo/modules/tpo-dashboard/tpo-dashboard.module'
          ).then((m) => m.TpoDashboardModule),
        data: {
          breadcrumb: 'Dashboard',
        },
      },
      {
        path: 'new-application',
        component: TpoNewApplicationComponent,
        data: {
          breadcrumb: 'New Application',
        },
      },
      {
        path: 'new-lead-referral',
        component: LeadEditorComponent,
        data: {
          breadcrumb: 'New Lead Referral',
        },
      },
      {
        path: 'pipeline',
        component: TpoPipelineComponent,
        data: {
          breadcrumb: 'Pipeline',
        },
      },
      {
        path: 'quick-pricer',
        component: TpoQuickPricerComponent,
        data: {
          breadcrumb: 'Quick Pricer',
        },
      },
      {
        path: 'appraisal-management',
        component: TpoAppraisalManagementComponent,
        data: {
          breadcrumb: 'Appraisal Management',
        },
      },
      {
        path: 'va-payment',
        component: TpoVaPaymentComponent,
        data: {
          breadcrumb: 'VA Payment',
        },
      },
      {
        path: 'app-details/:id',
        component: TpoAppDetailsComponent,
        children: [
          {
            path: 'urla',
            data: {
              breadcrumb: 'URLA',
            },
            loadChildren: () =>
              import('./modules/urla/urla.module').then((m) => m.UrlaModule),
          },
          {
            path: 'credit-reports',
            component: LoanCreditComponent,
            data: {
              breadcrumb: 'Credit Reports',
            },
          },
          {
            path: 'loan-summary',
            component: TpoLoanSummaryComponent,
            data: {
              breadcrumb: 'Loan Summary',
            },
          },
          {
            path: 'digital-emp-income-verification',
            component: TwnComponent,
            data: {
              breadcrumb: 'Digital Employment Verification',
            },
          },
          {
            path: 'digital-asset-verification',
            component: VoaComponent,
            data: {
              breadcrumb: 'Digital Asset Verification',
            },
          },
          {
            path: 'aus',
            component: AusComponent,
            data: {
              breadcrumb: 'Automated Underwriting',
            },
          },
          {
            path: 'disclosures', loadChildren: () =>
              import('./modules/loan-services/run-disclosures/run-disclosures.module').then((m) => m.RunDisclosuresModule),
          },
          {
            path: 'disclosure',
            component: DisclosuresWizardComponent,
            data: {
              breadcrumb: 'Disclosures',
            },
          },
          {
            path: 'appraisal',
            component: AppraisalComponent,
            data: {
              breadcrumb: 'Appraisal',
            },
          },
          {
            path: 'cd-request',
            component: TpoCdRequestComponent,
            data: {
              breadcrumb: 'CD Request',
            },
          },
          {
            path: 'manual-lock',
            component: TpoManualLockComponent,
            data: {
              breadcrumb: 'Manual Lock',
            },
          },
          {
            path: 'conditions', component: ConditionsTasksComponent,
            data: {
              breadcrumb: 'Conditions',
            },
          },
          {
            path: 'fees',
            canDeactivate: [PendingChangesGuard],
            component: LoanFeesComponent,
            data: {
              breadcrumb: 'Fees',
            },
          },
          {
            path: 'fees/feewise-wizard/complete',
            component: LoanFeesComponent,
            data: {
              breadcrumb: 'Fees',
            },
          },
          {
            path: 'atr-qm-management',
            component: AtrQmManagementComponent,
            data: {
              breadcrumb: 'ATR / QM Management',
            },
          },
          {
            path: 'pricing',
            component: PricingComponent,
            data: {
              breadcrumb: 'Pricing',
            },
          },
          {
            path: 'edit-pricing',
            component: EditPricingComponent,
            data: {
              breadcrumb: 'Pricing',
            },
          },
          {
            path: 'pricing-history',
            component: PricingHistoryTableComponent,
            data: {
              breadcrumb: 'Pricing History',
            },
          },
          {
            path: 'application-pricing-scenarios',
            component: ApplicationPricingScenariosComponent,
            data: {
              breadcrumb: 'Pricing Scenarios',
            },
          },
          {
            path: 'amortization',
            component: AmortizationComponent,
            data: {
              breadcrumb: 'Amortization',
            },
          },
          {
            path: 'submission',
            component: SubmissionComponent,
            data: {
              breadcrumb: 'Submission',
            },
          },
          {
            path: 'uw-decision',
            component: UWDecisionComponent,
            data: {
              breadcrumb: 'UW Decision',
            },
          },
          {
            path: 'key-dates',
            component: KeyDatesComponent,
            data: {
              breadcrumb: 'Key Dates',
            },
          },
          {
            path: 'file-contacts',
            component: FileContactsComponent,
            data: {
              breadcrumb: 'File Contacts',
            },
          },
          {
            path: 'doc-preparation',
            component: DocumentPreparationComponent,
            data: {
              breadcrumb: 'Document Preparation',
            },
          },
          {
            path: 'internal-contacts',
            component: InternalContactsComponent,
            data: {
              breadcrumb: 'Internal Contacts',
            },
          },
          {
            path: 'associate-file-contact',
            component: RemainContactComponent,
          },
          {
            path: 'loan-activity',
            component: LoanActivityCardComponent,
            data: {
              breadcrumb: 'Loan Activity',
            },
          },
          {
            path: 'escalation-history',
            component: EscalationHistoryComponent,
            data: {
              breadcrumb: 'Escalation History',
            },
          },
          {
            path: 'loan-docs',
            loadChildren: () =>
              import('./modules/loan-docs/loan-docs.module').then(
                (m) => m.LoanDocsModule
              ),
            data: {
              breadcrumb: 'Loan Documents',
            },
          },
          {
            path: 'loan-docs-v2',
            loadChildren: () =>
              import('./modules/loan-docs-v2/loan-docs-v2.module').then(
                (m) => m.LoanDocsV2Module
              ),
            data: {
              breadcrumb: 'Loan Documents V2',
            },
          },

          {
            path: 'change-of-circumstance',
            component: ChangeOfCircumstanceComponent,
            data: {
              breadcrumb: 'Change of Circumstance',
            },
          },
          { path: 'amortization', component: AmortizationComponent },
          { path: '', redirectTo: 'loan-summary', pathMatch: 'full' },
          {
            path: 'export-documents',
            data: {
              breadcrumb: 'Export Documents',
            },
            loadChildren: () =>
              import('./modules/export-loan-docs/export-loan-docs.module').then(
                (m) => m.ExportLoanDocsModule
              ),
          },
        ],
      },
      {
        path: 'remain-contact/:appId',
        data: {
          breadcrumb: 'Remain Contact',
        },
        loadChildren: () =>
          import('./modules/remain-contact/remain-contact.module').then(
            (m) => m.RemainContactModule
          ),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./modules/tpo/modules/tpo-profile/tpo-profile.module').then(
            (m) => m.TpoProfileModule
          ),
        data: {
          breadcrumb: 'Profile',
        },
      }
    ],
  },
  {
    path: 'iframe-confirmation/document-signing',
    component: DocumentSigningRequestConfirmationComponent,
  },
  {
    path: 'iframe-confirmation/document-esigning',
    component: DocumentESigningRequestConfirmationComponent,
  },

  { path: 'admin/esign-via-token', component: ESignViaTokenComponent },
  { path: 'admin/esign-via-token/:loanDocTaskId', component: ESignViaTokenComponent },
  { path: 'admin/esign-confirmation/:token/:taskNumber/:allTasksCount/:orderId', component: ESignViaTokenConfirmationComponent },
  { path: 'tpo/esign-via-token', component: ESignViaTokenComponent },
  { path: 'tpo/esign-via-token/:loanDocTaskId', component: ESignViaTokenComponent },
  { path: 'tpo/esign-confirmation/:token/:taskNumber/:allTasksCount/:orderId', component: ESignViaTokenConfirmationComponent },


  { path: 'index', redirectTo: 'admin/dashboardv2' },
  { path: '', redirectTo: 'admin/dashboardv2', pathMatch: 'full' },
  { path: '**', component: LoginComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
