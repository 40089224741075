import { Component, Injector, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { chain } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs';
import { ThirdPartyCredential, ThirdPartyKeyValue, ThirdPartyKeyValueArray } from 'src/app/models';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { NotificationService } from 'src/app/services/notification.service';
import { SystemLevelService } from 'src/app/services/system-level.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';

const EMAIL_OVERRIDE_KEY = 'EmailOverride';

@Component({
  selector: 'loanpass-profile-vendor-editor-dialog',
  templateUrl: 'loanpass-profile-vendor-editor-dialog.component.html',
  styleUrls: ['./loanpass-profile-vendor-editor-dialog.component.scss'],
})
export class LoanPassProfileVendorEditorDialogComponent extends ApplicationContextBoundComponent {

  @Input()
  inEditMode: boolean = true;

  @Input()
  userCompanyGuid: string;

  @Input()
  branchId?: number;

  @Input()
  scope: 'User' | 'Branch' | 'TpoUser' | 'ExternalCompanyBranch';

  @Input()
  availableBusinessChannels: EnumerationItem[] = [];

  protected emailOverrideItem: ThirdPartyKeyValue = new ThirdPartyKeyValue(EMAIL_OVERRIDE_KEY);
  protected selectedChannelKey: string = null;

  constructor(
    private readonly injector: Injector,
    public activeModal: NgbActiveModal,
    private readonly _systemLevelService: SystemLevelService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _notificationService: NotificationService
  ) {
    super(injector);
  }

  private _vendor: ThirdPartyCredential;

  get vendor(): ThirdPartyCredential {
    return this._vendor;
  }

  @Input() set vendor(value: ThirdPartyCredential) {
    this._vendor = value;
    const initItem = findOrAddThirdPartyItem(this._vendor?.thirdPartyKeyValuePairs ?? []);
    this.emailOverrideItem = initItem(EMAIL_OVERRIDE_KEY);

    const emailOverride = this.emailOverrideItem?.value;
    if (!emailOverride) {
      this._vendor.userName = this.applicationContext.currentlyLoggedInUser.email;
    }
    else {
      this._vendor.userName = emailOverride;
    }

    const selectedChannels = chain(this.vendor.thirdPartyKeyValuePairs)
      .cloneDeep()
      .filter(el => el.key.includes('Channel:'))
      .map(el => {
        return el.key.replace('Channel:', '');
      })
      .value();

    this.selectedChannelKey = selectedChannels[0] ?? null;
  }

  private deleteEmptyThirdPartyItems(thirdPartyKeyValuePairs: any[]) {
    const deleteEmptyItem = deleteEmptyThirdPartyItem(thirdPartyKeyValuePairs);
    deleteEmptyItem(EMAIL_OVERRIDE_KEY);
  }

  saveCredential = () => {
    const thirdPartyKeyValuePairs = [...(this._vendor.thirdPartyKeyValuePairs ?? [])];
    this.deleteEmptyThirdPartyItems(thirdPartyKeyValuePairs);
    const vendor = { ...this._vendor, thirdPartyKeyValuePairs };
    vendor.userId = this.userCompanyGuid;
    vendor.branchId = this.branchId ? String(this.branchId) : null;

    //remove all Channel Key Value Pairs first then add those from local array.
    vendor.thirdPartyKeyValuePairs = vendor.thirdPartyKeyValuePairs.filter(kvp => !kvp.key.startsWith('Channel:'));

    vendor.thirdPartyKeyValuePairs.push({
      key: `Channel:${this.selectedChannelKey}`,
      value: "1"
    });

    this._spinner.show();

    this._systemLevelService.saveCredential(vendor).pipe(
      finalize(() => this._spinner.hide()),
    ).subscribe({
      next: (result => {
        this.activeModal.close(result);
      }),
      error: (error => {
        this._notificationService.showError(error?.message || 'An error has been encountered when saving a credential', 'Error');
      }),
    });
  }
}

function findOrAddThirdPartyItem(thirdPartyKeyValuePairs: ThirdPartyKeyValueArray) {
  return function (key: ThirdPartyKeyValue['key']) {
    let item = thirdPartyKeyValuePairs.find(x => x.key === key);
    if (item == null) {
      item = new ThirdPartyKeyValue(key);
      thirdPartyKeyValuePairs.push(item);
    }
    return item;
  };
}

function deleteEmptyThirdPartyItem(thirdPartyKeyValuePairs: ThirdPartyKeyValueArray) {
  return function (key: ThirdPartyKeyValue['key']) {
    const index = thirdPartyKeyValuePairs.findIndex(x => x.key === key);
    if (index < 0) {
      return;
    }

    const item = thirdPartyKeyValuePairs[index];
    const value = item?.value;
    if (value == null || value === '') {
      thirdPartyKeyValuePairs.splice(index, 1);
    }
  };
}
