import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { chain, cloneDeep, isNil } from 'lodash';
import { finalize } from 'rxjs/operators';
import { Utils } from 'src/app/core/services/utils';
import { Address, ExternalCompany, Role } from 'src/app/models';
import { GlobalConfig } from 'src/app/models/config/global-config.model';
import { LoanPurpose } from 'src/app/models/config/loan-purpose.model';
import { LoanType } from 'src/app/models/config/loan-type.model';
import { ReferralSource } from 'src/app/models/referral-source.model';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { User } from 'src/app/models/user/user.model';
import { BranchService } from 'src/app/modules/admin/company/services/branch.service';
import { AgentFull } from 'src/app/modules/app-details/models/agent.model';
import {
  UpsertReferralSourceComponent,
} from 'src/app/modules/referral-source/components/upsert-referral-source/upsert-referral-source.component';
import { AgentService } from 'src/app/services/agent.service';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { NewAppBorrowerModel } from '../../../models/new-app-borrower.model';
import { NewAppMortgageModel } from '../../../models/new-app-mortgage.model';
import { AlignmentRoleUsers } from '../../../models/process-submission.model';
import { NewApplicationService } from '../../../services/new-application.service';

@Component({
  selector: 'start-new-app',
  templateUrl: './start-new-app.component.html',
  styleUrls: ['./start-new-app.component.scss']
})
export class StartNewAppComponent implements OnInit, OnDestroy {
  @ViewChild('startNewAppForm') startNewAppForm: NgForm | undefined;

  @Input()
  isTpoUser: boolean;

  @Input()
  availableUsers: User[];

  @Input()
  globalConfig: GlobalConfig;

  @Input()
  enabledChannels: Array<EnumerationItem>;

  @Input()
  selectedChannel: string;

  @Input()
  externalCompanies: ExternalCompany[];

  @Input()
  selectedCompanyId: number | '';

  @Input()
  userCompanyGuid: string;

  @Input()
  tpoEnabledRoles: Role[] = [];

  @Input()
  isCompanySmartfi: boolean;

  @Output()
  startNewApp = new EventEmitter();

  firstRole: Role = undefined;
  avaliableSecondaryRoles: Role[] = [];

  primaryRoleUserId: string;
  tpoAlignmentRoleUsers: AlignmentRoleUsers = {};

  availableRoleUsers: User[] = [];

  mortgageModel: NewAppMortgageModel = new NewAppMortgageModel();
  enabledCoBorrower: boolean = false;
  loanPurposeId: number | '' = '';
  loanTypeId: string = '';
  currentLoanStatusName: string = '';
  referralSource: number;
  referralSourceType: number;
  secondaryReferralSource: number;
  secondaryReferralSourceType: number;
  referredTo: number;
  insideReferralSource: string;
  availableLoanTypes: Array<LoanType> = [];
  availableLoanPurpose: Array<LoanPurpose> = [];
  referralSources: Array<ReferralSource> = [];
  loadingLoanOptions: boolean = false;
  loadingUsers: boolean = false;
  filteredExternalCompanies: ExternalCompany[] = [];

  maxDate: Date = new Date();
  minDate: Date = new Date("1/1/1900");

  constructor(
    private readonly _newApplicationService: NewApplicationService,
    private readonly _branchService: BranchService,
    private readonly _agentService: AgentService,
    private readonly _modalService: NgbModal,
    private readonly _notifyService: NotificationService
  ) { }

  ngOnInit(): void {
    const userExist = this.availableUsers.some(u => u.userCompanyGuid === this.userCompanyGuid);
    this.primaryRoleUserId = userExist ? this.userCompanyGuid : '';
    this.mortgageModel.borrowers[0].typeOfBorrower = 'PrimaryBorrower';

    this.availableRoleUsers = cloneDeep(this.availableUsers);

    this.populateLoanOptions();
    this.loadReferralSources();
    this.handleChannelChange(this.selectedChannel);
  }

  ngOnDestroy(): void {
    this.checkAndRemoveGooglePlacesElements();
  }

  populateLoanOptions = () => {
    if (this.startNewAppForm) {
      this.startNewAppForm.controls.loanPurposeId?.markAsUntouched();
      this.startNewAppForm.controls.loanTypeId?.markAsUntouched();
    }
    if ((this.enabledChannels.length > 0 && !this.selectedChannel)) {
      this.availableLoanTypes = [];
      this.availableLoanPurpose = [];
      this.loanPurposeId = '';
      this.loanTypeId = '';
      return;
    }
    if (this.enabledChannels.length === 0 || (this.selectedChannel !== 'Wholesale' && this.selectedChannel !== 'Correspondent')) {
      this.availableLoanTypes = this.globalConfig.loanType;
      this.availableLoanPurpose = this.globalConfig.loanPurpose
        .filter(lt => this.enabledChannels.length === 0 || (lt.enabledChannels && lt.enabledChannels.includes(this.selectedChannel)));
      if (this.availableLoanTypes.length == 1) {
        this.loanTypeId = this.availableLoanTypes[0].loanTypeId.toString();
      }
      if (this.availableLoanPurpose.length == 1) {
        this.loanPurposeId = this.availableLoanPurpose[0].loanPurposeId;
      }
      this.firstRole = this.enabledChannels.length === 0
        ? this.globalConfig.firstRole
        : this.getFirstRoleByChannel(this.selectedChannel);

      if (this.isTpoUser && this.firstRole) {
        this.avaliableSecondaryRoles = this.tpoEnabledRoles.filter(r => r.roleId != this.firstRole.roleId && r.order != 1 &&
          r.roleChannels.map(rc => rc.channel.toLocaleLowerCase()).includes(this.selectedChannel.toLowerCase()));
      }
      return;
    }
    this.firstRole = this.getFirstRoleByChannel(this.selectedChannel);

    if (this.isTpoUser && this.firstRole) {
      this.avaliableSecondaryRoles = this.tpoEnabledRoles.filter(r => r.roleId != this.firstRole.roleId && r.order != 1 &&
        r.roleChannels.map(rc => rc.channel.toLocaleLowerCase()).includes(this.selectedChannel.toLowerCase()));
    }

    this.loadingLoanOptions = true;
    const matchingUser = this.availableRoleUsers.find((user: User) => user.userCompanyGuid == this.primaryRoleUserId);
    if (matchingUser && matchingUser.branchId && matchingUser.externalCompanyId) {
      this._branchService.getExternalBranchLoanOptions(matchingUser.branchId)
        .pipe(finalize(() => this.loadingLoanOptions = false))
        .subscribe({
          next: (res) => {
            const availableLoanTypeIds = res?.allowedLoanTypes ? res.allowedLoanTypes.split(',') : [];
            this.availableLoanTypes = this.globalConfig.loanType
              .filter((loanType: LoanType) => availableLoanTypeIds.includes(loanType.loanTypeId.toString()));
            const availableLoanPurposeIds = res?.allowedLoanPurposes ? res.allowedLoanPurposes.split(',') : [];
            this.availableLoanPurpose = this.globalConfig.loanPurpose
              .filter((loanPurpose: LoanPurpose) => availableLoanPurposeIds.includes(loanPurpose.loanPurposeId.toString()));
            if (this.availableLoanTypes.length == 1) {
              this.loanTypeId = this.availableLoanTypes[0].loanTypeId.toString();
            }
            if (this.availableLoanPurpose.length == 1) {
              this.loanPurposeId = this.availableLoanPurpose[0].loanPurposeId;
            }
            this.loanPurposeId = '';
            this.loanTypeId = '';
          }, error: (err) => {
            this.loadingLoanOptions = false;
            this._notifyService.showError(err?.message || 'Unable to get Branch Loan Options', 'Error!');
          }
        });
      return;
    }
    this.availableLoanTypes = [];
    this.availableLoanPurpose = [];
    this.loanPurposeId = '';
    this.loanTypeId = '';
    this.loadingLoanOptions = false;
  }

  fillLoanStatus = (loanPurposeId: number | '') => {
    const topMostStatusByLoanPurposeAndChannel = chain(this.globalConfig.loanStatusAssociation)
      .filter(lsa => lsa.loanPurposeId == loanPurposeId &&
        (!this.selectedChannel || (lsa.channel && lsa.channel == this.selectedChannel)))
      .head()
      .value();
    if (!topMostStatusByLoanPurposeAndChannel) {
      this.currentLoanStatusName = '';
      return;
    }
    const matchingStatus = this.globalConfig.loanStatus
      .find(ls => ls.loanStatusId == topMostStatusByLoanPurposeAndChannel.loanStatusId);
    this.currentLoanStatusName = matchingStatus
      ? matchingStatus.loanStatusName
      : '';
  }

  handleChannelChange = (newVal: string): void => {
    this.selectedChannel = newVal;
    this.firstRole = this.getFirstRoleByChannel(this.selectedChannel);

    if (this.isTpoUser && this.firstRole) {
      this.avaliableSecondaryRoles = this.tpoEnabledRoles.filter(r => r.roleId != this.firstRole.roleId && r.order != 1 &&
        r.roleChannels.map(rc => rc.channel.toLocaleLowerCase()).includes(this.selectedChannel.toLowerCase()));
    }

    if (!this.isTpoUser) {
      this.selectedCompanyId = '';
    }
    if (!this.isTpoUser && (newVal == 'Wholesale' || newVal == 'Correspondent' || newVal == 'NonDelegatedCorrespondent')) {
      this.availableRoleUsers = [];
      this.insideReferralSource = null;
      this.filteredExternalCompanies = this.externalCompanies.filter(c => c.enabledChannels.indexOf(this.selectedChannel) > -1);
    } else {
      this.filteredExternalCompanies = this.externalCompanies.filter(c => c.enabledChannels.indexOf(this.selectedChannel) > -1);
      const filteredUsers = this.isTpoUser
        ? this.availableUsers.filter(x => x.externalCompanyId == this.selectedCompanyId)
        : this.availableUsers;

      this.availableRoleUsers = cloneDeep(filteredUsers || []);

      if (this.availableUsers.findIndex(u => u.userCompanyGuid === this.insideReferralSource) === -1) {
        this.insideReferralSource = null;
      }
    }
    const userExist = this.availableUsers.some(u => u.userCompanyGuid === this.userCompanyGuid);
    this.primaryRoleUserId = userExist ? this.userCompanyGuid : '';
    this.populateLoanOptions();
  }

  loadExternalCompanyUsers = (externalCompanyId: number | '') => {
    this.selectedCompanyId = externalCompanyId;
    if (!this.selectedCompanyId) {
      this.availableRoleUsers = [];
      this.populateLoanOptions();
      return;
    }
    if (this.isTpoUser) {
      if (this.selectedCompanyId) {
        this.availableRoleUsers = this.availableUsers.filter(x => x.externalCompanyId == this.selectedCompanyId);
        this.populateLoanOptions()
      } else {
        this.availableRoleUsers = [];
      }
      return;
    }
    this.loadingUsers = true;
    this._newApplicationService.getExternalCompanyUsers(this.selectedCompanyId)
      .pipe(finalize(() => this.loadingUsers = false))
      .subscribe({
        next: (response) => {
          this.availableRoleUsers = response || [];
          this.populateLoanOptions()
        }, error: (err) => {
          this._notifyService.showError(err?.message || 'Unable to load external company users', 'Error!');
        }
      });
  }

  addCoBorr = () => {
    this.enabledCoBorrower = true;
    const coBorr = new NewAppBorrowerModel();
    coBorr.borrowerId = -2;
    coBorr.typeOfBorrower = 'CoBorrower';
    coBorr.jointWithBorrowerId = -1;
    this.mortgageModel.borrowers.push(coBorr);
    this.mortgageModel.borrowers = [...this.mortgageModel.borrowers];
  }

  removeCoBorr = () => {
    this.enabledCoBorrower = false;
    if (this.mortgageModel.borrowers.length > 1) {
      this.mortgageModel.borrowers.pop();
      this.mortgageModel.borrowers = [...this.mortgageModel.borrowers];
    }
  }

  proceed = () => {
    this.startNewAppForm.form.markAllAsTouched();
    if (this.startNewAppForm.form.valid) {
      const data = {
        mortgageModel: this.mortgageModel,
        primaryRoleUserId: this.primaryRoleUserId,
        loanPurposeId: this.loanPurposeId,
        loanTypeId: this.loanTypeId,
        channel: this.selectedChannel,
        referralSource: this.referralSource,
        referralSourceType: this.referralSourceType,
        secondaryReferralSource: this.secondaryReferralSource,
        secondaryReferralSourceType: this.secondaryReferralSourceType,
        referredTo: this.referredTo,
        insideReferralSource: this.insideReferralSource,
        availableLoanTypes: this.availableLoanTypes,
        availableLoanPurposes: this.availableLoanPurpose,
        additionalAlignmentRoleUsers: this.tpoAlignmentRoleUsers
      }
      this.startNewApp.emit(data);
    }
  }

  protected handleAddressChange(e: Partial<Address>, borrowerIndex: number): void {
    let addressModel = this.mortgageModel.borrowers[borrowerIndex].residencyAddresses[0].address;
    addressModel.address1 = ''; // to reset the last populated address.

    setTimeout(() => {
      addressModel.address1 = e.address1;
      addressModel.city = e.city;
      addressModel.state = e.state;
      addressModel.zipCode = e.zipCode;
    }, 200);
  }

  copyBorrowerAddress = () => {
    this.mortgageModel.borrowers[1].residencyAddresses[0].address.address1 = this.mortgageModel.borrowers[0].residencyAddresses[0].address.address1;
    this.mortgageModel.borrowers[1].residencyAddresses[0].address.address2 = this.mortgageModel.borrowers[0].residencyAddresses[0].address.address2;
    this.mortgageModel.borrowers[1].residencyAddresses[0].address.city = this.mortgageModel.borrowers[0].residencyAddresses[0].address.city;
    this.mortgageModel.borrowers[1].residencyAddresses[0].address.state = this.mortgageModel.borrowers[0].residencyAddresses[0].address.state;
    this.mortgageModel.borrowers[1].residencyAddresses[0].address.zipCode = this.mortgageModel.borrowers[0].residencyAddresses[0].address.zipCode;
  }

  onAddNewReferralSourceClicked = (source: RefSource) => {
    const modalRef = this._modalService.open(UpsertReferralSourceComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.agents = this.referralSources;

    modalRef.result.then((newAgent: AgentFull) => {
      this.loadReferralSources(newAgent, source);
    }, () => {
    });
  }

  private checkAndRemoveGooglePlacesElements = (): void => {
    const elements: any = document.querySelectorAll('.pac-container');
    for (const e of elements) {
      e.remove();
    }
  }

  private loadReferralSources = (defaultSelectedAgent: AgentFull = null, source: RefSource = null) => {
    this._agentService.getAllReferralSources().subscribe(response => {
      this.referralSources = chain(response)
        .filter(a => !isNil(a.firstName) || !isNil(a.lastName))
        .map(agent => ({
          ...agent,
          displayName: Utils.getPersonsDisplayName(agent)
        }))
        .orderBy(['lastName', 'firstName'])
        .value();

      if (defaultSelectedAgent && source) {
        const referralSourceToSelect = this.referralSources.find(a => a.firstName.toLocaleLowerCase() === defaultSelectedAgent.agent.firstName.toLocaleLowerCase() &&
          a.lastName.toLocaleLowerCase() === defaultSelectedAgent.agent.lastName.toLocaleLowerCase());
        if (referralSourceToSelect) {
          this[source] = referralSourceToSelect.agentId;
        }
      }
    });
  }

  private getFirstRoleByChannel = (channel: string) => {
    if (!channel) {
      return this.globalConfig.firstRole;
    }
    return this.globalConfig.channelRoles[channel.toLowerCase()][0];
  }

}

type RefSource = 'referralSource' | 'secondaryReferralSource' | 'referredTo';
