import {FeesV2Service} from './fees-v2.service';
import {
  CalculatedFeeType,
  FeeCalculatedValues,
  FeeSectionType,
  FeeSystemDetails,
  FeeSystemDetailsFeeTypeDetails,
  FeeType,
  LoanFee,
  UpdateFeesFromLoanRequest,
} from '../fees.model';
import {EnumerationService} from '../../../services/enumeration-service';
import {
  concatMap,
  defer,
  forkJoin,
  map,
  MonoTypeOperatorFunction,
  Observable,
  of,
  ReplaySubject,
  take,
} from 'rxjs';
import {EnumerationItem} from '../../../models/simple-enum-item.model';
import {Constants} from '../../../services/constants';
import {merge} from 'lodash';
import {takeUntil, tap} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {FeeUtils} from '../utils/utils';
import mergeAllFees = FeeUtils.mergeAllFees;

const RESERVE_SUFFIX = 'Reserve';

export interface FeeDataAccessProps {
  value: Readonly<Partial<LoanFee>>;
  existingFees: readonly Readonly<LoanFee>[];
}

@Injectable()
export class FeeDataAccessService implements OnDestroy {
  private readonly _feeSystemDetails$: ReplaySubject<FeeSystemDetails> = new ReplaySubject(1);
  private readonly _feeTypeOptions$: ReplaySubject<EnumerationItem<FeeType>[]> = new ReplaySubject(1);
  private _destroyed$: ReplaySubject<void> = new ReplaySubject(1);

  private _operations: Map<FeeOperationType, FeeOperation> = new Map();

  constructor(
    private readonly _feesService: FeesV2Service,
    private readonly _enumerationService: EnumerationService,
  ) {
    this._enumerationService.getFeeEnumerations()
      .pipe(this.takeUntilDestroyed())
      .subscribe((enumerations) => {
        this._feeTypeOptions$.next(enumerations[Constants.feeEnumerations.feeType]);
      });
    this._feesService.getFeeSystemDetails()
      .pipe(this.takeUntilDestroyed())
      .subscribe(feeSystemDetails => {
        this._feeSystemDetails$.next(feeSystemDetails);
      });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  createFee(props: FeeDataAccessProps): Observable<LoanFee[]> {
    return this.operateFee({ ...props, type: FeeOperationType.Create });
  }

  updateFee(props: FeeDataAccessProps): Observable<LoanFee[]> {
    return this.operateFee({ ...props, type: FeeOperationType.Update });
  }

  deleteFee(props: FeeDataAccessProps): Observable<LoanFee[]> {
    return this.operateFee({ ...props, type: FeeOperationType.Delete });
  }

  private operateFee(props: FeeDataAccessProps & { type: FeeOperationType }): Observable<LoanFee[]> {
    return defer(() => {
      const operation$ = this.getOrCreateOperation(props.type);

      const { value, existingFees } = props;

      return operation$.pipe(
        this.takeUntilDestroyed(),
        concatMap(operation => operation.processFee({ value, existingFees })),
      );
    });
  }

  private useFieldValues(): Observable<{ feeSystemDetails: FeeSystemDetails, feeTypeOptions: EnumerationItem<FeeType>[] }> {
    return forkJoin({
      feeSystemDetails: this._feeSystemDetails$.pipe(take(1)),
      feeTypeOptions: this._feeTypeOptions$.pipe(take(1)),
    });
  }

  private getOrCreateOperation(type: FeeOperationType): Observable<FeeOperation> {
    const operation = this._operations.get(type);
    return operation != null
      ? of(operation)
      : this.useFieldValues().pipe(
        map(({ feeSystemDetails, feeTypeOptions }) => {
          const ctor = this.getOperationConstructor(type);
          return new ctor(this._feesService, feeSystemDetails, feeTypeOptions);
        }),
        tap(operation => this._operations.set(type, operation)),
      );
  }

  private getOperationConstructor(type: FeeOperationType): new (...args: ConstructorParameters<typeof FeeOperation>) => FeeOperation {
    switch (type) {
      case FeeOperationType.Create:
        return CreateFeeOperation;
      case FeeOperationType.Update:
        return UpdateFeeOperation;
      case FeeOperationType.Delete:
        return DeleteFeeOperation;
      default:
        throw new Error('Unknown operation type');
    }
  }

  private takeUntilDestroyed<T>(): MonoTypeOperatorFunction<T> {
    return takeUntil<T>(this._destroyed$);
  }
}

enum FeeOperationType {
  Create,
  Update,
  Delete,
}

abstract class FeeOperation {
  constructor(
    protected readonly feesService: FeesV2Service,
    protected readonly feeSystemDetails: Readonly<FeeSystemDetails>,
    protected readonly feeTypeOptions: readonly Readonly<EnumerationItem<FeeType>>[],
  ) {
  }

  public abstract processFee(props: {
    value: Readonly<Partial<LoanFee>>;
    existingFees: readonly Readonly<LoanFee>[];
  }): Observable<LoanFee[]>;

  protected findTypeInfo(feeType: FeeType): FeeSystemDetailsFeeTypeDetails | undefined {
    return this.feeSystemDetails.feeTypes.find(details => details.feeType === feeType);
  }

  protected tryGetReserveFeeType(feeType: FeeType): FeeType | undefined {
    return this.isReserveFeeType(feeType)
      ? undefined // It's already a reserve fee
      : feeType + RESERVE_SUFFIX as FeeType;
  }

  protected findReserveFee(props: {
    feeType: FeeType,
    existingFees: readonly Readonly<LoanFee>[],
  }): Readonly<LoanFee> | undefined {
    const reserveFeeType = this.tryGetReserveFeeType(props.feeType);
    return reserveFeeType == null
      ? undefined
      : props.existingFees.find(fee => fee.feeType === reserveFeeType);
  }

  protected findReserveFeeOption(type: FeeType): Readonly<EnumerationItem<FeeType>> | undefined {
    const reserveFeeType = this.tryGetReserveFeeType(type);
    return reserveFeeType == null
      ? undefined
      : this.feeTypeOptions.find(option => option.value === reserveFeeType);
  }

  private isReserveFeeType(feeType: FeeType): boolean {
    return feeType?.endsWith(RESERVE_SUFFIX) ?? false;
  }
}

class CreateFeeOperation extends FeeOperation {
  public processFee(props: {
    value: Readonly<Partial<LoanFee>>;
    existingFees: readonly Readonly<LoanFee>[];
  }): Observable<LoanFee[]> {
    const { value, existingFees } = props;

    return defer(() => {
      const defaults: Partial<LoanFee> = {
        isAprFee: false, // The API requires this property to exist, throws an error if it doesn't
      };

      const isSpecificFee = value.feeType != null;
      const props = {
        value,
        existingFees,
      };

      const payloads = isSpecificFee
        ? [
          this.createSpecificFeePayload(value),
          this.tryCreateReserveFeePayload(props),
        ].filter(Boolean)
        : [this.createNonSpecificFeePayload(value)];

      const updateObservables = payloads.map(payload => {
        const feeSection: FeeSectionType = payload.feeSection ?? ((payload as any).feeSections?.[0]);
        if (feeSection == null) {
          throw new Error('Fee section is not set');
        }
        const existingLoanFees = existingFees.filter(existingFee => existingFee.feeSection === feeSection);
        const feesToUpdate = [merge({}, defaults, payload)];

        const request: UpdateFeesFromLoanRequest = {
          existingLoanFees,
          feesToUpdate,
          updateCharges: true,
        };

        return this.feesService.updateFeesFromLoan(request);
      });

      return forkJoin(updateObservables).pipe(
        map((allFees) => mergeAllFees([[...existingFees], ...allFees])),
      );
    });
  }

  private createNonSpecificFeePayload(value: Readonly<Partial<LoanFee>>): Partial<LoanFee> {
    const { hudNumber } = value;
    if (hudNumber == null) {
      throw new Error('HUD number is not set');
    }

    const { feeSystemDetails } = this;
    const feeTypeInfo = feeSystemDetails.feeTypes.find(details => details.hudNumber === hudNumber);
    if (feeTypeInfo != null) {
      return feeTypeInfo as Partial<LoanFee>;
    }

    const defaults: Partial<LoanFee> = {
      feeSection: FeeSectionType.Other,
      calculatedFeeType: CalculatedFeeType.Default,
    };

    return merge({}, defaults, value);
  }

  private createSpecificFeePayload(value: Readonly<Partial<LoanFee>>): Partial<LoanFee> {
    const feeType = value.feeType;
    if (feeType == null) {
      throw new Error('Fee type is not set');
    }

    const feeTypeInfo = this.findTypeInfo(feeType);
    if (feeTypeInfo == null) {
      throw new Error(`Cannot find fee type information for ${feeType}`);
    }

    const defaults: Partial<LoanFee> = {
      name: feeTypeInfo.name,
    };

    return merge({}, defaults, feeTypeInfo, value);
  }

  private tryCreateReserveFeePayload(props: {
    value: Partial<LoanFee>;
    existingFees: readonly LoanFee[];
  }): Partial<LoanFee> | undefined {
    const { value } = props;
    const feeType = value.feeType;
    if (feeType == null) {
      throw new Error('Fee type is not set');
    }

    const reserveFeeType = this.tryGetReserveFeeType(feeType);
    if (reserveFeeType == null) {
      return undefined;
    }

    const { existingFees } = props;
    const reserveFee = this.findReserveFee({ feeType, existingFees });
    // It already has a reserve fee
    if (reserveFee != null) {
      return undefined;
    }

    const reserveOption = this.findReserveFeeOption(feeType);
    // No corresponding reserve fee type
    if (reserveOption == null) {
      return undefined;
    }

    return this.createSpecificFeePayload({
      feeType: reserveFeeType,
      name: reserveOption.name,
    });
  }
}

class UpdateFeeOperation extends FeeOperation {
  public processFee(props: {
    value: Readonly<Partial<LoanFee>>;
    existingFees: readonly Readonly<LoanFee>[];
  }): Observable<LoanFee[]> {
    const { value, existingFees } = props;

    return defer(() => {
      const requests = this.createRequests({ value, existingFees });
      if (requests.length === 0) {
        throw new Error('Nothing to update');
      }

      return forkJoin(requests.map(request => this.feesService.updateFeesFromLoan(request))).pipe(
        map((allFees) => mergeAllFees([[...existingFees], ...allFees]),
        ));
    });
  }

  private createRequests(props: {
    value: Readonly<Partial<LoanFee>>;
    existingFees: readonly Readonly<LoanFee>[];
  }): UpdateFeesFromLoanRequest[] {
    return [
      this.createUpdateFeeRequest(props),
      this.tryCreateUpdateReserveFeeRequest(props) ?? this.tryCreateCreateReserveFeeRequest(props),
    ].filter(Boolean);
  }

  private createUpdateFeeRequest(props: {
    value: Readonly<Partial<LoanFee>>;
    existingFees: readonly Readonly<LoanFee>[];
  }): UpdateFeesFromLoanRequest | undefined {
    const { value, existingFees } = props;

    const sectionFees = existingFees.filter(fee => fee.feeSection === value.feeSection);

    return {
      existingLoanFees: sectionFees,
      feesToUpdate: [value],
      updateCharges: false,
    };
  }

  private tryCreateUpdateReserveFeeRequest(props: {
    value: Readonly<Partial<LoanFee>>;
    existingFees: readonly Readonly<LoanFee>[];
  }): UpdateFeesFromLoanRequest | undefined {
    const { value, existingFees } = props;
    const { feeType } = value;

    const reserveFeeType = this.tryGetReserveFeeType(feeType);
    if (reserveFeeType == null) {
      return undefined;
    }

    const reserveFee = this.findReserveFee({ feeType, existingFees });
    if (reserveFee == null) {
      return undefined;
    }

    const monthlyFee = value.calculatedValues?.monthlyFee;
    if (reserveFee.calculatedValues?.monthlyFee === monthlyFee) {
      return undefined;
    }
    const calculatedValues: Partial<FeeCalculatedValues> = reserveFee.calculatedValues
      ? { ...reserveFee.calculatedValues }
      : {};
    calculatedValues.monthlyFee = monthlyFee;
    const feeToUpdate = { ...reserveFee, calculatedValues };

    const sectionFees = existingFees.filter(fee => fee.feeSection === reserveFee.feeSection);

    return {
      existingLoanFees: sectionFees,
      feesToUpdate: [feeToUpdate],
      updateCharges: false,
    };
  }

  private tryCreateCreateReserveFeeRequest(props: {
    value: Readonly<Partial<LoanFee>>;
    existingFees: readonly Readonly<LoanFee>[];
  }): UpdateFeesFromLoanRequest | undefined {
    const { value } = props;
    const { feeType } = value;

    const reserveFeeType = this.tryGetReserveFeeType(feeType);
    if (reserveFeeType == null) {
      return undefined;
    }

    const feeTypeInfo = this.findTypeInfo(reserveFeeType);
    if (feeTypeInfo == null) {
      return undefined;
    }

    const defaults: Partial<LoanFee> = {
      name: feeTypeInfo.name,
    };

    const overrides: Partial<LoanFee> = {
      calculatedValues: value.calculatedValues,
      loanFeeId: undefined,
      modelGuid: undefined,
    };

    const feeToUpdate = merge({}, value, defaults, feeTypeInfo, overrides);
    delete feeToUpdate.loanFeeId;
    delete feeToUpdate.modelGuid;
    const sectionFees = props.existingFees.filter(fee => fee.feeSection === feeToUpdate.feeSection);

    return {
      existingLoanFees: sectionFees,
      feesToUpdate: [feeToUpdate],
      updateCharges: true,
    };
  }
}

class DeleteFeeOperation extends FeeOperation {
  public processFee(props: {
    value: Readonly<Partial<LoanFee>>;
    existingFees: readonly Readonly<LoanFee>[];
  }): Observable<LoanFee[]> {
    const { value, existingFees } = props;

    return defer(() => {
      const remainingFees = this.removeFees({ value, existingFees });
      const request = this.createDeleteFeeRequest({ value, existingFees: remainingFees });

      return this.feesService.updateFeesFromLoan(request).pipe(
        map((fees) => mergeAllFees([remainingFees, fees])),
      );
    });
  }

  private removeFees(props: {
    value: Readonly<Partial<LoanFee>>;
    existingFees: readonly Readonly<LoanFee>[];
  }): Readonly<LoanFee>[] {
    const { value, existingFees } = props;

    const hudNumbersToDelete = new Set<string>([value.hudNumber]);
    const otherFees = this.getOtherFeesInHudGroup({ value, existingFees });
    if (otherFees.length === 0 && value.sumInHudNumber) {
      const hudGroupParent = existingFees.find(fee => fee.hudNumber === value.sumInHudNumber?.toString());
      if (hudGroupParent == null) {
        throw new Error('Parent fee not found');
      }
      hudNumbersToDelete.add(hudGroupParent.hudNumber);
    }

    const remainingFees = existingFees.filter(fee => !hudNumbersToDelete.has(fee.hudNumber));
    if (remainingFees.length === existingFees.length) {
      throw new Error('Fee not found');
    }

    return remainingFees;
  }

  private getOtherFeesInHudGroup(props: {
    value: Readonly<Partial<LoanFee>>;
    existingFees: readonly Readonly<LoanFee>[];
  }): Readonly<LoanFee>[] {
    const { value, existingFees } = props;
    const { sumInHudNumber } = value;
    if (sumInHudNumber == null) {
      return [];
    }

    return existingFees.filter(fee => {
      return fee.sumInHudNumber === sumInHudNumber && fee.hudNumber !== value.hudNumber;
    });
  }

  private createDeleteFeeRequest(props: {
    value: Readonly<Partial<LoanFee>>;
    existingFees: readonly Readonly<LoanFee>[];
  }): UpdateFeesFromLoanRequest {
    const { value, existingFees } = props;

    const sectionFees = existingFees.filter(fee => fee.feeSection === value.feeSection);

    return {
      existingLoanFees: sectionFees,
      feesToUpdate: sectionFees,
      updateCharges: false,
    };
  }
}
