import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Agent } from 'src/app/modules/app-details/models/agent.model';
import { NotificationService } from 'src/app/services/notification.service';
import { AgentTypeEnum } from '../../agents/models/agent-list.model';
import { AgentsService } from '../../agents/services/agents.service';
import { TagManagementService } from '../../admin/tag-management/services/tag-management.service';
import { Tag } from '../../admin/tag-management/models/tag.model';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'agent-tags',
  templateUrl: './agent-tags.component.html',
  styleUrls: ['./agent-tags.component.scss']
})
export class AgentTagsComponent implements OnInit, OnDestroy {
  
  @Input()
  agent: Agent;

  @Input()
  agentType?: AgentTypeEnum;

  @Input()
  isAgentTypeRequired?: boolean;
  
  allAgentTags: Tag[] = [];
  agentTags: Tag[] = [];
  newListTag: string = null;

  multiSelectSettings: IDropdownSettings = {
    idField: 'tagId',
    textField: 'name',
    allowSearchFilter: true,
    clearSearchFilter: true,
    noDataAvailablePlaceholderText: "No tags available",
    closeDropDownOnSelection: true,
    enableCheckAll: false
  }

  private _contextSubscription: Subscription;
  private _companyId: number;

  constructor(
    private readonly _agentsService: AgentsService,
    private readonly _tagService: TagManagementService,
    private readonly _notifyService: NotificationService,
    private readonly _appContextService: ApplicationContextService,
  ) { }

  ngOnInit(): void {
    this._contextSubscription = this._appContextService.context.subscribe(context => {
      this._companyId = context.userPermissions.companyId;
      this._tagService.getTagList().subscribe((response) => {
        this.allAgentTags = response
          .filter(a => {
            const agentTypeMatch = this.agentType 
              ? a.agentType?.toLowerCase() === this.agentType.toLowerCase() 
              : true;
            const contactListTypes = a.restrictedToContactListTypes 
              ? a.restrictedToContactListTypes.replace(/\s/g, '').split(',') 
              : [];
            const restrictedMatch = contactListTypes.includes('2');
            return agentTypeMatch && restrictedMatch;
          })
          .sort((a, b) => a.name.localeCompare(b.name));
  
          const agentTagIds = this.agent.tags?.map((t) => t.tagId) ?? []; 
          this.agentTags = this.allAgentTags.filter((tag) =>
            agentTagIds.includes(tag.tagId) 
          );
  
      }, (err) => {
        this._notifyService.showError(err ? err.message || err : "Error fetching lists!", "Error");
      });
    })
  }

  ngOnDestroy(): void {
    this._contextSubscription?.unsubscribe();
  }

  addNewTag = () => {
    const newtag: Tag = {
      name: this.newListTag,
      restrictedToContactListTypes: '2',
      companyId: this._companyId
    }
    
    this._tagService.postTag(newtag)
      .subscribe({
        next: (addedtag: Tag) => {
          this.newListTag = null;
          this.allAgentTags = [...this.allAgentTags, addedtag];
          this.agentTags = [...this.agentTags, addedtag];
          this._notifyService.showSuccess("New Tag created successfully", "Success");
        },
        error: (err) => {
          this._notifyService.showError("Error encountered while creating tag", "Error");
        },
        complete: () => {
          //this._spinnerService.hide()
          
        }
      });
  }

  addToAgentTags = (tag: Tag) => {
      if (!this.agent.agentId) {
        this.agentTags = _.uniqBy([...this.agentTags, tag], "tagId");
        return;
      }
  }
  
  deleteFromAgentTags = (tag: Tag) => {
    if (!this.agent.agentId) {
      this.agentTags = this.agentTags.filter(l => l.tagId != tag.tagId);
      return;
    };
  }
  
  onTagsChange(updatedTags: Tag[]) {
    const previousTags = this.agentTags || [];
    const addedTags = updatedTags.filter(tag => !previousTags.some(prev => prev.tagId === tag.tagId));
    const removedTags = previousTags.filter(tag => !updatedTags.some(updated => updated.tagId === tag.tagId));
    addedTags.forEach(tag => {
      this.addToAgentTags(tag);
    });
    removedTags.forEach(tag => {
      this.deleteFromAgentTags(tag);
    });
      this.agentTags = updatedTags;
  }
}
