import { Injectable } from '@angular/core';
import { exhaustMap, first, interval, Observable, timeout } from 'rxjs';

@Injectable()
export class PollHelperService {
  OPTIONS_DEFAULT: PollOptions = {
    interval: 5000,
    timeout: 60000
  };

  startPoll<T>(
    pollFn: () => Observable<T>, // intermediate polled responses
    stopPollPredicate: (value: T) => boolean, // condition to stop polling
    options: PollOptions = this.OPTIONS_DEFAULT): Observable<T> {
    return interval(options.interval)
      .pipe(
        exhaustMap(() => pollFn()),
        first(value => stopPollPredicate(value)),
        timeout(options.timeout)
      );
  }

  
}

export interface PollOptions {
  interval: number;
  timeout: number;
}

