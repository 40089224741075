import {LoanFee} from './fees.model';
import {createAllFeePayers, FeePayer, FeePayerType} from './fee-payer';
import {FeeSectionViewType} from './fee-section.model';
import { isNumber } from 'lodash';

export interface FeeViewModelProps {
  fee: LoanFee;
  subFees?: readonly FeeViewModel[];
  payers: readonly FeePayer[];
  sectionType?: FeeSectionViewType;
  canBeSplit?: boolean;
  canBeApr?: boolean;
  deletable?: boolean;
  defaultPayerType?: FeePayerType;
  durationType?: 'months' | 'days';
}

export class FeeViewModel {
  public readonly fee: LoanFee;
  public readonly subFees: readonly FeeViewModel[];
  public readonly payers: readonly FeePayer[];
  /**
   * This might be different from the section type of the fee.
   */
  public readonly sectionType: FeeSectionViewType;
  public readonly canBeSplit: boolean;
  public readonly canBeApr: boolean;
  public readonly deletable: boolean;
  /**
   * The default payer type to use when there is no active payer.
   */
  public readonly defaultPayerType: FeePayerType;
  public readonly durationType?: 'months' | 'days';

  get hasSubFees(): boolean {
    // TODO: Depending on the UI use case, we may simply check if `fee.isSummaryFee` is true.
    //       Currently, we are only concerned with whether it has a sub fee in the UI.
    //       Having a summary fee without a sub fee is not a valid case.
    return this.subFees?.length > 0;
  }

  get total(): number {
    const multiplier = this.fee.isLoanCredit ? -1 : 1;
    return (this.fee.calculatedValues?.totalFee || 0) * multiplier;
  }

  get atClosingTotal(): number {
    const multiplier = this.fee.isLoanCredit ? -1 : 1;
    return (this.fee.calculatedValues?.totalPaidAtClosing || 0) * multiplier;
  }

  get beforeClosingTotal(): number {
    return this.total - this.atClosingTotal;
  }

  get name(): string {
    const hudNumber = this.fee.hudNumber;
    const name = this.fee.name;

    return hudNumber ? `${hudNumber} - ${name}` : name;
  }

  /**
   * Returns the valid payers of the fee. (i.e., payers with a total greater than 0)
   */
  get validPayers(): FeePayer[] {
    return this.payers.filter(payer => payer.total.calculatedDollar != 0 && isNumber(payer.total.calculatedDollar));
  }

  /**
   * Returns true if there is more than one payer in {@link validPayers}. Otherwise, returns false.
   */
  get isSplit(): boolean {
    return this.validPayers.length > 1;
  }

  /**
   * If there is only one payer in {@link validPayers}, returns it. Otherwise, returns undefined.
   */
  get activePayer(): FeePayer | undefined {
    const validPayers = this.validPayers;
    return validPayers.length === 1 ? validPayers[0] : undefined;
  }

  /**
   * Returns payer with {@link defaultPayerType} if there is no {@link validPayers}. Otherwise,
   * returns {@link activePayer}.
   */
  get activePayerOrDefault(): FeePayer | undefined {
    return this.validPayers.length === 0
      ? this.payers.find(payer => payer.type === this.defaultPayerType)
      : this.activePayer;
  }

  /**
   * Returns {@link activePayerOrDefault} if it is not a percent payer.
   * Otherwise, returns undefined.
   */
  get activePayerOrDefaultNonPercent(): FeePayer | undefined {
    const payer = this.activePayerOrDefault;
    return payer?.atClosing.percent ? undefined : payer;
  }

  private constructor({
    fee,
    subFees = [],
    payers,
    sectionType = FeeSectionViewType.Other,
    canBeSplit = true,
    canBeApr = true,
    deletable = true,
    defaultPayerType = FeePayerType.Borrower,
    durationType,
  }: FeeViewModelProps) {
    this.fee = fee;
    this.subFees = subFees;
    this.payers = payers;
    this.sectionType = sectionType;
    this.canBeSplit = canBeSplit;
    this.canBeApr = canBeApr;
    this.deletable = deletable;
    this.defaultPayerType = defaultPayerType;
    this.durationType = durationType;
  }

  static fromFee(fee: LoanFee, props?: Partial<FeeViewModelProps>): FeeViewModel {
    if (!fee.name) fee.name = '';
    return new FeeViewModel({
      fee,
      ...props,
      subFees: [...(props?.subFees ?? [])],
      payers: createAllFeePayers(fee),
    });
  }

  static empty(): FeeViewModel {
    return new FeeViewModel({
      fee: {},
      payers: [],
    });
  }
}
