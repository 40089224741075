import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData } from 'ng-select2';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, Subscription } from 'rxjs';
import { ApplicationContextService } from 'src/app/services/application-context.service';
import { AuthService } from 'src/app/services/auth.service';
import { Constants } from 'src/app/services/constants';
import { EnumerationService } from 'src/app/services/enumeration-service';
import { NotificationService } from 'src/app/services/notification.service';
import { SecurityCodeDialog } from 'src/app/shared/components'; 
import { ProfileService } from '../../modules/profile/profile.service';
import * as _ from 'lodash';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html'
})
export class SettingComponent implements OnInit, OnDestroy {
  @Input() isEditingUserProfile: boolean = false;
  @Input() setting = {
    phone: null,
    areaCode: null,
    isTwoFactor: null
  }
  
  countries: Array<Select2OptionData> = [];
  countriesList = [];

  countriesOptions = {
    width: '100%',
    multiple: false,
    closeOnSelect: true,
  };

  currentPhone: string;

  updatingPhoneNumber: boolean = false;
  deletingPhoneNumber: boolean = false;

  private _applicationContextSubscription: Subscription;
  private _confirmOperationSubscription: Subscription;

  constructor(
    private _applicationContextService: ApplicationContextService,
    private _notificationService: NotificationService,
    private readonly _enumsService: EnumerationService,
    private readonly _spinner: NgxSpinnerService,
    private readonly _modalService: NgbModal,
    private readonly _authService: AuthService,
    private _service: ProfileService,
  ) { }

  ngOnInit(): void {
    if(!this.isEditingUserProfile){
      this._applicationContextSubscription = this._applicationContextService.context.subscribe(
        (result) => {
          this.currentPhone = result.currentlyLoggedInUser.phoneNumber;
          this.setting.isTwoFactor = result.currentlyLoggedInUser.twoFactor;
        }, ({ error }) => {
          this._notificationService.showError(
            error ? error.message : 'Unable to load data',
            'Error!'
          );
        }
      )
    }
    else {
      const phone = this.setting.phone as string;
      if(phone){
        const matched = this._enumsService.countries.find((c: any) => phone.startsWith(c.areaCode)) as any;
        if(matched){
          this.setting.areaCode = matched.areaCode;
          const onlyPhone = phone.slice(matched.areaCode.length);
          this.setting.phone = onlyPhone;
          this.currentPhone = matched.areaCode + onlyPhone;
        }
        else {
          this.currentPhone = phone;
        }

      }
    }
 
    this.countries = this._enumsService.countries.map((val: any) => ({
      id: val.areaCode,
      text: `(${val.areaCode}) ${val.name}`,
      code: val.areaCode
    }));
  }

  ngOnDestroy(): void {
    this._applicationContextSubscription?.unsubscribe();
    this._confirmOperationSubscription?.unsubscribe();
  }

  updatePhone() {
    this.updatingPhoneNumber = true;
    this._service.updatePhoneNumber(this.setting.phone, this.setting.areaCode)
      .pipe(finalize(() => this.updatingPhoneNumber = false))
      .subscribe({
        next: (res) => {
          const modalRef = this._modalService.open(SecurityCodeDialog, Constants.modalOptions.medium)
          this._confirmOperationSubscription = modalRef.componentInstance.confirmOperation.subscribe((securityCode: string) => {

            modalRef.componentInstance.isSending = true;
            this._authService.confirmPhoneNumber(this.setting.phone, this.setting.areaCode, securityCode)
              .pipe(finalize(() => modalRef.componentInstance.isSending = false))
              .subscribe({
                next: () => {
                  this.currentPhone = _.clone(this.setting.phone);
                  modalRef.close();
                  this._notificationService.showSuccess(
                    'Phone successfuly updated',
                    'Success!'
                  );
                },
                error: (error) => {
                  if (error?.code === "InvalidToken") {
                    this._notificationService.showError(error.description, "Error");
                  } else {
                    this._notificationService.showError(error?.message || "Couldn't process the confirmation code", "Error");
                  }
                }
              })
          })
        },
        error: (error) => {
          this._notificationService.showError(
            error?.message || 'Unable to update phone number',
            'Profile Service'
          );
        }
      });
  }

  changeTwoFactor() {
    if(this.isEditingUserProfile){
      return;
    }
    
    this._spinner.show();
    this._service.updateTwoFactor(this.setting.isTwoFactor)
      .pipe(finalize(() => this._spinner.hide()))
      .subscribe({
        next: (res) => {
          this._notificationService.showSuccess(
            'Two factor authentication setting successfuly updated',
            'Success!'
          );
        },
        error: (error) => {
          this._notificationService.showError(
            error?.message || 'An error occurred updating two factor authentication setting',
            'Error!'
          );
        }
      })
  }

  // TODO verify
  onNewPhoneNumberChanged = () => {
    if (!this.setting.phone && !this.currentPhone) {
      this.setting.isTwoFactor = false;
      this.changeTwoFactor();
    }
  }

  deletePhone() {
    Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to delete this phone number ${this.currentPhone}?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result: SweetAlertResult) => {
      if (!result.value) {
        return;
      }

      this.deletingPhoneNumber = true;
      this._service.deletePhoneNumber()
        .pipe(finalize(() => this.deletingPhoneNumber = false))
        .subscribe({
          next: (res) => {
            this.currentPhone = null;
            this._notificationService.showSuccess(
              'Phone number deleted successfully.',
              'Success!'
            );
          },
          error: (error) => {
            this._notificationService.showError(
              error?.message || 'Unable to delete phone number',
              'Profile Service'
            );
          }
        });
    });
  }
}
