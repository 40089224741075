import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanApplication, Mortgage } from 'src/app/models';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { ErrorMessage } from 'src/app/shared/models/error.model';
import { PriceCell } from '../../models/pricing/price-cell.model';
import { PricingCredentials } from '../../models/pricing/pricing-credentials.model';
import { Adjustment } from '../../models/pricing/pricing-quote.model';
import { ProductSearchRequestInfo } from '../../models/pricing/product-search-request-info.model';
import { PricingTransactionType } from '../pricing-details/pricing-details.component';

@Component({
  selector: 'loan-pricing-search-dialog',
  templateUrl: 'loan-pricing-search-dialog.component.html',
  styleUrls: ['loan-pricing-search-dialog.component.scss'],
})
export class LoanPricingSearchDialogComponent extends ApplicationContextBoundComponent implements OnInit {

  @Output()
  scenariosChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  lockStatus: string;

  @Input()
  credentials: PricingCredentials;

  error: ErrorMessage | undefined;

  currentMortgage: Mortgage;

  currentApplication: LoanApplication;

  isLoanReadOnly: boolean = false;

  productSearchRequestInfo: ProductSearchRequestInfo = new ProductSearchRequestInfo();

  transactionType: PricingTransactionType;

  constructor(
    private readonly injector: Injector,
    public activeModal: NgbActiveModal
  ) {
    super(injector);
  }

  ngOnInit() {
  }

  onScenariosAdded = () => {
    this.scenariosChanged.emit();
  }

  onProductPriceSelectedForApplicationFinished = (e) => {
    this.activeModal.close('success');
  }

  onProductRepriceSelectedForApplicationFinished = (e) => {
    this.activeModal.close('success');
  }

  handlePricingCompleted = () => {
    this.activeModal.close('success');
  }
}

export class ProductPricingDetails {
  prices: PriceCell[] = [];
  notesAndAdvisories: string[] = [];
  adjustments: Adjustment[] = [];

  constructor(prices: PriceCell[], notesAndAdvisories: string[], adjustments: Adjustment[]) {
    this.prices = prices;
    this.notesAndAdvisories = notesAndAdvisories;
    this.adjustments = adjustments;
  }
}
