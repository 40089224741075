import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import _, { debounce } from 'lodash';
import { Subscription, firstValueFrom } from 'rxjs';
import { LoanApplication, UserType } from 'src/app/models';
import { LosService } from 'src/app/services/los.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { EnabledBusinessChannel } from '../../models/business-channel.model';
import { PricingService } from '../../services/pricing.service';
import { getErrorMessageOrDefault } from 'src/app/shared/utils/error-utils';
import { SpinnerComponent } from 'src/app/shared/components/spinner/spinner.component';

@Component({
  selector: 'polly-pricer',
  templateUrl: 'polly-pricer.component.html',
  styleUrls: ['./polly-pricer.component.scss']
})
export class PollyPricerComponent extends ApplicationContextBoundComponent implements OnInit {

    @ViewChild("spinner")
    spinner: SpinnerComponent;

  @Input()
  isQuickPricer: boolean = false;

  @Input()
  set enabledChannels(enabledChannels: EnabledBusinessChannel[]) {
    this.error = null;
    this._enabledChannels = enabledChannels;

    if (!enabledChannels.length) {
      this.error = "There are no pricing profiles defined, please contact your system administrator.";
      return;
    }
    this.groupSelectedVendorPricingProfiles();

    if (this.enabledChannels.length == 1) {
      this.selectedProfileChannel = this.enabledChannels[0];
    } else {
      this.selectedProfileChannel = this.enabledChannels.find(x => x.channel == this.applicationContext.application.channel);
      if (!this.selectedProfileChannel) {
        this.error = "There are no pricing profiles defined for this loan channel, please contact your system administrator.";
      }
    }
  }

  get enabledChannels(): EnabledBusinessChannel[] {
    return this._enabledChannels;
  }

  @Output()
  pricingCancelled: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  pricingCompleted: EventEmitter<LoanApplication> = new EventEmitter<LoanApplication>();

  preparingForPricing: boolean = false;
  losSyncCompleted: boolean = false;
  loanCreateAttempts: number = 0;
  loanSyncAttempts: number = 0;

  protected error: string = null;

  protected application: LoanApplication;
  protected pollyPricerUrl: SafeResourceUrl;
  protected selectedProfileChannel: EnabledBusinessChannel = null;

  protected groupedVendorProfiles: any[] = [];

  private _loanInfoChangesSubscription: Subscription;
  private _enabledChannels: EnabledBusinessChannel[] = [];

  constructor(
    private readonly injector: Injector,
    private readonly _losService: LosService,
    private readonly _pricingService: PricingService
  ) {
    super(injector);
    this._loanInfoChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe((context) => {
      if (context.application) {
        this.application = context.application;
        this.initializeDebounce();
      }
    });
  }

  async ngOnInit() {
    if (this.applicationContext?.application?.applicationId) {
      this.application = this.applicationContext.application;
      this.initializeDebounce();
    } else if (this.isQuickPricer) {
      this.initializeDebounce();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }

  onCancelClicked() {
    this.pricingCancelled.emit();
  }

  protected onPollyPriceChannelChanged = async (selectedChannel: EnabledBusinessChannel) => {
    await this.getPollyPricerUrl();
  }

  initializeDebounce = debounce(this.initialize, 1000);

  private async initialize() {
    if (this.isQuickPricer) {
      return;
    }

    if (this.applicationContext.userPermissions.userType == UserType.Tpo) {
      if (!this.losSyncCompleted) {
        if (!this.applicationContext.application.losIdentifier) {
          while (this.loanCreateAttempts < 3 && !this.losSyncCompleted) {
            this.loanCreateAttempts++;
            await this.autoCreateLosLoan();
          }
        } else {
          await this.getPollyPricerUrl();
        }
      } else {
        await this.getPollyPricerUrl();
      }
    }
  }

  private autoCreateLosLoan = async () => {
    this.preparingForPricing = true;
    try {
      const losAppOpResult = await firstValueFrom(
        this._losService.autoCreateLosLoan(this.application.applicationId),
      );
      this.error = undefined;
      this.losSyncCompleted = true;
      this.applicationContextService.updateMortgageAndApplication(losAppOpResult.application?.mortgageLoan,
        losAppOpResult.application, losAppOpResult.customData);
    } catch (e) {
      console.error(e);
      this.error = e.message?.replace('{' + this.application.losIdentifier + '}', this.application.refNumber) || "There was an error attempting to prepare your loan for pricing. Please contact your AE.";
    } finally {
      this.preparingForPricing = false;
    }
  }

  private groupSelectedVendorPricingProfiles() {
    this.groupedVendorProfiles = [];
    if (!this.enabledChannels?.length) {
      return;
    }
    this.groupedVendorProfiles = _.chain(this.enabledChannels)
      .groupBy("externalCompanyId")
      .map((compGrp, compId) => {
        const externalCompanyName = compId === "undefined"
          ? ""
          : this.getExternalCompanyNameById(Number(compId));
        const branchGrping = !externalCompanyName ? compGrp : _.chain(compGrp)
          .groupBy("branchId")
          .map((branchGrp, branchId) => {
            const branchName = branchId === "undefined"
              ? ""
              : this.getBranchNameById(Number(branchId));
            return {
              branchName,
              branchCreds: branchGrp
            };
          }).value();
        return {
          externalCompanyName,
          externalCompanyCreds: branchGrping
        };
      })
      .orderBy("externalCompanyName")
      .value();
  }

  private getPollyPricerUrl = async () => {
    this.preparingForPricing = true;
    try {
      const suffixUrl = await firstValueFrom(this._pricingService.getPollyPricerUrl(this.selectedProfileChannel.channel, 
        this.application?.applicationId, this.selectedProfileChannel.credentialId));
      this.pollyPricerUrl = this.selectedProfileChannel?.url + suffixUrl;
      if (this.application && this.application.losIdentifier) {
        this.pollyPricerUrl += `&loanId=${this.application.losIdentifier}`;
      }
    } catch (error) {
      const errorMessage = getErrorMessageOrDefault(error, {
        defaultMessage:
          'An error occurred while getting Polly Pricer Iframe URL.',
      });
      this.error = errorMessage;
    } finally {
      this.preparingForPricing = false;
    }
  }

  private getExternalCompanyNameById(externalCompanyId: number) {
    const matchingCompany = this.applicationContext?.globalConfig?.externalCompanies.find(ec => ec.externalCompanyId === externalCompanyId);
    return matchingCompany?.name || '';
  }

  private getBranchNameById(branchId: number) {
    const matchingBranch = this.applicationContext?.globalConfig?.branches.find(b => b.branchId === branchId);
    return matchingBranch?.branchName || '';
  }
}
