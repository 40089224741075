import { Injectable } from "@angular/core";
import { catchError, Observable, of, Subject, switchMap } from "rxjs";
import { DataService } from "src/app/core/services/data.service";
import { RecentAgent } from "src/app/models";
import { PipelineItem } from "src/app/models/pipeline/pipeline-item.model";
import { Agent, AgentCompany, AgentFull } from "../../app-details/models/agent.model";
import { Lead } from "../../leads/models/lead.model";
import { AgentForTable } from "../models/agent-for-table.model";
import { AgentList } from "../models/agent-list.model";
import { AgentNote } from "../models/agent-note.model";
import { BulkUpdateAgent } from "../models/bulk-update-agent.model";
import { Tag } from "../../admin/tag-management/models/tag.model";

@Injectable({
  providedIn: 'root',
})
export class AgentsService {

  private readonly _agentsTableChanges$: Subject<AgentForTable> = new Subject<AgentForTable>();
  
  get agentsTableChanges$() { return this._agentsTableChanges$.asObservable(); }

  constructor(private _dataService: DataService) { }

  updateAgentsTable = (agent: AgentForTable) => {
    this._agentsTableChanges$.next(agent);
  }

  saveAgent = (agentFull: AgentFull, suppressInvite: boolean = true): Observable<AgentFull> => {
    const url = `api/Agent/UpsertAgent?suppressInvite=${suppressInvite}`;
    return this._dataService.post(url, agentFull);
  }

  saveAgentCompany = (agentCompany: AgentCompany): Observable<AgentCompany> => {
    const url = `api/Agent/UpsertAgentCompany`;
    return this._dataService.post(url, agentCompany);
  }

  associateAgentToAgentCompant = (agentId: number, agentCompanyId: number): Observable<void> => {
    const url = `api/Agent/${agentId}/AssociateWithAgentCompany/${agentCompanyId}`;
    return this._dataService.post(url, null);
  }

  getLists = (): Observable<AgentList[]> => {
    return this._dataService.get("api/Agent/lists");
  }

  getList = (agentListId: number): Observable<AgentList> => {
    return this._dataService.get(`api/Agent/lists/${agentListId}`);
  }

  getAllAgents = (): Observable<Agent[]> => {
    const url = `api/Agent/AllAgent?loadApplicationCounts=true`;
    return this._dataService.get(url);
  }

  getAllApplicationInfo = (agentId: number): Observable<PipelineItem[]> => {
    const url = 'api/Agent/' + agentId + '/GetAllApplicationInfo/';
    return this._dataService.get(url);
  }

  getAllLeads = (agentId: number): Observable<Lead[]> => {
    const url = 'api/Agent/' + agentId + '/GetAllLeads';
    return this._dataService.get(url);
  }

  getAgent = (agentId: number): Observable<AgentFull> => {
    return this.openAgent(agentId).pipe(
      catchError((error) => of(error)),
      switchMap(() => this._dataService.get(`api/Agent/GetAgent/${agentId}`))
    );
  };

  inviteAgent = (agentId: number): Observable<any> => {
    const url = `api/Agent/InviteAgent/${agentId}`;
    return this._dataService.post(url, {});
  };

  getAgentNotes = (agentId: number): Observable<AgentNote[]> => {
    const url = 'api/Agent/' + agentId + '/notes';
    return this._dataService.get(url);
  }

  postAgentNote = (agentId: number, note: AgentNote) => {
    const url = 'api/Agent/' + agentId + '/note';
    return this._dataService.post(url, note);
  }

  postList = (name: string) => {
    const url = 'api/Agent/lists';
    return this._dataService.post(url, { name });
  }

  deleteAgent = (agentId: number) => {
    const url = 'api/Agent/DeleteAgent/' + agentId;
    return this._dataService.delete(url);
  }

  openAgent = (agentId: number): Observable<RecentAgent> => {
    return this._dataService.get(`api/Agent/${agentId}/open-agent`);
  }

  bulkUpdateAgent = (req: BulkUpdateAgent): Observable<any[]> => {
    return this._dataService.post('api/Agent/bulk-update', req);
  }

  addTagToAgent(agentId: number, tagId: number): Observable<Tag> {
      return this._dataService.post(
        'api/Agent/tags/' + tagId + '/agents/' + agentId,
        {}
      );
  }
  
  deleteTagFromAgent(agentId: number, tagId: number): Observable<any> {
      return this._dataService.delete(
        'api/Agent/tags/' + tagId + '/agents/' + agentId
      );
  }
}
