import { DocFile } from "../doc-file.model";

export class SyncDocFile {
    doSync: boolean;
    docFile: DocFile;
    error: string;
    globalId: string;
    sync: DocSyncStatus;
    disabled: boolean;
    checked: boolean;
}

export enum DocSyncStatus {
    Synced = "Synced",
    PullFromLos = "PullFromLos",
    PushToLos = "PushToLos",
    Unattached = "Unattached",
    Unmapped = "Unmapped",
    Deleted = "Deleted"
}