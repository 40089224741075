import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { LoanService } from 'src/app/services/loan';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { DocFile } from '../../loan-docs/models/doc-file.model';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { ApplicationContext, LoanDoc } from 'src/app/models';

@Component({
  selector: 'uw-decision',
  templateUrl: 'uw-decision.component.html',
})

export class UWDecisionComponent extends ApplicationContextBoundComponent implements OnInit, OnDestroy {

    iframeUrl: string;

    errorMessage: string;

    testData: any;

    private _loanInfoChangesSubscription: Subscription;

  constructor(
    injector: Injector,
    private readonly _loanService: LoanService,
    private readonly _spinner: NgxSpinnerService,
  ) {
    super(injector);
   }

  ngOnInit() {

    if (!this.applicationContext?.application) {
      this._loanInfoChangesSubscription =
        this.applicationContextService.loanInfoChanges.subscribe((context) => {
          if (context.application) {
            this.initialize(context);
          }
        });
    } else {
      this.initialize(this.applicationContext);
    }
  }

  initialize = (context: ApplicationContext) => {
    this._spinner.show();
    this._loanService.getRecentUWDoc(context.application.applicationId).subscribe({
        next: (uwDoc: LoanDoc) => {
            if (uwDoc && uwDoc.docFiles && uwDoc.docFiles.length) {
              // Order by date descending and then get the first item
              uwDoc.docFiles.sort((a, b) => new Date(b.dateUpdated).getTime() - new Date(a.dateUpdated).getTime());
              this.viewFile(uwDoc.docFiles[0]);
            }
        },
        error: (err) => {
            this.errorMessage = err.message;
        }
    }).add(()=> {this._spinner.hide();})

  }

  viewFile = (docFile: DocFile) => {
    if (docFile.fileData) {
      const decodedData = window.atob(docFile.fileData);
      const uInt8Array = new Uint8Array(decodedData.length);
      for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i);
      }
      const blob = new Blob([uInt8Array], { type: docFile.mimeType });
      const url = window.URL.createObjectURL(blob);
      this.iframeUrl = url;
    }
  }

  ngOnDestroy(): void {
    if (this._loanInfoChangesSubscription) {
      this._loanInfoChangesSubscription.unsubscribe();
    }
  }
}