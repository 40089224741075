<admin-header 
  [user]="user" 
  (mentionsClicked)="onMentionsStreamToggled($event)"
  (niceInContactClicked)="onNiceInContactToggled($event)" 
  (notificationsClicked)="onNotificationsToggled($event)"
  (alertsCreated)="onAlertsCreated($event)" 
  (conversationsToggled)="onConversationsToggled($event)"
  [unreadConversations]="checkUnreadConversations()" 
  (newLeadClicked)="onNewLeadToggled()"
  (newAgentClicked)="onNewAgentToggled()" 
  (newContactClicked)="onNewContactToggled()"
  (newApplicationClicked)="onNewApplicationToggled()" 
  (viewLeadClicked)="viewViewLeadModal($event?.leadId)"
  (dialpadClicked)="onDialpadToggled()"
  (viewAgentClicked)="onEditRequestedForAgent($event)">
</admin-header>

<div class="row">
  <div *ngIf="niceInContactVisible" class="col-md-3 pe-0">
    <div id="niceInContact" class="card" style="height: calc(100vh - 53px);position: sticky;top: 53px;border-radius: 0;">
      <div class="card-body" style="height: 100%; padding-top: 0.75rem;">
        <div class="row" style="height: 100%;">
          <div class="col-md-12 d-flex flex-column" style="height: 100%; overflow-y: hidden;">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': currentTab === 'ring-central'}"
                  (click)="currentTab = 'ring-central';">
                  Ring Central (Phone)
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': currentTab === 'nice-in-contact'}"
                  (click)="currentTab = 'nice-in-contact';">
                  Nice In Contact (Dialer)
                </a>
              </li>
            </ul>

            <div class="tab-content flex-grow-1 overflow-hidden">
              <div style="height: 100%;" *ngIf="currentTab === 'ring-central'">
                <iframe height="100%" width="100%" id="rc-widget" allow="microphone"
                  src="https://apps.ringcentral.com/integration/ringcentral-embeddable/latest/app.html?clientId=6DJ7cVVHsgldD3cvWNDWQJ&appServer=https://platform.devtest.ringcentral.com&redirectUri=https://advantage1st.lodasoft.com/">
                </iframe>
              </div>
              <div style="height: 100%;" *ngIf="currentTab === 'nice-in-contact'">
                <iframe height="100%" width="100%" src="https://cxagent.nicecxone.com/home?app=cxa"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="dialpadVisible" class="col-md-3 pe-0">
    <div id="dial-a-number" class="card" style="height: calc(100vh - 53px);position: sticky;top: 53px;border-radius: 0;">
      <div class="card-body" style="height: 100%; padding-top: 0.75rem;">
        <div class="row" style="height: 100%;">
          <div class="col-md-12 d-flex flex-column" style="height: 100%; overflow-y: hidden;">

            <div class="tab-content flex-grow-1 overflow-hidden">
              <div style="height: 100%;">
                <dial-number-and-call-history (closeDialClicked)="onDialpadToggled()"></dial-number-and-call-history>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="{'col-md-9 ps-0' : niceInContactVisible || dialpadVisible, 'col-md-12' : !niceInContactVisible && !dialpadVisible }">
    <router-outlet></router-outlet>
  </div>
</div>
<!-- <call-control-panel></call-control-panel> -->

<lead-route-leads></lead-route-leads>

<drawer [title]="'New Lead'" [name]="'newLeadDrawer'" [templateRef]="newLeadContentRef" [options]="newLeadDrawerOptions"
  (closed)="onNewLeadDrawerClosed()">
</drawer>
<ng-template #newLeadContentRef>
  <lead-editor (newLeadAdded)="onNewLeadDrawerClose($event)" (closeDrawer)="onNewLeadCancelled()">
  </lead-editor>
</ng-template>

<drawer [title]="'New Agent'" [name]="'newAgentDrawer'" [templateRef]="newAgentContentRef"
  [options]="newAgentDrawerOptions" (closed)="onNewAgentDrawerClosed()">
</drawer>
<ng-template #newAgentContentRef>
  <upsert-agent [agentId]="0" [isDrawer]="true" (onAddEditAgent)="onNewAgentDrawerClose($event)">
  </upsert-agent>
</ng-template>

<drawer [title]="'New Contact'" [name]="'newContactDrawer'" [templateRef]="newContactContentRef"
  [options]="newContactDrawerOptions" (closed)="onNewContactDrawerClosed()">
</drawer>
<ng-template #newContactContentRef>
  <new-contact [isDrawer]="true" (closeDrawer)="onNewContactDrawerClose()"
    (contactCreated)="onNewContactCreated($event)">
  </new-contact>
</ng-template>

<drawer [title]="'New Application'" [name]="'newApplicationDrawer'" [templateRef]="newApplicationContentRef"
  [options]="newApplicationDrawerOptions" (closed)="onNewApplicationDrawerClosed()">
</drawer>
<ng-template #newApplicationContentRef>
  <new-application (closeDrawer)="onNewApplicationDrawerClose()"></new-application>
</ng-template>

<drawer #mentionsDrawer [title]="'Mentions'" [name]="'mentionsDrawer'" [templateRef]="mentionsDrawerContentRef"
  [options]="mentionsDrawerOptions" (closed)="onMentionsDrawerClosed()">
</drawer>
<ng-template #mentionsDrawerContentRef>
  <mentions-stream (mentionClicked)="onMentionClicked($event)"></mentions-stream>
</ng-template>

<drawer [title]="'Alerts & Notifications'" #alerts [name]="'alertsDrawer'" [templateRef]="alertsDrawerContentRef"
  [options]="alertsDrawerOptions" (closed)="onNotificationsDrawerClosed()">
</drawer>
<ng-template #alertsDrawerContentRef>
  <notifications-list [data]="notifications" (clickedGotoDetails)="notificationDetails($event)">
  </notifications-list>
</ng-template>

<drawer [title]="'Loan Activity'" [options]="loanActivityDrawerOptions" [name]="'loanActivityInternalMessageDrawer'"
  [templateRef]="loanActivityRef" (closed)="onMentionDetailsDrawerClosed()">
</drawer>
<ng-template #loanActivityRef>
  <div style="height: 90%; padding: 12px;">
    <loan-activity [refreshMentions]="refreshMentions" [idOfMessageToScrollTo]="messageId" [showFilters]="true"
      [activityTab]="'InternalMessage'" [appId]="mentionApplicationId" [mentionTrackingGuid]="mentionTrackingGuid"
      *ngIf="mentionMessageScope == 'InternalMessage'">
    </loan-activity>
  </div>
</ng-template>

<drawer [title]="'Editing Task'" [options]="taskEditorDrawerOptions" [name]="'taskEditorDrawer'"
  [templateRef]="taskEditorRef" (closed)="onMentionDetailsDrawerClosed()"></drawer>
<ng-template #taskEditorRef>
  <img *ngIf="taskMentionTrackingUrl" [src]="taskMentionTrackingUrl" class="tracker-image" width="1" height="1">
  <task-editor *ngIf="mentionMessageScope == 'TaskNote' && mentionLoanDocTask" style="height: 90%"
    [hostedInModal]="false" [refreshMentions]="refreshMentions" [task]="mentionLoanDocTask"></task-editor>
</ng-template>

<drawer [title]="'Conversations'" [name]="'conversationsDrawer'" [templateRef]="conversationsContentRef"
  [options]="conversationsDrawerOptions" (closed)="onConversationsDrawerClosed()">
</drawer>
<ng-template #conversationsContentRef>
  <conversations-panel
    #conversationList
    [allConversations]="allConversations"
    [globalConfig]="globalConfig"
    [conversationTargetPersonContext]="conversationTargetPersonContext"
    [userId]="conversationsUserId"
    [loanStatus]="loanStatus"
    [senderAvatar]="avatarUrl"
    [currentUserPhoneNumber]="currentUserPhoneNumber"
    [updatingConversation]="updatingConversation"
    (editAgent)="onEditRequestedForAgent($event)"
    (editLead)="onEditRequestedForLeadId($event)"
    (editBorrower)="onEditRequestedForContact($event)"
    (impersonatedUserChanged)="onImpersonatedUserChanged($event)"
    [borrowerId]="alertBorrowerId"
    [agentId]="alertAgentId">
  </conversations-panel>
</ng-template>

<drawer [title]="'Edit Agent'" #editAgentDrawer [name]="'editAgentDrawer'" [templateRef]="editAgentContentRef"
  [options]="editAgentDrawerOptions" (closed)="onEditAgentDrawerClosed()">
</drawer>
<ng-template #editAgentContentRef>
  <upsert-agent [agentId]="agentIdToEdit" [isDrawer]="true" (closeDrawer)="onEditAgentDrawerClose()">
  </upsert-agent>
</ng-template>

<drawer [title]="'Edit Lead'" #editLeadDrawer [name]="'editLeadDrawer'" [options]="editLeadDrawerOptions"
  (opened)="onEditLeadDrawerOpened()" (closed)="onEditLeadDrawerClosed()">
</drawer>

<drawer [title]="'Edit Contact'" #editContactDrawer [name]="'editContactDrawer'" [templateRef]="editContactContentRef"
  [options]="editContactDrawerOptions" (closed)="onEditContactDrawerClosed()">
</drawer>
<ng-template #editContactContentRef>
  <borrower-editor [borrowerId]="borrowerIdToEdit" (borrowerSaved)="onEditContactDrawerClose()"
    (borrowerEditCancelled)="onEditContactDrawerClose()">
  </borrower-editor>
</ng-template>

<drawer [title]="'Generic Drawer'" #genericDrawer [name]="'genericDrawer'" [options]="genericDrawerOptions">
</drawer>

<favicon-loader [faviconUrl]="faviconUrl"></favicon-loader>
