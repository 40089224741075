import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { GlobalCreditConfig } from '../models/global-credit-config.model';

@Injectable({
  providedIn: 'root',
})
export class CreditConfigService {
  constructor(private readonly _dataService: DataService) {}

  getGlobalCreditConfigList = (): Observable<GlobalCreditConfig[]> => {
    return this._dataService.get(`api/super-admin/global-credit-config`);
  };

  insertGlobalCreditConfig = (
    creditConfig: GlobalCreditConfig
  ): Observable<GlobalCreditConfig> => {
    return this._dataService.post(
      `api/super-admin/global-credit-config`,
      creditConfig
    );
  };

  updateGlobalCreditConfig = (
    creditConfig: GlobalCreditConfig
  ): Observable<GlobalCreditConfig> => {
    return this._dataService.post(
      `api/super-admin/global-credit-config/${creditConfig.id}`,
      creditConfig
    );
  };

  deleteGlobalCreditConfig = (id: number): Observable<any> => {
    return this._dataService.delete(
      `api/super-admin/global-credit-config/${id}`
    );
  };
}
