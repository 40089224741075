<div class="card-header filter-heading">
  <div class="row align-items-center">
    <div class="col">
      <!-- <button class="btn btn-sm btn-outline-light" (click)="onClickedFilterSectionVisible()">
        <i class="fas fa-arrow-left"></i>
      </button> -->
      <h4 class="card-title" style="display:inline; font-size: 21px;"><i class="fas fa-filter text-secondary ms-1"></i>
      </h4>
      <b style="font-size: 20px"> Filters </b>
    </div>
    <div class="col-auto">
      <a href="javascript: void(0);" (click)="clearAll()" class="btn btn-sm btn-outline-light d-inline-block">Clear
        All</a>
    </div>
  </div>
</div>
<div class="accordion overflow-setter" id="pipeline-filter-accordion">
 
  <div class="accordion-item">
    <h5 class="accordion-header m-0" id="loanPurposes">
      <button class="accordion-button fw-semibold" type="button" data-bs-toggle="collapse"
        data-bs-target="#collapseChannel" aria-expanded="false" aria-controls="collapseChannel">
        Channel
      </button>
    </h5>
    <div id="collapseChannel" class="accordion-collapse collapse show" aria-labelledby="channels">
      <div class="accordion-body">
        <div class="form-check" *ngFor="let channel of channelsThatCanBeUsedForFiltering">
          <input class="form-check-input" [checked]="channel.isChecked" type="checkbox" value=""
            id="channel{{channel.value}}" (change)="onChannelCheckChanged($event, channel)" />
          <label class="form-check-label" for="channel_{{channel.value}}">
            {{channel.name}}
          </label>
          <span *ngIf="applicationCountsByChannel[channel.value]"
            class="badge bg-info mx-2">{{applicationCountsByChannel[channel.value]}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="accordion-item">
    <h5 class="accordion-header m-0" id="loanPurposes">
      <button class="accordion-button fw-semibold" type="button" data-bs-toggle="collapse"
        data-bs-target="#collapseLoanPurpose" aria-expanded="false" aria-controls="collapseLoanPurpose">
        Loan Purpose
      </button>
    </h5>
    <div id="collapseLoanPurpose" class="accordion-collapse collapse show" aria-labelledby="loanPurposes">
      <div class="accordion-body">
        <div class="form-check" *ngFor="let loanPurpose of loanPurposesThatCanBeUsedForFiltering">
          <input class="form-check-input" [checked]="loanPurpose.isChecked" type="checkbox" value=""
            id="loanPurpose_{{loanPurpose.loanPurposeId}}" (change)="onLoanPurposeCheckChanged($event, loanPurpose)" />
          <label class="form-check-label" for="loanPurpose_{{loanPurpose.loanPurposeId}}">
            {{loanPurpose.loanPurposeName}}
          </label>
          <span *ngIf="applicationCountsByLoanPurpose[loanPurpose.loanPurposeId]"
            class="badge bg-info mx-2">{{applicationCountsByLoanPurpose[loanPurpose.loanPurposeId]}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h5 class="accordion-header m-0" id="loanStatuses">
      <button class="accordion-button fw-semibold" 
        type="button" data-bs-toggle="collapse" data-bs-target="#collapseLoanStatuses"
        aria-expanded="false" aria-controls="collapseLoanStatuses">
        Loan Status
      </button>
    </h5>
    <div id="collapseLoanStatuses" class="accordion-collapse collapse show" aria-labelledby="loanStatuses">
      <div class="form-check form-switch form-switch-success mt-1 toggle-archived">
        <input class="form-check-input form-check-input-md" type="checkbox" name="showArchived" id="showArchived"
          [(ngModel)]="isShowAsArchiveActive" (click)="onShowArchivedToggleClick($event)">
        <label class="form-check-label label-archived" for="showArchived">
            Show Archived
        </label>
      </div>
      <div class="accordion-body">
        <div class="form-check" *ngFor="let loanStatus of loanStatusesThatCanBeUsedForFiltering">
          <input class="form-check-input" [checked]="loanStatus.isChecked" type="checkbox" value=""
            id="loanStatus_{{loanStatus.loanStatusId}}" (change)="onLoanStatusCheckChanged($event, loanStatus)" />
          <label class="form-check-label" for="loanStatus_{{loanStatus.loanStatusId}}">
            {{loanStatus.loanStatusName}}
          </label>
          <span *ngIf="applicationCountsByLoanStatus[loanStatus.loanStatusId]"
            class="badge bg-info mx-2">{{applicationCountsByLoanStatus[loanStatus.loanStatusId]}}</span>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="applicationContext.isTpo">
    <div class="accordion-item" *ngFor="let role of roles | filter: { tpoEnabled : true} | sort: 'asc':'order'">
      <h5 class="accordion-header m-0" id="role_{{role.roleId}}">
        <button class="accordion-button fw-semibold" type="button" data-bs-toggle="collapse"
          [attr.data-bs-target]="'#collapseRole_' + role.roleId" aria-expanded="false"
          [attr.aria-controls]="'collapseRole_' + role.roleId">
          {{role.roleName}}
        </button>
      </h5>
      <div id="collapseRole_{{role.roleId}}" class="accordion-collapse collapse show"
        [aria-labelledby]="'role_' + role.roleId">
        <div class="accordion-body">
          <ng-container *ngFor="let user of users | sort: 'asc':'displayName'">
            <div class="form-check" *ngIf="user.roleId == role.roleId">
              <input class="form-check-input" type="checkbox" [checked]="user.isChecked" value="{{user.id}}"
                name="user_{{user.roleId}}" id="user_{{user.roleId}}"
                (change)="onUserRoleCheckChanged($event, role, user)">
              <label class="form-check-label" for="user_{{user.roleId}}">
                {{user.displayName}}
              </label>
              <span *ngIf="user.appCount" class="badge bg-info mx-2">{{user.appCount}}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!applicationContext.isTpo">
    <div class="accordion-item"
      *ngFor="let role of roles | filter: { isLoanContact : true, roleId: 1 } | sort: 'asc':'order'">
      <h5 class="accordion-header m-0" id="role_{{role.roleId}}">
        <button class="accordion-button fw-semibold" type="button" data-bs-toggle="collapse"
          [attr.data-bs-target]="'#collapseRole_' + role.roleId" aria-expanded="false"
          [attr.aria-controls]="'collapseRole_' + role.roleId">
          {{role.roleName}}
        </button>
      </h5>
      <div id="collapseRole_{{role.roleId}}" class="accordion-collapse collapse show"
        [aria-labelledby]="'role_' + role.roleId">
        <div class="accordion-body">
          <ng-container *ngFor="let user of users | sort: 'asc':'displayName'">
            <div class="form-check" *ngIf="user.roleId == role.roleId">
              <input class="form-check-input" type="checkbox" [checked]="user.isChecked" value="{{user.id}}"
                id="user_{{user.roleId}}" (change)="onUserRoleCheckChanged($event, role, user)">
              <label class="form-check-label" for="user_{{user.roleId}}">
                {{user.displayName}}
              </label>
              <span *ngIf="user.appCount" class="badge bg-info mx-2">{{user.appCount}}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>
