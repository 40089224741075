import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EnumerationItem } from 'src/app/models/simple-enum-item.model';
import { User } from 'src/app/models/user/user.model';
import { ZipCodeLookupResult } from 'src/app/models/zipcode-lookup-result.model';
import { Agent, AgentFull } from 'src/app/modules/app-details/models/agent.model';
import { Constants } from 'src/app/services/constants';
import { NotificationService } from 'src/app/services/notification.service';
import { ApplicationContextBoundComponent } from 'src/app/shared/components';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import * as _ from 'lodash';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RecordType } from 'src/app/modules/dialer/models/dial-list-record-basic.model';
import { ManualDialParams } from 'src/app/modules/dialer/models/manual-dial-params.model';
import { DialerService } from 'src/app/modules/dialer/services/dialer.service';
import { PhoneType } from 'src/app/modules/dialer/models/phone-type.model';
import { Address, ApplicationContext, Branch } from 'src/app/models';
import { AgentType } from 'src/app/modules/admin/company/models/agent-type.model';
import { AgentsService } from '../agents/services/agents.service';
import { ConversationService } from '../conversations/services/conversation.service';
import { AgentTagsComponent } from './agent-tags/agent-tags.component';
import { ContactTrackingTag } from '../admin/tag-management/models/tag.model';

@Component({
  selector: 'agent-info',
  templateUrl: './agent-info.component.html',
  styleUrls: ['./agent-info.component.scss'],
})
export class AgentInfoComponent extends ApplicationContextBoundComponent implements OnInit {
  @Input()
  set agentFull(agentFull: AgentFull) {
    if (agentFull) {
      if(agentFull?.agent?.mailingState){
        agentFull.agent.mailingState = agentFull.agent.mailingState.toLowerCase();
      }
      if (!agentFull.agent.tags) {
        agentFull.agent.tags = [];
      }
      this._agentFull = agentFull;
    } else {
      this._agentFull = new AgentFull();
    }
  }

  get agentFull(): AgentFull {
    return this._agentFull;
  }

  @Input()
  isManualDial: boolean = true;

  @Input()
  hideSaveCloseButton: boolean = false;

  @Input()
  agentType?: string; // used in loan info -> service providers

  @Input()
  isAgentTypeRequired?: boolean; // used in loan info -> service providers

  @Output()
  savedAgent = new EventEmitter<ISaveAgentEvent>();

  @Output()
  closed = new EventEmitter<boolean>();

  @Output()
  dialClicked = new EventEmitter<any>();

  @ViewChild('agentTags')
  agentTagsComponent: AgentTagsComponent;

  private _agentFull: AgentFull;

  agentInfoForm: UntypedFormGroup = new UntypedFormGroup(
    {
      firstName: new UntypedFormControl(""),
      lastName: new UntypedFormControl(""),
      email: new UntypedFormControl(""),
      agentListIds: new UntypedFormControl(""),
    }
  );

  firstNameControl: UntypedFormControl
  lastNameControl: UntypedFormControl
  emailControl: UntypedFormControl

  agentTypes: AgentType[] = [];
  users: User[] = [];
  states: EnumerationItem[];
  branches: Branch[] = [];

  currentPortalUserName: string;

  dialerEnabled: boolean;
  sendingInvite: boolean;
  smsEnabledForCompany: boolean;

  maxDate: Date = new Date();
  minDate: Date = new Date("1/1/1900");

  constructor(
    injector: Injector,
    private readonly _agentsService: AgentsService,
    private readonly _dialerService: DialerService,
    private readonly _conversationService: ConversationService,
    private readonly _notifyService: NotificationService,
    private readonly _modalService: NgbModal,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.applicationContextService.context.subscribe(context => {
      this.users = context.globalConfig.users;
      this.branches = context.globalConfig.branches;
      this.smsEnabledForCompany = context.userPermissions.smsAlertsEnabled;

      // if previously selected user became inactive/disabled then we are looking for a user among all users
      const userExist =
        this.agentFull.agent.agentContactUserId &&
        this.users.find(u => u.userCompanyGuid === this.agentFull.agent.agentContactUserId);
      if (!userExist) {
        this.fillMissingUser(context);
      }

      this.dialerEnabled = context.userPermissions.dialerEnabled;
      this.states = Object.keys(context.globalConfig.states).map(key => new EnumerationItem(context.globalConfig.states[key], key));
    })

    this.currentPortalUserName = this.agentFull.portalUserName;
    this.agentFull.agent.agentContactUserId = this.agentFull.agent.agentContactUserId || null;
    this.agentFull.agent.alertViaSms = this.agentFull.agent.agentId ? this.agentFull.agent.alertViaSms : this.smsEnabledForCompany

    this.setFormInitValues();
    this.setFormControls();
  }

  closeWindow = (): void => {
    this.closed.emit(true);
  }

  saveAgentData = (isClose: boolean) => {
    if (!this.agentInfoForm) {
      return;
    }
    this.agentInfoForm.markAllAsTouched();
    
    if (!this.agentInfoForm.valid) {
      return;
    }

    const suppressInvite = this.agentFull.portalUserName && this.currentPortalUserName === this.agentFull.portalUserName;
    this.trimAgentDetails();  
    this.prepareAgentFullForSave();
    this.savedAgent.emit({ agent: this.agentFull, isClosedAfterSave: isClose, suppressInvite: suppressInvite});
  }

  onSaveAndInviteAgent = () => {
    if (!this.agentInfoForm) return;
    this.agentInfoForm.markAllAsTouched();
    if (!this.agentInfoForm.valid) return;

    if (this.agentFull.portalUserName === this.agentFull.agent.email) {
      this.prepareAgentFullForSave();
      this.savedAgent.emit({ agent: this.agentFull, isClosedAfterSave: false, suppressInvite: false });
    } else {
      this.portalUserNameChange().then((result) => {
        if (!result) {
          return;
        }
        this.prepareAgentFullForSave();
        this.savedAgent.emit({ agent: this.agentFull, isClosedAfterSave: false, suppressInvite: false });
      }).catch(console.error);
    }
  }

  onCopyFromEmailClicked = () => {
    this.portalUserNameChange().catch(console.error);
  }

  sendInviteAgent = (agentId: number) => {
    this.sendingInvite = true;
    this._agentsService.inviteAgent(agentId).subscribe({
      next: () => {
        this.agentFull.accountStatus = 'Invited';
        this._notifyService.showSuccess('Success', 'Invite');
      },
      error: (error) => {
        const fallbackMessage = 'An error occurred while sending invite';
        const message = error?.message || fallbackMessage;
        console.error(fallbackMessage, error);
        this._notifyService.showError(message, 'Error');
      }
    }).add(() => this.sendingInvite = false);
  }

  protected onAddressChange(address: Partial<Address>): void {
    const agent = this.agentFull.agent;
    agent.mailingStreet = ''; // to reset the last populated address.

    setTimeout(() => {
      agent.mailingStreet = address.address1;
      agent.mailingCity = address.city;
      agent.mailingState = address.state;
      agent.mailingZip = address.zipCode;
    }, 200);
  }

  onZipCodeRelatedInfoChanged = (zipCode: ZipCodeLookupResult, modelNamePrefix: string) => {
    if (zipCode) {
      this.agentFull.agent[modelNamePrefix + "State"] = zipCode.state.toLowerCase();
      this.agentFull.agent[modelNamePrefix + "City"] = _.startCase(_.toLower(zipCode.city)); // titleCase string
      this.agentFull.agent[modelNamePrefix + "Zip"] = zipCode.zipcode;
    }
  }

  dial = (phoneNumber: string, phoneType: PhoneType): void => {

    if (this.isManualDial) {

      let data = {
        phoneNumber: phoneNumber,
        phoneType: phoneType,
        firstName: this.agentFull.agent.firstName,
        lastName: this.agentFull.agent.lastName,
        recordType: RecordType.Agent,
        recordId: this.agentFull.agent.agentId
      } as ManualDialParams;

      this._dialerService.openCallControlPanel(undefined, undefined, undefined, undefined, undefined, undefined, undefined, true, data)

    }
    else {
      this.dialClicked.emit({
        phoneNumber: phoneNumber,
        phoneType: phoneType,
        firstName: this.agentFull.agent.firstName,
        lastName: this.agentFull.agent.lastName,
        recordType: RecordType.Agent,
        recordId: this.agentFull.agent.agentId
      })
    }

  }

  onOpenSmsChat = (agent: Agent, phone: string) => {
    this._conversationService.openSmsChat({
      userPhone: phone,
      userName: `${agent.firstName} ${agent.lastName}`,
      openDrawer: true,
      openChat: true
    })
  }

  onEnableSmsToggled = (alertViaSms: boolean) => {
    this.agentFull.agent.alertViaSms = alertViaSms;
  }

  private prepareAgentFullForSave = () => {
    this.trimAgentDetails();
    this._agentFull.agent.tags = [];
    this.agentTagsComponent.agentTags.forEach(tag => {
      const contactTrackingTag: ContactTrackingTag = {
        tagId: tag.tagId
      }
      this._agentFull.agent.tags.push(contactTrackingTag);
    })
  }

  private portalUserNameChange = async (): Promise<boolean> => {
    if (this.agentFull.agent.email == this.currentPortalUserName) {
      return true;
    }

    const modalRef = this._modalService.open(ConfirmModalComponent, Constants.modalOptions.medium);
    if (this.currentPortalUserName) {
      modalRef.componentInstance.title = "Confirm Portal User Name Change";
      modalRef.componentInstance.text = "Are you sure you want to change the user name for this agent? \n\n Changing the agent's user name will require that the agent now login to the Portal using this new user name.";
    } else {
      modalRef.componentInstance.title = "Confirm Send Portal Invite";
      modalRef.componentInstance.text = "Are you sure you want to add a portal user name for this agent? \n\n Adding the user name will send out an invite to the agent if the user is new.";
    }

    const result = await modalRef.result;
    if (result === 'cancel') {
      return false;
    }

    this.agentFull.portalUserName = this.agentFull.agent.email;
    return true;
  }

  private setFormInitValues = () => {
    this.agentInfoForm.setControl('firstName', new UntypedFormControl(this.agentFull.agent.firstName));
    this.agentInfoForm.setControl('lastName', new UntypedFormControl(this.agentFull.agent.lastName));
    this.agentInfoForm.setControl('email', new UntypedFormControl(this.agentFull.agent.email));
  }

  private setFormControls = () => {
    this.firstNameControl = <UntypedFormControl>this.agentInfoForm.get('firstName');
    this.firstNameControl.valueChanges.subscribe(value => this.agentFull.agent.firstName = value);

    this.lastNameControl = <UntypedFormControl>this.agentInfoForm.get('lastName');
    this.lastNameControl.valueChanges.subscribe(value => this.agentFull.agent.lastName = value);

    this.emailControl = <UntypedFormControl>this.agentInfoForm.get('email');
    this.emailControl.valueChanges.subscribe(value => this.agentFull.agent.email = value);

  }

  private fillMissingUser = (context: ApplicationContext) => {
    const userFound = context.globalConfig.usersAll.find(u => u.userCompanyGuid === this.agentFull.agent.agentContactUserId);
    if (userFound) {
      this.users.push({ ...userFound })
    }
  }

  private trimAgentDetails = () => {
    if (!this.agentFull?.agent) return;
    this.agentFull.agent.firstName = this.agentFull.agent.firstName?.trim();
    this.agentFull.agent.lastName = this.agentFull.agent.lastName?.trim();
    this.agentFull.agent.email = this.agentFull.agent.email?.trim();
  }
}


export interface ISaveAgentEvent {
  agent: AgentFull;
  isClosedAfterSave: boolean;
  suppressInvite: boolean;
}

/**
 * Convert string to date if valid, otherwise return undefined
 * @param value string to convert to date
 * @returns Date or undefined
 */
function stringToDateOrUndefined(value: string): Date | undefined {
  if (!value) return undefined;

  const date = new Date(value);
  // Check if date is valid
  if (isNaN(date.getTime())) {
    console.error(`Invalid date: ${value}`);

    return undefined;
  }

  return date;
}
