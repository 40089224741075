export class UserRolesFilter {
  loanPurposes: number[] = [];
  loanStatuses: number[] = [];
  loanSubstatuses: number[] = [];
  channels: string[] = [];
  users: UserRolesFilterUser[] = [];
  branches: number[] = [];
  lenders: number[] = [];
  columnsToReturn?: string[] = [];
  keyDatesConfigurationIdsToReturn?: number[] = [];
  roleOrderIdToShowInContacts: number | null;
  isShowAsArchiveActive?: boolean;
}

export class UserRolesFilterUser {
  userId: string;
  roleId: number;

  constructor(roleId: number, userId: string) {
    this.roleId = roleId;
    this.userId = userId
  }
}
