import {
  AfterViewInit,
  Component, Injector,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import * as feather from 'feather-icons';
import { firstValueFrom, Subscription } from 'rxjs';
import { ApplicationContext, ChannelEnum } from 'src/app/models';
import { PricingVendor } from 'src/app/models/pricing/pricing-vendor';
import { UrlaMenuComponent } from 'src/app/modules/urla/urla-menu/urla-menu.component';
import { Constants } from 'src/app/services/constants';
import { MenuService } from 'src/app/services/menu.service';
import { UrlaValidationService } from 'src/app/services/urla-validation.service';
import { v4 as uuidv4 } from 'uuid';
import { ApplicationContextBoundComponent } from "../../../../shared/components";
import { MenuItemStatus } from '../../models/enums/menu-item-status.enum';
import { MenuItemComponent } from '../menu-item/menu-item.component';
import { TpoFeaturePermissions } from '../tpo-app-details/tpo-app-details.component';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { LoanService } from 'src/app/services/loan';
import { TransactionType } from 'src/app/models/config/loan-purpose.model';

@Component({
  selector: 'tpo-menu-v2',
  templateUrl: './tpo-menu-v2.component.html',
  styleUrls: ['./tpo-menu-v2.component.scss']
})
export class TpoMenuV2Component extends ApplicationContextBoundComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  featurePermissions: TpoFeaturePermissions;

  @Input()
  showStepLabels: boolean;

  @Input()
  set stepStatuses(value: { [key: string]: string }) {
    this._stepStatuses = value;
    this.populateInitialMenuItemStatuses();
  }

  get stepStatuses() {
    return this._stepStatuses;
  }

  tab: string;

  fileArchived: boolean = false;
  isUrlaReadOnly: boolean = false;
  isPRMG: boolean = false;
  isCompanyPulseProcessing: boolean = false;
  isBrokerDisclosedAndSubmitted: boolean = false;
  isReverseMortgage: boolean = false;

  stepOrder: { [key: string]: number } = {};

  protected uniqueId: string;
  protected channel: string;

  protected pricingVendorsAreOnlyIframes: boolean = false;
  protected isManualPricingEntryAvailable: boolean = false;

  protected disclosuresPending: boolean = false;

  protected loanId: number;
  protected hasLosLink: boolean = false;

  protected isCdRequestApplicable: boolean = false;

  protected showUWDecision: boolean = false;

  private _menuItemStatusSubscription: Subscription;
  private _routerEventSubscription: Subscription;
  private _activatedRouteSubscription: Subscription;
  private _queryParamsSubscription: Subscription;
  private _contextSubscription: Subscription;
  private _loanChangesSubscription: Subscription;

  private _stepStatuses: { [key: string]: string } = {};

  private _parentMenuItemsThatAreAlwaysExpanded: ParentMenuItemDescriptor[] = [
    { listItemId: 'services-menu-list', childListId: 'services-menu-ulist' }
  ];

  private _rootElement: HTMLElement;

  @ViewChild('urlaMenu') urlaMenu: UrlaMenuComponent;

  @ViewChildren(MenuItemComponent) menuItems:
    | QueryList<MenuItemComponent>
    | undefined;

  constructor(
    private readonly injector: Injector,
    private readonly _menuService: MenuService,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _loanService: LoanService,
    private readonly _urlaValidationService: UrlaValidationService
  ) {
    super(injector);
    this.isPRMG = this.applicationContext.isCompanyPRMG;
    this.isCompanyPulseProcessing = this.applicationContext.isCompanyPulseProcessing;
    this.uniqueId = uuidv4();
    this._routerEventSubscription = this._router.events.subscribe(
      (event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          this._getInitTab();
        }
      }
    );

    this._contextSubscription = this.applicationContextService.context.subscribe(context => {
      this.pricingVendorsAreOnlyIframes = this.checkIfPricingVendorsAreOnlyIframesOrNot(context);
      this.initialize(context);
    });

    this._loanChangesSubscription = this.applicationContextService.loanInfoChanges.subscribe(context => {
      this.initialize(context);
    })
  }

  ngOnInit(): void {
    (<any>$('#metismenu')).metisMenu();
    feather.replace();
    this._activatedRouteSubscription = this._activatedRoute.params.subscribe(params => {
      this._getInitTab();
    });

    this._queryParamsSubscription = this._activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams['disclosuresPending']) {
        this.disclosuresPending = queryParams['disclosuresPending'] === 'true';
      }
    });

    this.initialize(this.applicationContext);
  }

  ngAfterViewInit(): void {
    this.isReverseMortgage = this.applicationContext.globalConfig.loanPurpose.find(x =>
      x.loanPurposeId == this.applicationContext?.application?.loanPurposeId
    )?.transactionType == TransactionType.ReverseMortgage;

    this.applicationContext.application.mortgageLoan.isReverseMortgage = this.isReverseMortgage;

    const urlaFieldsConfig = this._urlaValidationService.getUrlaFieldsConfigForMortgage(this.applicationContext.application.mortgageLoan,
      !!this.applicationContext.application.losIdentifier, true);
    this._menuService.setStatus('borrowerInfo', this._urlaValidationService.getStatusForBorrowersInfo(urlaFieldsConfig, this.applicationContext.application.mortgageLoan, true));
    this._menuService.setStatus('financialInfo', this._urlaValidationService.getStatusForFinancialInfo(urlaFieldsConfig, this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('reo', this._urlaValidationService.getStatusForReo(urlaFieldsConfig, this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('loanAndProperty', this._urlaValidationService.getStatusForLoanProperty(urlaFieldsConfig, this.applicationContext.application.mortgageLoan, this.featurePermissions.isAppraisedValueHidden));
    this._menuService.setStatus('declarations', this._urlaValidationService.getStatusForDeclarations(urlaFieldsConfig, this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('militaryService', this._urlaValidationService.getStatusForMilitaryService(urlaFieldsConfig, this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('demographics', this._urlaValidationService.getStatusForDemographics(urlaFieldsConfig, this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('loanOriginatorInfo', this._urlaValidationService.getStatusForLoanOriginatorInfo(urlaFieldsConfig, this.applicationContext.application.mortgageLoan, this.featurePermissions.isMersEnabled, this.featurePermissions.isRequiredLoanAndCaseNumbers));
    this._menuService.setStatus('propertyLoanInfo', this._urlaValidationService.getStatusForPropertyLoanInfo(urlaFieldsConfig, this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('titleInfo', this._urlaValidationService.getStatusForTitleInfo(urlaFieldsConfig, this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('mortgageLoanInfo', this._urlaValidationService.getStatusForMortgageLoanInfo(urlaFieldsConfig, this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('qualifyingTheBorr', this._urlaValidationService.getStatusForQualifyingTheBorr(urlaFieldsConfig, this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('homeownershipEducation', this._urlaValidationService.getStatusForHomeownershipEducation(urlaFieldsConfig, this.applicationContext.application.mortgageLoan));
    this._menuService.setStatus('reverseMortgageLoanInfo', this._urlaValidationService.getStatusForReverseMortgageLoanInfo(urlaFieldsConfig, this.applicationContext.application.mortgageLoan));

    const urlaStatus = this._urlaValidationService.getStatusForUrla(urlaFieldsConfig, this.applicationContext.application.mortgageLoan,
      this.featurePermissions.isMersEnabled, this.featurePermissions.isRequiredLoanAndCaseNumbers, true);

    this.stepStatuses.urla = urlaStatus;
    this._menuService.setStatus('urla', urlaStatus);
    this._menuService.setUrlaStatus(urlaStatus);

    this.populateInitialMenuItemStatuses();
    this._menuItemStatusSubscription = this._menuService.menuItemStatusChanged.subscribe((e) => {
      const menuItem = this.menuItems.find((mi) => mi.id === e.id);
      if (menuItem) {
        menuItem.status = e.status;
      }

      setTimeout(() => {
        if (this.urlaMenu) {
          if (this.urlaMenu.menuItems.some(mi => mi.groupId === 'urla' && mi.status === MenuItemStatus.Error)) {
            this.stepStatuses.urla = MenuItemStatus.Error;
            this._menuService.urlaStatusChanged.emit(MenuItemStatus.Error);
          } else if (this.urlaMenu.menuItems.some(mi => mi.groupId === 'urla' && mi.status === MenuItemStatus.Pending)) {
            this.stepStatuses.urla = MenuItemStatus.Pending;
            this._menuService.urlaStatusChanged.emit(MenuItemStatus.Pending);
          } else {
            this.stepStatuses.urla = MenuItemStatus.Success;
            this._menuService.urlaStatusChanged.emit(MenuItemStatus.Success);
          }
        }
      }, 200);
    });
    this._rootElement = document.getElementById(this.uniqueId);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._menuItemStatusSubscription?.unsubscribe();
    this._activatedRouteSubscription?.unsubscribe();
    this._routerEventSubscription?.unsubscribe();
    this._contextSubscription?.unsubscribe();
    this._loanChangesSubscription?.unsubscribe();
    this._queryParamsSubscription?.unsubscribe();
  }

  onListItemClicked = (e: any, tabToActivate: string) => {
    this.setExpandedItems();
    if (this.tab === tabToActivate) {
      e.preventDefault();
      return;
    }
    this.tab = tabToActivate;

    const menuItemElements = this._rootElement.getElementsByTagName('menu-item');
    Array.from(menuItemElements).forEach(menuItem => {
      const listsUnderMenuItem = menuItem.parentElement?.getElementsByTagName('li');
      Array.from(listsUnderMenuItem).forEach(list => {
        list.classList.remove('mm-active');
      });
    });
  }

  onArchiveLoanClicked = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure you want to archive this loan?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
    }).then(async (result: SweetAlertResult) => {
      if (!result.value) {
        return;
      }
      const request = {
        "TpoArchiveRequested": new Date().toISOString()
      }
      await firstValueFrom(this._loanService.saveKeyDatesByType(request, this.applicationContext.application.applicationId));
      
      this.fileArchived = true;
      this.isUrlaReadOnly = true;
    });
  }

  onLinkSubMenuItemClicked = () => {
    const menuItemElements = this._rootElement.getElementsByTagName('menu-item');
    Array.from(menuItemElements).forEach(menuItem => {
      const listsUnderMenuItem = menuItem.parentElement?.getElementsByTagName('li');
      Array.from(listsUnderMenuItem).forEach(list => {
        list.classList.remove('mm-active');
      });
    });
  }

  onMenuItemClicked = (id: string) => {
    this._menuService.onMenuItemClicked(id);

    // *** All the code below is hacking the metismenu so that the behavior is as we wanted for the expanse/collapse of menu items...
    // const menuItem = document.getElementById(id);

    // const allLists = this._rootElement.getElementsByClassName('tpo-parent-menu-item');
    // Array.from(allLists).forEach(element => {
    //   if (menuItem?.parentElement?.parentElement !== element) {
    //     element.classList.remove("mm-active");
    //     const childLists = element.getElementsByTagName('ul');
    //     Array.from(childLists).forEach(childList => {
    //       if (menuItem?.parentElement !== childList) {
    //         childList.classList.remove("mm-show");
    //       }
    //     })
    //   }
    // });
    const childItems = this._rootElement.getElementsByClassName('nav-item');
    Array.from(childItems).forEach(element => {
      if (element.id !== id) {
        element.classList.remove("mm-active");
      } else {
        element.classList.add("mm-active");
      }
    });
  }

  private initialize = (ctx: ApplicationContext) => {
    if (!ctx.application) {
      return;
    }
    this.loanId = ctx.application.applicationId;
    this.hasLosLink = !!ctx.application.losIdentifier;

    this.getRecentUW(this.loanId);

    this.isCdRequestApplicable = (ctx.application.channel === ChannelEnum.Wholesale
      || ctx.application.channel === ChannelEnum.NonDelegatedCorrespondent || ctx.application.channel === ChannelEnum.Broker)
      && ctx.application.productPricing?.lockStatus === 'Accepted';

    try {
      const keyDates = ctx.applicationKeyDatesByType;
      if (keyDates?.leIssued?.eventDate || keyDates?.initialDisclosureSent?.eventDate) {
        this.isUrlaReadOnly = true;
      }
      if (keyDates?.tpoArchiveRequested?.eventDate) {
        this.fileArchived = true;
        this.isUrlaReadOnly = true;
      }
    } catch (error) {
      console.error(error);
    }

    const menuStatuses = ctx.tpoMenuStatuses;
    const statusItem = menuStatuses[Constants.menu.servicesMenuItems.submission];
    const tpoSubmitted = statusItem && statusItem.toLowerCase() == MenuItemStatus.Success;
    if (tpoSubmitted) {
      this.isUrlaReadOnly = true;
    }

    this.isBrokerDisclosedAndSubmitted = ctx.application?.disclosurePath == "Broker";

    this.isManualPricingEntryAvailable = ctx.userPermissions.companyId == 302;

    this.channel = ctx.application.channel;
    this.setStepOrders();
  }

  private setStepOrders = () => {

    let stepCounter = 2;

    this.stepOrder['urla'] = 1;

    if (this.featurePermissions?.isServicesEnabled && this.channel != 'Correspondent' && !this.isCompanyPulseProcessing) {
      this.stepOrder['services'] = stepCounter;
      stepCounter++;
    }

    if (this.featurePermissions?.pricingEnabled || this.isManualPricingEntryAvailable) {
      this.stepOrder['pricing'] = stepCounter;
      stepCounter++;
    }

    if (this.featurePermissions?.isFeesEnabled && this.channel != 'Correspondent' && !this.isCompanyPulseProcessing) {
      this.stepOrder['fees'] = stepCounter;
      stepCounter++;
    }

    if ((this.featurePermissions?.isDisclosureGenerationAllowed || this.isBrokerDisclosedAndSubmitted) && this.channel != 'Correspondent') {
      this.stepOrder['disclosures'] = stepCounter;
      stepCounter++;
    }

    this.stepOrder['submission'] = stepCounter;
  }

  private populateInitialMenuItemStatuses = () => {
    if (!this.menuItems) {
      return;
    }
    Object.keys(this.stepStatuses).forEach((key) => {
      const menuItem = this.menuItems.find((mi) => mi.id === key);
      if (menuItem) {
        menuItem.status = this.stepStatuses[key].toLowerCase() as MenuItemStatus;
      }
    });
  }

  protected setExpandedItems = () => {
    this._parentMenuItemsThatAreAlwaysExpanded.forEach(item => {
      const listItem = document.getElementById(item.listItemId);
      if (listItem) {
        listItem.className = "mm-active";
      }

      const childListItem = document.getElementById(item.childListId);
      if (childListItem) {
        childListItem.classList.add("mm-show");
        childListItem.style.height = "auto";
      }
    })
  }

  private _getInitTab = () => {
    const splitUrl = this._router.url.split("/");

    // init page
    setTimeout(() => {
      if (splitUrl.length < 5) {
        this.tab = 'loan-summary'
      } else {
        const pageType = splitUrl[splitUrl.length - 1];
        this.tab = pageType ? pageType : 'loan-summary';
        this.checkMenuItems(pageType);
      }
      this.setExpandedItems();
    })
  }

  private checkIfPricingVendorsAreOnlyIframesOrNot = (context: ApplicationContext): boolean => {
    const pricingEngines = context.userPermissions.enabledPricingEngines;
    let thereIsAtLeastOneVendorThatIsNotIframe = false;
    if (pricingEngines && pricingEngines.length > 0) {
      pricingEngines.forEach(pe => {
        if (pe !== PricingVendor.LenderPriceIframe && pe !== PricingVendor.LoanPassIframe) {
          thereIsAtLeastOneVendorThatIsNotIframe = true;
        }
      });
    }
    return !thereIsAtLeastOneVendorThatIsNotIframe;
  }

  private checkMenuItems = (pageType: string) => {
    const isCredit: string = 'credit-reports';
    const isAus: string = 'aus';
    const childItems = this._rootElement.getElementsByClassName('nav-item');
    if (pageType === isCredit) {
      Array.from(childItems).forEach(element => {
        if (element.id !== 'credit') {
          element.classList.remove("mm-active");
        } else {
          element.classList.add("mm-active");
        }
      });
    }
    if (pageType === isAus) {
      Array.from(childItems).forEach(element => {
        if (element.id !== isAus) {
          element.classList.remove("mm-active");
        } else {
          element.classList.add("mm-active");
        }
      });
    }
  }

  private getRecentUW = (loanId: number) => {
    this._loanService.getRecentUWDoc(loanId).subscribe({
      next: (response) => {
          if (response && response.docFiles && response.docFiles.length) {
              this.showUWDecision = true;
          }
      }
    })
  } 
}

export class ParentMenuItemDescriptor {
  listItemId: string;
  childListId: string;
}
