<form id="integrationPricingForm" #integrationPricingForm="ngForm" name="integrationPricingForm">
    <!-- Optimal Blue Pricing -->
    <div class='fieldset-title mt-3'>Optimal Blue Pricing Configuration</div>
    <ng-template [ngIf]="isOBEnabled" [ngIfElse]="OBDisabled">
        <div class='d-flex align-content-end flex-wrap'>
            <div class='mt-4 me-2' *ngFor='let obCred of optimalBlueCreds'>
                <credential-item [credential]='obCred' [credentials]='optimalBlueCreds'
                    (openCredentialModal)='openCredentialModal("OptimalBlue", $event)'>
                </credential-item>
            </div>
            <ng-container *ngIf="optimalBlueCreds.length === 0">
                <div class='mt-4 me-2'>
                    <create-credential [credentialType]="'OptimalBlue'"
                        (openCredentialModal)='openCredentialModal("OptimalBlue")'>
                    </create-credential>
                </div>
            </ng-container>
        </div>
    </ng-template>
    <ng-template #OBDisabled>
        <enable-message>Call to enable this feature!</enable-message>
    </ng-template>

    <!-- Lender Pricing -->
    <div class='fieldset-title mt-3'>Lender Pricing Configuration</div>
    <div class='d-flex align-content-end flex-wrap'>
        <div class='mt-4 me-2' *ngFor='let lpCred of lenderPriceCreds'>
            <credential-item [credential]='lpCred' [credentials]='lenderPriceCreds'
                (openCredentialModal)='openCredentialModal("LenderPrice", $event)'>
            </credential-item>
        </div>
        <ng-container>
            <div class='mt-4 me-2'>
                <create-credential [credentialType]="'PricingVendor'"
                    (openCredentialModal)='openCredentialModal("LenderPrice")'>
                </create-credential>
            </div>
        </ng-container>
    </div>

    <!-- Polly Pricing -->
    <div class='fieldset-title mt-3'>Polly Pricing Configuration</div>
    <div *ngIf="!hasSystemLevelCredsForPolly">
        <h5 class="fw-light"> System level credentials not found. Please configure system level credentials first. </h5>
    </div>
    <div *ngIf="hasSystemLevelCredsForPolly" class='d-flex align-content-end flex-wrap'>
        <div class='mt-4 me-2' *ngFor='let pollyCred of pollyPriceCreds'>
            <credential-item [credential]='pollyCred' [credentials]='pollyPriceCreds'
                (openCredentialModal)='openCredentialModal("Polly", $event)'>
            </credential-item>
        </div>
        <ng-container>
            <div class='mt-4 me-2'>
                <create-credential [credentialType]="'Polly'"
                    (openCredentialModal)='openCredentialModal("Polly")'>
                </create-credential>
            </div>
        </ng-container>
    </div>

    <!-- PollyIframe Pricing -->
    <div class='fieldset-title pt-3'>Polly IFrame Pricing Configuration</div>
    <div class='d-flex align-content-end flex-wrap'>
        <div class='mt-4 me-2' *ngFor='let pollyIframeCreds of pollyIframePricingCreds'>
            <credential-item [credential]='pollyIframeCreds' [credentials]='pollyIframePricingCreds'
                (openCredentialModal)='openCredentialModal("PollyIframe", $event)'>
            </credential-item>
        </div>
        <ng-container>
            <div class='mt-4 me-2'>
                <create-credential [credentialType]="'PollyIframe'"
                    (openCredentialModal)='openCredentialModal("PollyIframe")'>
                </create-credential>
            </div>
        </ng-container>
    </div>

    <!-- Meridian Link Pricing -->
    <div class='fieldset-title mt-3'>Meridian Link Pricing Configuration</div>
    <div class='d-flex align-content-end flex-wrap'>
        <div class='mt-4 me-2' *ngFor='let pml of meridianLinkPricingCreds'>
            <credential-item [credential]='pml' [credentials]='meridianLinkPricingCreds'
                (openCredentialModal)='openCredentialModal("MeridianLink", $event)'>
            </credential-item>
        </div>
        <ng-container>
            <div class='mt-4 me-2'>
                <create-credential [credentialType]="'MeridianLink'"
                    (openCredentialModal)='openCredentialModal("MeridianLink")'>
                </create-credential>
            </div>
        </ng-container>
    </div>

    <div class='fieldset-title pt-3'>LoanPass Pricing Configuration</div>
    <div class='d-flex align-content-end flex-wrap'>
        <div class='mt-4 me-2' *ngFor='let loanPassCreds of loanPassPricingCreds'>
            <credential-item [credential]='loanPassCreds' [credentials]='loanPassPricingCreds'
                (openCredentialModal)='openCredentialModal("LoanPass", $event)'>
            </credential-item>
        </div>
        <ng-container>
            <div class='mt-4 me-2'>
                <create-credential [credentialType]="'LoanPass'"
                    (openCredentialModal)='openCredentialModal("LoanPass")'>
                </create-credential>
            </div>
        </ng-container>
    </div>

    <div class='fieldset-title pt-3'>LoanPass IFrame Pricing Configuration</div>
    <div class='d-flex align-content-end flex-wrap'>
        <div class='mt-4 me-2' *ngFor='let loanPassIframeCreds of loanPassIframePricingCreds'>
            <credential-item [credential]='loanPassIframeCreds' [credentials]='loanPassIframePricingCreds'
                (openCredentialModal)='openCredentialModal("LoanPassIframe", $event)'>
            </credential-item>
        </div>
        <ng-container>
            <div class='mt-4 me-2'>
                <create-credential [credentialType]="'LoanPassIframe'"
                    (openCredentialModal)='openCredentialModal("LoanPassIframe")'>
                </create-credential>
            </div>
        </ng-container>
    </div>
</form>

