import { OnlineApplications } from "src/app/models";
import { IHaveName } from "src/app/models/have-name.interface";
import { ContactTrackingTag, Tag } from "../../admin/tag-management/models/tag.model";

export class AgentFull {
  agent: Agent;
  portalUserName: string;
  agentTypeId: number;
  isEdit: boolean;
  accountStatus: string;
  appId?: number;
  onlineApplications: OnlineApplications;

  constructor() {
    this.agent = new Agent();
  }
}

export class Agent implements IHaveName {
  agentId: number;
  userId: string;
  userProfile: UserProfile;
  agentContactUserId: string;
  agentCompanyId: number;
  agentCompany: AgentCompany;
  branchId: number = null;
  agentVisibility: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  title: string;
  department: string;
  orgName: string;
  nmlsId: string;
  mobilePhone: string;
  workPhone: string;
  extPhone: string;
  fax: string;
  email: string;
  assistantName: string;
  assistantPhone: string;
  assistantEmail: string;
  doNotCall: boolean;
  doNotEmail: boolean;
  alertViaSms: boolean;
  mailingStreet: string;
  mailingPOBox: string;
  mailingCity: string;
  mailingState: string;
  mailingZip: string;
  //agentListIds: Array<number> = [];
  contactListId: number;
  contactListRecordId: number;
  applicationCount: number;
  leadCount: number;
  lastAttemptedContact: Date;
  lastSuccessfulContact: Date;
  lastLoginActivity: Date;
  companyId: number;
  insertedBy: string;
  dateInserted: Date;
  updatedBy: string;
  dateUpdated: Date;
  tags: ContactTrackingTag[] = [];
  
  constructor() {
    this.userProfile = new UserProfile();
    this.agentCompany = new AgentCompany();
  }
}

export class UserProfile {
  id: string;
  companyId: number;
  stackOrderId: number;
  roleId: number;
  userName: string;
  userType: string;
  avatarId: string;
  firstName: string;
  lastName: string;
  timeZone: string;
  title: string;
  nmls: string;
  emailSignature: string;
  smsSignature: string;
  flagAdmin: boolean;
  reportTo: string;
  inviteDate: string;
  confirmationDate: string;
  twoFactorEnabled: boolean;
  email: string;
  emailConfirmed: boolean;
  phoneNumber: string;
  phoneNumberConfirmed: boolean;
  cellPhone: string;
  workPhone: string;
  homePhone: string;
  fax: string;
  mailingStreet: string;
  mailingPOBox: string;
  mailingCity: string;
  mailingState: string;
  mailingZip: string;
  active: boolean;
}

export class AgentCompany {
  agentCompanyId:	number;
  companyName:	string;
  companyAddress1:	string;
  companyAddress2:	string;
  companyCity:	string;
  companyState:	string;
  companyZip:	string;
  companyNMLSId:	string;
  companyStateLicenseId:	string;
  companyStateLicenseState:	string;
  companyStateLicenseIssueDate:	Date;
  website:	string;
  lenderOwnershipShare:	number;
  natureOfRelationshipWithLender:	string;
  companyId:	number;
  insertedBy:	string;
  updatedBy:	string;
  dateInserted:	Date;
  dateUpdated:	Date;
}
