import {Injectable, OnDestroy} from '@angular/core';
import {LoanFee} from '../fees.model';
import {FeeUtils} from '../utils/utils';
import {FeeSection, FeeSectionViewType} from '../fee-section.model';
import {createFeeSections} from '../create-fee-sections';
import {FeeViewModel} from '../fee-view-model';
import {FeeViewModelFactory} from '../fee-view-model-factory';
import {FeeDataAccessProps, FeeDataAccessService} from './fee-data-access.service';
import {BehaviorSubject, combineLatest, map, Observable, switchMap} from 'rxjs';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import {cloneDeep} from 'lodash';
import {KeyOfType} from '../../../../utils/types';
import assignRequiredMissingFeeProperties = FeeUtils.assignRequiredMissingFeeProperties;
import sortFeesByHudNumber = FeeUtils.sortFeesByHudNumber;
import areFeeReferencesEqual = FeeUtils.areFeeReferencesEqual;
import areFeesEqual = FeeUtils.areFeesEqual;
import areFeeSectionReferencesEqual = FeeUtils.areFeeSectionReferencesEqual;
import areFeeSectionsEqual = FeeUtils.areFeeSectionsEqual;

export interface SetFeesOptions {
  /**
   * If set to true, the missing required values will be set to default values.
   * @remarks The given fees will be modified in-place.
   * @default true
   */
  setMissingFeeProperties?: boolean;
  /**
   * If set to true, the fees will be sorted by their `hudNumber` property in
   * ascending order.
   * @default true
   */
  sortFees?: boolean;
  /**
   * If set to true, current {@link originalFees} will be updated with the given
   * fees.
   * @default true if there are no original fees; otherwise, false
   */
  resetOriginalFees?: boolean;
}

@Injectable()
export class FeeContextService implements OnDestroy {
  public get fees(): readonly LoanFee[] {
    return this._feesSubject.value;
  }

  public get originalFees(): readonly Readonly<LoanFee>[] {
    return this._originalFees;
  }

  private _originalFees: readonly Readonly<LoanFee>[] = [];
  private _originalFeeById: Map<string, LoanFee> = new Map();

  public get feeSections(): readonly FeeSection[] {
    return this._feeSections;
  }

  private _feeSections: FeeSection[] = [];
  private _feeSectionByType: Map<FeeSectionViewType, FeeSection> = new Map();

  private _feeViewModels: FeeViewModel[] = [];

  private _feesSubject = new BehaviorSubject<readonly LoanFee[]>([]);
  public readonly fees$ = this._feesSubject.asObservable();

  private _hasChangesSubject = new BehaviorSubject<boolean>(false);
  public readonly hasChanges$ = this._hasChangesSubject.asObservable();

  constructor(private readonly _feeDataAccessService: FeeDataAccessService) {
    const bind = <T extends KeyOfType<FeeDataAccessService, Function>>(key: T) =>
      this._feeDataAccessService[key].bind(this._feeDataAccessService);

    this.createFee = this.processFee(bind('createFee'))();
    this.deleteFee = this.processFee(bind('deleteFee'))({
      setMissingFeeProperties: false,
      sortFees: false,
    });
    this.updateFee = this.processFee(bind('updateFee'))();
  }

  ngOnDestroy(): void {
    this._feesSubject.complete();
  }

  public setFees(fees: readonly LoanFee[], options?: SetFeesOptions): void {
    const effectiveOptions = {
      setMissingFeeProperties: options?.setMissingFeeProperties ?? true,
      sortFees: options?.sortFees ?? true,
      resetOriginalFees: options?.resetOriginalFees ?? this._originalFees.length === 0,
    };

    if (effectiveOptions.setMissingFeeProperties) {
      fees = fees.map(assignRequiredMissingFeeProperties);
    }
    if (effectiveOptions.sortFees) {
      fees = sortFeesByHudNumber(fees);
    }

    this._feeSections = createFeeSections(fees);
    this._feeSectionByType = new Map(this._feeSections.map(section => [section.type, section]));

    this.setFeeViewModels(fees);

    this._feesSubject.next(fees);

    if (effectiveOptions.resetOriginalFees) {
      this.setOriginalFees(fees);
      this._hasChangesSubject.next(false);
    } else {
      this.checkChanges({ignoreCache: true});
    }
  }

  private setOriginalFees(fees: readonly LoanFee[]) {
    this._originalFees = cloneDeep(fees);
    this._originalFeeById = new Map(
      this._originalFees.reduce(
        (acc, fee) => {
          if (fee.modelGuid != null) {
            acc.push([fee.modelGuid, fee]);
          }
          return acc;
        },
        [] as [string, LoanFee][]
      )
    );
  }

  private setFeeViewModels(fees: readonly LoanFee[]) {
    const feeViewModelFactory = new FeeViewModelFactory();
    this._feeViewModels = feeViewModelFactory.create(fees);
  }

  public getFeeSectionByType(type: FeeSectionViewType): FeeSection | undefined {
    return this._feeSectionByType.get(type);
  }

  public getFeeSectionChanges$(
    type: FeeSectionViewType,
    compareFn?: (a: FeeSection, b: FeeSection) => boolean
  ): Observable<FeeSection | undefined> {
    const effectiveCompareFn = compareFn ?? areFeeSectionsEqual;
    return this.fees$.pipe(
      map(this.getFeeSectionByType.bind(this, type)),
      distinctUntilChanged(effectiveCompareFn)
    );
  }

  public getFeeSectionsChanges$(
    compareFn?: (a: FeeSection[], b: FeeSection[]) => boolean
  ): Observable<readonly FeeSection[]> {
    const effectiveCompareFn =
      compareFn ??
      ((a: FeeSection[], b: FeeSection[]) => {
        if (a.length !== b.length) {
          return false;
        }

        return a.every((section, index) => areFeeSectionReferencesEqual(section, b[index]));
      });

    return this.fees$.pipe(
      switchMap(
        () =>
          combineLatest(
            this._feeSections.map(section => this.getFeeSectionChanges$(section.type))
          ) as Observable<FeeSection[]>
      ),
      distinctUntilChanged(effectiveCompareFn)
    );
  }

  public getSectionFeeViewModels(section: FeeSection): FeeViewModel[] {
    return this._feeViewModels.filter(feeViewModel =>
      section.fees.some(areFeeReferencesEqual.bind(null, feeViewModel.fee))
    );
  }

  private getOriginalFeeOf(fee: LoanFee): LoanFee | undefined {
    const foundFee = this._originalFeeById.get(fee.modelGuid);
    if (foundFee) {
      return foundFee;
    }

    return this._originalFees.find(areFeeReferencesEqual.bind(null, fee));
  }

  public readonly createFee: (fee: Partial<LoanFee>) => Observable<void>;
  public readonly deleteFee: (fee: Partial<LoanFee>) => Observable<void>;
  public readonly updateFee: (fee: Partial<LoanFee>) => Observable<void>;

  private processFee(process: (props: FeeDataAccessProps) => Observable<LoanFee[]>) {
    return (options?: SetFeesOptions) => {
      return (fee: Partial<LoanFee>): Observable<void> => {
        return process({
          value: fee,
          existingFees: this._feesSubject.value,
        }).pipe(
          tap((fees: LoanFee[]) => {
            this.setFees(fees, options);
          }),
          map(() => undefined)
        );
      };
    };
  }

  /**
   * Checks if there are any changes in the fees.
   * @param {object} [options]
   * @param {boolean} [options.ignoreCache=false] - If set to true, it ignores
   * the cache and re-calculates the changes.
   * Otherwise, it returns the cached value (if available).
   * @returns {boolean} - True if there are changes in the fees; otherwise,
   * false.
   * @remarks Comparing the fees is an expensive operation. That's why the
   * result is cached. If you want to re-calculate the changes, set
   * `options.resetCache` to true.
   */
  public checkChanges(options?: {ignoreCache?: boolean}): boolean {
    options = {
      ignoreCache: options?.ignoreCache ?? false,
    };

    if (!options.ignoreCache) {
      return this._hasChangesSubject.value;
    }

    this._hasChangesSubject.next(this.calculateHasChanges());
  }

  private calculateHasChanges(): boolean {
    const fees = this._feesSubject.value;
    if (fees.length !== this._originalFees.length) {
      return true;
    }

    return !fees.every(fee => areFeesEqual(this.getOriginalFeeOf(fee), fee));
  }

  /**
   * Discards the changes in the fees.
   * @param {object} [options]
   * @param {boolean} [options.force=false] - If set to true, it skips the check
   * for changes and discards the changes directly.
   */
  public discardChanges(options?: {force?: boolean}): void {
    options = {
      force: options?.force ?? false,
    };

    if (!options.force && !this.checkChanges()) {
      return;
    }

    this.setFees(cloneDeep(this._originalFees), {
      setMissingFeeProperties: false,
      sortFees: false,
      resetOriginalFees: false,
    });
  }
}
