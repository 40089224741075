import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomData, CustomDataConfig, UserType } from 'src/app/models';
import { CustomDataEditConfig } from './custom-field-editor/custom-data-edit-config.model';
import _, { cloneDeep } from 'lodash';
import { AppDetailsService } from '../app-details/services/app-details.service';

export enum CustomInfoTemplateType {
  Label_LEFT = "Label_LEFT",
  Label_BOTTOM = "Label_BOTTOM"
}
@Component({
  selector: 'custom-data-info',
  templateUrl: './custom-data-info.component.html',
  styleUrls: ['./custom-data-info.component.scss']
})
export class CustomDataInfoComponent implements OnInit {

  @Output()
  customDataInfoInitialized: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  set customData(customData: CustomData[]) {
    if (!customData) {
      return;
    }
    this._customData = customData;
    this.ngOnInit();
  }

  get customData(): CustomData[] {
    return this._customData;
  }

  @Input()
  isInClassicEditMode: boolean = true;

  @Input()
  userType: UserType = UserType.Admin;

  @Input()
  leadId?: number;

  @Input()
  applicationId?: number;

  @Input()
  templateType: CustomInfoTemplateType = CustomInfoTemplateType.Label_LEFT;

  @Input()
  labelAlignment: 'left' | 'right' = 'right';

  customDataConfig: CustomDataConfig[] = [];
  customDataFiltered: {
    row: number;
    col1: CustomDataEditConfig;
    col2: CustomDataEditConfig;
    customData1: CustomData;
    customData2: CustomData;
  }[] = [];

  isLoading: boolean = false;

  data: {
    index: number;
    col: CustomDataEditConfig;
    customData: CustomData;
  }[] = [];

  private _customData: CustomData[];

  constructor(
    private readonly _appService: AppDetailsService,
  ) {
    this._customData = [];
  }

  ngOnInit() {

    this.isLoading = true;

    this._appService.getCustomDataConfig().subscribe(response => {
      this.customDataConfig = (response || []).filter(x => !!x.layouts?.find(l => l.roleType == this.userType && l.uiRow));

      this.customDataFiltered = [];
      
      this.customDataConfig.forEach(item => {
        var cd = this.customDataFiltered.find(x => x.row == item.layouts?.filter(x => x.roleType == this.userType)[0].uiRow);
        var layout = item.layouts.filter(x => x.roleType == this.userType)[0];
        if (!cd) {
          cd = { row: layout.uiRow, col1: null, col2: null, customData1: null, customData2: null };
          this.customDataFiltered.push(cd);
        }
        if (layout.uiColumn == 1) {
          cd.col1 = new CustomDataEditConfig(item.labelText, item.editorType, item.fieldType, item.customDropDownOptions, item.multiLookupType);
          cd.customData1 = this._customData?.find(x => x.customDataFieldConfigId == item.customDataFieldConfigId);
          if (!cd.customData1) {
            cd.customData1 = new CustomData();
            cd.customData1.customDataFieldConfigId = item.customDataFieldConfigId;
            cd.customData1.leadId = this.leadId;
            cd.customData1.applicationId = this.applicationId;
            this._customData.push(cd.customData1);
          }
        }
        if (layout.uiColumn == 2) {
          cd.col2 = new CustomDataEditConfig(item.labelText, item.editorType, item.fieldType, item.customDropDownOptions, item.multiLookupType);
          cd.customData2 = this._customData?.find(x => x.customDataFieldConfigId == item.customDataFieldConfigId);
          if (!cd.customData2) {
            cd.customData2 = new CustomData();
            cd.customData2.customDataFieldConfigId = item.customDataFieldConfigId;
            cd.customData2.leadId = this.leadId;
            cd.customData2.applicationId = this.applicationId;
            this._customData.push(cd.customData2);
          }
        }
      });

      this.customDataFiltered = _.orderBy(this.customDataFiltered, x=>x.row);

      for (let i=0; i<this.customDataFiltered.length; i++) {
        if (!this.customDataFiltered[i].col1) {
          let col1Index = i;
          while (!this.customDataFiltered[i].col1 && col1Index < this.customDataFiltered.length) {
            col1Index++;
            if (this.customDataFiltered[col1Index]?.col1) {
              this.customDataFiltered[i].col1 = cloneDeep(this.customDataFiltered[col1Index].col1);
              this.customDataFiltered[i].customData1 = cloneDeep(this.customDataFiltered[col1Index].customData1);
              this.customDataFiltered[col1Index].col1 = null;
              this.customDataFiltered[col1Index].customData1 = null;
            }
          }
        }
        if (!this.customDataFiltered[i].col2) {
          let col2Index = i;
          while (!this.customDataFiltered[i].col2 && col2Index < this.customDataFiltered.length) {
            col2Index++;
            if (this.customDataFiltered[col2Index]?.col2) {
              this.customDataFiltered[i].col2 = cloneDeep(this.customDataFiltered[col2Index].col2);
              this.customDataFiltered[i].customData2 = cloneDeep(this.customDataFiltered[col2Index].customData2);
              this.customDataFiltered[col2Index].col2 = null;
              this.customDataFiltered[col2Index].customData2 = null;
            }
          }
        }
      }
      
      this.isLoading = false;
      this.customDataInfoInitialized.emit();
    }, () => {
      this.isLoading = false;
    });
  }

  protected reRender = () => {
    this.isLoading = true;
    setTimeout(() => { this.isLoading = false; }, 100);
  }
}
